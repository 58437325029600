import { StyledPage } from '../SignUp/styles';

import { FillCheck } from '@/assets/icons';
import Page from '@/components/Page';
import Stack from '@/components/Stack';
import { Body, Small, Title } from '@/components/Typography';
import Link from '@/elements/Link';

const Waitlist = () => {
  return (
    <Page size="xs">
      <StyledPage
        css={{ alignItems: 'flex-start', paddingTop: '36px' }}
        size="xs"
      >
        <Stack vertical gap={24}>
          <Stack vertical gap={41}>
            <Stack vertical gap={2}>
              <FillCheck style={{ fontSize: '64px' }} />
              <Title size={'xxxl'} weight={'normal'}>
                Thank You!
              </Title>
              <Small color="lightest" size="md">
                We will let you know when you are invited!
              </Small>
            </Stack>
            <Stack vertical gap={2}>
              <Link
                as="a"
                href={`https://${process.env.SUB_DOMAIN}.gravywork.com`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Body color="inherit" size="md" weight="medium">
                  Open the client portal in your browser
                </Body>
              </Link>
            </Stack>
          </Stack>
        </Stack>
      </StyledPage>
    </Page>
  );
};

export default Waitlist;

import { useFeatureValue } from '@growthbook/growthbook-react';
import { ArrowBack, Campaign, PersonAdd, Search } from '@mui/icons-material';
import {
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';

import ChatWorkerList from '../ChatWorkerList';
import { InputStyle } from '../utils';

import SelectGroupWorkers from './SelectGroupWorkers';

import { Heading, Subheading } from '@/components/Typography';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { USER_FETCH_LIMIT } from '@/constants/general';
import { V3Endpoints } from '@/constants/urls';
import useAuth from '@/hooks/useAuth';
import useDebouncedValue from '@/hooks/useDebouncedValue';
import { IconColor, theme } from '@/styles/colors';
import axiosClient from '@/util/axios/axiosClient';
import { ChatGroupGWUserType, MessageReceiversList } from '@/util/axios/types';

type Props = {
  setShowReceivers: (show: boolean) => void;
  handleCreateChatGroup: (id: string) => void;
  showBulkMsg: boolean;
  setShowBulkMsg: (show: boolean) => void;
  handleBulkClick: () => void;
};

const SelectReceiver = ({
  setShowReceivers,
  handleCreateChatGroup,
  showBulkMsg,
  setShowBulkMsg,
  handleBulkClick,
}: Props) => {
  const groupChatEnable = useFeatureValue(FEATURE_TOGGLE.GroupChat, false);
  const [page, setPage] = useState(1);
  const [input, setInput] = useState('');
  const debInput = useDebouncedValue(input);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { currentAdminIsCustomerAdmin: isCustomerAdmin } = useAuth();
  const [showGroupChatList, setShowGroupChatList] = useState(false);

  useEffect(() => {
    setLoading(true);
    axiosClient
      .post(V3Endpoints.SEARCH_CHAT_USERS, {
        search: debInput,
        offset: (page - 1) * USER_FETCH_LIMIT,
        limit: USER_FETCH_LIMIT,
      })
      .then((data) => {
        const records = data?.data?.records;
        if (records) {
          if (page > 1) {
            setData((d) => [...d, ...records]);
          } else {
            setData(records);
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  }, [page, debInput]);

  const getStyles = (selected) => ({
    padding: '11px 16px',
    cursor: 'pointer',
    backgroundColor: selected ? '#EEFFEC' : '',
    color: selected ? theme.themeDefault : 'black',
    height: '44px',
  });

  const handleClick = async (info: MessageReceiversList[0]) => {
    const name =
      info.type === ChatGroupGWUserType.WORKER
        ? `${info.first_name || ''} ${info.middle_name || ''} ${info.last_name}`
        : ChatGroupGWUserType.TENANT_ADMIN;
    try {
      const resp = await axiosClient.post(V3Endpoints.CREATE_CHAT_GROUP, {
        name,
        user: {
          id: info.id,
          type: info.type,
        },
      });

      if (resp?.data?.id) {
        handleCreateChatGroup(resp.data.id);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div style={{ maxHeight: '92vh', overflowY: 'auto', height: '92vh' }}>
      {showGroupChatList && (
        <SelectGroupWorkers
          handleBack={() => {
            setShowGroupChatList(false);
          }}
        />
      )}
      {!showGroupChatList && (
        <>
          <Stack
            direction={'row'}
            spacing={1.5}
            style={{
              padding: '12px 16px',
            }}
          >
            <IconButton
              id="back-btn"
              sx={{
                padding: '0',
              }}
              onClick={() => {
                setShowBulkMsg(false);
                setShowReceivers(false);
              }}
            >
              <ArrowBack htmlColor="#262626" />
            </IconButton>
            <Heading>{'New Message'}</Heading>
          </Stack>
          <Divider />
          <div style={{ padding: '12px 20px' }}>
            <TextField
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
              id="search-input"
              placeholder="Search or start a new chat"
              size="small"
              sx={InputStyle}
              value={input}
              onChange={(e) => {
                setInput(e.target.value);
                setPage(1);
              }}
            />
          </div>
          {!isCustomerAdmin && (
            <Stack
              direction={'row'}
              id={'bulk-message'}
              spacing={2}
              style={getStyles(showBulkMsg)}
              onClick={() => {
                setShowGroupChatList(false);
                handleBulkClick();
              }}
            >
              <Campaign htmlColor={showBulkMsg ? 'inherit' : IconColor} />
              <Subheading color={'inherit'}>Bulk Message Workers</Subheading>
            </Stack>
          )}
          {groupChatEnable && (
            <Stack
              direction={'row'}
              id={'group-chat'}
              spacing={2}
              style={getStyles(showGroupChatList)}
              onClick={() => {
                setShowBulkMsg(false);
                setShowGroupChatList(true);
              }}
            >
              <PersonAdd
                htmlColor={showGroupChatList ? 'inherit' : IconColor}
              />
              <Subheading color={'inherit'}>Group Chat</Subheading>
            </Stack>
          )}
          <Divider />
          {!showBulkMsg && (
            <ChatWorkerList
              data={data}
              handleWorkerClick={handleClick}
              loading={loading}
              page={page}
              setPage={setPage}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SelectReceiver;

import { createSvgIcon } from '@mui/material';

const ToolBoxIcon = createSvgIcon(
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5 5.23438H2.5C1.80964 5.23438 1.25 5.79402 1.25 6.48438V12.7344C1.25 13.4247 1.80964 13.9844 2.5 13.9844H12.5C13.1904 13.9844 13.75 13.4247 13.75 12.7344V6.48438C13.75 5.79402 13.1904 5.23438 12.5 5.23438Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10 13.9844V3.98438C10 3.65285 9.8683 3.33491 9.63388 3.10049C9.39946 2.86607 9.08152 2.73438 8.75 2.73438H6.25C5.91848 2.73438 5.60054 2.86607 5.36612 3.10049C5.1317 3.33491 5 3.65285 5 3.98438V13.9844" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
  </svg>,
  'ToolBoxIcon'
);
export default ToolBoxIcon;

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { ChangeEvent, useState } from 'react';

import { onRefetchType } from '../modals/ChangePaymentModal';

import { ButtonContainer, CardElementContainer, Wrapper } from './styles';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import { AchConfigProps } from '@/components/PaymentMethod/Ach';
import Stack from '@/components/Stack';
import TextSelect from '@/components/TextSelect';
import { useAttachBankAccountMutation } from '@/graphql';
import { GetAccountQuery } from '@/types/graphql';

type SelectPaymentAccountProps = {
  account?: GetAccountQuery['account'];
  achConfig: AchConfigProps;
  onRefetch?: onRefetchType;
  onCloseEditor: () => void;
  handleSelect?: (payload: {
    bankAccountId: string;
    publicToken: string;
  }) => void;
};

const SelectPaymentAccount = ({
  account,
  achConfig: { publicToken, paymentAccounts },
  onRefetch,
  onCloseEditor,
  handleSelect,
}: SelectPaymentAccountProps) => {
  const [selectedBankAccountId, setSelectedBankAccountId] = useState<string>(
    paymentAccounts !== undefined ? paymentAccounts[0].id : ''
  );
  const [error, setError] = useState<any>(null);

  const [attachBankAccountToAccount, { loading }] =
    useAttachBankAccountMutation();

  const addAccount = async () => {
    try {
      if (handleSelect) {
        handleSelect({ bankAccountId: selectedBankAccountId, publicToken });
        return;
      }
      if (account) {
        await attachBankAccountToAccount({
          variables: {
            account: account.id,
            bankAccountId: selectedBankAccountId,
            publicToken,
          },
        });

        await onRefetch?.();
        onCloseEditor();
      }
    } catch (error: any) {
      setError(error);
    }
  };

  const handleSelectAccount = ({
    currentTarget: { value },
  }: ChangeEvent<HTMLSelectElement>) => {
    setSelectedBankAccountId(value);
  };

  return (
    <Wrapper>
      {error && (
        <Alert
          description={error.message}
          icon={faExclamationTriangle}
          status="warning"
          title="Failed to add account"
        />
      )}
      <CardElementContainer vertical gap={0}>
        <Stack>
          <TextSelect onChange={handleSelectAccount}>
            <option defaultChecked disabled hidden defaultValue="">
              Select your account
            </option>
            {paymentAccounts.map((account) => (
              <option key={account.id} value={account.id}>
                {account.name} - {account.subtype.toUpperCase()} ****{' '}
                {account.mask}
              </option>
            ))}
          </TextSelect>
        </Stack>
        <ButtonContainer justify="end">
          <Button
            a11yLabel="Go back"
            appearance="outline"
            id="btn-go-back"
            label="Back"
            type="button"
            onClick={onCloseEditor}
          />
          <Button
            a11yLabel="Add Account"
            disabled={!selectedBankAccountId}
            id="btn-add-account"
            isLoading={loading}
            label="Add Account"
            type="button"
            onClick={addAccount}
          />
        </ButtonContainer>
      </CardElementContainer>
    </Wrapper>
  );
};

export default SelectPaymentAccount;

import { CloseOutlined } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import * as React from 'react';

interface Props {
  hideModal: () => void;
  children: React.ReactNode;
  title: string;
  count: number;
}

const EventsModal = ({ hideModal, title, count, children }: Props) => {
  return (
    <Dialog
      PaperProps={{
        style: {
          height: count > 6 ? '75vh' : 30 + count * 8 + 'vh',
          borderRadius: '15px',
        },
      }}
      aria-labelledby="options-dialog"
      fullScreen={true}
      open={true}
      sx={{
        '& .MuiDialog-container': { alignItems: 'end', marginBottom: '2px' },
      }}
      onClose={hideModal}
    >
      <DialogTitle id="options-dialog-title" sx={{ m: 0, p: 1.5, pb: 0 }}>
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
        onClick={hideModal}
      >
        <CloseOutlined />
      </IconButton>
      <DialogContent
        dividers
        style={{ marginTop: '10px', padding: '10px 30px' }}
      >
        <div
          onClick={() =>
            setTimeout(() => {
              hideModal();
            }, 250)
          }
        >
          {children}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EventsModal;

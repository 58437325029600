import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { useState } from 'react';

import ActiveUserList from './ActiveUserList';
import ActiveUserListBySkill from './ActiveUserListBySkill';
import CustomerList from './CustomerList';
import Invoicing from './Invoicing';
import Revenue from './Revenue';
import Timesheets from './Timesheets';

import Alert from '@/components/Alert';
import Card from '@/components/Card';
import Page from '@/components/Page';
import Table from '@/components/Table';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { TableRow } from '@/elements/Table';
import { ERROR_SUPPORT_MESSAGE } from '@/util/constants';

function Reports() {
  const [error, setError] = useState<any>(null);
  const hideReports = useFeatureValue(FEATURE_TOGGLE.HideBrokenReports, false);

  const sections = hideReports
    ? [
        <CustomerList key="CustomerList" setError={setError} />,
        <Timesheets key="Timesheets" />,
      ]
    : [
        <ActiveUserList key="ActiveUserList" setError={setError} />,
        <ActiveUserListBySkill
          key="ActiveUserListBySkill"
          setError={setError}
        />,
        <CustomerList key="CustomerList" setError={setError} />,
        <Invoicing key="Invoicing" setError={setError} />,
        <Revenue key="Revenue" setError={setError} />,
        <Timesheets key="Timesheets" />,
      ];

  return (
    <Page title="Reports">
      {error && (
        <Alert
          description={error.message ?? ERROR_SUPPORT_MESSAGE}
          icon={faExclamationTriangle}
          status="danger"
          title="An error has occurred"
        />
      )}
      <Card>
        <Table>
          <tbody>
            {sections.map((child, index) => (
              <TableRow key={index}>{child}</TableRow>
            ))}
          </tbody>
        </Table>
      </Card>
    </Page>
  );
}

export default Reports;

export const ACCESS_TOKEN_KEY = '$accessToken';
export const DEVICE_TOKEN_KEY = '$deviceToken';
export const RESET_PASSWORD = '$resetPassword';
export const ACCOUNT_DISABLED = '$ad';
export const PENDING_CUSTOMERS = '$pendingCustomers';
export const SHOW_RED_DOT_ON_CHATS = '$showRedDotOnChats';
export const DEBUG_FLAG_KEY = 'debug';
export const ACTIVE_CHAT_ID = '$activeChatId';

export default {
  clear: () => localStorage.clear(),
  clearAuth: () =>
    [
      ACCESS_TOKEN_KEY,
      DEVICE_TOKEN_KEY,
      RESET_PASSWORD,
      ACCOUNT_DISABLED,
      PENDING_CUSTOMERS,
      SHOW_RED_DOT_ON_CHATS,
      ACTIVE_CHAT_ID,
    ].forEach((key) => localStorage.removeItem(key)),
  get: (key: string) => localStorage.getItem(key),
  set: (key: string, value: string) => localStorage.setItem(key, value),
  remove: (key: string) => localStorage.removeItem(key),
  multiGet: (...keys: string[]) => {
    const values: (string | null)[] = [];

    keys.forEach((key) => {
      values.push(localStorage.getItem(key));
    });

    return values;
  },
  multiSet: (map: Record<string, string>) => {
    Object.keys(map).forEach((key) => {
      localStorage.setItem(key, map[key]);
    });
  },
};

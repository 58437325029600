import { useCallback, useEffect, useMemo, useState } from 'react';

import Autocomplete from '@/components/Autocomplete';
import Stack from '@/components/Stack';
import TagList from '@/components/TagList';
import { FilterProps } from '@/filters';
import { useAutocompleteCustomersLazyQuery } from '@/graphql';
import useAuth from '@/hooks/useAuth';
import useDebouncedValue from '@/hooks/useDebouncedValue';
import { AutocompleteCustomersQuery, JobFilterSetInput } from '@/types/graphql';
import { CustomerStatusEnum } from '@/util/accountstatus';

type Item = AutocompleteCustomersQuery['agency']['customers']['items'][0];

const SelectableClientFilter = ({
  filters,
  filterKey,
  onChangeFilter,
  onClearFilter,
}: FilterProps<JobFilterSetInput>) => {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebouncedValue(query);
  const { currentAgency } = useAuth();

  const [clientSelectable, setClientSelectable] = useState<Item[] | []>([]);

  const [fetch, { data }] = useAutocompleteCustomersLazyQuery({
    onCompleted: (data) => {
      const clients = data.agency.customers.items.filter((client) => {
        return filters.customerIds?.includes(client.id);
      });

      setClientSelectable(clients);
    },
  });

  const items: Item[] = useMemo(() => {
    if (data) {
      const clients = data.agency.customers.items.filter((item) => {
        if (clientSelectable.length > 0 && clientSelectable?.includes(item)) {
          return false;
        }

        return item.name.toLowerCase().includes(debouncedQuery.toLowerCase());
      });

      return clients;
    }
    return [];
  }, [data, debouncedQuery, clientSelectable]);

  const handleSelect = useCallback(
    (item) => {
      if (clientSelectable.length < 1) {
        return setClientSelectable([item.selectedItem]);
      }
      return setClientSelectable([...clientSelectable, item.selectedItem]);
    },
    [clientSelectable]
  );

  const handleRemove = useCallback(
    (index) => {
      const newSkillsSelected = clientSelectable.filter(
        (_, skillIndex) => skillIndex !== index
      );

      setClientSelectable(newSkillsSelected);

      if (newSkillsSelected.length < 1) {
        onClearFilter(filterKey);
      }
    },
    [clientSelectable]
  );

  useEffect(() => {
    fetch({
      variables: {
        agencyId: currentAgency!.id,
        query: debouncedQuery,
        customerStatus: [CustomerStatusEnum.APPROVED],
      },
    });
  }, [debouncedQuery]);

  useEffect(() => {
    if (clientSelectable.length > 0) {
      onChangeFilter(
        filterKey,
        clientSelectable.map((item) => item.id)
      );
    }
  }, [clientSelectable]);

  return (
    <div style={{ width: '80%' }}>
      <Autocomplete<Item>
        fixedSelectHeight="lg"
        id={'client-autocomplete'}
        itemToKey={(item) => item.id}
        itemToString={(item) => (item ? item.name : '')}
        items={items}
        placeholder="Search for clients..."
        selectedItem={null}
        onInputValueChange={({ inputValue }) => setQuery(inputValue || '')}
        onSelectedItemChange={handleSelect}
      />

      {clientSelectable.length > 0 && (
        <Stack
          css={{
            paddingTop: '0.5rem',
          }}
        >
          <TagList
            tags={clientSelectable.map((item) => item.name)}
            onRemove={handleRemove}
          />
        </Stack>
      )}
    </div>
  );
};

export default SelectableClientFilter;

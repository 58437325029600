import { Dispatch, SetStateAction, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { ItemSelected, PayrollItem } from '..';
import PayrollStatusBadge from '../../PayrollStatusBadge';

import Card from '@/components/Card';
import Table from '@/components/Table';
import TableHeader from '@/components/TableHeader';
import { TableCell, TableRow } from '@/elements/Table';
import { capitalizeText } from '@/util/text';

export const itemsHeaderFields = [
  'Employee',
  'Payment Method',
  'Payroll Status',
  'Net Pay',
];

type Props = {
  items: PayrollItem[];
  setItem: Dispatch<SetStateAction<ItemSelected>>;
};

const ItemsCard = ({ items, setItem }: Props) => {
  const { payrollId } = useParams();

  const renderItems = useCallback(
    (item) => {
      const { id, netPay, paymentMethod, status, worker } = item;
      return (
        <TableRow
          key={id}
          clickable
          onClick={() =>
            setItem({
              showItem: true,
              item,
              type: 'item',
            })
          }
        >
          <TableCell>
            {capitalizeText(
              `${worker!.user.firstName} ${worker!.user.middleName ?? ''} ${
                worker!.user.lastName
              }`
            )}
          </TableCell>
          <TableCell>{capitalizeText(paymentMethod)}</TableCell>
          <TableCell>
            <PayrollStatusBadge status={status} />
          </TableCell>
          <TableCell>{netPay}</TableCell>
        </TableRow>
      );
    },
    [payrollId]
  );

  return (
    <Card title="Items">
      <Table>
        <TableHeader fields={itemsHeaderFields} />
        <tbody>{items!.map(renderItems)}</tbody>
      </Table>
    </Card>
  );
};

export default ItemsCard;

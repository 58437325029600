import { FieldContext } from '@area2k/use-form';
import { useEffect, useState } from 'react';

import { useOrderActions, useOrderState } from '../../../context';
import {
  Step,
  Uniform,
  useJobDraftActions,
  useJobDraftState,
} from '../../context';
import BottomBar from '../BottomBar';
import Layout from '../Layout';

import InstructionsSection from './InstructionsSection';
import UniformSection from './UniformSection';

import Button from '@/components/Button';
import SingleColumnLayout from '@/components/SingleColumnLayout';
import Stack from '@/components/Stack';
import { Heading } from '@/components/Typography';
import { GAEvent } from '@/constants/gaevents';
import Form from '@/form';
import useMediaQuery from '@/hooks/useMediaQuery';
import { Maybe } from '@/types';
import { AccountRateTypeEnum, JobTypeEnum, Scalars } from '@/types/graphql';
import useAnalytics from '@/util/analytics';

type Props = {
  setStep: (step: Step) => Scalars['Void'];
};

export type FormValues = {
  instructions: Scalars['String'];
  uniform: Maybe<Uniform>;
  uniformInstructions: Scalars['String'];
};

const DetailsStep = ({ setStep }: Props) => {
  const { updateDetails } = useJobDraftActions();
  const jobState = useJobDraftState();
  const { addJob } = useOrderActions();
  const startTime = new Date().getTime();

  const { logEvent } = useAnalytics();

  const { billing, orderType } = useOrderState();
  const {
    skill,
    uniform,
    uniformInstructions,
    instructions: instructionsInContext,
    contact,
    contactInstructions,
  } = jobState;

  const [formValues, setFormValues] = useState<FormValues>({
    instructions:
      instructionsInContext !== ''
        ? instructionsInContext
        : (skill?.descriptionEn ?? ''),
    uniform,
    uniformInstructions,
  });
  const continueButtonIsDisabled =
    !formValues.uniform || !formValues.instructions;

  const handleFormValuesChange = <T extends any>(
    fieldContext: FieldContext<T>,
    fieldId: keyof FormValues,
  ) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldId]: fieldContext.value,
    }));
  };

  const handleSubmit = async ({
    instructions,
    uniform,
    uniformInstructions,
  }: FormValues) => {
    updateDetails({
      instructions: instructions.trim(),
      uniform: uniform!,
      uniformInstructions: uniformInstructions.trim(),
      contact,
      contactInstructions: contactInstructions.trim(),
    });

    if (billing?.account.rateType === AccountRateTypeEnum.MARKUP) {
      logEvent(
        orderType === JobTypeEnum.LTA
          ? GAEvent.StepFourMarkupLta
          : GAEvent.StepFourMarkupGig,
        billing?.account?.id,
        {
          time_spent: (new Date().getTime() - startTime) / 1000,
        },
      );
      return setStep(Step.PAYRATE);
    } else {
      logEvent(
        orderType === JobTypeEnum.LTA
          ? GAEvent.StepFourStaticLta
          : GAEvent.StepFourStaticGig,
        billing?.account?.id,
        {
          time_spent: (new Date().getTime() - startTime) / 1000,
        },
      );
      addJob({
        ...jobState,
        instructions: instructions.trim(),
        uniform: uniform!,
        uniformInstructions: uniformInstructions.trim(),
        contact,
        contactInstructions: contactInstructions.trim(),
      });
      return setStep(Step.SUBMISSION);
    }
  };
  const phoneOnly = useMediaQuery('(max-width: 559px)');

  return (
    <Layout>
      <Form initialValues={formValues} onSubmit={handleSubmit}>
        <SingleColumnLayout
          noPadding={phoneOnly}
          size={phoneOnly ? 'full' : 'md'}
        >
          <Stack vertical gap={24}>
            <Heading>Add job instructions</Heading>
            <UniformSection handleUniformChange={handleFormValuesChange} />
            <InstructionsSection
              handleInstructionsChange={handleFormValuesChange}
            />
            <BottomBar>
              <Button
                a11yLabel="Go back to previous step"
                appearance="outline"
                id="back-btn"
                label="Back"
                type="button"
                onClick={() => setStep(Step.STAFFING)}
              />
              <Button
                a11yLabel="Submit form"
                disabled={continueButtonIsDisabled}
                id="continue-btn"
                label="Continue"
                type="submit"
              />
            </BottomBar>
          </Stack>
        </SingleColumnLayout>
      </Form>
    </Layout>
  );
};

export default DetailsStep;

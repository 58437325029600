import { JobDraftState } from '../../../context';
import HolidayInfo from '../../ScheduleStep/HolidayInfo';

import JobRates from './JobRates';

import { calculateTotalHours, formatDuration } from '@/util/datetime';

interface SummaryProps {
  job: JobDraftState;
}

const Summary = ({ job }: SummaryProps) => {
  const schedules = job.schedules;
  const totalHours = formatDuration(
    calculateTotalHours({ schedules: job.schedules })
  );

  return (
    <>
      <JobRates schedule={schedules[0]} totalHours={totalHours} />
      <HolidayInfo schedules={schedules} totalHours={totalHours} />
    </>
  );
};

export default Summary;

import { createSvgIcon } from '@mui/material';

const UnlinkIcon = createSvgIcon(
  <svg
    fill="none"
    height="51"
    viewBox="0 0 45 51"
    width="45"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1H13V50H1"
      stroke="#D3D3D3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M38 28.5L38 30.5C38 33.2614 35.7614 35.5 33 35.5C30.2386 35.5 28 33.2614 28 30.5L28 28.5M38 22.5L38 20.5C38 17.7386 35.7614 15.5 33 15.5C30.2386 15.5 28 17.7386 28 20.5L28 22.5"
      stroke="#262626"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>,
  'UnlinkIcon'
);
export default UnlinkIcon;

import Card from '@/components/Card';
import Skeleton from '@/components/Skeleton';

const ListPayrollsSkeleton = () => {
  return (
    <Skeleton.Page>
      <Card sectioned>
        <Skeleton.TagList />
      </Card>
      <Card sectioned>
        <Skeleton.TagList />
      </Card>
      <Card sectioned>
        <Skeleton.TagList />
      </Card>
    </Skeleton.Page>
  );
};

export default ListPayrollsSkeleton;

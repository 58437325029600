import { Stack } from '@mui/material';

import { PaymentFailIcon } from '@/assets/icons';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import { Heading, Body } from '@/components/Typography';

type Props = { invId: string; hideModal: () => void };

const PaymentFailModal = ({ invId, hideModal }: Props) => {
  return (
    <Modal
      disableClickout
      size="xs"
      title="Payment Failed"
      wrapperBackground={true}
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Stack alignItems={'center'} gap={1} sx={{ mb: 2 }}>
          <PaymentFailIcon sx={{ width: '70px', height: '70px' }} />
          <Heading>Payment has been failed!</Heading>
          <Body>{`Your payment has not been proceed for invoice #${invId}.`}</Body>
          <Body>Check your payment method and try again later.</Body>
        </Stack>
        <Button
          a11yLabel="Close"
          id="close-btn"
          style={{ width: '100%' }}
          onClick={hideModal}
        />
      </Card.Section>
    </Modal>
  );
};

export default PaymentFailModal;

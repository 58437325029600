import useModal from '@area2k/use-modal';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';

import { UpdateInterviewStatusModal } from '../modals';

import Card from '@/components/Card';
import EmptyState from '@/components/EmptyState';
import LoadingState from '@/components/LoadingState';
import ErrorEmptyState from '@/components/QueryEmptyState/ErrorEmptyState';
import Stack from '@/components/Stack';
import { Subheading } from '@/components/Typography';
import Icon from '@/elements/Icon';
import { useGetShowcaseLinkQuery } from '@/graphql';
import { GenericActionOrList } from '@/types';
import {
  GetWorkerQuery,
  WilloWorkerInterviewStatusEnum,
  InterviewStatusEnum,
  Maybe,
} from '@/types/graphql';
import { parseInterViewQuestionarieStatus } from '@/util/worker';

type Props = {
  worker: GetWorkerQuery['worker'];
  isWorkerInProgress?: boolean;
};

const Interview = ({ worker, isWorkerInProgress = true }: Props) => {
  const query = useGetShowcaseLinkQuery({
    variables: {
      workerId: worker.id,
      typeInterview: WilloWorkerInterviewStatusEnum.ONBOARDING,
    },
  });
  const [showUpdateInterviewStatusModal, hideUpdateInterviewStatusModal] =
    useModal<{ interviewId: string }>(
      ({ interviewId }) => (
        <UpdateInterviewStatusModal
          hideModal={hideUpdateInterviewStatusModal}
          interviewId={interviewId}
          worker={worker}
        />
      ),
      []
    );
  const cardActions = useMemo<GenericActionOrList[] | undefined>(
    () =>
      query.data && query.data.willoWorkerInterview
        ? [
            ...(isWorkerInProgress
              ? [
                  {
                    a11yLabel: 'Approve or reject interview',
                    label: 'Approve/Reject',
                    onAction: () =>
                      showUpdateInterviewStatusModal({
                        interviewId: query.data!.willoWorkerInterview!.id,
                      }),
                  },
                ]
              : []),
            {
              a11yLabel: 'View interview',
              label: 'View',
              href: query.data!.willoWorkerInterview!.showcaseLink!,
              external: true,
              id: 'view-interview',
            },
          ]
        : undefined,
    [query.data]
  );

  return (
    <Card actions={cardActions} title="Video Questionnaire">
      <InterviewCardContent
        {...{ query, workerInterviewStatus: worker.interviewStatus }}
      />
    </Card>
  );
};

const InterviewCardContent = ({
  query,
  workerInterviewStatus,
}: {
  query: ReturnType<typeof useGetShowcaseLinkQuery>;
  workerInterviewStatus: Maybe<InterviewStatusEnum>;
}) => {
  if (query.loading) {
    return (
      <Stack css={{ padding: '32px 0', marginTop: '8px' }} justify="center">
        <LoadingState text="Loading video questionnaire status..." />
      </Stack>
    );
  }

  if (query.error) {
    return <ErrorEmptyState error={query.error} />;
  }

  if (!query.data) {
    return null;
  }

  const { willoWorkerInterview } = query.data;

  if (!willoWorkerInterview) {
    return (
      <EmptyState
        text="Pending GravyWorker's video questionnaire submission"
        title="No submitted video response"
      />
    );
  }

  return (
    <Stack
      vertical
      align="center"
      css={{ padding: '32px 0', marginTop: '8px' }}
      justify="center"
    >
      <Icon fixedWidth icon={faVideo} size="3x" />
      <Stack gap={4} justify="center">
        <Subheading>Status:</Subheading>
        <Subheading>
          {parseInterViewQuestionarieStatus(workerInterviewStatus)}
        </Subheading>
      </Stack>
    </Stack>
  );
};

export default Interview;

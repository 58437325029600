/* eslint no-underscore-dangle: 0 */
import { FieldContext, SubmitHelpers } from '@area2k/use-form';
import { useEffect, useState } from 'react';

import { useInvoiceGeneratorActions } from '../context';

import AccountSelectField from './AccountSelectField';
import CustomerAutocompleteField from './CustomerAutocompleteField';

import Button from '@/components/Button';
import Card from '@/components/Card';
import LoadingState from '@/components/LoadingState';
import Stack from '@/components/Stack';
import Form from '@/form';
import { useGetCustomerLazyQuery } from '@/graphql';
import useMediaQuery from '@/hooks/useMediaQuery';
import { Maybe } from '@/types';
import { Account, Customer } from '@/types/graphql';

type FormValues = {
  customer: Maybe<Customer>;
  account: Maybe<Account>;
};

type Props = {
  clientId: number;
  accountId: number;
  resetQueryParams: () => void;
};

const Billing = ({ clientId, accountId, resetQueryParams }: Props) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const { setBilling } = useInvoiceGeneratorActions();
  const [formValues, setFormValues] = useState<FormValues>({
    customer: null,
    account: null,
  });

  const [fetchGetCustomer, { data: customerData, loading }] =
    useGetCustomerLazyQuery();

  const handleFormValuesChange = <T extends unknown>(
    fieldContext: FieldContext<T>,
    fieldId: keyof FormValues
  ) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldId]: fieldContext.value,
    }));
  };

  useEffect(() => {
    if (clientId && accountId) {
      fetchGetCustomer({
        variables: { customerId: clientId },
      });
    }
  }, [clientId]);

  useEffect(() => {
    if (customerData) {
      const { customer } = customerData;
      const account = customer.accounts.filter(
        (x) => x.id.toString() === accountId?.toString()
      );
      if (customer && account.length) {
        setBilling({
          account: account[0],
          customer,
        });
      } else {
        resetQueryParams();
      }
    }
  }, [customerData]);

  const handleSubmit = async (
    values: FormValues,
    { setFormError }: SubmitHelpers
  ) => {
    if (formValues.account && formValues.customer) {
      setBilling({
        account: formValues.account,
        customer: formValues.customer,
      });
    } else {
      setFormError('incomplete', {
        title: 'Please complete all fields',
        message: 'All fields are required to continue.',
      });
    }
  };

  if (loading) {
    return (
      <Card sectioned noRadius={phoneOnly}>
        <LoadingState text="Loading..." />
      </Card>
    );
  }

  return (
    <Card sectioned noRadius={phoneOnly} title="Client Selection">
      <Form initialValues={formValues} onSubmit={handleSubmit}>
        <CustomerAutocompleteField
          callback={(fieldContext) => {
            handleFormValuesChange(fieldContext, 'customer');
          }}
          fieldId="customer"
        />

        <AccountSelectField
          accountCallback={(fieldContext) => {
            handleFormValuesChange(fieldContext, 'account');
          }}
          customer={formValues.customer}
          fieldId="account"
          label="Billing Account*"
        />

        <Stack justify="end">
          <Button
            a11yLabel="Submit"
            disabled={!formValues.customer || !formValues.account}
            id="btn-start-invoice-generator"
            label="Start"
            type="submit"
          />
        </Stack>
      </Form>
    </Card>
  );
};

export default Billing;

import { useReactiveVar } from '@apollo/client';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useState, useCallback } from 'react';
import { useModal } from 'react-modal-hook';

import { CandidateItem } from '../helpers';

import { LinkWrapper } from './styles';

import Alert from '@/components/Alert';
import Autocomplete from '@/components/Autocomplete';
import Button from '@/components/Button';
import Card from '@/components/Card';
import ConfirmationModal from '@/components/ConfirmationModal';
import EditAddress, { TypeEditorEnum } from '@/components/EditAddress';
import ExperienceForm from '@/components/ExperienceForm';
import FormElement from '@/components/FormElement';
import Modal from '@/components/Modal';
import Option from '@/components/Option';
import Stack from '@/components/Stack';
import TagList from '@/components/TagList';
import { Small } from '@/components/Typography';
import Form from '@/form';
import FormColumns from '@/form/FormColumns';
import MaskedInputField from '@/form/MaskedInputField';
import TextAreaField from '@/form/TextAreaField';
import TextField from '@/form/TextField';
import TextSelectField from '@/form/TextSelectField';
import {
  useAddCommentMutation,
  useAddWorkerReferenceMutation,
  useAddWorkerSkillMutation,
  useBlockWorkerMutation,
  useListAgencySkillsQuery,
  useRemoveWorkerSkillMutation,
  useUpdateCandidateMutation,
} from '@/graphql';
import useDebouncedValue from '@/hooks/useDebouncedValue';
import useGetSortSkillItems from '@/hooks/useGetSortSkillItems';
import { SkillItem } from '@/types';
import {
  CandidateStatusEnum,
  CommentableEnum,
  GetWorkerQuery,
  LanguageEnum,
  ListCandidatesQuery,
  Maybe,
  PointInput,
  SmartphoneTypeEnum,
  WorkerReference,
} from '@/types/graphql';
import { currentAgencyVar } from '@/util/apollo/cache';
import { CONFIRM_MSG } from '@/util/constants';

type Props = {
  experiences: WorkerReference[];
  hideModal: () => void;
  candidate: CandidateItem;
};

type FormValues = {
  active: boolean;
  disableReason: string;
  candidateStatus: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phoneNumber: Maybe<string>;
  language: LanguageEnum;
  secondaryLanguage: LanguageEnum;
  smartphoneType: SmartphoneTypeEnum;
  dateOfBirth: GetWorkerQuery['worker']['user']['dateOfBirth'];
  deactivatedByUser: ListCandidatesQuery['agency']['candidates']['items'][0]['deactivatedByUser'];
};

export type AddressValues = {
  addressLine1: Maybe<string>;
  addressLine2: Maybe<string>;
  city: Maybe<string>;
  state: Maybe<string>;
  zip: Maybe<string>;
  coords: Maybe<PointInput>;
};

const languageOptions = [
  { label: 'English', value: LanguageEnum.ENGLISH },
  { label: 'Spanish', value: LanguageEnum.SPANISH },
];

const statusOptions = [
  { label: 'Verified', value: CandidateStatusEnum.VERIFIED },
  { label: 'Unverified', value: CandidateStatusEnum.UNVERIFIED },
];

const smartPhoneTypeOptions = [
  { label: 'Android', value: SmartphoneTypeEnum.ANDROID },
  { label: 'IOS', value: SmartphoneTypeEnum.IOS },
  { label: 'Other', value: SmartphoneTypeEnum.OTHER },
];

const EditCandidateModal = ({ candidate, experiences, hideModal }: Props) => {
  const [formValues, setFormValues] = useState<FormValues>({
    active: Boolean(candidate.active),
    disableReason: candidate.disableReason || '',
    candidateStatus: candidate.candidateStatus,
    firstName: candidate.user.firstName,
    middleName: candidate.user.middleName ?? '',
    lastName: candidate.user.lastName,
    email: candidate.user.email,
    phoneNumber: candidate.user.phoneNumber,
    language: candidate.user.language,
    secondaryLanguage: candidate.user.secondaryLanguage!,
    dateOfBirth: candidate.user.dateOfBirth!
      ? new Date(candidate.user.dateOfBirth!).toISOString().split('T')[0]
      : null,
    smartphoneType: candidate.user.smartphoneType || SmartphoneTypeEnum.OTHER,
    deactivatedByUser: candidate.deactivatedByUser,
  });

  const [invitedToApply, setInvitedToApply] = useState<boolean>(false);
  const [comment, setComment] = useState<string>(
    candidate.comments.length > 0
      ? candidate.comments[candidate.comments.length - 1].body
      : ''
  );

  const [addressValues, setAddressValues] = useState<AddressValues>({
    addressLine1: candidate.addressLine1,
    addressLine2: candidate.addressLine2,
    city: candidate.city,
    state: candidate.state,
    zip: candidate.zip,
    coords: candidate.coords,
  });

  const [error, setError] = useState({
    active: false,
    obj: {},
  });
  const [query, setQuery] = useState('');
  const [skills, setSkills] = useState<SkillItem[]>(candidate.skills);
  const [references, setReferences] = useState<WorkerReference[]>(experiences);
  const [deleteQueue, setDeleteQueue] = useState<string[]>([]);
  const [initialReferenceLength] = useState<number>(
    candidate.references ? candidate.references.length : 0
  );

  const initialComment =
    candidate.comments.length > 0
      ? candidate.comments[candidate.comments.length - 1].body
      : '';

  const debouncedQuery = useDebouncedValue(query);
  const currentAgency = useReactiveVar(currentAgencyVar);
  const [blockWorker] = useBlockWorkerMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(currentAgency!),
        fields: { candidates() {} },
      });
    },
  });

  const [showConfirmationModal, hideConfirmationModal] = useModal(
    () => (
      <ConfirmationModal
        acceptAction={handleSubmit}
        acceptButtonLabel="Confirm"
        bodyContentText={CONFIRM_MSG}
        denyAction={() => {}}
        denyButtonLabel="Cancel"
        hideModal={hideConfirmationModal}
        title={`Confirmation`}
      />
    ),
    [
      formValues,
      addressValues,
      comment,
      invitedToApply,
      initialComment,
      references,
      initialReferenceLength,
    ]
  );

  const blockWorkerAction = async (applicant) => {
    try {
      await blockWorker({
        variables: {
          workerId: applicant.id,
          reason: applicant.disableReason,
          active: applicant.active,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const { data } = useListAgencySkillsQuery({
    variables: { agencyId: currentAgency!.id },
  });

  const updateCache = {
    update: (cache) => {
      cache.modify({
        id: cache.identify(currentAgency!),
        fields: { candidates() {} },
      });
    },
  };

  const [addSkill] = useAddWorkerSkillMutation(updateCache);
  const [removeSkill] = useRemoveWorkerSkillMutation(updateCache);
  const [updateCandidate] = useUpdateCandidateMutation(updateCache);
  const [addComment] = useAddCommentMutation(updateCache);
  const [addReference] = useAddWorkerReferenceMutation(updateCache);

  const { items, sortedWorkerSkills } = useGetSortSkillItems(
    data,
    skills,
    debouncedQuery
  );

  const handleRemove = useCallback(
    async (index: number) => {
      try {
        const trueIndex = skills.findIndex(
          (skill) => skill.id === sortedWorkerSkills[index].id
        );
        const skillsCopy = [...skills];
        skillsCopy.splice(trueIndex, 1);
        setSkills([...skillsCopy]);
        await removeSkill({
          variables: {
            skillId: sortedWorkerSkills[index].id,
            workerId: candidate.id,
          },
        });
      } catch (_error) {
        // Can do a console.log(), needs to be improved, not for this ticket
        // We need a new one for refactors
        // console.log(error)
      }
    },
    [candidate, skills, sortedWorkerSkills]
  );

  const handleSelect = useCallback(
    async (item) => {
      try {
        setSkills([...skills, item.selectedItem]);
        await addSkill({
          variables: {
            skillId: item.selectedItem.id,
            workerId: candidate.id,
          },
        });
      } catch (_error) {
        // Can do a console.log(), needs to be improved, not for this ticket
        // We need a new one for refactors
        // console.log(error)
      }
    },
    [candidate, skills]
  );
  const submitReferences = useCallback(async () => {
    try {
      references.map(async (reference) => {
        if (reference.id === undefined) {
          await addReference({
            variables: {
              ...reference,
              workerId: candidate.id,
            },
          });
        }
      });
    } catch (error) {
      setError({ active: true, obj: error });
    }
  }, [references]);

  const handleFormValuesChange = ({ value }, id) => {
    setFormValues({ ...formValues, [id]: value });
  };

  const handleSubmit = useCallback(async () => {
    const finalStatus: CandidateStatusEnum = invitedToApply
      ? CandidateStatusEnum.APPLIED
      : (formValues.candidateStatus as CandidateStatusEnum);

    try {
      if (references.length > 0 && references.length !== initialReferenceLength)
        await submitReferences();

      await blockWorkerAction({
        id: candidate.id,
        active: formValues.active,
        disableReason: formValues.disableReason,
      });
      updateCandidate({
        variables: {
          ...formValues,
          ...addressValues,
          workerId: candidate.id,
          candidateStatus: finalStatus,
        },
      });
      if (comment !== initialComment)
        await addComment({
          variables: {
            commentableId: candidate.id,
            commentableType: CommentableEnum.WORKER,
            body: comment,
          },
        });
      hideModal();
    } catch (error) {
      setError({ active: true, obj: error });
    }
  }, [
    formValues,
    addressValues,
    comment,
    invitedToApply,
    initialComment,
    references,
    initialReferenceLength,
  ]);

  return (
    <Modal
      disableClickout
      size="xl"
      title="Edit Candidate"
      onRequestClose={hideModal}
    >
      <Card.Section>
        {error.active && (
          <Alert
            description={error.obj.message}
            icon={faExclamationTriangle}
            status="danger"
            title="Something went wrong"
          />
        )}

        <Form
          initialValues={{ ...formValues, notes: comment }}
          onSubmit={handleSubmit}
        >
          <TextSelectField
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext, 'candidateStatus');
            }}
            disabled={invitedToApply}
            fieldId="candidateStatus"
            label="Status"
            options={statusOptions}
          />
          <LinkWrapper>
            <Small>----- or ------</Small>
            <Button
              a11yLabel={'invite to apply'}
              appearance="plain"
              css={{ alignSelf: 'flex-start' }}
              label={invitedToApply ? 'Cancel invitation' : 'Invite to apply'}
              status={invitedToApply ? 'danger' : 'theme'}
              type="button"
              onClick={() => {
                setInvitedToApply(!invitedToApply);
              }}
            />
          </LinkWrapper>
          <FormColumns layout="tuple">
            <TextField
              autoFocus
              required
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'firstName');
              }}
              fieldId="firstName"
              label="First Name"
              placeholder="First Name"
            />
            <TextField
              required
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'middleName');
              }}
              fieldId="middleName"
              label="Middle Name"
              placeholder="Middle Name"
            />
            <TextField
              required
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'lastName');
              }}
              fieldId="lastName"
              label="Last Name"
              placeholder="Last Name"
            />
            <TextField
              required
              autoComplete="current-password"
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'dateOfBirth');
              }}
              fieldId="dateOfBirth"
              label="Date of Birth"
              type="date"
            />
          </FormColumns>

          <FormColumns layout="triple">
            <TextField
              autoFocus
              required
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'email');
              }}
              fieldId="email"
              label="Email"
              placeholder="Email"
            />
            <MaskedInputField
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'phoneNumber');
              }}
              fieldId="phoneNumber"
              incompletemessage="Must be a valid phone number"
              label="Phone Number"
              mask="(000) 000-0000"
              placeholder={
                candidate.user.phoneNumber
                  ? candidate.user.phoneNumber
                  : '(555) 555-5555'
              }
              type="tel"
            />
            <TextSelectField
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'smartphoneType');
              }}
              fieldId="smartphoneType"
              label="Phone Type"
              options={smartPhoneTypeOptions}
            />
          </FormColumns>

          <FormColumns layout="single">
            <FormElement htmlFor="" label="Address">
              <EditAddress
                direction="row"
                setValues={setAddressValues}
                typeEditor={TypeEditorEnum.FOR_WORKER}
                values={addressValues}
              />
            </FormElement>
          </FormColumns>

          <FormColumns layout="triple">
            <TextSelectField
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'language');
              }}
              fieldId="language"
              label="Primary language"
              options={languageOptions}
            />
            <TextSelectField
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'secondaryLanguage');
              }}
              fieldId="secondaryLanguage"
              label="Secondary language"
              options={languageOptions}
            />
            <FormElement label="Skills">
              <Autocomplete
                fixedSelectHeight="lg"
                id="skillFather"
                itemToKey={(item) => item.id}
                itemToString={(item) => (item ? item.name : '')}
                items={items}
                placeholder="Search for skills..."
                selectedItem={null}
                onInputValueChange={({ inputValue }) =>
                  setQuery(inputValue || '')
                }
                onSelectedItemChange={handleSelect}
              />
            </FormElement>
          </FormColumns>

          <FormColumns layout="double">
            <TextAreaField
              callback={(fieldContext) => {
                setComment(fieldContext.value);
              }}
              fieldId="notes"
              label="Notes"
              placeholder="Any notes..."
            />
            <FormElement htmlFor="" label="Skills Checked">
              {sortedWorkerSkills.length > 0 && (
                <div style={{ marginBottom: 8 }}>
                  <TagList
                    tags={sortedWorkerSkills.map((skill) => skill.name)}
                    onRemove={handleRemove}
                  />
                </div>
              )}
            </FormElement>
          </FormColumns>
          <Card.Section title="Experience form">
            <ExperienceForm
              deleteExperiences={deleteQueue}
              experiences={references}
              handleAddition={setReferences}
              handleDeletion={setDeleteQueue}
            />
          </Card.Section>
          <FormColumns>
            <FormElement label="Status">
              <Option
                appearance="switch"
                checked={formValues.active}
                id="worker-status"
                label="Account Enabled"
                onChange={() =>
                  handleFormValuesChange(
                    { value: !formValues.active },
                    'active'
                  )
                }
              />

              <FormElement>
                {!formValues.active && formValues.deactivatedByUser && (
                  <>
                    <span>Account has been disabled by </span>
                    <span style={{ fontWeight: 'bold' }}>
                      {[
                        formValues.deactivatedByUser.firstName,
                        formValues.deactivatedByUser.middleName,
                        formValues.deactivatedByUser.lastName,
                      ].join(' ')}
                    </span>
                  </>
                )}
              </FormElement>
            </FormElement>
            {!formValues.active && (
              <FormElement label="Account Disabling Reason">
                <TextAreaField
                  autoFocus
                  callback={(fieldContext) => {
                    handleFormValuesChange(fieldContext, 'disableReason');
                  }}
                  fieldId={'disableReason'}
                  maxLength={250}
                  placeholder="State reason for disabling the worker"
                />
              </FormElement>
            )}
          </FormColumns>
        </Form>
      </Card.Section>
      <Card.Section>
        <Stack justify="end">
          <Button
            a11yLabel="Submit"
            id="btn-submit"
            isLoading={undefined}
            label="Save"
            type="submit"
            onClick={showConfirmationModal}
          />
        </Stack>
      </Card.Section>
    </Modal>
  );
};

export default EditCandidateModal;

import { createSvgIcon } from '@mui/material';

const BlockIcon = createSvgIcon(
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 20C9.11667 20 7.81667 19.7375 6.6 19.2125C5.38333 18.6875 4.325 17.975 3.425 17.075C2.525 16.175 1.8125 15.1167 1.2875 13.9C0.7625 12.6833 0.5 11.3833 0.5 10C0.5 8.61667 0.7625 7.31667 1.2875 6.1C1.8125 4.88333 2.525 3.825 3.425 2.925C4.325 2.025 5.38333 1.3125 6.6 0.7875C7.81667 0.2625 9.11667 0 10.5 0C11.8833 0 13.1833 0.2625 14.4 0.7875C15.6167 1.3125 16.675 2.025 17.575 2.925C18.475 3.825 19.1875 4.88333 19.7125 6.1C20.2375 7.31667 20.5 8.61667 20.5 10C20.5 11.3833 20.2375 12.6833 19.7125 13.9C19.1875 15.1167 18.475 16.175 17.575 17.075C16.675 17.975 15.6167 18.6875 14.4 19.2125C13.1833 19.7375 11.8833 20 10.5 20ZM10.5 18C11.4 18 12.2667 17.8542 13.1 17.5625C13.9333 17.2708 14.7 16.85 15.4 16.3L4.2 5.1C3.65 5.8 3.22917 6.56667 2.9375 7.4C2.64583 8.23333 2.5 9.1 2.5 10C2.5 12.2333 3.275 14.125 4.825 15.675C6.375 17.225 8.26667 18 10.5 18ZM16.8 14.9C17.35 14.2 17.7708 13.4333 18.0625 12.6C18.3542 11.7667 18.5 10.9 18.5 10C18.5 7.76667 17.725 5.875 16.175 4.325C14.625 2.775 12.7333 2 10.5 2C9.6 2 8.73333 2.14583 7.9 2.4375C7.06667 2.72917 6.3 3.15 5.6 3.7L16.8 14.9Z"
      fill="#DC1515"
    />
  </svg>,
  'BlockIcon',
);
export default BlockIcon;

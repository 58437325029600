import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';

import Action from '@/components/Action';
import { Body } from '@/components/Typography';
import Icon from '@/elements/Icon';
import Link from '@/elements/Link';
import { TableCell, TableRow } from '@/elements/Table';
import { mapUrl } from '@/routes/Agency/Customer/AddressList';
import ROUTES from '@/routes/routes';
import styled from '@/styles';
import {
  PayrollItemEarningEnum,
  SimplePayrollItemFragment,
} from '@/types/graphql';
import { centsToCurrency } from '@/util/number';
import { capitalizeText } from '@/util/text';
import { mapTimesheetUpdatedByType } from '@/util/timesheet';

type Props = {
  earning: SimplePayrollItemFragment['earnings'][0];
  id: string;
};

const AccordionButton = styled('button', {
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  padding: '1rem .5rem',
  display: 'block',
  marginRight: 'auto',
});

const SubTableHeading = styled('p', {
  padding: '0',
  marginBottom: '1rem',
  color: '$textLighter',
  fontSize: '$sm',
  fontWeight: '$medium',
  verticalAlign: 'bottom',
});

const PayrollRowItem = ({ earning, id }: Props) => {
  const [isOpen, setOpen] = useState(false);

  const toggleAccordion = () => {
    setOpen((prev) => !prev);
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <Link
            to={`/${ROUTES.orders}/${earning.timesheet?.job.order.id}/jobs/${earning.timesheet?.job.id}`}
          >
            <Body color="inherit" weight="medium">
              #{earning.timesheet?.job.order.id}
            </Body>
          </Link>
        </TableCell>
        <TableCell>
          <Link to={`/${ROUTES.clients}/${earning.timesheet?.job.customer.id}`}>
            <Body color="inherit" weight="medium">
              {earning.timesheet?.job.customer.name}
            </Body>
          </Link>
        </TableCell>
        <TableCell>
          {capitalizeText(earning.earningType.toLowerCase())}
        </TableCell>
        <TableCell>
          {earning.earningType !== PayrollItemEarningEnum.PAYCHECK_TIPS
            ? earning.hours
            : 'N/A'}
        </TableCell>
        <TableCell>{earning.amount}</TableCell>
        <TableCell>
          {earning.timesheet?.updatedBy ? (
            <React.Fragment>
              {earning.timesheet?.updatedBy.firstName}{' '}
              {earning.timesheet?.updatedBy.lastName}
            </React.Fragment>
          ) : (
            mapTimesheetUpdatedByType(earning.timesheet)
          )}
        </TableCell>
        <TableCell>
          <AccordionButton type="button" onClick={toggleAccordion}>
            <Icon fixedWidth icon={isOpen ? faChevronUp : faChevronDown} />
          </AccordionButton>
        </TableCell>
      </TableRow>
      {isOpen && (
        <TableRow key={id} id={id}>
          <TableCell />
          <TableCell>
            <SubTableHeading>Description</SubTableHeading>
            {earning.description}
          </TableCell>
          <TableCell>
            <SubTableHeading>Account</SubTableHeading>
            <Link
              to={`/${ROUTES.clients}/${earning.timesheet?.job.customer.id}/accounts/${earning.timesheet?.job.customer.id}`}
            >
              <Body color="inherit" weight="medium">
                {earning.timesheet?.job.account.name}
              </Body>
            </Link>
          </TableCell>
          <TableCell>
            <SubTableHeading>Workplace</SubTableHeading>
            <Action.Button
              action={{
                a11yLabel: `Open new tab to ${mapUrl(earning.address)}`,
                label: `${earning.address?.addressLine1!}, 
                ${earning.address?.state}`,
                href: mapUrl(earning.address),
                external: true,
              }}
            />
          </TableCell>
          <TableCell>
            <SubTableHeading>Pay Rate</SubTableHeading>
            {centsToCurrency(earning.timesheet?.payRate ?? 0)}
          </TableCell>
          <TableCell align="center">
            <SubTableHeading>Tip Amount</SubTableHeading>
            {earning.timesheet?.tipAmount ?? 0}
          </TableCell>
          <TableCell />
          <TableCell />
        </TableRow>
      )}
    </React.Fragment>
  );
};

export default PayrollRowItem;

import { createSvgIcon } from '@mui/material';

const InactiveCrownIcon = createSvgIcon(
  <svg
    fill="none"
    height="24"
    viewBox="0 0 25 24"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12.5" cy="12" fill="#9BA5B5" r="12" />
    <path
      d="M6.67316 16.7824C6.6907 16.8563 6.72301 16.9259 6.76815 16.9871C6.8133 17.0482 6.87035 17.0996 6.93586 17.1381C7.00137 17.1766 7.074 17.2014 7.14937 17.2111C7.22474 17.2208 7.30129 17.2152 7.37443 17.1946C10.7276 16.2687 14.2689 16.2684 17.6222 17.1936C17.6953 17.2142 17.7718 17.2198 17.8472 17.2101C17.9225 17.2004 17.9951 17.1756 18.0606 17.1371C18.1261 17.0986 18.1831 17.0473 18.2283 16.9862C18.2734 16.9251 18.3057 16.8555 18.3233 16.7816L20.1155 9.16557C20.1395 9.06361 20.1345 8.95698 20.1012 8.85769C20.0678 8.75839 20.0074 8.67038 19.9268 8.60355C19.8461 8.53673 19.7484 8.49376 19.6446 8.47948C19.5409 8.46519 19.4352 8.48016 19.3395 8.5227L15.7828 10.1034C15.6544 10.1605 15.5092 10.1675 15.3759 10.1229C15.2427 10.0783 15.1309 9.98545 15.0626 9.8626L12.9918 6.13503C12.9431 6.04734 12.8718 5.97427 12.7853 5.9234C12.6989 5.87253 12.6004 5.8457 12.5001 5.8457C12.3997 5.8457 12.3013 5.87253 12.2148 5.9234C12.1283 5.97427 12.0571 6.04734 12.0083 6.13503L9.93747 9.8626C9.86922 9.98545 9.75745 10.0783 9.62416 10.1229C9.49088 10.1675 9.34573 10.1605 9.21731 10.1034L5.66009 8.52246C5.5644 8.47993 5.45873 8.46496 5.35498 8.47923C5.25124 8.4935 5.15354 8.53645 5.07288 8.60324C4.99222 8.67003 4.93181 8.75801 4.89845 8.85728C4.86509 8.95654 4.8601 9.06315 4.88404 9.1651L6.67316 16.7824Z"
      fill="white"
    />
    <path
      d="M10.25 14.368C11.7459 14.2107 13.2541 14.2107 14.75 14.368"
      stroke="#9BA5B5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'InactiveCrownIcon'
);
export default InactiveCrownIcon;

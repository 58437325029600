import styled from '@/styles';

const Layout = styled('div', {
  padding: '112px 32px',
  overflowY: 'auto',
  '@phoneOnly': {
    paddingLeft: '24px',
    paddingRight: '24px',
  },
});

Layout.displayName = 'stitches(JobEditor.Layout)';

export default Layout;

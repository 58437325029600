import useModal from '@area2k/use-modal';
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

import UpdateContactModal from './UpdateContactModal';

import Avatar from '@/components/Avatar';
import Card from '@/components/Card';
import Stack from '@/components/Stack';
import StackContainer from '@/components/StackContainer';
import { Body, Small } from '@/components/Typography';
import Icon from '@/elements/Icon';
import { Prettify } from '@/types';
import { GetJobQuery, JobStatusEnum } from '@/types/graphql';
import '../index.css';

type Props = {
  job: Prettify<GetJobQuery['job']>;
};

const ContactSection = ({ job }: Props) => {
  const [showCreateModal, hideCreateModal] = useModal(
    () => <UpdateContactModal hideModal={hideCreateModal} job={job} />,
    [job]
  );
  const emailAndPhoneColor = '#000000';
  const noteColor = '#6D6D6D';
  const { contact, contactInstructions, status } = job;

  return (
    <Card.Section
      actions={
        status === JobStatusEnum.UPCOMING
          ? [
              {
                a11yLabel: 'Change shift supervisor',
                label: 'Change',
                onAction: showCreateModal,
              },
            ]
          : undefined
      }
      styleHeading={true}
      title="Shift Supervisor"
    >
      <StackContainer className="scrollbar">
        <Stack vertical gap={12}>
          <Stack gap={20}>
            <div>
              <Avatar
                firstName={contact.user.firstName}
                size="md"
                src={contact.avatarUrl}
              />
            </div>
            <Body>
              {contact.user.firstName} {contact.user.lastName}
            </Body>
          </Stack>
          <Small css={{ color: emailAndPhoneColor }}>
            <Icon icon={faEnvelope} style={{ color: '#808B9D' }} />
            &nbsp; {contact.user.email}
          </Small>
          <Small css={{ color: emailAndPhoneColor }}>
            <Icon icon={faPhoneAlt} style={{ color: '#808B9D' }} />
            &nbsp; {contact.user.phoneNumber}
          </Small>
          {contactInstructions && (
            <Small css={{ color: noteColor }}>
              Note: {contactInstructions}
            </Small>
          )}
        </Stack>
      </StackContainer>
    </Card.Section>
  );
};

export default ContactSection;

import TableRow from './TableRow';

import styled from '@/styles';

const TableHead = styled('thead', {
  [`> ${TableRow}`]: {
    boxShadow: 'inset 0 -1px 0 0 $colors$neutralA8',

    '&:hover': {
      backgroundColor: 'initial',
    },
  },
});

TableHead.displayName = 'stitches(Table.TableHead)';

export default TableHead;

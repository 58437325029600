import { useReactiveVar } from '@apollo/client';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { Body } from '@/components/Typography';
import { ListPayrollsDocument, useDeletePayrollMutation } from '@/graphql';
import { Payroll } from '@/types/graphql';
import { currentTenantVar } from '@/util/apollo/cache';

type Props = {
  payrollId: Payroll['id'];
  hideModal: () => void;
};

const ConfirmDeletePayrollModal = ({ payrollId, hideModal }: Props) => {
  const currentTenant = useReactiveVar(currentTenantVar);
  const tenantId = currentTenant!.id;
  const navigate = useNavigate();
  const [error, setError] = useState<boolean>(false);

  const [deletePayroll, { loading: isLoading }] = useDeletePayrollMutation({
    refetchQueries: [{ query: ListPayrollsDocument, variables: { tenantId } }],
  });

  const handleDeletePayroll = async () => {
    try {
      await deletePayroll({
        variables: {
          payrollId,
        },
      });
      navigate('/payrolls');
    } catch (err) {
      setError(true);
    }
  };

  return (
    <Modal
      disableClickout
      size="sm"
      title="Are you sure you want to delete this payroll?"
      onRequestClose={hideModal}
    >
      <Card.Section>
        {error && (
          <Alert
            description="Something went wrong, please contact support"
            icon={faExclamationTriangle}
            status="danger"
            title="An error has occurred"
          />
        )}
        <Body>
          To see this payroll again, you would have to run new payroll.
        </Body>
        <Stack css={{ marginTop: '10px' }} gap={10} justify="end">
          <Button
            a11yLabel="deny"
            appearance="outline"
            label="Cancel"
            status="danger"
            type="button"
            onClick={hideModal}
          />

          <Button
            a11yLabel="accept"
            isLoading={isLoading}
            label="Delete"
            type="submit"
            onClick={handleDeletePayroll}
          />
        </Stack>
      </Card.Section>
    </Modal>
  );
};

export default ConfirmDeletePayrollModal;

import useModal from '@area2k/use-modal';
import { faPen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import qs from 'query-string';
import { useEffect, useState } from 'react';

import CreateAddressModal from './CreateAddressModal';
import UpdateAddressModal from './UpdateAddressModal';

import Action from '@/components/Action';
import ActiveCard from '@/components/ActiveCard';
import Badge from '@/components/Badge';
import Card from '@/components/Card';
import ConfirmationModal from '@/components/ConfirmationModal';
import EmptyState from '@/components/EmptyState';
import IconicButton from '@/components/IconicButton';
import Table from '@/components/Table';
import TextStack from '@/components/TextStack';
import { Body, Small } from '@/components/Typography';
import { TableCell, TableRow } from '@/elements/Table';
import { GetCustomerQueryResult, useDeleteAddressMutation } from '@/graphql';
import { GetCustomerQuery } from '@/types/graphql';

type Item = GetCustomerQuery['customer']['addresses'][0];

type Props = {
  customer: GetCustomerQuery['customer'];
  accordion?: boolean;
  refetchGetCustomer: GetCustomerQueryResult['refetch'];
};

export const mapUrl = (item: unknown) => {
  const addressItem = item as Item;

  const params = {
    query: `${addressItem.addressLine1},${addressItem.city},${addressItem.state}`,
  };
  return `https://www.google.com/maps/search/?api=1&${qs.stringify(params)}`;
};

const AddressList = ({
  customer,
  accordion = false,
  refetchGetCustomer,
}: Props) => {
  const [addresses, setAddresses] = useState<Item[]>([]);

  useEffect(() => {
    const listAddresses = [...customer.addresses].sort((x) =>
      x.active ? -1 : 1,
    );

    setAddresses(listAddresses);
  }, [customer.addresses]);

  const [deleteAddress] = useDeleteAddressMutation();

  const [showCreateModal, hideCreateModal] = useModal(
    () => (
      <CreateAddressModal customer={customer} hideModal={hideCreateModal} />
    ),
    [customer],
  );

  const [showUpdateModal, hideUpdateModal] = useModal<Item>(
    (address) => (
      <UpdateAddressModal
        address={address}
        hideModal={hideUpdateModal}
        refetchGetCustomer={refetchGetCustomer}
      />
    ),
    [customer],
  );

  const [showConfirmationModal, hideConfirmationModal] = useModal<Item>(
    (address) => (
      <ConfirmationModal
        acceptAction={async () => {
          await deleteAddress({ variables: { addressId: address.id } });
          refetchGetCustomer();
        }}
        acceptButtonLabel={'Yes'}
        bodyContentText={'Are you sure you want to delete this address ?'}
        denyAction={hideConfirmationModal}
        denyButtonLabel={'No'}
        hideModal={hideConfirmationModal}
        title="Confirm"
      />
    ),
    [customer],
  );

  const renderItem = (item: Item, index: number) => (
    <TableRow
      key={`${item.id}${index}`}
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <TableCell>
        <TextStack>
          {item.active ? (
            <Action.Button
              action={{
                a11yLabel: `Open new tab to ${mapUrl(item)}`,
                label: item.addressLine1!,
                href: mapUrl(item),
                external: true,
              }}
            />
          ) : (
            <Body color={'lightest'}>{item.addressLine1}</Body>
          )}

          {item.addressLine2 && (
            <Small color={item.active ? 'default' : 'lightest'}>
              {item.addressLine2}
            </Small>
          )}
          <Small color={item.active ? 'default' : 'lightest'}>
            {item.city}, {item.state} {item.zip}
          </Small>
        </TextStack>
      </TableCell>
      <TableCell
        style={{
          display: 'flex',
          alignItems: 'center',
          alignContent: 'space-around',
        }}
      >
        {!item.active && (
          <Badge
            label={'Rejected'}
            size="md"
            status={'danger'}
            style={{ marginRight: '10px', padding: '4px 10px' }}
          />
        )}
        {item.jobsCount === 0 && item.jobsCount !== null && (
          <IconicButton
            a11yLabel="delete address"
            appearance="plain"
            icon={faTrashAlt}
            id={`btn-delete-address-${item.id}`}
            size="md"
            onClick={() => {
              showConfirmationModal(item);
            }}
          />
        )}
        <IconicButton
          a11yLabel="update address"
          appearance="plain"
          disabled={!item.active}
          icon={faPen}
          id={`btn-update-address-${item.id}`}
          size="md"
          style={
            !item.active
              ? {
                  backgroundColor: 'transparent',
                  color: '#d3d3d3',
                }
              : {}
          }
          onClick={() => {
            showUpdateModal(item);
          }}
        />
      </TableCell>
    </TableRow>
  );

  return (
    <ActiveCard active={true}>
      <Card
        accordion={accordion}
        actions={[
          {
            a11yLabel: 'Create a new address',
            label: 'Create',
            onAction: showCreateModal,
            id: 'create-address',
          },
        ]}
        open={
          !(
            customer.accounts.some((el) => el.currentPaymentMethod) &&
            customer.accounts.length > 0
          )
        }
        title="Addresses"
      >
        {addresses.length > 0 ? (
          <Table>
            <tbody>{addresses.map(renderItem)}</tbody>
          </Table>
        ) : (
          <EmptyState
            text="Add a new address by clicking the button above"
            title="No addresses"
          />
        )}
      </Card>
    </ActiveCard>
  );
};

export default AddressList;

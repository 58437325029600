import { Stack } from '@mui/material';

import { Container } from './styles';

import Avatar from '@/components/Avatar';
import { Subheading } from '@/components/Typography';
import Text from '@/elements/Text';
import { JobWorkerItemFragment } from '@/types/graphql';
import { checkGravyCertificationByName } from '@/util/worker';
import './index.css';

type WorkerCertificatesSectionProps = {
  activeCertificates: JobWorkerItemFragment['worker']['activeCertificates'];
};

const WorkerCertificatesSection = ({
  activeCertificates,
}: WorkerCertificatesSectionProps) => {
  return (
    <>
      {activeCertificates.length > 0 && (
        <>
          <Subheading
            css={{ padding: '4px 20px 10px 20px', fontSize: '20px' }}
            weight="semibold"
            width="100%"
          >
            Credentials
          </Subheading>
          <Stack marginBottom="10px" marginTop="10px">
            <Stack
              className="worker-profile-dialog-skills-section"
              flexDirection="row"
              gap="28px"
              paddingLeft="30px"
              width="100%"
            >
              {activeCertificates.map((certificate, idx) => {
                const isGravyCertified = checkGravyCertificationByName(
                  certificate.name!
                );
                return (
                  <Stack key={idx} gap="10px">
                    <Container>
                      <Avatar
                        css={{
                          width: '80px',
                          height: '80px',
                          border: isGravyCertified ? '' : '2px solid #D3D3D3',
                        }}
                        data-testid={`certificate-avatar-${idx}`}
                        rounded={!isGravyCertified}
                        src={certificate?.imageUrl ?? ''}
                      />
                    </Container>
                    <Text
                      css={{
                        textAlign: 'center',
                        color: '#262626',
                        width: '100px',
                        overflowWrap: 'break-word',
                        right: '6px',
                      }}
                      size="md"
                    >
                      {certificate.name!}
                    </Text>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </>
      )}
    </>
  );
};

export default WorkerCertificatesSection;

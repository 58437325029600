import { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import LoadingState from '@/components/LoadingState';
import {
  useGetActiveRegionStatesLazyQuery,
  useGetTenantByKeyQuery,
} from '@/graphql';
import { activeRegionStatesVar, currentTenantVar } from '@/util/apollo/cache';

const subdomain =
  process.env.SUB_DOMAIN || window.location.hostname.split('.')[0];
const Root = () => {
  const query = useGetTenantByKeyQuery({
    variables: { key: subdomain },
  });
  const [getActiveStates, { data }] = useGetActiveRegionStatesLazyQuery();

  useEffect(() => {
    if (query.data) {
      currentTenantVar(query.data.tenantByKey);
      getActiveStates({
        variables: { tenantId: query.data.tenantByKey!.id },
      });
    }

    return () => {
      currentTenantVar(null);
    };
  }, [query.data]);

  useEffect(() => {
    if (data) {
      activeRegionStatesVar(data.activeRegionState);
    }
  }, [data]);

  if (query.loading) {
    return <LoadingState text="Loading the application..." />;
  }

  if (query.error || (query.data && !query.data.tenantByKey)) {
    return <Navigate replace to="/invalid-tenant" />;
  }

  return <Outlet />;
};

export default Root;

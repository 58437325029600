import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { StyledPage } from '../SignUp/styles';
import routes from '../routes';

import Button from '@/components/Button';
import Stack from '@/components/Stack';
import { Small, Title } from '@/components/Typography';
import { V3Endpoints } from '@/constants/urls';
import axiosClient from '@/util/axios/axiosClient';

const RequestAppAccess = () => {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const requestAccess = () => {
    if (state) {
      setLoading(true);
      axiosClient
        .post(V3Endpoints.REQUEST_APP_ACCESS, {
          email: state?.email,
        })
        .then(() => {
          navigate(routes.waitlist);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <StyledPage
      css={{ alignItems: 'flex-start', paddingTop: '132px' }}
      size="xs"
    >
      <Stack vertical gap={24}>
        <Stack vertical gap={66}>
          <Stack vertical gap={2}>
            <Title size={'xxxl'} weight={'normal'}>
              Join the waitlist!
            </Title>
            <Small color="default" size="md">
              {`GravyWork for Business is invite-only - we're still working on the
              recipe.`}
            </Small>
          </Stack>
          <Stack vertical gap={2}>
            <Small color="lightest" size="md">
              {`We don't see an account for:`}
            </Small>
            <Small size="md">{state?.email}</Small>
          </Stack>
        </Stack>
        <Button
          a11yLabel="Request Access"
          id="sign-in-btn"
          isLoading={loading}
          loadingLabel="Requesting..."
          style={{ width: '100%' }}
          onClick={() => requestAccess()}
        />
      </Stack>
    </StyledPage>
  );
};

export default RequestAppAccess;

import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { useModal } from 'react-modal-hook';

import WorkerFiltersModal from './WorkerFiltersModal';

import Button from '@/components/Button';
import IconicButton from '@/components/IconicButton';
import Stack from '@/components/Stack';
import TextInput from '@/components/TextInput';
import ButtonGroup from '@/elements/ButtonGroup';
import useMediaQuery from '@/hooks/useMediaQuery';
import breakpoints from '@/styles/breakpoints';
import { WorkerFilterSetInput, Scalars } from '@/types/graphql';

type Props = {
  filters: WorkerFilterSetInput;
  query: Scalars['String'];
  onQueryChange: (query: Scalars['String']) => Scalars['Void'];
  onChangeFilter: <K extends keyof WorkerFilterSetInput>(
    key: K,
    value: NonNullable<WorkerFilterSetInput[K]>
  ) => Scalars['Void'];
  onClearFilter: (key: keyof WorkerFilterSetInput) => Scalars['Void'];
  onClearAll: () => Scalars['Void'];
  updatePageNumber: (pageno: number) => void;
};

const CandidateFilters = ({
  filters,
  query,
  onQueryChange,
  onChangeFilter,
  onClearFilter,
  onClearAll,
  updatePageNumber,
}: Props) => {
  const [showFiltersModal, hideFiltersModal] = useModal(
    () => (
      <WorkerFiltersModal
        filters={filters}
        hideModal={hideFiltersModal}
        updatePageNumber={updatePageNumber}
        onChangeFilter={onChangeFilter}
        onClearAll={onClearAll}
        onClearFilter={onClearFilter}
      />
    ),
    [filters]
  );
  const phoneOnly = useMediaQuery(breakpoints.phoneOnly);

  return (
    <Stack vertical gap={16}>
      <Stack gap={16}>
        <div style={{ flex: 1 }}>
          <TextInput
            id="input-search-worker"
            placeholder={`Search for GravyWorkers...`}
            value={query}
            onChange={(ev) => onQueryChange(ev.currentTarget.value)}
          />
        </div>

        {!phoneOnly ? (
          <ButtonGroup>
            <Button
              a11yLabel="View all GravyWorkers filters"
              appearance="outline"
              id="btn-filters"
              label="Filters"
              onClick={showFiltersModal}
            />
          </ButtonGroup>
        ) : (
          <IconicButton
            a11yLabel="viewFilters"
            appearance="clear"
            icon={faFilter}
            id="btn-filters"
            size="sm"
            onClick={showFiltersModal}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default CandidateFilters;

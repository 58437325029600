import { isBefore, lastDayOfWeek, startOfWeek } from 'date-fns';

import { MONDAY } from '@/util/constants';

type weeksRange = {
  start: Date;
  end: Date;
};

export const getWeeksRanges = (startDate: Date, endDate: Date) => {
  const weeks: weeksRange[] = [];
  let currentDate = startOfWeek(startDate, { weekStartsOn: MONDAY });
  const formatEndDate = lastDayOfWeek(endDate);
  while (isBefore(currentDate, formatEndDate)) {
    const endOfWeek = new Date(currentDate.getTime());
    endOfWeek.setDate(endOfWeek.getDate() + 6);
    weeks.push({
      start: currentDate,
      end: endOfWeek,
    });
    currentDate = new Date(currentDate.getTime());
    currentDate.setDate(currentDate.getDate() + 7);
  }
  return weeks;
};

export const getUTCDate = (date: Date, addDate: number = 0) => {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate() + addDate),
  );
};

import useModal from '@area2k/use-modal';
import { useEffect, useState } from 'react';

import ChangePaymentModal from './modals/ChangePaymentModal';

import Card from '@/components/Card';
import LoadingState from '@/components/LoadingState';
import QueryEmptyState from '@/components/QueryEmptyState';
import Stack from '@/components/Stack';
import { Body } from '@/components/Typography';
import { useGetAccountPaymentMethodsQuery } from '@/graphql';
import useMediaQuery from '@/hooks/useMediaQuery';
import {
  GetAccountPaymentMethodsQuery,
  GetAccountQuery,
  PaymentMethodInfo,
} from '@/types/graphql';
import { formatCurrentPaymentMethod } from '@/util/payments';

type Props = {
  account: GetAccountQuery['account'];
  hideChangeButton?: boolean;
  notice?: any;
  setPaymentMethod?: (paymentMethod: any) => void;
  cardBorderOutline?: boolean;
  firstAccount?: boolean;
  showMarkupBasedPricing?: boolean;
};

export type AccountPaymentMethods = PaymentMethodInfo;
export type AccountPaymentMethodsType =
  GetAccountPaymentMethodsQuery['accountPaymentMethods'];

const PaymentSection = ({
  account,
  hideChangeButton,
  notice,
  setPaymentMethod,
  cardBorderOutline = false,
  firstAccount,
  showMarkupBasedPricing,
}: Props) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const [accountPaymentMethods, setAccountPaymentMethods] =
    useState<AccountPaymentMethods>();

  const query = useGetAccountPaymentMethodsQuery({
    variables: {
      accountId: account.id,
    },
  });

  const [showChangeModal, hideChangeModal] = useModal(
    () => (
      <ChangePaymentModal
        account={account}
        accountPaymentMethods={accountPaymentMethods!}
        firstAccount={firstAccount}
        hideModal={hideChangeModal}
        onRefetch={query.refetch}
      />
    ),
    [account, accountPaymentMethods]
  );

  useEffect(() => {
    if (query.data) {
      setAccountPaymentMethods(query.data.accountPaymentMethods);
      setPaymentMethod?.(
        formatCurrentPaymentMethod(query.data.accountPaymentMethods)
      );
    }
  }, [query.data]);

  if (query.loading || !accountPaymentMethods) {
    return (
      <Card headerNobg={true} noRadius={phoneOnly}>
        <Card.Section>
          <Stack align="center" justify="center">
            <LoadingState text="Loading your current payment method" />
          </Stack>
        </Card.Section>
      </Card>
    );
  }

  if (query.error || !query.data) {
    return (
      <Card headerNobg={true} noRadius={phoneOnly}>
        <Card.Section>
          <Stack align="center" justify="center">
            <Body>{query.error?.message}</Body>
          </Stack>
        </Card.Section>
      </Card>
    );
  }

  const { currentPaymentMethod } = accountPaymentMethods;

  return (
    <Card
      actions={
        query.loading ||
        query.error ||
        hideChangeButton ||
        !accountPaymentMethods
          ? undefined
          : [
              {
                a11yLabel: currentPaymentMethod
                  ? 'Change the payment settings for this account'
                  : 'Add payment settings for this account',
                label: currentPaymentMethod ? 'Change' : 'ADD',
                onAction: () => showChangeModal(),
                id: 'payment-add',
              },
            ]
      }
      headerNobg={true}
      noRadius={phoneOnly}
      showBorder={cardBorderOutline}
      title="Payment Method"
    >
      <Card.Section>
        {currentPaymentMethod ? (
          <Body
            color={showMarkupBasedPricing ? 'themeDefault' : 'default'}
            textTransform="capitalize"
            weight={showMarkupBasedPricing ? 'medium' : 'normal'}
          >
            {formatCurrentPaymentMethod(accountPaymentMethods)}
          </Body>
        ) : (
          <QueryEmptyState
            query={query}
            text={`Add a payment method by clicking the button above`}
            title="No payment method"
          />
        )}
      </Card.Section>
      {notice}
    </Card>
  );
};

export default PaymentSection;

/* global google */
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  OverlayView,
} from '@react-google-maps/api';
import qs from 'query-string';
import { Fragment, useEffect } from 'react';

import CheckinMilesIcon from '@/assets/icons/CheckinMilesIcon';
import CheckoutMilesIcon from '@/assets/icons/CheckoutMilesIcon';
import Action from '@/components/Action';
import Stack from '@/components/Stack';
import Config from '@/config';
import { GAEvent } from '@/constants/gaevents';
import useMediaQuery from '@/hooks/useMediaQuery';
import styled from '@/styles';
import { Point } from '@/types/graphql';
import useAnalytics from '@/util/analytics';
import { haversine, metersToMiles } from '@/util/geo';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const responsiveContainerStyle = {
  width: '100%',
  marginBottom: '19px',
  height: '100%',
  borderRadius: '12px',
};

const MapLabel = styled('div', {
  color: '#7A7A7A',
  fontSize: '14px',
  fontWeight: '400',
});

const MapVal = styled('div', {
  color: '#262626',
  fontSize: '14px',
  fontWeight: '400',
  span: {
    fontWeight: '400',
  },
});

const mapCoordsUrl = (coords: Point): string =>
  qs.stringifyUrl({
    url: 'https://www.google.com/maps/search/',
    query: { api: 1, query: `${coords.latitude},${coords.longitude}` },
  });

const MapView = ({ timesheet, jobAddress, mapSize = '139' }) => {
  const { coords: jobCoords } = jobAddress;
  const { logEvent } = useAnalytics();
  useEffect(() => {
    if (timesheet) {
      logEvent(GAEvent.TimesheetViewMap, timesheet.id);
    }
  }, []);

  const markers = [
    {
      lat: jobCoords.latitude,
      lng: jobCoords.longitude,
      icon: require('@/assets/icons/joblocation.png'),
      addressText: `${jobAddress.addressLine1}\n${jobAddress.city}, ${jobAddress.state}`,
      color: '#7456ED',
    },
  ];
  if (timesheet?.checkinCoords) {
    markers.push({
      lat: timesheet.checkinCoords.latitude,
      lng: timesheet.checkinCoords.longitude,
      icon: require('@/assets/icons/checkin.png'),
      addressText: 'Check In',
      color: '#332F2D',
    });
  }
  if (timesheet?.checkoutCoords) {
    markers.push({
      lat: timesheet.checkoutCoords.latitude,
      lng: timesheet.checkoutCoords.longitude,
      icon: require('@/assets/icons/checkout.png'),
      addressText: 'Check Out',
      color: '#44A735',
    });
  }

  const onLoad = (map) => {
    const bounds = new google.maps.LatLngBounds();
    markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    map.fitBounds(bounds);
    setTimeout(() => {
      map.setZoom(15);
    }, 100);
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: Config.GOOGLE_API_KEY,
  });
  const phoneOnly = useMediaQuery('(max-width: 790px)');

  const getPixelPositionOffset = (offsetWidth, offsetHeight, labelAnchor) => {
    return {
      x: offsetWidth + labelAnchor.x,
      y: offsetHeight + labelAnchor.y,
    };
  };

  return (
    <>
      {isLoaded && (
        <Stack vertical gap={'1px'}>
          <Stack vertical gap={'12px'} style={{ margin: '0 0 20px' }}>
            <Stack justify={'apart'}>
              <Stack horizontalGap={'13px'}>
                <CheckinMilesIcon />
                <Stack vertical verticalGap={'0'}>
                  <MapLabel>Check In:</MapLabel>
                  <MapVal>
                    {timesheet?.checkinCoords ? (
                      <>
                        <span>
                          {metersToMiles(
                            haversine(timesheet.checkinCoords, jobCoords)
                          ).toFixed(1)}
                          mi{' '}
                        </span>
                        from job site
                      </>
                    ) : (
                      <>Not reported</>
                    )}
                  </MapVal>
                </Stack>
              </Stack>
              {timesheet?.checkinCoords ? (
                <Action.Button
                  action={{
                    a11yLabel: `Open new tab to ${mapCoordsUrl(
                      timesheet.checkinCoords
                    )}`,
                    label: 'View',
                    href: mapCoordsUrl(timesheet.checkinCoords),
                    external: true,
                    id: 'link-checkin',
                  }}
                />
              ) : (
                ''
              )}
            </Stack>

            <Stack justify={'apart'}>
              <Stack horizontalGap={'13px'}>
                <CheckoutMilesIcon />
                <Stack vertical verticalGap={'0'}>
                  <MapLabel>Check Out:</MapLabel>
                  <MapVal>
                    {timesheet?.checkoutCoords ? (
                      <>
                        <span>
                          {metersToMiles(
                            haversine(timesheet.checkoutCoords, jobCoords)
                          ).toFixed(1)}
                          mi{' '}
                        </span>
                        from the job site
                      </>
                    ) : (
                      <>Not reported</>
                    )}
                  </MapVal>
                </Stack>
              </Stack>
              {timesheet?.checkoutCoords ? (
                <Action.Button
                  action={{
                    a11yLabel: `Open new tab to ${mapCoordsUrl(
                      timesheet.checkoutCoords
                    )}`,
                    label: 'View',
                    href: mapCoordsUrl(timesheet.checkoutCoords),
                    external: true,
                    id: 'link-checkout',
                  }}
                />
              ) : (
                ''
              )}
            </Stack>
          </Stack>
          <Stack
            vertical
            style={{
              height: `${mapSize}px`,
            }}
          >
            <GoogleMap
              mapContainerStyle={
                phoneOnly ? responsiveContainerStyle : containerStyle
              }
              zoom={15}
              onLoad={onLoad}
            >
              {markers.map(({ lat, lng, icon, color, addressText }, index) => (
                <Fragment key={'marker' + index}>
                  <Marker
                    icon={{
                      url: icon,
                    }}
                    position={{ lat, lng }}
                  />
                  <OverlayView
                    getPixelPositionOffset={(x, y) =>
                      getPixelPositionOffset(x, y, {
                        x: 20,
                        y: -45,
                      })
                    }
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    position={{ lat, lng }}
                  >
                    <h1
                      style={{
                        fontWeight: 'bolder',
                        color: `${color}`,
                      }}
                    >
                      {addressText}
                    </h1>
                  </OverlayView>
                </Fragment>
              ))}
            </GoogleMap>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default MapView;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* width */
.scrollbar::-webkit-scrollbar {
  width: 4px;
}

.scrollbar::-webkit-scrollbar-track {
  background: #D9D9D9; 
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #D9D9D9; 
}
`, "",{"version":3,"sources":["webpack://./src/routes/Agency/Job/DetailsCard/index.css"],"names":[],"mappings":"AAAA,UAAU;AACV;EACE,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["/* width */\n.scrollbar::-webkit-scrollbar {\n  width: 4px;\n}\n\n.scrollbar::-webkit-scrollbar-track {\n  background: #D9D9D9; \n}\n\n.scrollbar::-webkit-scrollbar-thumb {\n  background: #D9D9D9; \n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

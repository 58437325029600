import { useReactiveVar } from '@apollo/client';
import {
  faAddressBook,
  faBuilding,
  faCommentAlt,
  faUser,
} from '@fortawesome/free-regular-svg-icons';
import {
  faFileAlt,
  faShoppingBasket,
  faTachometerAlt,
  faUserFriends,
  faFileInvoice,
  faFile,
} from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';

import PageSkeleton from './PageSkeleton';
import { AgencyWrapper, AgencyAvatar } from './styles';

import { InvoiceGeneratorIcon, JobMonitorIcon } from '@/assets/icons';
import NavigationMenu from '@/components/NavigationMenu';
import Skeleton from '@/components/Skeleton';
import Stack from '@/components/Stack';
import { Body, Small } from '@/components/Typography';
import Icon from '@/elements/Icon';
import { useAppDispatch } from '@/hooks/store';
import ROUTES from '@/routes/routes';
import { setRefetchDashboardData } from '@/store/slices/layoutSlice';
import { currentAgencyVar, currentAdminVar } from '@/util/apollo/cache';

const Sidebar = () => {
  const currentAgency = useReactiveVar(currentAgencyVar);
  const currentAdmin = useReactiveVar(currentAdminVar);
  const dispatch = useAppDispatch();
  const dashboardClick = () => {
    if (window.location.pathname !== ROUTES.basePath) {
      dispatch(setRefetchDashboardData(true));
    }
  };

  const adminNavigation = useMemo(
    () => [
      {
        id: 'menu-general',
        items: [
          {
            icon: faTachometerAlt,
            a11yLabel: 'Dashboard',
            to: ROUTES.basePath,
            end: true,
            id: 'dashboard-link',
            onClick: dashboardClick,
          },
          {
            icon: faCommentAlt,
            a11yLabel: 'Chat',
            to: ROUTES.inappchat,
            end: true,
            id: 'inappchat-link',
          },
          {
            icon: faCommentAlt,
            a11yLabel: 'Chat',
            to: ROUTES.chat,
            end: true,
            id: 'chat-link',
          },
        ],
      },
      {
        id: 'menu-gravywork-team',
        title: 'GravyWork Team',
        items: [
          {
            icon: faBuilding,
            a11yLabel: 'Clients',
            to: ROUTES.clients,
            id: 'clients-link',
          },
          {
            icon: faAddressBook,
            a11yLabel: 'Contacts',
            to: ROUTES.contacts,
            id: 'contacts-link',
          },
          {
            icon: faUserFriends,
            a11yLabel: 'GravyWorkers',
            to: ROUTES.workers,
            id: 'workers-link',
          },
        ],
      },
      {
        id: 'menu-admin',
        title: 'Admin',
        items: [
          {
            icon: faShoppingBasket,
            a11yLabel: 'Orders',
            to: ROUTES.orders,
            id: 'orders-link',
          },
          {
            icon: faFileInvoice,
            a11yLabel: 'Invoices',
            to: ROUTES.invoices,
            id: 'invoices-link',
          },
          {
            a11yLabel: 'Invoice Generator',
            to: ROUTES.invoiceGenerator,
            id: 'inv-generator-link',
            CustomIcon: () => (
              <InvoiceGeneratorIcon fontSize="small" htmlColor="inherit" />
            ),
          },
          {
            icon: faFileAlt,
            a11yLabel: 'Payroll Runs',
            to: ROUTES.payrolls,
            id: 'payrolls-link',
          },
          {
            icon: faFile,
            a11yLabel: 'Reports',
            to: ROUTES.reports,
            id: 'reports-link',
          },
          {
            a11yLabel: 'Job Monitor',
            to: ROUTES.jobmonitor,
            id: 'jobmonitor-link',
            CustomIcon: () => (
              <JobMonitorIcon fontSize="small" htmlColor="inherit" />
            ),
          },
        ],
      },
      {
        id: 'menu-my-information',
        title: 'My Information',
        items: [
          {
            icon: faUserFriends,
            a11yLabel: 'My Team',
            to: ROUTES.myTeam,
            id: 'myteam-link',
          },
          {
            icon: faBuilding,
            a11yLabel: 'My Company',
            to: ROUTES.myCompany,
            id: 'mycompany-link',
          },
          {
            icon: faUser,
            a11yLabel: 'My Profile',
            to: ROUTES.myProfile,
            id: 'profile-link',
          },
        ],
      },
    ],
    [currentAdmin]
  );

  return (
    <Stack vertical>
      <AgencyWrapper>
        <Stack gap={16}>
          <div>
            <AgencyAvatar>
              <Icon fixedWidth icon={faBuilding} />
            </AgencyAvatar>
          </div>
          {currentAgency ? (
            <Stack vertical gap={0}>
              <Body weight="semibold">{currentAgency.name}</Body>
              <Small>Washington, DC</Small>
            </Stack>
          ) : (
            <Skeleton.Body lines={2} />
          )}
        </Stack>
      </AgencyWrapper>
      {currentAdmin ? (
        <NavigationMenu groups={adminNavigation} />
      ) : (
        <PageSkeleton />
      )}
    </Stack>
  );
};

export default Sidebar;

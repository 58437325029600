import { KeyboardEvent } from 'react';
import {
  Control,
  Controller,
  FormState,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';

import type { AccountFormValues } from './';

import FormElement from '@/components/FormElement';
import StatesAutocomplete from '@/components/StatesAutocomplete';
import TextInput from '@/components/TextInput';
import { Small } from '@/components/Typography';
import FormColumns from '@/form/FormColumns';
import { capitalizeFirstLetter } from '@/util/text';

interface Props {
  register: UseFormRegister<AccountFormValues>;
  errors: FormState<AccountFormValues>['errors'];
  control: Control<AccountFormValues, object>;
  setValue: UseFormSetValue<AccountFormValues>;
}

const handleOnKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
  const INVALID_CHARACTERES = ['-', '+', 'e', 'E', '.', ','];

  if (INVALID_CHARACTERES.includes(event.key)) {
    event.preventDefault();
  }
};

const POBoxFormFields = ({ register, errors, control, setValue }: Props) => (
  <FormElement>
    <FormColumns layout="double">
      <FormElement htmlFor="addressLine1" label="Address Line">
        <TextInput
          autoCapitalize="words"
          id="addressLine1"
          placeholder="Address Line"
          {...register('address.addressLine1', {
            onChange: (ev) => {
              setValue(
                'address.addressLine1',
                capitalizeFirstLetter(ev.target.value)
              );
            },
          })}
        />
        {errors.address?.addressLine1 && (
          <Small color="danger" role="alert">
            {errors.address.addressLine1.message}
          </Small>
        )}
      </FormElement>
      <FormElement htmlFor="city" label="City">
        <TextInput
          autoCapitalize="words"
          id="city"
          placeholder="City"
          {...register('address.city', {
            onChange: (ev) => {
              setValue('address.city', capitalizeFirstLetter(ev.target.value));
            },
          })}
        />
        {errors.address?.city && (
          <Small color="danger" role="alert">
            {errors.address.city.message}
          </Small>
        )}
      </FormElement>
    </FormColumns>
    <FormColumns layout="double">
      <Controller
        control={control}
        name="address.state"
        render={({ field: { value, onChange } }) => (
          <StatesAutocomplete
            selectedItem={value}
            onSelectedItemChange={({ selectedItem: newSelectedItem }) =>
              onChange(newSelectedItem)
            }
          >
            {errors.address?.state && (
              <Small color="danger" role="alert">
                {errors.address.state.message}
              </Small>
            )}
          </StatesAutocomplete>
        )}
      />
      <FormElement htmlFor="zip" label="Zip Code">
        <TextInput
          id="zip"
          placeholder="Zip Code"
          type="number"
          {...register('address.zip')}
          min="0"
          onKeyPress={handleOnKeyPress}
        />
        {errors.address?.zip && (
          <Small color="danger" role="alert">
            {errors.address.zip.message}
          </Small>
        )}
      </FormElement>
    </FormColumns>
  </FormElement>
);
export default POBoxFormFields;

import useModal from '@area2k/use-modal';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { addDays } from 'date-fns';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import SuccessfulAlertModal from '../../Reports/shared/SuccessfulAlertModal';
import ItemPayrollDetails from '../ItemPayroll';
import PageSkeleton from '../Skeletons/DetailsSkeleton';
import ErrorPage from '../Skeletons/ErrorPage';

import ConfirmApprovalPayrollModal from './ConfirmApprovalPayrollModal';
import ConfirmDeletePayrollModal from './ConfirmDeletePayrollModal';
import ContractorPaymentsCard from './ContractorPaymentsCard';
import DetailsCard from './DetailsCard';
import ItemsCard from './ItemsCard';
import TotalsCard from './TotalsCard';

import Page from '@/components/Page';
import Stack from '@/components/Stack';
import {
  useGetPayrollDetailQuery,
  useListPayrollJournalReportLazyQuery,
} from '@/graphql';
import { Prettify } from '@/types';
import {
  GetPayrollDetailQuery,
  PayrollStatusEnum,
  SimpleContractorPaymentsFragment,
  SimplePayrollItemFragment,
  Scalars,
  Maybe,
} from '@/types/graphql';
import { formatHumanDate } from '@/util/datetime';

export type Payroll = Prettify<GetPayrollDetailQuery['payroll']>;

export type ItemSelected = {
  showItem: Scalars['Boolean'];
  item: Prettify<
    Maybe<
      | Prettify<SimplePayrollItemFragment>
      | Prettify<SimpleContractorPaymentsFragment>
    >
  >;
  type: Maybe<'item' | 'contractor'>;
};

const PayrollDetail = () => {
  const { payrollId } = useParams();

  const { data, error } = useGetPayrollDetailQuery({
    variables: { payrollId },
  });

  const [item, setItem] = useState<ItemSelected>({
    showItem: false,
    item: null,
    type: null,
  });

  const [getPayrollJournals, { loading }] =
    useListPayrollJournalReportLazyQuery({
      onCompleted: () => {
        showSuccessfulAlertModal();
      },
      fetchPolicy: 'network-only',
    });
  const [showConfirmApprovalModal, hideConfirmApprovalModal] = useModal(
    () => (
      <ConfirmApprovalPayrollModal
        hideModal={hideConfirmApprovalModal}
        payrollId={payrollId}
      />
    ),
    [payrollId]
  );
  const [showConfirmDeleteModal, hideConfirmDeleteModal] = useModal(
    () => (
      <ConfirmDeletePayrollModal
        hideModal={hideConfirmDeleteModal}
        payrollId={payrollId}
      />
    ),
    [payrollId]
  );

  const [showSuccessfulAlertModal, hideSuccessfulAlertModal] = useModal(
    () => <SuccessfulAlertModal hideModal={hideSuccessfulAlertModal} />,
    []
  );

  if (error) {
    return <ErrorPage />;
  }

  if (!data) {
    return <PageSkeleton />;
  }

  const { payroll } = data;
  const formattedPayday = formatHumanDate(addDays(new Date(payroll.payday), 1));

  return !item.showItem ? (
    <Page
      primaryAction={
        payroll.status === PayrollStatusEnum.DRAFT
          ? {
              a11yLabel: 'Approve Preview Payroll',
              id: 'approve-payroll-btn',
              label: 'Approve Payroll',
              onAction: showConfirmApprovalModal,
            }
          : undefined
      }
      secondaryActions={[
        {
          a11yLabel: 'Download Payroll',
          label: 'Download Payroll',
          onAction: () => {
            getPayrollJournals({ variables: { payrollId: payroll.id } });
          },
          id: 'download-payroll-btn',
          isLoading: loading,
          icon: faDownload,
          disabled: loading,
        },
        ...(payroll.status === PayrollStatusEnum.DRAFT
          ? [
              {
                a11yLabel: 'Delete Payroll',
                label: 'Delete Payroll',
                dangerous: true,
                onAction: showConfirmDeleteModal,
                id: 'btn-delete-payroll',
              },
            ]
          : []),
      ]}
      size="lg"
      title={`Payroll Journal ${formattedPayday}`}
    >
      <Stack vertical gap={30}>
        <DetailsCard payroll={payroll} />
        <TotalsCard totals={payroll.totals} />
        {payroll.payrollItems && payroll.payrollItems.items!.length > 0 && (
          <ItemsCard items={payroll.payrollItems?.items} setItem={setItem} />
        )}
        {payroll.contractorPayments &&
          payroll.contractorPayments.items.length > 0 && (
            <ContractorPaymentsCard
              items={payroll.contractorPayments.items}
              setItem={setItem}
            />
          )}
      </Stack>
    </Page>
  ) : (
    <ItemPayrollDetails
      context={item.type ?? 'item'}
      item={item.item}
      setItem={setItem}
    />
  );
};

export default PayrollDetail;

import Shimmer from '@/elements/Shimmer';
import styled from '@/styles';

const Action = styled(Shimmer, {
  height: 36,
  width: 128,
});

Action.displayName = 'stitches(Skeleton.Action)';

export default Action;

import { SubmitHelpers } from '@area2k/use-form';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { isEqual } from 'lodash';
import { useCallback, useState } from 'react';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Option from '@/components/Option';
import Stack from '@/components/Stack';
import TextInput from '@/components/TextInput';
import { Small } from '@/components/Typography';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import Form from '@/form';
import OptionField from '@/form/OptionField';
import TextField from '@/form/TextField';
import TextSelectField from '@/form/TextSelectField';
import {
  useCheckDuplicateCustomerNameQuery,
  useUpdateCustomerMutation,
} from '@/graphql';
import styled from '@/styles';
import {
  DefaultDueDateEnum,
  GetCustomerQuery,
  TaxTypeEnum,
} from '@/types/graphql';
import {
  JOB_TAX_TYPES,
  TAX_TYPE_LABELS,
  taxOptions,
  LOW_MARKUP_WARNING_DESCRIPTION,
  DEFAULT_MARKUP_PRICE_VALUE,
  MARKUP_PERCENTAGE_FIELD_ERROR,
  CONFIGURABLE_CHECKIN_FIELD_ERROR,
} from '@/util/constants';
import { stringToEnumInvoiceDueDate } from '@/util/date';
import { handleMutationFormError } from '@/util/error';

const LabelText = styled('p', {
  fontSize: '13px',
  fontWeight: 600,
  color: '#332F2D',
  marginBottom: '5px',
});

export type Props = {
  customer: GetCustomerQuery['customer'];
  hideModal: () => void;
};

type FormValues = {
  invoiceDueDate: DefaultDueDateEnum;
  name: string;
  enableLTA: boolean;
  markupPercent: number;
  checkInRadiusFeet: number;
};

const invoiceDueDateOptions = [
  { label: '10 days', value: DefaultDueDateEnum.INVOICE_DUE_10_DAYS },
  { label: '15 days', value: DefaultDueDateEnum.INVOICE_DUE_15_DAYS },
  { label: '30 days', value: DefaultDueDateEnum.INVOICE_DUE_30_DAYS },
  { label: '45 days', value: DefaultDueDateEnum.INVOICE_DUE_45_DAYS },
  { label: '60 days', value: DefaultDueDateEnum.INVOICE_DUE_60_DAYS },
];

const UpdateClientModal = ({ customer, hideModal }: Props) => {
  const invoiceDueDate: DefaultDueDateEnum = stringToEnumInvoiceDueDate(
    customer.invoiceDueDate,
  );

  const formatCurrentTaxType = (taxType: string) => {
    switch (taxType) {
      case JOB_TAX_TYPES.all:
        return TaxTypeEnum.ALL;
      case JOB_TAX_TYPES.w2:
        return TaxTypeEnum.TAX_W2;
      default:
        return taxType;
    }
  };

  const initialValues: FormValues = {
    invoiceDueDate,
    name: customer.name,
    enableLTA: customer.ltaAllowed || false,
    markupPercent: customer.markupPercent,
    checkInRadiusFeet: customer.checkInRadiusFeet,
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [error, setError] = useState<{ message: string } | null>(null);
  const [markupLowValueError, setMarkupLowValueError] =
    useState<boolean>(false);

  const [customerTaxType, setCustomerTaxType] = useState(
    formatCurrentTaxType(customer.jobTaxType ?? JOB_TAX_TYPES.all),
  );
  const [updateCustomer, { loading: isLoading }] = useUpdateCustomerMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(customer),
        fields: {
          jobTaxType() {},
          ltaAllowed() {},
        },
      });
    },
  });

  const { data: checkCustomerNameData } = useCheckDuplicateCustomerNameQuery({
    fetchPolicy: 'no-cache',
    variables: { clientName: formValues.name },
  });

  const customerNameDuplicate =
    checkCustomerNameData && checkCustomerNameData.customerNameDuplicate;

  const showMarkupBasedPricing = useFeatureValue(
    FEATURE_TOGGLE.MarkupBasedPricingFeature,
    false,
  );
  const configurableCheckinFeature = useFeatureValue(
    FEATURE_TOGGLE.ConfigurableCheckinFeature,
    false,
  );

  const handleFormValuesChange = (fieldContext, fieldId: keyof FormValues) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldId]: fieldContext.value,
    }));
  };
  const handleMarkUpValueChange = (inputMarkupValue: string) => {
    if (!/^\d*%?$/.test(inputMarkupValue)) {
      // check only digits and only one %
      return;
    }
    const markUpValueFormatted = inputMarkupValue.replace('%', '');
    Number(markUpValueFormatted) < DEFAULT_MARKUP_PRICE_VALUE
      ? setMarkupLowValueError(true)
      : setMarkupLowValueError(false);
    handleFormValuesChange({ value: markUpValueFormatted }, 'markupPercent');
  };

  const handleCheckinDistanceChange = (inputCheckinDistanceValue: string) => {
    if (!/^\d*$/.test(inputCheckinDistanceValue)) {
      return;
    }
    handleFormValuesChange(
      { value: inputCheckinDistanceValue },
      'checkInRadiusFeet',
    );
  };

  const showMarkUpPriceFieldError = formValues.markupPercent < 1;
  const checkinDistanceError =
    formValues.checkInRadiusFeet < 250 || formValues.checkInRadiusFeet > 10000;
  const formSubmitDisabledCheck = () => {
    const markupError =
      showMarkupBasedPricing && customer?.markupPercent
        ? showMarkUpPriceFieldError
        : false;
    const checkinError = configurableCheckinFeature && checkinDistanceError;
    return markupError || checkinError;
  };

  const handleSubmit = useCallback(
    async (_values: any, { setFormError }: SubmitHelpers) => {
      try {
        const {
          enableLTA,
          invoiceDueDate,
          name,
          markupPercent,
          checkInRadiusFeet,
        } = formValues;
        if (
          isEqual(formValues, initialValues) &&
          customerTaxType ===
            (customer.jobTaxType
              ? (customer.jobTaxType as TaxTypeEnum)
              : TaxTypeEnum.ALL)
        ) {
          return hideModal();
        }
        if (customerNameDuplicate) {
          if (formValues.name !== initialValues.name) {
            return setError({ message: 'This client name is already in use.' });
          } else {
            setError(null);
          }
        }
        if (error === null) {
          await updateCustomer({
            variables: {
              customerId: customer.id,
              taxType: customerTaxType as TaxTypeEnum,
              ltaAllowed: enableLTA,
              invoiceDueDate: invoiceDueDate,
              name: name,
              markupPercent: Number(markupPercent),
              checkInRadiusFeet: Number(checkInRadiusFeet),
            },
          });

          hideModal();
        }
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
          errorMap: {
            all: (gqlError) => ({
              title: gqlError.name,
              message: gqlError.message,
              status: 'danger',
            }),
          },
        });
      }
    },
    [customer.id, customerNameDuplicate, formValues, customerTaxType],
  );

  return (
    <Modal
      disableClickout
      size="xs"
      title="Account Settings"
      wrapperBackground={true}
      onRequestClose={hideModal}
    >
      <Card.Section>
        {error && (
          <Alert
            description={error.message}
            icon={faExclamationTriangle}
            status="warning"
            title="Something went wrong"
          />
        )}
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <LabelText>Client Name</LabelText>
          <TextField
            autoFocus
            required
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext, 'name');
              if (error) {
                setError(null);
              }
            }}
            fieldId="name"
            placeholder="Client name"
          />
          <LabelText>Net Terms</LabelText>
          <TextSelectField
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext, 'invoiceDueDate');
            }}
            fieldId="invoiceDueDate"
            options={invoiceDueDateOptions}
          />
          <LabelText>Tax Type</LabelText>
          <Stack style={{ marginBottom: '20px' }}>
            {taxOptions.map((option) => (
              <Option
                key={option}
                appearance="bullseye"
                checked={customerTaxType === option}
                id={`${taxOptions.indexOf(option)}`}
                label={TAX_TYPE_LABELS[option]}
                style={{ marginRight: '20px' }}
                type="radio"
                value={option}
                onChange={(ev) => setCustomerTaxType(ev.currentTarget.value)}
              />
            ))}
          </Stack>
          {configurableCheckinFeature && (
            <>
              <LabelText>Check In Radius (ft)</LabelText>
              <Stack
                vertical
                gap="8px"
                style={{ marginTop: '5px', marginBottom: '20px' }}
              >
                <TextInput
                  data-testid="checkin-distance-input"
                  value={formValues.checkInRadiusFeet}
                  width="100%"
                  onChange={(e) => {
                    handleCheckinDistanceChange(e.target.value);
                  }}
                  step={1}
                />
                {checkinDistanceError && (
                  <Small color="danger" role="alert">
                    {CONFIGURABLE_CHECKIN_FIELD_ERROR}
                  </Small>
                )}
              </Stack>
            </>
          )}
          <LabelText style={{ marginTop: '15px', marginBottom: '5px' }}>
            LTA
          </LabelText>
          <OptionField
            appearance="switch"
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext, 'enableLTA');
            }}
            fieldId="enableLTA"
            label="Enable LTA"
          />
          {showMarkupBasedPricing && (
            <>
              <LabelText style={{ marginTop: '15px', marginBottom: '10px' }}>
                Set the Markup
              </LabelText>
              <Stack vertical gap="16px" style={{ marginBottom: '20px' }}>
                <TextInput
                  data-testid="markup-value-input"
                  value={`${formValues.markupPercent}%`}
                  width="100%"
                  onChange={(e) => {
                    handleMarkUpValueChange(e.target.value);
                  }}
                />
                {showMarkUpPriceFieldError && (
                  <Small color="danger" role="alert">
                    {MARKUP_PERCENTAGE_FIELD_ERROR}
                  </Small>
                )}
                {markupLowValueError && (
                  <Alert
                    css={{ marginBottom: '0px' }}
                    description={LOW_MARKUP_WARNING_DESCRIPTION}
                    icon={faExclamationTriangle}
                    status="warning"
                    title="Low Markup"
                  />
                )}
              </Stack>
            </>
          )}
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              disabled={formSubmitDisabledCheck()}
              id="btn-save"
              isLoading={isLoading}
              label="Save"
              style={{ width: '100%' }}
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  );
};

export default UpdateClientModal;

import { useReactiveVar } from '@apollo/client';

import Avatar from '@/components/Avatar';
import Card from '@/components/Card';
import Stack from '@/components/Stack';
import TextStack from '@/components/TextStack';
import { Body, Small } from '@/components/Typography';
import Link from '@/elements/Link';
import useMediaQuery from '@/hooks/useMediaQuery';
import { Role } from '@/routes/PrivateRoute';
import ROUTES from '@/routes/routes';
import { GetOrderQuery } from '@/types/graphql';
import { currentAdminVar } from '@/util/apollo/cache';

type Props = {
  order: GetOrderQuery['order'];
};

const ClientCard = ({ order: { account } }: Props) => {
  const currentAdmin = useReactiveVar(currentAdminVar);
  const currentAdminIsCustomerAdmin =
    currentAdmin!.role === Role.CUSTOMER_ADMIN;
  const phoneOnly = useMediaQuery('(max-width: 559px)');

  return (
    <Card sectioned noRadius={phoneOnly} title="Client">
      <Stack gap={16}>
        <div>
          <Avatar
            firstName={account.customer.name}
            size="sm"
            src={account.customer.logoUrl}
          />
        </div>
        <TextStack>
          <Link
            to={
              currentAdminIsCustomerAdmin
                ? `../../${ROUTES.myCompany}`
                : `../../clients/${account.customer.id}`
            }
          >
            <Body color="inherit" weight="medium">
              {account.customer.name}
            </Body>
          </Link>
          <Link
            appearance="subtle"
            to={
              currentAdminIsCustomerAdmin
                ? `../../${ROUTES.myCompany}/accounts/${account.id}`
                : `../../clients/${account.customer.id}/accounts/${account.id}`
            }
          >
            <Small>{account.name}</Small>
          </Link>
        </TextStack>
      </Stack>
    </Card>
  );
};

export default ClientCard;

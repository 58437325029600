import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { GetInvoiceQuery, Scalars } from '@/types/graphql';

interface Props {
  invoice: GetInvoiceQuery['invoice'];
  hideModal: () => Scalars['Void'];
}

const SuccessInvoicePayModal = ({ invoice, hideModal }: Props) => (
  <Modal
    disableClickout
    size="xs"
    title={`Invoice #${invoice.id} paid!`}
    onRequestClose={hideModal}
  >
    <Card.Section>
      <Alert
        icon={faCheckCircle}
        status="success"
        title="The invoice was successfully paid"
      />
      <Stack justify="end">
        <Button
          a11yLabel="Close modal"
          appearance="primary"
          label="Ok"
          type="button"
          onClick={hideModal}
        />
      </Stack>
    </Card.Section>
  </Modal>
);

export default SuccessInvoicePayModal;

import { ApolloError } from '@apollo/client';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { GraphQLError } from 'graphql';
import { useCallback, useState } from 'react';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Counter from '@/components/Counter';
import FormElement from '@/components/FormElement';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { UNKNOWN_ERROR_TEXT } from '@/constants/text';
import { useUpdateGravyWorkersMutation } from '@/graphql';
import styled from '@/styles';
import { GetJobQuery, Maybe, Scalars } from '@/types/graphql';

const Wrapper = styled('div', {
  width: '80%',
  display: 'flex',
});

type Props = {
  job: GetJobQuery['job'];
  limitOfEdit: Scalars['Int'];
  hideModal: () => void;
};

type FormValues = {
  quantity: Scalars['Int'];
};

type ErrorTypes = {
  message: Scalars['String'];
};

const UpdateGravyWorkers = ({ job, limitOfEdit, hideModal }: Props) => {
  const [formValues, setFormValues] = useState<FormValues>({
    quantity: job.quantity,
  });

  const [error, setError] = useState<Maybe<ErrorTypes>>();

  const [updateGravyWorkers, { loading: isLoading }] =
    useUpdateGravyWorkersMutation({
      update: (cache) => {
        cache.modify({
          id: cache.identify(job),
          fields: {
            quantity() {},
            jobWorkers() {},
          },
        });
      },
    });

  const handleQuantityChange = (value: number) => {
    const quantity = value;
    setFormValues((prev) => ({ ...prev, quantity }));
  };

  const handleSubmit = useCallback(async () => {
    try {
      await updateGravyWorkers({
        variables: {
          jobId: job.id,
          numberWorkers: formValues.quantity,
        },
      });
      hideModal();
    } catch (err) {
      if (err instanceof GraphQLError || err instanceof ApolloError) {
        return setError({ message: err.message ?? UNKNOWN_ERROR_TEXT });
      }
      setError({ message: UNKNOWN_ERROR_TEXT });
    }
  }, [formValues]);

  return (
    <Modal
      disableClickout
      size="xxs"
      title="Update GravyWorkers"
      onRequestClose={hideModal}
    >
      <Card.Section>
        {error && (
          <Alert
            description={error.message}
            icon={faExclamationTriangle}
            status="warning"
            title="Something went wrong"
          />
        )}
        <Wrapper>
          <FormElement fontSize="md" label="Employees">
            <Counter
              min={limitOfEdit}
              value={formValues.quantity}
              onChange={handleQuantityChange}
            />
          </FormElement>
        </Wrapper>

        <Stack justify="end">
          <Button
            a11yLabel="Submit form"
            id="save-worker-count-btn"
            isLoading={isLoading}
            label="Save"
            type="button"
            onClick={handleSubmit}
          />
        </Stack>
      </Card.Section>
    </Modal>
  );
};

export default UpdateGravyWorkers;

import Card from '@/components/Card';
import Stack from '@/components/Stack';
import StackContainer from '@/components/StackContainer';
import { Small } from '@/components/Typography';
import Text from '@/elements/Text';
import { useGetJobHistoryQuery } from '@/graphql';
import { GetJobQuery } from '@/types/graphql';
import '../index.css';
import { getJobCreationTime } from '@/util/datetime';

type Props = {
  job: GetJobQuery['job'];
};

const JobHistory = ({ job }: Props) => {
  const { data } = useGetJobHistoryQuery({
    variables: { jobId: job.id },
  });
  const createdBy = data?.jobHistory?.createdBy;
  return (
    <Card.Section styleHeading={true} title="Job History">
      <StackContainer className="scrollbar">
        <Stack vertical>
          <Text as="h4" css={{ color: '#262626' }} weight={'bold'}>
            Created By
          </Text>
          <Stack justify={'apart'}>
            <Text>
              {createdBy
                ? `${createdBy.firstName} ${createdBy?.lastName || ''}`
                : ''}
            </Text>
            <Small>{getJobCreationTime(job.createdAt)}</Small>
          </Stack>
        </Stack>
      </StackContainer>
    </Card.Section>
  );
};

export default JobHistory;

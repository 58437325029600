import { useReactiveVar } from '@apollo/client';
import { useCallback, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';

import { SubmitErrorAlert } from '@/components/Alerts';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import { useUpdateAccountMutation } from '@/graphql';
import { AccountForm } from '@/routes/Agency/common/components';
import { AccountFormValues } from '@/routes/Agency/common/components/AccountForm';
import { Role } from '@/routes/PrivateRoute';
import { Maybe } from '@/types';
import { GetAccountQuery, AccountRateTypeEnum } from '@/types/graphql';
import { currentAdminVar } from '@/util/apollo/cache';

export type Props = {
  account: GetAccountQuery['account'];
  hideModal: () => void;
  rateType?: string;
};

const UpdateContactModal = ({ account, hideModal, rateType }: Props) => {
  const [submitError, setSubmitError] = useState<Maybe<Error>>(null);
  const [updateAccountInformation, { loading }] = useUpdateAccountMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(account),
        fields: {
          defaultContact() {},
          addressLine1() {},
          addressLine2() {},
          city() {},
          state() {},
          zip() {},
        },
      });
    },
    onCompleted: hideModal,
    onError: setSubmitError,
  });

  const currentAdmin = useReactiveVar(currentAdminVar);
  const isTenantAdmin = currentAdmin?.role === Role.TENANT_ADMIN;

  const onSubmit: SubmitHandler<AccountFormValues> = useCallback(
    ({ defaultContact, address, markupPercent = '' }) => {
      const markupPercentageValue = isTenantAdmin
        ? Number(markupPercent?.replace('%', ''))
        : account?.markupPercent;
      updateAccountInformation({
        // @ts-ignore
        variables: {
          accountId: account.id,
          defaultContactId: defaultContact.id,
          ...address,
          addressLine2: address.addressLine2 ?? null,
          ...(rateType === AccountRateTypeEnum.MARKUP && {
            markupPercent: markupPercentageValue,
          }),
        },
      });
    },
    []
  );

  return (
    <Modal
      disableClickout
      size="sm"
      title={'Update Account Information'}
      wrapperBackground={true}
      onRequestClose={hideModal}
    >
      <Card.Section>
        {submitError && <SubmitErrorAlert description={submitError.message} />}
        <AccountForm
          customer={account.customer}
          isLoading={loading}
          newAccount={false}
          rateType={rateType}
          selectedAccount={account}
          onSubmit={onSubmit}
        />
      </Card.Section>
    </Modal>
  );
};

export default UpdateContactModal;

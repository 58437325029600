import { VariantProps, CSS } from '@stitches/react';
import { PropsWithChildren, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import Action from '@/components/Action';
import LoadingState from '@/components/LoadingState';
import Stack from '@/components/Stack';
import styled from '@/styles';
import { GenericActionOrList, RouterLocationDescriptor } from '@/types';

const Wrapper = styled('div', {
  alignItems: 'center',
  columnGap: '20px',
  display: 'flex',
  padding: '12px 20px',
  textDecoration: 'none',
  cursor: 'pointer',

  '& + &': {
    borderTop: '1px solid $colors$neutralLightest',
  },

  '&:hover': {
    backgroundColor: '$neutralA2',
  },

  variants: {
    size: {
      default: {},
      full: {
        width: '100%',
      },
    },
    disabled: {
      true: {
        backgroundColor: '$neutralA12',

        '&:hover': {
          backgroundColor: '$neutralA12',
        },
      },
      false: {},
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

const ActionsDrawer = styled('div', {
  position: 'absolute',
  right: 20,

  variants: {
    persistent: {
      true: {
        display: 'block',
      },
      // @ts-ignore
      false: {
        display: 'none',

        [`${Wrapper}:hover &`]: {
          display: 'block',
        },
      },
    },
  },

  defaultVariants: {
    persistent: false,
  },
});

const ChildrenWrapper = styled('div', {
  flex: '1 1 100%',
});

const ItemLoadingWrapper = styled('div', {
  color: 'red',
  [`h1`]: {
    fontSize: '20px',
  },
});

const listOptions = { justify: 'right' } as const;

export type Variants = VariantProps<typeof Wrapper>;

export type Props = Variants & {
  actions?: GenericActionOrList[];
  secondaryActions?: GenericActionOrList[];
  media?: ReactNode;
  persistActions?: boolean;
  to: RouterLocationDescriptor;
  disabled?: boolean;
  loading?: boolean;
  css?: CSS;
};

const Item = ({
  actions,
  secondaryActions,
  children,
  media = null,
  persistActions = false,
  to,
  disabled,
  loading,
  ...rest
}: PropsWithChildren<Props>) => {
  const navigate = useNavigate();
  const hasPrefix = media;

  const handleNavigate = () => {
    if (!loading) {
      navigate(to);
    }
  };

  return (
    <Wrapper
      disabled={disabled || loading}
      role="link"
      tabIndex={0}
      onClick={handleNavigate}
      {...rest}
    >
      {hasPrefix && <div>{media}</div>}
      <ChildrenWrapper>{children}</ChildrenWrapper>
      {loading ? (
        <ItemLoadingWrapper>
          <LoadingState />
        </ItemLoadingWrapper>
      ) : (
        <ActionsDrawer persistent={persistActions}>
          <Stack inline>
            <>
              {secondaryActions &&
                secondaryActions.length > 0 &&
                secondaryActions.map((action, index) => (
                  <Action.Button
                    key={index}
                    stopEvent
                    action={action}
                    appearance={action.appearance || 'plain'}
                    listOptions={listOptions}
                  />
                ))}
              {actions &&
                actions.length > 0 &&
                actions.map((action, index) => (
                  <Action.Button
                    key={index}
                    stopEvent
                    action={action}
                    appearance={action.appearance || 'plain'}
                    listOptions={listOptions}
                  />
                ))}
            </>
          </Stack>
        </ActionsDrawer>
      )}
    </Wrapper>
  );
};

export default Item;

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';
import { format } from 'date-fns';

import { logo } from '../../../assets/img/index';

import PoppinsBold from './fonts/Poppins-Bold.ttf';
import PoppinsRegular from './fonts/Poppins-Regular.ttf';
import { getShiftIntervals } from './util';

import { GetJobQuery, JobWorkerItemFragment } from '@/types/graphql';

// Register Fonts
Font.register({
  family: 'Poppins',
  fonts: [
    {
      src: PoppinsRegular,
    },
    {
      src: PoppinsBold,
      fontWeight: 'bold',
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 100,
    paddingHorizontal: 35,
  },
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    paddingBottom: '20px',
  },
  pageHeading: {
    fontFamily: 'Poppins',
    fontSize: 20,
    fontWeight: 700,
  },
  pageSubHeading: {
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 400,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  logo: {
    width: 250,
    textAlign: 'right',
  },
  table: {
    borderCollapse: 'collapse',
    borderSpacing: 0,
    width: 'calc(100% - 70px)',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderTopWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
    minHeight: '20px',
  },
  tableHeader: {
    margin: 'auto',
    borderStyle: 'solid',
    borderTopWidth: 1,
    flexDirection: 'row',
    backgroundColor: 'lightgrey',
  },
  tableCol: {
    width: '13%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: '5px',
    fontSize: 12,
    whiteSpace: 'nowrap',
  },
  footer: {
    position: 'absolute',
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  footerSignature: {
    padding: 36,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
});

// Prop types
type Props = {
  job: GetJobQuery['job'];
  hiredWorkers: JobWorkerItemFragment[];
};

// Create Document Component
const PrintWorkerTimeSheet = ({ job, hiredWorkers }: Props) => {
  const shiftInterval = getShiftIntervals(job.shifts);

  const jobWorkers = [
    ...hiredWorkers.map((item) => {
      const middleName = item.worker.user.middleName
        ? ` ${item.worker.user.middleName}`
        : ``;
      return (
        item.worker.user.firstName +
        middleName +
        ` ${item.worker.user.lastName}`
      );
    }),
  ].sort();

  return (
    <Document>
      <Page wrap size="A4" style={styles.page}>
        <View fixed>
          <View style={styles.pageHeader}>
            <View>
              <Text style={{ ...styles.pageHeading }}>
                {job.account.customer.name}
              </Text>
              <Text style={styles.pageSubHeading}>{job.skill.name}</Text>
            </View>
            <Image source={logo} style={styles.logo} />
          </View>
        </View>
        <View>
          <View style={{ paddingBottom: '15px' }}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={{ ...styles.pageHeading, fontSize: 12 }}>
                Job Date:{' '}
              </Text>
              <Text style={{ ...styles.pageSubHeading, fontSize: 12 }}>
                {format(new Date(job.firstShiftStartAt), 'PP')}
              </Text>
              <Text style={{ ...styles.pageHeading, fontSize: 12 }}>
                {'  '}Job Time:{' '}
              </Text>
              <Text style={{ ...styles.pageSubHeading, fontSize: 12 }}>
                {format(shiftInterval[0][0].startAt, 'p')} &mdash;{' '}
                {format(
                  shiftInterval[0][shiftInterval[0].length - 1].endAt,
                  'p'
                )}
              </Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Text style={{ ...styles.pageHeading, fontSize: 12 }}>
                Shift Supervisor:{' '}
              </Text>
              <Text style={{ ...styles.pageSubHeading, fontSize: 12 }}>
                {job.contact.user.firstName} {job.contact.user.lastName}
              </Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Text style={{ ...styles.pageHeading, fontSize: 12 }}>
                Order ID:{' '}
              </Text>
              <Text style={{ ...styles.pageSubHeading, fontSize: 12 }}>
                #{job.order.id}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.table}>
          <View fixed style={styles.tableHeader}>
            <View style={{ ...styles.tableCol, width: '36%' }}>
              <Text style={styles.tableCell}>Name</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Time In</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Time Out</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Break Time</Text>
            </View>
            <View style={{ ...styles.tableCol, width: '25%' }}>
              <Text style={styles.tableCell}>Employee Signature</Text>
            </View>
          </View>
          {jobWorkers.map((item, index) => (
            <View key={index} style={styles.tableRow}>
              <View style={{ ...styles.tableCol, width: '36%' }}>
                <Text style={styles.tableCell}>{item}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}> </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}> </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}> </Text>
              </View>
              <View style={{ ...styles.tableCol, width: '25%' }}>
                <Text style={styles.tableCell}> </Text>
              </View>
            </View>
          ))}
        </View>

        <View fixed style={styles.footer}>
          <View style={styles.footerSignature}>
            <Text>Supervisor Signature _____________________</Text>
            <Text>Date _____________________</Text>
            <Text render={({ pageNumber }) => `${pageNumber}`} />
          </View>
          <View
            style={{
              bottom: 20,
            }}
          >
            <Text
              style={{
                ...styles.pageSubHeading,
                fontSize: 11,
                color: 'grey',
              }}
            >
              GravyWork | (703) 548-4060 | operations@gravywork.com
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PrintWorkerTimeSheet;

import { createSvgIcon } from '@mui/material';

const fillCheck = createSvgIcon(
  <svg
    height="64"
    viewBox="0 0 64 64"
    width="64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#a)">
      <path
        d="M32 .395C14.617.395.395 14.617.395 32 .395 49.383 14.617 63.605 32 63.605c17.383 0 31.605-14.222 31.605-31.605C63.605 14.617 49.383.395 32 .395Zm-6.321 47.407L9.877 32l4.456-4.456 11.346 11.314L49.667 14.87l4.456 4.488L25.68 47.802Z"
        fill="#44A735"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 0h64v64H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>,
  'FillCheck'
);
export default fillCheck;

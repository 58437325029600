import { To } from 'history';

import AvatarGroupComponent, { TypeAvatarGroups } from './AvatarGroupComponent';
import Stack from './Stack';
import { Body } from './Typography';

import Badge from '@/components/Badge';
import { ParagraphProps as BodyVariants, Small } from '@/components/Typography';
import Link from '@/elements/Link';
import { TableCell, TableRow } from '@/elements/Table';
import useMediaQuery from '@/hooks/useMediaQuery';
import { OrderListItemFragment } from '@/types/graphql';
import { formatISO } from '@/util/date';
import {
  approvalStatusResolver,
  getFilledJobsCount,
  getSkillsImages,
} from '@/util/job';
import { pluralizeNumber } from '@/util/number';

type Props = {
  item: OrderListItemFragment;
  to: To;
};

const OrderTableItem = ({ item, to }: Props) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const pluralizedJobsCount = pluralizeNumber(item.jobsCount, 'job', 'jobs');
  const filledJobsCount = getFilledJobsCount(item.jobs);
  const filledJobsContent = `${filledJobsCount}/${item.jobsCount} ${pluralizedJobsCount}`;
  const jobApprovalStatus = approvalStatusResolver(item.status);
  const skillImagesArray = getSkillsImages(item.jobs);

  return (
    <TableRow mobile={phoneOnly}>
      <TableCell noCellTitle stack={phoneOnly}>
        <Link to={to}>
          {phoneOnly && (
            <Small size={'lg'} weight={'semibold'}>
              ID:{' '}
            </Small>
          )}
          <Small
            color={'theme'}
            size={phoneOnly ? 'lg' : 'md'}
            weight={phoneOnly ? 'semibold' : 'normal'}
          >
            #{item.id}
          </Small>
        </Link>
      </TableCell>
      <TableCell noCellTitle stack={phoneOnly}>
        {item.account.name}
      </TableCell>
      <TableCell data-celltitle={'Timeframe: '} stack={phoneOnly}>
        {formatISO(item.startAt, 'PP')} &mdash; {formatISO(item.endAt, 'PP')}
      </TableCell>
      <TableCell data-celltitle={'Approval Status: '} stack={phoneOnly}>
        <Body
          color={jobApprovalStatus.color as BodyVariants['color']}
          size={!phoneOnly ? 'md' : 'sm'}
          weight="medium"
        >
          {jobApprovalStatus.label}
        </Body>
      </TableCell>
      <TableCell data-celltitle={'Filled Jobs: '} stack={phoneOnly}>
        {!phoneOnly ? (
          filledJobsContent
        ) : (
          <Badge label={filledJobsContent} status="success" />
        )}
      </TableCell>
      <TableCell data-celltitle={'Skills: '} stack={phoneOnly}>
        <Stack>
          <AvatarGroupComponent
            items={skillImagesArray}
            maxLimitToShow={4}
            small={phoneOnly}
            type={TypeAvatarGroups.Skill}
          />
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default OrderTableItem;

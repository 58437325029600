import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import Action from '@/components/Action';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Stack from '@/components/Stack';
import { Body, Subheading } from '@/components/Typography';
import { useUserResendTokenMutation } from '@/graphql';
import ROUTES from '@/routes/routes';

const LinkExpired = () => {
  const { caseId } = useParams();
  const [isSending, setIsSending] = useState<boolean>(false);

  const [resendToken, { loading }] = useUserResendTokenMutation();

  const handleSubmit = useCallback(async () => {
    try {
      await resendToken({
        variables: { userId: caseId },
      });
      setIsSending(true);
    } catch (err) {
      console.error(err);
    }
  }, []);

  return (
    <>
      {isSending ? (
        <>
          <Card.Section>
            <Stack vertical verticalGap={10}>
              <Subheading>Check your email</Subheading>
              <Body>
                We send you an email with instructions to activate your account.
              </Body>
            </Stack>
          </Card.Section>
          <Card.Footer>
            <Stack justify="end">
              <Action.Button
                action={{
                  a11yLabel: `Open new tab to login`,
                  label: 'Ok',
                  href: ROUTES.login,
                }}
                appearance="primary"
              />
            </Stack>
          </Card.Footer>
        </>
      ) : (
        <>
          <Card.Section>
            <Stack vertical verticalGap={11}>
              <Subheading>This Link has expired</Subheading>
              <Body>
                To validate your email account please click here to recieve a
                new Link on your email.
              </Body>
            </Stack>
          </Card.Section>
          <Card.Footer justify="end">
            <Button
              a11yLabel="Send new Link"
              isLoading={loading}
              type="button"
              onClick={handleSubmit}
            />
          </Card.Footer>
        </>
      )}
    </>
  );
};

export default LinkExpired;

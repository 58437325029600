import { Region } from '@/types/graphql';

interface RegionFilterInterface {
  label: string;
  value: string;
}

const REGIONS: RegionFilterInterface[] = [];

const setRegions = (data: Region[]) => {
  if (data) {
    REGIONS.length = 0;
    data.forEach((region) => {
      REGIONS.push({ label: region.name, value: region.id });
      REGIONS.sort((a, b) => a.label.localeCompare(b.label));
    });
  }
};

export { REGIONS, setRegions };

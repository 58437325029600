import { useEffect, useState } from 'react';

import { isWithin24Hours } from '@/util/date';

const useCountdown = (targetDate: Date) => {
  const countDownDate = targetDate.getTime();
  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );
  useEffect(() => {
    const interval = setInterval(() => {
      if (
        countDownDate - new Date().getTime() < 0 ||
        !isWithin24Hours(targetDate)
      ) {
        clearInterval(interval);
      } else {
        setCountDown(countDownDate - new Date().getTime());
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getTimeinHoursAndMinutes(countDown);
};

const getTimeinHoursAndMinutes = (countDown) => {
  // calculate time left
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));

  return [hours < 0 ? 0 : hours, minutes < 0 ? 0 : minutes];
};

export { getTimeinHoursAndMinutes, useCountdown };

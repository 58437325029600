import { useReactiveVar } from '@apollo/client';
import { FieldContext, useField } from '@area2k/use-form';
import { useCallback } from 'react';

import { FormValues } from '../';
import { Uniform } from '../../../context';

import UniformItem from './UniformItem';

import FormElement from '@/components/FormElement';
import LoadingState from '@/components/LoadingState';
import Stack from '@/components/Stack';
import TextArea from '@/components/TextArea';
import { TIME_TO_REFRESH } from '@/constants/general';
import { MAX_LENGTH_UNIFORM_INSTRUCTIONS } from '@/constants/text';
import SingleSelectableField from '@/form/SingleSelectableField';
import { useListUniformsQuery } from '@/graphql';
import useMediaQuery from '@/hooks/useMediaQuery';
import { Scalars } from '@/types/graphql';
import { currentAgencyVar } from '@/util/apollo/cache';

type Props = {
  handleUniformChange: <T extends any>(
    fieldContext: FieldContext<T>,
    fieldId: keyof FormValues
  ) => Scalars['Void'];
};

const UniformSection = ({ handleUniformChange }: Props) => {
  const currentAgency = useReactiveVar(currentAgencyVar);
  const { data, loading } = useListUniformsQuery({
    variables: { agencyId: currentAgency!.id },
    pollInterval: TIME_TO_REFRESH,
  });

  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const renderItem = useCallback(
    (item: Uniform) => <UniformItem dense={phoneOnly} item={item} />,
    [phoneOnly]
  );

  const { value, setValue } = useField<Scalars['String']>(
    'uniformInstructions',
    (fieldContext) => {
      handleUniformChange(fieldContext, 'uniformInstructions');
    }
  );

  return (
    <>
      {!data ? (
        <Stack align="center" justify="center">
          <LoadingState overlayColor="white" text="Loading uniforms" />
        </Stack>
      ) : (
        <>
          <FormElement label="Choose the dress code*">
            <Stack
              wrap
              align="center"
              css={{
                alignItems: phoneOnly ? 'stretch' : 'center',
                display: phoneOnly ? 'grid' : null,
                gridTemplateColumns: phoneOnly ? '1fr 1fr 1fr' : null,
              }}
              gap={phoneOnly ? 8 : 16}
              justify={phoneOnly ? 'start' : 'center'}
            >
              <SingleSelectableField<Uniform>
                required
                callback={(fieldContext) => {
                  handleUniformChange(fieldContext, 'uniform');
                  setValue(fieldContext.value?.defaultInstructions!);
                }}
                disabled={loading}
                fieldId="uniform"
                itemToKey={(item) => item.id}
                options={
                  data ? data.agency.uniforms.map((item) => ({ item })) : []
                }
                renderItem={renderItem}
              />
            </Stack>
          </FormElement>
          <div style={{ width: '100%' }}>
            <FormElement
              htmlFor="uniformInstructions"
              label="Dress code instructions"
            >
              <TextArea
                id="uniformInstructions"
                maxLength={MAX_LENGTH_UNIFORM_INSTRUCTIONS}
                name="uniformInstructions"
                placeholder="Provide details on dress code expectations..."
                value={value}
                onChange={(ev) => setValue(ev.currentTarget.value)}
              />
            </FormElement>
          </div>
        </>
      )}
    </>
  );
};

export default UniformSection;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.msgContent a {
    color: #44A735;
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/routes/Agency/MessageContainer/index.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,qBAAqB;AACzB","sourcesContent":["div.msgContent a {\n    color: #44A735;\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

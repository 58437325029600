import Action from '@/components/Action';
import Card from '@/components/Card';
import Stack from '@/components/Stack';
import TextStack from '@/components/TextStack';
import { Body } from '@/components/Typography';
import ROUTES from '@/routes/routes';

const FourthStep = () => {
  return (
    <Card.Section title={'Check your email'}>
      <Stack vertical verticalGap={25}>
        <TextStack>
          <Body>
            We just sent an email with instructions for you to validate your
            GravyWork account.
          </Body>
        </TextStack>
        <Stack justify="end">
          <Action.Button
            action={{
              a11yLabel: 'Navigate to order page',
              label: 'Ok',
              to: ROUTES.login,
              id: 'ok-btn',
            }}
            appearance="primary"
          />
        </Stack>
      </Stack>
    </Card.Section>
  );
};

export default FourthStep;

import { ApolloError } from '@apollo/client';
import {
  faExclamationCircle,
  faFireAlt,
  faQuestionCircle,
  faUnlink,
} from '@fortawesome/free-solid-svg-icons';
import { GraphQLError } from 'graphql';
import { useCallback } from 'react';

import EmptyState, { Props as EmptyStateProps } from '@/components/EmptyState';
import { hasGraphQLErrors } from '@/util/error';

type ErrorEmptyStateProps = Omit<EmptyStateProps, 'error'>;
type ErrorHandler = (err: GraphQLError) => ErrorEmptyStateProps;
export type ErrorMap = { [code: string]: ErrorHandler } & {
  all?: ErrorHandler;
};

const defaultEmptyStateProps: ErrorEmptyStateProps = {
  icon: faExclamationCircle,
  title: 'An error ocurred',
  text: 'An unknown error ocurred while loading your data.',
};

const defaultErrorMap: ErrorMap = {
  all: () => defaultEmptyStateProps,
};

export type Props = {
  error: ApolloError;
  errorMap?: ErrorMap;
};

const ErrorEmptyState = ({ error, errorMap = defaultErrorMap }: Props) => {
  const defaultErrorHandler = useCallback(
    (gqlError: GraphQLError) =>
      errorMap.all ? errorMap.all(gqlError) : defaultEmptyStateProps,
    [errorMap.all]
  );

  if (hasGraphQLErrors(error)) {
    const graphQLError = error.graphQLErrors[0];
    const emptyStateProps =
      graphQLError && graphQLError.extensions
        ? (errorMap[graphQLError.extensions.code] || defaultErrorHandler)(
            graphQLError
          )
        : defaultEmptyStateProps;

    return <EmptyState error {...emptyStateProps} />;
  } else {
    if (error.networkError) {
      if (error.networkError.message.endsWith('Failed to fetch')) {
        return (
          <EmptyState
            error
            icon={faUnlink}
            text="Unable to connect to the remote server."
            title="Connection error"
          />
        );
      } else if (error.networkError.name === 'ServerError') {
        return (
          <EmptyState
            error
            icon={faFireAlt}
            text="A server error has ocurrred, please contact support."
            title="Server error"
          />
        );
      } else {
        return (
          <EmptyState
            error
            icon={faQuestionCircle}
            text="An unknown network error has ocurred, please contact support."
            title="Network error"
          />
        );
      }
    } else {
      return (
        <EmptyState
          error
          icon={faQuestionCircle}
          text="An unknown error has ocurred, please contact support."
          title="Unknown error"
        />
      );
    }
  }
};

export default ErrorEmptyState;

import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';

import JobStatusFilter from './JobStatusFilter';

import Avatar from '@/components/Avatar';
import Badge from '@/components/Badge';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Dropdown from '@/components/Dropdown';
import LoadingState from '@/components/LoadingState';
import Pagination from '@/components/Pagination';
import QueryEmptyState from '@/components/QueryEmptyState';
import Stack from '@/components/Stack';
import Table from '@/components/Table';
import TableHeader from '@/components/TableHeader';
import Tabs, { TabDefinition } from '@/components/Tabs';
import TextInput from '@/components/TextInput';
import { TIME_TO_REFRESH } from '@/constants/general';
import {
  JobWorkerStatusFilterEnum,
  JobWorkerStatusFilterEnumType,
} from '@/constants/job';
import Link from '@/elements/Link';
import { TableCell, TableRow } from '@/elements/Table';
import { useGetWorkerJobsQuery } from '@/graphql';
import useAuth from '@/hooks/useAuth';
import useFilters from '@/hooks/useFilters';
import ROUTES from '@/routes/routes';
import styled from '@/styles';
import {
  GetWorkerJobsQuery,
  GetWorkerQuery,
  JobWorkerFilterSetInput,
} from '@/types/graphql';
import {
  jobStatusResolver,
  jobWorkerStatusResolver,
  sortByDate,
} from '@/util/job';

const StyledStatusFilter = styled('div', {
  padding: 16,
});

const JOBS_TABS: TabDefinition[] = [
  { a11yLabel: 'View current jobs for this GravyWorker', label: 'Current' },
  { a11yLabel: 'View past jobs for this GravyWorker', label: 'Past' },
];

const initialFilters: JobWorkerFilterSetInput = {};

type JobItem = GetWorkerJobsQuery['worker']['jobWorkers']['items'][0]['job'] &
  Pick<GetWorkerJobsQuery['worker']['jobWorkers']['items'][0], 'showed'> & {
    jobWorkerStatus: JobWorkerStatusFilterEnumType;
  };

type Props = {
  worker: GetWorkerQuery['worker'];
  currentAdminIsCustomerAdmin: boolean;
};

export const filterOptions = [
  { label: 'All', value: JobWorkerStatusFilterEnum.ALL },
  { label: 'In Progress', value: JobWorkerStatusFilterEnum.IN_PROGRESS },
  { label: 'Approved', value: JobWorkerStatusFilterEnum.APPROVED },
  { label: 'Completed', value: JobWorkerStatusFilterEnum.COMPLETED },
  { label: 'Dismissed', value: JobWorkerStatusFilterEnum.DISMISSED },
  { label: 'Dropped', value: JobWorkerStatusFilterEnum.DROPPED },
  { label: 'Expired', value: JobWorkerStatusFilterEnum.EXPIRED },
  { label: 'Hired', value: JobWorkerStatusFilterEnum.HIRED },
  { label: 'Paid', value: JobWorkerStatusFilterEnum.PAID },
];

const headerFields = [
  ['Skill', 'Client', 'Timeframe', 'Status', 'Job Status'],
  ['Skill', 'Client', 'Timeframe', 'Status', 'Job Status'],
];

const JobsList = ({ worker, currentAdminIsCustomerAdmin }: Props) => {
  const [tab, setTab] = useState(0);
  const [page, setPage] = useState(1);
  const [selectedStatus, setSelectedStatus] =
    useState<JobWorkerStatusFilterEnumType>(JobWorkerStatusFilterEnum.ALL);

  const { currentAdmin, isCurrentCustomerAdmin } = useAuth();

  const {
    debouncedQuery,
    filters,
    query: searchQuery,
    clearFilter,
    setFilter,
    setQuery,
  } = useFilters<JobWorkerFilterSetInput>(initialFilters);

  const finalFilters = useMemo<JobWorkerFilterSetInput>(() => {
    const value = { ...filters };

    if (debouncedQuery !== '') {
      value.query = { value: debouncedQuery };
    }

    return value;
  }, [debouncedQuery, filters]);

  const query = useGetWorkerJobsQuery({
    variables: {
      filters: finalFilters,
      workerId: worker.id,
      page,
      perPage: 5,
    },
    pollInterval: TIME_TO_REFRESH,
  });

  const TableBody = useCallback(
    ({ jobs }: { jobs: JobItem[] }) => (
      <tbody>
        {jobs.map((item: JobItem) => {
          const permittedNavigate =
            currentAdminIsCustomerAdmin &&
            isCurrentCustomerAdmin(currentAdmin) &&
            currentAdmin.customer.id === item.customer.id;

          const jobStatusBadgeInfo = jobStatusResolver(item.status);
          const jobWorkerStatusBadgeInfo = jobWorkerStatusResolver(
            item.jobWorkerStatus
          );

          return (
            <TableRow key={item.id}>
              <TableCell>
                <Stack gap={12}>
                  <Avatar size="xs" src={item.skill.imageUrl} />
                  <Link
                    to={
                      !currentAdminIsCustomerAdmin
                        ? `../../orders/${item.order.id}/jobs/${item.id}`
                        : permittedNavigate
                        ? `../../orders/${item.order.id}/jobs/${item.id}`
                        : ''
                    }
                  >
                    {item.skill.name}
                  </Link>
                </Stack>
              </TableCell>
              <TableCell>
                <Stack gap={12}>
                  <div>
                    <Avatar
                      firstName={item.customer.name}
                      size="xs"
                      src={item.customer.logoUrl}
                    />
                  </div>
                  <Link
                    to={
                      !currentAdminIsCustomerAdmin
                        ? `../../clients/${item.customer.id}`
                        : permittedNavigate
                        ? `../../${ROUTES.myCompany}`
                        : ''
                    }
                  >
                    {item.customer.name}
                  </Link>
                </Stack>
              </TableCell>
              <TableCell>
                {format(new Date(item.firstShiftStartAt), 'PP')} &mdash;{' '}
                {format(new Date(item.lastShiftEndAt), 'PP')}
              </TableCell>
              <TableCell>
                {!item.showed ? (
                  <Badge label="No show" size="sm" status="warning" />
                ) : (
                  <Badge
                    label={jobWorkerStatusBadgeInfo.label}
                    status={jobWorkerStatusBadgeInfo.color}
                  />
                )}
              </TableCell>
              <TableCell align="center">
                <Badge
                  label={jobStatusBadgeInfo.label}
                  status={jobStatusBadgeInfo.color}
                />
              </TableCell>
            </TableRow>
          );
        })}
        {query.loading && (
          <TableRow>
            <TableCell align="center" colSpan={100}>
              <LoadingState overlayColor="white" />
            </TableCell>
          </TableRow>
        )}
      </tbody>
    ),
    [currentAdmin, query]
  );

  const { jobWorkers } = query.data?.worker || {};
  const { items, pageInfo } = jobWorkers || {};

  const jobs = items
    ? items.map((item) => ({
        ...item.job,
        jobWorkerStatus: item.status,
        showed: item.showed,
      }))
    : [];

  const sortJobsByDate = sortByDate(jobs) as JobItem[];

  const parseOption = useMemo(() => {
    const currentJobs = filterOptions.filter(
      (item) =>
        item.value === JobWorkerStatusFilterEnum.HIRED ||
        item.value === JobWorkerStatusFilterEnum.IN_PROGRESS ||
        item.value === JobWorkerStatusFilterEnum.ALL
    );

    const pastJobs = filterOptions.filter(
      (item) =>
        item.value !== JobWorkerStatusFilterEnum.HIRED &&
        item.value !== JobWorkerStatusFilterEnum.IN_PROGRESS
    );

    return tab === 0 ? currentJobs : pastJobs;
  }, [tab]);

  useEffect(() => {
    tab === 0
      ? setFilter('currentJobs', { value: true })
      : setFilter('currentJobs', { value: false });
  }, [tab]);

  const DropdownContent = useCallback(
    () => (
      <StyledStatusFilter>
        <JobStatusFilter
          idPrefix="dropdown"
          jobStatusValue={selectedStatus}
          options={parseOption}
          setJobStatusValue={setSelectedStatus}
          onChangeFilter={setFilter}
          onClearFilter={clearFilter}
        />
      </StyledStatusFilter>
    ),
    [selectedStatus, tab]
  );

  return (
    <Card title="Jobs">
      <Tabs
        fit
        selected={tab}
        showTabOnMobile={false}
        tabs={JOBS_TABS}
        onSelect={setTab}
      />
      <Card.Section>
        <Stack gap={16}>
          <div style={{ flex: 1 }}>
            <TextInput
              id="input-search-jobs"
              placeholder="Search for jobs..."
              value={searchQuery}
              onChange={(ev) => {
                setQuery(ev.currentTarget.value);
              }}
            />
          </div>
          <Dropdown Content={DropdownContent} justify="right" size="sm">
            <Button
              hasPopover
              a11yLabel="View job worker status filters"
              appearance="outline"
              id="btn-job-status-filter"
              label="Status"
            />
          </Dropdown>
        </Stack>
      </Card.Section>
      <Card>
        <Table>
          <TableHeader fields={headerFields[tab]} />
          <TableBody jobs={sortJobsByDate} />
        </Table>
        {!query.loading && jobs.length === 0 && (
          <QueryEmptyState
            query={query}
            text="If there were any, they would be here."
            title="No Jobs"
          />
        )}
        {jobs.length > 0 && pageInfo && pageInfo.totalPages > 1 && (
          <Card.Section>
            <Pagination
              currentPage={page}
              totalPages={pageInfo.totalPages}
              onPageChange={setPage}
            />
          </Card.Section>
        )}
      </Card>
    </Card>
  );
};

export default JobsList;

import { useEffect } from 'react';

import ItemSelectField from '@/form/ItemSelectField';
import { useListJobCustomerAdminsLazyQuery } from '@/graphql';
import {
  GetJobQuery,
  ListJobCustomerAdminsQuery,
  Scalars,
} from '@/types/graphql';

export type ContactItem =
  ListJobCustomerAdminsQuery['job']['customer']['admins']['items'][0];

type Props = {
  fieldId: Scalars['String'];
  label: Scalars['String'];
  job: GetJobQuery['job'];
};

const ContactSelectField = ({ fieldId, label, job: { id } }: Props) => {
  const [fetch, { data, loading }] = useListJobCustomerAdminsLazyQuery();

  useEffect(() => {
    fetch({ variables: { jobId: id } });
  }, []);

  return (
    <ItemSelectField<ContactItem>
      required
      disabled={loading}
      fieldId={fieldId}
      itemToKey={(item) => item.id}
      itemToString={(item) =>
        item ? `${item.user.firstName} ${item.user.lastName}` : ''
      }
      items={data ? data.job.customer.admins.items : []}
      label={label}
    />
  );
};

export default ContactSelectField;

import { useState } from 'react';

interface Props {
  min?: number;
  max?: number;
  onChange: (value: number) => void;
  value?: number;
}

interface ReturnType {
  count: number;
  increment: () => void;
  decrement: () => void;
  reset: () => void;
}

const useCounter = ({ min, max, onChange, value = 0 }: Props): ReturnType => {
  const [count, setCount] = useState<number>(value);

  const handleChange = (value: number) => {
    setCount(value);
    onChange(value);
  };

  const increment = () => {
    if (max && count >= max) {
      return;
    }
    handleChange(count + 1);
  };

  const decrement = () => {
    if (min && count <= min) {
      return;
    }
    handleChange(count - 1);
  };

  const reset = () => {
    handleChange(min ?? 0);
  };

  return { count, increment, decrement, reset };
};

export default useCounter;

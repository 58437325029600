import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { Body } from '@/components/Typography';
import { Scalars } from '@/types/graphql';

type Props = {
  hideModal: () => Scalars['Void'];
  onAction: () => Scalars['Void'];
};

const RemoveSkillModalConfirm = ({ hideModal, onAction }: Props) => {
  return (
    <Modal
      size="sm"
      title="Are you sure you want to remove the skill?"
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Stack vertical>
          <Body>
            {`Please note that removing a skill from a worker's profile will
            dismiss them from all upcoming jobs matching the skill and will
            cancel any related job invitations.`}
          </Body>

          <Stack justify="end">
            <Button
              a11yLabel="deny"
              appearance="outline"
              label="Cancel"
              status="danger"
              type="button"
              onClick={() => {
                hideModal();
              }}
            />

            <Button
              a11yLabel={'Accept'}
              label="Accept"
              type="button"
              onClick={() => {
                onAction();
              }}
            />
          </Stack>
        </Stack>
      </Card.Section>
    </Modal>
  );
};

export default RemoveSkillModalConfirm;

export const TIME_TO_REFRESH = 120000;
export const USER_FETCH_LIMIT = 100;
export const MIN_WORDS_TO_CAPITALIZE = 2;
export const PUBNUB_CHAT_LIMIT = 100;
export const PUBNUB_INITIAL_CHAT_LIMIT = 25;
export const ROUND_OFF_MINUTES = 15;
export const DEBOUNCE_TIME = 300;
export const PAST_DAYS = 14;
export const INTERVAL_IN_MINUTES = 238;
export const MAX_LIMIT_OF_GROUP_WORKERS = 200;
export const INCORRECT_STATE_ERROR_MSG =
  'Sorry, Gravywork doesn’t operate in this area';
export const DISABLE_BREAK_MINUTES = 359;
export const GRAVY_CERTIFICATES = [
  'GravyTrained Banquets',
  'GravyTrained Bartender',
];
export const WORKERS_LISTING_LIMIT = 15;

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { isValid, parseISO } from 'date-fns';
import { useEffect, useReducer, useRef } from 'react';
import { CSVLink } from 'react-csv';

import DownloadIcon from '../DownloadIcon';

import Stack from '@/components/Stack';
import Icon from '@/elements/Icon';
import Input from '@/elements/Input';
import { TableCell } from '@/elements/Table';
import Text from '@/elements/Text';
import { useRevenueByRangeLazyQuery } from '@/graphql';
import styled from '@/styles';
import { RevenueReport } from '@/types/graphql';

type Props = {
  setError: any; // TODO
};

type CustomerRevenueReportType = Omit<RevenueReport, '__typename' | 'period'>;

enum stateActionsKind {
  CHANGE_START_DATE = 'CHANGE_START_DATE',
  CHANGE_END_DATE = 'CHANGE_END_DATE',
  SET_CSV_DATA = 'SET_CSV_DATA',
}

interface RevenueActions {
  type: stateActionsKind;
  value: any;
}

interface HeaderType {
  label: string;
  key: keyof CustomerRevenueReportType;
}

interface RevenueState {
  startDate: string;
  endDate: string;
  csvData: CustomerRevenueReportType[] | null;
}

const Label = styled('p', {
  fontSize: '$md',
});

const inputDateCustomCSS = {
  width: '200px',
};

const filename = `RevenueReport-${Date.now()}.csv`;

const headers: HeaderType[] = [
  { label: 'Company', key: 'company' },
  { label: 'Region', key: 'region' },
  { label: 'Total Hours', key: 'totalHours' },
  { label: 'Total Revenue', key: 'totalRevenue' },
  { label: 'Total Payroll Expense', key: 'totalPayrollExpense' },
  { label: 'Net Profit', key: 'netProfit' },
];

const initialState = {
  startDate: '',
  endDate: '',
  csvData: null,
};

function reducer(state: RevenueState, action: RevenueActions) {
  switch (action.type) {
    case stateActionsKind.CHANGE_START_DATE: {
      return {
        ...state,
        startDate: action.value,
      };
    }

    case stateActionsKind.CHANGE_END_DATE: {
      return {
        ...state,
        endDate: action.value,
      };
    }

    case stateActionsKind.SET_CSV_DATA: {
      return {
        ...state,
        csvData: action.value,
      };
    }

    default: {
      return state;
    }
  }
}

const Revenue = ({ setError }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const csvInstance = useRef<any>();

  const isBeforeError = parseISO(state.startDate) > parseISO(state.endDate);
  const isValidDates =
    isValid(parseISO(state.startDate)) && isValid(parseISO(state.endDate));

  const [getRevenueByRange, { loading }] = useRevenueByRangeLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (data) =>
      dispatch({
        type: stateActionsKind.SET_CSV_DATA,
        value: data.revenueReport,
      }),
    onError: (error) => setError({ message: error.message }),
  });

  useEffect(() => {
    if (state.csvData && csvInstance.current && csvInstance.current.link) {
      csvInstance.current.link.click();
      dispatch({ type: stateActionsKind.SET_CSV_DATA, value: null });
    }
  }, [state.csvData]);

  function onDownload() {
    const { startDate, endDate } = state;

    if (startDate.length === 0 && endDate.length === 0) {
      return;
    }

    if (isBeforeError) {
      return;
    }

    getRevenueByRange({ variables: { from: startDate, to: endDate } });
  }

  return (
    <>
      <TableCell>
        <Stack wrap>Revenue Report</Stack>
        {isBeforeError && (
          <Text color="danger" size="sm">
            <Icon icon={faExclamationTriangle} /> The start date must be before
            the end date
          </Text>
        )}
      </TableCell>
      <TableCell>
        <Stack>
          <Label>Start Date</Label>
          <Input
            css={inputDateCustomCSS}
            type="date"
            value={state.startDate}
            onChange={(e) =>
              dispatch({
                type: stateActionsKind.CHANGE_START_DATE,
                value: e.target.value,
              })
            }
          />
        </Stack>
      </TableCell>
      <TableCell>
        <Stack>
          <Label>End Date</Label>
          <Input
            css={inputDateCustomCSS}
            type="date"
            value={state.endDate}
            onChange={(e) =>
              dispatch({
                type: stateActionsKind.CHANGE_END_DATE,
                value: e.target.value,
              })
            }
          />
        </Stack>
      </TableCell>
      <TableCell>
        <DownloadIcon
          disabled={!isValidDates || isBeforeError}
          isLoading={loading}
          onClick={onDownload}
        />
        {state.csvData && (
          <CSVLink
            ref={csvInstance}
            data={state.csvData}
            filename={filename}
            headers={headers}
          />
        )}
      </TableCell>
    </>
  );
};

export default Revenue;

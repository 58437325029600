import styled from '@/styles';
import { IconColor } from '@/styles/colors';

export const RatingLabel = styled('span', {
  fontSize: '20px',
  color: IconColor,
});

export const Container = styled('div', {
  position: 'relative',
  display:
    'inline-block' /* Make sure the container is inline-block to fit the avatar size */,
});

export const OverlayImage = styled('img', {
  position: 'absolute',
  top: 140,
  left: 130,
  right: 0,
  width: '56px',
  height: '56px',
});

export const OverlaySkillCountWrapper = styled('div', {
  position: 'absolute',
  top: 52,
  left: 44,
  right: 0,
});

export const OverlayCertificateImage = styled('img', {
  position: 'absolute',
  top: 50,
  left: 50,
  right: 0,
  width: '30px',
  height: '30px',
});

import StarIcon from '@mui/icons-material/Star';
import { Stack, Divider } from '@mui/material';

import { RatingLabel } from './styles';

import TextStack from '@/components/TextStack';
import { Small, Body } from '@/components/Typography';
import { IconColor } from '@/styles/colors';
import './index.css';

type WorkerDetailsItemProps = {
  averageRating: number;
  jobsDropped: number;
  jobsRatingCount: number;
  noShow: number | null;
  jobsWorked: number;
};

type WorkerDetailsSectionProps = {
  ProfileInfo: WorkerDetailsItemProps;
  isClientAdmin: boolean;
};

const WorkerDetailsSection = ({
  ProfileInfo,
  isClientAdmin,
}: WorkerDetailsSectionProps) => {
  return (
    <>
      <Stack
        flexDirection="row"
        gap="10px"
        marginTop={'12px !important'}
        width={isClientAdmin ? '50%' : '100%'}
      >
        <TextStack align="center" spacing="loose">
          <Stack alignItems="center" flexDirection="row" gap="8px">
            <StarIcon
              data-testid="star-icon"
              id="star-icon"
              sx={{ color: '#faaf00', width: '20px', height: '20px' }}
            />
            <Body size="xl" weight="bold">
              {ProfileInfo.averageRating
                ? Number(ProfileInfo.averageRating).toFixed(1)
                : '0.0'}
            </Body>
            <RatingLabel>{`(${
              ProfileInfo?.jobsRatingCount || '0'
            })`}</RatingLabel>
          </Stack>
          <Small css={{ color: IconColor }}>Rating</Small>
        </TextStack>
        <Divider
          style={{ borderWidth: '1px', height: '25px', marginTop: '10px' }}
        />
        <TextStack align="center" spacing="loose">
          <Body size="xl" weight="bold">
            {ProfileInfo.jobsWorked ?? ''}
          </Body>
          <Small css={{ color: IconColor }}>Shifts Worked</Small>
        </TextStack>

        {!isClientAdmin && (
          <>
            <Divider
              style={{ borderWidth: '1px', height: '25px', marginTop: '10px' }}
            />
            <TextStack align="center" spacing="loose">
              <Body size="xl" weight="bold">
                {ProfileInfo.jobsDropped ?? ''}
              </Body>
              <Small css={{ color: IconColor }}>Shifts Dropped</Small>
            </TextStack>
            <Divider
              style={{ borderWidth: '1px', height: '25px', marginTop: '10px' }}
            />
            <TextStack align="center" spacing="loose">
              <Body size="xl" weight="bold">
                {ProfileInfo.noShow ?? ''}
              </Body>
              <Small css={{ color: IconColor }}>No Show</Small>
            </TextStack>
          </>
        )}
      </Stack>
    </>
  );
};

export default WorkerDetailsSection;

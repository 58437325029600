import { onRefetchType } from '../modals/ChangePaymentModal';

import Button from '@/components/Button';
import {
  EditorTypeEnum,
  SelectedPaymentMethodType,
} from '@/components/PaymentMethod';
import Stack from '@/components/Stack';
import { Body } from '@/components/Typography';
import { useDettachPaymentMethodMutation } from '@/graphql';
import { GetAccountQuery, PaymentMethodEnum } from '@/types/graphql';
import {
  checkIfPaymentMethodIsBankAccountType,
  checkIfPaymentMethodIsCardType,
} from '@/util/payments';

type Props = {
  account: GetAccountQuery['account'];
  kind: EditorTypeEnum;
  paymentMethod: SelectedPaymentMethodType;
  onCloseEditor: () => void;
  onRefetch: onRefetchType;
};

const DettachPaymentMethod = ({
  account,
  kind,
  paymentMethod,
  onCloseEditor,
  onRefetch,
}: Props) => {
  const [dettachPaymentMethod, { loading }] = useDettachPaymentMethodMutation();

  const onDettachPaymentMethod = async () => {
    try {
      await dettachPaymentMethod({
        variables: {
          account: account.id,
          paymentMethodType:
            kind === EditorTypeEnum.REMOVE_CARD
              ? PaymentMethodEnum.CARD
              : PaymentMethodEnum.BANK_ACCOUNT,
          paymentMethodId: paymentMethod!.id,
        },
      });
      await onRefetch();
      onCloseEditor();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Stack vertical gap={24}>
      <Stack>
        {
          {
            [EditorTypeEnum.REMOVE_CARD]: (
              <>
                {checkIfPaymentMethodIsCardType(paymentMethod) && (
                  <>
                    <Body>Are you sure you want to remove card</Body>
                    <Body weight="bold">{`${paymentMethod.card.brand} **** ${paymentMethod.card.last4} ?`}</Body>
                  </>
                )}
              </>
            ),
            [EditorTypeEnum.REMOVE_ACCOUNT]: (
              <>
                <Body>Are you sure you want to remove the bank account</Body>
                {checkIfPaymentMethodIsBankAccountType(paymentMethod) && (
                  <Body weight="bold">{`${paymentMethod.bankName} **** ${paymentMethod.last4} ?`}</Body>
                )}
              </>
            ),
          }[kind]
        }
      </Stack>
      <Stack justify="end">
        <Button
          a11yLabel="Back"
          appearance="outline"
          label="Back"
          type="button"
          onClick={onCloseEditor}
        />
        <Button
          a11yLabel="Remove Payment Method"
          appearance="primary"
          isLoading={loading}
          label="Remove"
          type="button"
          onClick={onDettachPaymentMethod}
        />
      </Stack>
    </Stack>
  );
};

export default DettachPaymentMethod;

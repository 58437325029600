import styled from '@/styles';

export const JobTypeSectionContainer = styled('div', {
  textAlign: 'center',
  padding: 16,
  borderRadius: 8,
  width: 630,
  height: 130,
  '@phoneOnly': {
    width: '100%',
    height: 'auto',
  },
});

export const JobTypeSectionIconWrapper = styled('div', {
  padding: 6,
  borderRadius: 8,
  background: '#F4F6F3',
  width: 80,
  height: 80,
});

import { ApolloError } from '@apollo/client';
import { useEffect, useState } from 'react';

import { SubmitErrorAlert } from '@/components/Alerts';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { Body } from '@/components/Typography';
import { useUpdateInterviewStatusMutation } from '@/graphql';
import { GetWorkerQuery, InterviewStatusEnum } from '@/types/graphql';

type Props = {
  worker: GetWorkerQuery['worker'];
  interviewId: string;
  hideModal: () => void;
};

const UpdateInterviewStatusModal = ({
  worker,
  interviewId,
  hideModal,
}: Props) => {
  const [status, setStatus] = useState<InterviewStatusEnum | null>(null);
  const [error, setError] = useState<ApolloError | null>(null);
  const [updateInterviewStatus, { loading }] = useUpdateInterviewStatusMutation(
    {
      onCompleted: hideModal,
      onError: setError,
      update: (cache) => {
        cache.modify({
          id: cache.identify(worker),
          fields: {
            interviewStatus() {},
          },
        });
      },
    }
  );

  useEffect(() => {
    if (status) {
      updateInterviewStatus({
        variables: { workerInterviewId: interviewId, status },
      });
    }
  }, [status]);

  return (
    <Modal
      disableClickout
      size="xs"
      title="Video Questionnaire Status"
      onRequestClose={hideModal}
    >
      <Card.Section>
        {error && (
          <SubmitErrorAlert
            description={'The video questionnaire could not approve/reject.'}
          />
        )}
        <Stack>
          <Body>
            Are you sure you want to update the video questionnaire status?
          </Body>
        </Stack>
      </Card.Section>
      <Card.Footer>
        <Stack justify="end">
          <Button
            a11yLabel="Approve"
            isLoading={
              status === InterviewStatusEnum.INTERVIEW_PASSED && loading
            }
            label="Approve"
            onClick={() => setStatus(InterviewStatusEnum.INTERVIEW_PASSED)}
          />
          <Button
            a11yLabel="Reject"
            isLoading={
              status === InterviewStatusEnum.INTERVIEW_DECLINED && loading
            }
            label="Reject"
            status="danger"
            onClick={() => setStatus(InterviewStatusEnum.INTERVIEW_DECLINED)}
          />
        </Stack>
      </Card.Footer>
    </Modal>
  );
};

export default UpdateInterviewStatusModal;

import { createSvgIcon } from '@mui/material';

const AccountRecordIcon = createSvgIcon(
  <svg
    fill="none"
    height="17"
    viewBox="0 0 17 17"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.08333 15.3833L8 14.6667L5.5 16.3333L3 14.6667L0.5 16.3333V0.5H15.5V9.11667C14.975 8.93333 14.4167 8.83333 13.8333 8.83333V2.16667H2.16667V13.2167L3 12.6667L5.5 14.3333L8 12.6667L8.86667 13.2417C8.83333 13.4167 8.83333 13.6333 8.83333 13.8333C8.83333 14.375 8.91667 14.9 9.08333 15.3833ZM12.1667 3.83333V5.5H3.83333V3.83333H12.1667ZM10.5 7.16667V8.83333H3.83333V7.16667H10.5ZM13 13V11.3333L10.5 13.8333L13 16.3333V14.6667H16.3333V13H13Z"
      fill="#44A735"
    />
  </svg>,
  'AccountRecordIcon'
);
export default AccountRecordIcon;

import { useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';

import { currentAdminVar } from './apollo/cache';
import { WorkerTabs } from './axios/types';
import { isIOS, isWeb } from './platform';

import { GAEvent, eventMap } from '@/constants/gaevents';

const useAnalytics = (trackingId?: string) => {
  useEffect(() => {
    if (trackingId) {
      ReactGA.initialize(trackingId, {
        gaOptions: {
          debug_mode: true,
        },
        gtagOptions: {
          debug_mode: true,
        },
      });
    }
  }, []);

  const resetGoogleAnalytics = () => {
    ReactGA.set({
      user_id: null,
    });
  };
  const currentAdmin = useReactiveVar(currentAdminVar);

  const setUser = (userId: string) => {
    if (!process.env.GA_TRACKING_ID) return;
    ReactGA.set({
      user_id: userId,
    });
  };

  const logEvent = (
    event: GAEvent | WorkerTabs,
    value?: number | string,
    paramters?: any
  ) => {
    if (!process.env.GA_TRACKING_ID) return;

    value = value ? Number(value) : undefined;
    const { category, action, label } = eventMap[event];

    const defaultUserData = {
      role: currentAdmin?.role === 0 ? 'customer_admin' : 'tenant_admin',
      user_id: currentAdmin?.user.id,
      admin_name: `${currentAdmin?.user.firstName} ${currentAdmin?.user.lastName}`,
      platform: isWeb() ? 'web' : isIOS() ? 'ios' : 'android',
      ...(currentAdmin?.role === 0 && {
        client_name: currentAdmin?.customer.name,
      }),
    };

    ReactGA.send({
      hitType: 'event',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      ...paramters,
      ...(defaultUserData.role !== '' && defaultUserData),
    });
  };

  const logPageView = (path: string) => {
    ReactGA.send({ hitType: 'pageview', path });
  };

  return {
    logEvent,
    logPageView,
    setUser,
    resetGoogleAnalytics,
  };
};

export default useAnalytics;

import { useReactiveVar } from '@apollo/client';
import useModal from '@area2k/use-modal';
import { useCallback, useEffect, useMemo, useState } from 'react';

import ContactFilters from './ContactFilters';
import CreateAdminModal from './CreateAdminModal';
import TableBodyCustomerAdmin from './Tables/TableBodyCustomerAdmin';
import TableBodyTenantAdmin from './Tables/TableBodyTenantAdmin';

import Card from '@/components/Card';
import Page from '@/components/Page';
import Paginator from '@/components/Paginator/Paginator';
import QueryEmptyState from '@/components/QueryEmptyState';
import SuccessfulCreateAlert from '@/components/SuccessfulCreationAlert';
import Table from '@/components/Table';
import TableHeader from '@/components/TableHeader';
import Tabs, { TabDefinition } from '@/components/Tabs';
import { TIME_TO_REFRESH } from '@/constants/general';
import { useListCustomerAdminQuery, useListTenantAdminQuery } from '@/graphql';
import useFilters from '@/hooks/useFilters';
import {
  CustomerAdminFilterSetInput,
  TenantAdminFilterSetInput,
  User,
} from '@/types/graphql';
import { currentAgencyVar } from '@/util/apollo/cache';

const initialFilters: CustomerAdminFilterSetInput = {};

export type ItemCreated = {
  created: boolean;
  data: Pick<User, 'id' | 'firstName' | 'lastName'> | undefined;
};

const RELATIONSHIP_TABS: TabDefinition[] = [
  { a11yLabel: 'View Gravy Admins', label: 'Gravy Admin' },
  { a11yLabel: 'View Client Admins', label: 'Client Admin' },
];

const Contacts = () => {
  const initialAdminCreated: ItemCreated = {
    created: false,
    data: {
      id: '',
      firstName: '',
      lastName: '',
    },
  };

  const [createdItem, setCreatedItem] =
    useState<ItemCreated>(initialAdminCreated);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [tab, setTab] = useState<number>(1);

  const [showCreateModal, hideCreateModal] = useModal(
    () => (
      <CreateAdminModal
        hideModal={hideCreateModal}
        setCreatedItem={setCreatedItem}
      />
    ),
    [setCreatedItem]
  );

  const [showSuccessfulAlertModal, hideSuccessfulAlertModal] = useModal(
    () => (
      <SuccessfulCreateAlert
        body="The administrator has been invited to access the account."
        hideModal={hideSuccessfulAlertModal}
        item={`${createdItem.data!.firstName} ${createdItem.data!.lastName}`}
      />
    ),
    [createdItem]
  );

  const {
    debouncedQuery,
    filters,
    query: searchQuery,
    clearAll,
    clearFilter,
    setFilter,
    setQuery,
  } = useFilters<CustomerAdminFilterSetInput>(initialFilters);

  const finalFilters = useMemo<CustomerAdminFilterSetInput>(() => {
    const value = { ...filters };

    if (debouncedQuery !== '') {
      value.query = { value: debouncedQuery };
    }

    return value;
  }, [debouncedQuery, filters]);

  const tenantFilters = useMemo<TenantAdminFilterSetInput>(() => {
    const value = { ...filters };

    if (debouncedQuery !== '') {
      value.query = { value: debouncedQuery };
    }

    return value;
  }, [debouncedQuery, filters]);

  const currentAgency = useReactiveVar(currentAgencyVar);

  const tenantAdminListQuery = useListTenantAdminQuery({
    variables: {
      agencyId: currentAgency!.id,
      filters: tenantFilters,
    },
    pollInterval: TIME_TO_REFRESH,
  });

  const customerAdminListQuery = useListCustomerAdminQuery({
    variables: {
      agencyId: currentAgency!.id,
      filters: finalFilters,
      page: currentPage,
      perPage: itemsPerPage,
    },
    pollInterval: TIME_TO_REFRESH,
  });

  const handlePageChange = useCallback((pageNumber: number) => {
    setCurrentPage(pageNumber);
  }, []);

  const handleNumberItemsChange = useCallback((event) => {
    setCurrentPage(1);
    setItemsPerPage(parseInt(event.target.value));
  }, []);

  const headerFieldsTenant = useMemo(
    () => ['User', 'Phone Number', 'Status'],
    []
  );

  const headerFieldsCustomerAdmin = useMemo(
    () => ['Contact Name', 'Client', 'Phone Number', 'Status'],
    []
  );

  const tenantItems = useMemo(
    () =>
      !tenantAdminListQuery.loading && tenantAdminListQuery.data
        ? tenantAdminListQuery.data.agency.tenant.admins
        : [],
    [tenantAdminListQuery]
  );

  const customerAdminItems = useMemo(
    () =>
      !customerAdminListQuery.loading && customerAdminListQuery.data
        ? customerAdminListQuery.data.agency.customerAdmins.items
        : [],
    [customerAdminListQuery]
  );

  const pageInfo = useMemo(
    () =>
      customerAdminListQuery.data?.agency.customerAdmins.pageInfo.totalItems ||
      0,
    [customerAdminListQuery]
  );

  useEffect(() => {
    if (createdItem.created) {
      showSuccessfulAlertModal();
    }
  }, [createdItem, showSuccessfulAlertModal]);

  useEffect(() => {
    if (searchQuery !== '') setCurrentPage(1);
  }, [searchQuery]);

  return (
    <Page
      primaryAction={{
        a11yLabel: 'Create a Admin',
        id: 'new-admin-btn',
        label: 'New Admin',
        onAction: showCreateModal,
      }}
      size="md"
      title="Contacts"
    >
      <Card>
        <Tabs
          fit
          selected={tab}
          showTabOnMobile={false}
          tabs={RELATIONSHIP_TABS}
          onSelect={setTab}
        />
        <Card.Section>
          <ContactFilters
            filters={filters}
            query={searchQuery}
            onChangeFilter={setFilter}
            onClearAll={clearAll}
            onClearFilter={clearFilter}
            onQueryChange={setQuery}
          />
        </Card.Section>
        <Table>
          <TableHeader
            fields={tab === 0 ? headerFieldsTenant : headerFieldsCustomerAdmin}
          />
          {tab === 0 ? (
            <TableBodyTenantAdmin
              headerFields={headerFieldsTenant}
              isLoading={tenantAdminListQuery.loading}
              items={tenantItems}
            />
          ) : (
            <TableBodyCustomerAdmin
              headerFields={headerFieldsCustomerAdmin}
              isLoading={customerAdminListQuery.loading}
              items={customerAdminItems}
            />
          )}
        </Table>
        {tab === 0 && tenantItems.length === 0 && (
          <QueryEmptyState
            query={tenantAdminListQuery}
            text="If there were any, they would be here."
            title="No tenant administrators available"
          />
        )}
        {tab === 1 && customerAdminItems.length === 0 && (
          <QueryEmptyState
            query={customerAdminListQuery}
            text="If there were any, they would be here."
            title="No customer administrators available"
          />
        )}
      </Card>
      {tab === 1 && customerAdminItems.length > 0 && (
        <Paginator
          currentPage={currentPage}
          handleNumberItemsChange={handleNumberItemsChange}
          handlePageChange={handlePageChange}
          itemsLength={pageInfo}
          itemsPerPage={itemsPerPage}
        />
      )}
    </Page>
  );
};

export default Contacts;

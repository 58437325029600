import styled from '@/styles';

const FormColumns = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  '@smallDesktop': {
    columnGap: '1.25rem',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },

  variants: {
    phoneFull: {
      true: {
        '@phoneOnly': {
          width: '100%',
        },
      },
    },
    layout: {
      single: {
        '@smallDesktop': {
          gridTemplateColumns: '1fr',
        },
      },
      double: {
        '@smallDesktop': {
          gridTemplateColumns: '1fr 1fr',
        },
      },
      'double-left': {
        '@smallDesktop': {
          gridTemplateColumns: '2fr 1fr',
        },
      },
      'double-right': {
        '@smallDesktop': {
          gridTemplateColumns: '1fr 2fr',
        },
      },
      triple: {
        '@smallDesktop': {
          gridTemplateColumns: '1fr 1fr 1fr',
        },
      },
      tuple: {
        '@smallDesktop': {
          gridTemplateColumns: '1fr 1fr 1fr 1fr',
        },
      },
      doubleLarge: {
        '@smallDesktop': {
          gridTemplateColumns: '2fr 1fr',
        },
      },
      doubleBoth: {
        '@smallDesktop': {
          width: '100%',
          gridTemplateColumns: '2fr 2fr',
        },
      },
    },
  },
});

FormColumns.displayName = 'stitches(FormColumns)';

export default FormColumns;

import { JobStatus } from './util';

import Badge from '@/components/Badge';

const JobBadge = ({ jobStatus }: { jobStatus: JobStatus | JobStatus[] }) => {
  const badges = {
    [JobStatus.CANCELLED]: <Badge label="Cancelled" status="danger" />,
    [JobStatus.UPCOMING]: <Badge label="Upcoming" status="success" />,
    [JobStatus.COMPLETED]: <Badge label="Completed" status="theme" />,
    [JobStatus.IN_PROGRESS]: <Badge label="In progress" status="warning" />,
    [JobStatus.UNPUBLISHED]: <Badge label="Private" />,
  };
  return (
    <>
      {Array.isArray(jobStatus) ? (
        jobStatus.map((jobStatus, index) => (
          <span key={index} style={{ marginRight: '10px' }}>
            {badges[jobStatus]}
          </span>
        ))
      ) : (
        <span style={{ marginRight: '10px' }}>{badges[jobStatus]}</span>
      )}
    </>
  );
};

export default JobBadge;

import { useCallback } from 'react';
import { useModal } from 'react-modal-hook';

import { useChangeCustomerAdminAvatarMutation } from '@/graphql';
import ChangeAvatarModal from '@/modals/ChangeAvatarModal';
import { GetAdminCustomerQuery } from '@/types/graphql';

const useChangeAvatarModal = (
  customerAdmin: GetAdminCustomerQuery['customerAdmin'] | null | undefined
) => {
  const [changeCustomerAdminAvatar] = useChangeCustomerAdminAvatarMutation();

  const handleUpload = useCallback(
    (avatarKey: string) =>
      changeCustomerAdminAvatar({
        variables: { avatarKey, customerAdminId: customerAdmin!.id },
      }),
    [customerAdmin?.id]
  );

  const [showModal, hideModal] = useModal(
    () => (
      <ChangeAvatarModal
        currentAvatarUrl={customerAdmin ? customerAdmin.avatarUrl : null}
        hideModal={hideModal}
        onUpload={handleUpload}
      />
    ),
    [customerAdmin?.avatarUrl]
  );

  return showModal;
};

export default useChangeAvatarModal;

import { createSvgIcon } from '@mui/material';

const ChatIcon = createSvgIcon(
  <svg
    fill="none"
    height="20"
    viewBox="0 0 512 512"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 512V56.889C0 25.47 25.47 0 56.889 0H455.11C486.529 0 512 25.47 512 56.889v284.445c0 31.42-25.471 56.89-56.89 56.89H170.667a56.55 56.55 0 0 0-34.133 11.377L0 512.001zM56.889 56.889v341.334l60.7-45.514a56.436 56.436 0 0 1 34.134-11.377H455.11V56.887H56.889z"
      fill="currentColor"
    />
  </svg>,
  'ChatIcon'
);
export default ChatIcon;

import { FieldContext, SubmitHelpers } from '@area2k/use-form';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { isEqual } from 'lodash';
import { useCallback, useState } from 'react';

import ChangeAvatarCard from '../ChangeAvatarCard';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import FormElement from '@/components/FormElement';
import Modal from '@/components/Modal';
import Option from '@/components/Option';
import Stack from '@/components/Stack';
import { Small } from '@/components/Typography';
import { PlatformAccessStatusEnum } from '@/constants/platform';
import { V3Endpoints } from '@/constants/urls';
import Form from '@/form';
import FormColumns from '@/form/FormColumns';
import MaskedInputField from '@/form/MaskedInputField';
import OptionField from '@/form/OptionField';
import TextField from '@/form/TextField';
import {
  useChangeStatusUserMutation,
  useChangeTenantAdminAvatarMutation,
  useCheckExistingEmailQuery,
  useGetUploadUrlQuery,
  useUpdateUserProfileMutation,
} from '@/graphql';
import { TenantAdminItem } from '@/hooks/useOpenProfile';
import { Maybe } from '@/types';
import { CurrentTenantAdminFragment, UserRoleEnum } from '@/types/graphql';
import { currentAdminVar } from '@/util/apollo/cache';
import axiosClient from '@/util/axios/axiosClient';
import { handleMutationFormError } from '@/util/error';
import TextSelectField from '@/form/TextSelectField';
import { useReactiveVar } from '@apollo/client';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';

export type Props = {
  admin: TenantAdminItem | CurrentTenantAdminFragment;
  hideModal: () => void;
};

type FormValues = {
  active: Props['admin']['user']['active'];
  email: Props['admin']['user']['email'];
  firstName: Props['admin']['user']['firstName'];
  lastName: Props['admin']['user']['lastName'];
  password: Maybe<string>;
  phoneNumber: Props['admin']['user']['phoneNumber'];
  roleId: UserRoleEnum;
};

const roleOptions = [
  { label: 'Tenant Admin', value: UserRoleEnum.TENANT_ADMIN },
  { label: 'Help Desk Admin', value: UserRoleEnum.HELPDESK_ADMIN },
];

const AdminUpdateProfileModal = ({ admin, hideModal }: Props) => {
  const initialValues: FormValues = {
    active: admin.user.active,
    email: admin.user.email,
    firstName: admin.user.firstName,
    lastName: admin.user.lastName,
    password: null,
    phoneNumber: admin.user.phoneNumber,
    roleId: admin.user.roleId,
  };

  const helpdeskRole = useFeatureValue(
    FEATURE_TOGGLE.HelpdeskRole,
    false
  );

  const [formValues, setFormValues] = useState<FormValues>(initialValues);

  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const [error, setError] = useState<{ message: string } | null>(null);

  const [tenatAdminChangeAvatar] = useChangeTenantAdminAvatarMutation();

  const [updateTenantAdminProfile] = useUpdateUserProfileMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(admin),
        fields: { user() { } },
      });
    },
  });

  const currentAdmin = useReactiveVar(currentAdminVar);

  const [appAccess, setAppAccess] = useState<boolean>(
    (admin.user.allowedPlatform & PlatformAccessStatusEnum.APP_APPROVED) !== 0
  );

  const query = useCheckExistingEmailQuery({
    variables: { email: formValues.email },
  });

  const { data, loading } = query;
  const emailExists = data && data.existingUser;

  const [changeStatusUserMutation] = useChangeStatusUserMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(admin),
        fields: { user() { } },
      });
    },
  });

  const { refetch: getUploadUrl } = useGetUploadUrlQuery({ skip: true });

  const handleFormValuesChange = <T extends any>(
    fieldContext: FieldContext<T>,
    fieldId: keyof FormValues
  ) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldId]: fieldContext.value,
    }));
  };

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      setIsLoading(true);

      try {
        if (file) {
          const { data } = await getUploadUrl({ filename: file.name });
          const { key, url } = data.uploadUrl;

          await fetch(url, { method: 'PUT', body: file });

          await tenatAdminChangeAvatar({
            variables: { avatarKey: key, tenantAdminId: admin!.id },
          });
        }

        if (isEqual(formValues, initialValues)) {
          return hideModal();
        }

        if (formValues.active !== initialValues.active) {
          await changeStatusUserMutation({
            variables: { userId: admin!.user.id, active: formValues.active },
          });
        }

        if (emailExists) {
          if (formValues.email !== initialValues.email) {
            return setError({ message: 'Email already in use' });
          } else {
            setError(null);
          }
        }

        if (!loading && error === null) {
          await updateTenantAdminProfile({
            variables: {
              userId: admin.user.id,
              email: formValues.email,
              firstName: formValues.firstName,
              middleName: formValues.lastName,
              lastName: formValues.lastName,
              password: formValues.password,
              phoneNumber: formValues.phoneNumber,
              roleId: formValues.roleId,
            },
          });
          hideModal();
        }
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [emailExists, error, file, formValues]
  );

  const toggleAppAccess = () => {
    if (appAccess) {
      axiosClient
        .patch(V3Endpoints.REVOKE_APP_ACCESS, {
          email: admin.user.email,
        })
        .then(() => {
          setAppAccess(false);
        });
    } else {
      axiosClient
        .patch(V3Endpoints.GRANT_APP_ACCESS, {
          email: admin.user.email,
        })
        .then(() => {
          setAppAccess(true);
        });
    }
  };

  return (
    <Modal disableClickout title="Update Profile" onRequestClose={hideModal}>
      <Card.Section>
        {error && (
          <Alert
            description={error.message}
            icon={faExclamationTriangle}
            status="warning"
            title="Something went wrong"
          />
        )}
        <Form initialValues={formValues} onSubmit={handleSubmit}>
          <FormColumns>
            <FormElement>
              <ChangeAvatarCard
                currentAvatarUrl={admin?.avatarUrl ?? null}
                item={admin}
                useFile={file}
                useSetFile={setFile}
              />
            </FormElement>
            <FormElement>
              <TextField
                autoFocus
                required
                callback={(fieldContext) => {
                  handleFormValuesChange(fieldContext, 'firstName');
                }}
                fieldId="firstName"
                label="First Name"
              />
              <TextField
                required
                callback={(fieldContext) => {
                  handleFormValuesChange(fieldContext, 'lastName');
                }}
                fieldId="lastName"
                label="Last Name"
              />
              <MaskedInputField
                callback={(fieldContext) => {
                  handleFormValuesChange(fieldContext, 'phoneNumber');
                }}
                fieldId="phoneNumber"
                incompletemessage="Must be a valid phone number"
                label="Phone Number"
                mask="(000) 000-0000"
                placeholder={admin.user.phoneNumber}
                type="tel"
              />
              <TextField
                autoFocus
                required
                autoComplete="chrome-off"
                callback={(fieldContext) => {
                  handleFormValuesChange(fieldContext, 'email');
                  if (error) {
                    setError(null);
                  }
                }}
                fieldId="email"
                label="Email Address"
              />

              {helpdeskRole && (<FormElement>
                <TextSelectField
                  callback={(fieldContext) => {
                    handleFormValuesChange(fieldContext, 'roleId');
                  }}
                  disabled={currentAdmin?.id === admin.id}
                  fieldId="role"
                  label="Role"
                  defaultValue={initialValues.roleId}
                  options={roleOptions}
                />
              </FormElement>)}

              <FormElement>
                {isOpen ? (
                  <>
                    <Button
                      a11yLabel="Hide input"
                      appearance="plain"
                      label="Hide new password input"
                      type="button"
                      onClick={() => {
                        setIsOpen(false);
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          password: null,
                        }));
                      }}
                    />
                    <TextField
                      autoComplete="password"
                      callback={(fieldContext) => {
                        handleFormValuesChange(fieldContext, 'password');
                      }}
                      css={{ letterSpacing: '2px' }}
                      fieldId="password"
                      label="New password"
                      placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                      type="password"
                    />
                    <Small>
                      The password must be have at least 8 characters long
                    </Small>
                  </>
                ) : (
                  <Button
                    a11yLabel="Click to Reset user password"
                    appearance="plain"
                    label="Reset user password"
                    type="button"
                    onClick={() => {
                      setIsOpen(true);
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        password: null,
                      }));
                    }}
                  />
                )}
              </FormElement>

              {currentAdminVar()?.__typename === 'TenantAdmin' && (
                <FormElement>
                  <Option
                    appearance="checkbox"
                    checked={appAccess}
                    id={'allShiftsSameWorker'}
                    label={'Allow access to client mobile app'}
                    name={'allShiftsSameWorker'}
                    type="checkbox"
                    onChange={() => {
                      toggleAppAccess();
                    }}
                  />
                </FormElement>
              )}
              <FormElement>
                <FormColumns>
                  <OptionField
                    appearance="switch"
                    callback={(fieldContext) => {
                      handleFormValuesChange(fieldContext, 'active');
                    }}
                    fieldId="active"
                    label="Account Enabled"
                  />
                  <Stack justify="end">
                    <Button
                      a11yLabel="Submit form"
                      css={{}}
                      disabled={
                        isOpen
                          ? (formValues.password &&
                            formValues.password === '') ||
                          formValues.password === null ||
                          formValues.password.length < 8
                          : false
                      }
                      isLoading={isLoading}
                      label="Save"
                      loadingLabel="Saving"
                      type="submit"
                    />
                  </Stack>
                </FormColumns>
              </FormElement>
            </FormElement>
          </FormColumns>
        </Form>
      </Card.Section>
    </Modal>
  );
};

export default AdminUpdateProfileModal;

import { useEffect } from 'react';

import LoadingState from '@/components/LoadingState';
import { useAuthenticateChatServiceMutation } from '@/graphql';
import useAuth from '@/hooks/useAuth';
import {
  HubspotConversationsProvider,
  useHubspotConversations,
} from '@/hooks/useHubSpotChat';

const HubSpotChatEmbedded = () => {
  const { currentAdmin } = useAuth();
  const { initChat, isReady } = useHubspotConversations();
  const [authenticateChatService, { loading }] =
    useAuthenticateChatServiceMutation();

  useEffect(() => {
    if (isReady && currentAdmin?.user.email) {
      authenticateChatService()
        .then(({ data }) => {
          const { chatServiceAuthenticate: token } = data;
          initChat({
            email: currentAdmin.user.email,
            token,
          });
        })
        .catch(() => {});
    }
  }, [isReady, initChat]);

  return !loading && !isReady ? <LoadingState /> : null;
};

const Chat = () => {
  return (
    <HubspotConversationsProvider>
      <HubSpotChatEmbedded />
    </HubspotConversationsProvider>
  );
};

export default Chat;

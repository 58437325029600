import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { currentAdminVar } from '@/util/apollo/cache';
import { UserRoleEnum } from '@/types/graphql';

export enum Role {
  CUSTOMER_ADMIN,
  TENANT_ADMIN,
  HELPDESK_ADMIN,
}

interface Props {
  element: React.ReactElement;
  requiredRoles: UserRoleEnum[];
  redirectTo?: string;
}

const PrivateRoute = ({ element, requiredRoles, redirectTo }: Props) => {
  const location = useLocation();
  const currentAdmin = useReactiveVar(currentAdminVar);

  const userHasRequiredRoles =
    (currentAdmin && requiredRoles.includes(currentAdmin.user.roleId!)) ||
    false;

  if (userHasRequiredRoles) {
    return element;
  } else {
    return (
      <Navigate
        to={redirectTo || '/restricted-access'}
        state={{ from: location }}
      />
    );
  }
};

export default PrivateRoute;

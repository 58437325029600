import { useMemo } from 'react';

import { Subheading } from '@/components/Typography';
import { TableCell, TableRow } from '@/elements/Table';
import Text from '@/elements/Text';
import { InvoiceItem } from '@/types/graphql';
import { formatNetTimeInHrAndMin } from '@/util/invoice';
import { centsToCurrency } from '@/util/number';

type Props = {
  invoice: any;
  invoiceItems: InvoiceItem[];
  editable: boolean;
};

const TotalsRow = ({ invoice, editable, invoiceItems }: Props) => {
  const total = centsToCurrency(invoice.amountDue);
  const totalMints = useMemo(() => {
    let mints = 0;
    invoiceItems.forEach((item) => {
      const netTime = Number(item.netTimeMinutes);
      if (netTime) {
        mints += netTime;
      }
    });
    return mints;
  }, [invoiceItems]);
  return (
    <>
      <TableRow
        key={`totals-row`}
        css={{
          backgroundColor: '#EEFFEC !important',
        }}
      >
        <TableCell colSpan={3}>
          <Subheading>Total</Subheading>
        </TableCell>
        <TableCell>
          <Text weight={'semibold'}>{formatNetTimeInHrAndMin(totalMints)}</Text>
        </TableCell>
        <TableCell colSpan={4} />
        <TableCell>
          <Text weight={'semibold'}>${total}</Text>
        </TableCell>
        {editable && <TableCell />}
      </TableRow>
    </>
  );
};

export default TotalsRow;

import { Close, Groups } from '@mui/icons-material';
import {
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
} from '@mui/material';
import { useState } from 'react';

import Avatar from '@/components/Avatar';
import { V3Endpoints } from '@/constants/urls';
import Button from '@/elements/Button';
import Text from '@/elements/Text';
import styled from '@/styles';
import colors, { IconColor } from '@/styles/colors';
import axiosClient from '@/util/axios/axiosClient';
import { ChatGroupGWUserType, MessageReceiversList } from '@/util/axios/types';

interface Props {
  selectedWorkers: MessageReceiversList;
  deleteWorker: (id: number) => void;
  phoneOnly: boolean;
}

const IconWrapper = styled('div', {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  height: '40px',
  width: '46px',
  background: colors.themeDefault,
  borderRadius: '25px',
});

const CreateGroupChat = ({
  selectedWorkers,
  deleteWorker,
  phoneOnly,
}: Props) => {
  const [groupName, setGroupName] = useState('');
  const [loading, setLoading] = useState(false);

  const borderBottom = '2px solid ' + colors.themeLighter;
  const height = phoneOnly ? '66%' : '69%';
  const invalidWorkers = selectedWorkers.length < 2;

  const handleCreateGroup = async () => {
    const payload = {
      name: groupName,
      users: selectedWorkers.map((sw) => ({
        id: sw.id,
        type: ChatGroupGWUserType.WORKER,
      })),
    };
    setLoading(true);
    try {
      await axiosClient.post(V3Endpoints.CREATE_GROUP_CHAT, payload);
      // Todo: handler func for create group chat
    } catch (e) {}
    setLoading(false);
  };
  return (
    <>
      <Stack
        direction={'row'}
        gap={1.5}
        sx={{
          padding: '16px',
        }}
      >
        <IconWrapper>
          <Groups htmlColor="white" />
        </IconWrapper>

        <TextField
          fullWidth
          id="input-group-name"
          inputProps={{ maxLength: 100 }}
          placeholder="Enter Group Name"
          size="small"
          sx={{
            paddingTop: '8px',
            '& .MuiInput-root.Mui-focused': {
              '&:before': {
                borderBottom,
              },
              '&:after': {
                borderBottom,
              },
            },
          }}
          value={groupName}
          variant="standard"
          onChange={(e) => {
            setGroupName(e.target.value);
          }}
        />
      </Stack>
      <Divider />
      {loading && <LinearProgress />}
      <Text
        as="p"
        css={{ padding: '8px 16px', color: IconColor }}
        weight={'semibold'}
      >
        Participants: {selectedWorkers.length}
      </Text>
      <List
        id="worker-list"
        sx={{
          width: '100%',
          minWidth: '320px',
          paddingTop: 0,
          overflowY: 'auto',
          overflowX: 'hidden',
          maxHeight: height,
          height,
        }}
      >
        {selectedWorkers.map((info) => (
          <ListItem
            key={info.id}
            alignItems="flex-start"
            dense={phoneOnly}
            divider={true}
          >
            <ListItemAvatar>
              <Avatar
                firstName={info.first_name}
                rounded={true}
                size={phoneOnly ? 'sm' : 'm'}
                src={info.avatar_url}
              />
            </ListItemAvatar>
            <ListItemText
              primary={`${info.first_name} ${info.middle_name || ''} ${
                info.last_name || ''
              }`}
              primaryTypographyProps={{
                component: 'p',
                fontWeight: 'bold',
                paddingTop: '2px',
              }}
              secondary={info.email}
            />
            <IconButton
              id={`delete-${info.id}`}
              style={{ marginTop: phoneOnly ? '5px' : '8px', left: '8px' }}
              onClick={() => {
                deleteWorker(info.id);
              }}
            >
              <Close id={'close-btn-' + info.id} />
            </IconButton>
          </ListItem>
        ))}
        {invalidWorkers && (
          <ListItem>
            {
              <Text as="span" color={'lighter'} size={'sm'}>
                Note: You are required to select minimum 2 workers
              </Text>
            }
          </ListItem>
        )}
      </List>
      <div style={{ padding: '6px 20px' }}>
        <Button
          css={{ width: '100%' }}
          disabled={invalidWorkers || !groupName}
          id="create-group-btn"
          onClick={handleCreateGroup}
        >
          Create Group
        </Button>
      </div>
    </>
  );
};

export default CreateGroupChat;

import { Checkbox, FormControlLabel, List, ListItem } from '@mui/material';
import React from 'react';

import Avatar from '@/components/Avatar';
import { theme } from '@/styles/colors';

interface CheckboxListProps {
  data: any[];
  selectedData: any[];
  setSelectedOptions: (data: any[]) => void;
  style;
  disableAdd?: boolean;
}

const CheckboxList = ({
  style,
  data,
  selectedData,
  setSelectedOptions,
  disableAdd,
}: CheckboxListProps) => {
  const handleClick = (checked: boolean, item: any) => {
    if (!checked) {
      setSelectedOptions(selectedData.filter((sd) => sd.id !== item.id));
    } else {
      setSelectedOptions([...selectedData, item]);
    }
  };
  return (
    <List dense={true} style={style}>
      {data.map((item) => (
        <ListItem key={item.id}>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  !!selectedData.find((selected) => selected.id === item.id)
                }
                disabled={
                  selectedData.find((selected) => selected.id === item.id)
                    ? false
                    : disableAdd
                }
                sx={{
                  '&.Mui-checked': {
                    color: theme.themeDefault,
                  },
                }}
                onChange={(e, checked) => handleClick(checked, item)}
              />
            }
            label={
              <React.Fragment>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar
                    aria-hidden="true"
                    firstName={item?.user?.firstName || ''}
                    rounded={true}
                    size="sm"
                    src={item.avatarUrl}
                  />
                  <span style={{ marginLeft: '20px' }}>
                    {item.user.firstName} {item.user.lastName}
                  </span>
                </div>
              </React.Fragment>
            }
            style={{ width: '100%' }}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default CheckboxList;

import { isEqual } from 'lodash';
import { Dispatch, SetStateAction, ChangeEvent } from 'react';

import Button from '@/components/Button';
import Dropdown from '@/components/Dropdown';
import LoadingState from '@/components/LoadingState';
import Option from '@/components/Option';
import Stack from '@/components/Stack';
import { Body } from '@/components/Typography';
import { useListAgencySkillsQuery } from '@/graphql';
import { ListAgencySkillsQuery } from '@/types/graphql';

type SkillCategories = ListAgencySkillsQuery['agency']['skillCategories'];
type SkillId = SkillCategories[0]['skills'][0]['id'];

interface Props {
  agencyId: string;
  selectedSkillsIds: SkillId[];
  setSelectedSkillsIds: Dispatch<SetStateAction<SkillId[]>>;
}

function DropdownSkillsByAgency(props: Props) {
  return (
    <Dropdown
      fixed
      Content={() => <DropdownContent {...props} />}
      justify="right"
      size="sm"
    >
      <Button
        hasPopover
        a11yLabel="View Skills"
        appearance="outline"
        label="Skills"
      />
    </Dropdown>
  );
}

function DropdownContent({
  agencyId,
  selectedSkillsIds,
  setSelectedSkillsIds,
}: Props) {
  const { loading, error, data } = useListAgencySkillsQuery({
    variables: { agencyId },
  });

  if (loading) {
    return (
      <Stack css={{ padding: '1rem 0rem' }} justify="center">
        <LoadingState />
      </Stack>
    );
  }

  if (error || !data) {
    return (
      <Stack css={{ padding: '1rem 0rem' }} justify="center">
        <Body css={{ padding: '0rem 1rem' }}>
          An error has occurred while loading the skills.
        </Body>
      </Stack>
    );
  }

  const { skillCategories } = data.agency;
  const skills = mapAgencySkillCategories(skillCategories);
  const allSkillsId = skills.map((skill) => skill.id).sort();
  const allChecked = isEqual(selectedSkillsIds.sort(), allSkillsId);
  const optionAll = {
    id: (skills.length + 1).toString(),
    name: 'All',
  };

  function handleOnChange(ev: ChangeEvent<HTMLInputElement>) {
    const { value: selectedSkillId } = ev.currentTarget;

    if (selectedSkillIdExits(selectedSkillId)) {
      const filteredSkillId = filterSelectedSkillId(selectedSkillId);
      return setSelectedSkillsIds(filteredSkillId);
    }

    if (selectedSkillId === optionAll.id) {
      return setSelectedSkillsIds(!allChecked ? allSkillsId : []);
    }

    return setSelectedSkillsIds([...selectedSkillsIds, selectedSkillId]);
  }

  function selectedSkillIdExits(selectedSkillId: string) {
    return selectedSkillsIds.includes(selectedSkillId);
  }

  function filterSelectedSkillId(selectedSkillId: string) {
    return selectedSkillsIds.filter((skillId) => skillId !== selectedSkillId);
  }

  return (
    <Stack vertical style={{ padding: '1rem' }} verticalGap={16}>
      <Button
        a11yLabel="Clear"
        appearance="plain"
        label="Clear"
        onClick={() => setSelectedSkillsIds([])}
      />
      <Option
        key={optionAll.id}
        appearance="checkbox"
        checked={allChecked}
        id={optionAll.id}
        label={optionAll.name}
        name={optionAll.name}
        type="checkbox"
        value={optionAll.id}
        onChange={(ev) => handleOnChange(ev)}
      />
      {skills.map((skill) => (
        <Option
          key={skill.id}
          appearance="checkbox"
          checked={selectedSkillIdExits(skill.id)}
          id={skill.id}
          label={skill.name}
          name={skill.name}
          type="checkbox"
          value={skill.id}
          onChange={(ev) => handleOnChange(ev)}
        />
      ))}
    </Stack>
  );
}

function mapAgencySkillCategories(skillsCategories: SkillCategories) {
  return skillsCategories.flatMap((skillCategory) => skillCategory.skills);
}

export default DropdownSkillsByAgency;

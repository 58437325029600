import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

import { Address, Maybe, Skill } from '@/types/graphql';

export enum TaxTypeEnumVoid {
  BOTH = 'BOTH',
  TAX_1099 = 'TAX_1099',
  TAX_W2 = 'TAX_W2',
}
interface FiltersState {
  workerCompleteTableFilters: {
    address: Maybe<Address>;
    skillArray: Array<Skill>;
    taxType: TaxTypeEnumVoid;
    certArray: any[];
  };
}

const initialState: FiltersState = {
  workerCompleteTableFilters: {
    address: null,
    skillArray: [],
    taxType: TaxTypeEnumVoid.BOTH,
    certArray: [],
  },
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setWorkerCompleteTableFilters: (state, action: PayloadAction<any>) => {
      state.workerCompleteTableFilters = action.payload;
    },
    resetWorkerTableFilters: (state) => {
      state.workerCompleteTableFilters =
        initialState.workerCompleteTableFilters;
    },
  },
});

export const selectWorkerCompleteFilters = (state: RootState) =>
  state.filters.workerCompleteTableFilters;

export const { setWorkerCompleteTableFilters, resetWorkerTableFilters } =
  filtersSlice.actions;

export default filtersSlice.reducer;

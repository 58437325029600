import { createSvgIcon } from '@mui/material';

const CommentCounterIcon = createSvgIcon(
  <svg
    fill="none"
    height="19"
    viewBox="0 0 19 19"
    width="19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5 9.13284C18.5034 10.4527 18.1951 11.7547 17.6 12.9328C16.8944 14.3446 15.8098 15.532 14.4674 16.3621C13.1251 17.1922 11.5782 17.6322 10 17.6328C8.68013 17.6363 7.37812 17.3279 6.2 16.7328L0.5 18.6328L2.4 12.9328C1.80493 11.7547 1.49656 10.4527 1.5 9.13284C1.50061 7.5546 1.94061 6.00769 2.77072 4.66539C3.60083 3.32309 4.78825 2.23841 6.2 1.53284C7.37812 0.937772 8.68013 0.6294 10 0.632841H10.5C12.5843 0.747833 14.553 1.6276 16.0291 3.1037C17.5052 4.5798 18.385 6.54849 18.5 8.63284V9.13284Z"
      fill="#5BB24F"
    />
  </svg>,
  'CommentCounterIcon'
);
export default CommentCounterIcon;

import { FieldCallback, useField } from '@area2k/use-form';
import React, { ComponentPropsWithoutRef } from 'react';

import FormElement from '@/components/FormElement';
import TextInput from '@/components/TextInput';

type InputProps = Omit<
  ComponentPropsWithoutRef<typeof TextInput>,
  'id' | 'value' | 'onChange'
>;

type Props = InputProps & {
  fieldId: string;
  label?: string;
  callback?: FieldCallback<string>;
  errorComponent?: React.ReactNode;
  displayType?: string;
  showFocusCover?: boolean;
};

const TextField = ({
  fieldId,
  callback = () => {},
  label,
  errorComponent,
  displayType = 'block',
  width = '100%',
  showFocusCover = true,
  ...props
}: Props) => {
  const { value, setValue } = useField<string>(fieldId, callback);

  return (
    <FormElement displayType={displayType} htmlFor={fieldId} label={label}>
      <TextInput
       showFocusCover={showFocusCover}
        width={width}
        {...props}
        id={fieldId}
        name={fieldId}
        value={value}
        onChange={(ev) => setValue(ev.currentTarget.value)}
      />
      {errorComponent}
    </FormElement>
  );
};

export default TextField;

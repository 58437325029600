import * as yup from 'yup';

export const secondStepSchema = yup
  .object({
    address: yup
      .object({
        city: yup.string().required('City is a required field'),
        state: yup.string().required('State is a required field'),
        zip: yup.string().required('Zip is a required field'),
      })
      .required(),
  })
  .required();

import { useEffect, useMemo, useState } from 'react';

import { getTabValue, getTabs } from './StaffingUtils';

import Tabs from '@/components/Tabs';
import { V3Endpoints } from '@/constants/urls';
import { Skill } from '@/types/graphql';
import useAnalytics from '@/util/analytics';
import axiosClient from '@/util/axios/axiosClient';
import { WorkersCount } from '@/util/axios/types';
import useAuth from '@/hooks/useAuth';

type StaffingTabsProps = {
  tab: number;
  setTab: (tab: number) => void;
  skill: Partial<Skill>;
  isLTA: boolean;
  custId: number | string;
  addressId: number | string;
  jobTaxType: string;
};

const StaffingTabs = ({
  tab,
  setTab,
  skill,
  isLTA,
  custId,
  addressId,
  jobTaxType,
}: StaffingTabsProps) => {
  const [counts, setCounts] = useState<WorkersCount>();
  const { currentAdminIsCustomerAdmin: isClientAdmin } = useAuth();

  const TABS = useMemo(
    () => getTabs({ counts, isTrained: !!skill?.gravyTrained, isClientAdmin }),
    [counts, skill],
  );

  const { logEvent } = useAnalytics();
  useEffect(() => {
    axiosClient
      .post(V3Endpoints.GET_WORKERS_COUNT, {
        skillId: Number(skill?.id),
        customerId: Number(custId),
        addressId: Number(addressId),
        workerType: isLTA ? 'lta' : 'gig',
        taxType: jobTaxType || 'all',
      })
      .then((res) => {
        if (res.data?.data) {
          setCounts(res.data.data);
        }
      })
      .catch((e) => console.error(e));
  }, []);

  return (
    <div
      className="hide-scroll"
      style={{ width: '100%', overflow: 'auto hidden' }}
    >
      <Tabs
        fit
        minWidth="216px"
        selected={tab}
        showTabOnMobile={true}
        tabs={TABS}
        onSelect={(tab: number) => {
          logEvent(getTabValue(tab, !!skill.gravyTrained));
          setTab(tab);
        }}
      />
    </div>
  );
};

export default StaffingTabs;

import { object, string } from 'yup';

const validationSchema = object({
  description: string()
    .required('Description is a required field')
    // To remove white and break spaces in TextArea input
    .trim(),
}).required();

export { validationSchema };

import Stack from '@/components/Stack';
import TextInput from '@/components/TextInput';
import useMediaQuery from '@/hooks/useMediaQuery';

type Props = {
  query: string;
  onQueryChange: (query: string) => void;
};

const JobMonitorFilters = ({ query, onQueryChange }: Props) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  return (
    <Stack justify="end">
      <div style={{ flex: phoneOnly ? 1 : 0.7 }}>
        <TextInput
          id="txt_search_client"
          minWidth="sm"
          placeholder="Search by Client Name"
          value={query}
          onChange={(ev) => onQueryChange(ev.currentTarget.value)}
        />
      </div>
    </Stack>
  );
};

export default JobMonitorFilters;

import { FieldCallback, useField } from '@area2k/use-form';
import { ComponentProps } from 'react';

import FormElement from '@/components/FormElement';
import Option from '@/components/Option';
import Stack from '@/components/Stack';

type OptionProps = Omit<
  ComponentProps<typeof Option>,
  'checked' | 'id' | 'name' | 'value' | 'onChange' | 'label'
>;
export type OptionGroupOption = {
  disabled?: boolean;
  label: string;
  value: string;
};

type Props = OptionProps & {
  fieldId: string;
  label?: string;
  options: OptionGroupOption[];
  vertical?: boolean;
  callback?: FieldCallback<string>;
};

const SingleOptionGroupField = ({
  appearance = 'bullseye',
  disabled,
  fieldId,
  label,
  options,
  vertical = false,
  callback = () => {},
  ...props
}: Props) => {
  const { value, setValue } = useField<string>(fieldId, callback);

  return (
    <FormElement label={label}>
      <Stack vertical={vertical}>
        {options.map((option, index) => (
          <Option
            key={`${fieldId}${index}`}
            appearance={appearance}
            checked={value === option.value}
            disabled={disabled || option.disabled}
            id={`${fieldId}${index}`}
            label={option.label}
            name={fieldId}
            type="radio"
            value={option.value}
            onChange={(ev) => setValue(ev.currentTarget.value)}
            {...props}
          />
        ))}
      </Stack>
    </FormElement>
  );
};

export default SingleOptionGroupField;

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { Body } from '@/components/Typography';
import {
  GetInvoiceDetailsDocument,
  useDeleteInvoiceItemMutation,
} from '@/graphql';

type Props = {
  invoiceItemId: string;
  invoiceId: string;
  hideModal: () => void;
};

const ConfirmDeleteItem = ({ invoiceItemId, invoiceId, hideModal }: Props) => {
  const [error, setError] = useState<boolean>(false);

  const [deteleInvoiceItem, { loading: isLoading }] =
    useDeleteInvoiceItemMutation({
      refetchQueries: [
        { query: GetInvoiceDetailsDocument, variables: { invoiceId } },
      ],
    });

  const handleDeleteInvoiceItem = async () => {
    try {
      await deteleInvoiceItem({
        variables: {
          invoiceItemId,
        },
      });
      hideModal();
    } catch (err) {
      setError(true);
    }
  };

  return (
    <Modal
      disableClickout
      size="sm"
      title="Are you sure you want to delete this invoice item?"
      onRequestClose={hideModal}
    >
      <Card.Section>
        {error && (
          <Alert
            description="Something went wrong, please contact support"
            icon={faExclamationTriangle}
            status="danger"
            title="An error has occurred"
          />
        )}
        <Body>
          To see this invoice item again, you would have to add a new item.
        </Body>
        <Stack css={{ marginTop: '10px' }} gap={10} justify="end">
          <Button
            a11yLabel="deny"
            appearance="outline"
            label="Cancel"
            status="danger"
            type="button"
            onClick={hideModal}
          />

          <Button
            a11yLabel="accept"
            isLoading={isLoading}
            label="Delete"
            type="submit"
            onClick={handleDeleteInvoiceItem}
          />
        </Stack>
      </Card.Section>
    </Modal>
  );
};

export default ConfirmDeleteItem;

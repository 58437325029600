import useModal from '@area2k/use-modal';
import {
  CancelOutlined,
  CheckCircleOutline,
  CloseOutlined,
  EditOutlined,
} from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  IconButton as MuiIconButton,
  Snackbar,
  Stack,
  Tooltip,
} from '@mui/material';
import { addDays, isAfter, parse, parseISO } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { TimeSheetStatus, getTimeSheetStatus } from '../util';

import BulkEditModal, { EditFormValues } from './BulkEditModal';

import { ApproveReverseIcon, RejectReverseIcon } from '@/assets/icons';
import { GetJobDocument, useBulkEditTimesheetMutation } from '@/graphql';
import useMediaQuery from '@/hooks/useMediaQuery';
import styled from '@/styles';
import colors, { timesheetColors } from '@/styles/colors';
import { GetJobQuery, TimesheetsBulkEditActionEnum } from '@/types/graphql';

const WrapperDiv = styled('div', {
  margin: '15px 20px',
  backgroundColor: timesheetColors.bulkActionsBg,
  height: '54px',
  borderRadius: '140px',
  padding: '7px 18px',
  '@phoneOnly': {
    padding: '6px 10px',
    margin: '0px 0px',
    borderRadius: '0px',
    position: 'fixed',
    left: 0,
    zIndex: '1100',
    width: '100%',
  },
});
const TextDiv = styled('div', {
  paddingTop: '9px',
  marginRight: '20px !important',
  '@phoneOnly': {
    marginRight: '5px',
    minWidth: '70px',
  },
});
const IconButton = styled(MuiIconButton, {
  color: 'black !important',
  '&:hover': {
    color: `${colors.themeDefault} !important`,
    backgroundColor: '#D7EDCF !important',
  },
  '&.Mui-disabled': {
    color: `rgba(0, 0, 0, 0.3) !important`,
  },
});

interface Props {
  checkboxIds: string[];
  setCheckboxIds: (ids: string[]) => void;
  job: GetJobQuery['job'];
  isClientAdmin: boolean;
}

const parseTimeValue = (time: string, startAt: string) =>
  parse(time, 'HH:mm', parseISO(startAt));

const MIN_SCROLL = 14;
const MAX_SCROLL = 25;

const ActionTab = ({
  checkboxIds,
  job,
  setCheckboxIds,
  isClientAdmin,
}: Props) => {
  const [top, setTop] = useState(window.scrollY > MAX_SCROLL ? 0 : 48);
  const phoneOnly = useMediaQuery('(max-width: 559px)');

  const TimesheetStatuses = useMemo(() => {
    const statuses: any[] = [];
    checkboxIds.forEach((id) => {
      const timesheet = job.timesheets.find(
        (timesheet) => timesheet.jobWorker.id === id,
      );
      const tsStatus = getTimeSheetStatus(timesheet);
      if (tsStatus) statuses.push(tsStatus);
      else statuses.push('null');
    });
    return statuses;
  }, [checkboxIds, job.timesheets]);

  const [successMsg, setSuccessMsg] = useState('');
  const [error, setError] = useState('');

  const [bulkEditTimesheet] = useBulkEditTimesheetMutation({
    refetchQueries: [{ query: GetJobDocument, variables: { jobId: job.id } }],
    onError: (error) => {
      setError(error?.message || 'Some error occurred while updating');
    },
    onCompleted: (data) => {
      if (data.timesheetsBulkEdit.message) {
        setSuccessMsg(data.timesheetsBulkEdit.message);
      }
      setCheckboxIds([]);
    },
  });

  const handleEditSubmit = useCallback(
    async (values: EditFormValues) => {
      const startAt =
        values.checkIn !== ''
          ? parseTimeValue(values.checkIn, job.shifts[0].startAt)
          : undefined;
      let endAt =
        values.checkOut !== ''
          ? parseTimeValue(values.checkOut, job.shifts[0].startAt)
          : undefined;
      const tipAmount = values.tip !== '' ? parseFloat(values.tip) : undefined;
      const reportedBreakMinutes =
        values.break !== '' ? parseInt(values.break) : undefined;

      if (startAt && endAt && isAfter(startAt, endAt)) {
        endAt = addDays(endAt, 1);
      }

      bulkEditTimesheet({
        variables: {
          jobId: job.id,
          jobWorkerIds: checkboxIds,
          action: isClientAdmin
            ? TimesheetsBulkEditActionEnum.EDIT_AND_APPROVE
            : TimesheetsBulkEditActionEnum.EDIT,
          editInfo: {
            reportedCheckinAt: startAt?.toISOString(),
            reportedCheckoutAt: endAt?.toISOString(),
            reportedBreakMinutes,
            tipAmount,
          },
        },
      });
    },
    [bulkEditTimesheet, job, checkboxIds, isClientAdmin],
  );

  const [showEditModal, hideEditModal] = useModal(
    () => (
      <BulkEditModal
        handleEdit={async (val) => {
          await handleEditSubmit(val);
          hideEditModal();
        }}
        hideModal={hideEditModal}
        isClientAdmin={isClientAdmin}
      />
    ),
    [checkboxIds, job.id, isClientAdmin],
  );

  const handleUpdate = useCallback(
    (action: TimesheetsBulkEditActionEnum) => {
      bulkEditTimesheet({
        variables: {
          jobId: job.id,
          jobWorkerIds: checkboxIds,
          action,
          editInfo: null,
        },
      });
    },
    [checkboxIds, job.id],
  );

  const handleClose = (event: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessMsg('');
    setError('');
  };

  useEffect(() => {
    const handleScroll = (e: any) => {
      const scrollY = e?.detail?.scrollTop;
      if (scrollY < MIN_SCROLL) setTop(48);
      else if (scrollY > MAX_SCROLL) setTop(0);
    };

    if (phoneOnly) {
      const content = document.querySelector('ion-content');
      content?.addEventListener('ionScroll', handleScroll);

      return () => {
        content?.removeEventListener('ionScroll', handleScroll);
      };
    }
  }, [phoneOnly]);

  return (
    <>
      {checkboxIds.length > 0 && (
        <WrapperDiv id="actions-tab" style={phoneOnly ? { top } : undefined}>
          <Stack direction={'row'} spacing={{ xs: 0.1, md: 1.3 }}>
            <Tooltip arrow title="Close">
              <IconButton onClick={() => setCheckboxIds([])}>
                <CloseOutlined htmlColor="inherit" />
              </IconButton>
            </Tooltip>
            <TextDiv>
              <span>
                <b>{checkboxIds.length}</b> Selected
              </span>
            </TextDiv>
            <Stack direction={'row'} spacing={{ xs: 0, md: 0.5 }}>
              <Tooltip arrow title="Edit">
                <span>
                  <IconButton
                    disabled={
                      !(
                        TimesheetStatuses.includes(
                          TimeSheetStatus.INCOMPLETE,
                        ) ||
                        TimesheetStatuses.includes(TimeSheetStatus.PENDING) ||
                        TimesheetStatuses.includes('null')
                      )
                    }
                    id="edit-action"
                    onClick={() => showEditModal()}
                  >
                    <EditOutlined htmlColor="inherit" />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip arrow title="Approve">
                <span>
                  <IconButton
                    disabled={
                      !TimesheetStatuses.includes(TimeSheetStatus.PENDING)
                    }
                    id="approve-action"
                    onClick={() =>
                      handleUpdate(TimesheetsBulkEditActionEnum.APPROVE)
                    }
                  >
                    <CheckCircleOutline htmlColor="inherit" />
                  </IconButton>
                </span>
              </Tooltip>
              {!isClientAdmin && (
                <>
                  <Tooltip arrow title="Reject">
                    <span>
                      <IconButton
                        disabled={
                          !(
                            TimesheetStatuses.includes(
                              TimeSheetStatus.INCOMPLETE,
                            ) ||
                            TimesheetStatuses.includes(TimeSheetStatus.PENDING)
                          )
                        }
                        id="reject-action"
                        onClick={() =>
                          handleUpdate(TimesheetsBulkEditActionEnum.REJECT)
                        }
                      >
                        <CancelOutlined htmlColor="inherit" />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip arrow title="Unapprove">
                    <span>
                      <IconButton
                        disabled={
                          !TimesheetStatuses.includes(TimeSheetStatus.APPROVED)
                        }
                        id="unapprove-action"
                        onClick={() =>
                          handleUpdate(TimesheetsBulkEditActionEnum.UN_APPROVE)
                        }
                      >
                        <ApproveReverseIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip arrow title="Unreject">
                    <span>
                      <IconButton
                        disabled={
                          !TimesheetStatuses.includes(TimeSheetStatus.REJECTED)
                        }
                        id="unreject-action"
                        onClick={() =>
                          handleUpdate(TimesheetsBulkEditActionEnum.UN_REJECT)
                        }
                      >
                        <RejectReverseIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </>
              )}
            </Stack>
          </Stack>
        </WrapperDiv>
      )}

      <Snackbar
        action={
          <MuiIconButton
            color="inherit"
            id="close-notification"
            size="small"
            onClick={handleClose}
          >
            <CloseOutlined fontSize="small" />
          </MuiIconButton>
        }
        autoHideDuration={3000}
        id="success-msg"
        message={successMsg}
        open={!!successMsg}
        onClose={handleClose}
      />
      {error && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={3000}
          open={true}
          onClose={handleClose}
        >
          <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose}>
            <AlertTitle>Error: {error}</AlertTitle>
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default ActionTab;

import { UseSelectStateChange } from 'downshift';
import { Dispatch, SetStateAction } from 'react';

import FormElement from '../FormElement';
import ItemSelect from '../ItemSelect';

import { GROUP_BY_HINT } from '@/constants/text';
import { InvoiceGroupByEnum, Scalars } from '@/types/graphql';

interface GroupByInvoicesOption {
  label: Scalars['String'];
  value: InvoiceGroupByEnum;
}

export const GROUPBY_INVOICES_OPTIONS: GroupByInvoicesOption[] = [
  { label: 'None', value: InvoiceGroupByEnum.NONE },
  { label: 'Location', value: InvoiceGroupByEnum.LOCATION },
  { label: 'Skills', value: InvoiceGroupByEnum.SKILL },
];

interface GroupByInvoicesFormFieldProps {
  groupBy: InvoiceGroupByEnum;
  onChangeGroupBy: Dispatch<SetStateAction<InvoiceGroupByEnum>>;
}

const GroupInvoicesFormField = ({
  groupBy,
  onChangeGroupBy,
}: GroupByInvoicesFormFieldProps) => {
  const selectedGroupByOption =
    GROUPBY_INVOICES_OPTIONS.find((option) => option.value === groupBy) || null;
  const handleOnGroupByInvoicesChange = ({
    selectedItem,
  }: UseSelectStateChange<GroupByInvoicesOption>) => {
    if (selectedItem) {
      onChangeGroupBy(selectedItem.value!);
    }
  };

  return (
    <FormElement hint={GROUP_BY_HINT} htmlFor="groupBy" label="Group By">
      <ItemSelect<GroupByInvoicesOption>
        required
        id="groupby-invoices"
        itemToKey={(item) => item.value}
        itemToString={(item) => item?.label || ''}
        items={GROUPBY_INVOICES_OPTIONS}
        placeholder="Select Group By"
        selectedItem={selectedGroupByOption}
        onSelectedItemChange={handleOnGroupByInvoicesChange}
      />
    </FormElement>
  );
};

export default GroupInvoicesFormField;

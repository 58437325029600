import { SubmitHelpers } from '@area2k/use-form';
import { useCallback, useState } from 'react';

import AddressAutocomplete from '@/components/AddressAutocomplete';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { INCORRECT_STATE_ERROR_MSG } from '@/constants/general';
import { NOT_PROVIDED } from '@/constants/text';
import Form from '@/form';
import { AddressItemFragmentDoc, useCreateAddressMutation } from '@/graphql';
import useGetValidStates from '@/hooks/useGetValidStates';
import styled from '@/styles';
import {
  AddressItemFragment,
  GetCustomerQuery,
  PointInput,
  Scalars,
} from '@/types/graphql';
import { handleMutationFormError } from '@/util/error';

export type Props = {
  customer: GetCustomerQuery['customer'];
  hideModal: (addressCreated?: AddressItemFragment) => Scalars['Void'];
  onChangeAddress?: (address: AddressItemFragment) => Scalars['Void'];
};

type FormValues = {
  addressLine1: AddressItemFragment['addressLine1'];
  addressLine2: AddressItemFragment['addressLine2'];
  city: AddressItemFragment['city'];
  neighborhood: AddressItemFragment['city'];
  name: AddressItemFragment['name'];
  state: AddressItemFragment['state'];
  zip: AddressItemFragment['zip'];
  coords: PointInput;
};

export const InvalidStateErrorText = styled('p', {
  color: '#DC1515',
  fontSize: '14px',
  fontWeight: 400,
  marginBottom: '5px',
});

const CreateAddressModal = ({
  customer,
  hideModal,
  onChangeAddress,
}: Props) => {
  const initialValues: FormValues = {
    addressLine1: '',
    addressLine2: '',
    city: '',
    neighborhood: '',
    name: '',
    state: '',
    zip: '',
    coords: { latitude: 0, longitude: 0 },
  };
  const [addressPayload, setAddressPayload] =
    useState<FormValues>(initialValues);
  const [invalidStateError, showInvalidStateError] = useState<boolean>(false);

  const validStates = useGetValidStates();

  const [createAddress, { loading }] = useCreateAddressMutation({
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify(customer),
        fields: {
          addresses(existingRefs = [], { readField }) {
            if (!data) {
              return existingRefs;
            }

            const { address } = data.addressCreate;

            if (
              existingRefs.some((ref) => readField('id', ref) === address.id)
            ) {
              return existingRefs;
            }

            const newAddressRef = cache.writeFragment({
              fragment: AddressItemFragmentDoc,
              data: address,
            });

            return [...existingRefs, newAddressRef];
          },
        },
      });
    },
  });

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        const addressCreated = await createAddress({
          variables: {
            customerId: customer.id,
            addressLine1: addressPayload.addressLine1!,
            addressLine2: addressPayload.addressLine2!,
            city: addressPayload.city!,
            state: addressPayload.state!,
            zip: addressPayload.zip!,
            name: addressPayload.name!,
            coords: addressPayload.coords,
            neighborhood: addressPayload.neighborhood || NOT_PROVIDED,
          },
        });
        setAddressPayload(initialValues);
        onChangeAddress?.(addressCreated.data!.addressCreate.address);
        hideModal(addressCreated.data!.addressCreate.address);
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
          errorMap: {
            all: (gqlError) => ({
              title: gqlError.name,
              message: gqlError.message,
              status: 'danger',
            }),
          },
        });
      }
    },
    [addressPayload]
  );

  const disabledSubmit =
    invalidStateError ||
    !(
      addressPayload.addressLine1 &&
      addressPayload.city &&
      addressPayload.state &&
      addressPayload.zip
    );

  return (
    <Modal
      disableClickout
      size="sm"
      title="New address"
      onRequestClose={() => {
        hideModal();
      }}
    >
      <Card.Section>
        <Form
          data-testid="create-address-form"
          initialValues={addressPayload}
          onSubmit={handleSubmit}
        >
          <AddressAutocomplete
            showSuiteNumber
            setValues={(address) => {
              showInvalidStateError(!validStates.includes(address?.state));
              setAddressPayload(address);
            }}
            values={addressPayload}
            onReset={(address) => {
              showInvalidStateError(false);
              setAddressPayload(address);
            }}
          />
          {invalidStateError && (
            <InvalidStateErrorText>
              {INCORRECT_STATE_ERROR_MSG}
            </InvalidStateErrorText>
          )}
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              data-testid="save-address-btn"
              disabled={disabledSubmit}
              id="save-address-btn"
              isLoading={loading}
              label="Save"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  );
};

export default CreateAddressModal;

import { createSvgIcon } from '@mui/material';

const PeopleExclaimIcon = createSvgIcon(
  <svg
    fill="none"
    height="12"
    viewBox="0 0 16 12"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 0C7.29565 0 8.05871 0.31607 8.62132 0.87868C9.18393 1.44129 9.5 2.20435 9.5 3C9.5 3.79565 9.18393 4.55871 8.62132 5.12132C8.05871 5.68393 7.29565 6 6.5 6C5.70435 6 4.94129 5.68393 4.37868 5.12132C3.81607 4.55871 3.5 3.79565 3.5 3C3.5 2.20435 3.81607 1.44129 4.37868 0.87868C4.94129 0.31607 5.70435 0 6.5 0ZM6.5 7.5C9.815 7.5 12.5 8.8425 12.5 10.5V12H0.5V10.5C0.5 8.8425 3.185 7.5 6.5 7.5ZM14 6V2.25H15.5V6.75H14M14 9.75V8.25H15.5V9.75H14Z"
      fill="#DC1515"
    />
  </svg>,
  'PeopleExclaimIcon'
);
export default PeopleExclaimIcon;

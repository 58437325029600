import { SubmitHelpers } from '@area2k/use-form';
import useModal from '@area2k/use-modal';
import { format } from 'date-fns';
import { isEqual } from 'lodash';
import { useState } from 'react';

import Button from '@/components/Button';
import Card from '@/components/Card';
import ConfirmationModal from '@/components/ConfirmationModal';
import FormElement from '@/components/FormElement';
import Modal from '@/components/Modal';
import Option from '@/components/Option';
import Stack from '@/components/Stack';
import Form from '@/form';
import FormColumns from '@/form/FormColumns';
import MaskedInputField from '@/form/MaskedInputField';
import TextAreaField from '@/form/TextAreaField';
import TextField from '@/form/TextField';
import TextSelectField from '@/form/TextSelectField';
import {
  useUpdateUserProfileMutation,
  useWorkerDisableMutation,
  useWorkerEnableMutation,
  useWorkerUpdateProfileMutation,
} from '@/graphql';
import {
  GenderEnum,
  GetWorkerQuery,
  LanguageEnum,
  SmartphoneTypeEnum,
  TaxTypeEnum,
  UserRoleEnum,
} from '@/types/graphql';
import { CONFIRM_MSG } from '@/util/constants';
import { handleMutationFormError } from '@/util/error';

export type Props = {
  worker: GetWorkerQuery['worker'];
  hideModal: () => void;
};

type FormValues = {
  active: Props['worker']['active'];
  email: Props['worker']['user']['email'];
  firstName: Props['worker']['user']['firstName'];
  middleName: Props['worker']['user']['middleName'];
  lastName: Props['worker']['user']['lastName'];
  gender: GenderEnum;
  heardFrom: Props['worker']['heardFrom'];
  language: LanguageEnum;
  phoneNumber: Props['worker']['user']['phoneNumber'];
  ownsCar: Props['worker']['ownsCar'];
  secondaryLanguage: LanguageEnum;
  smartphoneType: SmartphoneTypeEnum;
  dateOfBirth: Props['worker']['user']['dateOfBirth'];
  hasSsn: Props['worker']['hasSsn'];
  reenableDate: Props['worker']['reenableDate'];
  disableReason: Props['worker']['disableReason'];
  deactivatedByUser: Props['worker']['deactivatedByUser'];
};

const smartPhoneTypeOptions = [
  { label: 'Android', value: SmartphoneTypeEnum.ANDROID },
  { label: 'IOS', value: SmartphoneTypeEnum.IOS },
  { label: 'Other', value: SmartphoneTypeEnum.OTHER },
];

const genderOptions = [
  { label: 'Male', value: GenderEnum.MALE },
  { label: 'Female', value: GenderEnum.FEMALE },
  { label: 'Non-Binary', value: GenderEnum.NON_BINARY },
  { label: 'Unspecified', value: GenderEnum.UNSPECIFIED },
];

const UpdateProfileModal = ({ worker, hideModal }: Props) => {
  const initialValues: FormValues = {
    active: worker.active,
    email: worker.user.email,
    firstName: worker.user.firstName,
    middleName: worker.user.middleName,
    lastName: worker.user.lastName,
    gender: worker.gender,
    heardFrom: worker.heardFrom,
    dateOfBirth: new Date(worker.user.dateOfBirth!).toISOString().split('T')[0],
    language: worker.user.language,
    phoneNumber: worker.user.phoneNumber,
    ownsCar: worker.ownsCar ? worker.ownsCar : false,
    secondaryLanguage: worker.user.secondaryLanguage!,
    smartphoneType: worker.user.smartphoneType || SmartphoneTypeEnum.ANDROID,
    hasSsn: worker.hasSsn,
    reenableDate: !worker.reenableDate
      ? ''
      : new Date(worker.reenableDate!).toISOString().split('T')[0],
    disableReason: worker.disableReason,
    deactivatedByUser: worker.deactivatedByUser,
  };
  const [workerValues, setWorkerValues] = useState<FormValues>(initialValues);
  const [disableWorker] = useWorkerDisableMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(worker),
        fields: {
          status() {},
        },
      });
    },
  });
  const [enableWorker] = useWorkerEnableMutation({
    variables: {
      workerId: worker.id,
    },
    update: (cache) => {
      cache.modify({
        id: cache.identify(worker),
        fields: {
          status() {},
        },
      });
    },
  });
  const [updateWorkerProfile, { loading: isLoading }] =
    useWorkerUpdateProfileMutation({
      update: (cache) => {
        cache.modify({
          id: cache.identify(worker),
          fields: { user() {} },
        });
      },
    });
  const [updateUserProfile, { loading: isUserLoading }] =
    useUpdateUserProfileMutation({
      update: (cache) => {
        cache.modify({
          id: cache.identify(worker),
          fields: { user() {} },
        });
      },
    });

  const handleWorkerValuesChange = ({ value }, id: keyof FormValues) =>
    setWorkerValues({ ...workerValues, [id]: value });
  const [showConfirmationModal, hideConfirmationModal] = useModal(
    ({ values, submitHelpers }: any) => (
      <ConfirmationModal
        acceptAction={() => handleSubmit(values, submitHelpers)}
        acceptButtonLabel="Confirm"
        bodyContentText={CONFIRM_MSG}
        denyAction={() => {}}
        denyButtonLabel="Cancel"
        hideModal={hideConfirmationModal}
        title={`Confirmation`}
      />
    ),
    [workerValues, initialValues]
  );

  const handleSubmit = async (
    values: FormValues,
    { setFormError }: SubmitHelpers
  ) => {
    try {
      if (isEqual(workerValues, initialValues)) {
        return hideModal();
      }

      const {
        dateOfBirth,
        email,
        firstName,
        middleName,
        lastName,
        gender,
        heardFrom,
        language,
        phoneNumber,
        secondaryLanguage,
        smartphoneType,
        reenableDate,
        disableReason,
      } = values;

      const { active, ownsCar, hasSsn } = workerValues;

      if (!active) {
        await disableWorker({
          variables: {
            workerId: worker.id,
            reenableDate: reenableDate || null,
            reason: disableReason || null,
          },
        });
      } else {
        await enableWorker();
      }

      await updateWorkerProfile({
        variables: {
          workerId: worker.id,
          firstName,
          middleName,
          lastName,
          email,
          dateOfBirth: dateOfBirth!,
          gender,
          heardFrom,
          language,
          ownsCar,
          secondaryLanguage,
          smartphoneType,
          hasSsn,
        },
      });

      if (values.phoneNumber !== initialValues.phoneNumber) {
        await updateUserProfile({
          // @ts-ignore
          variables: {
            userId: worker.user.id,
            firstName,
            middleName,
            lastName,
            phoneNumber,
            roleId: UserRoleEnum.WORKER,
          },
        });
      }

      hideModal();
    } catch (err) {
      handleMutationFormError(err, {
        setFormError,
        errorMap: {
          all: (gqlError) => ({
            title: gqlError.name,
            message: gqlError.message,
            status: 'danger',
          }),
        },
      });
    }
  };

  return (
    <Modal
      disableClickout
      size="lg"
      title="Update Profile"
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Form
          initialValues={initialValues}
          onSubmit={async (values: FormValues, submitHelpers: SubmitHelpers) =>
            showConfirmationModal({ values, submitHelpers })
          }
        >
          <FormColumns layout="triple">
            <TextField
              autoFocus
              required
              callback={(fieldContext) => {
                handleWorkerValuesChange(fieldContext, 'firstName');
              }}
              fieldId="firstName"
              label="First Name"
            />
            <TextField
              autoFocus
              callback={(fieldContext) => {
                handleWorkerValuesChange(fieldContext, 'middleName');
              }}
              fieldId="middleName"
              label="Middle Name"
            />
            <TextField
              required
              callback={(fieldContext) => {
                handleWorkerValuesChange(fieldContext, 'lastName');
              }}
              fieldId="lastName"
              label="Last Name"
            />
          </FormColumns>
          <FormColumns>
            <TextField
              required
              callback={(fieldContext) => {
                handleWorkerValuesChange(fieldContext, 'dateOfBirth');
              }}
              fieldId="dateOfBirth"
              label="Date of Birth"
              type="date"
            />
            <TextSelectField
              required
              callback={(fieldContext) => {
                handleWorkerValuesChange(fieldContext, 'gender');
              }}
              fieldId="gender"
              label="Gender"
              options={genderOptions}
            />
          </FormColumns>
          <FormColumns>
            <MaskedInputField
              callback={(fieldContext) => {
                handleWorkerValuesChange(fieldContext, 'phoneNumber');
              }}
              fieldId="phoneNumber"
              incompletemessage="Must be a valid phone number"
              label="Phone Number"
              mask="(000) 000-0000"
              placeholder={
                workerValues.phoneNumber
                  ? workerValues.phoneNumber
                  : '(555) 555-5555'
              }
              type="tel"
            />
            <TextField
              required
              callback={(fieldContext) => {
                handleWorkerValuesChange(fieldContext, 'email');
              }}
              fieldId="email"
              label="Email Address"
            />
          </FormColumns>
          <FormColumns>
            <TextSelectField
              required
              callback={(fieldContext) => {
                handleWorkerValuesChange(fieldContext, 'smartphoneType');
              }}
              fieldId="smartphoneType"
              label="Phone Type"
              options={smartPhoneTypeOptions}
            />
            <FormElement label="Important Information">
              <Stack vertical>
                <Option
                  appearance="checkbox"
                  checked={
                    worker.taxType === TaxTypeEnum.TAX_W2
                      ? false
                      : workerValues.hasSsn
                  }
                  disabled={worker.taxType === TaxTypeEnum.TAX_W2}
                  id="hasSsn"
                  label="Eligible for W2 employment"
                  onChange={() =>
                    handleWorkerValuesChange(
                      { value: !workerValues.hasSsn },
                      'hasSsn'
                    )
                  }
                />
                <Option
                  appearance="checkbox"
                  checked={workerValues.ownsCar}
                  id="ownsCar"
                  label="Access To Reliable Transportation?"
                  onChange={() =>
                    handleWorkerValuesChange(
                      { value: !workerValues.ownsCar },
                      'ownsCar'
                    )
                  }
                />
              </Stack>
            </FormElement>
          </FormColumns>
          <FormColumns>
            <FormElement label="Status">
              <Option
                appearance="switch"
                checked={Boolean(workerValues.active)}
                id="worker-status"
                label="Account Enabled"
                onChange={() =>
                  handleWorkerValuesChange(
                    { value: !workerValues.active },
                    'active'
                  )
                }
              />
              <FormElement>
                {!workerValues.active && workerValues.deactivatedByUser && (
                  <>
                    <span>Account has been disabled by </span>
                    <span style={{ fontWeight: 'bold' }}>
                      {[
                        workerValues.deactivatedByUser.firstName,
                        workerValues.deactivatedByUser.middleName,
                        workerValues.deactivatedByUser.lastName,
                      ].join(' ')}
                    </span>
                  </>
                )}
              </FormElement>

              {!workerValues.active && (
                <TextField
                  callback={(fieldContext) => {
                    handleWorkerValuesChange(fieldContext, 'reenableDate');
                  }}
                  fieldId="reenableDate"
                  label="Account Re-Enable Date"
                  min={format(new Date(), 'yyyy-MM-dd')}
                  type="date"
                />
              )}
            </FormElement>
            {!workerValues.active && (
              <FormElement label="Account Disabling Reason">
                <TextAreaField
                  autoFocus
                  callback={(fieldContext) => {
                    handleWorkerValuesChange(fieldContext, 'disableReason');
                  }}
                  fieldId={'disableReason'}
                  maxLength={250}
                  placeholder="State reason for disabling the worker"
                />
              </FormElement>
            )}
          </FormColumns>
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              id="btn-submit"
              isLoading={isLoading || isUserLoading}
              label="Save"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  );
};

export default UpdateProfileModal;

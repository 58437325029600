import React from 'react';

import TableElement from '@/elements/Table';
import styled from '@/styles';

const Wrapper = styled('div', {
  variants: {
    spacing: {
      false: {
        overflowX: 'auto',
      },
    },
  },
});

const Table: React.FC<{ spacing?: boolean }> = ({ children, spacing }) => {
  return (
    <Wrapper spacing={spacing}>
      <TableElement spacing={spacing}>{children}</TableElement>
    </Wrapper>
  );
};

export default Table;

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState } from 'react';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';

import { Step } from '../..';
import { useSignUpActions } from '../../hooks/useSignUpActions';
import { useSignUpState } from '../../hooks/useSignUpState';
import { ThirdStepUpdate } from '../../interfaces/signUp.interface';
import { BackButton, InputWrapper } from '../../styles';

import { thirdStepSchema } from './schema';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import FormElement from '@/components/FormElement';
import MaskedInput from '@/components/MaskedInput';
import Option from '@/components/Option';
import Stack from '@/components/Stack';
import TextInput from '@/components/TextInput';
import TextSelect from '@/components/TextSelect';
import { Body, Small } from '@/components/Typography';
import { GAEvent } from '@/constants/gaevents';
import Input from '@/elements/Input';
import FormColumns from '@/form/FormColumns';
import { TextSelectOption } from '@/form/TextSelectField';
import { useCustomerAdminSignUpMutation } from '@/graphql';
import { ReferralEnum } from '@/types/graphql';
import useAnalytics from '@/util/analytics';
import { openInNewTab } from '@/util/link';

type Props = {
  setStep: (step: Step) => void;
};

const ThirdStep = ({ setStep }: Props) => {
  const [submitErrors, setSubmitErrors] = useState<any>(null);
  const [maskedInputIsComplete, setMaskedInputIsComplete] =
    useState<boolean>(false);
  const [agreeWithUseAndPrivacyTerms, setAgreeWithUseAndPrivacTerms] =
    useState<boolean>(false);
  const {
    firstName,
    lastName,
    phoneNumber,
    email,
    howDidYourHearAboutUs,
    ...rest
  } = useSignUpState();
  const { updateThirdStep } = useSignUpActions();
  const { logEvent } = useAnalytics();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm<ThirdStepUpdate>({
    resolver: yupResolver(thirdStepSchema),
    mode: 'onChange',
    defaultValues: {
      firstName,
      lastName,
      phoneNumber,
      howDidYourHearAboutUs,
    },
  });

  const [customerAdminSignUp, { loading }] = useCustomerAdminSignUpMutation({
    onCompleted: (data) => {
      logEvent(GAEvent.Signup, data?.customerSignup?.customer?.id);
      setStep(Step.FOURTH);
    },
    onError: (error) => setSubmitErrors(error),
  });

  const options = useMemo(
    (): TextSelectOption[] => [
      { value: ReferralEnum.FACEBOOK, label: 'Facebook' },
      { value: ReferralEnum.GOOGLE, label: 'Google' },
      { value: ReferralEnum.INSTAGRAM, label: 'Instagram' },
      { value: ReferralEnum.INDEED, label: 'Indeed' },
      { value: ReferralEnum.CRAIGLIST, label: `Craiglist` },
      { value: ReferralEnum.WORD_OF_MOUTH, label: `Word of mouth` },
      { value: ReferralEnum.OTHER, label: `Other` },
    ],
    []
  );

  const TermsOfUseAndPrivacy = useMemo(
    () => (
      <Stack inline wrap gap={4}>
        <span>I agree with</span>
        <Button
          a11yLabel="Open new tab to Terms of use"
          appearance="plain"
          label="Terms of Use"
          type="button"
          onClick={() => openInNewTab('https://gravywork.com/terms-of-use/')}
        />
        <span>and</span>
        <Button
          a11yLabel="Open new tab to Privacy Policy"
          appearance="plain"
          label="Privacy Policy"
          type="button"
          onClick={() => openInNewTab('https://gravywork.com/privacy-policy/')}
        />
      </Stack>
    ),
    []
  );

  const onSubmit: SubmitHandler<ThirdStepUpdate> = (
    values: ThirdStepUpdate
  ) => {
    const { firstName, lastName, phoneNumber, howDidYourHearAboutUs } = values;
    const { companyName, password, address } = rest;
    const { addressLine1, city, state, zip, coords, neighborhood } = address;

    updateThirdStep(values);

    customerAdminSignUp({
      variables: {
        agencyId: '1',
        // Step 1
        customerName: companyName,
        email,
        password,

        // Step 2
        addressLine1,
        addressLine2: '',
        name: '',
        city,
        state,
        zip,
        coords,
        neighborhood,

        // Step 3
        firstName,
        lastName,
        phoneNumber,
        referral: howDidYourHearAboutUs,
      },
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card.Section>
        {submitErrors && (
          <Alert
            icon={faExclamationTriangle}
            status="warning"
            title={
              submitErrors.message
                ? submitErrors.message
                : 'Something went wrong'
            }
          />
        )}

        <Body>Personal</Body>
        <FormColumns layout="double">
          <FormElement htmlFor="firstName" label="First Name">
            <Stack vertical verticalGap={6}>
              <InputWrapper>
                <TextInput
                  autoFocus
                  {...register('firstName')}
                  id="firstName"
                  placeholder="First Name"
                />
              </InputWrapper>
              {errors.firstName && (
                <Small color="danger">{errors.firstName.message}</Small>
              )}
            </Stack>
          </FormElement>

          <FormElement htmlFor="lastName" label="Last Name">
            <Stack vertical verticalGap={6}>
              <InputWrapper>
                <TextInput
                  {...register('lastName')}
                  id="lastName"
                  placeholder="Last Name"
                />
              </InputWrapper>
              {errors.lastName && (
                <Small color="danger">{errors.lastName.message}</Small>
              )}
            </Stack>
          </FormElement>
        </FormColumns>

        <FormColumns layout="double">
          <FormElement htmlFor="phoneNumber" label="Phone Number">
            <Controller
              control={control}
              name="phoneNumber"
              render={({ field: { onChange } }) => (
                <MaskedInput
                  id="phoneNumber"
                  mask="(000) 000-0000"
                  placeholder="(000) 000-0000"
                  type="tel"
                  onAccept={(_, inputMask) =>
                    setMaskedInputIsComplete(inputMask.masked.isComplete)
                  }
                  onChange={onChange}
                />
              )}
            />
            {errors.phoneNumber && (
              <Small color="danger">{errors.phoneNumber.message}</Small>
            )}
          </FormElement>

          <FormElement htmlFor="email" label="Email">
            <Stack vertical verticalGap={6}>
              <Input disabled id="email" value={email} />
            </Stack>
          </FormElement>
        </FormColumns>

        <Body>Referral</Body>
        <FormColumns layout="single">
          <FormElement htmlFor="howAboutUs" label="How did you hear about us?">
            <Stack vertical verticalGap={6}>
              <TextSelect
                {...register('howDidYourHearAboutUs')}
                id="howAboutUs"
              >
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextSelect>
            </Stack>
            {errors.howDidYourHearAboutUs && (
              <Small color="danger">
                {errors.howDidYourHearAboutUs.message}
              </Small>
            )}
          </FormElement>
        </FormColumns>

        <FormColumns layout="single">
          <FormElement htmlFor="termsUseAndPrivacyPolicy">
            <Option
              appearance="checkbox"
              checked={agreeWithUseAndPrivacyTerms}
              id="termsUseAndPrivacyPolicy"
              label={TermsOfUseAndPrivacy}
              type="checkbox"
              onChange={() =>
                setAgreeWithUseAndPrivacTerms((prevValue) => !prevValue)
              }
            />
          </FormElement>
        </FormColumns>

        <FormColumns layout="double">
          <BackButton
            a11yLabel="Go back to previous step"
            appearance="outline"
            id="back-btn"
            label="Back"
            type="button"
            onClick={() => setStep(Step.SECOND)}
          />

          <Button
            a11yLabel="Submit form"
            disabled={
              !isValid || !agreeWithUseAndPrivacyTerms || !maskedInputIsComplete
            }
            id="next-btn"
            isLoading={loading}
            label="Next"
            type="submit"
          />
        </FormColumns>
      </Card.Section>
    </form>
  );
};

export default ThirdStep;

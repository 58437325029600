import { useReactiveVar } from '@apollo/client';
import qs from 'query-string';
import { useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Billing from './Billing';
import Invoices from './Invoices';
import {
  InvoiceGeneratorActions,
  InvoiceGeneratorActionsContext,
  InvoiceGeneratorActionType,
  InvoiceGeneratorStateContext,
  stateReducer,
} from './context';

import Page from '@/components/Page';
import useMediaQuery from '@/hooks/useMediaQuery';
import { Role } from '@/routes/PrivateRoute';
import ROUTES from '@/routes/routes';
import { currentAdminVar } from '@/util/apollo/cache';

const InvoiceGenerator = () => {
  const [state, dispatch] = useReducer(stateReducer, {
    billing: null,
  });

  const navigate = useNavigate();

  const currentAdmin = useReactiveVar(currentAdminVar);

  if (currentAdmin!.role !== Role.TENANT_ADMIN) {
    navigate(ROUTES.basePath, { replace: true });
  }

  const actions: InvoiceGeneratorActions = {
    setBilling: (billing) =>
      dispatch({ type: InvoiceGeneratorActionType.SET_BILLING, billing }),
  };
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const [clientId, setClientId] = useState(0);
  const [accountId, setAccountId] = useState(0);

  useEffect(() => {
    const parsed = qs.parse(location.search);
    if (parsed && parsed.clientId && parsed.accountId) {
      setClientId(parseInt(parsed.clientId));
      setAccountId(parseInt(parsed.accountId));
    } else if (state.billing) {
      setClientId(0);
      setAccountId(0);
      actions.setBilling(null);
    }
  }, [location.search]);

  useEffect(() => {
    if (state.billing) {
      navigate({
        search: `?clientId=${state.billing.customer.id}&accountId=${state.billing.account.id}`,
      });
    }
  }, [state.billing]);

  const resetQueryParams = () => {
    navigate({
      search: '',
    });
  };

  return (
    <InvoiceGeneratorActionsContext.Provider value={actions}>
      <InvoiceGeneratorStateContext.Provider value={state}>
        {state.billing ? (
          <Invoices />
        ) : (
          <Page
            headerPadding={phoneOnly}
            noPadding={phoneOnly}
            size={phoneOnly ? 'full' : 'md'}
            title="Invoice Generator"
          >
            <Billing
              accountId={accountId}
              clientId={clientId}
              resetQueryParams={resetQueryParams}
            />
          </Page>
        )}
      </InvoiceGeneratorStateContext.Provider>
    </InvoiceGeneratorActionsContext.Provider>
  );
};

export default InvoiceGenerator;

import { SkillCategory, Skill } from '../../context';

import Stack from '@/components/Stack';
import TextStack from '@/components/TextStack';
import styled from '@/styles';

const DEFAULT_IMAGE_SIZE = 90;
const DEFAULT_IMAGE_SIZE_DENSE = 60;

const Wrapper = styled('div', {
  padding: '0.5rem',
  height: DEFAULT_IMAGE_SIZE + 65,

  variants: {
    chip: {
      true: {
        height: 'auto',
        padding: '6px 12px',
      },
      false: {},
    },
    dense: {
      true: {
        height: '100%',
        padding: '6px',
      },
      false: {},
    },
  },
});

const Image = styled('img', {
  height: DEFAULT_IMAGE_SIZE,
  width: DEFAULT_IMAGE_SIZE,
  '@phoneOnly': {
    height: DEFAULT_IMAGE_SIZE_DENSE,
    width: DEFAULT_IMAGE_SIZE_DENSE,
  },
});

const Text = styled(TextStack, {
  height: '100%',
  width: DEFAULT_IMAGE_SIZE,
  marginTop: '0.5rem',
  textAlign: 'center',
  '@phoneOnly': {
    fontSize: '12px',
  },
  variants: {
    chip: {
      true: {
        height: 'auto',
        width: 'auto',
        margin: 0,
        lineHeight: 'normal',
      },
      false: {},
    },
  },
});

type Props = {
  item: SkillCategory | Skill;
  hideImg?: boolean;
  dense?: boolean;
};

const SkillItem = ({ item, hideImg = false, dense = false }: Props) => (
  <Wrapper chip={hideImg} dense={dense}>
    <Stack
      vertical
      align="center"
      css={{
        width: !hideImg ? (dense ? '100%' : DEFAULT_IMAGE_SIZE) : 'auto',
        height: '100%',
      }}
      gap={!dense ? 0 : 8}
      justify="apart"
    >
      {!hideImg && (
        <Image
          src={
            item.imageUrl ??
            `http://placehold.it/${DEFAULT_IMAGE_SIZE}x${DEFAULT_IMAGE_SIZE}`
          }
        />
      )}

      <Text align="center" chip={hideImg || dense} justify="center">
        {item.name}
      </Text>
    </Stack>
  </Wrapper>
);

export default SkillItem;

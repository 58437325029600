import {
  PickerChangeHandlerContext,
  TimeStepOptions,
  TimeValidationError,
} from '@mui/x-date-pickers';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';

import { ROUND_OFF_MINUTES } from '@/constants/general';
import styled from '@/styles';
import './index.css';
import { theme } from '@/styles/colors';

type PropTypes = {
  onChange?: (
    value: Dayjs,
    context: PickerChangeHandlerContext<TimeValidationError>,
    name: string,
  ) => void;
  minutesStep?: number;
  name: string;
  defaultValue?: Dayjs;
  label?: string;
  id?: string;
  timeSteps?: TimeStepOptions;
  inputStyle?: Record<string, any>;
  hideClock?: boolean;
  fix?: boolean;
};

const StyledTimePicker = styled(TimePicker, {
  borderColor: '$colors$neutralLight !important',
  borderWidth: '1px !important',
  borderRadius: '$lg !important',
  variants: {
    fix: {
      true: {
        width: '130px',
      },
      false: {
        width: '100%',
      },
    },
  },
  '@phoneOnly': {
    '& > div': {
      borderRadius: '10px !important',
    },
  },
  '& input': {
    padding: '4px 12px',
    fontSize: '$md !important',
    lineHeight: '32px !important',
    height: 'auto',
  },
});

const ShiftTimePicker = ({
  label,
  onChange,
  minutesStep,
  name,
  defaultValue,
  id,
  timeSteps,
  inputStyle = {},
  hideClock = false,
  fix = false,
}: PropTypes) => {
  if (!dayjs(defaultValue).isValid()) {
    defaultValue = undefined;
  }
  const [time, setTime] = useState<Dayjs | undefined>(defaultValue);
  const [pickerOpen, setPickerOpen] = useState<boolean>(false);
  const [placeholder, setPlaceholder] = useState<string>(label ?? '');
  const handleTimeChange = (newValue, e) => {
    if (e.validationError !== 'invalidDate') {
      let value = newValue;
      if (e.validationError === 'minutesStep') {
        if (value.minute() % ROUND_OFF_MINUTES !== 0) {
          // Round the value to the nearest multiple of 15 minutes
          const roundedMinutes =
            Math.round(value.minute() / ROUND_OFF_MINUTES) * ROUND_OFF_MINUTES;
          value = value.set('minute', roundedMinutes);
        }
        if (value.minute() >= 60) {
          value.set('minute', 0);
        }
      }
      setTime(value);

      if (onChange) {
        onChange(value, e, name);
      }
    } else {
      console.error(e.validationError);
    }
  };

  return (
    <StyledTimePicker
      desktopModeMediaQuery="@media (min-width: 768px)"
      fix={fix}
      minutesStep={minutesStep}
      open={pickerOpen}
      onOpen={() => setPickerOpen(true)}
      slotProps={{
        popper: { disablePortal: true },
        actionBar: { actions: [] },
        textField: {
          placeholder: `${placeholder}`,
          onClick: () => {
            setPickerOpen(true);
            setPlaceholder('hh:mm aa');
          },
          id,
        },
        mobilePaper: {
          sx: {
            '& .MuiClockPointer-root, .MuiClock-pin, .MuiClockNumber-root.Mui-selected':
              {
                backgroundColor: theme.themeDefault,
              },
            '& .MuiClockPointer-thumb': {
              backgroundColor: theme.themeDefault,
              borderColor: theme.themeDefault,
            },
            '& .MuiPickersToolbarText-root.Mui-selected': {
              color: theme.themeDefault,
            },
          },
        },
      }}
      sx={{
        ...(hideClock
          ? { '& .MuiInputAdornment-positionEnd': { display: 'none' } }
          : {}),
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderColor: theme.themeDark,
          },
          ...inputStyle,
        },
        '& .MuiFormLabel-root': {
          '&.MuiInputLabel-root': {
            top: '-6px',
          },
        },
        '& .MuiInputBase-input::placeholder': {
          color: 'rgba(0, 0, 0, 0.5)',
        },
        '& .MuiInputBase-input::-moz-placeholder': {
          color: 'rgba(0, 0, 0, 0.5)',
        },
        '& .MuiInputBase-input::-ms-placeholder': {
          color: 'rgba(0, 0, 0, 0.5)',
        },
        '& .MuiInputBase-input::-webkit-input-placeholder': {
          color: 'rgba(0, 0, 0, 0.5)',
        },
        '& .MuiInputBase-input': {
          color: time ? 'rgba(0, 0, 0, 1.0)' : 'rgba(0, 0, 0, 0.2)',
        },
      }}
      timeSteps={timeSteps || { hours: 1, minutes: 15, seconds: 1 }}
      value={time}
      onChange={handleTimeChange}
      onClose={() => {
        setPickerOpen(false);
        setPlaceholder(label ?? '');
      }}
    />
  );
};

export default ShiftTimePicker;

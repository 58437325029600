import Button from '@/components/Button';
import Page from '@/components/Page';
import styled from '@/styles';

export const StyledPage = styled(Page, {
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});

export const InputWrapper = styled('div', {
  width: '100%',
});

export const BackButton = styled(Button, {
  '@touchable': {
    marginBottom: '1.25rem',
  },
});

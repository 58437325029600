import type { GeocoderResult, GoogleAddress } from './types';

import { NOT_PROVIDED } from '@/constants/text';

const findContent = (
  attribute: string,
  geocoderResult: GeocoderResult,
  short = false
) => {
  const data = geocoderResult.address_components.find((item) =>
    item.types.includes(attribute)
  );

  if (data) {
    return short ? data.short_name : data.long_name;
  }

  return NOT_PROVIDED;
};
const validateTwoChars = (word: string) => (word.length !== 2 ? false : word);
const getAddress = (
  selectedAddress: GoogleAddress,
  geocoderResult: GeocoderResult
) => {
  const withValues = selectedAddress.value.terms[0].value;
  const streetNumber = findContent('street_number', geocoderResult);
  const withComponents = `${
    streetNumber === NOT_PROVIDED ? '' : streetNumber
  } ${findContent('route', geocoderResult)}`;

  return withComponents || withValues || NOT_PROVIDED;
};
const getCity = (geocoderResult: GeocoderResult) => {
  const withAddressComponents = findContent('locality', geocoderResult);
  return withAddressComponents || NOT_PROVIDED;
};
const getNeighborhood = (geocoderResult: GeocoderResult) => {
  const withAddressComponents = findContent('neighborhood', geocoderResult);
  return withAddressComponents || NOT_PROVIDED;
};
const getState = (
  selectedAddress: GoogleAddress,
  geocoderResult: GeocoderResult
) => {
  const withAddressComponents = findContent(
    'administrative_area_level_1',
    geocoderResult,
    true
  );
  const withTerms = selectedAddress.value.terms[2].value;

  return (
    validateTwoChars(withAddressComponents) ||
    validateTwoChars(withTerms) ||
    'NA'
  );
};

export {
  findContent,
  validateTwoChars,
  getAddress,
  getCity,
  getState,
  getNeighborhood,
};

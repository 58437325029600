import { faPen } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

import Avatar from '@/components/Avatar';
import Badge from '@/components/Badge';
import IconicButton from '@/components/IconicButton';
import LoadingState from '@/components/LoadingState';
import Stack from '@/components/Stack';
import TextStack from '@/components/TextStack';
import { Body, Small } from '@/components/Typography';
import { TableRow, TableCell } from '@/elements/Table';
import useTenantAdminProfile, { TenantAdminItem } from '@/hooks/useOpenProfile';
import { ListTenantAdminQuery } from '@/types/graphql';

type Props = {
  items: ListTenantAdminQuery['agency']['tenant']['admins'];
  headerFields: string[];
  isLoading: boolean;
};

const TableBodyTenantAdmin = ({ items, headerFields, isLoading }: Props) => {
  const [selectedTenant, setSelectedTenantItem] = useState<TenantAdminItem>();

  const { showProfile } = useTenantAdminProfile(selectedTenant!);

  return (
    <tbody>
      {items.map((item: TenantAdminItem) => (
        <TableRow key={item.id}>
          <TableCell>
            <Stack gap={20}>
              <div>
                <Avatar
                  firstName={item.user.firstName}
                  size="sm"
                  src={item.avatarUrl}
                />
              </div>
              <TextStack spacing="tight">
                <Body color="theme" weight="medium">
                  {item.user.firstName} {item.user.lastName}
                </Body>
                <Small>{item.user.email}</Small>
              </TextStack>
            </Stack>
          </TableCell>
          <TableCell>{item.user.phoneNumber}</TableCell>
          <TableCell>
            <Stack>
              <Badge
                label={item.user.active ? 'Enabled' : 'Disabled'}
                status={item.user.active ? 'theme' : 'danger'}
              />
            </Stack>
          </TableCell>
          <TableCell>
            <Stack justify="end">
              <IconicButton
                a11yLabel="profile"
                appearance="clear"
                icon={faPen}
                size="md"
                onClick={() => {
                  setSelectedTenantItem(item);
                  showProfile();
                }}
              />
            </Stack>
          </TableCell>
        </TableRow>
      ))}
      {isLoading && (
        <TableRow>
          <TableCell align="center" colSpan={headerFields.length + 1}>
            <LoadingState overlayColor="white" />
          </TableCell>
        </TableRow>
      )}
    </tbody>
  );
};

export default TableBodyTenantAdmin;

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import type { ReactNode } from 'react';

interface StripeProviderProps {
  children: ReactNode;
}

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY!);

/**
 * You can access stripe through the "useStripe" hook.
 * @example import { useStripe } from '@stripe/react-stripe-js';
 */
export const StripeProvider = ({ children }: StripeProviderProps) => {
  return (
    <Elements options={{ locale: 'en' }} stripe={stripePromise}>
      {children}
    </Elements>
  );
};

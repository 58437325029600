import { createSvgIcon } from '@mui/material';

const PaymentSuccessIcon = createSvgIcon(
  <svg
    fill="none"
    height="110"
    viewBox="0 0 110 110"
    width="110"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M55.25 91C75.2703 91 91.5 74.7703 91.5 54.75C91.5 34.7297 75.2703 18.5 55.25 18.5C35.2297 18.5 19 34.7297 19 54.75C19 74.7703 35.2297 91 55.25 91Z"
      fill="#EEFFEC"
      stroke="#44A735"
      strokeWidth="2"
    />
    <path
      d="M57.6339 87.9079C50.8604 87.9026 44.234 85.8943 38.5582 82.1265C32.8824 78.3587 28.4012 72.9934 25.6579 66.6812C22.9146 60.369 22.0272 53.3812 23.1033 46.5651C24.1794 39.7491 27.1728 33.3977 31.7203 28.2812C28.1523 31.51 25.2696 35.4482 23.2494 39.8539C21.2291 44.2596 20.114 49.0397 19.9724 53.9007C19.8309 58.7617 20.6659 63.6008 22.4262 68.1209C24.1866 72.6409 26.835 76.7464 30.2089 80.1852C33.5827 83.6239 37.6108 86.3233 42.0456 88.1175C46.4803 89.9117 51.2282 90.7628 55.9974 90.6185C60.7667 90.4742 65.4567 89.3376 69.7792 87.2785C74.1018 85.2194 77.9657 82.2813 81.1335 78.6447C74.7094 84.6139 66.3269 87.9182 57.6339 87.9079Z"
      fill="#ADDB9B"
    />
    <path
      d="M49.2236 68.4978C49.0532 68.4989 48.8843 68.465 48.7273 68.3984C48.5704 68.3317 48.4288 68.2337 48.3111 68.1103L36.8361 55.9103C36.6033 55.6761 36.4727 55.3593 36.4727 55.029C36.4727 54.6988 36.6033 54.382 36.8361 54.1478C36.9524 54.0306 37.0906 53.9376 37.2429 53.8742C37.3952 53.8107 37.5586 53.7781 37.7236 53.7781C37.8887 53.7781 38.052 53.8107 38.2044 53.8742C38.3567 53.9376 38.4949 54.0306 38.6111 54.1478L49.1736 65.3978L71.8361 41.3853C72.0649 41.1433 72.3804 41.0021 72.7133 40.9927C73.0462 40.9833 73.3691 41.1066 73.6111 41.3353C73.8532 41.564 73.9944 41.8796 74.0038 42.2124C74.0131 42.5453 73.8899 42.8683 73.6611 43.1103L50.1361 68.1103C50.0185 68.2337 49.8769 68.3317 49.72 68.3984C49.563 68.465 49.3941 68.4989 49.2236 68.4978Z"
      fill="#44A735"
    />
    <circle cx="41.2227" cy="15.5" fill="#ADDB9B" r="2.5" stroke="#ADDB9B" />
    <circle cx="33.2227" cy="104.5" r="2.5" stroke="#ADDB9B" />
    <circle cx="102.223" cy="75.5" fill="#ADDB9B" r="1.5" stroke="#ADDB9B" />
    <circle cx="3.22266" cy="35.5" r="1" stroke="#ADDB9B" />
    <circle cx="89" cy="15.5" r="2" stroke="#ADDB9B" />
  </svg>,
  'PaymentSuccessIcon'
);
export default PaymentSuccessIcon;

import { Step, StepsName } from '../../context';

import Text from '@/elements/Text';
import styled from '@/styles';
import { Maybe } from '@/types';
import { JobTypeEnum } from '@/types/graphql';

const isStepTraversable = (step: Step, currentStep: Step) =>
  currentStep >= step;

const isStepNext = (step: Step, completedStep: Maybe<Step>) =>
  completedStep ? step === completedStep + 1 : step === Step.SKILL;

const Wrapper = styled('ol', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingLeft: '0px',
  margin: 0,

  listStyle: 'none',
});

Wrapper.displayName = 'stitches(Progress.Wrapper)';

const Item = styled('li', {
  $$pathTop: '6px',

  $$beforePathColor: '$colors$neutralLighter',
  $$afterPathColor: '$colors$neutralLighter',

  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '2px',
  paddingTop: '2px',

  color: '$textLighter',

  '&::after': {
    content: '""',
    height: '1px',
    width: '50%',

    position: 'absolute',
    left: '50%',
    top: '$$pathTop',

    backgroundColor: '$$afterPathColor',

    zIndex: -1,
  },

  '&:not(:first-child)': {
    marginLeft: '24px',

    '&::before': {
      content: '""',
      height: '1px',
      width: 'calc(50% + 24px)',

      position: 'absolute',
      left: -24,
      top: '$$pathTop',

      backgroundColor: '$$beforePathColor',
    },
    '@phoneOnly': {
      marginLeft: '16px',
    },
  },

  '&:last-child': {
    '&::after': {
      display: 'none',
    },
  },

  variants: {
    isNext: {
      true: {},
      false: {},
    },
    isTraversable: {
      true: {
        $$pathTop: '10px',

        $$beforePathColor: '$colors$themeDefault',
        $$afterPathColor: '$colors$themeDefault',

        paddingTop: 2,

        color: '$textDefault',
      },
      false: {},
    },
  },

  compoundVariants: [
    {
      isNext: true,
      isTraversable: true,
      css: {
        $$afterPathColor: '$colors$neutralLighter',
      },
    },
  ],
});

Item.displayName = 'stitches(Progress.Item)';

const Indicator = styled('div', {
  height: 12,
  width: 12,

  backgroundColor: 'white',
  border: '1px solid $colors$neutralLight',
  borderRadius: '$round',
});

Indicator.displayName = 'stitches(Progress.Indicator)';

const StepButton = styled('button', {
  all: 'unset',
  boxSize: 20,

  backgroundColor: '$colors$themeDefault',
  borderRadius: '$round',
  color: 'white',

  fontSize: '$sm',
  textAlign: 'center',

  zIndex: 10,

  focusPseudoElement: {
    element: 'after',
  },

  variants: {
    isCurrent: {
      true: {
        boxShadow: '0 0 0 3px $colors$themeA24',
      },
      false: {},
    },
  },
});

StepButton.displayName = 'stitches(Progress.StepButton)';

export type Props = {
  completedStep: Maybe<Step>;
  currentStep: Step;
  orderType: Maybe<JobTypeEnum>;
  showPayRate: Boolean;
  isTouchableBreakpoint?: boolean;
};

const Progress = ({
  completedStep,
  currentStep,
  showPayRate,
  isTouchableBreakpoint,
}: Props) => {
  const totalSteps = Object.keys(Step).length / 2;

  return isTouchableBreakpoint ? (
    <div style={{ padding: '5px 10px' }}>
      <Text color={'lighter'}>{`Step ${currentStep} of ${totalSteps}: `}</Text>
      <Text weight={'semibold'}>{StepsName[currentStep]} </Text>
    </div>
  ) : (
    <Wrapper>
      <Item
        isNext={isStepNext(Step.SKILL, completedStep)}
        isTraversable={isStepTraversable(Step.SKILL, currentStep)}
      >
        {isStepTraversable(Step.SKILL, currentStep) ? (
          <StepButton isCurrent={currentStep === Step.SKILL}>
            {Step.SKILL}
          </StepButton>
        ) : (
          <Indicator />
        )}
        <Text color="inherit" size="sm">
          Job Type
        </Text>
      </Item>

      <Item
        isNext={isStepNext(Step.SCHEDULE, completedStep)}
        isTraversable={isStepTraversable(Step.SCHEDULE, currentStep)}
      >
        {isStepTraversable(Step.SCHEDULE, currentStep) ? (
          <StepButton isCurrent={currentStep === Step.SCHEDULE}>
            {Step.SCHEDULE}
          </StepButton>
        ) : (
          <Indicator />
        )}
        <Text color="inherit" size="sm">
          Schedule
        </Text>
      </Item>

      <Item
        isNext={isStepNext(Step.STAFFING, completedStep)}
        isTraversable={isStepTraversable(Step.STAFFING, currentStep)}
      >
        {isStepTraversable(Step.STAFFING, currentStep) ? (
          <StepButton isCurrent={currentStep === Step.STAFFING}>
            {Step.STAFFING}
          </StepButton>
        ) : (
          <Indicator />
        )}
        <Text color="inherit" size="sm">
          Staffing
        </Text>
      </Item>

      <Item
        isNext={isStepNext(Step.DETAILS, completedStep)}
        isTraversable={isStepTraversable(Step.DETAILS, currentStep)}
      >
        {isStepTraversable(Step.DETAILS, currentStep) ? (
          <StepButton isCurrent={currentStep === Step.DETAILS}>
            {Step.DETAILS}
          </StepButton>
        ) : (
          <Indicator />
        )}
        <Text color="inherit" size="sm">
          Instructions
        </Text>
      </Item>

      {showPayRate && (
        <Item
          isNext={isStepNext(Step.PAYRATE, completedStep)}
          isTraversable={isStepTraversable(Step.PAYRATE, currentStep)}
        >
          {isStepTraversable(Step.PAYRATE, currentStep) ? (
            <StepButton isCurrent={currentStep === Step.PAYRATE}>
              {Step.PAYRATE}
            </StepButton>
          ) : (
            <Indicator />
          )}
          <Text color="inherit" size="sm">
            Pay Rate
          </Text>
        </Item>
      )}

      <Item
        isNext={isStepNext(Step.SUBMISSION, completedStep)}
        isTraversable={isStepTraversable(Step.SUBMISSION, currentStep)}
      >
        {isStepTraversable(Step.SUBMISSION, currentStep) ? (
          <StepButton isCurrent={currentStep === Step.SUBMISSION}>
            {showPayRate ? Step.SUBMISSION : Step.SUBMISSION - 1}
          </StepButton>
        ) : (
          <Indicator />
        )}
        <Text color="inherit" size="sm">
          Submission
        </Text>
      </Item>
    </Wrapper>
  );
};

export default Progress;

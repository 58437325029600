import styled from '@/styles';

export const WeeekDay = styled('span', {
  textAlign: 'center',
  padding: '4px',
  borderRadius: '11px',
  width: '22px',
  height: '22px',
  border: '1px solid #F2F3F6',
  background: '#F2F3F6',
  color: '#262626',
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '12px',
});

export const weekDayActiveStyles = {
  border: '1px solid #45A735',
  background: 'white',
  color: '#45A735',
};

import useModal from '@area2k/use-modal';
import { useState } from 'react';

import InviteAdminModal from './InviteAdminModal';

import Avatar from '@/components/Avatar';
import Badge from '@/components/Badge';
import Card from '@/components/Card';
import EmptyState from '@/components/EmptyState';
import LoadingState from '@/components/LoadingState';
import Pagination from '@/components/Pagination';
import ResourceList from '@/components/ResourceList';
import Stack from '@/components/Stack';
import TextStack from '@/components/TextStack';
import { Body, Small } from '@/components/Typography';
import { TIME_TO_REFRESH } from '@/constants/general';
import { useGetCustomersAdminsPaginetedQuery } from '@/graphql';
import useAuth from '@/hooks/useAuth';
import useMediaQuery from '@/hooks/useMediaQuery';
import ROUTES from '@/routes/routes';
import {
  GetCustomerQuery,
  GetCustomersAdminsPaginetedQuery,
} from '@/types/graphql';

type CustomerAdminPaginatedItem =
  GetCustomersAdminsPaginetedQuery['customer']['admins']['items'][0];

type Props = {
  customer: GetCustomerQuery['customer'];
  currentAdminIsCustomerAdmin: boolean;
  accordion?: boolean;
};

const AdminList = ({
  customer,
  currentAdminIsCustomerAdmin,
  accordion = false,
}: Props) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const [page, setPage] = useState(1);
  const { currentAdmin } = useAuth();

  const query = useGetCustomersAdminsPaginetedQuery({
    variables: {
      customerId: customer.id,
      page,
      perPage: 10,
    },
    pollInterval: TIME_TO_REFRESH,
  });

  const [showinviteEmployeeModal, hideinviteEmployeeModal] = useModal(
    () => (
      <InviteAdminModal
        customer={customer}
        hideModal={hideinviteEmployeeModal}
      />
    ),
    [customer]
  );

  const renderItem = (item: CustomerAdminPaginatedItem) => {
    const isCurrentCustomerAdmin =
      currentAdminIsCustomerAdmin && currentAdmin?.id === item.id;

    const itemHref = () => {
      if (!currentAdminIsCustomerAdmin) {
        return `admin/${item.id}`;
      } else if (isCurrentCustomerAdmin) {
        return `../${ROUTES.myProfile}`;
      }
      return `../${ROUTES.myTeam}/${item.id}`;
    };

    const badgeLabel = () => {
      if (item.user.active) {
        if (item.acceptedAt) {
          return 'Verified';
        } else {
          return 'Pending';
        }
      }
      return item.active ? 'Disabled' : 'Deleted';
    };

    const badgeStatus = () => {
      if (item.user.active) {
        if (item.acceptedAt) {
          return 'theme';
        } else {
          return 'warning';
        }
      }
      return 'danger';
    };

    return (
      <ResourceList.Item
        key={`customer-admin-item-${item.id}`}
        disabled={!item.active}
        media={
          <Avatar
            firstName={item.user.firstName}
            size="sm"
            src={item.avatarUrl}
          />
        }
        to={item.active ? itemHref() : ''}
      >
        <Stack wrap={phoneOnly}>
          <TextStack>
            <Body>
              {item.user.firstName} {item.user.lastName}
            </Body>
            <Small>{item.user.email}</Small>
          </TextStack>
          <Badge label={badgeLabel()} status={badgeStatus()} />
        </Stack>
      </ResourceList.Item>
    );
  };

  if (!query.data) {
    return (
      <Card title="Administrators">
        <Card.Section>
          <LoadingState overlayColor="white" />
        </Card.Section>
      </Card>
    );
  }

  const admins = query.data.customer.admins;

  return (
    <Card
      accordion={accordion}
      actions={[
        {
          a11yLabel: 'Invite a new administrator',
          label: 'Invite',
          onAction: showinviteEmployeeModal,
          id: 'invite-admin',
        },
      ]}
      title="Administrators"
    >
      <ResourceList
        emptyState={
          <EmptyState
            text="Invite a new administrator by clicking the button above"
            title="No client admins"
          />
        }
        items={admins.items}
        renderItem={renderItem}
      />
      {admins.items.length > 0 && admins.pageInfo.totalPages > 1 && (
        <Card.Section>
          <Pagination
            currentPage={page}
            totalPages={admins.pageInfo.totalPages}
            onPageChange={setPage}
          />
        </Card.Section>
      )}
    </Card>
  );
};

export default AdminList;

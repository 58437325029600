import Stack from '@/components/Stack';
import TextInput from '@/components/TextInput';
import { CustomerAdminFilterSetInput } from '@/types/graphql';

type Props = {
  filters: CustomerAdminFilterSetInput;
  query: string;
  onQueryChange: (query: string) => void;
  onChangeFilter: <K extends keyof CustomerAdminFilterSetInput>(
    key: K,
    value: NonNullable<CustomerAdminFilterSetInput[K]>
  ) => void;
  onClearFilter: (key: keyof CustomerAdminFilterSetInput) => void;
  onClearAll: () => void;
};

const ContactFilters = ({ query, onQueryChange }: Props) => {
  return (
    <Stack vertical gap={16}>
      <Stack gap={16}>
        <div style={{ flex: 1 }}>
          <TextInput
            id="input-search-contacts"
            placeholder="Search for contacts..."
            value={query}
            onChange={(ev) => onQueryChange(ev.currentTarget.value)}
          />
        </div>
      </Stack>
    </Stack>
  );
};

export default ContactFilters;

export const theme = {
  themeInk: '#1c4315',
  themeDarkest: '#307525',
  themeDarker: '#37862a',
  themeDark: '#3e9630',
  themeDefault: '#45a735',
  themeNew: '#44A735',
  themeBackground: '#EEFFEC',
  themeLight: '#6ab95d',
  themeLighter: '#8fca86',
  themeLightest: '#b5dcae',
};

export const themeAlphas = {
  themeA48: 'rgba(69, 167, 53, 0.48)',
  themeA32: 'rgba(69, 167, 53, 0.32)',
  themeA24: 'rgba(69, 167, 53, 0.24)',
  themeA16: 'rgba(69, 167, 53, 0.16)',
  themeA12: 'rgba(69, 167, 53, 0.12)',
  themeA8: 'rgba(69, 167, 53, 0.08)',
  themeA4: 'rgba(69, 167, 53, 0.04)',
};

export const success = {
  successDarkest: '#107163',
  successDarker: '#128172',
  successDark: '#159180',
  successDefault: '#17a18e',
  successLight: '#45b4a5',
  successLighter: '#74c7bb',
  successLightest: '#a2d9d2',
  successTick: '#44A735',
};

export const successAlphas = {
  successA48: 'rgba(23, 161, 142, 0.48)',
  successA32: 'rgba(23, 161, 142, 0.32)',
  successA24: 'rgba(23, 161, 142, 0.24)',
  successA16: 'rgba(23, 161, 142, 0.16)',
  successA12: 'rgba(23, 161, 142, 0.12)',
  successA8: 'rgba(23, 161, 142, 0.08)',
  successA4: 'rgba(23, 161, 142, 0.04)',
};

export const info = {
  infoDark: '#4785FF',
  infoDefault: '#EAF1FF',
  infoLight: '#EAF1FF',
};

export const warning = {
  warningDarkest: '#b37839',
  warningDarker: '#cc8941',
  warningDark: '#e69a49',
  warningDefault: '#ffab51',
  warningLight: '#ffbc74',
  warningLighter: '#ffcd97',
  warningLightest: '#ffddb9',
};

export const warningAlphas = {
  warningA48: 'rgba(255, 171, 81, 0.48)',
  warningA32: 'rgba(255, 171, 81, 0.32)',
  warningA24: 'rgba(255, 171, 81, 0.24)',
  warningA16: 'rgba(255, 171, 81, 0.16)',
  warningA12: 'rgba(255, 171, 81, 0.12)',
  warningA8: 'rgba(255, 171, 81, 0.08)',
  warningA4: 'rgba(255, 171, 81, 0.04)',
};

export const danger = {
  dangerDarkest: '#B02A2A',
  dangerDarker: '#DC1515',
  dangerDark: '#E33636',
  dangerDefault: '#FC3C3C',
  dangerLight: '#FD6363',
  dangerLighter: '#FD8A8A',
  dangerLightest: '#FEB1B1',
};

export const dangerAlphas = {
  dangerA48: 'rgba(252, 60, 60, 0.48)',
  dangerA32: 'rgba(252, 60, 60, 0.32)',
  dangerA24: 'rgba(252, 60, 60, 0.24)',
  dangerA16: 'rgba(252, 60, 60, 0.16)',
  dangerA12: 'rgba(252, 60, 60, 0.12)',
  dangerA8: 'rgba(252, 60, 60, 0.08)',
  dangerA4: 'rgba(252, 60, 60, 0.04)',
};

export const neutral = {
  neutralDarkest: '#091E42',
  neutralDarker: '#253858',
  neutralDark: '#505F79',
  neuralLessDark: '#E1EFFF',
  neutralDefault: '#7A869A',
  neutralLight: '#B3BAC5',
  neutralLighter: '#DFE1E6',
  neutralLightest: '#EBECF0',
  neutralGhost: '#F4F5F7',
  neutralBlueLight: '#E0E9FF',
  neutralRatingLight: '#A1A1A1',
};

export const neutralAlphas = {
  neutralA48: 'rgba(9, 30, 66, 0.48)',
  neutralA32: 'rgba(9, 30, 66, 0.32)',
  neutralA24: 'rgba(9, 30, 66, 0.24)',
  neutralA16: 'rgba(9, 30, 66, 0.16)',
  neutralA12: 'rgba(9, 30, 66, 0.12)',
  neutralA8: 'rgba(9, 30, 66, 0.08)',
  neutralA4: 'rgba(9, 30, 66, 0.04)',
  neutralA2: 'rgba(9, 30, 66, 0.02)',
};

export const text = {
  textDefault: '#091E42',
  textGrayLightest: '#656565',
  textLight: '#344563',
  textLighter: '#5E6C84',
  textLightest: '#97A0AF',
  textBlueLight: '#004DE2',
  textNeutral: '#BDBDBD',
  textPlaceholder: 'rgba(0, 0, 0, 0.2)',
};

export const jobColors = {
  redLight: '#FFF5F5',
  red: '#FF0000',
  redLightest: '#FFD7D7',
  yellowLightest: '#F9F1DF',
  yellowLight: '#FFFCF5',
  yellow: '#FFB800',
  cancel: '#F9F9F9',
  header: '#C4C4C4',
  headerLight: '#E2E2E2',
  greyLigher: '#F4F6F3',
  nightCharcoal: '#332F2D14',
  badgelBlue: '#3171E1',
  badgeBrown: '#56565B',
  badgeGreen: '#0D7744',
  black: '#000',
  needsApproval: '#d49f29',
};
export const timesheetColors = {
  selectBg: '#F4F5F7',
  selected: '#44A735',
  unSelected: '#7A7A7A',
  bulkActionsBg: '#ECF4E9',
};
export const IconColor = '#7A7A7A';

export const whiteAlphas = {
  whiteA96: 'rgba(255, 255, 255, 0.96)',
  whiteA92: 'rgba(255, 255, 255, 0.92)',
  whiteA88: 'rgba(255, 255, 255, 0.88)',
  whiteA84: 'rgba(255, 255, 255, 0.84)',
  whiteA76: 'rgba(255, 255, 255, 0.76)',
  whiteA68: 'rgba(255, 255, 255, 0.68)',
  whiteA52: 'rgba(255, 255, 255, 0.52)',
  whiteA48: 'rgba(255, 255, 255, 0.48)',
  whiteA32: 'rgba(255, 255, 255, 0.32)',
  whiteA24: 'rgba(255, 255, 255, 0.24)',
  whiteA16: 'rgba(255, 255, 255, 0.16)',
  whiteA12: 'rgba(255, 255, 255, 0.12)',
  whiteA8: 'rgba(255, 255, 255, 0.08)',
  whiteA4: 'rgba(255, 255, 255, 0.04)',
};

export default {
  ...theme,
  ...themeAlphas,
  ...success,
  ...successAlphas,
  ...info,
  ...warning,
  ...warningAlphas,
  ...danger,
  ...dangerAlphas,
  ...neutral,
  ...neutralAlphas,
  ...text,
  ...whiteAlphas,
  ...jobColors,
  COLORNAME: 'fuschia',
};

// export const neutrals = {
//   N900: '#091E42',
//   N800: '#172B4D',
//   N700: '#253858',
//   N600: '#344563',
//   N500: '#42526E',
//   N400: '#505F79',
//   N300: '#5E6C84',
//   N200: '#6B778C',
//   N100: '#7A869A',
//   N90: '#8993A4',
//   N80: '#97A0AF',
//   N70: '#A5ADBA',
//   N60: '#B3BAC5',
//   N50: '#C1C7D0',
//   N40: '#DFE1E6',
//   N30: '#EBECF0',
//   N20: '#F4F5F7',
//   N10: '#FAFBFC',
//   N0: '#FFFFFF'
// }

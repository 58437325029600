import { ApolloError } from '@apollo/client';
import { SubmitHelpers } from '@area2k/use-form';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import ConfirmationModal from '@/components/ConfirmationModal';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import WorkerAutocompleteField, {
  WorkerItem,
} from '@/components/WorkerAutocompleteField';
import Form from '@/form';
import { GetJobDocument, useJobOfferCreateMutation } from '@/graphql';
import useAuth from '@/hooks/useAuth';
import { JOB_HIRE_AND_OFFER_ERROR_MAP } from '@/routes/Agency/Order/InviteAndHireModal';
import { GetJobQuery } from '@/types/graphql';
import { handleMutationFormError } from '@/util/error';
import { workerName } from '@/util/worker';

export type Props = {
  job: GetJobQuery['job'];
  hideModal: () => void;
};

type FormValues = {
  worker: WorkerItem | null;
};

const NewOfferModal = ({ job, hideModal }: Props) => {
  const jobId = job.id;
  const initialValues: FormValues = {
    worker: null,
  };
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [reInvitedWorkerId, setReInvitedWorkerId] = useState('');
  const { currentAdminIsCustomerAdmin } = useAuth();

  const [error, setError] = useState<boolean>(false);

  const [createJobOffer, { loading: isLoading }] = useJobOfferCreateMutation({
    refetchQueries: [{ query: GetJobDocument, variables: { jobId } }],
  });

  const handleSubmit = async (
    values: FormValues,
    { setFormError }: SubmitHelpers
  ): Promise<void> => {
    try {
      await createJobOffer({
        variables: { jobId, workerId: values.worker!.id, forceInvite: null },
      });

      hideModal();
    } catch (err: any) {
      if (
        err?.graphQLErrors &&
        err.graphQLErrors[0]?.extensions?.code === 'ALREADY_OFFERED' &&
        !currentAdminIsCustomerAdmin
      ) {
        setReInvitedWorkerId(values.worker!.id);
        setShowConfirmModal(true);
        return;
      }
      if (err instanceof ApolloError) {
        handleMutationFormError(
          err,
          {
            setFormError,
            errorMap: JOB_HIRE_AND_OFFER_ERROR_MAP,
          },
          workerName(values.worker)
        );
      } else {
        setError(true);
      }
    }
  };
  const handleReinvite = async () => {
    try {
      await createJobOffer({
        variables: { jobId, workerId: reInvitedWorkerId, forceInvite: true },
      });
      hideModal();
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <>
      <Modal
        disableClickout
        size="sm"
        title="Offer job to GravyWorker"
        onRequestClose={hideModal}
      >
        <Card.Section>
          {error && (
            <Alert
              icon={faExclamationTriangle}
              status="warning"
              title="GravyWorker not found"
            />
          )}
          <Form initialValues={initialValues} onSubmit={handleSubmit}>
            <WorkerAutocompleteField
              aria-labelledby="worker-label"
              context="default"
              fieldId="worker"
              jobId={jobId}
              label="Select GravyWorker"
              skillsId={[job.skill.id]}
            />
            <Stack justify="end">
              <Button
                a11yLabel="Submit form"
                id="invite-worker-btn"
                isLoading={isLoading}
                label="Offer"
                type="submit"
              />
            </Stack>
          </Form>
        </Card.Section>
      </Modal>
      {showConfirmModal && (
        <ConfirmationModal
          acceptAction={handleReinvite}
          acceptButtonLabel="Send Invite"
          bodyContentText={
            'This worker has previously declined an invitation for this job. Do you want to proceed?'
          }
          denyAction={() => {}}
          denyButtonLabel="Cancel"
          hideModal={() => setShowConfirmModal(false)}
          title={`Confirmation`}
        />
      )}
    </>
  );
};

export default NewOfferModal;

import TableCell from './TableCell';
import TableHead from './TableHead';
import TableHeading from './TableHeading';
import TableRow from './TableRow';

import styled from '@/styles';

const Table = styled('table', {
  borderCollapse: 'collapse',
  width: '100%',
  variants: {
    spacing: {
      true: {
        borderCollapse: 'separate',
        borderSpacing: '0 12px',
        '@phoneOnly': {
          borderSpacing: '0 8px',
        },
        tr: {
          boxShadow: 'none !important',
        },
        'thead th': {
          paddingBottom: 0,
        },
        'tbody tr': {
          'td:first-child': {
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
          },
          'td:last-child': {
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
          },
        },
      },
      false: {},
    },
  },
});

Table.displayName = 'stitches(Table)';

export { TableCell, TableHead, TableHeading, TableRow };
export default Table;

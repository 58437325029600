import { createSvgIcon } from '@mui/material';

const AlarmIcon = createSvgIcon(
  <svg
    fill="none"
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.41667 5.66712H8.16667V10.6671L12.125 13.0421L12.75 12.0171L9.41667 10.0421V5.66712ZM13.4475 0.508789L17.2867 3.71296L16.22 4.99212L12.3783 1.78962L13.4475 0.508789ZM4.55251 0.508789L5.62084 1.78879L1.78084 4.99212L0.714172 3.71212L4.55251 0.508789ZM9.00001 2.33379C4.85834 2.33379 1.50001 5.69212 1.50001 9.83379C1.50001 13.9755 4.85834 17.3338 9.00001 17.3338C13.1417 17.3338 16.5 13.9755 16.5 9.83379C16.5 5.69212 13.1417 2.33379 9.00001 2.33379ZM9.00001 15.6671C5.78334 15.6671 3.16667 13.0505 3.16667 9.83379C3.16667 6.61712 5.78334 4.00046 9.00001 4.00046C12.2167 4.00046 14.8333 6.61712 14.8333 9.83379C14.8333 13.0505 12.2167 15.6671 9.00001 15.6671Z"
      fill="#ADDB9B"
    />
  </svg>,
  'Alarm'
);
export default AlarmIcon;

import Action from '@/components/Action';
import Card from '@/components/Card';
import Stack from '@/components/Stack';
import { Body, Subheading } from '@/components/Typography';
import ROUTES from '@/routes/routes';

const AlreadyValidated = () => {
  return (
    <>
      <Card.Section>
        <Stack vertical verticalGap={10}>
          <Subheading>Your account has been validated</Subheading>
          <Body>{`Click "OK" to log into GravyWork.`}</Body>
        </Stack>
      </Card.Section>
      <Card.Footer>
        <Stack justify="end">
          <Action.Button
            action={{
              a11yLabel: `Open new tab to login`,
              label: 'Ok',
              href: ROUTES.login,
              id: 'ok-btn',
            }}
            appearance="primary"
          />
        </Stack>
      </Card.Footer>
    </>
  );
};

export default AlreadyValidated;

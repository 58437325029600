import useModal from '@area2k/use-modal';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useState } from 'react';

import MoreInfoModal from './MoreInfoModal';

import Card from '@/components/Card';
import EmptyState from '@/components/EmptyState';
import ResourceList from '@/components/ResourceList';
import Stack from '@/components/Stack';
import TextStack from '@/components/TextStack';
import { Body, Small } from '@/components/Typography';
import AddExperienceModal from '@/modals/AddExperienceModal';
import { GetWorkerQuery, WorkerReference } from '@/types/graphql';

type Item = GetWorkerQuery['worker']['references'][0];

type Props = {
  worker: GetWorkerQuery['worker'];
};

const ExperiencesList = ({ worker }: Props) => {
  const [selectedReferenceItem, setSelectedReferenceItem] =
    useState<WorkerReference[]>();

  const [showMoreInfoModal, hideMoreInfoModal] = useModal(
    () => (
      <MoreInfoModal
        hideModal={hideMoreInfoModal}
        reference={selectedReferenceItem}
      />
    ),
    [selectedReferenceItem]
  );
  const [showAddExperience, hideAddExperience] = useModal(
    () => <AddExperienceModal hideModal={hideAddExperience} worker={worker} />,
    [worker]
  );

  const renderItem = useCallback(
    (item: Item) => (
      <ResourceList.Item
        key={item.id}
        actions={[
          {
            a11yLabel: 'More information reference',
            icon: faSearch,
            onAction: () => {
              setSelectedReferenceItem(item);
              showMoreInfoModal();
            },
          },
        ]}
        to={'.'}
      >
        <Stack>
          <TextStack>
            <Body>{item.employerName}</Body>
            <Small>{item.title}</Small>
            <Small>{item.city}</Small>
            <Small>{item.phoneNumber}</Small>
            <Small color="theme">{item.duration}</Small>
          </TextStack>
        </Stack>
      </ResourceList.Item>
    ),
    []
  );
  return (
    <Card
      actions={[
        {
          a11yLabel: 'add experience',
          label: 'Add',
          onAction: () => showAddExperience(),
          id: 'add-experience',
        },
      ]}
      title="Experiences"
    >
      <ResourceList
        emptyState={
          <EmptyState text={undefined} title="No previous experiences added " />
        }
        items={worker.references}
        renderItem={renderItem}
      />
    </Card>
  );
};

export default ExperiencesList;

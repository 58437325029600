import { createSvgIcon } from '@mui/material';

const EditJobIcon = createSvgIcon(
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_13657_53038)">
      <path
        d="M13.7188 2.23851C13.9157 2.04152 14.1496 1.88527 14.407 1.77866C14.6643 1.67206 14.9402 1.61719 15.2188 1.61719C15.4973 1.61719 15.7732 1.67206 16.0305 1.77866C16.2879 1.88527 16.5218 2.04152 16.7188 2.23851C16.9157 2.43549 17.072 2.66934 17.1786 2.92671C17.2852 3.18408 17.3401 3.45993 17.3401 3.73851C17.3401 4.01708 17.2852 4.29293 17.1786 4.5503C17.072 4.80767 16.9157 5.04153 16.7188 5.23851L6.59375 15.3635L2.46875 16.4885L3.59375 12.3635L13.7188 2.23851Z"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_13657_53038">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(0.96875 -0.0078125)"
        />
      </clipPath>
    </defs>
  </svg>,
  'EditJobIcon',
);
export default EditJobIcon;

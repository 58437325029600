import useModal from '@area2k/use-modal';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { useNavigate, useParams } from 'react-router-dom';

import ProfileCard from './ContactSection/ProfileCard';
import InvoiceDuration from './InvoiceDuration';
import OrdersList from './OrdersList';
import PageSkeleton from './PageSkeleton';
import PaymentSection from './PaymentSection';

import Badge from '@/components/Badge';
import Button from '@/components/Button';
import ConfirmationModal from '@/components/ConfirmationModal';
import Page from '@/components/Page';
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout';
import Stack from '@/components/Stack';
import { Title } from '@/components/Typography';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { GAEvent } from '@/constants/gaevents';
import { TIME_TO_REFRESH } from '@/constants/general';
import { useDisableAccountMutation, useGetAccountQuery } from '@/graphql';
import useAuth from '@/hooks/useAuth';
import useMediaQuery from '@/hooks/useMediaQuery';
import useAnalytics from '@/util/analytics';

const Account = () => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const navigate = useNavigate();
  const { accountId } = useParams();
  const { currentAdminIsCustomerAdmin } = useAuth();
  const disableBillingAccount = useFeatureValue(
    FEATURE_TOGGLE.HideDisabledBillingAccount,
    false
  );
  const { logEvent } = useAnalytics();

  const query = useGetAccountQuery({
    variables: { accountId },
    pollInterval: TIME_TO_REFRESH,
  });

  const [disableAccount] = useDisableAccountMutation({
    onCompleted: () => {
      query?.refetch();
    },
  });

  const [showConfirmationModal, hideConfirmationModal] = useModal(
    () => (
      <ConfirmationModal
        acceptAction={async () => {
          query?.data?.account.active
            ? logEvent(GAEvent.DisableBillingAccount)
            : logEvent(GAEvent.ReEnableBillingAccount);

          await disableAccount({
            variables: {
              accountId: query?.data?.account.id!,
              active: !query?.data?.account.active,
            },
          });
        }}
        acceptButtonLabel={'Yes'}
        bodyContentText={`Are you sure you want to ${
          query?.data?.account.active ? 'disable' : 're enable'
        } this billing account?`}
        denyAction={hideConfirmationModal}
        denyButtonLabel={'No'}
        hideModal={hideConfirmationModal}
        title="Confirm"
      />
    ),
    [query?.data?.account]
  );

  if (!query.data) {
    return <PageSkeleton />;
  }

  const { account } = query.data;
  const firstAccount =
    account.customer.accounts.length === 1 &&
    account.customer.accounts[0].id === account.id;

  return (
    <Page
      customActions={
        !currentAdminIsCustomerAdmin &&
        disableBillingAccount &&
        account.canDisable ? (
          <Button
            a11yLabel="disable account"
            appearance={'outline'}
            label={account.active ? 'Disable Account' : 'Re-enable Account'}
            status={'danger'}
            onClick={showConfirmationModal}
          />
        ) : undefined
      }
      headerPadding={phoneOnly}
      noPadding={phoneOnly}
      size={phoneOnly ? 'full' : 'lg'}
      subtitle={account.customer.name}
      title={
        <Stack gap={14}>
          <Title>{account.name}</Title>
          {!account.active && <Badge label="Disabled" />}
        </Stack>
      }
    >
      <Stack vertical gap={30}>
        <PrimarySecondaryLayout css={{ gap: phoneOnly ? 0 : 24 }}>
          <PrimarySecondaryLayout.Primary>
            <Stack vertical>
              <OrdersList account={account} />
            </Stack>
          </PrimarySecondaryLayout.Primary>
          <PrimarySecondaryLayout.Secondary>
            <Stack vertical verticalGap={phoneOnly ? 0 : 24}>
              <ProfileCard account={account} phoneOnly={phoneOnly} />
              <InvoiceDuration account={account} phoneOnly={phoneOnly} />
              <PaymentSection account={account} firstAccount={firstAccount} />
            </Stack>
          </PrimarySecondaryLayout.Secondary>
        </PrimarySecondaryLayout>
        <Stack justify="end">
          <Button
            a11yLabel="Go back"
            appearance="primary"
            id="btn-go-back"
            label="Go Back"
            type="button"
            onClick={() => navigate(-1)}
          />
        </Stack>
      </Stack>
    </Page>
  );
};

export default Account;

import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import TextStack from '@/components/TextStack';
import { Body } from '@/components/Typography';
import { ContextConfirmModalCancellation } from '@/constants/job';
import { Scalars } from '@/types/graphql';

type Props = {
  hideModal: () => Scalars['Void'];
  onAction: () => Scalars['Void'];
  context: ContextConfirmModalCancellation;
};

const ConfirmModalCancellation = ({ hideModal, context, onAction }: Props) => {
  const title =
    context === ContextConfirmModalCancellation.CancelJob
      ? 'Are you sure you want to cancel this job?'
      : 'Are you sure you want to cancel this order?';

  return (
    <Modal size={'sm'} title={title} onRequestClose={hideModal}>
      <Card.Section>
        <Stack vertical>
          <TextStack spacing="extra-loose">
            <Body>Please note that you will be charged for 4 hours for: </Body>
            <Body>• Every hired worker</Body>
            <Body>
              • Any worker dismissed within 24 hours of the job start time
            </Body>
          </TextStack>

          <Stack justify="end">
            <Button
              a11yLabel="deny"
              appearance="outline"
              label="Cancel"
              status="danger"
              type="button"
              onClick={() => {
                hideModal();
              }}
            />

            <Button
              a11yLabel={'Ok'}
              label="Ok"
              type="button"
              onClick={() => {
                onAction();
              }}
            />
          </Stack>
        </Stack>
      </Card.Section>
    </Modal>
  );
};

export default ConfirmModalCancellation;

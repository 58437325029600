import { createSvgIcon } from '@mui/material';

const PeopleSearchIcon = createSvgIcon(
  <svg
    fill="none"
    height="17"
    viewBox="0 0 18 17"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9167 6.99967C14 6.99967 15.6667 8.66634 15.6667 10.7497C15.6667 11.483 15.4583 12.1747 15.0917 12.7497L17.6583 15.333L16.5 16.4913L13.9 13.933C13.325 14.2913 12.6417 14.4997 11.9167 14.4997C9.83333 14.4997 8.16666 12.833 8.16666 10.7497C8.16666 8.66634 9.83333 6.99967 11.9167 6.99967ZM11.9167 8.66634C11.3641 8.66634 10.8342 8.88583 10.4435 9.27654C10.0528 9.66724 9.83333 10.1971 9.83333 10.7497C9.83333 11.3022 10.0528 11.8321 10.4435 12.2228C10.8342 12.6135 11.3641 12.833 11.9167 12.833C12.4692 12.833 12.9991 12.6135 13.3898 12.2228C13.7805 11.8321 14 11.3022 14 10.7497C14 10.1971 13.7805 9.66724 13.3898 9.27654C12.9991 8.88583 12.4692 8.66634 11.9167 8.66634ZM7.33333 0.333008C8.21739 0.333008 9.06523 0.684197 9.69035 1.30932C10.3155 1.93444 10.6667 2.78229 10.6667 3.66634C10.6667 4.42467 10.4083 5.12467 9.98333 5.69134C9.26666 5.95801 8.625 6.38301 8.09166 6.91634L7.33333 6.99967C6.44928 6.99967 5.60143 6.64849 4.97631 6.02336C4.35119 5.39824 4 4.5504 4 3.66634C4 2.78229 4.35119 1.93444 4.97631 1.30932C5.60143 0.684197 6.44928 0.333008 7.33333 0.333008ZM0.666664 13.6663V11.9997C0.666664 10.233 3.425 8.78301 6.91666 8.66634C6.65 9.31634 6.5 10.0163 6.5 10.7497C6.5 11.8247 6.81666 12.833 7.33333 13.6663H0.666664Z"
      fill="#DC1515"
    />
  </svg>,
  'PeopleSearch'
);
export default PeopleSearchIcon;

import { SubmitHelpers } from '@area2k/use-form';
import { useCallback } from 'react';

import { getJobValues } from '../../util';

import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { MAX_LENGTH_ADDRESS_INSTRUCTIONS } from '@/constants/text';
import Form from '@/form';
import ItemSelectField from '@/form/ItemSelectField';
import TextAreaField from '@/form/TextAreaField';
import { useUpdateJobMutation } from '@/graphql';
import {
  GetJobQuery,
  Scalars,
  UpdateJobMutationVariables,
} from '@/types/graphql';
import { handleMutationFormError } from '@/util/error';

export type Props = {
  job: GetJobQuery['job'];
  hideModal: () => Scalars['Void'];
};

type AddressItem = GetJobQuery['job']['account']['customer']['addresses'][0];

type FormValues = {
  address: AddressItem;
  addressInstructions: UpdateJobMutationVariables['addressInstructions'];
};

const UpdateAddressModal = ({ job, hideModal }: Props) => {
  const initialValues: FormValues = {
    address: job.address,
    addressInstructions: job.addressInstructions ?? '',
  };

  const [updateJob, { loading: isLoading }] = useUpdateJobMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(job),
        fields: {
          address() {},
          addressInstructions() {},
        },
      });
    },
  });

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      const jobValues = getJobValues(job);
      try {
        if (
          values.address.id === jobValues.addressId &&
          values.addressInstructions === jobValues.addressInstructions
        ) {
          return hideModal();
        }

        await updateJob({
          variables: {
            ...jobValues,
            addressId: values.address.id,
            addressInstructions: values.addressInstructions.trim(),
          },
        });

        hideModal();
      } catch (err: any) {
        setFormError('error', { message: err.message });
        handleMutationFormError(err, { setFormError });
      }
    },
    [job],
  );

  return (
    <Modal
      disableClickout
      size="sm"
      title="Change address"
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <ItemSelectField<AddressItem>
            required
            fieldId="address"
            itemToKey={(item) => item.id}
            itemToString={(item) => (item ? `${item.addressLine1}` : '')}
            items={job.account.customer.addresses.filter(
              (address) => address.active,
            )}
            label="Address"
            placeholder="Select an address..."
          />
          <TextAreaField
            fieldId="addressInstructions"
            label="Address instructions"
            maxLength={MAX_LENGTH_ADDRESS_INSTRUCTIONS}
            placeholder="Provide any job location instructions..."
          />
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              isLoading={isLoading}
              label="Save"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  );
};

export default UpdateAddressModal;

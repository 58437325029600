import Button from '@/elements/Button';
import styled from '@/styles';

const IconicButton = styled(Button, {
  $$boxSize: '36px',
  $$fontSize: '$fontSizes$lg',

  height: '$$boxSize',
  padding: 0,
  width: '$$boxSize',

  fontSize: '$$fontSize',
  lineHeight: '$$boxSize',

  variants: {
    size: {
      xs: {
        $$boxSize: '21px',
        $$fontSize: '$fontSizes$md',
      },
      sm: {
        $$boxSize: '28px',
        $$fontSize: '$fontSizes$lg',
      },
      md: {},
      lg: {
        $$boxSize: '36px',
        $$fontSize: '$fontSizes$xl',
      },
      xl: {
        $$boxSize: '42px',
        $$fontSize: '$fontSizes$xxl',
      },
      full: {
        $$boxSize: '100%',
        $$fontSize: '11px',
      },
    },
    transparentOnHover: {
      true: {
        '&:hover': {
          backgroundColor: 'transparent',
          borderColor: 'transparent',
        },
      },
      false: {},
    },
    borderless: {
      true: {},
      false: {
        borderColor: '$colors$themeDefault',
        borderWidth: 1,
        borderStyle: 'solid',
        aspectRatio: 1,
        '&:hover': {
          backgroundColor: 'transparent',
          borderColor: '$colors$themeDefault',
        },
      },
    },

    borderColor: {
      default: {},
      neutral: {
        borderColor: '$colors$neutralLight',
      },
    },

    rounded: {
      true: {
        borderRadius: '$round',
      },
      false: {},
    },
    appearanceMod: {
      danger: {
        backgroundColor: '#DC1515',
        borderColor: '#DC1515',
        '&:hover': {
          backgroundColor: '#DC1515',
          borderColor: '#DC1515',
        },
      },
      black: {
        backgroundColor: '#000',
        borderColor: '#000',
        '&:hover': {
          backgroundColor: '#000',
          borderColor: '#000',
        },
      },
      default: {},
    },
  },

  defaultVariants: {
    size: 'md',
    rounded: false,
    borderless: true,
  },
});

export default IconicButton;

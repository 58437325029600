import { createSvgIcon } from '@mui/material';

const NoShowIcon = createSvgIcon(
  <svg
    fill="none"
    height="16"
    viewBox="0 0 22 16"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.460022 4.88L1.88002 3.46L4.00002 5.59L6.12002 3.46L7.54002 4.88L5.41002 7L7.54002 9.12L6.12002 10.54L4.00002 8.41L1.88002 10.54L0.460022 9.12L2.59002 7L0.460022 4.88ZM14 0C15.0609 0 16.0783 0.421427 16.8284 1.17157C17.5786 1.92172 18 2.93913 18 4C18 5.06087 17.5786 6.07828 16.8284 6.82843C16.0783 7.57857 15.0609 8 14 8C12.9392 8 11.9217 7.57857 11.1716 6.82843C10.4214 6.07828 10 5.06087 10 4C10 2.93913 10.4214 1.92172 11.1716 1.17157C11.9217 0.421427 12.9392 0 14 0ZM14 1.9C13.4431 1.9 12.9089 2.12125 12.5151 2.51508C12.1213 2.9089 11.9 3.44305 11.9 4C11.9 4.55695 12.1213 5.0911 12.5151 5.48492C12.9089 5.87875 13.4431 6.1 14 6.1C15.16 6.1 16.1 5.16 16.1 4C16.1 2.84 15.16 1.9 14 1.9ZM14 9C16.67 9 22 10.33 22 13V16H6.00002V13C6.00002 10.33 11.33 9 14 9ZM14 10.9C11 10.9 7.90002 12.36 7.90002 13V14.1H20.1V13C20.1 12.36 16.97 10.9 14 10.9Z"
      fill="#7A7A7A"
    />
  </svg>,
  'NoShowIcon'
);
export default NoShowIcon;

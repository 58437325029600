import StarIcon from '@mui/icons-material/Star';
import { Rating } from '@mui/material';
import { useEffect, useState } from 'react';

import FormElement from '@/components/FormElement';
import Stack from '@/components/Stack';
import styled from '@/styles';

const NumberLabelMobile = styled('span', {
  fontSize: '12px',
  background: '#E0EBFF',
  color: '#3A5FA0',
  fontWeight: '$medium',
  borderRadius: '4px',
  padding: '3px 6px',
});

type Props = {
  readOnly: boolean;
  initialRating: number | null;
  showNumberLabel?: boolean;
  starSize?: string;
  onChange?: (value: number | null) => void;
};

const RatingField = ({
  readOnly,
  initialRating,
  showNumberLabel,
  starSize = '18',
  onChange,
}: Props) => {
  const [value, setValue] = useState<number | null>(initialRating);

  const roundHalf = (num) => {
    return Math.round(num * 2) / 2;
  };

  const handleSelect = (index: number) => {
    setValue(index);
    if (onChange) {
      onChange(index);
    }
  };

  useEffect(() => {
    if (readOnly && initialRating) {
      setValue(Math.round(initialRating * 10) / 10);
    }
  }, [readOnly, initialRating]);

  const starValue = roundHalf(value || 0);

  return (
    <div style={{ width: 'auto' }}>
      <FormElement displayType={'row'}>
        <Stack inline style={{ width: 'auto' }}>
          {showNumberLabel && value !== null && (
            <NumberLabelMobile>
              {value ? value.toFixed(1) : '0.0'}
            </NumberLabelMobile>
          )}
          <Rating
            data-testid={'rating-component'}
            emptyIcon={<StarIcon fontSize="inherit" style={{ opacity: 0.5 }} />}
            name="simple-controlled"
            precision={readOnly ? 0.5 : 1}
            readOnly={readOnly}
            style={{ width: 'auto' }}
            sx={{
              fontSize: `${starSize}px`,
              '& .MuiRating-icon': {
                width: `${starSize}px`,
              },
            }}
            value={starValue}
            onChange={(event, newValue) => {
              handleSelect(newValue && newValue > 0 ? newValue : 1);
            }}
          />
        </Stack>
      </FormElement>
    </div>
  );
};

export default RatingField;

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useReducer } from 'react';

import {
  CreateWorkerActionTypes,
  initialState,
  workerModalReducer,
} from './reducer';

import AddressAutocomplete from '@/components/AddressAutocomplete';
import Alert from '@/components/Alert';
import Autocomplete from '@/components/Autocomplete';
import Button from '@/components/Button';
import Card from '@/components/Card';
import ExperienceForm from '@/components/ExperienceForm';
import FormElement from '@/components/FormElement';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import TagList from '@/components/TagList';
import Form from '@/form';
import FormColumns from '@/form/FormColumns';
import MaskedInputField from '@/form/MaskedInputField';
import OptionField from '@/form/OptionField';
import TextField from '@/form/TextField';
import TextSelectField from '@/form/TextSelectField';
import {
  useAddWorkerReferenceMutation,
  useCreateWorkerMutation,
  useListAgencySkillsQuery,
} from '@/graphql';
import useAuth from '@/hooks/useAuth';
import useDebouncedValue from '@/hooks/useDebouncedValue';
import useGetSortSkillItems from '@/hooks/useGetSortSkillItems';
import { SkillItem } from '@/types';
import { GenderEnum, LanguageEnum, Scalars } from '@/types/graphql';
import { removeAtIndex } from '@/util/array';
import { hasGraphQLErrors, isApolloError } from '@/util/error';

type Props = {
  hideModal: () => Scalars['Void'];
};

const languageOptions = [
  { label: 'Select Language', value: '', status: 'empty' },
  { label: 'English', value: LanguageEnum.ENGLISH, status: 'selected' },
  { label: 'Spanish', value: LanguageEnum.SPANISH, status: 'selected' },
];

const genderOptions = [
  { label: 'Select Gender', value: '', status: 'empty' },
  { label: 'Male', value: GenderEnum.MALE, status: 'selected' },
  { label: 'Female', value: GenderEnum.FEMALE, status: 'selected' },
  { label: 'Non-Binary', value: GenderEnum.NON_BINARY, status: 'selected' },
  { label: 'Unspecified', value: GenderEnum.UNSPECIFIED, status: 'selected' },
];

const formErrorSchema = {
  firstName: {
    title: 'Enter Name',
    message: `You must enter worker's first name`,
  },
  lastName: {
    title: 'Enter Name',
    message: `You must enter worker's last name`,
  },
  dateOfBirth: {
    title: 'Enter Date of Birth',
    message: `You must enter worker's date of birth`,
  },
  email: {
    title: 'Enter Email Address',
    message: `You must enter worker's email address`,
  },

  duplicateWorker: {
    title: 'Duplicate GravyWorker',
    message: 'The GravyWorker you are trying to add already exists.',
  },
  defaultError: {
    title: 'Failed to add GravyWorker',
    message:
      'An error occurred while trying to add new GravyWorker. Please try again later.',
  },
};

const CreateWorkerModal = ({ hideModal }: Props) => {
  const [
    { formValues, showExperience, references, formError, skills, query },
    dispatch,
  ] = useReducer(workerModalReducer, initialState);
  const debouncedQuery = useDebouncedValue(query);
  const { currentAgency } = useAuth();

  const { data } = useListAgencySkillsQuery({
    variables: { agencyId: currentAgency?.id ?? '' },
    skip: !currentAgency?.id,
  });

  const [createWorker, { loading: isLoading }] = useCreateWorkerMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(currentAgency!),
        fields: { candidates() {} },
      });
    },
  });

  const [addReference] = useAddWorkerReferenceMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(currentAgency!),
        fields: { candidates() {} },
      });
    },
  });

  const dispatchFormError = (payload: { visible: boolean; key: string }) => {
    dispatch({ type: CreateWorkerActionTypes.SET_FORM_ERROR, payload });
  };

  const dispatchSkills = (payload: SkillItem[]) => {
    dispatch({ type: CreateWorkerActionTypes.SET_SKILLS_VALUES, payload });
  };

  const { items, sortedWorkerSkills } = useGetSortSkillItems(
    data,
    skills,
    debouncedQuery
  );

  const handleRemove = (index: number) => {
    const currentSkill = sortedWorkerSkills[index];
    const skillIndex = skills.findIndex((item) => item.id === currentSkill.id);
    const newSkillSet = removeAtIndex(skills, skillIndex, 1);
    dispatchSkills(newSkillSet);
  };

  const submitReferences = async (id: string) => {
    try {
      references.map(async (reference) => {
        await addReference({
          // @ts-ignore
          variables: {
            ...reference,
            workerId: id,
          },
        });
      });
    } catch (error) {
      dispatchFormError({ visible: true, key: 'defaultError' });
    }
  };

  const toggleExperience = () => {
    if (references.length === 0) {
      dispatch({
        type: CreateWorkerActionTypes.SET_EXPERIENCE_VALUES,
        payload: !showExperience,
      });
    }
  };

  const handleSubmit = async () => {
    const skillIds = skills.map(({ id }) => id);
    const hasError = formValidation();
    if (hasError) {
      return;
    }

    try {
      const { data } = await createWorker({
        // @ts-ignore
        variables: {
          ...formValues,
          dateOfBirth: formValues.dateOfBirth || null,
          agencyId: currentAgency!.id,
          skillIds,
        },
      });
      if (references.length > 0) {
        await submitReferences(data!.workerCreate.worker.id);
      }
      hideModal();
    } catch (error) {
      if (isApolloError(error) && hasGraphQLErrors(error)) {
        const { code } = error?.graphQLErrors[0]?.extensions || {};
        if (code === 'DUPLICATE_WORKER') {
          return dispatchFormError({ visible: true, key: 'duplicateWorker' });
        }
      }
      return dispatchFormError({ visible: true, key: 'defaultError' });
    }
  };

  const formValidation = () => {
    const { firstName, lastName, email } = formValues;
    const formElements = { firstName, lastName, email };
    let hasError = false;

    for (const [key, value] of Object.entries(formElements)) {
      if (!value) {
        dispatchFormError({ visible: true, key });
        hasError = true;
        return hasError;
      }
    }

    return hasError;
  };

  const handleFormValuesChange = (value: string | boolean, id: string) => {
    const payload = { ...formValues, [id]: value };
    dispatch({ type: CreateWorkerActionTypes.SET_FORM_VALUES, payload });
  };

  return (
    <Modal disableClickout title="New GravyWorker" onRequestClose={hideModal}>
      <Card.Section>
        {formError.visible && (
          <Alert
            description={formErrorSchema[formError.key].message}
            icon={faExclamationTriangle}
            status="warning"
            title={formErrorSchema[formError.key].title}
          />
        )}
        <Form initialValues={formValues} onSubmit={handleSubmit}>
          <FormColumns layout="triple">
            <TextField
              autoFocus
              required
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext.value, 'firstName');
              }}
              fieldId="firstName"
              label="First Name"
              placeholder="First Name"
            />
            <TextField
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext.value, 'middleName');
              }}
              fieldId="middleName"
              label="Middle Name"
              placeholder="Middle Name"
            />
            <TextField
              required
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext.value, 'lastName');
              }}
              fieldId="lastName"
              label="Last Name"
              placeholder="Last Name"
            />
          </FormColumns>
          <TextField
            required
            autoComplete="current-password"
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext.value, 'dateOfBirth');
            }}
            fieldId="dateOfBirth"
            label="Date of Birth"
            type="date"
          />
          <TextField
            required
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext.value, 'email');
            }}
            fieldId="email"
            label="Email Address"
            placeholder="Email"
          />
          <MaskedInputField
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext.value, 'phoneNumber');
            }}
            fieldId="phoneNumber"
            incompletemessage="Must be a valid phone number"
            label="Phone Number"
            mask="(000) 000-0000"
            placeholder="(555) 555-5555"
            type="tel"
          />
          <FormColumns layout="doubleLarge">
            <FormElement htmlFor="" label="Address">
              <AddressAutocomplete
                setValues={(prev) =>
                  dispatch({
                    type: CreateWorkerActionTypes.SET_FORM_VALUES,
                    payload: prev,
                  })
                }
                values={formValues}
              />
            </FormElement>
            <TextField
              required
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext.value, 'addressLine2');
              }}
              fieldId="addressLine2"
              label="Apartment Number"
              placeholder="Type apartment number..."
            />
          </FormColumns>

          <FormElement label="Skills">
            {sortedWorkerSkills.length > 0 && (
              <FormElement>
                <TagList
                  tags={sortedWorkerSkills.map((skill) => skill.name)}
                  onRemove={handleRemove}
                />
              </FormElement>
            )}
            <Autocomplete
              fixedSelectHeight="lg"
              id="tag"
              itemToKey={(item) => item.id}
              itemToString={(item) => (item ? item.name : '')}
              items={items}
              placeholder="Search for skills..."
              selectedItem={null}
              onInputValueChange={({ inputValue }) => {
                dispatch({
                  type: CreateWorkerActionTypes.SET_QUERY_VALUE,
                  payload: inputValue || '',
                });
              }}
              onSelectedItemChange={({ selectedItem }) => {
                if (selectedItem) {
                  dispatchSkills([...skills, selectedItem]);
                }
              }}
            />
          </FormElement>
          <TextSelectField
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext.value, 'gender');
            }}
            fieldId="gender"
            label="Gender"
            options={genderOptions}
          />
          <TextSelectField
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext.value, 'language');
            }}
            fieldId="language"
            label="Primary language"
            options={languageOptions}
          />
          <TextSelectField
            callback={(fieldContext) =>
              handleFormValuesChange(fieldContext.value, 'secondaryLanguage')
            }
            fieldId="secondaryLanguage"
            label="Secondary language"
            options={languageOptions}
          />
          <OptionField
            appearance="checkbox"
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext.value, 'ownsCar');
            }}
            fieldId="ownsCar"
            label="Do you have access to reliable transportation?"
          />
        </Form>
        {showExperience && (
          <ExperienceForm
            experiences={references}
            handleAddition={(item) =>
              dispatch({
                type: CreateWorkerActionTypes.SET_REFERENCE_VALUES,
                payload: item,
              })
            }
          />
        )}
      </Card.Section>
      <Card.Section>
        <Stack justify="apart">
          <Button
            a11yLabel="experience"
            appearance="plain"
            id="btn-experience"
            label={showExperience ? 'Hide Experience' : 'Add Experience'}
            type="button"
            onClick={toggleExperience}
          />
          <Button
            a11yLabel="Submit"
            id="btn-submit"
            isLoading={isLoading}
            label="Create"
            type="button"
            onClick={handleSubmit}
          />
        </Stack>
      </Card.Section>
    </Modal>
  );
};
export default CreateWorkerModal;

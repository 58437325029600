import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { useModal } from 'react-modal-hook';

import { Billing } from '../../context';

import InvoiceFiltersModal from './InvoiceFiltersModal';

import Button from '@/components/Button';
import IconicButton from '@/components/IconicButton';
import Stack from '@/components/Stack';
import Tag from '@/components/Tag';
import useMediaQuery from '@/hooks/useMediaQuery';
import {
  Maybe,
  TimesheetFilterSetInput,
  TimesheetInvoiceStatusEnum,
} from '@/types/graphql';
import { formatISO } from '@/util/date';

type Props = {
  billing: Maybe<Billing>;
  filters: TimesheetFilterSetInput;
  onChangeFilter: (arg: TimesheetFilterSetInput) => void;
};

type TagControl = {
  label: string;
  onRemove?: () => void;
};

enum invoiceStatusString {
  'ALL' = 'All',
  'DRAFT' = 'Draft',
  'NOT_INVOICED' = 'Not Invoiced',
}

const InvoicesFilters = ({ filters, onChangeFilter, billing }: Props) => {
  const [showFiltersModal, hideFiltersModal] = useModal(
    () => (
      <InvoiceFiltersModal
        filters={filters}
        hideModal={hideFiltersModal}
        onChangeFilter={onChangeFilter}
      />
    ),
    [filters]
  );
  const phoneOnly = useMediaQuery('(max-width: 559px)');

  const tagControls = useMemo<TagControl[]>(() => {
    const controls: TagControl[] = [];
    controls.push({
      label: `Client: ${billing?.customer.name}`,
    });
    controls.push({
      label: `Account: ${billing?.account.name}`,
    });
    if (filters.invoiceStatus) {
      controls.push({
        label: `Status: ${invoiceStatusString[filters.invoiceStatus]}`,
        onRemove:
          filters.invoiceStatus !== TimesheetInvoiceStatusEnum.ALL
            ? () => {
                onChangeFilter({
                  ...filters,
                  invoiceStatus: TimesheetInvoiceStatusEnum.ALL,
                });
              }
            : undefined,
      });
    }

    if (filters.approvedCheckinAt?.from && filters.approvedCheckinAt?.to) {
      const formatStartAt = formatISO(
        filters.approvedCheckinAt?.from,
        'MMM dd'
      );
      const formatEndAt = formatISO(filters.approvedCheckinAt?.to, 'MMM dd');

      controls.push({
        label: `Date: ${formatStartAt} — ${formatEndAt}`,
        onRemove: () => {
          onChangeFilter({
            ...filters,
            approvedCheckinAt: undefined,
          });
        },
      });
    }

    return controls;
  }, [filters]);

  return (
    <Stack vertical gap={16}>
      <Stack gap={phoneOnly ? 8 : 16}>
        <Stack wrap="true">
          {tagControls.map(
            (tagControl, index) =>
              tagControl && (
                <Tag
                  key={index}
                  id={index}
                  label={tagControl.label}
                  onRemove={tagControl.onRemove}
                />
              )
          )}
        </Stack>
        {!phoneOnly ? (
          <Button
            a11yLabel="View filters"
            appearance="outline"
            id="btn-show-filters"
            label="Filters"
            onClick={showFiltersModal}
          />
        ) : (
          <IconicButton
            a11yLabel="View filters"
            appearance={phoneOnly ? 'outline' : 'clear'}
            icon={faFilter}
            id="btn-show-filters"
            size={phoneOnly ? 'md' : 'sm'}
            onClick={() => showFiltersModal()}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default InvoicesFilters;

import { createSvgIcon } from '@mui/material';

const ApprovedByIcon = createSvgIcon(
  <svg
    fill="none"
    height="15"
    viewBox="0 0 17 15"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.939 2.84622C14.8063 2.74426 14.6328 2.69251 14.4566 2.70236C14.2803 2.7122 14.1158 2.78282 13.999 2.89872L12.7523 4.15289L12.3323 3.73872C12.2147 3.62269 12.0493 3.55228 11.8724 3.54298C11.6955 3.53368 11.5216 3.58626 11.389 3.68914C11.2564 3.79202 11.1759 3.93678 11.1653 4.09158C11.1546 4.24638 11.2147 4.39853 11.3323 4.51456L12.259 5.42456C12.322 5.48572 12.3992 5.5345 12.4854 5.56767C12.5716 5.60085 12.6648 5.61768 12.759 5.61706C12.8525 5.61613 12.9448 5.59799 13.0298 5.56382C13.1148 5.52965 13.1906 5.48022 13.2523 5.41872L14.9923 3.66872C15.1097 3.55337 15.1699 3.40201 15.1599 3.24783C15.1499 3.09364 15.0705 2.94922 14.939 2.84622Z"
      fill="#1C1C1C"
    />
    <path
      d="M7.16667 6.7841C7.69408 6.7841 8.20966 6.64725 8.64819 6.39086C9.08672 6.13447 9.42851 5.77006 9.63035 5.34369C9.83218 4.91733 9.88499 4.44818 9.78209 3.99556C9.6792 3.54293 9.42523 3.12717 9.05229 2.80085C8.67935 2.47453 8.20419 2.2523 7.68691 2.16227C7.16962 2.07223 6.63345 2.11844 6.14618 2.29505C5.65891 2.47165 5.24243 2.77072 4.94941 3.15444C4.6564 3.53815 4.5 3.98928 4.5 4.45077C4.5 5.0696 4.78095 5.6631 5.28105 6.10068C5.78115 6.53827 6.45942 6.7841 7.16667 6.7841ZM7.16667 3.2841C7.43038 3.2841 7.68816 3.35252 7.90743 3.48072C8.12669 3.60891 8.29759 3.79112 8.39851 4.0043C8.49942 4.21748 8.52583 4.45206 8.47438 4.67837C8.42293 4.90468 8.29595 5.11256 8.10948 5.27572C7.92301 5.43889 7.68543 5.55 7.42679 5.59502C7.16815 5.64003 6.90006 5.61693 6.65642 5.52863C6.41279 5.44032 6.20455 5.29079 6.05804 5.09893C5.91153 4.90707 5.83333 4.68151 5.83333 4.45077C5.83333 4.14135 5.97381 3.8446 6.22386 3.62581C6.47391 3.40702 6.81304 3.2841 7.16667 3.2841Z"
      fill="#1C1C1C"
    />
    <path
      d="M7.16667 7.95093C5.92899 7.95093 4.742 8.38114 3.86683 9.14691C2.99167 9.91268 2.5 10.9513 2.5 12.0343C2.5 12.189 2.57024 12.3373 2.69526 12.4467C2.82029 12.5561 2.98986 12.6176 3.16667 12.6176C3.34348 12.6176 3.51305 12.5561 3.63807 12.4467C3.7631 12.3373 3.83333 12.189 3.83333 12.0343C3.83333 11.2607 4.18452 10.5188 4.80964 9.97187C5.43477 9.42489 6.28261 9.11759 7.16667 9.11759C8.05072 9.11759 8.89857 9.42489 9.52369 9.97187C10.1488 10.5188 10.5 11.2607 10.5 12.0343C10.5 12.189 10.5702 12.3373 10.6953 12.4467C10.8203 12.5561 10.9899 12.6176 11.1667 12.6176C11.3435 12.6176 11.513 12.5561 11.6381 12.4467C11.7631 12.3373 11.8333 12.189 11.8333 12.0343C11.8333 10.9513 11.3417 9.91268 10.4665 9.14691C9.59133 8.38114 8.40434 7.95093 7.16667 7.95093Z"
      fill="#1C1C1C"
    />
  </svg>,
  'ApprovedByIcon'
);
export default ApprovedByIcon;

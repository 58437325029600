import useModal from '@area2k/use-modal';
import * as React from 'react';

import { JobWorkerItem } from '../WorkerList';
import CancellationDissmissModal from '../modals/CancellationAndDismissModal';

import { GAEvent } from '@/constants/gaevents';
import { ContextCancelRemoveModal } from '@/constants/job';
import {
  GetJobDocument,
  useApproveTimesheetMutation,
  useMarkShowedMutation,
  useRejectTimesheetMutation,
  useUnapproveTimesheetMutation,
  useUnrejectTimesheetMutation,
} from '@/graphql';
import useAnalytics from '@/util/analytics';
import { handleGraphQLError } from '@/util/error';

const useWorkerActions = (job) => {
  const [error, setError] = React.useState('');

  const { logEvent } = useAnalytics();
  const [showRemoveModal, hideRemoveModal] = useModal(
    (jobWorkerId: { id: JobWorkerItem['id'] }) => (
      <CancellationDissmissModal
        context={ContextCancelRemoveModal.RemoveWorker}
        hideModal={hideRemoveModal}
        job={job}
        jobWorkerId={jobWorkerId.id}
        updatedUi={true}
      />
    ),
    [job]
  );
  const [handleShowed] = useMarkShowedMutation({
    refetchQueries: [{ query: GetJobDocument, variables: { jobId: job.id } }],
    onError: (error) => setError(error.message),
  });
  const [approveTimesheet] = useApproveTimesheetMutation({
    refetchQueries: [{ query: GetJobDocument, variables: { jobId: job.id } }],
    onCompleted: (data) => {
      logEvent(GAEvent.TimesheetApprove, data.timesheetApprove.timesheet.id);
    },
    onError: (error) => setError(error.message),
  });
  const [unapproveTimesheet] = useUnapproveTimesheetMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: [{ query: GetJobDocument, variables: { jobId: job.id } }],
    onError: (error) => setError(error.message),
    onCompleted: (data) => {
      logEvent(
        GAEvent.TimesheetUnapprove,
        data.timesheetUnapprove.timesheet.id
      );
    },
  });
  const [rejectTimesheet] = useRejectTimesheetMutation({
    refetchQueries: [{ query: GetJobDocument, variables: { jobId: job.id } }],
    onError: (error) =>
      handleGraphQLError(error, {
        TIMESHEET_APPROVED: () => setError('The timesheet is already approved'),
        all: () => setError(error?.message),
      }),
    onCompleted: (data) => {
      logEvent(GAEvent.TimesheetReject, data.timesheetReject.timesheet.id);
    },
  });

  const [unRejectTimesheet] = useUnrejectTimesheetMutation({
    refetchQueries: [{ query: GetJobDocument, variables: { jobId: job.id } }],
    onError: (error) => setError(error?.message),
    onCompleted: (data) => {
      logEvent(GAEvent.TimesheetUnReject, data.timesheetUnreject.timesheet.id);
    },
  });

  return {
    handleShowed,
    showRemoveModal,
    approveTimesheet,
    unapproveTimesheet,
    rejectTimesheet,
    unRejectTimesheet,
    error,
    setError,
  };
};

export default useWorkerActions;

import { CSSProperties } from 'react';

import Stack from '@/components/Stack';
import Tag from '@/components/Tag';

export type Props = {
  disabled?: boolean;
  gap?: number;
  tags: string[];
  onRemove?: (index: number) => void;
  style?: CSSProperties | undefined;
  showIcon?: boolean;
};

const TagList = ({ disabled, gap, tags, onRemove, style, showIcon }: Props) => {
  return (
    <Stack wrap gap={gap}>
      {tags.map((tagLabel, index) => (
        <Tag
          key={index}
          disabled={disabled}
          label={tagLabel}
          showIcon={showIcon}
          style={style}
          onRemove={onRemove ? async () => await onRemove(index) : undefined}
        />
      ))}
    </Stack>
  );
};

export default TagList;

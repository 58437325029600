import { useReactiveVar } from '@apollo/client';
import {
  faSortNumericDown,
  faSortNumericUpAlt,
} from '@fortawesome/free-solid-svg-icons';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import OrderFilters from '../../Orders/OrderFilters';

import AccountStateError from '@/components/AccountStateError';
import Card from '@/components/Card';
import LoadingState from '@/components/LoadingState';
import OrderTableItem from '@/components/OrderTableItem';
import Pagination from '@/components/Pagination';
import QueryEmptyState from '@/components/QueryEmptyState';
import Table from '@/components/Table';
import { TIME_TO_REFRESH } from '@/constants/general';
import { OrderFilterKeysEnum } from '@/constants/job';
import Icon from '@/elements/Icon';
import { TableCell, TableHead, TableHeading, TableRow } from '@/elements/Table';
import { useListOrdersLazyQuery } from '@/graphql';
import useAuth from '@/hooks/useAuth';
import useFilters from '@/hooks/useFilters';
import useMediaQuery from '@/hooks/useMediaQuery';
import {
  CompareOperatorEnum,
  GetCustomerQuery,
  OrderFilterSetInput,
  OrderListItemFragment,
  OrderSortKeyEnum,
  Scalars,
  CurrentCustomerAdminFragment,
} from '@/types/graphql';
import {
  getAccountErrorTitle,
  getAccountErrorDesc,
  hasValidCustomerAccountStatus,
} from '@/util/accountstatus';
import { currentAdminVar } from '@/util/apollo/cache';
import ls, { ACCOUNT_DISABLED } from '@/util/localstorage';

type Props = {
  customer: GetCustomerQuery['customer'];
  accordion?: boolean;
};

type SortByFilter = {
  sort: OrderSortKeyEnum;
  reverse: Scalars['Boolean'];
};

const initialFilters: OrderFilterSetInput = {};

const initialSortBy: SortByFilter = {
  sort: OrderSortKeyEnum.START_AT,
  reverse: false,
};

const MAX_ITEMS_PER_PAGE = 7;

const OrdersList = ({ customer, accordion = false }: Props) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState<SortByFilter>(initialSortBy);

  const { currentAgency } = useAuth();

  const isFeatFlagDisabledCustomerLoginAllowedEnabled = useFeatureValue(
    'disabled-customer-login',
    false
  );
  const currentAdminUser = useReactiveVar(
    currentAdminVar
  ) as CurrentCustomerAdminFragment;
  const accountDisabled = ls.get(ACCOUNT_DISABLED) === 'true';

  function hasValidAccountStatus() {
    return hasValidCustomerAccountStatus(
      isFeatFlagDisabledCustomerLoginAllowedEnabled,
      currentAdminUser,
      accountDisabled
    );
  }

  const [handleListOrders, query] = useListOrdersLazyQuery({
    fetchPolicy: 'network-only',
    pollInterval: TIME_TO_REFRESH,
  });

  const {
    debouncedQuery,
    filters,
    query: searchQuery,
    clearAll,
    clearFilter,
    setFilter,
    setQuery,
  } = useFilters<OrderFilterSetInput>(initialFilters);

  const finalFilters = useMemo<OrderFilterSetInput>(() => {
    const value = { ...filters };

    if (debouncedQuery !== '') {
      value.query = { value: debouncedQuery };
    }

    return value;
  }, [debouncedQuery, filters]);

  const sorting = (value: SortByFilter['sort']) => {
    setSortBy((prevValue) => ({
      sort: value,
      reverse: prevValue.sort === value ? !prevValue.reverse : true,
    }));
  };

  const clearAllFilterMantainCustomer = () => {
    clearAll();
    setFilter('customer', { value: customer.id });
  };

  const headerFields = useMemo(
    () => [
      'ID',
      'Billing Account',
      'Timeframe',
      'Approval Status',
      'Filled Jobs',
      'Skills',
    ],
    []
  );

  const renderRow = useCallback(
    (item: OrderListItemFragment) => (
      <OrderTableItem
        key={item.id}
        item={item}
        to={`../../orders/${item.id}`}
      />
    ),
    []
  );

  const orders =
    !query.loading && query.data ? query.data.agency.orders.items : [];
  const totalItems =
    !query.loading && query.data
      ? query.data.agency.orders.pageInfo.totalItems
      : 0;

  const totalPage = useMemo(() => {
    if (totalItems <= MAX_ITEMS_PER_PAGE) {
      return 1;
    }
    return Math.ceil(totalItems / MAX_ITEMS_PER_PAGE);
  }, [totalItems]);

  useEffect(() => {
    setFilter(OrderFilterKeysEnum.START_AT, {
      op: CompareOperatorEnum.LT,
      value: new Date().toISOString(),
    });

    setFilter('endAt', {
      op: CompareOperatorEnum.GT,
      value: new Date().toISOString(),
    });

    setFilter('customer', { value: customer.id });
  }, [customer.id]);

  useEffect(() => {
    handleListOrders({
      variables: {
        agencyId: currentAgency!.id,
        filters: finalFilters,
        page,
        perPage: MAX_ITEMS_PER_PAGE,
        sortKey: sortBy.sort,
        reverse: sortBy.reverse,
      },
    });
  }, [finalFilters, sortBy, page]);

  useEffect(() => {
    setPage(1);
  }, [finalFilters]);

  return (
    <Card accordion={accordion} title="Orders">
      <Card.Section>
        <OrderFilters
          filters={filters}
          hasValidAccountStatus={hasValidAccountStatus()}
          query={searchQuery}
          onChangeFilter={setFilter}
          onClearAll={clearAllFilterMantainCustomer}
          onClearFilter={clearFilter}
          onQueryChange={setQuery}
        />
      </Card.Section>
      {!hasValidAccountStatus() && (
        <AccountStateError
          message={getAccountErrorDesc(accountDisabled, currentAdminUser)}
          title={getAccountErrorTitle(accountDisabled, currentAdminUser)}
        />
      )}
      {hasValidAccountStatus() && (
        <Table>
          {!phoneOnly && (
            <TableHead>
              <TableRow>
                <TableHeading
                  touchable="true"
                  onClick={() => sorting(OrderSortKeyEnum.ID)}
                >
                  ID
                  <Icon
                    fixedWidth
                    icon={
                      sortBy.reverse && sortBy.sort === OrderSortKeyEnum.ID
                        ? faSortNumericUpAlt
                        : faSortNumericDown
                    }
                  />
                </TableHeading>
                <TableHeading>Billing Account</TableHeading>
                <TableHeading
                  touchable="true"
                  onClick={() => sorting(OrderSortKeyEnum.START_AT)}
                >
                  Timeframe
                  <Icon
                    fixedWidth
                    icon={
                      sortBy.reverse &&
                      sortBy.sort === OrderSortKeyEnum.START_AT
                        ? faSortNumericUpAlt
                        : faSortNumericDown
                    }
                  />
                </TableHeading>
                <TableHeading> Approval Status</TableHeading>
                <TableHeading>Filled Jobs</TableHeading>
                <TableHeading align="center">Skills</TableHeading>
              </TableRow>
            </TableHead>
          )}
          <tbody>{orders.map(renderRow)}</tbody>
          {query.loading && (
            <TableRow>
              <TableCell align="center" colSpan={headerFields.length}>
                <LoadingState overlayColor="white" />
              </TableCell>
            </TableRow>
          )}
        </Table>
      )}
      {hasValidAccountStatus() && orders.length === 0 && (
        <QueryEmptyState query={query} title="No orders created" />
      )}
      {hasValidAccountStatus() && orders.length > 0 && (
        <Card.Section subdued>
          <Pagination
            currentPage={page}
            totalPages={totalPage}
            onPageChange={setPage}
          />
        </Card.Section>
      )}
    </Card>
  );
};

export default OrdersList;

import useModal from '@area2k/use-modal';
import useMediaQuery from '@mui/material/useMediaQuery';

import UpdateShiftDialog from './UpdateShiftDateMobile';

import styled from '@/styles';
import { GetJobQuery } from '@/types/graphql';

type Props = {
  job: GetJobQuery['job'];
  limitOfHoursOnSelectedDays: any;
  handleEditWeb: () => void;
};

const EditScedule = styled('span', {
  height: 'auto',
  color: '#262626',
  marginLeft: '0px',
});

const ShiftsCard = ({
  job,
  limitOfHoursOnSelectedDays,
  handleEditWeb,
}: Props) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');

  const [showEditDialog, hideEditDialog] = useModal(
    () => (
      <UpdateShiftDialog
        hideModal={hideEditDialog}
        job={job}
        limitOfHours={limitOfHoursOnSelectedDays}
      />
    ),
    [job]
  );

  return (
    <EditScedule onClick={phoneOnly ? showEditDialog : handleEditWeb}>
      Edit Job Details
    </EditScedule>
  );
};

export default ShiftsCard;

import { SubmitHelpers } from '@area2k/use-form';
import { useCallback, useState } from 'react';

import Button from '@/components/Button';
import Card from '@/components/Card';
import EditAddress, { TypeEditorEnum } from '@/components/EditAddress';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import Form from '@/form';
import { useChangeWorkerAddressMutation } from '@/graphql';
import { GetWorkerQuery, Scalars, WorkerCoordsFragment } from '@/types/graphql';
import { handleMutationFormError } from '@/util/error';

export type Props = {
  worker: GetWorkerQuery['worker'];
  hideModal: () => Scalars['Void'];
};

type FormValues = {
  addressLine1: GetWorkerQuery['worker']['addressLine1'];
  addressLine2: GetWorkerQuery['worker']['addressLine2'];
  city: GetWorkerQuery['worker']['city'];
  state: GetWorkerQuery['worker']['state'];
  zip: GetWorkerQuery['worker']['zip'];
  coords: {
    latitude: WorkerCoordsFragment['latitude'];
    longitude: WorkerCoordsFragment['longitude'];
  };
};

const ChangeAddressModal = ({ worker, hideModal }: Props) => {
  const [initialValues, setInitialValues] = useState<FormValues>({
    addressLine1: worker.addressLine1,
    addressLine2: worker.addressLine2,
    city: worker.city,
    state: worker.state,
    zip: worker.zip,
    coords: {
      latitude: worker.coords ? worker.coords.latitude : 0,
      longitude: worker.coords ? worker.coords.longitude : 0,
    },
  });

  const [changeWorkerAddress, { loading: isLoading }] =
    useChangeWorkerAddressMutation();

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        await changeWorkerAddress({
          variables: { workerId: worker.id, ...initialValues },
        });
        hideModal();
      } catch (err: unknown) {
        handleMutationFormError(err, {
          setFormError,
          errorMap: {
            all: (gqlError) => ({
              title: gqlError.name,
              message: gqlError.message,
              status: 'danger',
            }),
          },
        });
      }
    },
    [worker.id, initialValues]
  );

  return (
    <Modal
      disableClickout
      size="sm"
      title="Change GravyWorker address"
      onRequestClose={hideModal}
    >
      <Form initialValues={initialValues} onSubmit={handleSubmit}>
        <Card.Section>
          <EditAddress
            setValues={setInitialValues}
            typeEditor={TypeEditorEnum.FOR_WORKER}
            values={initialValues}
          />
        </Card.Section>
        <Card.Section>
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              id="btn-save"
              isLoading={isLoading}
              label="Save"
              type="submit"
            />
          </Stack>
        </Card.Section>
      </Form>
    </Modal>
  );
};

export default ChangeAddressModal;

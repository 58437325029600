import { createSvgIcon } from '@mui/material';

const CrossHairIcon = createSvgIcon(
  <svg
    fill="none"
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
      stroke="#DC1515"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 9H13.5"
      stroke="#DC1515"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 9H1.5"
      stroke="#DC1515"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 4.5V1.5"
      stroke="#DC1515"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 16.5V13.5"
      stroke="#DC1515"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'CrossHairIcon'
);
export default CrossHairIcon;

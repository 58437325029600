import { Fragment } from 'react';

import Item from './Item';

import Dropdown, { Props as DropdownProps } from '@/components/Dropdown';
import styled from '@/styles';
import { GenericAction } from '@/types';
import { noOp } from '@/util/actions';

const Wrapper = styled('ul', {
  display: 'block',
  padding: '4px 0',

  listStyle: 'none',
});

Wrapper.displayName = 'stitches(DropdownMenu.Wrapper)';

const Separator = styled('li', {
  display: 'block',
  height: '1px',
  margin: '6px 0',

  borderBottom: '1px solid $colors$neutralLightest',
});

Separator.displayName = 'stitches(DropdownMenu.Separator)';

export type Props = Omit<DropdownProps, 'Content'> & {
  actions: GenericAction[][];
  disableCloseOnClick?: boolean;
};

const DropdownMenu = ({
  actions,
  disableCloseOnClick = false,
  ...props
}: Props) => {
  return (
    <Dropdown
      {...props}
      Content={({ closeDropdown }) => (
        <Wrapper role="menu">
          {actions.map((group, groupIndex) => (
            <Fragment key={`group-${groupIndex}`}>
              {group.map((action, itemIndex) => (
                <Item
                  key={`item-${groupIndex}-${itemIndex}`}
                  action={action}
                  id={`item-${action.id || ''}`}
                  onAction={disableCloseOnClick ? noOp : closeDropdown}
                />
              ))}
              {groupIndex < actions.length - 1 && (
                <Separator aria-hidden role="separator" />
              )}
            </Fragment>
          ))}
        </Wrapper>
      )}
    />
  );
};

export default DropdownMenu;

import { CloseOutlined } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { useState } from 'react';

import CheckboxList from './CheckboxList';

import Button from '@/components/Button';

interface Data {
  id: string;
  name: string;
}

interface Props {
  data: Data[];
  selectedData: Data[];
  setSelectedOptions: (data: Data[]) => void;
  title: string;
  hideModal: () => void;
}

const SelectSkillsModal = ({
  title,
  hideModal,
  data,
  selectedData,
  setSelectedOptions,
}: Props) => {
  const [unSavedSkills, setUnSavedSkills] = useState(selectedData);
  const allSelected = data.length === unSavedSkills.length;

  const handleSave = () => {
    setSelectedOptions(unSavedSkills);
    hideModal();
  };
  return (
    <Dialog
      PaperProps={{
        style: {
          height: '95vh',
          borderRadius: '15px',
        },
      }}
      aria-labelledby="options-dialog"
      fullScreen={true}
      open={true}
      sx={{
        '& .MuiDialog-container': { alignItems: 'end', marginBottom: '2px' },
      }}
      onClose={hideModal}
    >
      <DialogTitle id="options-dialog-title" sx={{ m: 0, p: 1.5, pb: 0 }}>
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
        onClick={hideModal}
      >
        <CloseOutlined />
      </IconButton>
      <DialogContent style={{ padding: '0px' }}>
        <CheckboxList
          allSelected={allSelected}
          data={data}
          selectedData={unSavedSkills}
          setSelectedOptions={setUnSavedSkills}
        />
      </DialogContent>
      <DialogActions>
        <Button a11yLabel="Save" type="button" onClick={handleSave} />
      </DialogActions>
    </Dialog>
  );
};

export default SelectSkillsModal;

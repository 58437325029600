import { useState, useEffect, SetStateAction, Dispatch } from 'react';

import ApprovalStatusFilter from './ApprovalStatusFilter';
import OrderStatusFilter from './OrderStatusFilter';

import { OrderStatus } from './index';

import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { AllFiltersProps } from '@/filters';
import NumericCompareFilter from '@/filters/NumericCompareFilter';
import { OrderFilterSetInput } from '@/types/graphql';

type Props = AllFiltersProps<OrderFilterSetInput> & {
  hideModal: () => void;
  orderStatusValue: OrderStatus;
  setOrderStatusValue: Dispatch<SetStateAction<OrderStatus>>;
};

const OrderFiltersModal = ({
  filters,
  hideModal,
  onChangeFilter,
  onClearAll,
  orderStatusValue,
  setOrderStatusValue,
}: Props) => {
  const [able, setAble] = useState(false);
  const [filterParams, setFilterParams] = useState(filters);

  const [refSelectedOrderValue, setRefSelectedOrderValue] =
    useState<OrderStatus>(orderStatusValue);

  const handleOnChangeFilter = <K extends keyof OrderFilterSetInput>(
    key: K,
    value: NonNullable<OrderFilterSetInput[K]>
  ) => {
    setFilterParams((prevValue) => ({ ...prevValue, [key]: value }));
  };

  const handleOnClearFilter = (key: keyof OrderFilterSetInput) => {
    const updatedFilterParams = { ...filterParams };
    delete updatedFilterParams[key];
    setFilterParams(updatedFilterParams);
  };

  useEffect(() => {
    if (able && Object.keys(filterParams).length !== 0) {
      onClearAll();
      Object.keys(filterParams).forEach((key: any) => {
        onChangeFilter(key, filterParams[key]);
      });
    }
  }, [able]);

  return (
    <Modal
      disableClickout
      size="sm"
      title="Change Filters"
      onRequestClose={hideModal}
    >
      <Card.Section title="Order status">
        <OrderStatusFilter
          filters={filters}
          idPrefix="modal"
          orderStatusValue={refSelectedOrderValue}
          setOrderStatusValue={setRefSelectedOrderValue}
          onChangeFilter={handleOnChangeFilter}
          onClearFilter={handleOnClearFilter}
        />
      </Card.Section>
      <Card.Section title="Approval Status">
        <ApprovalStatusFilter
          filterKey="status"
          filters={filters}
          onChangeFilter={handleOnChangeFilter}
          onClearFilter={handleOnClearFilter}
        />
      </Card.Section>
      <Card.Section title="Number of Jobs">
        <NumericCompareFilter
          filterKey="jobsCount"
          filters={filters}
          onChangeFilter={handleOnChangeFilter}
          onClearFilter={handleOnClearFilter}
        />
      </Card.Section>
      <Card.Section>
        <Stack justify="end">
          <Button
            a11yLabel="Clear all order filters"
            appearance="outline"
            id="btn-clear-filter"
            label="Clear all"
            onClick={() => {
              onClearAll();
              hideModal();
              setOrderStatusValue(OrderStatus.ALL);
            }}
          />
          <Button
            a11yLabel="Close modal"
            id="btn-close-filter"
            label="Done"
            onClick={() => {
              setAble(true);
              hideModal();
              setOrderStatusValue(refSelectedOrderValue);
            }}
          />
        </Stack>
      </Card.Section>
    </Modal>
  );
};

export default OrderFiltersModal;

import { CloseOutlined } from '@mui/icons-material';
import { Dialog, DialogContent, IconButton, MenuList } from '@mui/material';
import * as React from 'react';

import styled from '@/styles';

interface Props {
  title: string;
  hideModal: () => void;
  menuItems: React.ReactNode[];
}

const Menu = styled(MenuList, {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  li: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    color: '#1C1C1C',
    fontSize: '$lg',
    padding: '0',
    minHeight: '24px',
    svg: {
      height: '24px',
      width: '24px',
      margin: '0',
    },
  },
});

const HeaderDialog = styled('span', {
  padding: '10px 20px 15px 25px',
  background: 'linear-gradient(180deg, #EEFFEC 38.16%, #FFF 107.94%)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const TitleDialog = styled('span', {
  color: '#45A735',
  fontSize: '16px',
  fontWeight: '600',
});

const OptionsModal = ({ title, hideModal, menuItems }: Props) => {
  return (
    <Dialog
      PaperProps={{
        style: {
          height: 'fit-content',
          borderRadius: '15px',
        },
      }}
      aria-labelledby="options-dialog"
      fullScreen={true}
      open={true}
      sx={{
        '& .MuiDialog-container': { alignItems: 'end', marginBottom: '2px' },
      }}
      onClose={hideModal}
    >
      <HeaderDialog>
        <TitleDialog> {title}</TitleDialog>
        <IconButton aria-label="close" onClick={hideModal}>
          <CloseOutlined />
        </IconButton>
      </HeaderDialog>
      <DialogContent
        dividers
        style={{ padding: '25px 25px 55px', border: 'none' }}
      >
        <div
          onClick={() =>
            setTimeout(() => {
              hideModal();
            }, 250)
          }
        >
          <Menu
            sx={{ paddingTop: '0px', paddingBottom: '0px', overflowY: 'auto' }}
          >
            {menuItems}
          </Menu>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default OptionsModal;

import Card from '@/components/Card';
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout';
import Skeleton from '@/components/Skeleton';
import Stack from '@/components/Stack';
import useMediaQuery from '@/hooks/useMediaQuery';

const PageSkeleton = () => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');

  return (
    <Skeleton.Page>
      <PrimarySecondaryLayout>
        <PrimarySecondaryLayout.Primary>
          <Card sectioned noRadius={phoneOnly} title="Jobs">
            <Skeleton.Body />
          </Card>
        </PrimarySecondaryLayout.Primary>
        <PrimarySecondaryLayout.Secondary>
          <Stack vertical gap={24}>
            <Card sectioned noRadius={phoneOnly} title="Client">
              <Skeleton.Body />
            </Card>
            <Card sectioned noRadius={phoneOnly} title="Summary">
              <Skeleton.Body />
            </Card>
          </Stack>
        </PrimarySecondaryLayout.Secondary>
      </PrimarySecondaryLayout>
    </Skeleton.Page>
  );
};

export default PageSkeleton;

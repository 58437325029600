import { CloseOutlined } from '@mui/icons-material';
import { Dialog, DialogContent, IconButton } from '@mui/material';

import { TimesheetItem } from '../..';
import MapView from '../EditTimesheetModal/mapView';

import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { ProfileHeading } from '@/components/Typography';
import useMediaQuery from '@/hooks/useMediaQuery';
import styled from '@/styles';
import { Address } from '@/types/graphql';

interface Props {
  hideModal: () => void;
  timesheet: TimesheetItem;
  jobAddress?: Address;
}
const HeaderDialog = styled('span', {
  padding: '10px 20px 15px 25px',
  background: 'linear-gradient(180deg, #EEFFEC 38.16%, #FFF 107.94%)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const TitleDialog = styled('span', {
  color: '#45A735',
  fontSize: '16px',
  fontWeight: '600',
});

const MapViewModal = ({ hideModal, timesheet, jobAddress }: Props) => {
  const phoneOnly = useMediaQuery('(max-width: 790px)');

  return phoneOnly ? (
    <Dialog
      PaperProps={{
        style: {
          height: 'fit-content',
          borderRadius: '15px',
        },
      }}
      aria-labelledby="options-dialog"
      fullScreen={true}
      open={true}
      sx={{
        '& .MuiDialog-container': { alignItems: 'end', marginBottom: '2px' },
      }}
      onClose={hideModal}
    >
      <HeaderDialog>
        <Stack>
          <TitleDialog>
            {`${timesheet?.jobWorker.worker.user.firstName}'s Map`}
          </TitleDialog>
        </Stack>
        <IconButton aria-label="close" onClick={hideModal}>
          <CloseOutlined />
        </IconButton>
      </HeaderDialog>
      <DialogContent>
        <div
          onClick={() =>
            setTimeout(() => {
              hideModal();
            }, 250)
          }
        >
          <ProfileHeading>View Check In/Check Out Location </ProfileHeading>
          <MapView
            jobAddress={jobAddress}
            mapSize={'235'}
            timesheet={timesheet}
          />
        </div>
      </DialogContent>
    </Dialog>
  ) : (
    <Modal
      disableClickout
      size="xs"
      title={`${timesheet?.jobWorker.worker.user.firstName}'s Map`}
      wrapperBackground={true}
      onRequestClose={hideModal}
      displayFlex={true}
    >
      <Stack vertical gap={15} style={{ padding: 20 }}>
        <ProfileHeading>View Check In/Check Out Location </ProfileHeading>
        <MapView
          jobAddress={jobAddress}
          mapSize={'250'}
          timesheet={timesheet}
        />
      </Stack>
    </Modal>
  );
};

export default MapViewModal;

import { useState } from 'react';

import Action from '../Action';
import AddressAutocomplete from '../AddressAutocomplete';
import Stack from '../Stack';
import TextStack from '../TextStack';
import { Small } from '../Typography';

import { Wrapper } from './styles';

import Text from '@/elements/Text';
import useMediaQuery from '@/hooks/useMediaQuery';
import { mapUrl } from '@/routes/Agency/Customer/AddressList';
import { AddressItemFragment, Scalars } from '@/types/graphql';

export enum TypeEditorEnum {
  'FOR_CLIENT',
  'FOR_WORKER',
}

type Props = {
  direction?:
    | 'column'
    | 'row'
    | {
        [x: string]: 'column' | 'row' | undefined;
        initial?: 'column' | 'row';
      };
  typeEditor?: TypeEditorEnum;
  values: any;
  setValues: (args: any) => Scalars['Void'];
};

const EditAddress = ({
  direction,
  typeEditor = TypeEditorEnum.FOR_CLIENT,
  values,
  setValues,
}: Props) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const [initialValues] = useState<AddressItemFragment>({
    ...values,
  });

  return (
    <Wrapper direction={direction}>
      <div style={{ marginBottom: '10px' }}>
        <Small color="light" style={{ fontStyle: 'italic' }}>
          Current:
        </Small>
        <TextStack spacing="extra-loose">
          {initialValues.addressLine1?.length &&
          initialValues.addressLine1.length > 0 ? (
            <Action.Button
              action={{
                a11yLabel: `Open new tab to ${mapUrl(initialValues)}`,
                label: initialValues.addressLine1!,
                href: mapUrl(initialValues),
                external: true,
              }}
            />
          ) : (
            <span> No previous address</span>
          )}
          {initialValues.addressLine2 && (
            <Text>{initialValues.addressLine2}</Text>
          )}
          {initialValues.city &&
            initialValues.state &&
            initialValues.zip !== '' && (
              <Text>
                {initialValues.city}, {initialValues.state} {initialValues.zip}
              </Text>
            )}
        </TextStack>
      </div>
      <Stack
        vertical
        style={{ width: phoneOnly ? '100%' : '70%', alignItems: 'stretch' }}
      >
        <Small color="light" style={{ fontsize: 'italic' }}>
          New:
        </Small>
        <AddressAutocomplete
          showSuiteNumber
          setValues={setValues}
          type={typeEditor}
          values={values}
          onReset={() => {
            setValues({ ...initialValues });
          }}
        />
      </Stack>
    </Wrapper>
  );
};

export default EditAddress;

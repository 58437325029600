import { differenceInMinutes, parseISO } from 'date-fns';

import { TimesheetItem } from '..';

import FormElement from '@/components/FormElement';
import Stack from '@/components/Stack';
import { Body, Small, Subheading } from '@/components/Typography';
import { getTotalLengthOfShiftTimesheet } from '@/routes/Agency/Job/util';
import { Point, TimesheetStatusEnum } from '@/types/graphql';
import { formatISO } from '@/util/date';

enum TimesheetInformationVariants {
  DETAIL = 'DETAIL',
  DEFAULT = 'DEFAULT',
}

type Props = {
  variant?: 'DETAIL' | 'DEFAULT';
  jobCoords: Point;
  timesheet?: TimesheetItem;
};

const TimesheetInformation = ({
  variant = TimesheetInformationVariants.DEFAULT,
  timesheet,
}: Props) => {
  const reportedNetHours =
    timesheet &&
    timesheet.reportedCheckinAt &&
    timesheet.reportedCheckoutAt &&
    timesheet.checkinCoords &&
    timesheet.checkoutCoords
      ? differenceInMinutes(
          parseISO(timesheet.reportedCheckoutAt),
          parseISO(timesheet.reportedCheckinAt)
        ) - (timesheet.reportedBreakMinutes ?? 0)
      : 0;

  const systemNetHours =
    timesheet && timesheet.checkinAt && timesheet.checkoutAt
      ? differenceInMinutes(
          parseISO(timesheet.checkoutAt),
          parseISO(timesheet.checkinAt)
        )
      : 0;

  const approvedNetHours =
    timesheet && timesheet.approvedCheckinAt && timesheet.approvedCheckoutAt
      ? differenceInMinutes(
          parseISO(timesheet.approvedCheckoutAt),
          parseISO(timesheet.approvedCheckinAt)
        ) - (timesheet.approvedBreakMinutes ?? 0)
      : 0;

  return (
    <>
      <Stack vertical verticalGap={5}>
        <Subheading>Workers Submitted Hours</Subheading>
        <FormElement displayType="row" label="Check-in:">
          {(timesheet?.checkinAt || timesheet?.reportedCheckinAt) &&
          timesheet?.checkinCoords ? (
            <Body>
              {formatISO(
                timesheet.reportedCheckinAt ?? timesheet.checkinAt,
                'hh:mm a'
              )}
            </Body>
          ) : (
            <Small>-</Small>
          )}
        </FormElement>
        <FormElement displayType="row" label="Check-out:">
          {(timesheet?.checkoutAt || timesheet?.reportedCheckoutAt) &&
          timesheet?.checkoutCoords ? (
            <Body>
              {formatISO(
                timesheet.reportedCheckoutAt ?? timesheet.checkoutAt,
                'hh:mm a'
              )}
            </Body>
          ) : (
            <Small>-</Small>
          )}
        </FormElement>
        <FormElement displayType="row" label="Break:">
          {timesheet?.checkoutCoords ? (
            <Body>{timesheet.reportedBreakMinutes ?? 0} m</Body>
          ) : (
            <Small>-</Small>
          )}
        </FormElement>
        <FormElement displayType="row" label="Net Hours:">
          <Body>
            {reportedNetHours
              ? getTotalLengthOfShiftTimesheet(reportedNetHours)
              : '-'}
          </Body>
        </FormElement>
        <div style={{ marginTop: '10px' }}>
          <Subheading>System Generated Hours</Subheading>
        </div>
        <FormElement displayType="row" label="Check-in:">
          {timesheet?.checkinAt ? (
            <Body>{formatISO(timesheet.checkinAt, 'hh:mm a')}</Body>
          ) : (
            <Small>-</Small>
          )}
        </FormElement>
        <FormElement displayType="row" label="Check-out:">
          {timesheet?.checkoutAt ? (
            <Body>{formatISO(timesheet.checkoutAt, 'hh:mm a')}</Body>
          ) : (
            <Small>-</Small>
          )}
        </FormElement>
        <FormElement displayType="row" label="Break:">
          <Body>0 m</Body>
        </FormElement>
        <FormElement displayType="row" label="Net Hours:">
          <Body>
            {systemNetHours
              ? getTotalLengthOfShiftTimesheet(systemNetHours)
              : '-'}
          </Body>
        </FormElement>
        {variant === TimesheetInformationVariants.DETAIL && (
          <>
            <div style={{ marginTop: '10px' }}>
              <Subheading>Approved Hours</Subheading>
            </div>
            <FormElement displayType="row" label="Check-in:">
              {timesheet?.approvedCheckinAt ? (
                <Body>{formatISO(timesheet.approvedCheckinAt, 'hh:mm a')}</Body>
              ) : (
                <Small>-</Small>
              )}
            </FormElement>
            <FormElement displayType="row" label="Check-out:">
              {timesheet?.approvedCheckoutAt ? (
                <Body>
                  {formatISO(timesheet.approvedCheckoutAt, 'hh:mm a')}
                </Body>
              ) : (
                <Small>-</Small>
              )}
            </FormElement>
            <FormElement displayType="row" label="Break:">
              <Body>{timesheet?.approvedBreakMinutes ?? 0} m</Body>
            </FormElement>
            <FormElement displayType="row" label="Net Hours:">
              <Body>
                {approvedNetHours
                  ? getTotalLengthOfShiftTimesheet(approvedNetHours)
                  : '-'}
              </Body>
            </FormElement>
            <div style={{ marginTop: '10px' }}>
              <Subheading>Tips</Subheading>
            </div>
            {timesheet?.status !== TimesheetStatusEnum.REJECTED &&
            timesheet?.tipAmount ? (
              <FormElement displayType="row" label="Tip amount">
                <Body>{timesheet.tipAmount}</Body>
              </FormElement>
            ) : (
              <FormElement displayType="row" label="Tip amount">
                <Body color="lightest">No tips</Body>
              </FormElement>
            )}
          </>
        )}
      </Stack>
    </>
  );
};

export default TimesheetInformation;

import { useState, useMemo, useRef, useEffect } from 'react';
import { CSVLink } from 'react-csv';

import DownloadIcon from '../DownloadIcon';

import DropdownSkillsByAgency from './DropdownSkills';

import { TableCell } from '@/elements/Table';
import { useListActiveUsersBySkilLazyQuery } from '@/graphql';
import {
  ActiveUsersBySkillReport,
  ListAgencySkillsQuery,
} from '@/types/graphql';
import { currentAgencyVar } from '@/util/apollo/cache';

interface HeaderType {
  label: string;
  key: keyof Omit<ActiveUsersBySkillReport, '__typename'>;
}

type SkillId =
  ListAgencySkillsQuery['agency']['skillCategories'][0]['skills'][0]['id'];

const filename = `ActiveUsersBySkillReport-${Date.now()}.csv`;

type Props = {
  setError: ({ message }: { message: string }) => void;
};

const ActiveUserListBySkill = ({ setError }: Props) => {
  const currentAgency = currentAgencyVar();
  const [selectedSkillsIds, setSelectedSkillsIds] = useState<SkillId[]>([]);
  const [csvData, setCsvData] = useState<ActiveUsersBySkillReport[] | null>(
    null
  );
  const csvInstance = useRef<any>();

  const [getActiveUsersBySkill, { loading }] =
    useListActiveUsersBySkilLazyQuery({
      fetchPolicy: 'no-cache',
      onCompleted: (data) => setCsvData(data.activeUsersBySkillReport),
      onError: (error) => setError({ message: error.message }),
    });

  const headers = useMemo<HeaderType[]>(
    () => [
      { label: 'Email', key: 'email' },
      { label: 'First Name', key: 'firstName' },
      { label: 'Middle Name', key: 'middleName' },
      { label: 'Last Name', key: 'lastName' },
      { label: 'Phone', key: 'phone' },
      { label: 'City', key: 'city' },
      { label: 'State', key: 'state' },
      { label: 'Jobs Worked', key: 'jobsWorked' },
      { label: 'Skill', key: 'skill' },
    ],
    []
  );

  function onDownload() {
    if (selectedSkillsIds.length === 0) return;

    const skills = selectedSkillsIds.map((skillId) => Number(skillId));
    getActiveUsersBySkill({ variables: { skills } });
  }

  useEffect(() => {
    if (csvData && csvInstance.current && csvInstance.current.link) {
      csvInstance.current.link.click();
      setCsvData(null);
    }
  }, [csvData]);

  return (
    <>
      <TableCell>Active User List By Skills</TableCell>
      <TableCell>
        <DropdownSkillsByAgency
          agencyId={currentAgency!.id}
          selectedSkillsIds={selectedSkillsIds}
          setSelectedSkillsIds={setSelectedSkillsIds}
        />
      </TableCell>
      <TableCell />
      <TableCell>
        <DownloadIcon isLoading={loading} onClick={onDownload} />
        {csvData && (
          <CSVLink
            ref={csvInstance}
            data={csvData}
            filename={filename}
            headers={headers}
          />
        )}
      </TableCell>
    </>
  );
};

export default ActiveUserListBySkill;

import { useReactiveVar } from '@apollo/client';

import { Role } from '@/routes/PrivateRoute';
import {
  CurrentCustomerAdminFragment,
  CurrentTenantAdminFragment,
} from '@/types/graphql';
import { currentAdminVar, currentAgencyVar } from '@/util/apollo/cache';

const useAuth = () => {
  const currentAgency = useReactiveVar(currentAgencyVar);

  // Adding this typeguard to ensure if customer is a property of currentAdmin object.
  const isCurrentCustomerAdmin = (
    arg: CurrentCustomerAdminFragment | CurrentTenantAdminFragment | null,
  ): arg is CurrentCustomerAdminFragment =>
    (arg as CurrentCustomerAdminFragment).customer !== undefined;

  const currentAdmin = useReactiveVar(currentAdminVar);
  const currentAdminIsCustomerAdmin =
    currentAdmin?.role === Role.CUSTOMER_ADMIN;

  return {
    currentAdmin,
    currentAdminIsCustomerAdmin,
    isCurrentCustomerAdmin,
    currentAgency,
  };
};

export default useAuth;

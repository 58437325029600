import { addDays, parseISO, startOfDay } from 'date-fns';
import { useMemo } from 'react';

import ShiftsColumn from './ShiftsColumn';

import EmptyState from '@/components/EmptyState';
import LoadingState from '@/components/LoadingState';
import useMediaQuery from '@/hooks/useMediaQuery';
import styled from '@/styles';
import { ListShiftsByWeekQuery } from '@/types/graphql';
import { nTimes } from '@/util/array';

const Wrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(7, 1fr)',
  width: '100%',
  overflowX: 'auto',
  '@phoneOnly': {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
});

type Shift = ListShiftsByWeekQuery['agency']['shiftsByWeek'][0];
type Props = {
  startDate: Date;
  shifts: Shift[];
  loading: boolean;
};

const ShiftGrid = ({ shifts, startDate, loading }: Props) => {
  const shiftsByDate = useMemo(() => getShiftsByDate(shifts), [shifts]);
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const isEmptyShifts = Object.keys(shiftsByDate).length === 0;

  const emptyStateStyle = {
    gridColumn: phoneOnly ? 'auto' : 'span 7',
    order: phoneOnly ? -1 : 99,
  };

  if (loading) {
    return <LoadingState />;
  }

  return (
    <Wrapper>
      {!isEmptyShifts &&
        nTimes(7, (index) => {
          const date = addDays(startDate, index);
          const shiftsForDate = shiftsByDate[date.getDate()] ?? [];
          return (
            shiftsForDate.length > 0 && (
              <ShiftsColumn
                key={date.toISOString()}
                date={date}
                shifts={shiftsForDate}
              />
            )
          );
        })}

      {isEmptyShifts && (
        <div style={emptyStateStyle}>
          <EmptyState
            text="There are no scheduled jobs for this week."
            title="No jobs"
          />
        </div>
      )}
    </Wrapper>
  );
};

const getShiftsByDate = (shifts: Shift[]) => {
  const byDate: Record<number, Shift[]> = {};

  shifts.forEach((shift) => {
    const date = startOfDay(parseISO(shift.startAt));

    if (typeof byDate[date.getDate()] === 'object') {
      byDate[date.getDate()].push(shift);
    } else {
      byDate[date.getDate()] = [shift];
    }
  });

  return byDate;
};

export default ShiftGrid;

import { createSvgIcon } from '@mui/material';

const EditTimesheetIcon = createSvgIcon(
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.90476 7.61905H11.0476V10.8571L13.2267 12.1448L12.6552 13.0743L9.90476 11.4286V7.61905ZM10.6667 5.33333C11.1924 5.33333 11.7105 5.40952 12.1905 5.55429V2.05714L9.90476 2.94095V5.38667C10.1562 5.33333 10.4076 5.33333 10.6667 5.33333ZM16 10.6667C16 12.0812 15.4381 13.4377 14.4379 14.4379C13.4377 15.4381 12.0812 16 10.6667 16C8.38095 16 6.4 14.5371 5.65333 12.4952L4.57143 12.1143L0.502857 13.6914L0.380952 13.7143C0.279918 13.7143 0.183021 13.6742 0.111578 13.6027C0.040136 13.5313 0 13.4344 0 13.3333V1.81333C0 1.6381 0.114286 1.50095 0.274286 1.44762L4.57143 0L9.14286 1.6L13.2114 0.0228571L13.3333 0C13.4344 0 13.5313 0.040136 13.6027 0.111578C13.6742 0.183021 13.7143 0.279918 13.7143 0.380952V6.28571C15.0933 7.2381 16 8.85333 16 10.6667ZM5.33333 10.6667C5.33333 8.54095 6.57524 6.70476 8.38095 5.84381V2.94857L5.33333 1.8819V10.7657C5.33333 10.7352 5.33333 10.6971 5.33333 10.6667ZM10.6667 6.85714C9.65632 6.85714 8.68735 7.2585 7.97293 7.97293C7.2585 8.68735 6.85714 9.65632 6.85714 10.6667C6.85714 11.677 7.2585 12.646 7.97293 13.3604C8.68735 14.0748 9.65632 14.4762 10.6667 14.4762C11.677 14.4762 12.646 14.0748 13.3604 13.3604C14.0748 12.646 14.4762 11.677 14.4762 10.6667C14.4762 9.65632 14.0748 8.68735 13.3604 7.97293C12.646 7.2585 11.677 6.85714 10.6667 6.85714ZM1.52381 2.63619V11.6648L3.80952 10.781V1.86667L1.52381 2.63619Z"
      fill="currentColor"
    />
  </svg>,
  'EditTimesheetIcon'
);
export default EditTimesheetIcon;

import styled from '@/styles';

export const StyledStackItemCreateAccountModal = styled('div', {
  height: '34px',
  paddingLeft: '10px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  transition: 'background-color 0.3s ease',
  width: '100%',
  marginBottom: '3px',

  '&:hover': {
    backgroundColor: '#E7F3E6 !important',
    color: '#45A735',
  },

  '&:hover > *': {
    color: '#45A735',
  },

  '&:hover svg': {
    color: '#45A735',
    border: '3px solid #45A735',
  },
});

export const CreateAccountDropDownIconsStyles = {
  border: '3px solid #7A7A7A',
  borderRadius: '100%',
  padding: '2px',
  color: '#7A7A7A',
  width: '20px !important',
  height: '20px',
};

export const BillingAccountListItemStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
};

export const BillingAccountStatusPillStyles = {
  padding: '5px 10px',
  backgroundColor: '#E7E7E7',
  borderRadius: '999px',
};

import { faPen } from '@fortawesome/free-solid-svg-icons';
import { ChangeEvent, useCallback, useMemo } from 'react';

import Avatar from '@/components/Avatar';
import styled from '@/styles';
import { Maybe } from '@/types';
import { ListTenantAdminQuery } from '@/types/graphql';
import { getNewFileWithUniqueName } from '@/util/file';

type AdmintItem = ListTenantAdminQuery['agency']['tenant']['admins'][0];

const AvatarContainer = styled('div', {
  aspectRatio: 1,
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
});

const AvatarLabel = styled('label', {
  aspectRatio: 1,
  cursor: 'pointer',
});

const InputFile = styled('input', {
  visibility: 'hidden',
  width: 0,
  height: 0,
});

type Props = {
  currentAvatarUrl: string | null;
  item?: Maybe<AdmintItem>;
  useFile;
  useSetFile;
};

const ChangeAvatarCard = ({
  currentAvatarUrl,
  item,
  useFile: file,
  useSetFile: setFile,
}: Props) => {
  const handleFileSelect = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    setFile(
      getNewFileWithUniqueName(
        ev.currentTarget.files ? ev.currentTarget.files[0] ?? null : null
      )
    );
  }, []);

  const previewSrc = useMemo(
    () => (file ? URL.createObjectURL(file) : null),
    [file]
  );

  return (
    <AvatarContainer>
      <AvatarLabel htmlFor="Preview">
        <Avatar
          showIconicAction
          firstName={item?.user.firstName}
          icon={faPen}
          size="profilePic"
          src={previewSrc ?? currentAvatarUrl}
        />
      </AvatarLabel>
      <InputFile
        accept="image/*"
        capture="user"
        id="Preview"
        type="file"
        onChange={handleFileSelect}
      />
    </AvatarContainer>
  );
};

export default ChangeAvatarCard;

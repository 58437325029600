import { firstChar } from './regex';

export const capitalizeText = (word: string) =>
  word.replace(/_/, ' ').replace(/\b\w/g, (l) => l.toUpperCase());
export const parseEnums = (sentence: string) =>
  sentence
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(firstChar, (char) => char.toUpperCase());

export const capitalizeFirstLetter = (word: string) =>
  word.charAt(0).toUpperCase() + word.slice(1);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.worker-profile-dialog-view
  .MuiDialog-container
  .MuiDialog-paper::-webkit-scrollbar {
  width: 0px;
}

.worker-profile-dialog-view
  .MuiDialog-container
  .MuiDialog-paper::-webkit-scrollbar-track {
  background: #d9d9d9;
}

.worker-profile-dialog-view
  .MuiDialog-container
  .MuiDialog-paper::-webkit-scrollbar-thumb {
  background: #d9d9d9;
}

.worker-profile-dialog-skills-section {
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-user-select: none;
  user-select: none;
}

.worker-profile-dialog-skills-section::-webkit-scrollbar {
  width: 0px;
}

.worker-profile-dialog-skills-section::-webkit-scrollbar-track {
  background: transparent;
}

.worker-profile-dialog-skills-section::-webkit-scrollbar-thumb {
  background: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/routes/Agency/Job/WorkerTable/ProfileModal/ProfileModalRevamp/index.css"],"names":[],"mappings":"AAAA;;;EAGE,UAAU;AACZ;;AAEA;;;EAGE,mBAAmB;AACrB;;AAEA;;;EAGE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".worker-profile-dialog-view\n  .MuiDialog-container\n  .MuiDialog-paper::-webkit-scrollbar {\n  width: 0px;\n}\n\n.worker-profile-dialog-view\n  .MuiDialog-container\n  .MuiDialog-paper::-webkit-scrollbar-track {\n  background: #d9d9d9;\n}\n\n.worker-profile-dialog-view\n  .MuiDialog-container\n  .MuiDialog-paper::-webkit-scrollbar-thumb {\n  background: #d9d9d9;\n}\n\n.worker-profile-dialog-skills-section {\n  overflow-x: scroll;\n  overflow-y: hidden;\n  -webkit-user-select: none;\n  user-select: none;\n}\n\n.worker-profile-dialog-skills-section::-webkit-scrollbar {\n  width: 0px;\n}\n\n.worker-profile-dialog-skills-section::-webkit-scrollbar-track {\n  background: transparent;\n}\n\n.worker-profile-dialog-skills-section::-webkit-scrollbar-thumb {\n  background: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

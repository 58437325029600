import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { useOrderState } from '../../context';

import Progress, { Props as ProgressProps } from './Progress';

import IconicButton from '@/components/IconicButton';
import Stack from '@/components/Stack';
import TextStack from '@/components/TextStack';
import useMediaQuery from '@/hooks/useMediaQuery';
import styled from '@/styles';
import { AccountRateTypeEnum, JobTypeEnum } from '@/types/graphql';

const Wrapper = styled('nav', {
  display: 'grid',
  flexWrap: 'wrap',
  gridTemplateColumns: '1fr auto 1fr',
  gridTemplateRows: '64px',

  padding: '0 32px',

  position: 'fixed',
  right: 0,
  left: 0,
  top: 0,

  backgroundColor: 'white',
  boxShadow: '$2',

  zIndex: 1001,

  '@touchable': {
    gridTemplateColumns: 'auto',
    padding: '0 0 0.75rem 0',
    gridTemplateRows: '44px',
  },
});

type Props = ProgressProps & {
  onClose: () => void;
};

const TopBar = ({ completedStep, currentStep, onClose }: Props) => {
  const isTouchableBreakpoint = useMediaQuery('(max-width: 600px)');

  const { orderType, billing } = useOrderState();

  const CloseButton = () => (
    <Stack justify="end">
      <IconicButton
        a11yLabel="Close job editor"
        appearance="clear"
        icon={faTimes}
        onClick={onClose}
      />
    </Stack>
  );

  const StepperProgress = () => (
    <Progress
      completedStep={completedStep}
      currentStep={currentStep}
      isTouchableBreakpoint={isTouchableBreakpoint}
      orderType={orderType}
      showPayRate={billing?.account.rateType === AccountRateTypeEnum.MARKUP}
    />
  );

  return (
    <Wrapper>
      <Stack>
        <TextStack css={{ marginLeft: '0.75rem', fontWeight: 'bold' }}>
          {`Adding a new ${
            orderType === JobTypeEnum.GIG ? 'Gig' : orderType
          } job`}
        </TextStack>
        {isTouchableBreakpoint && <CloseButton />}
      </Stack>

      {isTouchableBreakpoint ? (
        <StepperProgress />
      ) : (
        <>
          <StepperProgress />
          <CloseButton />
        </>
      )}
    </Wrapper>
  );
};

export default TopBar;

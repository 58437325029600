import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from '@/styles';

export const Icon = styled(FontAwesomeIcon, {
  fontSize: 'inherit',
  lineHeight: 'inherit',

  variants: {
    size: {
      xs: {
        fontSize: '$xs',
      },
      sm: {
        fontSize: '$sm',
      },
      lg: {
        fontSize: '$lg',
      },
      '1x': {
        fontSize: '$xl',
      },
      '2x': {
        fontSize: '$xxl',
      },
      '3x': {
        fontSize: '$xxxl',
      },
    },
    theme: {
      default: { color: 'inherit' },
      success: { color: '$successTick' },
      information: { color: '#ECA300' },
      danger: { color: '$dangerDarker' },
      neutral: { color: '$neutralLight' },
      theme: { color: '$colors$themeDefault' },
      lighter: { color: '$textLighter' },
    },
  },
  defaultVariants: {
    size: 'lg',
    theme: 'default',
  },
});

Icon.displayName = 'stitches(Icon)';

export default Icon;

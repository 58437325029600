// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';

import { MAX_LENGTH_SHIFT_SUPERVISOR_INSTRUCTIONS } from '@/constants/text';
import ItemSelectField from '@/form/ItemSelectField';
import TextAreaField from '@/form/TextAreaField';
import { OnChangeFirstStepForm } from '@/routes/Agency/CreateGig/JobEditor/Steps/SkillStep';
import { useOrderState } from '@/routes/Agency/CreateGig/context';
import styled from '@/styles';
import { CreateJobContactAdminType } from '@/types';

type Props = {
  onChange: OnChangeFirstStepForm;
};

const CreateContactFormWrapper = styled('div', {
  width: '100%',
});

const CreateContactForm = ({ onChange }: Props) => {
  const { billing } = useOrderState();
  const admins = billing?.customer.admins.items;

  return (
    <CreateContactFormWrapper>
      <ItemSelectField<CreateJobContactAdminType>
        required
        callback={(fieldContext) => {
          onChange(fieldContext, 'contact');
        }}
        css={{
          width: '100%',
        }}
        fieldId="contact"
        itemToKey={(item) => item.id}
        itemToString={(item) => {
          return item ? `${item.user.firstName} ${item.user.lastName}` : '';
        }}
        items={admins ?? []}
        label="Shift supervisor"
        placeholder="Select a shift supervisor..."
      />
      <TextAreaField
        callback={(fieldContext) => {
          onChange(fieldContext, 'contactInstructions');
        }}
        fieldId="contactInstructions"
        label="Shift supervisor instructions"
        maxLength={MAX_LENGTH_SHIFT_SUPERVISOR_INSTRUCTIONS}
        placeholder="Provide any instructions for checking in with the shift supervisor..."
      />
    </CreateContactFormWrapper>
  );
};

export default CreateContactForm;

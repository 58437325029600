import { Divider } from '@mui/material';

import {
  sortSchedules,
  useOrderActions,
  useOrderState,
} from '../../../context';
import { JobDraftState, Schedule } from '../../context';
import { getTotalHoursInDecimal } from '../ScheduleStep/util';
import SummaryTableRevamped from '../SummaryTableRevamped';

import Accordion from '@/components/Accordion';
import Avatar from '@/components/Avatar';
import Card from '@/components/Card';
import Stack from '@/components/Stack';
import TextStack from '@/components/TextStack';
import { Heading, Subheading } from '@/components/Typography';
import Text from '@/elements/Text';
import useMediaQuery from '@/hooks/useMediaQuery';
import {
  formatDaySimpleWithTwoDigitYear,
  calculateTotalHours,
} from '@/util/datetime';

type Props = {
  job: JobDraftState;
  index: number;
};

const JobCardDetailsRevamped = ({ job, index }: Props) => {
  const { jobs } = useOrderState();
  const { removeJob, replaceJob } = useOrderActions();

  const allShiftsLength = job?.schedules.length;
  const phoneOnly = useMediaQuery('(max-width: 559px)');

  const updateSchedule = (jobIndex, schedules: Schedule[]) => {
    const job = jobs[jobIndex];
    if (schedules.length) {
      job.schedules = sortSchedules(schedules);
      replaceJob(jobIndex, job);
    } else {
      removeJob(jobIndex);
    }
  };

  const totalHoursPerJob = calculateTotalHours({ schedules: job.schedules });
  const totalDecimalHours = getTotalHoursInDecimal(totalHoursPerJob ?? 0);
  const firstShiftStartDate = formatDaySimpleWithTwoDigitYear(
    job?.schedules[0]?.dateRange?.startDate
  );
  const lastShiftStartDate = formatDaySimpleWithTwoDigitYear(
    job?.schedules[allShiftsLength - 1]?.dateRange?.startDate
  );

  return (
    <Stack>
      <Card
        key={index}
        css={{ border: '1px solid #B3BAC5' }}
        noRadius={phoneOnly}
      >
        <Card.Section>
          <Stack justify={'apart'}>
            <Stack gap={8} style={{ alignItems: 'flex-start' }}>
              <Avatar size="lg" src={job.skill!.imageUrl!} />
              <TextStack>
                <Heading>{job.skill!.name}</Heading>
                <Text css={{ color: '#7A7A7A', fontSize: '14px' }}>
                  {firstShiftStartDate + ' - ' + lastShiftStartDate}
                </Text>
                <Text css={{ color: '#7A7A7A', fontSize: '14px' }}>
                  {`${job?.schedules.length} Shifts`}
                </Text>
              </TextStack>
            </Stack>
            <TextStack
              css={{ alignItems: 'center', width: '120px' }}
              justify={'end'}
            >
              <Subheading css={{ color: '#7A7A7A', fontSize: '12px' }}>
                {'Total Hours'}
              </Subheading>
              <Text
                size={'lg'}
                weight={'medium'}
              >{`${totalDecimalHours}`}</Text>
            </TextStack>
          </Stack>
        </Card.Section>
        <Divider />
        <Accordion
          css={{ borderColor: 'transparent', marginBottom: '10px' }}
          customHeadingLabel="View Schedule"
          label="Schedule"
        >
          <SummaryTableRevamped
            handleJobsChange={(schedules: Schedule[]) => {
              updateSchedule(index, schedules);
            }}
            job={job}
            jobIndex={index}
            skillId={job.skill?.id}
          />
        </Accordion>
      </Card>
    </Stack>
  );
};

export default JobCardDetailsRevamped;

import { v4 as uuidv4 } from 'uuid';

export const getFileExtension = (filename: string) => {
  return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
};

export const getFileNameAndExtension = (fullFileName: string) => {
  const lastDotIndex = fullFileName.lastIndexOf('.');

  if (lastDotIndex === -1 || lastDotIndex === 0) {
    return {
      name: fullFileName,
      extension: '',
    };
  }

  const name = fullFileName.substring(0, lastDotIndex);
  const extension = fullFileName.substring(lastDotIndex + 1);

  return { name, extension };
};

export const getNewFileWithUniqueName = (file: File | null): File | null => {
  const uuid = uuidv4();

  if (file) {
    const { name, extension } = getFileNameAndExtension(file.name);
    const newFileName = `${name}_${uuid}.${extension}`;

    file = new File([file], `${newFileName}`, {
      type: file.type,
      lastModified: file.lastModified,
    });
    return file;
  }

  return null;
};

import { FieldContext, SubmitHelpers, useField } from '@area2k/use-form';
import { useCallback, useState } from 'react';

import { getJobValues } from '../../util';

import UniformSelectField, { UniformItem } from './UniformSelectField';

import Button from '@/components/Button';
import Card from '@/components/Card';
import FormElement from '@/components/FormElement';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import TextArea from '@/components/TextArea';
import { MAX_LENGTH_UNIFORM_INSTRUCTIONS } from '@/constants/text';
import Form from '@/form';
import { useUpdateJobMutation } from '@/graphql';
import { GetJobQuery, Scalars } from '@/types/graphql';
import { handleMutationFormError } from '@/util/error';

export type Props = {
  job: GetJobQuery['job'];
  hideModal: () => Scalars['Void'];
};

type FormValues = {
  uniform: UniformItem;
  uniformInstructions: Scalars['String'];
};

type FormProps = {
  handleFormValuesChange: <T extends unknown>(
    fieldContext: FieldContext<T>,
    fieldId: keyof FormValues
  ) => Scalars['Void'];
};

const FormFields = ({ handleFormValuesChange }: FormProps) => {
  const { value, setValue } = useField<Scalars['String']>(
    'uniformInstructions',
    (fieldContext) => {
      handleFormValuesChange(fieldContext, 'uniformInstructions');
    }
  );

  return (
    <>
      <UniformSelectField
        callback={(fieldContext) => {
          handleFormValuesChange(fieldContext, 'uniform');
          setValue(fieldContext.value?.defaultInstructions!);
        }}
        fieldId="uniform"
        label="Dress Code"
      />
      <FormElement
        htmlFor="uniformInstructions"
        label="Dress code instructions"
      >
        <TextArea
          id="uniformInstructions"
          maxLength={MAX_LENGTH_UNIFORM_INSTRUCTIONS}
          name="uniformInstructions"
          placeholder="Provide details on dress code expectation…"
          value={value}
          onChange={(ev) => {
            setValue(ev.currentTarget.value);
          }}
        />
      </FormElement>
    </>
  );
};

const UpdateUniformModal = ({ job, hideModal }: Props) => {
  const initialValues: FormValues = {
    uniform: job.uniform,
    uniformInstructions: job.uniformInstructions ?? '',
  };

  const [formValues, setFormValues] = useState<FormValues>(initialValues);

  const [updateJob, { loading: isLoading }] = useUpdateJobMutation();

  const handleFormValuesChange = <T extends any>(
    fieldContext: FieldContext<T>,
    fieldId: keyof FormValues
  ) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldId]: fieldContext.value,
    }));
  };

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      const jobValues = getJobValues(job);
      try {
        if (
          values.uniform.id === jobValues.uniformId &&
          values.uniformInstructions === jobValues.uniformInstructions
        ) {
          return hideModal();
        }
        await updateJob({
          variables: {
            ...jobValues,
            uniformId: values.uniform.id,
            uniformInstructions: values.uniformInstructions.trim(),
          },
        });
        hideModal();
      } catch (err: any) {
        setFormError('error', { message: err.message });
        handleMutationFormError(err, { setFormError });
      }
    },
    [job]
  );

  return (
    <Modal
      disableClickout
      size="sm"
      title="Update dress code"
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Form initialValues={formValues} onSubmit={handleSubmit}>
          <FormFields handleFormValuesChange={handleFormValuesChange} />

          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              isLoading={isLoading}
              label="Update"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  );
};

export default UpdateUniformModal;

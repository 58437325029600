import { ComponentProps } from 'react';

import Badge from '@/components/Badge';

export enum CustomerOnboardingStatusEnums {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

interface Props {
  status: CustomerOnboardingStatusEnums;
}

const mappedBadgeProps = {
  [CustomerOnboardingStatusEnums.IN_PROGRESS]: {
    label: 'In Progress',
    status: 'success',
  },
  [CustomerOnboardingStatusEnums.COMPLETED]: {
    label: 'Complete',
    status: 'theme',
  },
};

const getBadgePropsByInvoiceStatus = (
  status: CustomerOnboardingStatusEnums
): ComponentProps<typeof Badge> => mappedBadgeProps[status];

const OnboradingStatusBadge = ({ status }: Props) => (
  <Badge {...getBadgePropsByInvoiceStatus(status)} />
);

export default OnboradingStatusBadge;

/* eslint-disable no-console */
import { FieldContext, SubmitHelpers } from '@area2k/use-form';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { isEqual } from 'lodash';
import { useCallback, useState } from 'react';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Option from '@/components/Option';
import Stack from '@/components/Stack';
import { PlatformAccessStatusEnum } from '@/constants/platform';
import { V3Endpoints } from '@/constants/urls';
import Form from '@/form';
import FormColumns from '@/form/FormColumns';
import MaskedInputField from '@/form/MaskedInputField';
import OptionField from '@/form/OptionField';
import TextField from '@/form/TextField';
import {
  useChangeStatusUserMutation,
  useCheckExistingEmailQuery,
  useUpdateCustomerAdminProfileMutation,
} from '@/graphql';
import { GetAdminCustomerQuery } from '@/types/graphql';
import { currentAdminVar } from '@/util/apollo/cache';
import axiosClient from '@/util/axios/axiosClient';
import { handleMutationFormError } from '@/util/error';

type Props = {
  hideModal: () => void;
  customerAdmin: GetAdminCustomerQuery['customerAdmin'];
  setSuccessMsg: (msg: string) => void;
  setErrorMsg: (msg: string) => void;
  closeSuccessModal: () => void;
  closeErrorModal: () => void;
};

type FormValues = {
  active: GetAdminCustomerQuery['customerAdmin']['user']['active'];
  email: GetAdminCustomerQuery['customerAdmin']['user']['email'];
  firstName: GetAdminCustomerQuery['customerAdmin']['user']['firstName'];
  lastName: GetAdminCustomerQuery['customerAdmin']['user']['lastName'];
  phoneNumber: GetAdminCustomerQuery['customerAdmin']['user']['phoneNumber'];
  title: NonNullable<GetAdminCustomerQuery['customerAdmin']['title']>;
};

const EditAdminInfoModal = ({
  hideModal,
  customerAdmin,
  setSuccessMsg,
  setErrorMsg,
}: Props) => {
  const initialValues: FormValues = {
    active: customerAdmin.user.active,
    email: customerAdmin.user.email,
    firstName: customerAdmin.user.firstName,
    lastName: customerAdmin.user.lastName,
    phoneNumber: customerAdmin.user.phoneNumber,
    title: customerAdmin.title ?? '',
  };

  const [formValues, setFormValues] = useState<FormValues>(initialValues);
  const [error, setError] = useState<{ message: string } | null>(null);
  const [isLoadingAceess, setLoadingAccess] = useState<boolean>(false);
  const [appAccess, setAppAccess] = useState<boolean>(
    (customerAdmin.user.allowedPlatform &
      PlatformAccessStatusEnum.APP_APPROVED) !==
      0
  );
  const query = useCheckExistingEmailQuery({
    variables: { email: formValues.email },
  });

  const { data } = query;
  const emailExists = data && data.existingUser;

  const [changeStatusUserMutation] = useChangeStatusUserMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(customerAdmin),
        fields: { user() {} },
      });
    },
  });

  const toggleAppAccess = () => {
    setLoadingAccess(true);
    if (appAccess) {
      axiosClient
        .patch(V3Endpoints.REVOKE_APP_ACCESS, {
          email: customerAdmin.user.email,
        })
        .then(async () => {
          await changeStatusUserMutation({
            variables: {
              userId: customerAdmin!.user.id,
              active: formValues.active,
            },
          });
          setSuccessMsg('Access revoked');
        })
        .catch((err) => {
          setErrorMsg(err.response.data.message);
        })
        .finally(() => {
          hideModal();
          setAppAccess(false);
        });
    } else {
      axiosClient
        .patch(V3Endpoints.GRANT_APP_ACCESS, {
          email: customerAdmin.user.email,
        })
        .then(async () => {
          await changeStatusUserMutation({
            variables: {
              userId: customerAdmin!.user.id,
              active: formValues.active,
            },
          });
          setSuccessMsg('Access granted');
          setAppAccess(true);
        })
        .catch((err) => {
          setErrorMsg(err.response.data.message);
        })
        .finally(() => {
          hideModal();
          setAppAccess(false);
        });
    }
  };

  const [updateCustomerAdminProfile, { loading: isLoading }] =
    useUpdateCustomerAdminProfileMutation();

  const handleFormValuesChange = <T extends any>(
    fieldContext: FieldContext<T>,
    fieldId: keyof FormValues
  ) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldId]: fieldContext.value,
    }));
  };

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        if (isEqual(formValues, initialValues)) {
          return hideModal();
        }

        if (formValues.active !== initialValues.active) {
          await changeStatusUserMutation({
            variables: {
              userId: customerAdmin!.user.id,
              active: formValues.active,
            },
          });
          hideModal();
        }

        if (emailExists) {
          if (formValues.email !== initialValues.email) {
            return setError({ message: 'Email already in use' });
          } else {
            setError(null);
          }
        }

        if (error === null) {
          await updateCustomerAdminProfile({
            variables: {
              ...values,
              customerAdminId: customerAdmin.id,
            },
          });
          hideModal();
        }
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
        });
      }
    },
    [emailExists, error, formValues]
  );

  const renderCheckbox = () => {
    if (currentAdminVar()?.__typename === 'TenantAdmin') {
      return (
        <Option
          appearance="checkbox"
          checked={appAccess}
          disabled={isLoadingAceess}
          id={'allShiftsSameWorker'}
          label={'Allow access to client mobile app'}
          name={'allShiftsSameWorker'}
          type="checkbox"
          onChange={() => {
            toggleAppAccess();
          }}
        />
      );
    }
  };

  return (
    <Modal
      disableClickout
      size="sm"
      title="Update Information"
      onRequestClose={hideModal}
    >
      <Card.Section>
        {error && (
          <Alert
            description={error.message}
            icon={faExclamationTriangle}
            status="warning"
            title="Something went wrong"
          />
        )}
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <FormColumns>
            <TextField
              autoFocus
              required
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'firstName');
              }}
              fieldId="firstName"
              label="First Name"
              placeholder="First Name"
            />
            <TextField
              required
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'lastName');
              }}
              fieldId="lastName"
              label="Last Name"
              placeholder="Last Name"
            />
          </FormColumns>
          <FormColumns>
            <TextField
              autoComplete="off"
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'email');
                if (error) {
                  setError(null);
                }
              }}
              fieldId="email"
              label="Email Address"
              placeholder="Email"
            />
            <TextField
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'title');
              }}
              fieldId="title"
              label="Job Title"
              placeholder="Title"
            />
          </FormColumns>
          <FormColumns>
            <MaskedInputField
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'phoneNumber');
              }}
              fieldId="phoneNumber"
              incompletemessage="Must be a valid phone number"
              label="Phone Number"
              mask="(000) 000-0000"
              placeholder="(555) 555-5555"
              type="tel"
            />
            {renderCheckbox()}
          </FormColumns>

          <Stack justify="apart">
            <OptionField
              appearance="switch"
              callback={async (fieldContext) => {
                await handleFormValuesChange(fieldContext, 'active');
              }}
              fieldId="active"
              label="Account Enabled"
            />
            <Button
              a11yLabel="Submit form"
              id="btn-save"
              isLoading={isLoading}
              label="Save"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  );
};

export default EditAdminInfoModal;

import styled from '@/styles';

export const Wrapper = styled('div', {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',

  variants: {
    direction: {
      row: {
        flexDirection: 'row',
      },
      column: {
        flexDirection: 'column',
      },
    },
  },

  defaultVariants: {
    direction: 'column',
  },
});

import { createSvgIcon } from '@mui/material';

const ClientApproveIcon = createSvgIcon(
  <svg
    fill="none"
    height="17"
    viewBox="0 0 20 17"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.1 8.49609L19.5 9.90609L12.97 16.4961L9.5 12.9961L10.9 11.5861L12.97 13.6661L18.1 8.49609ZM8 -0.00390625C9.06087 -0.00390625 10.0783 0.417521 10.8284 1.16767C11.5786 1.91781 12 2.93523 12 3.99609C12 5.05696 11.5786 6.07438 10.8284 6.82452C10.0783 7.57467 9.06087 7.99609 8 7.99609C6.93913 7.99609 5.92172 7.57467 5.17157 6.82452C4.42143 6.07438 4 5.05696 4 3.99609C4 2.93523 4.42143 1.91781 5.17157 1.16767C5.92172 0.417521 6.93913 -0.00390625 8 -0.00390625ZM8 1.99609C7.46957 1.99609 6.96086 2.20681 6.58579 2.58188C6.21071 2.95695 6 3.46566 6 3.99609C6 4.52653 6.21071 5.03523 6.58579 5.41031C6.96086 5.78538 7.46957 5.99609 8 5.99609C8.53043 5.99609 9.03914 5.78538 9.41421 5.41031C9.78929 5.03523 10 4.52653 10 3.99609C10 3.46566 9.78929 2.95695 9.41421 2.58188C9.03914 2.20681 8.53043 1.99609 8 1.99609ZM8 8.99609C8.68 8.99609 9.5 9.08609 10.41 9.25609L8.74 10.9261L8 10.8961C5.03 10.8961 1.9 12.3561 1.9 12.9961V14.0961H8.1L10 15.9961H0V12.9961C0 10.3361 5.33 8.99609 8 8.99609Z"
      fill="#7A7A7A"
    />
  </svg>,
  'ClientApproveIcon'
);
export default ClientApproveIcon;

import { Link } from 'react-router-dom';

import { StyledPage } from '../SignUp/styles';
import routes from '../routes';

import { logoWorkLight } from '@/assets/img';
import Button from '@/components/Button';
import GravyWorkLogo from '@/components/GravyWorkLogo';
import Stack from '@/components/Stack';
import { Small, Title } from '@/components/Typography';
import { privacyPolicyUrl } from '@/constants/urls';

const MobileIntro = () => {
  return (
    <StyledPage
      css={{
        alignItems: 'flex-start',
        backgroundColor: '#1c1c1c',
        color: 'white',
        height: 'auto',
      }}
      size="xs"
    >
      <Stack
        vertical
        css={{ minHeight: 'calc(100vh - 48px)' }}
        justify={'apart'}
      >
        <Stack vertical gap={24}>
          <Stack vertical gap={66}>
            <Stack vertical css={{ paddingTop: '86px' }} justify="center">
              <GravyWorkLogo src={logoWorkLight} />
            </Stack>
            <Stack vertical gap={8}>
              <Title color={'inherit'} weight={'normal'}>
                Your Go-To Hiring Platform
              </Title>
              <Small color="lightest" size="md">
                Sign in to your business account
              </Small>
            </Stack>
            <Link
              id="sign-up"
              style={{ width: '100%' }}
              to={`/${routes.appLogin}`}
            >
              <Button
                a11yLabel="Sign in"
                id="sign-in-btn"
                loadingLabel="Log In..."
                style={{ width: '100%' }}
              />
            </Link>
          </Stack>
          <Link
            style={{ alignSelf: 'center' }}
            target="_self"
            to={{ pathname: `/` }}
            onClick={() => {
              window.open(privacyPolicyUrl, '_self');
            }}
          >
            Privacy Policy
          </Link>
        </Stack>
        <Stack vertical align={'center'} gap={2}>
          <Small color="lightest">Your Go-To Hiring Platform</Small>
          <Small color="theme">Sign in to your business account</Small>
        </Stack>
      </Stack>
    </StyledPage>
  );
};

export default MobileIntro;

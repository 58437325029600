import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import Action from '@/components/Action';
import Card from '@/components/Card';
import EmptyState from '@/components/EmptyState';
import Page from '@/components/Page';
import Stack from '@/components/Stack';

const PageSkeleton = () => (
  <Page css={{ alignItems: 'center', height: '100%' }} size="md">
    <Card sectioned>
      <Stack
        vertical
        gap={30}
        style={{ alignItems: 'center', padding: '30px' }}
      >
        <EmptyState
          icon={faExclamationCircle}
          text="This invoice cannot be found"
          title="Something went wrong"
        />
        <Action.Button
          action={{
            a11yLabel: 'Return to safe page',
            label: 'Return to previous page',
            to: '/invoices',
          }}
        />
      </Stack>
    </Card>
  </Page>
);

export default PageSkeleton;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: var(--radii-lg);
  font-size: var(---fontSize);
}
ul.pagination li a {
  text-decoration: none;
  position: relative;
  display: block;
  padding: 0.5rem 0.65rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #45a735;
  background-color: white;
  border: 1px solid var(--colors-themeDefault);
}
ul.pagination li:first-child a{
  border-radius: var(--radii-lg) 0 0 var(--radii-lg);
}
ul.pagination li:last-child a{
  border-radius: 0 var(--radii-lg) var(--radii-lg) 0;
}
ul.pagination li:hover a,
ul.pagination .active a {
  color: white;
}

ul.pagination li a:hover {
  background-color: #a6d69f;
}

ul.pagination .active a {
  background-color: #6ab95d;
}

ul.pagination li.disabled a {
  color: #B2B2B2;
  pointer-events: none;
  cursor: auto;
  background-color: #F4F6F3;
}
.pagination-action{
  font-weight: 700;
}

select {
  border-color: #c0c0c0;
  border-radius: 10px;
  padding: 11px 6px;
  border: 1px solid var(---borderColor);
  border-radius: var(--radii-lg);
  background: white;
  font-size: var(--fontSizes-md);
}
`, "",{"version":3,"sources":["webpack://./src/components/Paginator/styles.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,8BAA8B;EAC9B,2BAA2B;AAC7B;AACA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,cAAc;EACd,uBAAuB;EACvB,iBAAiB;EACjB,iBAAiB;EACjB,cAAc;EACd,uBAAuB;EACvB,4CAA4C;AAC9C;AACA;EACE,kDAAkD;AACpD;AACA;EACE,kDAAkD;AACpD;AACA;;EAEE,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,oBAAoB;EACpB,YAAY;EACZ,yBAAyB;AAC3B;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,iBAAiB;EACjB,qCAAqC;EACrC,8BAA8B;EAC9B,iBAAiB;EACjB,8BAA8B;AAChC","sourcesContent":["ul.pagination {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  padding-left: 0;\n  list-style: none;\n  border-radius: var(--radii-lg);\n  font-size: var(---fontSize);\n}\nul.pagination li a {\n  text-decoration: none;\n  position: relative;\n  display: block;\n  padding: 0.5rem 0.65rem;\n  margin-left: -1px;\n  line-height: 1.25;\n  color: #45a735;\n  background-color: white;\n  border: 1px solid var(--colors-themeDefault);\n}\nul.pagination li:first-child a{\n  border-radius: var(--radii-lg) 0 0 var(--radii-lg);\n}\nul.pagination li:last-child a{\n  border-radius: 0 var(--radii-lg) var(--radii-lg) 0;\n}\nul.pagination li:hover a,\nul.pagination .active a {\n  color: white;\n}\n\nul.pagination li a:hover {\n  background-color: #a6d69f;\n}\n\nul.pagination .active a {\n  background-color: #6ab95d;\n}\n\nul.pagination li.disabled a {\n  color: #B2B2B2;\n  pointer-events: none;\n  cursor: auto;\n  background-color: #F4F6F3;\n}\n.pagination-action{\n  font-weight: 700;\n}\n\nselect {\n  border-color: #c0c0c0;\n  border-radius: 10px;\n  padding: 11px 6px;\n  border: 1px solid var(---borderColor);\n  border-radius: var(--radii-lg);\n  background: white;\n  font-size: var(--fontSizes-md);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

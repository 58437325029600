import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Step } from '../..';
import { useSignUpActions } from '../../hooks/useSignUpActions';
import { useSignUpState } from '../../hooks/useSignUpState';
import { FirstStepState } from '../../interfaces/signUp.interface';
import { InputWrapper } from '../../styles';

import firstStepSchema from './schema';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import FormElement from '@/components/FormElement';
import Stack from '@/components/Stack';
import TextInput from '@/components/TextInput';
import { Body, Small } from '@/components/Typography';
import Link from '@/elements/Link';
import FormColumns from '@/form/FormColumns';
import {
  useCheckDuplicateCustomerNameLazyQuery,
  useCheckExistingEmailLazyQuery,
} from '@/graphql';
import ROUTES from '@/routes/routes';

type Props = {
  setStep: (step: Step) => void;
};

const FirstStep = ({ setStep }: Props) => {
  const [submitError, setSubmitError] = useState<{ message: string } | null>(
    null
  );
  const { companyName, email, password } = useSignUpState();
  const { updateFirstStep } = useSignUpActions();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setError,
  } = useForm<FirstStepState>({
    resolver: yupResolver(firstStepSchema),
    mode: 'onChange',
    defaultValues: { companyName, email, password },
  });
  const [formValues, setFormValues] = useState<FirstStepState>({
    companyName,
    email,
    password,
  });
  const [
    CheckExistingEmail,
    { data: emailExistsData, loading: checkExistingEmailLoading },
  ] = useCheckExistingEmailLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: ({ existingUser }) => {
      const emailExists = existingUser;

      if (emailExists) {
        return setError('email', { message: 'This email is already in use' });
      }

      setSubmitError(null);
    },
    onError: (error) => {
      const { networkError } = error;

      if (networkError && networkError.result) {
        const submitError = networkError.result.errors[0];
        return setSubmitError(submitError);
      }

      return setSubmitError(error);
    },
  });

  const existingUser = emailExistsData && emailExistsData.existingUser;

  const [CheckDuplicateCustomerName, { loading: checkCustomerNameLoading }] =
    useCheckDuplicateCustomerNameLazyQuery({
      fetchPolicy: 'no-cache',
      onCompleted: ({ customerNameDuplicate }) => {
        const duplicateCustomerName = customerNameDuplicate;

        if (duplicateCustomerName) {
          return setError('companyName', {
            message: 'This company name is already in use',
          });
        }

        if (duplicateCustomerName === false && existingUser === false) {
          updateFirstStep(formValues);
          setStep(Step.SECOND);
        }

        setSubmitError(null);
      },
      onError: (error) => {
        const { networkError } = error;

        if (networkError && networkError.result) {
          const submitError = networkError.result.errors[0];
          return setSubmitError(submitError);
        }

        return setSubmitError(error);
      },
    });

  const onSubmit: SubmitHandler<FirstStepState> = (values) => {
    setFormValues(values);
    CheckExistingEmail({ variables: { email: values.email } });
    CheckDuplicateCustomerName({
      variables: { clientName: values.companyName },
    });
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Card.Section>
        {submitError && (
          <Alert
            description={submitError.message}
            icon={faExclamationTriangle}
            status="warning"
            title="Something went wrong"
          />
        )}

        <FormElement htmlFor="companyName" label="Company Name">
          <Stack vertical verticalGap={6}>
            <InputWrapper>
              <TextInput
                autoFocus
                {...register('companyName')}
                id="companyName"
                placeholder="Company Name"
              />
            </InputWrapper>
            {errors.companyName && (
              <Small color="danger">{errors.companyName.message}</Small>
            )}
          </Stack>
        </FormElement>

        <FormElement htmlFor="email" label="Email Address">
          <Stack vertical verticalGap={6}>
            <InputWrapper>
              <TextInput
                {...register('email')}
                id="email"
                placeholder="Email Address"
                type="email"
              />
            </InputWrapper>
            {errors.email && (
              <Small color="danger">{errors.email.message}</Small>
            )}
          </Stack>
        </FormElement>

        <FormElement htmlFor="password" label="Password">
          <Stack vertical verticalGap={6}>
            <InputWrapper>
              <TextInput
                {...register('password')}
                css={{ letterSpacing: '2px' }}
                id="password"
                placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                type="password"
              />
            </InputWrapper>
            {errors.password && (
              <Small color="danger">{errors.password.message}</Small>
            )}
          </Stack>
        </FormElement>

        <FormColumns layout="single">
          <Button
            a11yLabel="Submit form"
            disabled={
              !isValid || checkCustomerNameLoading || checkExistingEmailLoading
            }
            id="signup-next"
            label="Next"
            type="submit"
          />
        </FormColumns>
      </Card.Section>

      <Card.Section subdued>
        <Stack justify="center">
          <Body>Already have an account?</Body>
          <Link id="sign-in" to={`/${ROUTES.login}`}>
            Sign in
          </Link>
        </Stack>
      </Card.Section>
    </form>
  );
};

export default FirstStep;

import styled from '@/styles';
import { getTimezoneAbbreviation } from '@/util/date';

const TimeZoneWrapper = styled('div', {
  backgroundColor: '#C3EEB3',
  borderRadius: '10px',
  padding: '0.1rem 0.4rem',
  fontSize: '12px',
  color: '#1c1c1c',
});

type Props = {
  timezone: string;
  date?: string;
  locale: boolean;
  style?: any;
};

const TZBadge = ({ timezone, date, locale, style }: Props) => {
  const tz = getTimezoneAbbreviation(timezone, date, locale);
  return tz ? <TimeZoneWrapper style={style}>{tz}</TimeZoneWrapper> : <></>;
};

export default TZBadge;

import { PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';

import Button from '@/components/Button';

export type onSuccessType = PlaidLinkOptions['onSuccess'];
type Props = {
  token: string;
  onSuccess: onSuccessType;
};

const PlaidLinkButton = ({ token, onSuccess }: Props) => {
  const config: PlaidLinkOptions = {
    token,
    onSuccess,
  };

  const { open, ready, error } = usePlaidLink(config);

  if (error) {
    console.error(error.message);
  }

  return (
    <Button
      a11yLabel="Add New Card"
      disabled={!ready || token === ''}
      label="Add Account"
      type="button"
      onClick={(e) => {
        e.preventDefault();
        open();
      }}
    />
  );
};

export default PlaidLinkButton;

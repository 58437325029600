import {
  faSortNumericDown,
  faSortNumericUpAlt,
} from '@fortawesome/free-solid-svg-icons';
import * as React from 'react';

import { SortByFilter } from '..';

import { MIN_WORDS_TO_CAPITALIZE } from '@/constants/general';
import Icon from '@/elements/Icon';
import { TableHead, TableHeading, TableRow } from '@/elements/Table';
import { CustomerSortKeyEnum } from '@/types/graphql';
import { capitalizeText } from '@/util/text';

type Props = {
  fields: (string | { name: string; style: Record<string, any> })[];
  headerBgColor?: string;
  headerColor?: string;
  checkBoxHeader?: React.ReactNode;
  sorting: (sortType: SortByFilter['sort']) => void;
  sortBy: SortByFilter;
};

const ClientTableHeader = ({
  fields,
  headerBgColor,
  headerColor,
  checkBoxHeader,
  sorting,
  sortBy,
}: Props) => {
  const sortKeyMap = {
    0: CustomerSortKeyEnum.NAME,
    1: CustomerSortKeyEnum.CREATED_AT,
  };

  const mappedTitleProps = React.useCallback(
    (index) => {
      return {
        action: () => sorting(sortKeyMap[index]),
        icon:
          sortBy.reverse && sortBy.sort === sortKeyMap[index]
            ? faSortNumericUpAlt
            : faSortNumericDown,
      };
    },
    [sortBy],
  );

  return (
    <TableHead style={{ backgroundColor: headerBgColor }}>
      <TableRow>
        {checkBoxHeader}
        {fields.map((field, index) => {
          const isTouchable = index > -1 && index < 2;
          if (typeof field === 'string') {
            return (
              <TableHeading
                key={index}
                style={headerColor ? { color: headerColor } : undefined}
                touchable={isTouchable && 'true'}
                {...(isTouchable && {
                  onClick: mappedTitleProps(index).action,
                })}
                id={`table-head-${index}`}
              >
                {field.length < MIN_WORDS_TO_CAPITALIZE
                  ? capitalizeText(field)
                  : field}

                {isTouchable && (
                  <Icon
                    size={'sm'}
                    fixedWidth
                    icon={mappedTitleProps(index).icon}
                  />
                )}
              </TableHeading>
            );
          } else {
            return (
              <TableHeading key={index} style={field?.style}>
                {field?.name?.length < MIN_WORDS_TO_CAPITALIZE
                  ? capitalizeText(field?.name)
                  : field?.name}
              </TableHeading>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

export default ClientTableHeader;

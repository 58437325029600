export const getHeaderFields = (editable: boolean) => {
  const headerFields = [
    { name: 'Job ID', style: { width: '10%', color: 'black' } },
    { name: 'Worker', style: { width: '13%', color: 'black' } },
    { name: 'Date', style: { width: '14%', color: 'black' } },
    { name: 'Net Time', style: { width: '8%', color: 'black' } },
    { name: 'Address', style: { width: '16%', color: 'black' } },
    { name: 'Approved By', style: { width: '12%', color: 'black' } },
    { name: 'Rate', style: { width: '10%', color: 'black' } },
    { name: 'Tips', style: { width: '6%', color: 'black' } },
    { name: 'Total', style: { color: 'black' } },
  ];

  if (editable) {
    headerFields.push({ name: 'Action', style: { color: 'black' } });
  }

  return headerFields;
};

import { FieldCallback } from '@area2k/use-form';
import { useEffect, useMemo, useState } from 'react';

import AutocompleteField from '@/form/AutocompleteField';
import {
  useGetWorkerForJobTenantLazyQuery,
  useGetWorkersForJobLazyQuery,
} from '@/graphql';
import useDebouncedValue from '@/hooks/useDebouncedValue';
import {
  CompareOperatorEnum,
  ListWorkersForAutocompleteQuery,
  TaxTypeEnum,
  GetJobQuery,
  Scalars,
  RelationshipKindEnum,
} from '@/types/graphql';

export type WorkerItem =
  ListWorkersForAutocompleteQuery['agency']['workers']['items'][0];
export type Workers = GetJobQuery['job']['jobWorkers'][0]['worker'];

type Props = {
  fieldId: Scalars['String'];
  label: Scalars['String'];
  openMenuOnInputFocus?: Scalars['Boolean'];
  skillsId?: Scalars['ID'][];
  jurisdiction?: Scalars['String'][];
  taxType?: TaxTypeEnum;
  callback?: FieldCallback<WorkerItem | null>;
  context?:
    | 'multipleJobSelection'
    | 'inviteEmployeeModal'
    | 'hireEmployeeModal'
    | 'default'
    | 'createTimesheetModal'
    | 'clientsAddWorker';
  jobId?: Scalars['ID'];
  workers?: Workers[];
  relationShip?: {
    customerId: Scalars['ID'];
    kind: RelationshipKindEnum;
  };
};

const WorkerAutocompleteField = ({
  fieldId,
  label,
  openMenuOnInputFocus = true,
  skillsId,
  callback,
  jobId,
  jurisdiction,
  context = 'default',
  taxType,
  workers,
  relationShip,
  ...rest
}: Props) => {
  const [getWorkersForJob, { data: listWorkersData }] =
    useGetWorkersForJobLazyQuery({ fetchPolicy: 'no-cache' });

  const [getWorkersForJobTenant, { data: listWorkersTenantData }] =
    useGetWorkerForJobTenantLazyQuery({
      fetchPolicy: 'no-cache',
    });

  const [query, setQuery] = useState('');
  const debouncedQuery = useDebouncedValue(query, 800);

  const items = useMemo(() => {
    switch (context) {
      case 'clientsAddWorker':
      case 'multipleJobSelection': {
        if (listWorkersTenantData) {
          return listWorkersTenantData.agency?.workers?.items;
        }

        break;
      }

      case 'createTimesheetModal': {
        if (listWorkersTenantData) {
          const agencyWorkers = listWorkersTenantData.agency?.workers?.items;
          const filteredWorkers = agencyWorkers?.filter(
            (agencyWorker) =>
              workers?.some((worker) => worker.id === agencyWorker.id),
            []
          );

          return filteredWorkers;
        }

        break;
      }

      default: {
        if (listWorkersData) {
          return listWorkersData.jobHireableWorkers;
        }

        break;
      }
    }
    return [];
  }, [listWorkersData, listWorkersTenantData]) as WorkerItem[];

  useEffect(() => {
    const commonFiltersOptions = {
      ...(skillsId && {
        workerSkills: skillsId.map((skill) => ({
          op: CompareOperatorEnum.EQ,
          value: Number(skill),
        })),
      }),
      ...(taxType && {
        taxType: {
          value: taxType,
        },
      }),
      ...(debouncedQuery.length > 0 && {
        query: { value: debouncedQuery },
      }),
      ...(jurisdiction && jurisdiction.length > 0 && { jurisdiction }),
    };

    switch (context) {
      case 'multipleJobSelection': {
        const filtersOptions = {
          ...commonFiltersOptions,
          active: { value: true },
          hired: true,
          ...(taxType && {
            taxType: {
              value: taxType,
            },
          }),
          ...(relationShip && {
            relationship: relationShip,
          }),
        };

        getWorkersForJobTenant({
          variables: {
            filters: filtersOptions,
          },
        });
        break;
      }

      case 'clientsAddWorker': {
        getWorkersForJobTenant({
          variables: {
            filters: { ...commonFiltersOptions, active: { value: true } },
          },
        });
        break;
      }

      case 'createTimesheetModal': {
        getWorkersForJobTenant({
          variables: {
            filters: commonFiltersOptions,
          },
        });
        break;
      }

      default: {
        if (jobId) {
          getWorkersForJob({
            variables: {
              jobId,
              filters: commonFiltersOptions,
            },
          });
        }
        break;
      }
    }
  }, [debouncedQuery]);

  return (
    <AutocompleteField<WorkerItem>
      autoFocus
      required
      callback={callback}
      disabled={false}
      fieldId={fieldId}
      itemToKey={(item) => item.id}
      itemToString={(item) => {
        if (item) {
          if (item.user.middleName) {
            return `${item.user.firstName} ${item.user.middleName ?? ''} ${
              item.user.lastName
            }`;
          } else {
            return `${item.user.firstName} ${item.user.lastName}`;
          }
        } else {
          return '';
        }
      }}
      items={items}
      label={label}
      openMenuOnInputFocus={openMenuOnInputFocus}
      placeholder="Select GravyWorker..."
      onInputValueChange={({ inputValue }) => setQuery(inputValue ?? '')}
      {...rest}
    />
  );
};

export default WorkerAutocompleteField;

import { useNavigate } from 'react-router-dom';

import Action from '@/components/Action';
import Card from '@/components/Card';
import Stack from '@/components/Stack';
import { Heading, Subheading } from '@/components/Typography';

const RestrictedAccess = () => {
  const navigate = useNavigate();
  return (
    <Card
      changeStyle={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
      }}
    >
      <Card.Section>
        <Stack vertical style={{ alignItems: 'center' }}>
          <img
            src={require('../../../assets/img/restrict.png')}
            style={{ width: '240px' }}
          />
          <Heading css={{ marginTop: '30px' }}>Restricted Access</Heading>
          <Subheading>You do not have permission to view this page</Subheading>
          <Action.Button
            action={{
              a11yLabel: `Go to dashboard`,
              label: 'Go to dashboard',
              id: 'goto-dashboard-btn',
              onAction: () => {
                navigate('/');
              },
            }}
            appearance="primary"
            style={{ marginTop: '30px' }}
          />
        </Stack>
      </Card.Section>
    </Card>
  );
};

export default RestrictedAccess;

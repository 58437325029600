import { useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';

import DownloadIcon from '../DownloadIcon';

import { TableCell } from '@/elements/Table';
import { useListCustomersReportLazyQuery } from '@/graphql';
import { CustomerListReport } from '@/types/graphql';

interface HeaderType {
  label: string;
  key: keyof Omit<CustomerListReport, '__typename'>;
}

const filename = `CustomerTrendReport-${Date.now()}.csv`;

function CustomerList({ setError }) {
  const [csvData, setCsvData] = useState<CustomerListReport[] | null>(null);
  const csvInstance = useRef<any>();

  const [getCustomersReports, { loading }] = useListCustomersReportLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: ({ customerListReport }) => {
      setCsvData(customerListReport);
    },
    onError: (error) => setError({ message: error.message }),
  });

  const headers = useMemo<HeaderType[]>(
    () => [
      { label: 'Client URL', key: 'clientUrl' },
      { label: 'Client Id', key: 'customerId' },
      { label: 'Client Name', key: 'customerName' },
      { label: 'Account Id', key: 'accountId' },
      { label: 'Account Name', key: 'accountName' },
      { label: 'Account Rep Name', key: 'accountRepName' },
      { label: 'Account Age Days', key: 'accountAgeDays' },
      { label: 'Account Rep Email', key: 'accountRepEmail' },
      { label: 'Account Rep Phone', key: 'accountRepPhone' },
      { label: 'City', key: 'city' },
      { label: 'State', key: 'state' },
      { label: 'Zip', key: 'zip' },
      { label: 'Latest Order', key: 'latestOrder' },
      { label: 'Total Jobs', key: 'totalJobs' },
      { label: 'Order This Month', key: 'ordersThisMonth' },
      { label: 'Order Last Month', key: 'ordersLastMonth' },
      { label: 'Order Two Months Ago', key: 'ordersTwoMonthsAgo' },
      { label: 'Order Three Months Ago', key: 'ordersThreeMonthsAgo' },
      { label: 'Order Four Months Ago', key: 'ordersFourMonthsAgo' },
      { label: 'Order Five Months Ago', key: 'ordersFiveMonthsAgo' },
      { label: 'Order Six Months Ago', key: 'ordersSixMonthsAgo' },
    ],
    []
  );

  useEffect(() => {
    if (csvData && csvInstance.current && csvInstance.current.link) {
      csvInstance.current.link.click();
      setCsvData(null);
    }
  }, [csvData]);

  return (
    <>
      <TableCell id="client-trend-report-label">Client Trend Report</TableCell>
      <TableCell />
      <TableCell />
      <TableCell>
        <DownloadIcon
          id="download-client-trend-report"
          isLoading={loading}
          onClick={getCustomersReports}
        />
        {csvData && (
          <CSVLink
            ref={csvInstance}
            data={csvData}
            filename={filename}
            headers={headers}
          />
        )}
      </TableCell>
    </>
  );
}

export default CustomerList;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul.MuiList-root::-webkit-scrollbar {
  width: 5px;
}

ul.MuiList-root::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.2);
}

ul.MuiList-root::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}
`, "",{"version":3,"sources":["webpack://./src/components/ShiftTimePicker/index.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,oDAAoD;AACtD;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":["ul.MuiList-root::-webkit-scrollbar {\n  width: 5px;\n}\n\nul.MuiList-root::-webkit-scrollbar-track {\n  -webkit-box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.2);\n}\n\nul.MuiList-root::-webkit-scrollbar-thumb {\n  background-color: darkgrey;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

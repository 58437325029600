import { ApolloError } from '@apollo/client';
import { SubmitHelpers } from '@area2k/use-form';
import {
  faFire,
  faQuestionCircle,
  faUnlink,
} from '@fortawesome/free-solid-svg-icons';
import { GraphQLError } from 'graphql';

import { FormError } from '@/types';
import debug from '@/util/debug';

export type ErrorHandler = (error: GraphQLError) => void;
export type HandlerMap = Record<string, ErrorHandler>;

export const isApolloError = (err: any): err is ApolloError =>
  !!(err as ApolloError).graphQLErrors || !!(err as ApolloError).networkError;

export const hasGraphQLErrors = (err: ApolloError) =>
  err.graphQLErrors && err.graphQLErrors.length > 0;

export const handleGraphQLError = (
  error: ApolloError,
  handlers: HandlerMap
) => {
  const defaultHandler = (gqlError: GraphQLError) =>
    handlers.all && handlers.all(gqlError);

  if (error.networkError || !error.graphQLErrors) {
    handlers.all(error.message as unknown as GraphQLError);
    return;
  }

  error.graphQLErrors.forEach((gqlError) => {
    const { extensions } = gqlError;
    let handler = defaultHandler;

    if (extensions && extensions.code) {
      handler = handlers[extensions.code.toUpperCase()] || defaultHandler;
    }

    handler(gqlError);
  });
};

export const handleUncaughtError = (
  error: any,
  setFormError: SubmitHelpers['setFormError']
) => {
  debug.error('[handleUncaughtError]', error);

  if (error.networkError) {
    const apolloError = (error as ApolloError).networkError!;

    if (apolloError.message.endsWith('Failed to fetch')) {
      setFormError('fetchFailed', {
        icon: faUnlink,
        message: 'Unable to connect to the remote server.',
        title: 'Connection error',
        status: 'danger',
      });
    } else if (apolloError.name === 'ServerError') {
      setFormError('serverError', {
        icon: faFire,
        message: 'A server error has occurred. Please contact support.',
        title: 'Server error',
        status: 'danger',
      });
    } else {
      setFormError('networkError', {
        icon: faQuestionCircle,
        message: apolloError.message,
        status: 'danger',
      });
    }
  } else {
    setFormError('unknown', {
      icon: faFire,
      message:
        error.message ??
        'An unknown error has occurred. Please contact support.',
      title: error.message ? undefined : 'Unknown error',
      status: 'danger',
    });
  }
};

type FormErrorHandler = (err: GraphQLError) => FormError;
export type ErrorMap = { [code: string]: FormErrorHandler } & {
  all?: FormErrorHandler;
};

type HandleMutatationFormErrorArgs = {
  errorMap?: ErrorMap;
  setFormError: SubmitHelpers['setFormError'];
};

export const handleMutationFormError = (
  err: any,
  { errorMap, setFormError }: HandleMutatationFormErrorArgs,
  stringToReplace?: string
) => {
  const handlerMap: HandlerMap = {};

  if (errorMap) {
    Object.keys(errorMap).forEach((key) => {
      handlerMap[key] = (err) => {
        let error = errorMap[key](err);
        if (stringToReplace) {
          error = {
            ...error,
            message: error.message.replace('{0}', stringToReplace),
          };
        }
        setFormError(key, error);
      };
    });
  }

  if (isApolloError(err) && hasGraphQLErrors(err)) {
    handleGraphQLError(err, handlerMap);
  } else {
    handleUncaughtError(err, setFormError);
  }
};

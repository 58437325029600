import { Schedule } from '../../../context';

import Card from '@/components/Card';
import Stack from '@/components/Stack';
import TextStack from '@/components/TextStack';
import { Body, Small, Subheading } from '@/components/Typography';
import useAuth from '@/hooks/useAuth';
import { formatDaySimple } from '@/util/datetime';
import { centsToCurrency } from '@/util/number';

interface HolidayInfoProps {
  schedules: Schedule[];
  totalHours?: string;
}

const HolidayInfo = ({ schedules, totalHours }: HolidayInfoProps) => {
  const holidays = schedules.filter((sch) => sch.isHoliday);
  if (holidays.length === 0) return <></>;

  const { currentAdminIsCustomerAdmin } = useAuth();

  const dates = holidays.map((h) => formatDaySimple(h.dateRange.startDate));

  return (
    <Card.Section>
      <TextStack>
        <Stack justify="apart">
          <Subheading>Holiday Rates</Subheading>
          <Body>{Array.from(new Set(dates)).join(', ')}</Body>
        </Stack>
      </TextStack>
      <Stack vertical gap={12} style={{ marginTop: 12 }}>
        {!currentAdminIsCustomerAdmin && (
          <Stack justify="apart">
            <Small>Pay rate</Small>
            <Body>${centsToCurrency(holidays[0].payRate)}</Body>
          </Stack>
        )}
        <Stack justify="apart">
          <Small>Bill rate</Small>
          <Body>${centsToCurrency(holidays[0].costRate)}</Body>
        </Stack>
        {totalHours && (
          <Stack justify="apart">
            <Small>Total Hours</Small>
            <Body>{totalHours}</Body>
          </Stack>
        )}
      </Stack>
    </Card.Section>
  );
};

export default HolidayInfo;

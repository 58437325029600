import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { set } from 'date-fns';

export type MessageBarType = {
  type: 'SUCCESS' | 'ERROR' | 'INFORMATION';
  title: string;
  content: string;
  reloadLink?: boolean;
};

export enum ReloadInvoicePayrollStatusEnum {
  'AUTO',
  'MANUAL',
  'OFF',
}

interface pubnubSliceInterface {
  pubnubMessage: any;
  channels: string[];
  unreadChats: boolean;
  connectionStatus: boolean;
  messages: MessageBarType[];
  reloadInvoices: ReloadInvoicePayrollStatusEnum;
  reloadPayrolls: ReloadInvoicePayrollStatusEnum;
}

const initialState: pubnubSliceInterface = {
  pubnubMessage: {},
  channels: [],
  unreadChats: false,
  connectionStatus: true,
  reloadInvoices: ReloadInvoicePayrollStatusEnum.OFF,
  reloadPayrolls: ReloadInvoicePayrollStatusEnum.OFF,
  messages: [],
};

export const pubnubSlice = createSlice({
  name: 'pubnub',
  initialState,
  reducers: {
    setPubnubMessage: (state, action: PayloadAction<any>) => {
      state.pubnubMessage = action.payload;
    },
    setChannels: (state, action: PayloadAction<string[]>) => {
      state.channels = [...action.payload];
    },
    setUnreadChats: (state, action: PayloadAction<boolean>) => {
      state.unreadChats = action.payload;
    },
    setConnectionStatus: (state, action: PayloadAction<boolean>) => {
      state.connectionStatus = action.payload;
    },
    addMessage: (state, action: PayloadAction<MessageBarType>) => {
      if (
        action.payload.type !== 'INFORMATION' ||
        (action.payload.type === 'INFORMATION' &&
          state.messages.filter((x) => x.type === 'INFORMATION').length === 0)
      ) {
        state.messages.push(action.payload);
      }
    },
    updateMessages: (state, action: PayloadAction<MessageBarType[]>) => {
      state.messages = [...action.payload];
    },
    setReloadInvoices: (
      state,
      action: PayloadAction<ReloadInvoicePayrollStatusEnum>,
    ) => {
      state.reloadInvoices = action.payload;
    },
    setReloadPayrolls: (
      state,
      action: PayloadAction<ReloadInvoicePayrollStatusEnum>,
    ) => {
      state.reloadPayrolls = action.payload;
    },
  },
});

export const {
  setPubnubMessage,
  setChannels,
  setUnreadChats,
  setConnectionStatus,
  addMessage,
  updateMessages,
  setReloadInvoices,
  setReloadPayrolls,
} = pubnubSlice.actions;

export default pubnubSlice.reducer;

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useMemo } from 'react';

import Alert from '../Alert';

export type ErrorSchema = {
  type: string;
  title: string;
  message: string;
  condition: boolean;
  icon?: IconDefinition;
};
interface Props {
  schema: ErrorSchema[];
  setDisabledButton: (isDisabled: boolean) => void;
}

const CustomErrorsForm = ({ schema, setDisabledButton }: Props) => {
  const error = useMemo(() => schema.find((item) => item.condition), [schema]);

  useEffect(() => {
    setDisabledButton(Boolean(error));
  }, [error]);

  return error ? (
    <Alert
      css={{ marginTop: '1em' }}
      description={error.message}
      icon={error.icon ? error.icon : faDollarSign}
      status="warning"
      title={error.title}
    />
  ) : null;
};

export default CustomErrorsForm;

import { VariantProps, CSS } from '@stitches/react';
import { FC } from 'react';

import PopoverElement from '@/elements/Popover';
import useClickout from '@/hooks/useClickout';

export type PopoverVariants = Omit<
  VariantProps<typeof PopoverElement>,
  'status'
>;

export type Props = PopoverVariants & {
  as?: any;
  disableClickout?: boolean;
  id?: string;
  isOpen: boolean;
  onRequestClose: () => void;
  css?: CSS;
};

const Popover: FC<Props> = ({
  align = 'top',
  as = 'div',
  children,
  disableClickout = false,
  isOpen,
  id,
  justify = 'left',
  margin = 'none',
  onRequestClose,
  ...rest
}) => {
  const clickoutRef = useClickout<HTMLDivElement>(onRequestClose, {
    debugName: `Popover(${id || '<no id>'})`,
    isActive: !disableClickout && isOpen,
  });

  return (
    <PopoverElement
      ref={clickoutRef}
      {...rest}
      align={align}
      aria-hidden={!isOpen}
      as={as}
      id={id}
      justify={justify}
      margin={margin}
      status={isOpen ? 'open' : 'closed'}
    >
      {children}
    </PopoverElement>
  );
};

export default Popover;

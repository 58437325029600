import Card from '@/components/Card';
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout';
import Skeleton from '@/components/Skeleton';
import Stack from '@/components/Stack';

const PageSkeleton = () => (
  <Skeleton.Page>
    <Stack vertical gap={30}>
      <Card sectioned title="Details">
        <Skeleton.Body />
      </Card>
      <Card sectioned title="Items">
        <Skeleton.Body />
      </Card>
      <PrimarySecondaryLayout flipped>
        <PrimarySecondaryLayout.Primary>
          <Stack justify="end">
            <Card sectioned size="sm">
              <Skeleton.Body />
            </Card>
          </Stack>
        </PrimarySecondaryLayout.Primary>
      </PrimarySecondaryLayout>
      <Stack justify="end">
        <Skeleton.Action />
      </Stack>
    </Stack>
  </Skeleton.Page>
);

export default PageSkeleton;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkIn:before {
  content: 'Update Check In';
  color: #97a0af;
  position: absolute;
  background: #fff;
  width: 150px;
}
.checkIn:focus:before {
  width: 0;
  content: '';
}

.checkOut:before {
  content: 'Update Check Out';
  color: #97a0af;
  position: absolute;
  background: #fff;
  width: 150px;
}
.checkOut:focus:before {
  width: 0;
  content: '';
}
`, "",{"version":3,"sources":["webpack://./src/routes/Agency/JobOld/WorkerTable/BulkEditModal/index.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,QAAQ;EACR,WAAW;AACb;;AAEA;EACE,2BAA2B;EAC3B,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,QAAQ;EACR,WAAW;AACb","sourcesContent":[".checkIn:before {\n  content: 'Update Check In';\n  color: #97a0af;\n  position: absolute;\n  background: #fff;\n  width: 150px;\n}\n.checkIn:focus:before {\n  width: 0;\n  content: '';\n}\n\n.checkOut:before {\n  content: 'Update Check Out';\n  color: #97a0af;\n  position: absolute;\n  background: #fff;\n  width: 150px;\n}\n.checkOut:focus:before {\n  width: 0;\n  content: '';\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

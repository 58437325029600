import { Snackbar, Alert, AlertTitle } from '@mui/material';

import Option from '@/components/Option';

type Props = {
  checked: boolean;
  disabled: boolean;
  error?: string | null;
  onToggleChange: (obj: any) => void;
  setAutoApproveError: (err: any) => void;
};

const ToggleAutoApprove = ({
  checked,
  disabled,
  error,
  onToggleChange,
  setAutoApproveError,
}: Props) => {
  const green = '#45a735';

  const onChange = (value: boolean) => {
    onToggleChange(value);
  };
  return (
    <>
      <div style={{ display: 'flex', float: 'right' }}>
        <p style={{ color: green, margin: '6px', fontWeight: 500 }}>
          Finalize Automatically
        </p>
        <Option
          appearance="switch"
          checked={checked}
          disabled={disabled}
          id={'auto-approve'}
          label={''}
          type="checkbox"
          onChange={(ev) => onChange(ev.currentTarget.checked)}
        />
      </div>
      {error && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={3000}
          open={true}
          onClose={() => {
            setAutoApproveError(null);
          }}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            <AlertTitle>Error: {error}</AlertTitle>
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default ToggleAutoApprove;

import { useParams } from 'react-router-dom';

import { StyledPage } from '../SignUp/styles';

import StateValidationAccount from './StateValidationAccount';

import Card from '@/components/Card';
import GravyWorkLogo from '@/components/GravyWorkLogo';
import Stack from '@/components/Stack';

const ValidationAccount = () => {
  const { caseId } = useParams();

  return (
    <StyledPage size="xs">
      <Card>
        <Stack css={{ paddingTop: '20px' }} justify="center">
          <GravyWorkLogo />
        </Stack>

        <StateValidationAccount stateAccount={caseId} />
      </Card>
    </StyledPage>
  );
};

export default ValidationAccount;

import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import { validationSchema } from './schema';

import { SubmitErrorAlert } from '@/components/Alerts';
import type { SubmitErrorType } from '@/components/Alerts/SubmitErrorAlert/types';
import Button from '@/components/Button';
import Card from '@/components/Card';
import FormElement from '@/components/FormElement';
import MaskedInput from '@/components/MaskedInput';
import Modal from '@/components/Modal';
import TextArea from '@/components/TextArea';
import { Small } from '@/components/Typography';
import {
  useAddInvoiceItemMutation,
  useEditInvoiceItemMutation,
  GetInvoiceDetailsDocument,
} from '@/graphql';
import type { StripeInvoice, InvoiceItem } from '@/types/graphql';
import { centsToCurrency } from '@/util/number';

type Props = {
  invoice: StripeInvoice;
  invoiceItem?: InvoiceItem;
  hideModal: () => void;
};

interface InvoiceItemFieldValues {
  quantity: number;
  amount: string;
  description: string;
}

const getUnitPrice = (value: string): string => {
  const SEARCH_VALUE = /,/g;
  const replacedUnitPrice = value.replace(SEARCH_VALUE, '');
  const parsedUnitPrice = Number(replacedUnitPrice) * 100;

  return parsedUnitPrice.toFixed();
};

const InvoiceItemForm = ({ invoice, invoiceItem, hideModal }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    setError,
    clearErrors,
    getValues,
  } = useForm<InvoiceItemFieldValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      amount: invoiceItem ? invoiceItem.amount : '0',
      description: invoiceItem ? invoiceItem.shortDescription : '',
    },
  });

  const [submitError, setSubmitError] = useState<SubmitErrorType | null>(null);
  const [amountInput, setAmountInput] = useState(() =>
    centsToCurrency(Number(getValues('amount')))
  );
  const mutationOptions = {
    refetchQueries: [
      {
        query: GetInvoiceDetailsDocument,
        variables: {
          invoiceId: invoice.id,
        },
      },
    ],
  };
  const [addInvoiceItem, { loading: addInvoiceItemIsLoading }] =
    useAddInvoiceItemMutation(mutationOptions);
  const [editInvoiceItem, { loading: editInvoiceItemIsLoading }] =
    useEditInvoiceItemMutation(mutationOptions);

  const handleAmountChange = (inputValue: string) => {
    setAmountInput(inputValue);

    const AMOUNT_FIELD_NAME = 'amount';

    if (inputValue === '') {
      return setError(AMOUNT_FIELD_NAME, {
        type: 'required',
        message: 'Amount is a required field',
      });
    }

    const amount = getUnitPrice(inputValue);
    clearErrors(AMOUNT_FIELD_NAME);
    return setValue(AMOUNT_FIELD_NAME, amount);
  };

  const onSubmit: SubmitHandler<InvoiceItemFieldValues> = async ({
    amount,
    description,
  }) => {
    try {
      if (invoiceItem) {
        await editInvoiceItem({
          variables: {
            invoiceItemId: invoiceItem.id!,
            amount,
            description,
          },
        });
        return endSubmit();
      }

      await addInvoiceItem({
        variables: {
          invoiceId: invoice.id,
          description,
          amount,
        },
      });
      endSubmit();
    } catch (error) {
      if (error instanceof ApolloError) {
        if (error.networkError && error.networkError.result) {
          const { errors } = error.networkError.result;
          const submitError = errors[0] as SubmitErrorType;
          return setSubmitError(submitError);
        }

        return setSubmitError(error);
      }
    }
  };

  const endSubmit = () => {
    setSubmitError(null);
    return hideModal();
  };

  return (
    <Modal
      disableClickout
      size="sm"
      title={invoiceItem ? 'Edit Item' : 'Add Item'}
      wrapperBackground={true}
      onRequestClose={hideModal}
    >
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Card.Section>
          {submitError && (
            <SubmitErrorAlert description={submitError.message} />
          )}
          <FormElement htmlFor="amount" label="Amount">
            <MaskedInput
              normalizeZeros
              padFractionalZeros
              required
              signed
              id="amount"
              mask={Number}
              placeholder="Amount"
              radix="."
              scale={2}
              thousandsSeparator=","
              value={amountInput}
              onAccept={handleAmountChange}
            />
            {errors.amount && (
              <Small color="danger">{errors.amount.message}</Small>
            )}
          </FormElement>
          <FormElement htmlFor="description" label="Description">
            <TextArea
              id="description"
              placeholder="Description"
              {...register('description')}
            />
            {errors.description && (
              <Small color="danger">{errors.description.message}</Small>
            )}
          </FormElement>
        </Card.Section>
        <Card.Footer>
          <Button
            a11yLabel="Submit form"
            css={{ width: '100%' }}
            disabled={!isValid}
            isLoading={addInvoiceItemIsLoading || editInvoiceItemIsLoading}
            label="Save"
            type="submit"
          />
        </Card.Footer>
      </form>
    </Modal>
  );
};

export default InvoiceItemForm;

import styled from '@/styles';
import { ShimmerAnimation } from '@/styles/animation';

const Shimmer = styled('div', {
  $$bgColor: '$colors$neutralLighter',

  width: '100%',

  animation: `${ShimmerAnimation} 800ms linear infinite alternate`,
  backgroundColor: '$$bgColor',
  borderRadius: '$lg',
});

Shimmer.displayName = 'stitches(Shimmer)';

export default Shimmer;

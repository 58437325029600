import Avatar from '@/components/Avatar';
import Card from '@/components/Card';
import Stack from '@/components/Stack';
import TextStack from '@/components/TextStack';
import { Small } from '@/components/Typography';
import Link from '@/elements/Link';
import useAuth from '@/hooks/useAuth';
import ROUTES from '@/routes/routes';
import type { GetAccountQuery } from '@/types/graphql';

interface DefaultContactSectionProps {
  account: GetAccountQuery['account'];
}

const DefaultContactSection = ({ account }: DefaultContactSectionProps) => {
  const { currentAdminIsCustomerAdmin } = useAuth();
  const clientDestinationPath = currentAdminIsCustomerAdmin
    ? `../../../${ROUTES.myTeam}/${account.defaultContact.id}`
    : `../../admin/${account.defaultContact.id}`;

  return (
    <Card.Section>
      <Stack gap={20}>
        <Avatar
          firstName={account.defaultContact.user.firstName}
          size="md"
          src={account.defaultContact?.avatarUrl}
        />
        <TextStack>
          <Small weight="medium">
            <Link to={clientDestinationPath}>
              {account.defaultContact.user.firstName}{' '}
              {account.defaultContact.user.lastName}
            </Link>
          </Small>
          <Small>{account.defaultContact.user.email}</Small>
          <Small>{account.defaultContact.user.phoneNumber}</Small>
        </TextStack>
      </Stack>
    </Card.Section>
  );
};

export default DefaultContactSection;

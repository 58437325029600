import styled from '@/styles';

const TableHeading = styled('th', {
  padding: '14px 12px 12px',
  color: '$textLighter',

  fontSize: '$sm',
  fontWeight: '$medium',

  verticalAlign: 'bottom',

  '&:first-child': {
    paddingLeft: '20px',
  },

  '&:last-child': {
    paddingRight: '20px',
  },
  variants: {
    size: {
      default: {
        padding: '14px 12px 12px',
      },
      md: {
        padding: '20px 12px 20px 12px',
        '&:first-child': {
          paddingLeft: '43px',
        },
        '&:last-child': {
          paddingRight: '40px',
        },
      },
    },

    weigth: {
      medium: {
        fontWeight: '$medium',
      },
      semi: {
        fontWeight: '$semi',
      },
      bold: {
        fontWeight: '$bold',
      },
    },

    align: {
      default: {
        textAlign: 'left',
      },
      center: {
        textAlign: 'center',
      },
    },
    touchable: {
      false: {},
      true: {
        cursor: 'pointer',
      },
    },
  },
  defaultVariants: {
    size: 'default',
    touchable: 'false',
    align: 'default',
    weigth: 'medium',
  },

  '@phoneOnly': {
    whiteSpace: 'nowrap',
  },
});

export default TableHeading;

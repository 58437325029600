import { Stack } from '@mui/material';
import { useRef, useState } from 'react';

import { Container, OverlaySkillCountWrapper } from './styles';

import Avatar from '@/components/Avatar';
import Badge from '@/components/Badge';
import { Subheading } from '@/components/Typography';
import Text from '@/elements/Text';
import { JobWorkerItemFragment } from '@/types/graphql';
import './index.css';

type WorkerSkillsSectionProps = {
  workerSkills: JobWorkerItemFragment['worker']['workerSkills'];
};

const WorkerSkillsSection = ({ workerSkills }: WorkerSkillsSectionProps) => {
  const sliderRef = useRef<any>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const startDrag = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const endDrag = () => {
    setIsDragging(false);
  };

  const drag = (e) => {
    if (!isDragging || !sliderRef?.current) return;
    e.preventDefault();
    const x = e.pageX - sliderRef?.current?.offsetLeft;
    const walk = (x - startX) * 2; // The multiplier can adjust the scroll speed
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };
  return (
    <>
      {workerSkills.length > 0 && (
        <>
          <Subheading
            css={{
              fontSize: '20px',
              padding: '14px 20px 10px 20px',
            }}
            weight="semibold"
            width="100%"
          >
            Skills
          </Subheading>
          <Stack marginBottom="10px" marginTop="10px">
            <Stack
              ref={sliderRef}
              className="worker-profile-dialog-skills-section"
              flexDirection="row"
              gap="28px"
              px="30px"
              width="100%"
              onMouseDown={startDrag}
              onMouseLeave={endDrag}
              onMouseMove={drag}
              onMouseUp={endDrag}
            >
              {workerSkills.map((skill, idx) => (
                <Stack key={idx} gap="10px">
                  <Container>
                    <Avatar
                      rounded
                      css={{
                        width: '80px',
                        height: '80px',
                        border: '2px solid #45A735',
                      }}
                      data-testid="avatar"
                      imageStyles={{
                        userselect: 'none',
                        pointerEvents: 'none',
                      }}
                      src={skill?.skill.imageUrl!}
                    />
                    {skill?.jobsWorked !== 0 && (
                      <OverlaySkillCountWrapper>
                        <Badge
                          css={{
                            border: '2px solid white',
                            padding: '2px 10px 2px 10px',
                          }}
                          data-testid="badge"
                          label={
                            skill?.jobsWorked
                              ? skill.jobsWorked > 9999
                                ? '9999+'
                                : skill.jobsWorked.toString()
                              : ''
                          }
                          size="md"
                          status="themeDefault"
                        />
                      </OverlaySkillCountWrapper>
                    )}
                  </Container>
                  <Text
                    css={{
                      textAlign: 'center',
                      color: '#262626',
                      width: '100px',
                      overflowWrap: 'break-word',
                      lineHeight: '1.4',
                      right: '9px',
                    }}
                    size="md"
                  >
                    {skill?.skill.name!}
                  </Text>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </>
      )}
    </>
  );
};

export default WorkerSkillsSection;

import { parseISO, differenceInMinutes, isValid } from 'date-fns';

import { minutesToHours } from './convertion';
import { calculateTotalHours } from './datetime';
import { pluralizeNumber, toFixedWithoutZeros } from './number';

import {
  ACCEPTANCE_STATUSES_TO_HIRED_WORKERS,
  JobWorkerStatusFilterEnumType,
} from '@/constants/job';
import { JobStatus, getRoundOff } from '@/routes/Agency/Job/util';
import { Prettify } from '@/types';
import {
  GetOrderQuery,
  Job,
  JobApprovalStatusEnum,
  JobStatusEnum,
  JobWorkerStatusEnum,
  OrderStatusEnum,
  ListOrdersQuery,
  Scalars,
  Shift,
  JobWorkerItemFragment,
} from '@/types/graphql';

type OrderJobs = Prettify<
  ListOrdersQuery['agency']['orders']['items'][0]['jobs']
>;

type OrderJob = OrderJobs[0];

type JobItem = Pick<Job, 'payRate' | 'quantity' | 'shifts' | 'costRate'>;

export const isJobArray = (job: unknown[]): job is Job[] =>
  (job as Job[])[0].__typename === 'Job';

export type OrderItem = GetOrderQuery['order'];

type CostOfJob = {
  totalEstimate: number;
  requiredHours: number;
  totalHours: number;
};

export const costOfJob = (job: JobItem): CostOfJob => {
  const billRate = job.costRate;
  const quantity = job.quantity;
  const numberOfShifts = job.shifts.length;

  const startHour = parseISO(job.shifts[0].startAt);
  const endHour = parseISO(job.shifts[0].endAt);

  const minsPerShift = differenceInMinutes(endHour, startHour);
  const hoursPerShift =
    minsPerShift < 0 ? 24 + minsPerShift / 60 : minsPerShift / 60;

  const totalEstimate = Math.round(
    billRate * quantity * numberOfShifts * hoursPerShift
  );

  return {
    totalEstimate,
    requiredHours: hoursPerShift * numberOfShifts,
    totalHours: hoursPerShift * quantity * numberOfShifts,
  };
};

export const calculateTotalEstimateByJobs = (jobs: OrderItem['jobs']) =>
  jobs.reduce((acc, el) => acc + costOfJob(el).totalEstimate, 0);

export const calculateTotalShiftsByJobs = (jobs: OrderItem['jobs']) =>
  jobs.reduce((acc, el) => acc + el.shifts.length, 0);

export const calculateTotalHoursByOrder = (jobs: OrderItem['jobs']) =>
  jobs.reduce(
    (acc, jobElement) => acc + calculateTotalHours({ job: jobElement }),
    0
  );

export const sortByDate = (jobs: unknown[]): unknown => {
  if (jobs.length > 0 && isJobArray(jobs)) {
    const sortedJobs = jobs.sort(
      (a, b) =>
        new Date(b.firstShiftStartAt).getTime() -
        new Date(a.firstShiftStartAt).getTime()
    );
    return sortedJobs;
  }
  return [];
};

export const sortDroppedWorkersByDropDate = (
  jobWorkers: JobWorkerItemFragment[]
): JobWorkerItemFragment[] => {
  if (jobWorkers.length > 0) {
    const sortedDroppedWorkers = jobWorkers.sort((a, b) => {
      return (
        new Date(b.droppedAt!).getTime() - new Date(a.droppedAt!).getTime()
      );
    });
    return sortedDroppedWorkers;
  }
  return [];
};

export const approvalStatus = (jobs: any[]) => {
  const formatJobsStatus = jobs.flatMap((job: any) =>
    job.timesheets.length < 1
      ? 'N/A'
      : job.timesheets.map((timesheet) => timesheet.status)
  );

  const deleteDuplicated = [...new Set(formatJobsStatus)];

  if (deleteDuplicated.length === 1) {
    return {
      'N/A': 'N/A',
      PENDING: 'Needs approval',
      APPROVED: 'All Approved',
      IN_PROGRESS: 'On working',
      REJECTED: 'Rejected',
    }[deleteDuplicated[0]];
  }
  if (deleteDuplicated.includes('PENDING')) {
    return 'Needs approval';
  }
  if (deleteDuplicated.includes('IN_PROGRESS')) {
    return 'On working';
  }
  if (deleteDuplicated.includes('APPROVED')) {
    return 'All Approved';
  }
  if (deleteDuplicated.includes('REJECTED')) {
    return 'Rejected';
  }
  return 'N/A';
};

export const approvalStatusResolver = (
  status: OrderStatusEnum | JobApprovalStatusEnum
): { label: Scalars['String']; color: Scalars['String'] } =>
  ({
    [OrderStatusEnum.PENDING]: { label: 'Needs Approval', color: 'warning' },
    [OrderStatusEnum.APPROVED]: { label: 'All Approved', color: 'theme' },
    [OrderStatusEnum.IN_PROGRESS]: { label: 'Checked In', color: 'success' },
    [OrderStatusEnum.REJECTED]: { label: 'Rejected', color: 'danger' },
    [OrderStatusEnum.NO_TIMESHEET]: {
      label: 'No Timesheet',
      color: 'warning',
    },

    [OrderStatusEnum.NO_SHOW]: {
      label: 'No Show',
      color: 'warning',
    },

    [OrderStatusEnum.TIMESHEET_UNFINISHED]: {
      label: 'Timesheet Unfinished',
      color: 'warning',
    },
  }[status] || { label: 'N/A', color: 'lighter' });

export const jobWorkerStatusResolver = (
  status: JobWorkerStatusFilterEnumType
): { label: string; color: string } =>
  ({
    [JobWorkerStatusEnum.APPROVED]: {
      label: 'Approved',
      color: 'success',
    },
    [JobWorkerStatusEnum.COMPLETED]: {
      label: 'Completed',
      color: 'success',
    },
    [JobWorkerStatusEnum.DISMISSED]: {
      label: 'Removed',
      color: 'danger',
    },
    [JobWorkerStatusEnum.DROPPED]: { label: 'Dropped', color: 'danger' },
    [JobWorkerStatusEnum.EXPIRED]: { label: 'Expired', color: 'danger' },
    [JobWorkerStatusEnum.HIRED]: { label: 'Hired', color: 'theme' },
    [JobWorkerStatusEnum.IN_PROGRESS]: {
      label: 'In Progress',
      color: 'warning',
    },
    [JobWorkerStatusEnum.PAID]: {
      label: 'Paid',
      color: 'theme',
    },
  }[status] || { label: 'N/A', color: 'lighter' });

export const jobStatusResolver = (
  status: JobStatusEnum
): { label: string; color: string } =>
  ({
    [JobStatusEnum.COMPLETED]: { label: 'Completed', color: 'theme' },
    [JobStatusEnum.UPCOMING]: { label: 'Upcoming', color: 'success' },
    [JobStatusEnum.IN_PROGRESS]: { label: 'In Progress', color: 'warning' },
    [JobStatusEnum.CANCELLED]: { label: 'Cancelled', color: 'danger' },
  }[status] || { label: 'N/A', color: 'lighter' });

const ACCEPTANCE_STATUSES_TO_ACTIVE_JOB = [
  JobStatus.UPCOMING,
  JobStatus.IN_PROGRESS,
];

// Job detail
export const checkIfJobIsActiveByStatus = (jobStatus: JobStatus) =>
  ACCEPTANCE_STATUSES_TO_ACTIVE_JOB.includes(jobStatus);

export const getDroppedWorkersByJobWorkers = (
  jobWorkers: JobWorkerItemFragment[]
) => jobWorkers.filter(checkIfJobWorkerStatusIsDropped);

export const getDroppedWorkersByJobWorkersSortedByDropDate = (
  jobWorkers: JobWorkerItemFragment[]
) => jobWorkers.filter(checkIfJobWorkerStatusIsDropped);

export const checkIfJobWorkerStatusIsDropped = (
  jobWorker: JobWorkerItemFragment
) => jobWorker.status === JobWorkerStatusEnum.DROPPED;

export const getRemovedWorkersByJobWorkers = (
  jobWorkers: JobWorkerItemFragment[]
) => jobWorkers.filter(checkIfJobWorkerStatusIsDismissed);

export const checkIfJobWorkerStatusIsDismissed = (
  jobWorker: JobWorkerItemFragment
) => jobWorker.status === JobWorkerStatusEnum.DISMISSED;

export const getHiredWorkersByJobWorkers = (
  jobWorkers: JobWorkerItemFragment[]
) =>
  jobWorkers.filter((jobWorker) =>
    ACCEPTANCE_STATUSES_TO_HIRED_WORKERS.includes(jobWorker.status)
  );

export const getFilledJobsCount = (jobs: OrderJobs) => {
  return jobs.filter((job) => job.filled).length;
};

export const getCancelledJobsCount = (jobs: OrderJobs) => {
  return jobs.filter((job) => job.cancelled).length;
};

export const getSkillsImages = (jobs: OrderJobs) => {
  return jobs.reduce((acc: string[], job: OrderJob) => {
    if (job.skill.imageUrl) {
      if (acc.includes(job.skill.imageUrl)) {
        return acc;
      }
      return [...acc, job.skill.imageUrl];
    }
    return acc;
  }, []);
};

export const sortShifts = (shiftA: Shift, shiftB: Shift) => {
  if (shiftA.startAt < shiftB.startAt) {
    return -1;
  } else if (shiftA.startAt > shiftB.startAt) {
    return 1;
  } else {
    return 0;
  }
};

export const getDateRangeInMinutes = (range: Shift) => {
  if (!range) {
    throw new Error('Invalid range');
  }

  const parsedStartAt = parseISO(range.startAt);
  const parsedEndAt = parseISO(range.endAt);

  if (!isValid(parsedStartAt)) {
    throw new RangeError('Invalid startAt');
  }
  if (!isValid(parsedEndAt)) {
    throw new RangeError('Invalid endAt');
  }

  return differenceInMinutes(parsedEndAt, parsedStartAt);
};

export const calculateTotalHoursInShift = (
  shift: Shift,
  showFixedDecimalNoRoundOff = false
) => {
  if (!shift) {
    throw new Error('Invalid shift');
  }

  const shiftRangesInMinutes = getDateRangeInMinutes(shift);
  const shiftRangesInHours = minutesToHours(shiftRangesInMinutes);
  const fixedHours = toFixedWithoutZeros(shiftRangesInHours, 1);
  const fixedHoursNoRoundOff = getRoundOff(shiftRangesInHours);

  return Number(showFixedDecimalNoRoundOff ? fixedHoursNoRoundOff : fixedHours);
};

export const getTotalHoursShiftLabel = (totalHours: number) => {
  const pluralizedTotalHours = pluralizeNumber(totalHours, 'hour', 'hours');

  return `${totalHours} ${pluralizedTotalHours ?? ''}`;
};

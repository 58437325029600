import { makeVar } from '@apollo/client';

import {
  CurrentAgencyFragment,
  CurrentTenantAdminFragment,
  CurrentCustomerAdminFragment,
  CurrentTenantFragment,
} from '@/types/graphql';
import client from '@/util/apollo/client';

export const currentTenantVar = makeVar<CurrentTenantFragment | null>(null);
export const currentAdminVar = makeVar<
  CurrentTenantAdminFragment | CurrentCustomerAdminFragment | null
>(null);
export const currentAgencyVar = makeVar<CurrentAgencyFragment | null>(null);
export const activeRegionStatesVar = makeVar<string[] | null>(null);

export const clearCache = async () => {
  currentAdminVar(null);
  currentAgencyVar(null);

  await client.cache.reset();
};

import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import { Scalars } from '@/types/graphql';

type ProcessingPaymentIntentModalProps = {
  hideModal: () => Scalars['Void'];
};

const ProcessingPaymentIntentModal = ({
  hideModal,
}: ProcessingPaymentIntentModalProps) => {
  return (
    <Modal
      disableClickout
      size="xs"
      title="Payment Status"
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Alert
          description="If you have any questions or concerns, please contact GravyWork support."
          icon={faCheckCircle}
          status="success"
          title="Your ACH payment is in process and will be completed soon."
          onClick={hideModal}
        />
      </Card.Section>
      <Card.Footer justify="end">
        <Button a11yLabel="Close modal" label="Close" onClick={hideModal} />
      </Card.Footer>
    </Modal>
  );
};

export default ProcessingPaymentIntentModal;

import { useReactiveVar } from '@apollo/client';
import { SubmitHelpers } from '@area2k/use-form';
import { Dispatch, SetStateAction, useCallback } from 'react';

import { MemberInvited } from '../..';

import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import Form from '@/form';
import FormColumns from '@/form/FormColumns';
import MaskedInputField from '@/form/MaskedInputField';
import TextField from '@/form/TextField';
import { useInviteAdminMutation } from '@/graphql';
import { currentAdminVar } from '@/util/apollo/cache';

type ModalProps = {
  onHide: () => void;
  setMemberWasInvited: Dispatch<SetStateAction<MemberInvited>>;
};

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
};

const AddNewMemberModal = ({ onHide, setMemberWasInvited }: ModalProps) => {
  const initialValues: FormValues = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  };

  const currentAdmin = useReactiveVar(currentAdminVar);

  const [inviteNewMember, { loading }] = useInviteAdminMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(currentAdmin!.customer!),
        fields: {
          admins() {},
        },
      });
    },
  });

  const handleSubmit = useCallback(
    async (values: FormValues, helpers: SubmitHelpers) => {
      try {
        const result = await inviteNewMember({
          variables: {
            ...values,
            customerId: currentAdmin!.customer!.id,
            password: null,
          },
        });

        if (result.data) {
          const { id, firstName, lastName } =
            result.data.customerInviteAdmin.customerAdmin.user;
          setMemberWasInvited({
            invited: true,
            data: { id, firstName, lastName },
          });
        }

        onHide();
      } catch (err: any) {
        helpers.setFormError('errorKey', {
          message: err.message,
        });
      }
    },
    []
  );

  return (
    <Modal disableClickout size="xs" title="New Member" onRequestClose={onHide}>
      <Card.Section>
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <FormColumns layout="double">
            <TextField
              autoFocus
              required
              fieldId="firstName"
              label="First Name"
              placeholder="First Name"
            />
            <TextField
              required
              fieldId="lastName"
              label="Last Name"
              placeholder="Last Name"
            />
          </FormColumns>

          <TextField
            required
            fieldId="email"
            label="Email"
            placeholder="Email"
          />

          <MaskedInputField
            fieldId="phoneNumber"
            incompletemessage="Must be a valid phone number"
            label="Phone Number"
            mask="(000) 000-0000"
            placeholder="(555) 555-5555"
            type="tel"
          />

          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              isLoading={loading}
              label="Send Invite"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  );
};

export default AddNewMemberModal;

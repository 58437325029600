import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { Body } from '@/components/Typography';
import { GetCustomerDocument, useDeleteRateMutation } from '@/graphql';
import { GetCustomerQuery, Rate } from '@/types/graphql';

type Props = {
  customerId: GetCustomerQuery['customer']['id'];
  rateId: Rate['id'];
  hideModal: () => void;
};

const ConfirmDeleteRate = ({ customerId, rateId, hideModal }: Props) => {
  const [error, setError] = useState<boolean>(false);

  const [deleteRate, { loading: isLoading }] = useDeleteRateMutation({
    refetchQueries: [{ query: GetCustomerDocument, variables: { customerId } }],
  });

  const handleDeleteRate = async () => {
    try {
      await deleteRate({
        variables: {
          rateId,
        },
      });
      hideModal();
    } catch (err) {
      setError(true);
    }
  };

  return (
    <Modal
      disableClickout
      size="sm"
      title="Are you sure you want to delete this rate?"
      onRequestClose={hideModal}
    >
      <Card.Section>
        {error && (
          <Alert
            description="Something went wrong, please contact support"
            icon={faExclamationTriangle}
            status="danger"
            title="An error has occurred"
          />
        )}
        <Body>
          {`This rate will be removed from the customer's profile. Please add a
          new rate if this skill needs to be available.`}
        </Body>
        <Stack css={{ marginTop: '10px' }} gap={10} justify="end">
          <Button
            a11yLabel="deny"
            appearance="outline"
            label="Cancel"
            status="danger"
            type="button"
            onClick={hideModal}
          />

          <Button
            a11yLabel="accept"
            isLoading={isLoading}
            label="Accept"
            type="submit"
            onClick={handleDeleteRate}
          />
        </Stack>
      </Card.Section>
    </Modal>
  );
};

export default ConfirmDeleteRate;

import {
  RenderWorkerInfo,
  getHiredButton,
  getInvitedButton,
} from './StaffingUtils';

import Stack from '@/components/Stack';
import { TableRow, TableCell } from '@/elements/Table';
import Text from '@/elements/Text';

type Mode = 'add' | 'remove';
type WorkerProfileRowMobileViewProps = {
  worker: Record<string, any>;
  isClientAdmin: boolean;
  hireButtonClick: (worker: Record<string, any>, mode?: Mode) => void;
  inviteButtonClick: (worker: Record<string, any>, mode?: Mode) => void;
  isHiredButton?: boolean;
  isInvitedButton?: boolean;
  buttonsDisableCheck?: boolean;
  isLTA: boolean;
  skillName: string;
  inviteButtonDisable?: boolean;
};

const WorkerProfileRowMobileView = ({
  worker,
  isClientAdmin,
  hireButtonClick,
  inviteButtonClick,
  isHiredButton,
  isInvitedButton,
  buttonsDisableCheck,
  inviteButtonDisable,
  isLTA,
  skillName,
}: WorkerProfileRowMobileViewProps) => {
  return (
    <>
      <TableRow>
        <TableCell css={{ paddingLeft: '0px !important' }}>
          <RenderWorkerInfo isClientAdmin={isClientAdmin} workerInfo={worker} />

          <Stack vertical css={{ marginTop: 20, alignItems: 'center' }}>
            <Text
              css={{ color: '#7A7A7A', textAlign: 'center' }}
              lineHeightProp={'small'}
            >
              <span style={{ fontWeight: 500, color: '#091E42' }}>
                {worker?.selectedSkillShift}
              </span>
              {` ${skillName} Shifts | `}
              <span style={{ fontWeight: 500, color: '#091E42' }}>
                {worker?.shiftsWith}
              </span>
              {` Shifts With You`}
            </Text>
          </Stack>

          <Stack css={{ flexDirection: 'row', marginTop: 30 }} justify="apart">
            {worker.hireable && (!isClientAdmin || isLTA)
              ? getHiredButton({
                  isHired: isHiredButton,
                  disable: buttonsDisableCheck,
                  handleClick: (mode) => hireButtonClick(worker, mode),
                  phoneOnly: true,
                })
              : null}
            {worker.invitable &&
              !isLTA &&
              getInvitedButton({
                isInvited: isInvitedButton,
                disable: isHiredButton || inviteButtonDisable,
                handleClick: (mode) => inviteButtonClick(worker, mode),
                phoneOnly: true,
              })}
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
};

export default WorkerProfileRowMobileView;

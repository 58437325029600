import Action from './Action';
import Body, { Props as BodyProps } from './Body';
import CommentsList, { Props as CommentsListProps } from './Comments';
import Display from './Display';
import Page, { Props as PageProps } from './Page';
import TagList, { Props as TagListProps } from './TagList';

export type { BodyProps, PageProps, TagListProps, CommentsListProps };

export default { Action, Body, Display, Page, TagList, CommentsList };

import { createSvgIcon } from '@mui/material';

const AccountDollarIcon = createSvgIcon(
  <svg
    fill="none"
    height="14"
    viewBox="0 0 13 14"
    width="13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_12844_111861)">
      <path
        d="M6.5 1.04199V12.9587"
        stroke="#44A735"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M9.20833 3.20801H5.14583C4.64303 3.20801 4.16081 3.40775 3.80528 3.76328C3.44974 4.11882 3.25 4.60104 3.25 5.10384C3.25 5.60665 3.44974 6.08886 3.80528 6.4444C4.16081 6.79994 4.64303 6.99968 5.14583 6.99968H7.85417C8.35697 6.99968 8.83919 7.19941 9.19472 7.55495C9.55026 7.91049 9.75 8.3927 9.75 8.89551C9.75 9.39831 9.55026 9.88053 9.19472 10.2361C8.83919 10.5916 8.35697 10.7913 7.85417 10.7913H3.25"
        stroke="#44A735"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
    <defs>
      <clipPath id="clip0_12844_111861">
        <rect
          fill="white"
          height="13"
          transform="translate(0 0.5)"
          width="13"
        />
      </clipPath>
    </defs>
  </svg>,
  'AccountDollarIcon'
);
export default AccountDollarIcon;

import Popover from '@mui/material/Popover';
import { format } from 'date-fns';

import { Body } from '@/components/Typography';
import Text from '@/elements/Text';

const CustomTooltipJob = ({
  open,
  anchorEl,
  handlePopoverClose,
  isDropped,
  isNoShow,
  reason,
  eventDate,
  eventDoneBy,
}: any) => {
  const rowStyle = {
    display: 'block',
    padding: '0px 12px 0px 16px',
  };
  const cellStyle = {
    color: 'black',
    lineHeight: 'normal',
    letterSpacing: '.17px',
    padding: '0px 0px',
  };
  const boldTextStyle = {
    color: 'black',
    lineHeight: 'normal',
    letterSpacing: '.17px',
    padding: '0px 4px 0px 0px',
    fontSize: 12,
    fontWeight: 700,
  };
  const textStyle = {
    color: 'black',
    lineHeight: 'normal',
    letterSpacing: '.17px',
    fontSize: 12,
    fontWeight: 400,
    width: '150px',
  };

  const getJobTooltipFormattedDate = (dateStr: string) => {
    if (dateStr === null || dateStr === undefined) {
      return '';
    }
    return format(Date.parse(dateStr), 'MMM do - HH:mma');
  };

  return (
    <Popover
      disableRestoreFocus
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      slotProps={{
        paper: {
          style: {
            maxWidth: '269px',
            width: '269px',
            boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.12)',
            border: '1px solid #E2E2E2',
          },
          className: 'afterArrow',
        },
      }}
      sx={{
        pointerEvents: 'none',
        left: '275px !important',
        top: '-50px',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={handlePopoverClose}
    >
      <Body
        css={{
          background: '#DFF3D8',
          padding: '12px 16px',
        }}
        weight={'bold'}
      >
        Detail
      </Body>
      <div style={{ padding: '12px 0px 20px 0px' }}>
        {!isDropped && (
          <div style={rowStyle}>
            <div style={cellStyle}>
              <Text css={boldTextStyle}>
                {isNoShow ? 'Marked By:' : 'Removed By:'}
              </Text>
              <Text css={textStyle} wordWrap={'breakWord'}>
                {eventDoneBy}
              </Text>
            </div>
          </div>
        )}
        <div style={rowStyle}>
          <div style={cellStyle}>
            <Text css={boldTextStyle}>{'Date & Time:'}</Text>
            <Text css={textStyle} wordWrap={'breakWord'}>
              {getJobTooltipFormattedDate(eventDate)}
            </Text>
          </div>
        </div>
        {!isNoShow && (
          <div style={rowStyle}>
            <div style={cellStyle}>
              <Text css={boldTextStyle}>{'Reason:'}</Text>
              <Text css={textStyle} wordWrap={'breakWord'}>
                {reason}
              </Text>
            </div>
          </div>
        )}
      </div>
    </Popover>
  );
};

export default CustomTooltipJob;

import { useState, useEffect } from 'react';

import { useOrderState } from '../../../context';

import AddressSectionV3 from './AddressSection/AddressSectionV3';
import CreateContactForm from './CreateContactForm';
import SkillCategorySelectField from './SkillCategorySelectField';
import SkillSelectFieldV3 from './SkillSelectFieldV3';

import { OnChangeFirstStepForm, FirstStepFormValues } from './';

import LoadingState from '@/components/LoadingState';
import Stack from '@/components/Stack';
import { V3Endpoints } from '@/constants/urls';
import { useFetchNearestRatesLazyQuery } from '@/graphql';
import useMediaQuery from '@/hooks/useMediaQuery';
import axiosClient from '@/util/axios/axiosClient';

interface Props {
  formValues: FirstStepFormValues;
  onChange: OnChangeFirstStepForm;
  continueButtonIsDisabled: boolean;
}

const SkillSelectionV3 = ({ formValues, onChange }: Props) => {
  const { billing } = useOrderState();
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<any[]>([]);

  const [fetchNearestRates, { data: nearestRates, error }] =
    useFetchNearestRatesLazyQuery({ fetchPolicy: 'no-cache' });

  const fetchSkills = async () => {
    setLoading(true);
    try {
      const res = await axiosClient.get(
        V3Endpoints.GET_ACCOUNT_SKILLS.replace(
          '{account_id}',
          `${billing?.account.id}`
        )
      );
      setOptions(res.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchSkills();
  }, [billing]);

  useEffect(() => {
    if (formValues.skill && formValues.address) {
      fetchNearestRates({
        variables: {
          accountId: billing?.account.id!,
          skillId: formValues.skill.id,
          addressId: formValues.address.id,
        },
      });
    }
  }, [formValues.skill, formValues.address?.id]);

  useEffect(() => {
    if (nearestRates) {
      const { defaultRates, rates } = nearestRates.fetchRates;
      if (defaultRates && defaultRates.length > 0) {
        const _defaultRate = defaultRates[0];
        onChange({ value: parseInt(_defaultRate.id) }, 'rateId');
        onChange(
          {
            value: {
              ...formValues.address,
              rateQuotes: [
                {
                  id: _defaultRate.id,
                  skill: { ...formValues.skill },
                  account: { ...billing?.account },
                  cost: _defaultRate.costRate,
                  pay: _defaultRate.payRate,
                },
              ],
            },
          },
          'address'
        );
      } else if (rates && rates.length > 0) {
        const _rate = rates[0];
        onChange({ value: parseInt(_rate.id) }, 'rateId');
        onChange(
          {
            value: {
              ...formValues.address,
              rateQuotes: [
                {
                  id: _rate.id,
                  skill: { ...formValues.skill },
                  account: { ...billing?.account },
                  cost: _rate.cost,
                  pay: _rate.pay,
                },
              ],
            },
          },
          'address'
        );
      }
    }
  }, [nearestRates]);

  if (loading) {
    return (
      <Stack align="center" justify="center">
        <LoadingState overlayColor="white" text="Loading skills categories" />
      </Stack>
    );
  }

  const getContactForm = (hasAddressSelected) => {
    if (phoneOnly) {
      if (hasAddressSelected) {
        return <CreateContactForm onChange={onChange} />;
      } else {
        return <></>;
      }
    } else {
      return <CreateContactForm onChange={onChange} />;
    }
  };

  return (
    <Stack vertical align="center" gap={24}>
      <SkillCategorySelectField
        callback={(fieldContext) => onChange(fieldContext, 'skillCategory')}
        options={options}
      />
      <SkillSelectFieldV3
        callback={(fieldContext) => onChange(fieldContext, 'skill')}
      />
      <AddressSectionV3
        address={formValues.address}
        hasInstructions={formValues.addressInstructions !== ''}
        rateError={error !== null && error !== undefined}
        skill={formValues.skill}
        onChange={(context, id) => {
          onChange(context, id);
        }}
      />
      {getContactForm(
        formValues.address?.addressLine1 &&
          formValues.address?.addressLine1.length > 0
      )}
    </Stack>
  );
};

export default SkillSelectionV3;

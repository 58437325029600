import { SubmitHelpers } from '@area2k/use-form';
import { useCallback } from 'react';

import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import Form from '@/form';
import TextField from '@/form/TextField';
import { useUpdateUserProfileMutation } from '@/graphql';
import { GetAdminCustomerQuery, UserRoleEnum } from '@/types/graphql';
import { handleMutationFormError } from '@/util/error';

export type CustomerAdminItem = GetAdminCustomerQuery['customerAdmin'];

type Props = {
  hideModal: () => void;
  customerAdmin: CustomerAdminItem;
};

type FormValues = {
  newPassword: string;
};

const UpdatePasswordToCustomerAdminModal = ({
  hideModal,
  customerAdmin,
}: Props) => {
  const initialValue: FormValues = {
    newPassword: '',
  };

  const [updatePassword, { loading }] = useUpdateUserProfileMutation();

  const handleSubmit = useCallback(
    async (
      { newPassword }: FormValues,
      { clearForm, setFormError }: SubmitHelpers
    ) => {
      try {
        if (newPassword.length < 8) {
          return setFormError('invalidNewPassword', {
            message: 'The new password must contain at least 8 characters',
          });
        }

        clearForm();
        const result = await updatePassword({
          variables: {
            userId: customerAdmin.user.id,
            firstName: customerAdmin.user.firstName,
            lastName: customerAdmin.user.lastName,
            phoneNumber: customerAdmin.user.phoneNumber,
            password: newPassword,
            roleId: UserRoleEnum.CUSTOMER_ADMIN,
          },
        });

        if (result.data) return hideModal();
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
          errorMap: {
            all: (gqlError) => ({
              title: gqlError.name,
              message: gqlError.message,
              status: 'danger',
            }),
          },
        });
      }
    },
    []
  );

  return (
    <Modal
      disableClickout
      size="sm"
      title="Change Password"
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Form initialValues={initialValue} onSubmit={handleSubmit}>
          <TextField
            required
            css={{ letterSpacing: '2px' }}
            fieldId="newPassword"
            label="New Password"
            placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
            type="password"
          />
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              isLoading={loading}
              label="Save"
              loadingLabel="Changing..."
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  );
};

export default UpdatePasswordToCustomerAdminModal;

import { FieldContext, useField } from '@area2k/use-form';

import { FormValues } from './';

import FormElement from '@/components/FormElement';
import TextArea from '@/components/TextArea';
import { MAX_LENGTH_JOB_INSTRUCTIONS } from '@/constants/text';
import { Scalars } from '@/types/graphql';

type Props = {
  handleInstructionsChange: <T extends unknown>(
    fieldContext: FieldContext<T>,
    fieldId: keyof FormValues
  ) => Scalars['Void'];
};

const InstructionsSection = ({ handleInstructionsChange }: Props) => {
  const { value, setValue } = useField<Scalars['String']>(
    'instructions',
    (fieldContext) => {
      handleInstructionsChange(fieldContext, 'instructions');
    }
  );
  return (
    <div style={{ width: '100% ' }}>
      <FormElement
        htmlFor="instructions"
        label="Add job description & instructions*"
      >
        <TextArea
          id="instructions"
          maxLength={MAX_LENGTH_JOB_INSTRUCTIONS}
          name="instructions"
          placeholder="Provide a detailed description of the job..."
          value={value}
          onChange={(ev) => setValue(ev.target.value)}
        />
      </FormElement>
    </div>
  );
};

export default InstructionsSection;

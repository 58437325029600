import Card from '@/components/Card';
import { GetWorkerQuery, TaxTypeEnum } from '@/types/graphql';

type Props = {
  worker: GetWorkerQuery['worker'];
  currentAdminIsCustomerAdmin: boolean;
};

const taxLabelOptions = {
  [TaxTypeEnum.TAX_1099]: '1099',
  [TaxTypeEnum.TAX_W2]: 'W2',
};

const TaxSection = ({ worker }: Props) => {
  return (
    <Card.Section title="Tax type">
      {taxLabelOptions[worker.taxType]}
    </Card.Section>
  );
};

export default TaxSection;

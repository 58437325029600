import { NavLink } from 'react-router-dom';

import Button from '@/components/Button';
import Stack from '@/components/Stack';
import { LinkAction } from '@/types';

export type Props = {
  crumbs: LinkAction[];
};

const Breadcrumbs = ({ crumbs }: Props) => {
  return (
    <Stack gap={2}>
      {crumbs.map((crumb, index) => (
        <>
          <Button
            a11yLabel={crumb.a11yLabel}
            appearance="plain"
            as={NavLink}
            end={crumb.end}
            iconLeft={crumb.icon}
            label={crumb.label}
            to={crumb.to}
          />
          {index !== crumbs.length - 1 && (
            <div aria-hidden style={{ padding: '0 4px', userSelect: 'none' }}>
              /
            </div>
          )}
        </>
      ))}
    </Stack>
  );
};

export default Breadcrumbs;

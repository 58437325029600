import Stack from '@/components/Stack';
import styled from '@/styles';

export const Wrapper = styled('div', {
  width: '100%',
  backgroundColor: 'white',
  borderRadius: '$lg',
});

export const CardElementContainer = styled(Stack, {
  '& .StripeElement': { width: '100%' },
});

export const ButtonContainer = styled(Stack, {
  paddingTop: '20px',
});

import { NavigateNext } from '@mui/icons-material';
import { Breadcrumbs, Link } from '@mui/material';
import React from 'react';

import Text from '@/elements/Text';

interface Props {
  step: number;
  onClickJobAccess?: () => void;
  onClickStaffing?: () => void;
  styles?: React.CSSProperties;
}

const BreakCrumbHeaders = ({
  step,
  onClickJobAccess,
  onClickStaffing,
  styles = {},
}: Props) => {
  return (
    <div style={{ marginBottom: '12px', ...styles }}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNext fontSize="small" />}
      >
        <Link
          color="inherit"
          sx={{ cursor: 'pointer' }}
          underline="none"
          onClick={onClickJobAccess}
        >
          <Text
            color={step === 1 ? 'default' : 'lighter'}
            weight={step === 1 ? 'semibold' : 'normal'}
          >
            Job Accessibility
          </Text>
        </Link>

        <Link
          color="inherit"
          sx={{ cursor: 'pointer' }}
          underline="none"
          onClick={onClickStaffing}
        >
          <Text
            color={step === 2 ? 'default' : 'lighter'}
            weight={step === 2 ? 'semibold' : 'normal'}
          >
            Staffing
          </Text>
        </Link>
      </Breadcrumbs>
    </div>
  );
};

export default BreakCrumbHeaders;

import styled from '@/styles';

export const PayRateWrapper = styled('div', {
  padding: '20px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  border: '1px solid #D3D3D3',
  variants: {
    phoneOnly: {
      true: {
        width: '100%',
      },
      false: {
        width: '90%',
      },
    },
  },
  borderRadius: '8px',
});

export const TitleWrapper = styled('div', {
  flexDirection: 'row',
  display: 'flex',
  variants: {
    phoneOnly: {
      true: {
        width: '100%',
        flexDirection: 'column',
      },
      false: {
        width: '90%',
      },
    },
  },
  alignItems: 'baseline',
});

export const TitleFlex = styled('div', {
  flex: 1,
});

export const TotalHours = styled('div', {
  marginRight: '10px',
  flexDirection: 'row',
  display: 'flex',
  '@phoneOnly': {
    marginTop: '6px',
  },
});

export const RowBorderWrapper = styled('div', {
  width: '100%',
  padding: '20px 0px 6px 0px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const RatingWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: '10px',
});

export const PayRateInputWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const RatingChartWrapper = styled('div', {
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
});

const TextRate = styled('p', {
  fontSize: '14px',
  fontWeight: '400',
  marginLeft: '5px',
  '@phoneOnly': {
    fontSize: '12px',
  },
});

export const TextGoodRate = styled(TextRate, {
  color: '#45A735',
});

export const TextAverageRate = styled(TextRate, {
  color: '#EC7F00',
});

export const TextBadRate = styled(TextRate, {
  color: '#DC1515',
});

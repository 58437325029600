import Popover from '@/elements/Popover';
import styled from '@/styles';

const Menu = styled(Popover, {
  listStyle: 'none',
  padding: '4px 0',

  backgroundColor: 'white',
  borderRadius: '$lg',
  boxShadow: '$3',
  '@phoneOnly': {
    width: '100%',
  },
});

Menu.displayName = 'stitches(Autocomplete.Menu)';

export default Menu;

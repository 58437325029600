import { createSvgIcon } from '@mui/material';

const CalendarIcon = createSvgIcon(
  <svg
    fill="none"
    height="14"
    viewBox="0 0 14 14"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0833 2.33203H2.91667C2.27233 2.33203 1.75 2.85437 1.75 3.4987V11.6654C1.75 12.3097 2.27233 12.832 2.91667 12.832H11.0833C11.7277 12.832 12.25 12.3097 12.25 11.6654V3.4987C12.25 2.85437 11.7277 2.33203 11.0833 2.33203Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33594 1.16797V3.5013"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66406 1.16797V3.5013"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.75 5.83203H12.25"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'CalendarIcon'
);
export default CalendarIcon;

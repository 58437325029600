import Button from '@/components/Button';
import styled from '@/styles';
import Card from '@/components/Card';
import Stack from '@/components/Stack';
import Option from '@/components/Option';
import { ProfileHeading, Subheading } from '@/components/Typography';
import Form from '@/form';
import TextAreaField from '@/form/TextAreaField';
import TextSelectField from '@/form/TextSelectField';
import { FieldContext, SubmitHelpers } from '@area2k/use-form';
import { useState } from 'react';

const blockOptions = [
  { label: 'Performance Issue', value: 'Performance Issue' },
  { label: 'Behavioral Issue', value: 'Behavioral Issue' },
  { label: 'Qualification Issue', value: 'Qualification Issue' },
  { label: 'Other', value: 'Other' },
];

type FormValues = {
  reason: string;
  comments: string;
};

type Props = {
  phoneOnly: boolean;
  onCancel: () => void;
  onBlock: (values: FormValues) => void;
};

const RoundedButtons = styled(Button, {
  borderRadius: '8px',
  '@phoneOnly': {
    flex: 1,
  },
});

const BlockWorkerSection = ({ phoneOnly, onCancel, onBlock }: Props) => {
  const [initialValues, setInitialValues] = useState<FormValues>({
    reason: blockOptions[0].value,
    comments: '',
  });

  const [loading, setLoading] = useState(false);

  const handleFormValuesChange = <T extends any>(
    fieldContext: FieldContext<T>,
    fieldId: keyof FormValues,
  ) => {
    setInitialValues((prevValues) => ({
      ...prevValues,
      [fieldId]: fieldContext.value,
    }));
  };

  const handleSubmit = async (
    values: FormValues,
    { clearForm }: SubmitHelpers,
  ) => {
    try {
      setLoading(true);
      await onBlock(values);
      setLoading(false);
      clearForm();
      onCancel();
    } catch {
      setLoading(false);
    }
  };

  return (
    <Card.Section>
      <Form initialValues={initialValues} onSubmit={handleSubmit}>
        <Subheading css={{ color: '#262626' }}>
          Are you sure you want to block this worker?
        </Subheading>
        <Stack vertical style={{ marginTop: '10px' }}>
          {phoneOnly ? (
            <>
              <ProfileHeading>Select Reason</ProfileHeading>
              <Stack gap={15} style={{ marginBottom: '15px' }} vertical>
                {blockOptions.map((item, index) => (
                  <Option
                    key={'filledFilter' + index}
                    appearance="bullseye"
                    checked={initialValues.reason === item.value}
                    id={`${blockOptions.indexOf(item)}-blockOptions`}
                    label={item.label}
                    type="radio"
                    value={item.value}
                    onChange={(ev) =>
                      handleFormValuesChange(
                        { value: ev.target.value },
                        'reason',
                      )
                    }
                  />
                ))}
              </Stack>
            </>
          ) : (
            <>
              <ProfileHeading>Reason</ProfileHeading>
              <TextSelectField
                callback={(fieldContext) => {
                  if (fieldContext.value) {
                    handleFormValuesChange(fieldContext, 'reason');
                  }
                }}
                disabled={false}
                fieldId="reason"
                options={blockOptions}
                defaultValue={initialValues.reason}
              />
            </>
          )}
        </Stack>
        <Stack vertical>
          <ProfileHeading>Add Comment</ProfileHeading>
          <TextAreaField
            fieldId="comments"
            maxLength={250}
            maxLengthPosition="top"
            placeholder="Add comments here..."
            required={false}
            trim={true}
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext, 'comments');
            }}
          />
        </Stack>

        <Stack justify={phoneOnly ? 'center' : 'end'}>
          <RoundedButtons
            a11yLabel="Dismiss"
            appearance="outline"
            label="Dismiss"
            type="button"
            onClick={onCancel}
          />
          <RoundedButtons
            a11yLabel="Submit form"
            id="block-worker-btn"
            isLoading={false}
            label="Block"
            type="submit"
            loading={loading}
            status={'danger'}
            disabled={
              initialValues.reason === 'Other' &&
              initialValues.comments.trim() === ''
            }
          />
        </Stack>
      </Form>
    </Card.Section>
  );
};

export default BlockWorkerSection;

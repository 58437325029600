import { faCheckCircle, faCircle } from '@fortawesome/free-regular-svg-icons';

import ProgressBar from './ProgressBar';

import Card from '@/components/Card';
import Stack from '@/components/Stack';
import Icon from '@/elements/Icon';
import Text from '@/elements/Text';
import styled from '@/styles';
import { GetCustomerQuery } from '@/types/graphql';

type Props = {
  customer: GetCustomerQuery['customer'];
};

const Wrapper = styled('ol', {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  height: '188px',
  listStyle: 'none',
  padding: 0,
});

Wrapper.displayName = 'stitches(ProgressCustomerSection.Wrapper)';

const Step = styled('li', {
  borderRadius: '$sm',
  boxShadow: '$1',
  display: 'flex',
  flexDirection: 'row',
  padding: '10px 0px 10px',
});

Step.displayName = 'stitches(ProgressCustomerSection.Step)';

const IconStep = styled(Icon, {
  marginRight: 10,

  variants: {
    completed: {
      true: {
        color: '$themeDefault',
      },
      false: {
        color: '$textLightest',
      },
    },
  },
});

const TextStep = styled(Text, {
  marginLeft: 20,
});

const STEPS_COUNT = 3;

const notEmptyArr = (arr: unknown[]) => (arr.length > 0 ? 1 : 0);

export const customersHasPaymentMethod = (
  accounts: GetCustomerQuery['customer']['accounts']
) =>
  accounts.some((el) => el.currentPaymentMethod) && accounts.length > 0 ? 1 : 0;

const ProgressCustomerSection = ({ customer }: Props) => {
  const progress =
    notEmptyArr(customer.admins.items) +
    customersHasPaymentMethod(customer.accounts) +
    notEmptyArr(customer.addresses);

  return (
    <Card.Section title="Profile">
      <Stack justify="start">
        <Text
          color={
            customer.admins.items.length > 0 &&
            customer.accounts.length > 0 &&
            customer.addresses.length > 0
              ? 'theme'
              : 'inherit'
          }
          size="md"
        >
          {`Complete your profile (${progress}/${STEPS_COUNT})`}
        </Text>
      </Stack>
      <Stack
        justify="center"
        style={{ display: 'flex,', flexDirection: 'column' }}
      >
        <ProgressBar size="xs" step={progress} />
      </Stack>
      <Wrapper>
        <Step>
          <TextStep
            color={customer.admins.items.length > 0 ? 'theme' : 'lightest'}
          >
            <IconStep
              completed={customer.admins.items.length > 0 ? 'true' : 'false'}
              icon={customer.admins.items.length > 0 ? faCheckCircle : faCircle}
            />
            Create client admin
          </TextStep>
        </Step>
        <Step>
          <TextStep color={customer.accounts.length > 0 ? 'theme' : 'lightest'}>
            <IconStep
              completed={customer.accounts.length > 0 ? 'true' : 'false'}
              icon={customer.accounts.length > 0 ? faCheckCircle : faCircle}
            />
            Create billing account
          </TextStep>
        </Step>
        <Step>
          <TextStep
            color={
              customersHasPaymentMethod(customer.accounts)
                ? 'theme'
                : 'lightest'
            }
          >
            <IconStep
              completed={
                customersHasPaymentMethod(customer.accounts) ? 'true' : 'false'
              }
              icon={
                customersHasPaymentMethod(customer.accounts)
                  ? faCheckCircle
                  : faCircle
              }
            />
            Add Payment Method
          </TextStep>
        </Step>
        <Step>
          <TextStep
            color={customer.addresses.length > 0 ? 'theme' : 'lightest'}
          >
            <IconStep
              completed={customer.addresses.length > 0 ? 'true' : 'false'}
              icon={customer.addresses.length > 0 ? faCheckCircle : faCircle}
            />
            Add address
          </TextStep>
        </Step>
      </Wrapper>
    </Card.Section>
  );
};

export default ProgressCustomerSection;

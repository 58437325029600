import { Chip } from '@mui/material';

import { AlarmIcon } from '@/assets/icons';
import StyledChip from '@/components/Chip';
import Text from '@/elements/Text';
import { useCountdown } from '@/hooks/useCountdown';

type JobTimerProps = {
  targetDate: Date;
  refetch: () => void;
};
const TimerColor = '#60A540';

const JobTimer = ({ targetDate }: JobTimerProps) => {
  const [hours, minutes] = useCountdown(targetDate);

  if (hours + minutes <= 0) {
    return <StyledChip color="primary" label="In Progress" size="small" />;
  }
  const Hours = hours < 10 ? `0${hours}` : hours;
  const Minutes = minutes < 10 ? `0${minutes}` : minutes;

  return (
    <Chip
      icon={<AlarmIcon style={{ fontSize: '17px', left: 2 }} />}
      label={
        <Text
          css={{ color: TimerColor, fontSize: '0.9rem' }}
        >{`${Hours}:${Minutes}`}</Text>
      }
      style={{ color: TimerColor, borderColor: TimerColor }}
      variant="outlined"
    />
  );
};

export default JobTimer;

import { createSvgIcon } from '@mui/material';

const LinkIcon = createSvgIcon(
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5898 13.4101C10.9998 13.8001 10.9998 14.4401 10.5898 14.8301C10.1998 15.2201 9.55982 15.2201 9.16982 14.8301C7.21982 12.8801 7.21982 9.71007 9.16982 7.76007L12.7098 4.22007C14.6598 2.27007 17.8298 2.27007 19.7798 4.22007C21.7298 6.17007 21.7298 9.34007 19.7798 11.2901L18.2898 12.7801C18.2998 11.9601 18.1698 11.1401 17.8898 10.3601L18.3598 9.88007C19.5398 8.71007 19.5398 6.81007 18.3598 5.64007C17.1898 4.46007 15.2898 4.46007 14.1198 5.64007L10.5898 9.17007C9.40982 10.3401 9.40982 12.2401 10.5898 13.4101ZM13.4098 9.17007C13.7998 8.78007 14.4398 8.78007 14.8298 9.17007C16.7798 11.1201 16.7798 14.2901 14.8298 16.2401L11.2898 19.7801C9.33982 21.7301 6.16982 21.7301 4.21982 19.7801C2.26982 17.8301 2.26982 14.6601 4.21982 12.7101L5.70982 11.2201C5.69982 12.0401 5.82982 12.8601 6.10982 13.6501L5.63982 14.1201C4.45982 15.2901 4.45982 17.1901 5.63982 18.3601C6.80982 19.5401 8.70982 19.5401 9.87982 18.3601L13.4098 14.8301C14.5898 13.6601 14.5898 11.7601 13.4098 10.5901C12.9998 10.2001 12.9998 9.56007 13.4098 9.17007Z"
      fill="#7A7A7A"
    />
  </svg>,
  'LinkIcon'
);
export default LinkIcon;

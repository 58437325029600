import { useReactiveVar } from '@apollo/client';
import { FieldContext, SubmitHelpers } from '@area2k/use-form';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Alert, AlertTitle } from '@mui/material';
import { format, subYears } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

import { getUTCDate, getWeeksRanges } from '../util';

import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import Icon from '@/elements/Icon';
import Form from '@/form';
import TextField from '@/form/TextField';
import TextSelectField from '@/form/TextSelectField';
import { ListPayrollsDocument, useCreateNewPayrollMutation } from '@/graphql';
import colors from '@/styles/colors';
import { Payroll } from '@/types/graphql';
import { currentTenantVar } from '@/util/apollo/cache';
import { LONG_DATE_FORMAT, MONDAY_OF_WEEK } from '@/util/constants';
import { handleMutationFormError } from '@/util/error';

type PayrollItem = Payroll;

type Props = {
  hideModal: () => void;
  setPayrollInitiatedMessage: (value: boolean) => void;
};

type FormValues = {
  weekDay: PayrollItem['periodStart'];
  payday: PayrollItem['payday'];
};

const NewPayrollModal = ({ hideModal, setPayrollInitiatedMessage }: Props) => {
  const currentTenant = useReactiveVar(currentTenantVar);
  const [weeksOptions, setWeeksOptions] = useState<any[]>([]);
  const [payrollError, setPayrollError] = useState<any>();

  const tenantId = currentTenant!.id;
  const initialValues: FormValues = {
    weekDay: getUTCDate(MONDAY_OF_WEEK).toISOString(),
    payday: '',
  };

  const [formValues, setFormValues] = useState<FormValues>(initialValues);

  const [createNewPayroll, { loading: isLoading }] =
    useCreateNewPayrollMutation({
      refetchQueries: [
        { query: ListPayrollsDocument, variables: { tenantId } },
      ],
    });

  const handleFormValuesChange = <T extends any>(
    fieldContext: FieldContext<T>,
    fieldId: keyof FormValues,
  ) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldId]: fieldContext.value,
    }));
  };

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        await createNewPayroll({
          variables: {
            ...values,
          },
        });
        hideModal();
        setPayrollInitiatedMessage(true);
      } catch (err: any) {
        try {
          const errorObj = JSON.parse(err.message);
          setPayrollError(errorObj);
        } catch (e) {
          setPayrollError(undefined);
          handleMutationFormError(err, {
            setFormError,
            errorMap: {
              all: (gqlError) => ({
                title: gqlError.name,
                message: gqlError.message,
                status: 'danger',
              }),
            },
          });
        }
      }
    },
    [],
  );

  useEffect(() => {
    const startDate = subYears(new Date(), 1);
    const endDate = new Date();
    const range = getWeeksRanges(startDate, endDate).map((dates) => {
      return {
        label: `${format(dates.start!, LONG_DATE_FORMAT)} - ${format(
          dates.end!,
          LONG_DATE_FORMAT,
        )}`,
        value: getUTCDate(dates.start).toISOString(),
      };
    });
    setWeeksOptions(range);
  }, []);

  return (
    <Modal
      disableClickout
      size="xs"
      title="Run New Payroll"
      onRequestClose={hideModal}
    >
      <Card.Section>
        {payrollError && (
          <Alert
            icon={<Icon icon={faExclamationTriangle} size={'lg'} />}
            severity="error"
            style={{
              marginBottom: '1rem',
              border: `1px solid ${colors.dangerLightest}`,
            }}
          >
            <AlertTitle>{payrollError?.message}</AlertTitle>
            <ul>
              {payrollError?.input_errors?.map((err, idx) => (
                <li key={idx}>{err?.message}</li>
              ))}
            </ul>
          </Alert>
        )}

        <Form initialValues={formValues} onSubmit={handleSubmit}>
          {weeksOptions.length > 0 && (
            <TextSelectField
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'weekDay');
              }}
              fieldId="weekDay"
              label="Select Week"
              options={weeksOptions}
            />
          )}
          <TextField
            required
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext, 'payday');
            }}
            fieldId="payday"
            label="Payday"
            placeholder="mm/dd/yyyy"
            style={{ textTransform: 'uppercase' }}
            type="date"
          />
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              id="submit-payroll-btn"
              isLoading={isLoading}
              label="Run Payroll"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  );
};

export default NewPayrollModal;

import { Stack } from '@mui/material';
import { useState } from 'react';

import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import CustomStack from '@/components/Stack';
import { Subheading } from '@/components/Typography';
import Form from '@/form';
import TextField from '@/form/TextField';

import './index.css';

export type EditFormValues = {
  checkIn: string;
  checkOut: string;
  tip: string;
  break: string;
};

type Props = {
  hideModal: () => void;
  handleEdit: (values: EditFormValues) => void;
  isClientAdmin: boolean;
};

const initialValues: EditFormValues = {
  checkIn: '',
  checkOut: '',
  break: '',
  tip: '',
};

const BulkEditModal = ({ hideModal, handleEdit, isClientAdmin }: Props) => {
  const handleSubmit = async (values: EditFormValues) => {
    handleEdit(values);
  };
  const [values, setValues] = useState({
    checkIn: '',
    checkOut: '',
    break: '',
    tip: '',
  });
  const disableBtn =
    values.checkIn === '' &&
    values.checkOut === '' &&
    values.break === '' &&
    values.tip === '';

  return (
    <Modal
      disableClickout
      size="xs"
      title="Edit Info"
      wrapperBackground={true}
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Form
          id="bulk-edit-form"
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          <Stack direction={'column'} spacing={{ md: 3, xs: 2 }}>
            <Stack direction={'column'} spacing={{ xs: 1 }}>
              <Subheading size="md">Check In</Subheading>
              <TextField
                callback={(ctx) => setValues({ ...values, checkIn: ctx.value })}
                className={values.checkIn ? '' : 'checkIn'}
                displayType="rowSpace"
                fieldId="checkIn"
                placeholder="Update CheckIn"
                type="time"
              />
            </Stack>
            <Stack direction={'column'} spacing={{ xs: 1 }}>
              <Subheading size="md">Check Out</Subheading>
              <TextField
                callback={(ctx) =>
                  setValues({ ...values, checkOut: ctx.value })
                }
                className={values.checkOut ? '' : 'checkOut'}
                displayType="rowSpace"
                fieldId="checkOut"
                placeholder="Update CheckIn"
                type="time"
              />
            </Stack>
            <Stack direction={'column'} spacing={{ xs: 1 }}>
              <Subheading size="md">Break</Subheading>
              <TextField
                callback={(ctx) => setValues({ ...values, break: ctx.value })}
                displayType="rowSpace"
                fieldId="break"
                max="90"
                min="0"
                placeholder="Update Break Time"
                type="number"
              />
            </Stack>
            <Stack direction={'column'} spacing={{ xs: 1 }}>
              <Subheading size="md">Tip</Subheading>
              <TextField
                callback={(ctx) => setValues({ ...values, tip: ctx.value })}
                displayType="rowSpace"
                fieldId="tip"
                max="1000"
                min="0"
                placeholder="Update Tip Amount"
                step=".01"
                type="number"
              />
            </Stack>
            <CustomStack justify={'end'}>
              <Button
                a11yLabel="Submit edit form"
                disabled={disableBtn}
                label={isClientAdmin ? 'Approve' : 'Done'}
                type="submit"
              />
            </CustomStack>
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  );
};

export default BulkEditModal;

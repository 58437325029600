import useModal from '@area2k/use-modal';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

import SuccessfulAlertModal from '../shared/SuccessfulAlertModal';

import IconicButton from '@/components/IconicButton';
import Option from '@/components/Option';
import Stack from '@/components/Stack';
import TextSelect from '@/components/TextSelect';
import { TableCell } from '@/elements/Table';
import { useTimesheetsByPayPeriodLazyQuery } from '@/graphql';
import useToggle from '@/hooks/useToggle';
import { MONDAY_OF_WEEK } from '@/util/constants';
import { getWeekRangesOptions } from '@/util/date';

const initialRange = MONDAY_OF_WEEK.toISOString();
const weekOptions = getWeekRangesOptions();

const Timesheets = () => {
  const [allTimesheets, toggleAllTimesheets] = useToggle(false);
  const [payPeriodRange, setPayPeriodRange] = useState<string>(initialRange);
  const [showSuccessfulAlertModal, hideSuccessfulAlertModal] = useModal(
    () => <SuccessfulAlertModal hideModal={hideSuccessfulAlertModal} />,
    []
  );
  const [sendTimesheetsReport, { loading }] = useTimesheetsByPayPeriodLazyQuery(
    {
      fetchPolicy: 'no-cache',
      onCompleted: showSuccessfulAlertModal,
    }
  );

  const handleSendReport = () => {
    if (!allTimesheets) {
      sendTimesheetsReport({ variables: { payPeriod: payPeriodRange } });
    } else {
      sendTimesheetsReport();
    }
  };

  return (
    <>
      <TableCell>
        <Stack wrap id="timesheets-reports-label">
          Timesheets
        </Stack>
      </TableCell>
      <TableCell>
        <Stack wrap>
          Pay Period
          {weekOptions.length > 0 && (
            <div>
              <TextSelect
                defaultValue={payPeriodRange}
                disabled={allTimesheets}
                id="payPeriod"
                onChange={(ev) => setPayPeriodRange(ev.target.value)}
              >
                {weekOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextSelect>
            </div>
          )}
        </Stack>
      </TableCell>
      <TableCell>
        <Stack>
          <Option
            appearance="checkbox"
            id="all"
            label="All"
            onClick={toggleAllTimesheets}
          />
        </Stack>
      </TableCell>
      <TableCell>
        <Stack justify="end">
          <IconicButton
            a11yLabel="download"
            appearance="clear"
            icon={faFileExport}
            id="download-timesheets-btn"
            isLoading={loading}
            onClick={handleSendReport}
          />
        </Stack>
      </TableCell>
    </>
  );
};

export default Timesheets;

import { createSvgIcon } from '@mui/material';

const TimerExclamationIcon = createSvgIcon(
  <svg
    fill="none"
    height="14"
    viewBox="0 0 14 14"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3333 6.99967H13.6667V10.9997H12.3333V6.99967ZM12.3333 12.333H13.6667V13.6663H12.3333V12.333ZM6.99999 0.333008C3.33333 0.333008 0.333328 3.33301 0.333328 6.99967C0.333328 10.6663 3.33333 13.6663 6.99999 13.6663C8.53333 13.6663 9.86666 13.133 11 12.333V5.66634H13.5333C12.9333 2.59967 10.2 0.333008 6.99999 0.333008ZM9.79999 9.79967L6.33333 7.66634V3.66634H7.33333V7.13301L10.3333 8.93301L9.79999 9.79967Z"
      fill="#FFB818"
    />
  </svg>,
  'TimerExclamation'
);
export default TimerExclamationIcon;

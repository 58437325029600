import { Schedule } from '../../../context';

import Card from '@/components/Card';
import Stack from '@/components/Stack';
import { Body, Small } from '@/components/Typography';
import useAuth from '@/hooks/useAuth';
import { Role } from '@/routes/PrivateRoute';
import { centsToCurrency } from '@/util/number';

type Props = {
  scheduleGroup: Schedule[];
  pay: number;
  cost: number;
};

const ScheduleSummary = ({ scheduleGroup, pay, cost }: Props) => {
  const { currentAdmin } = useAuth();
  const defaultJob = scheduleGroup[0] || {};
  const rate = defaultJob.rate || 1;
  const payRate = defaultJob.isHoliday
    ? defaultJob.payRate / rate
    : defaultJob.payRate;
  const costRate = defaultJob.isHoliday
    ? defaultJob.costRate / rate
    : defaultJob.costRate;

  const singleHolidayJob = defaultJob.isHoliday && scheduleGroup.length === 1;

  return (
    <Card.Section>
      <Stack vertical gap={24}>
        <Stack vertical gap={12}>
          {currentAdmin?.role !== Role.CUSTOMER_ADMIN && !singleHolidayJob && (
            <Stack justify="apart">
              <Small>Pay rate</Small>
              <Body>${centsToCurrency(payRate || pay)}</Body>
            </Stack>
          )}
          {!singleHolidayJob && (
            <Stack justify="apart">
              <Small>Bill rate</Small>
              <Body>${centsToCurrency(costRate || cost)}</Body>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Card.Section>
  );
};

export default ScheduleSummary;

import { createSvgIcon } from '@mui/material';

const PendingDotIcon = createSvgIcon(
  <svg
    fill="none"
    height="1"
    viewBox="0 0 8 8"
    width="1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="1" cy="4" fill="#DC1515" r="1" />
  </svg>,
  'PendingDotIcon'
);
export default PendingDotIcon;

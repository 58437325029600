import { useReactiveVar } from '@apollo/client';
import { SubmitHelpers } from '@area2k/use-form';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { Divider } from '@mui/material';
import { differenceInCalendarDays, parseISO } from 'date-fns';
import { useCallback, useState } from 'react';

import { TimesheetItem } from '../..';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Modal from '@/components/Modal';
import RatingField from '@/components/RatingField';
import Stack from '@/components/Stack';
import { Body, Small, Subheading } from '@/components/Typography';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { PAST_DAYS } from '@/constants/general';
import Form from '@/form';
import TextAreaField from '@/form/TextAreaField';
import {
  useAddTimesheetCommentMutation,
  useWorkerUpdateRatingMutation,
} from '@/graphql';
import { Role } from '@/routes/PrivateRoute';
import styled from '@/styles';
import { Scalars } from '@/types/graphql';
import { currentAdminVar } from '@/util/apollo/cache';
import { parseDateComment } from '@/util/date';
import { handleMutationFormError } from '@/util/error';

export type Props = {
  timesheet: TimesheetItem;
  variant?: 'DETAIL' | 'DEFAULT';
  hideModal: () => Scalars['Void'];
};

type FormValues = {
  body: string;
};

const initialValues: FormValues = {
  body: '',
};

type CommentItemProps = {
  comment: string;
  actor: string;
  date: any;
};
const CommentWrapper = styled('div', {
  maxHeight: '360px',
  overflowY: 'auto',
  '@phoneOnly': {
    maxHeight: '280px',
  },
});

const CommentItem = ({ comment, actor, date }: CommentItemProps) => (
  <Stack vertical css={{ padding: '1.5em 1.5em 0' }} verticalGap={4}>
    <Stack justify="apart">
      <Subheading>{actor}</Subheading>
      <Small color={'lighter'}>{parseDateComment(date)}</Small>
    </Stack>
    <Body css={{ wordWrap: 'break-word', maxWidth: '100%' }}>{comment}</Body>
  </Stack>
);

const CommentsTimesheetModal = ({
  timesheet,
  hideModal,
  variant = 'DEFAULT',
}: Props) => {
  const currentAdmin = useReactiveVar(currentAdminVar);
  const currentAdminIsTenantAdmin = currentAdmin!.role === Role.TENANT_ADMIN;
  const currentAdminIsClientAdmin = currentAdmin!.role === Role.CUSTOMER_ADMIN;
  const [workerRating, setWorkerRating] = useState<number | null>(
    timesheet.rating || 0
  );
  const [comments, setComments] = useState<any>(
    timesheet.timesheetComments.filter((c) => {
      if (currentAdminIsClientAdmin === true) {
        return c.actorType === 'CustomerAdmin';
      } else {
        return true;
      }
    })
  );
  const showRating = useFeatureValue(FEATURE_TOGGLE.WorkerRating, false);
  const isPast2Weeks =
    differenceInCalendarDays(new Date(), parseISO(timesheet.createdAt)) >
    PAST_DAYS;

  const [addComment, { loading }] = useAddTimesheetCommentMutation({
    onCompleted: (data) => {
      const { timesheet } = data.timesheetCommentsAdd;
      const { timesheetComments } = timesheet;
      setComments(timesheetComments);
    },
  });
  const [updateWorkerRating, { loading: loadingRating }] =
    useWorkerUpdateRatingMutation({
      onCompleted: (data) => {
        const { timesheet } = data.workerUpdateRating;
        const { timesheetComments } = timesheet;
        setComments(timesheetComments);
      },
    });

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError, clearForm }: SubmitHelpers) => {
      try {
        if (currentAdminIsClientAdmin && showRating) {
          if (!values.body && !workerRating) return;
          await updateWorkerRating({
            variables: {
              timesheetId: timesheet.id,
              body: values.body,
              rating: workerRating,
            },
          });
          if (!values.body) hideModal();
        } else {
          await addComment({
            variables: {
              timesheetId: timesheet.id,
              body: values.body,
            },
          });
        }
        clearForm();
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
        });
      }
    },
    [workerRating]
  );

  return (
    <Modal
      disableClickout
      size="xs"
      title="Add Comment"
      wrapperBackground={true}
      onRequestClose={hideModal}
    >
      <>
        {showRating && (
          <>
            <Stack vertical style={{ padding: '0.5em 1.5em 1em' }}>
              <Subheading>Worker Rating</Subheading>
              <RatingField
                initialRating={workerRating}
                readOnly={currentAdminIsTenantAdmin || isPast2Weeks}
                showNumberLabel={true}
                onChange={setWorkerRating}
              />
            </Stack>
            <Divider />
          </>
        )}
        <CommentWrapper>
          {comments &&
            comments
              .reverse()
              .map((item) => (
                <CommentItem
                  key={item.id}
                  actor={`${item.actor.firstName} ${item.actor.lastName}`}
                  comment={item.body}
                  date={item.createdAt}
                />
              ))}
        </CommentWrapper>
        {variant !== 'DETAIL' && (
          <Stack vertical css={{ padding: '0.5em 1.5em 0' }}>
            <Form
              initialValues={initialValues}
              style={{ width: '100%', margin: '20px 0 20px 0' }}
              onSubmit={handleSubmit}
            >
              <Stack justify="apart" style={{ marginBottom: '10px' }}>
                <Subheading>Add Comment</Subheading>
                <Small color="lightest" style="italic">
                  max 250 characters
                </Small>
              </Stack>
              <TextAreaField
                fieldId="body"
                maxLength={250}
                placeholder="Add comments here..."
                required={currentAdminIsTenantAdmin}
                trim={true}
              />
              <Stack css={{ marginTop: '1.5em' }} justify="end">
                <Button
                  a11yLabel="Cancel"
                  appearance="outline"
                  label="Cancel"
                  type="button"
                  onClick={hideModal}
                />
                <Button
                  a11yLabel="Submit form"
                  isLoading={loading || loadingRating}
                  label="Submit"
                  type="submit"
                />
              </Stack>
            </Form>
          </Stack>
        )}
        {!comments && variant === 'DETAIL' && (
          <Alert
            description="No admin comments provided"
            icon={faExclamationTriangle}
            status="warning"
            style={{ margin: '1.5em 1.5em 0' }}
            title={`Admin comments`}
          />
        )}
      </>
    </Modal>
  );
};

export default CommentsTimesheetModal;

import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Dispatch, SetStateAction } from 'react';

import { BankAccountPaymentMethod, EditorProps, EditorTypeEnum } from '..';

import PlaidLinkButton, { onSuccessType } from './PlaidLinkButton';

import IconicButton from '@/components/IconicButton';
import LoadingState from '@/components/LoadingState';
import Option from '@/components/Option';
import Stack from '@/components/Stack';
import { Body, Small } from '@/components/Typography';
import { useGetPlaidLinkTokenQuery } from '@/graphql';
import { PaymentMethodFormValues } from '@/routes/Agency/Account/PaymentSection/modals/ChangePaymentModal';

export type PaymentAccount = {
  id: string;
  mask: string;
  name: string;
  subtype: string;
  type: string;
};

export type AchConfigProps = {
  publicToken: string;
  paymentAccounts: PaymentAccount[];
};

type AchProps = {
  selectedPaymentMethodId: PaymentMethodFormValues['selectedPaymentMethodId'];
  bankAccounts: BankAccountPaymentMethod[];
  setEditor?: Dispatch<SetStateAction<EditorProps>>;
  setAchConfig?: Dispatch<SetStateAction<AchConfigProps>>;
  handleSuccess?: (payload: AchConfigProps) => void;
  setSelectedPaymentMethodId: (paymentMethodId: string) => void;
  leftSpace?: string;
};

const Ach = ({
  selectedPaymentMethodId,
  bankAccounts,
  setSelectedPaymentMethodId,
  setEditor,
  setAchConfig,
  handleSuccess,
  leftSpace = '2rem',
}: AchProps) => {
  const { loading, error, data } = useGetPlaidLinkTokenQuery();

  const renderLabelByBankAccount = (bankAccount: BankAccountPaymentMethod) => (
    <Stack inline>
      <Body>
        {bankAccount.bankName} **** {bankAccount.last4}
      </Body>
    </Stack>
  );

  const onRemoveBankAccount = (paymentMethod: BankAccountPaymentMethod) => {
    setEditor?.((prevValues) => ({
      ...prevValues,
      isOpen: true,
      type: EditorTypeEnum.REMOVE_ACCOUNT,
      selectedPaymentMethod: paymentMethod,
    }));
  };

  const onSuccess: onSuccessType = (publicToken, metadata) => {
    setAchConfig?.((prevValues) => ({
      ...prevValues,
      publicToken,
      paymentAccounts: metadata.accounts,
    }));
    setEditor?.((prevValues) => ({
      ...prevValues,
      isOpen: true,
      type: EditorTypeEnum.ADD_ACCOUNT,
    }));
    handleSuccess?.({ publicToken, paymentAccounts: metadata.accounts });
  };

  if (loading) {
    return (
      <Stack css={{ paddingLeft: '2rem' }}>
        <LoadingState />
      </Stack>
    );
  }

  if (error || !data) {
    return (
      <Stack css={{ paddingLeft: '2rem' }}>
        <Small>{error?.message}</Small>
      </Stack>
    );
  }

  const { plaidLinkToken } = data;

  return (
    <>
      {bankAccounts.length === 0 ? (
        <Stack css={{ paddingLeft: leftSpace }}>
          <Small>No Accounts yet</Small>
        </Stack>
      ) : (
        bankAccounts.map((paymentMethod, index) => (
          <Stack
            key={paymentMethod.id}
            css={{ paddingLeft: '2rem' }}
            justify="apart"
          >
            <Option
              checked={selectedPaymentMethodId === paymentMethod.id}
              id={`${index}`}
              label={renderLabelByBankAccount(paymentMethod)}
              name="bankAccount"
              type="radio"
              value={paymentMethod.id}
              onChange={({ currentTarget: { value: paymentMethodId } }) => {
                setSelectedPaymentMethodId(paymentMethodId);
              }}
            />
            <IconicButton
              a11yLabel="Remove this credit Card"
              appearance="clear"
              icon={faTrash}
              status="danger"
              type="button"
              onClick={() => onRemoveBankAccount(paymentMethod)}
            />
          </Stack>
        ))
      )}

      <Stack css={{ marginLeft: leftSpace }}>
        <PlaidLinkButton token={plaidLinkToken} onSuccess={onSuccess} />
      </Stack>
    </>
  );
};

export default Ach;

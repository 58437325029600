import { createSvgIcon } from '@mui/material';

const RatingAverage = createSvgIcon(
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 14.3333V11.045C7 11.0201 6.97985 11 6.955 11H5.5H4.045C4.02015 11 4 11.0201 4 11.045V14.3333V17.6667V20.955C4 20.9799 4.02015 21 4.045 21H5.5H6.955C6.97985 21 7 20.9799 7 20.955V17.6667V14.3333Z"
      fill="#EC7F00"
      stroke="#EC7F00"
      strokeLinecap="round"
      strokeWidth="2"
    />
    <path
      d="M13.5 11.3333V6.045C13.5 6.02015 13.4799 6 13.455 6H12H10.545C10.5201 6 10.5 6.02015 10.5 6.045V11.3333V15.6667V20.955C10.5 20.9799 10.5201 21 10.545 21H12H13.455C13.4799 21 13.5 20.9799 13.5 20.955V15.6667V11.3333Z"
      fill="#EC7F00"
      stroke="#EC7F00"
      strokeLinecap="round"
      strokeWidth="2"
    />
    <path
      d="M20 9V3.045C20 3.02015 19.9799 3 19.955 3H18.5H17.045C17.0201 3 17 3.02015 17 3.045V9V15V20.955C17 20.9799 17.0201 21 17.045 21H18.5H19.955C19.9799 21 20 20.9799 20 20.955V15V9Z"
      fill="#D3D3D3"
      stroke="#D3D3D3"
      strokeLinecap="round"
      strokeWidth="2"
    />
  </svg>,
  'RatingAverage'
);
export default RatingAverage;

import { createSvgIcon } from '@mui/material';

const HeartIcon = createSvgIcon(
  <svg
    fill="none"
    height="25"
    viewBox="0 0 24 25"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_14934_42963)">
      <path
        d="M20.8391 5.23683C20.3284 4.72583 19.722 4.32047 19.0545 4.04391C18.387 3.76735 17.6716 3.625 16.9491 3.625C16.2267 3.625 15.5112 3.76735 14.8438 4.04391C14.1763 4.32047 13.5699 4.72583 13.0591 5.23683L11.9991 6.29683L10.9391 5.23683C9.90745 4.20513 8.50818 3.62553 7.04914 3.62553C5.59011 3.62553 4.19083 4.20513 3.15914 5.23683C2.12745 6.26852 1.54785 7.66779 1.54785 9.12682C1.54785 10.5859 2.12745 11.9851 3.15914 13.0168L4.21914 14.0768L11.9991 21.8568L19.7791 14.0768L20.8391 13.0168C21.3501 12.5061 21.7555 11.8996 22.0321 11.2322C22.3086 10.5647 22.451 9.84931 22.451 9.12682C22.451 8.40434 22.3086 7.68893 22.0321 7.02147C21.7555 6.35401 21.3501 5.74758 20.8391 5.23683Z"
        stroke="#45A735"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.66667"
      />
    </g>
    <defs>
      <clipPath id="clip0_14934_42963">
        <rect
          fill="white"
          height="24"
          transform="translate(0 0.625)"
          width="24"
        />
      </clipPath>
    </defs>
  </svg>,
  'HeartIcon'
);
export default HeartIcon;

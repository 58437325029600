import { ComponentProps } from 'react';

import Badge from '@/components/Badge';
import { CustomerStatusEnum } from '@/util/accountstatus';

interface Props {
  status: CustomerStatusEnum;
}

const mappedBadgeProps = {
  [CustomerStatusEnum.APPROVED]: {
    label: 'Approved',
    status: 'theme',
  },
  [CustomerStatusEnum.DISABLED]: {
    label: 'Disabled',
    status: 'danger',
  },
  [CustomerStatusEnum.PENDING]: {
    label: 'Pending',
    status: 'neutral',
  },
  [CustomerStatusEnum.REJECTED]: {
    label: 'Rejected',
    status: 'danger',
  },
  [CustomerStatusEnum.DELETED]: {
    label: 'Deleted',
    status: 'danger',
  },
};

const getBadgePropsByInvoiceStatus = (
  status: CustomerStatusEnum
): ComponentProps<typeof Badge> => mappedBadgeProps[status];

const CustomerStatusBadge = ({ status }: Props) => (
  <Badge {...getBadgePropsByInvoiceStatus(status)} />
);

export default CustomerStatusBadge;

import { Reducer } from 'react';

import { SignUpAction, SignUpActionType } from '../actions/actions';
import { INITIAL_STATE } from '../context/state/SignUpStateProvider';
import { SignUpState } from '../interfaces/signUp.interface';

export const signUpReducer: Reducer<SignUpState, SignUpAction> = (
  state,
  action
) => {
  switch (action.type) {
    case SignUpActionType.RESET_STATE:
      return INITIAL_STATE;

    case SignUpActionType.UPDATE_FIRST_STEP:
      return { ...state, ...action.payload };

    case SignUpActionType.UPDATE_SECOND_STEP:
      return { ...state, ...action.payload };

    case SignUpActionType.UPDATE_THIRD_STEP:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

import { createSvgIcon } from '@mui/material';

const CancelNoShowIcon = createSvgIcon(
  <svg
    fill="none"
    height="20"
    viewBox="0 0 21 20"
    width="21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_13310_64886)">
      <path
        d="M14.3018 17.4922V15.8255C14.3018 14.9415 13.9506 14.0936 13.3254 13.4685C12.7003 12.8434 11.8525 12.4922 10.9684 12.4922H5.13509C4.25104 12.4922 3.40319 12.8434 2.77807 13.4685C2.15295 14.0936 1.80176 14.9415 1.80176 15.8255V17.4922"
        stroke="#262626"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M8.05208 9.15885C9.89303 9.15885 11.3854 7.66647 11.3854 5.82552C11.3854 3.98457 9.89303 2.49219 8.05208 2.49219C6.21113 2.49219 4.71875 3.98457 4.71875 5.82552C4.71875 7.66647 6.21113 9.15885 8.05208 9.15885Z"
        stroke="#262626"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M20.1357 9.15625H15.1357"
        stroke="#262626"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
    <defs>
      <clipPath id="clip0_13310_64886">
        <rect
          fill="white"
          height="20"
          transform="translate(0.96875 -0.0078125)"
          width="20"
        />
      </clipPath>
    </defs>
  </svg>,
  'CancelNoShowIcon'
);
export default CancelNoShowIcon;

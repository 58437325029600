import { Chip, ChipProps } from '@mui/material';

const Styles = {
  success: { bgcolor: '#EEFFEC', color: '#44A735' },
  primary: { bgcolor: '#EAF1FF', color: '#4785FF' },
  error: { bgcolor: '#FFDFD8', color: '#DC1515' },
  warning: { bgcolor: '#F5F2DD', color: '#DEA83E' },
};

const StyledChip = (props: ChipProps) => (
  <Chip
    {...props}
    sx={{
      ...props.sx,
      ...(props.color ? Styles[props.color] : undefined),
    }}
  />
);
export default StyledChip;

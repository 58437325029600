import { CheckCircle, CircleOutlined } from '@mui/icons-material';
import {
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import * as React from 'react';

import Avatar from '@/components/Avatar';
import { USER_FETCH_LIMIT } from '@/constants/general';
import useMediaQuery from '@/hooks/useMediaQuery';
import breakpoints from '@/styles/breakpoints';
import { MessageReceiversList } from '@/util/axios/types';

interface WorkerListProps {
  loading: boolean;
  data: any[];
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  handleWorkerClick: (info: MessageReceiversList[0]) => Promise<void>;
  selectedWorkers?: MessageReceiversList;
  listStyles?: React.CSSProperties;
  listHeader?: React.ReactNode;
}

const ChatWorkerList = ({
  loading,
  data = [],
  page,
  setPage,
  handleWorkerClick,
  selectedWorkers,
  listStyles = {},
  listHeader,
}: WorkerListProps) => {
  const phoneOnly = useMediaQuery(breakpoints.phoneOnly);

  let alphabet =
    data.length > 0 ? data[0].first_name.charAt(0)?.toUpperCase() : '';
  const handleClick = (info: MessageReceiversList[0]) => {
    handleWorkerClick(info);
  };
  return (
    <>
      {loading && page === 1 && <LinearProgress id="loader" />}
      <List
        id="worker-list"
        sx={{
          width: '100%',
          minWidth: '310px',
          paddingTop: 0,
          ...listStyles,
        }}
      >
        {listHeader}
        {data.map((d, idx) => {
          const firstChar = d.first_name.charAt(0)?.toUpperCase();

          if (firstChar === alphabet && idx > 0) {
            return (
              <WorkerInfo
                key={d.id}
                handleClick={handleClick}
                info={d}
                loading={loading}
                phoneOnly={phoneOnly}
                selectedWorkers={selectedWorkers}
              />
            );
          } else {
            alphabet = firstChar;
            return (
              <React.Fragment key={d.id}>
                <div
                  style={{
                    padding: '4px 0px',
                    paddingLeft: '20px',
                  }}
                >
                  {firstChar}
                </div>
                <WorkerInfo
                  handleClick={handleClick}
                  info={d}
                  loading={loading}
                  phoneOnly={phoneOnly}
                  selectedWorkers={selectedWorkers}
                />
              </React.Fragment>
            );
          }
        })}
        {data?.length === page * USER_FETCH_LIMIT && (
          <ListItemButton dense={true} onClick={() => setPage((p) => p + 1)}>
            <ListItem alignItems="center">
              <div>Load more data ...</div>
            </ListItem>
          </ListItemButton>
        )}
        {data?.length >= USER_FETCH_LIMIT && loading && <LinearProgress />}
        {data.length === 0 && !loading && (
          <ListItem dense={true}> No Records Found</ListItem>
        )}
      </List>
    </>
  );
};
const WorkerInfo = ({
  info,
  phoneOnly,
  handleClick,
  selectedWorkers,
  loading,
}: {
  info: MessageReceiversList[0];
  phoneOnly: boolean;
  handleClick: (info: MessageReceiversList[0]) => void;
  selectedWorkers?: MessageReceiversList;
  loading: boolean;
}) => (
  <>
    <ListItemButton
      dense={phoneOnly}
      disabled={loading}
      divider={true}
      id={`worker-${info.id}`}
      sx={{
        padding: '0',
        paddingBottom: phoneOnly ? '3px' : undefined,
      }}
      onClick={() => handleClick(info)}
    >
      <ListItem alignItems="flex-start" dense={phoneOnly}>
        <ListItemAvatar>
          <Avatar
            firstName={info.first_name}
            rounded={true}
            size={phoneOnly ? 'sm' : 'm'}
            src={info.avatar_url}
          />
        </ListItemAvatar>
        <ListItemText
          primary={`${info.first_name || ''} ${info.middle_name || ''} ${
            info.last_name || ''
          }`}
          primaryTypographyProps={{
            component: 'p',
            fontWeight: 'bold',
            paddingTop: '2px',
          }}
          secondary={info.email}
        />
        {selectedWorkers && (
          <div style={{ marginTop: '18px' }}>
            {selectedWorkers.find((sw) => sw.id === info.id) ? (
              <CheckCircle htmlColor="#44A735" id="selected-icon" />
            ) : (
              <CircleOutlined htmlColor="#D3D3D3" id="unselected-icon" />
            )}
          </div>
        )}
      </ListItem>
    </ListItemButton>
  </>
);
export default ChatWorkerList;

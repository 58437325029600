import { configureStore } from '@reduxjs/toolkit';

import filtersReducer from './slices/filtersSlice';
import layoutReducer from './slices/layoutSlice';
import pubnubReducer from './slices/pubnubSlice';

export const store = configureStore({
  reducer: {
    layout: layoutReducer,
    filters: filtersReducer,
    pubnub: pubnubReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import useModal from '@area2k/use-modal';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';

import AddCustomeRateModal from './modals/AddCustomRateModal';
import ConfirmDeleteRate from './modals/confirmDeleteCustomRate';
import UpdateRateModal from './modals/editCustomRateModal';

import Avatar from '@/components/Avatar';
import Card from '@/components/Card';
import EmptyState from '@/components/EmptyState';
import IconicButton from '@/components/IconicButton';
import Stack from '@/components/Stack';
import Table from '@/components/Table';
import TableHeader from '@/components/TableHeader';
import { TableCell, TableRow } from '@/elements/Table';
import { GetCustomerQueryResult } from '@/graphql';
import useMediaQuery from '@/hooks/useMediaQuery';
import { GetCustomerQuery, Rate } from '@/types/graphql';
import { centsToCurrency } from '@/util/number';

type Props = {
  customer: GetCustomerQuery['customer'];
  refetchGetCustomerQuery: GetCustomerQueryResult['refetch'];
  accordion?: boolean;
};

const CustomPayRateList = ({
  customer,
  refetchGetCustomerQuery,
  accordion = false,
}: Props) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const [showCreateModal, hideAddRateModal] = useModal(
    () => (
      <AddCustomeRateModal
        customer={customer}
        hideModal={() => {
          hideAddRateModal();
          refetchGetCustomerQuery();
        }}
      />
    ),
    [customer]
  );

  const [showDeleteModal, hideDeleteModal] = useModal<Rate>(
    ({ id }) => (
      <ConfirmDeleteRate
        customerId={customer.id}
        hideModal={hideDeleteModal}
        rateId={id}
      />
    ),
    [customer]
  );

  const [showEditModal, hideEditRateModal] = useModal<Rate>(
    (rate) => (
      <UpdateRateModal
        customer={customer}
        hideModal={hideEditRateModal}
        rate={rate}
      />
    ),
    [customer]
  );

  const haveRateQuotes = customer.addresses.some(
    (adrress) => adrress.rateQuotes && adrress.rateQuotes.length !== 0
  );

  const headerFields = [
    '',
    'Job Skill',
    'Pay Rate',
    'Billing Rate',
    'Address',
    'Billing Account',
    'Actions',
  ];

  return (
    <Card
      accordion={accordion}
      actions={[
        {
          a11yLabel: 'Create a new custome pay rate',
          label: 'Create',
          onAction: showCreateModal,
          id: 'create-custom-rate',
        },
      ]}
      title={phoneOnly ? 'Rates' : 'Custom rates'}
    >
      <Table>
        {!phoneOnly && <TableHeader fields={headerFields} />}
        <tbody>
          {haveRateQuotes &&
            customer.addresses.map((address) =>
              address.rateQuotes?.map((rateQuote) => (
                <TableRow key={rateQuote.id} mobile={phoneOnly}>
                  <TableCell noCellTitle stack={phoneOnly}>
                    <Stack justify={'apart'}>
                      <Avatar size="xs" src={rateQuote.skill?.imageUrl} />
                      {phoneOnly && (
                        <Stack justify={'end'}>
                          <IconicButton
                            a11yLabel="edit"
                            appearance="plain"
                            icon={faPen}
                            size="sm"
                            onClick={() => showEditModal(rateQuote as Rate)}
                          />
                          <IconicButton
                            a11yLabel="delete"
                            appearance="plain"
                            icon={faTrash}
                            size="sm"
                            onClick={() => showDeleteModal(rateQuote as Rate)}
                          />
                        </Stack>
                      )}
                    </Stack>
                  </TableCell>
                  <TableCell
                    data-celltitle={`${headerFields[1]}: `}
                    stack={phoneOnly}
                  >
                    {rateQuote.skill?.name}
                  </TableCell>
                  <TableCell
                    data-celltitle={`${headerFields[2]}: `}
                    stack={phoneOnly}
                  >
                    ${centsToCurrency(rateQuote.pay)}
                  </TableCell>
                  <TableCell
                    data-celltitle={`${headerFields[3]}: `}
                    stack={phoneOnly}
                  >
                    ${centsToCurrency(rateQuote.cost)}
                  </TableCell>
                  <TableCell
                    data-celltitle={`${headerFields[4]}: `}
                    stack={phoneOnly}
                  >
                    {address.addressLine1}
                  </TableCell>
                  <TableCell
                    data-celltitle={`${headerFields[5]}: `}
                    stack={phoneOnly}
                  >
                    {rateQuote.account?.name}
                  </TableCell>
                  {!phoneOnly && (
                    <TableCell
                      data-celltitle={`${headerFields[6]}: `}
                      stack={phoneOnly}
                    >
                      <Stack>
                        <IconicButton
                          a11yLabel="edit"
                          appearance="plain"
                          icon={faPen}
                          size="sm"
                          onClick={() => showEditModal(rateQuote as Rate)}
                        />
                        <IconicButton
                          a11yLabel="delete"
                          appearance="plain"
                          icon={faTrash}
                          size="sm"
                          onClick={() => showDeleteModal(rateQuote as Rate)}
                        />
                      </Stack>
                    </TableCell>
                  )}
                </TableRow>
              ))
            )}
        </tbody>
      </Table>
      {!haveRateQuotes && (
        <EmptyState
          text="Add a rate by clicking the button above"
          title="No custom rates"
        />
      )}
    </Card>
  );
};

export default CustomPayRateList;

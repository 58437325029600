/* eslint-disable react/display-name */
import { VariantProps, CSS } from '@stitches/react';
import {
  ComponentPropsWithoutRef,
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
} from 'react';

import Cover from '@/elements/Cover';
import Input from '@/elements/Input';
import styled from '@/styles';

const FocusCover = styled(Cover, {
  backgroundColor: 'white',
  border: '1px solid $colors$neutralLight',
  borderRadius: '$lg',
  '@phoneOnly': {
    border: '1px solid #D3D3D3',
    borderRadius: '10px',
  },

  focusPseudoElement: {
    element: 'after',
    activator: 'input:focus + &',
    borderWidth: 1,
  },
});

const Wrapper = styled('div', {
  display: 'flex',
  [`> ${Input}`]: {
    background: 'none',
    borderColor: 'transparent',
    zIndex: 2,
  },
});

type Props = ComponentPropsWithoutRef<'input'> &
  VariantProps<typeof Input> & {
    showFocusCover?: boolean;
    css?: CSS;
  };
type TextInputType = ForwardRefExoticComponent<
  Props & RefAttributes<HTMLInputElement>
>;

const TextInput = forwardRef<HTMLInputElement>((props: Props, ref) => {
  const { showFocusCover = true } = props;
  return (
    <Wrapper style={props.width && { width: `${props.width}` }}>
      <Input {...props} ref={ref} />
      {showFocusCover && <FocusCover />}
    </Wrapper>
  );
}) as TextInputType;

export default TextInput;

import { useReactiveVar } from '@apollo/client';
import { SubmitHelpers } from '@area2k/use-form';
import { useCallback, useMemo, useState } from 'react';
import { Navigate } from 'react-router-dom';

import Button from '@/components/Button';
import Card from '@/components/Card';
import Page from '@/components/Page';
import Stack from '@/components/Stack';
import TextStack from '@/components/TextStack';
import { Body, Display } from '@/components/Typography';
import Version from '@/components/Version';
import Link from '@/elements/Link';
import Form from '@/form';
import FormFooter from '@/form/FormFooter';
import TextField from '@/form/TextField';
import { useForgotPasswordMutation } from '@/graphql';
import useLocationSearch from '@/hooks/useLocationSearch';
import { TypeAppEnum } from '@/types/graphql';
import { currentTenantVar } from '@/util/apollo/cache';
import { handleMutationFormError } from '@/util/error';
import ls, { DEVICE_TOKEN_KEY } from '@/util/localstorage';
import { isWeb } from '@/util/platform';

type FormValues = { email: string };

const initialValues: FormValues = { email: '' };

const ForgotPassword = () => {
  const [onSend, setOnSend] = useState<boolean>(false);
  const { to } = useLocationSearch();

  const deviceToken = ls.get(DEVICE_TOKEN_KEY);
  const destination = useMemo(() => (typeof to === 'string' ? to : '/'), [to]);

  const currentTenant = useReactiveVar(currentTenantVar);
  const [userForgotPassword] = useForgotPasswordMutation();

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        await userForgotPassword({
          variables: { ...values, app: TypeAppEnum.WEB },
        });
        setOnSend(true);
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
          errorMap: {
            all: (gqlError) => ({
              title: gqlError.name,
              message: gqlError.message,
              status: 'danger',
            }),
          },
        });
      }
    },
    [currentTenant]
  );

  if (deviceToken) {
    return <Navigate replace to={destination} />;
  }

  return (
    <Page size="xs">
      <div style={{ padding: '64px 0' }}>
        <Stack vertical gap={24}>
          <div
            style={{
              padding: '0 12.5% 16px',
              width: '100%',
              textAlign: 'center',
            }}
          >
            <Display>{currentTenant?.name}</Display>
          </div>
          {onSend ? (
            <Card title={'Check your email'}>
              <Card.Section>
                <Stack vertical verticalGap={25}>
                  <TextStack>
                    <Body>
                      We have just sent an email with instructions to reset your
                      password.
                    </Body>
                  </TextStack>
                  <Stack justify="end">
                    <Link to={isWeb() ? '/login' : '/app-login'}>
                      <Button a11yLabel="OK" id="ok-btn" type="button" />
                    </Link>
                  </Stack>
                </Stack>
              </Card.Section>
            </Card>
          ) : (
            <Card title="Reset Password">
              <Card.Section>
                <Stack vertical verticalGap={25}>
                  <TextStack>
                    <Body>
                      Please enter the email associated with your account. We’ll
                      send you a link to reset your password!
                    </Body>
                  </TextStack>
                  <Form
                    initialValues={initialValues}
                    style={{ width: '100%' }}
                    onSubmit={handleSubmit}
                  >
                    <TextField
                      autoFocus
                      required
                      autoComplete="email"
                      fieldId="email"
                      label="Email Address"
                      placeholder="Email Address"
                      type="email"
                    />
                    <FormFooter>
                      <Link to={isWeb() ? '/login' : '/app-login'}>
                        <Button
                          a11yLabel="Cancel"
                          appearance="secondary"
                          id="cancel-btn"
                          type="button"
                        />
                      </Link>
                      <Button
                        a11yLabel="Send Link"
                        id="send-link-btn"
                        type="submit"
                      />
                    </FormFooter>
                  </Form>
                </Stack>
              </Card.Section>
            </Card>
          )}
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Version size="sm" />
          </div>
        </Stack>
      </div>
    </Page>
  );
};

export default ForgotPassword;

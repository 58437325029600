import { useMemo } from 'react';

import { SkillItem } from '@/types';
import { ListAgencySkillsQuery } from '@/types/graphql';
import { sortBy } from '@/util/array';

const useGetSortSkillItems = (
  data: ListAgencySkillsQuery | undefined,
  skills: SkillItem[],
  debouncedQuery: string
) => {
  const items: SkillItem[] = useMemo(() => {
    if (data) {
      const availableSkills = data.agency.skillCategories
        .flatMap((category) => category.skills)
        .filter((skill) => {
          if (skills.some((workerSkill) => workerSkill.id === skill.id)) {
            return false;
          }

          return skill.name
            .toLowerCase()
            .includes(debouncedQuery.toLowerCase());
        });

      return sortBy(availableSkills, 'name');
    }

    return [];
  }, [skills, data, debouncedQuery]);

  const sortedWorkerSkills = useMemo(() => sortBy(skills, 'name'), [skills]);

  return { items, sortedWorkerSkills };
};

export default useGetSortSkillItems;

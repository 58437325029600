import Stack from '@/components/Stack';
import Shimmer from '@/elements/Shimmer';
import styled from '@/styles';
import { nTimes } from '@/util/array';

const Tag = styled(Shimmer, {
  height: 22,
  width: 88,
});

export type Props = {
  count?: number;
  gap?: number;
};

const TagList = ({ count = 5, gap }: Props) => {
  return (
    <Stack wrap gap={gap}>
      {nTimes(count, (index) => (
        <Tag key={index} />
      ))}
    </Stack>
  );
};

Tag.displayName = 'stitches(Skeleton.TagList.Tag)';

export default TagList;

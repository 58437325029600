import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import { useState } from 'react';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import { Small } from '@/components/Typography';
import { MANDATE_TEXT } from '@/constants/text';
import { useUpdatePaymentIntentAchProcessMutation } from '@/graphql';
import { GetInvoiceQuery, Scalars } from '@/types/graphql';
import { isWeb } from '@/util/platform';

type InvoicePayCheckoutModalProps = {
  invoice: GetInvoiceQuery['invoice'];
  clientSecret: Scalars['String'];
  paymentMethodId: Scalars['String'];
  hideModal: () => Scalars['Void'];
  onCompleteCheckout: () => Scalars['Void'];
};

const InvoicePayCheckoutModal = ({
  invoice,
  clientSecret,
  paymentMethodId,
  hideModal,
  onCompleteCheckout,
}: InvoicePayCheckoutModalProps) => {
  const stripe = isWeb() ? useStripe() : undefined;
  const [stripeError, setStripeError] = useState<StripeError | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [updatePaymentIntentAchProcess] =
    useUpdatePaymentIntentAchProcessMutation({
      variables: {
        invoiceId: invoice.id,
        clientSecret,
        paymentMethodId,
      },
    });
  const handleOnClick = async () => {
    try {
      if (!stripe) {
        return;
      }

      setIsLoading(true);
      const { paymentIntent, error } = await stripe.confirmUsBankAccountPayment(
        clientSecret
      );

      if (error) {
        setStripeError(error);
        return;
      }

      if (paymentIntent.status === 'processing') {
        await updatePaymentIntentAchProcess();
        onCompleteCheckout();
      }
    } catch (error) {
      setStripeError(error as StripeError);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      disableClickout
      size="xs"
      title="Checkout"
      onRequestClose={hideModal}
    >
      <Card.Section>
        {stripeError && (
          <Alert
            description={stripeError.message}
            icon={faExclamationTriangle}
            status="warning"
            title="Something went wrong"
          />
        )}
        <Small>{MANDATE_TEXT}</Small>
      </Card.Section>
      <Card.Footer justify="end">
        <Button
          a11yLabel="Accept Mandate"
          isLoading={isLoading}
          label="Accept"
          onClick={handleOnClick}
        />
      </Card.Footer>
    </Modal>
  );
};

export default InvoicePayCheckoutModal;

import { useReactiveVar } from '@apollo/client';
import useModal from '@area2k/use-modal';
import { faFileAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import NewPayrollModal from './NewPayrollModal';
import PayrollStatusBadge from './PayrollStatusBadge';
import PageSkeleton from './Skeletons/ListPayrollsSkeleton';

import Card from '@/components/Card';
import EmptyState from '@/components/EmptyState';
import Page from '@/components/Page';
import Paginator from '@/components/Paginator/Paginator';
import Stack from '@/components/Stack';
import Table from '@/components/Table';
import TableHeader from '@/components/TableHeader';
import { Small } from '@/components/Typography';
import { TableCell, TableRow } from '@/elements/Table';
import { useListPayrollsLazyQuery } from '@/graphql';
import useMediaQuery from '@/hooks/useMediaQuery';
import { ListPayrollsQuery } from '@/types/graphql';
import { currentTenantVar } from '@/util/apollo/cache';
import { formatDate, formatHumanDate, formatDateTime } from '@/util/datetime';
import Alert from '@/components/Alert';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { useAppSelector, useAppDispatch } from '@/hooks/store';
import {
  ReloadInvoicePayrollStatusEnum,
  setReloadPayrolls,
} from '@/store/slices/pubnubSlice';

type PayrollItem = ListPayrollsQuery['allPayrolls']['items'][0];

export const payrollHeaderFields = [
  'ID',
  'Payday',
  'Period Start',
  'Period End',
  'Approval Deadline',
  'Approved on',
  'Payroll Status',
];

const Payrolls = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [payrollInitiatedMessage, setPayrollInitiatedMessage] = useState(false);
  const payrolllBackgroundJob = useFeatureValue(
    FEATURE_TOGGLE.EnablePayrollProcessBackgroundJob,
    false,
  );
  const navigate = useNavigate();

  const currentTenant = useReactiveVar(currentTenantVar);
  const tenantId = currentTenant!.id;
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const reloadPayrolls = useAppSelector((state) => state.pubnub.reloadPayrolls);
  const dispatch = useAppDispatch();

  const [refetch, { data, loading }] = useListPayrollsLazyQuery({
    fetchPolicy: 'network-only',
  });

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleNumberItemsChange = (event) => {
    setCurrentPage(1);
    setItemsPerPage(parseInt(event.target.value));
  };

  const items = !loading && data ? data.allPayrolls.items : [];
  const pageInfo =
    !loading && data ? data.allPayrolls.pageInfo : { totalItems: 0 };

  useEffect(() => {
    if (reloadPayrolls !== ReloadInvoicePayrollStatusEnum.OFF) {
      dispatch(setReloadPayrolls(ReloadInvoicePayrollStatusEnum.OFF));
      setPayrollInitiatedMessage(false);
    }

    refetch({
      variables: { tenantId, page: currentPage, perPage: itemsPerPage },
    });
  }, [tenantId, currentPage, itemsPerPage, reloadPayrolls]);

  const [showNewPayrollModal, hideNewPayrollModal] = useModal(
    () => (
      <NewPayrollModal
        hideModal={hideNewPayrollModal}
        setPayrollInitiatedMessage={setPayrollInitiatedMessage}
      />
    ),
    [refetch],
  );

  const renderPayrolls = useCallback(
    (payroll: PayrollItem, phoneOnly: boolean) => {
      return (
        <TableRow
          key={payroll.id}
          clickable
          mobile={phoneOnly}
          onClick={() => navigate(`${payroll.id}?status=${payroll.status}`)}
        >
          <TableCell noCellTitle stack>
            <Stack
              align={'start'}
              gap={1}
              justify={'start'}
              vertical={!phoneOnly}
            >
              {phoneOnly && (
                <Small size={'lg'} weight={'semibold'}>
                  ID:{' '}
                </Small>
              )}
              <Small
                color={'theme'}
                size={phoneOnly ? 'lg' : 'md'}
                weight={phoneOnly ? 'semibold' : 'normal'}
              >
                {payroll.id}
              </Small>
            </Stack>
          </TableCell>
          <TableCell data-celltitle={'Payday: '} stack={phoneOnly}>
            {formatDate(payroll.payday)}
          </TableCell>
          <TableCell data-celltitle={'Period Start: '} stack={phoneOnly}>
            {formatHumanDate(payroll.periodStart)}
          </TableCell>
          <TableCell data-celltitle={'Period End: '} stack={phoneOnly}>
            {formatHumanDate(payroll.periodEnd)}
          </TableCell>
          <TableCell data-celltitle={'Approval Deadline: '} stack={phoneOnly}>
            {formatDateTime(payroll.approvalDeadline)}
          </TableCell>
          <TableCell data-celltitle={'Approved On: '} stack={phoneOnly}>
            {payroll.approvedAt !== null
              ? formatDateTime(payroll.approvedAt)
              : 'N/A'}
          </TableCell>
          <TableCell data-celltitle={'Payroll Status: '} stack={phoneOnly}>
            <Stack>
              <PayrollStatusBadge status={payroll.status} />
            </Stack>
          </TableCell>
        </TableRow>
      );
    },
    [],
  );

  if (!data) {
    return <PageSkeleton />;
  }

  const { allPayrolls } = data;

  return (
    <Page
      headerPadding={!!phoneOnly}
      noPadding={!!phoneOnly}
      primaryAction={{
        a11yLabel: 'Create a Preview Payroll',
        label: 'Run New Payroll',
        id: 'new-payroll-btn',
        onAction: showNewPayrollModal,
      }}
      size={phoneOnly ? 'full' : 'lg'}
      title="Payroll Runs"
    >
      {payrolllBackgroundJob && payrollInitiatedMessage && (
        <Alert
          description="Your payroll is being generated. This process may take a minute to complete. Please refresh the page after a moment to view the results. Do not attempt to generate the payroll again while the process is in progress."
          icon={faInfoCircle}
          status="info"
        />
      )}
      <Card noRadius={phoneOnly}>
        <Table>
          {allPayrolls.items.length > 0 ? (
            <>
              {!phoneOnly && <TableHeader fields={payrollHeaderFields} />}
              <tbody>
                {allPayrolls.items.map((i) => {
                  return renderPayrolls(i, phoneOnly);
                })}
              </tbody>
            </>
          ) : (
            <Stack justify="center">
              <EmptyState
                icon={faFileAlt}
                text={`Click "Run New Payroll" to add`}
                title="No payrolls have been created yet"
              />
            </Stack>
          )}
        </Table>
      </Card>
      {items.length > 0 && (
        <Paginator
          currentPage={currentPage}
          handleNumberItemsChange={handleNumberItemsChange}
          handlePageChange={handlePageChange}
          itemsLength={pageInfo.totalItems as number}
          itemsPerPage={itemsPerPage}
        />
      )}
    </Page>
  );
};

export default Payrolls;

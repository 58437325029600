import { parseISO } from 'date-fns';

import { dateParser } from '../util';

import FormElement from '@/components/FormElement';
import Input from '@/elements/Input';
import FormColumns from '@/form/FormColumns';
import styled from '@/styles';

const Wrapper = styled('div', {
  '& *': {
    boxSizing: 'content-box',
    position: 'unset',
  },

  '& .rdrNextPrevButton': {
    backgroundColor: 'white',
  },
});

Wrapper.displayName = 'stitches(DateRangePicker.Wrapper)';

export type DateRange = {
  startDate: Date | null;
  endDate: Date | null;
};

export type DateContext = 'startDate' | 'endDate';

type Props = {
  maxDate?: string;
  minDate?: string;
  startDate: Date | null;
  endDate: Date | null;
  onChange: (selection: any, context: DateContext) => void;
};

const DateRangeInput = ({
  minDate,
  maxDate,
  onChange,
  startDate,
  endDate,
}: Props) => (
  <FormColumns>
    <FormElement label="Start Date">
      <Input
        id="start-date"
        max={maxDate}
        min={minDate}
        type="date"
        value={startDate ? dateParser(startDate) : ''}
        onChange={(e) => {
          onChange(parseISO(e.target.value), 'startDate');
        }}
      />
    </FormElement>
    <FormElement label="End Date">
      <Input
        id="end-date"
        max={maxDate}
        min={minDate}
        type="date"
        value={endDate ? dateParser(endDate) : ''}
        onChange={(e) => {
          onChange(parseISO(e.target.value), 'endDate');
        }}
      />
    </FormElement>
  </FormColumns>
);

export default DateRangeInput;

import { createSvgIcon } from '@mui/material';

const PlusIcon = createSvgIcon(
  <svg
    fill="none"
    height="20"
    viewBox="0 0 512 512"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M512 292.571H292.572v219.428h-73.144V292.571H0v-73.142h219.428V.001h73.144v219.428H512v73.142z"
      fill="currentColor"
    />
  </svg>,
  'PlusIcon'
);
export default PlusIcon;

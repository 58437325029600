import { StyledPage } from '../SignUp/styles';

import Action from '@/components/Action';
import Card from '@/components/Card';
import GravyWorkLogo from '@/components/GravyWorkLogo';
import Stack from '@/components/Stack';
import { Body, Subheading } from '@/components/Typography';
import ROUTES from '@/routes/routes';

const LinkExpirePage = () => {
  return (
    <StyledPage size="xs">
      <Card>
        <Stack css={{ paddingTop: '20px' }} justify="center">
          <GravyWorkLogo />
        </Stack>
        <Card.Section>
          <Stack vertical>
            <Subheading>This Link has expired</Subheading>
            <Body>{"Click 'OK' to log into GravyWork."}</Body>
          </Stack>
        </Card.Section>
        <Card.Footer>
          <Stack justify="end">
            <Action.Button
              action={{
                a11yLabel: `Open new tab to login`,
                label: 'Ok',
                href: ROUTES.login,
              }}
              appearance="primary"
            />
          </Stack>
        </Card.Footer>
      </Card>
    </StyledPage>
  );
};

export default LinkExpirePage;

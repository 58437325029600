import { createSvgIcon } from '@mui/material';

const MarkerIcon = createSvgIcon(
  <svg
    fill="none"
    height="17"
    viewBox="0 0 14 17"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5535 7.3119C12.5535 11.4586 7.22205 15.0129 7.22205 15.0129C7.22205 15.0129 1.89062 11.4586 1.89062 7.3119C1.89063 5.89791 2.45233 4.54184 3.45216 3.54201C4.452 2.54217 5.80807 1.98047 7.22205 1.98047C8.63603 1.98047 9.9921 2.54217 10.9919 3.54201C11.9918 4.54184 12.5535 5.89791 12.5535 7.3119Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.22246 9.08944C8.20394 9.08944 8.9996 8.29379 8.9996 7.3123C8.9996 6.33081 8.20394 5.53516 7.22246 5.53516C6.24097 5.53516 5.44531 6.33081 5.44531 7.3123C5.44531 8.29379 6.24097 9.08944 7.22246 9.08944Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'MarkerIcon'
);
export default MarkerIcon;

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useState } from 'react';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { Body } from '@/components/Typography';
import {
  GetInvoiceDetailsDocument,
  ListInvoicesDocument,
  useVoidInvoiceMutation,
} from '@/graphql';
import { StripeInvoice } from '@/types/graphql';

type Props = {
  invoiceId: StripeInvoice['id'];
  hideModal: () => void;
};

const ConfirmVoidInvoice = ({ invoiceId, hideModal }: Props) => {
  const [error, setError] = useState<any>(null);

  const [voidInvoice, { loading: isLoading }] = useVoidInvoiceMutation({
    refetchQueries: [
      { query: GetInvoiceDetailsDocument, variables: { invoiceId } },
      { query: ListInvoicesDocument },
    ],
  });

  const handleVoidInvoice = useCallback(async () => {
    try {
      await voidInvoice({
        variables: {
          invoiceId,
        },
      });
      hideModal();
    } catch (err) {
      setError(err);
    }
  }, [invoiceId]);

  return (
    <Modal
      disableClickout
      size="sm"
      title="Are you sure you want to void this invoice?"
      onRequestClose={hideModal}
    >
      <Card.Section>
        {error && (
          <Alert
            description={error.message}
            icon={faExclamationTriangle}
            status="warning"
            title={error.title}
          />
        )}
        <Body>
          This invoice will be cancelled and another one will be generated.
        </Body>
        <Stack css={{ marginTop: '10px' }} gap={10} justify="end">
          <Button
            a11yLabel="deny"
            appearance="outline"
            label="Cancel"
            status="danger"
            type="button"
            onClick={hideModal}
          />

          <Button
            a11yLabel="accept"
            isLoading={isLoading}
            label="Accept"
            type="submit"
            onClick={handleVoidInvoice}
          />
        </Stack>
      </Card.Section>
    </Modal>
  );
};

export default ConfirmVoidInvoice;

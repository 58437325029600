import { AccountStateErrorIcon } from '@/assets/icons';
import Stack from '@/components/Stack';
import { Body } from '@/components/Typography';
import Text from '@/elements/Text';
import styled from '@/styles';

const Wrapper = styled('div', {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  padding: '32px 0',
});

export type Props = {
  message?: string;
  title?: string;
};

const AccountStateError = ({ message, title }: Props) => {
  return (
    <Wrapper>
      <Stack vertical align="center">
        <div style={{ padding: '25px 0px' }} />
        <div>
          <AccountStateErrorIcon />
        </div>
        <div style={{ textAlign: 'center', padding: '0px 0px', width: '50%' }}>
          {title && (
            <Text as="h4" color="default" size="md" weight="medium">
              {title}
            </Text>
          )}
        </div>
        <div style={{ textAlign: 'center', padding: '0px 0px', width: '80%' }}>
          {message && (
            <Body
              color="light"
              dangerouslySetInnerHTML={{
                __html: message.replace(/\n/g, '<br />'),
              }}
            />
          )}
        </div>
        <div style={{ padding: '80px 0px' }} />
      </Stack>
    </Wrapper>
  );
};

AccountStateError.Wrapper = Wrapper;
Wrapper.displayName = 'stitches(AccountStateError.Wrapper)';

export default AccountStateError;

import useModal from '@area2k/use-modal';
import { differenceInHours, parseISO } from 'date-fns';
import { useMemo } from 'react';

import UpdateGravyWorkers from './UpdateGravyWorkers';

import Card from '@/components/Card';
import Stack from '@/components/Stack';
import TextStack from '@/components/TextStack';
import { Small } from '@/components/Typography';
import useAuth from '@/hooks/useAuth';
import { GetJobQuery, JobStatusEnum } from '@/types/graphql';
import { DAY_HOURS } from '@/util/constants';
import { getHiredWorkersByJobWorkers } from '@/util/job';

type Props = {
  job: GetJobQuery['job'];
};

const EmployeeSection = ({ job }: Props) => {
  const { currentAdminIsCustomerAdmin } = useAuth();
  const { quantity, jobWorkers, firstShiftStartAt, status } = job;

  const jobWorkersCount = useMemo(
    () => getHiredWorkersByJobWorkers(jobWorkers).length,
    [jobWorkers]
  );

  const { isEditable, limitOfEdit } = useMemo(() => {
    const ShiftStartAt = parseISO(firstShiftStartAt);

    const differenceHours = differenceInHours(ShiftStartAt, new Date());

    const isEditable =
      (!currentAdminIsCustomerAdmin ||
        (currentAdminIsCustomerAdmin && differenceHours >= DAY_HOURS)) &&
      status === JobStatusEnum.UPCOMING;

    const limitOfEdit = jobWorkersCount > 1 ? jobWorkersCount : 1;

    return {
      isEditable,
      limitOfEdit,
    };
  }, [status, quantity, jobWorkersCount]);

  const [showUpdateModal, hideUpdateModal] = useModal(
    () => (
      <UpdateGravyWorkers
        hideModal={hideUpdateModal}
        job={job}
        limitOfEdit={limitOfEdit}
      />
    ),
    [job]
  );

  return (
    <Card.Section
      actions={
        isEditable
          ? [
              {
                a11yLabel: 'Change needed workers',
                label: 'Edit',
                id: 'edit-worker-count',
                onAction: () => showUpdateModal(),
              },
            ]
          : undefined
      }
      style={{ padding: '10px 20px' }}
      title="GravyWorkers"
      titleStyle={{ paddingBottom: '5px' }}
    >
      <Stack vertical>
        <TextStack>
          <Small>
            {jobWorkersCount} / {quantity} Hired
          </Small>
        </TextStack>
      </Stack>
    </Card.Section>
  );
};

export default EmployeeSection;

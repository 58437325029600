import { GRAVY_CERTIFICATES } from '@/constants/general';
import { Certificate, InterviewStatusEnum } from '@/types/graphql';

export const parseInterViewQuestionarieStatus = (
  status: InterviewStatusEnum | null
) => {
  if (status === null) {
    return 'To Review';
  }

  return (
    {
      [InterviewStatusEnum.INTERVIEW_DECLINED]: 'Declined',
      [InterviewStatusEnum.INTERVIEW_PASSED]: 'Passed',
    }[status] || 'To Review'
  );
};

export const containsGravyCertification = (
  certifications: Partial<Certificate>[]
) => {
  return certifications?.some((certification) =>
    GRAVY_CERTIFICATES.includes(certification.name!)
  );
};

export const checkGravyCertificationByName = (certificationName: string) => {
  return GRAVY_CERTIFICATES.includes(certificationName);
};

export const workerName = (worker: any) => {
  if (worker && worker.user) {
    const middleName = worker.user.middleName || '';
    const lastName = worker.user.lastName || '';
    return `${worker.user.firstName} ${middleName} ${lastName}`;
  }
  return '';
};

import {
  faCheck,
  faHeart,
  faPlus,
  faUserCheck,
} from '@fortawesome/free-solid-svg-icons';
import StarIcon from '@mui/icons-material/Star';
import { Box, Stack as MuiStack } from '@mui/material';
import { useState } from 'react';
import { useModal } from 'react-modal-hook';

import { Schedule } from '../../../context';

import { GravyTrained, NoFavIcon } from '@/assets/icons';
import Avatar from '@/components/Avatar';
import Button from '@/components/Button';
import EmptyState from '@/components/EmptyState';
import TextStack from '@/components/TextStack';
import { Body } from '@/components/Typography';
import Icon from '@/elements/Icon';
import Text from '@/elements/Text';
import ProfileModalRevamp from '@/routes/Agency/Job/WorkerTable/ProfileModal/ProfileModalRevamp';
import { JobWorkerItemFragment } from '@/types/graphql';
import { WorkerTabs } from '@/util/axios/types';
import { workerName } from '@/util/worker';

export const RenderWorkerInfo = ({
  workerInfo,
  isClientAdmin,
  modalView,
  skillName,
}: {
  workerInfo: Record<string, any>;
  isClientAdmin: boolean;
  modalView?: boolean;
  skillName?: string;
}) => {
  const [showProfileRevampModal, hideProfileRevampModal] = useModal(() => (
    <ProfileModalRevamp
      ProfileInfo={workerInfo as any}
      hideModal={hideProfileRevampModal}
      isClientAdmin={isClientAdmin}
      workerId={workerInfo.id}
      relationshipStatus={null}
    />
  ));
  const [highlight, setHighlight] = useState(false);
  const isCertified = !!workerInfo.IsTrained;

  const handleClick = () => {
    showProfileRevampModal();
  };
  const handleMouseEnter = () => setHighlight(true);
  const handleMouseLeave = () => setHighlight(false);

  return (
    <MuiStack
      direction={'row'}
      spacing={1.5}
      sx={{ ':hover': { cursor: 'pointer' } }}
    >
      <MuiStack
        alignItems={'center'}
        id="worker-info-div"
        justifyContent={'center'}
        spacing={1.7}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Box sx={{ position: 'relative' }}>
          <Avatar
            border={isCertified}
            rounded={true}
            size="lg2"
            src={workerInfo.avatarUrl}
          />
          {isCertified && (
            <GravyTrained
              id="gravy-badge"
              sx={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '28px',
                height: '28px',
              }}
            />
          )}
        </Box>
      </MuiStack>
      <MuiStack justifyContent={'center'}>
        <div
          style={{ minHeight: '45px' }}
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <WorkerDesc
            highlight={highlight}
            modalView={modalView}
            skillName={skillName}
            workerInfo={workerInfo}
          />
        </div>
      </MuiStack>
    </MuiStack>
  );
};

export const WorkerDesc = ({
  workerInfo,
  highlight = false,
  modalView,
  skillName,
}: {
  workerInfo: JobWorkerItemFragment['worker'] | any;
  highlight?: boolean;
  modalView?: boolean;
  skillName?: string;
}) => {
  return (
    <TextStack>
      <Body
        color={highlight ? 'themeDefault' : undefined}
        css={{
          lineHeight: '1 !important',
          textDecoration: highlight ? 'underline' : 'none',
          fontSize: '16px',
        }}
        weight="medium"
      >
        {workerName(workerInfo)}
      </Body>
      <MuiStack alignItems={'center'} direction={'row'} spacing={0.6}>
        <StarIcon
          id="star-icon"
          sx={{ color: '#faaf00', width: '20px', height: '20px' }}
        />
        <Text size={'md'} weight={'semibold'}>
          {workerInfo.averageRating
            ? Number(workerInfo.averageRating).toFixed(1)
            : '0.0'}
        </Text>
        <Text color={'lighter'}>{`(${workerInfo.jobsRatingCount})`}</Text>
        {workerInfo?.IsFavorite === 1 && (
          <>
            <Text color="lightest">{' | '}</Text>
            <Icon
              fixedWidth
              icon={faHeart}
              style={{ width: 20, height: 17, color: '#45A735' }}
            />
          </>
        )}
      </MuiStack>
      {modalView && (
        <MuiStack direction={'row'} spacing={0.8} sx={{ mt: '4px' }}>
          <b>{workerInfo?.selectedSkillShift}</b>
          <Text color={'lighter'}>{`${skillName || ''} Shifts`}</Text>
          <Text color="lightest">{' | '}</Text>
          <b>{workerInfo?.shiftsWith}</b>
          <Text color={'lighter'}>Shifts With You</Text>
        </MuiStack>
      )}
    </TextStack>
  );
};

export const CountSection = ({
  isLTA,
  hiredCount,
  invitedCount,
  quantity,
  fullyStaffed,
  isClientAdmin,
}) => {
  return (
    <Text css={{ color: '#7A7A7A' }} lineHeightProp={'small'}>
      {isLTA ? (
        <>
          {`${hiredCount} of ${quantity} Hired`}
          {fullyStaffed && (
            <span style={{ color: '#45A735' }}>{' | Fully Staffed'}</span>
          )}
        </>
      ) : (
        <>
          {!isClientAdmin &&
            `${hiredCount} of ${quantity} Hired | ${
              !fullyStaffed ? `${invitedCount} Invited` : ''
            }`}

          {fullyStaffed && (
            <span style={{ color: '#45A735' }}>{'Fully Staffed'}</span>
          )}

          {isClientAdmin && `${invitedCount} of ${quantity} Invited`}
        </>
      )}
    </Text>
  );
};

export const NotFoundAlert = ({ tab }: { tab: number }) => {
  return tab === 1 ? (
    <EmptyState
      customIcon={<NoFavIcon sx={{ height: '250px', width: '250px' }} />}
      text="Add Gravyworkers as your favorites."
      title="No Favorites Yet!"
    />
  ) : (
    <EmptyState text="No Gravyworkers found." title="No Results Found" />
  );
};

export const getWorkersProfileImages = (
  workers: JobWorkerItemFragment['worker'][],
) => {
  return workers.map((w) => {
    if (w.avatarUrl) {
      return { url: w.avatarUrl };
    } else {
      return { url: undefined, name: w.user.firstName };
    }
  });
};

export const buttonStyles = {
  add: { borderColor: '#45A735', color: '#45A735' },
  addDisabled: { borderColor: '#A1A1A1', color: '#A1A1A1' },
  remove: { borderColor: '#262626', background: '#262626' },
};

export const getHiredButton = ({
  isHired,
  disable,
  handleClick,
  phoneOnly = false,
}) => {
  return isHired ? (
    <Button
      a11yLabel="hired button"
      iconLeft={faCheck}
      id="hired-btn"
      label="Hired"
      style={{
        ...buttonStyles.remove,
        width: phoneOnly ? '100%' : undefined,
      }}
      type="button"
      onClick={() => handleClick?.('remove')}
    />
  ) : (
    <Button
      a11yLabel="hire button"
      appearance="outline"
      disabled={disable}
      iconLeft={faUserCheck}
      id="hire-btn"
      label="Hire"
      style={
        disable
          ? {
              ...buttonStyles.addDisabled,
              width: phoneOnly ? '100%' : undefined,
            }
          : {
              ...buttonStyles.add,
              width: phoneOnly ? '100%' : undefined,
            }
      }
      type="button"
      onClick={() => handleClick?.()}
    />
  );
};
export const getInvitedButton = ({
  isInvited,
  disable,
  handleClick,
  phoneOnly = false,
}) => {
  return isInvited ? (
    <Button
      a11yLabel="invited button"
      iconLeft={faCheck}
      id="invite-btn"
      label="Invited"
      style={{
        ...buttonStyles.remove,
        width: phoneOnly ? '100%' : undefined,
      }}
      type="button"
      onClick={() => handleClick?.('remove')}
    />
  ) : (
    <Button
      a11yLabel="invite button"
      appearance="outline"
      disabled={disable}
      iconLeft={faPlus}
      id="invite-btn"
      label="Invite"
      style={
        disable
          ? {
              ...buttonStyles.addDisabled,
              width: phoneOnly ? '100%' : undefined,
            }
          : {
              ...buttonStyles.add,
              width: phoneOnly ? '100%' : undefined,
            }
      }
      type="button"
      onClick={() => handleClick?.()}
    />
  );
};

export const getTabs = ({
  counts,
  isTrained,
  isClientAdmin,
}: {
  counts?: Record<string, any>;
  isTrained?: boolean;
  isClientAdmin?: boolean;
}) => {
  const TABS = [
    {
      a11yLabel: 'All',
      label: 'All',
      minWidth: '100px',
    },
    {
      a11yLabel: 'Favorites',
      label: 'Favorites',
      id: counts?.favourite?.toString(),
      minWidth: '160px',
    },
  ];
  if (isTrained) {
    TABS.push({
      a11yLabel: 'GravyTrained',
      minWidth: '220px',
      // @ts-ignore
      label: (
        <>
          <GravyTrained
            id="gravy-badge"
            sx={{
              width: '20px',
              height: '20px',
              marginRight: '12px',
              top: 6,
            }}
          />
          GravyTrained
        </>
      ),
      id: counts?.trained?.toString(),
    });
  }
  TABS.push({
    a11yLabel: 'Highly Rated',
    label: 'Highly Rated',
    id: counts?.highlyRated?.toString(),
    minWidth: '200px',
  });
  TABS.push({
    a11yLabel: 'Past Workers',
    label: 'Past Workers',
    id: counts?.pastWorker?.toString(),
    minWidth: '200px',
  });
  if (!isClientAdmin) {
    TABS.push({
      a11yLabel: 'Others',
      label: 'Others',
      id: counts?.others?.toString(),
      minWidth: '100px',
    });
  }
  return TABS;
};

export const getTabName = (tab: number, isTrained: boolean) => {
  switch (tab) {
    case 0:
      return 'All';
    case 1:
      return 'Favorite';
    case 2:
      return isTrained ? 'GravyTrained' : 'Highly Rated';
    case 3:
      return isTrained ? 'Highly Rated' : 'Past Worker';
    case 4:
      return isTrained ? 'Past Worker' : 'Others';
    case 5:
      return 'Others';
    default:
      return 'All';
  }
};
export const getTabValue = (tab: number, isTrained: boolean) => {
  switch (tab) {
    case 0:
      return WorkerTabs.All;
    case 1:
      return WorkerTabs.Favorites;
    case 2:
      return isTrained ? WorkerTabs.GravyTrained : WorkerTabs.HighlyRated;
    case 3:
      return isTrained ? WorkerTabs.HighlyRated : WorkerTabs.PastWorkers;
    case 4:
      return isTrained ? WorkerTabs.PastWorkers : WorkerTabs.Others;
    case 5:
      return WorkerTabs.Others;
    default:
      return WorkerTabs.All;
  }
};

export const getMainHeading = (skillName, isClient, isLTA) => {
  return isLTA
    ? `Hire ${skillName}`
    : isClient
      ? `Invite ${skillName}`
      : `Hire & Invite for ${skillName}`;
};

export const getHiredUpdatedSchedules = (
  schedules: Schedule[],
  selectedSchedules: number[],
  worker: Record<string, any>,
  mode?: string,
) => {
  const removeSelection: number[] = [];
  const updatedSchedules = schedules.map((sch) => {
    if (selectedSchedules.includes(sch.groupId)) {
      if (mode === 'remove') {
        sch.hiredWorkers = sch.hiredWorkers.filter((w) => w.id !== worker.id);
      } else if (!sch.hiredWorkers.find((w) => w.id === worker.id)) {
        sch.hiredWorkers = [...sch.hiredWorkers, worker];
        sch.selectedWorkers = sch.selectedWorkers.filter(
          (w) => w.id !== worker.id,
        );
        if (sch.hiredWorkers.length === sch.quantity) {
          removeSelection.push(sch.groupId);
          sch.selectedWorkers = [];
        }
      }
    }
    return sch;
  });
  return { updatedSchedules, removeSelection };
};

import { useReactiveVar } from '@apollo/client';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useCallback } from 'react';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { Body } from '@/components/Typography';
import {
  GetPayrollDetailDocument,
  ListPayrollsDocument,
  useApprovePreviewPayrollMutation,
} from '@/graphql';
import { Payroll } from '@/types/graphql';
import { currentTenantVar } from '@/util/apollo/cache';

type Props = {
  payrollId: Payroll['id'];
  hideModal: () => void;
};

const ConfirmApprovalPayrollModal = ({ payrollId, hideModal }: Props) => {
  const currentTenant = useReactiveVar(currentTenantVar);
  const tenantId = currentTenant!.id;

  const [approvePreviewPayroll, { loading: isLoading, error }] =
    useApprovePreviewPayrollMutation({
      refetchQueries: [
        { query: GetPayrollDetailDocument, variables: { payrollId } },
        { query: ListPayrollsDocument, variables: { tenantId } },
      ],
      awaitRefetchQueries: true,
    });

  const handleApprovePreviewPayroll = useCallback(async () => {
    try {
      await approvePreviewPayroll({ variables: { payrollId } });
      hideModal();
    } catch (error) {
      console.error(error);
    }
  }, [payrollId]);

  return (
    <Modal
      disableClickout
      size="sm"
      title="Are you sure you want to approve this payroll?"
      onRequestClose={hideModal}
    >
      <Card.Section>
        {error ? (
          <>
            <Alert
              description={
                error.graphQLErrors[0].message ||
                'There was an error approving the payroll'
              }
              icon={faExclamationTriangle}
              status="danger"
              title="An error has occurred"
            />
            <Stack css={{ marginTop: '10px' }} gap={10} justify="end">
              <Button
                a11yLabel="close modal"
                id="ok-btn"
                label="Ok"
                status="danger"
                type="button"
                onClick={hideModal}
              />
            </Stack>
          </>
        ) : (
          <>
            <Body>After approving this payroll it cannot be deleted.</Body>
            <Stack css={{ marginTop: '10px' }} gap={10} justify="end">
              <Button
                a11yLabel="deny"
                appearance="outline"
                id="cancel-btn"
                label="Cancel"
                status="danger"
                type="button"
                onClick={hideModal}
              />

              <Button
                a11yLabel="accept"
                id="approve-btn"
                isLoading={isLoading}
                label="Approve"
                type="submit"
                onClick={handleApprovePreviewPayroll}
              />
            </Stack>
          </>
        )}
      </Card.Section>
    </Modal>
  );
};

export default ConfirmApprovalPayrollModal;

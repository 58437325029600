import {
  CommentsCard,
  ExperiencesCard,
  InterviewCard,
  ProfileCard,
} from '../shared';

import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout';
import Stack from '@/components/Stack';
import { GetWorkerQuery } from '@/types/graphql';

type Props = {
  worker: GetWorkerQuery['worker'];
  currentAdminIsCustomerAdmin: boolean;
};

const STACK_GAP = 24;

const WorkerInProgress = ({ worker, currentAdminIsCustomerAdmin }: Props) => (
  <PrimarySecondaryLayout>
    <PrimarySecondaryLayout.Primary>
      <Stack vertical gap={STACK_GAP}>
        <InterviewCard worker={worker} />
        <CommentsCard worker={worker} />
        <ExperiencesCard worker={worker} />
      </Stack>
    </PrimarySecondaryLayout.Primary>
    <PrimarySecondaryLayout.Secondary>
      <Stack vertical gap={STACK_GAP}>
        <ProfileCard
          currentAdminIsCustomerAdmin={currentAdminIsCustomerAdmin}
          worker={worker}
        />
      </Stack>
    </PrimarySecondaryLayout.Secondary>
  </PrimarySecondaryLayout>
);

export default WorkerInProgress;

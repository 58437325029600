import useModal from '@area2k/use-modal';
import { useCallback, useReducer, useState } from 'react';

import Steps from './Steps';
import TopBar from './TopBar';
import {
  initialState,
  JobDraftActions,
  JobDraftActionsContext,
  JobDraftActionType,
  JobDraftStateContext,
  stateReducer,
  Step,
} from './context';
import ExitJobEditorModal from './modals/ExitJobEditorModal';

import Overlay from '@/elements/Overlay';
import useDisableBodyScroll from '@/hooks/useDisableBodyScroll';

type Props = {
  onClose: () => void;
};

const JobEditor = ({ onClose }: Props) => {
  useDisableBodyScroll();

  const [state, dispatch] = useReducer(stateReducer, initialState);

  const actions: JobDraftActions = {
    resetState: () => dispatch({ type: JobDraftActionType.RESET_STATE }),
    updateSkill: (changes) =>
      dispatch({ type: JobDraftActionType.UPDATE_SKILL, changes }),
    updateSchedules: (changes) =>
      dispatch({ type: JobDraftActionType.UPDATE_SCHEDULES, changes }),
    updateDetails: (changes) =>
      dispatch({ type: JobDraftActionType.UPDATE_DETAILS, changes }),
    updatePublishing: (changes) =>
      dispatch({ type: JobDraftActionType.UPDATE_PUBLISHING, changes }),
    updateCompletedStep: (changes) =>
      dispatch({ type: JobDraftActionType.UPDATE_COMPLETED_STEP, changes }),
    updateLastShift: (changes) =>
      dispatch({ type: JobDraftActionType.UPDATE_LAST_SHIFT, changes }),
    updateJob: (changes) =>
      dispatch({ type: JobDraftActionType.UPDATE_JOB, changes }),
    updateRates: (changes) =>
      dispatch({ type: JobDraftActionType.UPDATE_RATES, changes }),
  };

  const [currentStep, setCurrentStep] = useState(Step.SKILL);

  const [showExitJobEditorModal, hideExitJobEditorModal] = useModal(
    () => (
      <ExitJobEditorModal
        hideModal={hideExitJobEditorModal}
        onConfirm={() => {
          onClose();
          actions.resetState();
        }}
      />
    ),
    []
  );
  const closeModal = () => {
    onClose();
    actions.resetState();
  };
  const handleClose = useCallback(() => {
    if (state.completedStep) {
      showExitJobEditorModal();
      return;
    }

    closeModal();
  }, [state.completedStep]);

  return (
    <JobDraftActionsContext.Provider value={actions}>
      <JobDraftStateContext.Provider value={state}>
        <Overlay css={{ $$bgColor: 'white' }} id="create-order-modal">
          <TopBar
            completedStep={state.completedStep}
            currentStep={currentStep}
            onClose={handleClose}
          />
          <Steps
            setStep={setCurrentStep}
            step={currentStep}
            onClose={closeModal}
          />
        </Overlay>
      </JobDraftStateContext.Provider>
    </JobDraftActionsContext.Provider>
  );
};

export default JobEditor;

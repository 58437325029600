import { useReactiveVar } from '@apollo/client';
import useModal from '@area2k/use-modal';
import { faBell, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@mui/material';
import {
  parseISO,
  formatISO as formatISO8601,
  addDays,
  format,
  differenceInMinutes,
  startOfToday,
} from 'date-fns';
import React, { useState, useMemo, useCallback } from 'react';

import JobMonitorFilters from './JobMonitorFilter';
import JobTimer from './JobTimer';
import SubscribeEmailModal from './SubscribeEmailModal';
import WorkerDetailsTable from './WorkerDetailsTable';

import {
  PeopleExclaimIcon,
  PeopleSearchIcon,
  TimerExclaimIcon,
} from '@/assets/icons';
import Card from '@/components/Card';
import Chip from '@/components/Chip';
import IconicButton from '@/components/IconicButton';
import LoadingState from '@/components/LoadingState';
import Page from '@/components/Page';
import QueryEmptyState from '@/components/QueryEmptyState';
import Stack from '@/components/Stack';
import Table from '@/components/Table';
import TableHeader from '@/components/TableHeader';
import { Body } from '@/components/Typography';
import Icon from '@/elements/Icon';
import Link from '@/elements/Link';
import { TableRow, TableCell } from '@/elements/Table';
import { useListJobsForMonitorQuery } from '@/graphql';
import useFilters from '@/hooks/useFilters';
import useMediaQuery from '@/hooks/useMediaQuery';
import { jobColors } from '@/styles/colors';
import {
  JobStatusEnum,
  JobFilterSetInput,
  FillStatusEnum,
  VisibilityStatusEnum,
  ListJobsForMonitorQuery,
} from '@/types/graphql';
import { currentAgencyVar } from '@/util/apollo/cache';
import { formatISO, isWithin24Hours } from '@/util/date';
import { sortShifts } from '@/util/job';
import TZBadge from '@/components/TZBadge';

const HEADER_FIELDS = [
  { name: '', style: { width: '5%' } },
  {
    name: 'STATUS',
    style: { minWidth: '136px', width: '11%', color: 'black' },
  },
  {
    name: 'SHIFT TIME',
    style: { minWidth: '110px', width: '13%', color: 'black' },
  },
  {
    name: 'HOURS',
    style: { minWidth: '60px', width: '6%', color: 'black' },
  },
  {
    name: 'CHECKED IN',
    style: { minWidth: '94px', width: '6%', color: 'black' },
  },
  {
    name: 'HIRED',
    style: { minWidth: '75px', width: '6%', color: 'black' },
  },
  {
    name: 'CLIENT',
    style: { minWidth: '120px', width: '14%', color: 'black' },
  },
  {
    name: 'SKILL',
    style: { minWidth: '100px', width: '11%', color: 'black' },
  },
  {
    name: 'LOCATION',
    style: { minWidth: '130px', width: '16%', color: 'black' },
  },
  {
    name: 'ALERTS',
    style: { minWidth: '65px', width: '6%', color: 'black' },
  },
];

const initialFilters: JobFilterSetInput = {
  fillStatus: FillStatusEnum.ALL,
  visibility: VisibilityStatusEnum.ALL,
};
const SHOW_CHECKIN = [JobStatusEnum.COMPLETED, JobStatusEnum.IN_PROGRESS];

type ItemsType = ListJobsForMonitorQuery['agency']['shiftsByDay'];
type JobType = ListJobsForMonitorQuery['agency']['shiftsByDay'][0]['job'];

type SubsEmailModalProps = { jobId: string; orderId: string; shiftId: string };

const JobMonitor = () => {
  const currentAgency = useReactiveVar(currentAgencyVar);
  const phoneOnly = useMediaQuery('(max-width: 559px)');

  const [startDate, setStartDate] = useState(startOfToday());
  const [collapseId, setCollapseId] = useState<undefined | string>();

  const {
    debouncedQuery,
    query: searchQuery,
    setQuery,
    filters,
  } = useFilters<JobFilterSetInput>(initialFilters);

  const finalFilters = useMemo<JobFilterSetInput>(() => {
    const value = { ...filters };

    if (debouncedQuery !== '') {
      value.query = { value: debouncedQuery };
    }
    return value;
  }, [debouncedQuery, filters]);

  const goBack = useCallback(
    () => setStartDate((date) => addDays(date, -1)),
    [],
  );

  const goForward = useCallback(
    () => setStartDate((date) => addDays(date, 1)),
    [],
  );

  const query = useListJobsForMonitorQuery({
    variables: {
      agencyId: currentAgency!.id,
      startDate: formatISO8601(startDate, {
        representation: 'date',
      }),
      filters: finalFilters,
      timeZone: Intl?.DateTimeFormat()?.resolvedOptions().timeZone,
    },
    fetchPolicy: 'network-only',
    pollInterval: 120000,
  });

  const [showModal, hideModal] = useModal(
    ({ jobId, orderId, shiftId }: SubsEmailModalProps) => (
      <SubscribeEmailModal
        hideModal={hideModal}
        jobId={jobId}
        orderId={orderId}
        shiftId={shiftId}
      />
    ),
    [],
  );

  const TableBody = ({ items }: { items: ItemsType }) => (
    <tbody>
      {items.sort(sortShifts).map((item) => {
        const { job } = item;
        const highLightInfo = getHighLightInfo(job);
        const startAtIso = parseISO(item.job.firstShiftStartAt);
        const isOpen = collapseId === item.id;
        const workerSideColor =
          highLightInfo.jobSearchicon || highLightInfo.noShowIcon
            ? jobColors.redLightest
            : highLightInfo.warningIcon
              ? jobColors.yellowLightest
              : jobColors.headerLight;

        const hours =
          differenceInMinutes(parseISO(item.job.lastShiftEndAt), startAtIso) /
          60;

        return (
          <React.Fragment key={item.id}>
            <TableRow
              clickable
              id={`row-${item.id}`}
              style={{
                backgroundColor: highLightInfo.bgColor,
                boxShadow: highLightInfo.color
                  ? `3px 0px ${highLightInfo.color} inset`
                  : isOpen
                    ? `3px 0px white inset`
                    : undefined,
              }}
              onClick={() => {
                window.open(`/orders/${job.order.id}/jobs/${item.id}`);
              }}
            >
              <TableCell onClick={(e) => e.stopPropagation()}>
                <IconButton
                  aria-label="expand row"
                  id={`expand-collapse-${item.id}`}
                  size="small"
                  onClick={() =>
                    setCollapseId((id) =>
                      id === item.id ? undefined : item.id,
                    )
                  }
                >
                  <Icon
                    fixedWidth
                    icon={isOpen ? faChevronUp : faChevronDown}
                  />
                </IconButton>
              </TableCell>
              <TableCell style={{ paddingLeft: 10 }}>
                {job.status === JobStatusEnum.UPCOMING &&
                isWithin24Hours(startAtIso) ? (
                  <JobTimer refetch={query.refetch} targetDate={startAtIso} />
                ) : (
                  getStatusChip(job.status)
                )}
                {!job.published && (
                  <Icon
                    fixedWidth
                    css={{ marginLeft: '7px' }}
                    icon={faEyeSlash}
                    size={'lg'}
                    title="Private"
                  />
                )}
              </TableCell>
              <TableCell>
                {formatISO(
                  item.job.firstShiftStartAt,
                  'p',
                  item.job.address.timezone,
                )}{' '}
                &mdash;{' '}
                {formatISO(
                  item.job.lastShiftEndAt,
                  'p',
                  item.job.address.timezone,
                )}
                <TZBadge
                  timezone={item.job.address.timezone}
                  date={item.job.firstShiftStartAt}
                  locale={false}
                  style={{ display: 'inline', marginLeft: '5px' }}
                />
              </TableCell>
              <TableCell>{hours > 8 ? <b>{hours}</b> : hours}</TableCell>

              <TableCell>
                {SHOW_CHECKIN.includes(job.status) ||
                (job.status === JobStatusEnum.UPCOMING &&
                  job.checkinWorkerCount) ? (
                  <span
                    style={{
                      color:
                        highLightInfo.noShowIcon || highLightInfo.warningIcon
                          ? highLightInfo.color
                          : '',
                    }}
                  >
                    {`${job.checkinWorkerCount}/${job.hiredWorkersCount}`}
                  </span>
                ) : (
                  ''
                )}

                {highLightInfo.noShowIcon && (
                  <PeopleExclaimIcon
                    style={{ marginLeft: 5, fontSize: '14px', top: '2px' }}
                    titleAccess="No Show"
                  />
                )}

                {highLightInfo.warningIcon && (
                  <TimerExclaimIcon
                    style={{ marginLeft: 5, fontSize: '14px', top: '2px' }}
                    titleAccess="Missing Check in"
                  />
                )}
              </TableCell>
              <TableCell>
                <span
                  style={{
                    color: highLightInfo.jobSearchicon
                      ? highLightInfo.color
                      : '',
                  }}
                >{`${job.hiredWorkersCount}/${job.quantity}`}</span>

                {highLightInfo.jobSearchicon && (
                  <PeopleSearchIcon
                    style={{ marginLeft: 5, fontSize: '14px', top: '3px' }}
                    titleAccess="Under Staffed"
                  />
                )}
              </TableCell>
              <TableCell onClick={(e) => e.stopPropagation()}>
                <Link
                  id={`client-${item.id}`}
                  to={`../clients/${job.account?.customer?.id}`}
                >
                  {job.account?.customer?.name}
                </Link>
              </TableCell>
              <TableCell>{job.skill?.name}</TableCell>
              <TableCell>
                {job.address?.addressLine1}, {job.address?.city},{' '}
                {job.address?.state} {job.address?.zip}
              </TableCell>
              <TableCell onClick={(e) => e.stopPropagation()}>
                <IconicButton
                  a11yLabel="alert"
                  icon={faBell}
                  id={`alert-${item.id}`}
                  style={{ borderRadius: '20px' }}
                  onClick={() =>
                    showModal({
                      jobId: item.job.id,
                      orderId: item.job.order.id,
                      shiftId: item.id,
                    })
                  }
                />
              </TableCell>
            </TableRow>
            {isOpen && (
              <TableRow
                style={{
                  backgroundColor: highLightInfo.bgColor,
                  boxShadow: highLightInfo.color
                    ? `3px 0px ${highLightInfo.color} inset`
                    : undefined,
                }}
              >
                <TableCell colSpan={HEADER_FIELDS.length}>
                  <WorkerDetailsTable
                    sideLineColor={workerSideColor}
                    job={item.job}
                  />
                </TableCell>
              </TableRow>
            )}
          </React.Fragment>
        );
      })}
      {query.loading && (
        <TableRow>
          <TableCell align="center" colSpan={HEADER_FIELDS.length}>
            <LoadingState overlayColor="white" />
          </TableCell>
        </TableRow>
      )}
    </tbody>
  );

  const getStatusChip = (status: JobStatusEnum) => {
    switch (status) {
      case JobStatusEnum.CANCELLED:
        return <Chip label="Cancelled" size="small" />;
      case JobStatusEnum.COMPLETED:
        return <Chip color="success" label="Complete" size="small" />;
      case JobStatusEnum.IN_PROGRESS:
        return <Chip color="primary" label="In Progress" size="small" />;
      case JobStatusEnum.UPCOMING:
        return <Chip color="success" label="Upcoming" size="small" />;
      default:
        return <Chip label="N/A" size="small" />;
    }
  };
  const getHighLightInfo = (job: JobType) => {
    let info = {
      color: '',
      bgColor: 'white',
      warningIcon: false,
      noShowIcon: false,
      jobSearchicon: false,
    };
    if (job.status === JobStatusEnum.CANCELLED) {
      return { ...info, bgColor: jobColors.cancel };
    }
    if (job.hasNoShow || job.hiredWorkersCount < job.quantity) {
      info = {
        ...info,
        color: jobColors.red,
        bgColor: jobColors.redLight,
        noShowIcon: job.hasNoShow,
        jobSearchicon: job.hiredWorkersCount < job.quantity,
      };
    } else if (
      job.status === JobStatusEnum.IN_PROGRESS &&
      (job.checkinWorkerCount || 0) < job.hiredWorkersCount
    ) {
      info = {
        ...info,
        color: jobColors.yellow,
        bgColor: jobColors.yellowLight,
        warningIcon: true,
      };
    }

    return info;
  };
  const items =
    !query.loading && query.data ? query.data.agency.shiftsByDay : [];

  return (
    <Page size="full" title="Job Monitor">
      <Card>
        <Card.Section>
          <Stack gap={phoneOnly ? 16 : 8} vertical={phoneOnly}>
            <Stack justify="apart">
              <Stack gap={16} justify={phoneOnly ? 'apart' : 'start'}>
                <IconicButton
                  a11yLabel="View previous day"
                  appearance={phoneOnly ? 'outline' : 'clear'}
                  icon={faChevronLeft}
                  size={phoneOnly ? 'md' : 'sm'}
                  onClick={goBack}
                />
                <Body>{format(startDate, 'EEE M/dd')}</Body>
                <IconicButton
                  a11yLabel="View next day"
                  appearance={phoneOnly ? 'outline' : 'clear'}
                  icon={faChevronRight}
                  size={phoneOnly ? 'md' : 'sm'}
                  onClick={goForward}
                />
              </Stack>
            </Stack>
            <JobMonitorFilters query={searchQuery} onQueryChange={setQuery} />
          </Stack>
        </Card.Section>
        <Table>
          <TableHeader
            fields={HEADER_FIELDS}
            headerBgColor={jobColors.headerLight}
          />
          {items.length ? (
            <TableBody items={items.map((i) => ({ ...i }))} />
          ) : (
            <tbody />
          )}
        </Table>
        {query.loading && (
          <Stack align="center" justify="center">
            <LoadingState overlayColor="white" text="Loading jobs" />
          </Stack>
        )}
        {!query.loading && !items.length && (
          <QueryEmptyState
            query={query}
            text="If there were any, they would be here."
            title="No jobs available"
          />
        )}
      </Card>
    </Page>
  );
};

export default JobMonitor;

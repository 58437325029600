import {
  AccessTime,
  CalendarMonth,
  CloseOutlined,
  Group,
} from '@mui/icons-material';
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { format } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useMemo, useState } from 'react';

import {
  Address,
  BreakTime,
  Schedule,
  ShiftInfo,
  breakOptions,
} from '../../../context';
import {
  checkTimeRange,
  getDifferenceInMinutes,
  isHoliday,
  parseStringsHours,
} from '../util';

import { BreakTimeIcon } from '@/assets/icons';
import Button from '@/components/Button';
import HolidayInfoAlert from '@/components/HolidayInfoAlert';
import ItemSelect from '@/components/ItemSelect';
import ShiftTimePicker from '@/components/ShiftTimePicker';
import TextInput from '@/components/TextInput';
import { Subheading, Body } from '@/components/Typography';
import {
  DISABLE_BREAK_MINUTES,
  INTERVAL_IN_MINUTES,
} from '@/constants/general';
import Text from '@/elements/Text';
import useAuth from '@/hooks/useAuth';
import breakpoints from '@/styles/breakpoints';
import { IconColor } from '@/styles/colors';
import { JobTypeEnum, Maybe } from '@/types/graphql';
import { convertTZ, getTimezoneAbbreviation } from '@/util/date';

interface ShiftModalProps {
  schedule?: Schedule;
  onSave: (info: ShiftInfo) => void;
  hideModal: (e: any, reason: string) => void;
  date: Date;
  customerId: string;
  orderType: JobTypeEnum | null;
  holidays?: any[];
  lastShiftInfo?: Schedule;
  address: Maybe<Address>;
}

const ShiftModal = ({
  schedule,
  date,
  onSave,
  hideModal,
  orderType,
  holidays,
  lastShiftInfo,
  address,
}: ShiftModalProps) => {
  const scheduleInfo = schedule || lastShiftInfo;
  const [time, setTime] = useState({
    startTime: scheduleInfo?.startTime || '',
    endTime: scheduleInfo?.endTime || '',
  });
  const [mandatoryBreakTime, setmandatoryBreakTime] = useState(
    breakOptions.find((x) => x.value === scheduleInfo?.mandatoryBreakTime) ||
      breakOptions[0],
  );
  const [quantity, setQuantity] = useState<number | string>(
    scheduleInfo?.quantity || 1,
  );
  const phoneOnly = useMediaQuery(breakpoints.phoneOnly);
  const { currentAdminIsCustomerAdmin } = useAuth();

  const [disableBreak, setdisableBreak] = useState(false);

  const [selectedWorkers, setSelectedWorkers] = useState<any[]>(
    schedule?.hiredWorkers || [],
  );
  useEffect(() => {
    const num = Number(quantity);
    if (num > 0 && selectedWorkers.length > num) {
      setSelectedWorkers(
        selectedWorkers.slice(0, num - selectedWorkers.length),
      );
    }
  }, [quantity, selectedWorkers]);

  const handleTimeChange = (seltime: Dayjs, event: any, key: string) => {
    const value = seltime ? seltime?.format('HH:mm') : '';
    setTime((val) => ({ ...val, [key]: value }));
  };

  const handleBreakTime = (fieldContext: BreakTime) => {
    setmandatoryBreakTime(fieldContext);
  };
  const holiday = schedule?.isHoliday || isHoliday(holidays || [], date);

  const { validBreakOptions, difference } = useMemo(() => {
    const difference = getDifferenceInMinutes(time.endTime, time.startTime);
    if (difference > DISABLE_BREAK_MINUTES && difference < 480) {
      return { validBreakOptions: breakOptions.slice(0, 3), difference };
    } else {
      return { validBreakOptions: breakOptions, difference };
    }
  }, [time.startTime, time.endTime]);

  useEffect(() => {
    if (!difference) return;
    if (difference <= DISABLE_BREAK_MINUTES) {
      setmandatoryBreakTime(breakOptions[0]);
      setdisableBreak(true);
    } else {
      if (difference < 480 && mandatoryBreakTime.value > 30) {
        setmandatoryBreakTime(breakOptions.find((b) => b.value === 30)!);
      }
      setdisableBreak(false);
    }
  }, [difference]);

  const errorMsg = useMemo(() => {
    if (
      time.startTime &&
      time.endTime &&
      checkTimeRange(time.endTime, time.startTime, INTERVAL_IN_MINUTES)
    ) {
      return { msg: 'Job duration should be at least 4 hours.', disable: true };
    }
    if (
      time.startTime &&
      currentAdminIsCustomerAdmin &&
      date <= convertTZ(new Date(), address?.timezone) &&
      parseStringsHours(time.startTime) <= convertTZ(new Date(), address?.timezone).getTime()
    ) {
      return { msg: 'Shift time cannot be in the past', disable: true };
    }
  }, [time]);

  const disableAdd = !time.startTime || !time.endTime || errorMsg?.disable;

  const handleSave = () => {
    const info = {
      date,
      startTime: time.startTime,
      endTime: time.endTime,
      mandatoryBreakTime: mandatoryBreakTime.value,
      quantity: Number(quantity),
      groupId: schedule?.groupId,
      hiredWorkers: selectedWorkers,
      invitedWorkers: schedule?.selectedWorkers || [],
    };
    onSave(info);
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          width: '520px',
          marginTop: 0,
          margin: phoneOnly ? 0 : undefined,
          borderRadius: phoneOnly ? '12px' : '',
          height: 'auto',
          overflowY: 'visible',
        },
      }}
      maxWidth="md"
      open={true}
      sx={{
        '& .MuiDialog-container': phoneOnly
          ? {
              alignItems: 'end',
              marginBottom: '2px',
            }
          : {},
      }}
      onClose={hideModal}
    >
      <DialogTitle style={{ backgroundColor: '#EEFFEC', padding: '12px 20px' }}>
        <b>{`${schedule ? 'Edit' : 'Create'} ${
          orderType === JobTypeEnum.GIG ? 'Gig' : orderType
        } Shift`}</b>
        <IconButton
          aria-label="close"
          sx={{
            float: 'right',
            padding: '2px',
            color: IconColor,
          }}
          onClick={(e) => hideModal(e, '')}
        >
          <CloseOutlined />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ overflowY: 'visible' }}>
        <Grid
          container
          columnSpacing={3}
          rowSpacing={phoneOnly ? 2 : 3.5}
          sx={{ mt: 0 }}
        >
          <Grid item sm={3} xs={3.5}>
            <Stack direction={'row'}>
              <CalendarMonth
                fontSize="medium"
                htmlColor={IconColor}
                style={{ marginRight: '10px', top: 1 }}
              />
              <Subheading>Date</Subheading>
            </Stack>
          </Grid>
          <Grid item sm={9} xs={8}>
            {date && format(date, 'MMMM dd, yyyy')}
          </Grid>
          <Grid item sm={3} xs={12}>
            <Stack direction={'row'} mt={1}>
              <AccessTime
                fontSize="medium"
                htmlColor={IconColor}
                style={{ marginRight: '10px', top: 1 }}
              />
              <Subheading>Time</Subheading>
              {phoneOnly && address && (
                <Body
                  css={{
                    marginLeft: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {getTimezoneAbbreviation(
                    address?.timezone,
                    format(date, 'MM-dd-yyyy'),
                    true,
                  )}
                </Body>
              )}
            </Stack>
          </Grid>
          <Grid item sm={4} xs={12}>
            <ShiftTimePicker
              defaultValue={dayjs(scheduleInfo?.startTime || '', 'HH:mm')}
              id="start-time"
              label="Start time"
              minutesStep={15}
              name="startTime"
              onChange={handleTimeChange}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <ShiftTimePicker
              defaultValue={dayjs(scheduleInfo?.endTime || '', 'HH:mm')}
              id="end-time"
              label="End time"
              minutesStep={15}
              name="endTime"
              onChange={handleTimeChange}
            />
          </Grid>
          {!phoneOnly && (
            <Grid
              item
              sm={1}
              xs={12}
              style={{ paddingLeft: '10px', alignSelf: 'center' }}
            >
              {address &&
                getTimezoneAbbreviation(
                  address?.timezone,
                  format(date, 'MM-dd-yyyy'),
                  true,
                )}
            </Grid>
          )}
          <Grid item sm={3} xs={12}>
            <Stack direction={'row'} mt={1}>
              <BreakTimeIcon
                fontSize="medium"
                style={{ marginRight: '10px', top: 1 }}
              />
              <Subheading>Break</Subheading>
            </Stack>
          </Grid>
          <Grid item sm={9} xs={12}>
            <ItemSelect
              required
              disabled={disableBreak}
              fixedSelectHeight={phoneOnly ? 'auto' : 'lg'}
              id="mandatoryBreakTime"
              itemToKey={(item) => item.value}
              itemToString={(item) => (item ? item.label : '')}
              items={validBreakOptions}
              placeholder="Select Break Time"
              selectedItem={mandatoryBreakTime}
              onSelectedItemChange={({ selectedItem }) => {
                handleBreakTime(selectedItem || breakOptions[0]);
              }}
            />
            {disableBreak && (
              <Text>
                <i>
                  Shifts shorter than 6 hours may not have mandatory break
                  times.
                </i>
              </Text>
            )}
          </Grid>
          <Grid item sm={3} xs={12}>
            <Stack direction={'row'} mt={1}>
              <Group
                fontSize="medium"
                htmlColor={IconColor}
                style={{ marginRight: '8px', top: 1 }}
              />
              <Subheading>Quantity</Subheading>
            </Stack>
          </Grid>
          <Grid item sm={9} xs={12}>
            <TextInput
              min="1"
              placeholder="Workers quantity"
              type="number"
              value={quantity}
              width="100%"
              onBlur={(e) => {
                if (Number(e.target.value) < 1) {
                  setQuantity(1);
                }
              }}
              onChange={(e) => {
                setQuantity(e.target.value);
              }}
            />
          </Grid>

          {holiday && (
            <Grid item paddingTop={'16px !important'} xs={12}>
              <HolidayInfoAlert
                rate={holiday.rate}
                style={{ marginBottom: '0px' }}
              />
            </Grid>
          )}

          {errorMsg && (
            <Grid item paddingTop={'8px !important'} xs={12}>
              <Alert icon={false} severity="error">
                {errorMsg.msg}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              a11yLabel="Add shift"
              css={{ width: '100%' }}
              disabled={disableAdd}
              label={`${schedule ? 'Save' : 'Add'}`}
              onClick={handleSave}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ShiftModal;

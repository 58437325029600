import { faDownload } from '@fortawesome/free-solid-svg-icons';

import IconicButton from '@/components/IconicButton';
import Stack from '@/components/Stack';

interface IconProps {
  isLoading?: boolean;
  onClick: () => void;
  disabled?: boolean;
}

function DownloadIcon(props: IconProps) {
  return (
    <Stack justify="end">
      <IconicButton
        a11yLabel="download"
        appearance="clear"
        disabled={props.disabled}
        icon={faDownload}
        id="btn-download"
        spin={props.isLoading}
        {...props}
      />
    </Stack>
  );
}

export default DownloadIcon;

import { CloseOutlined } from '@mui/icons-material';
import { IconButton as MuiIconButton, Stack, Tooltip } from '@mui/material';

import { GenerateInvoice } from '@/assets/icons';
import styled from '@/styles';
import colors, { timesheetColors } from '@/styles/colors';

const WrapperDiv = styled('div', {
  margin: '15px 20px',
  backgroundColor: timesheetColors.bulkActionsBg,
  height: '54px',
  borderRadius: '140px',
  padding: '7px 18px',
});
const TextDiv = styled('div', {
  paddingTop: '9px',
  marginRight: '20px !important',
});
const IconButton = styled(MuiIconButton, {
  color: 'black !important',
  '&:hover': {
    color: `${colors.themeDefault} !important`,
    backgroundColor: '#D7EDCF !important',
  },
  '&.Mui-disabled': {
    color: `rgba(0, 0, 0, 0.3) !important`,
  },
});

interface Props {
  checkboxIds: string[];
  setCheckboxIds: (ids: string[]) => void;
  handleGenerateInvoice: () => Promise<void>;
}

const ActionTab = ({
  checkboxIds,
  setCheckboxIds,
  handleGenerateInvoice,
}: Props) => {
  return (
    <>
      {checkboxIds.length > 0 && (
        <WrapperDiv id="actions-tab">
          <Stack direction={'row'} spacing={{ xs: 0.1, md: 1.3 }}>
            <Tooltip arrow title="Close">
              <IconButton id="close-tab" onClick={() => setCheckboxIds([])}>
                <CloseOutlined htmlColor="inherit" />
              </IconButton>
            </Tooltip>
            <TextDiv>
              <span>
                <b>{checkboxIds.length}</b> Selected
              </span>
            </TextDiv>
            <Stack direction={'row'} spacing={{ xs: 0, md: 0.5 }}>
              <Tooltip arrow title="Generate Invoice">
                <span>
                  <IconButton
                    id="generate-invoice-action"
                    onClick={handleGenerateInvoice}
                  >
                    <GenerateInvoice />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          </Stack>
        </WrapperDiv>
      )}
    </>
  );
};

export default ActionTab;

import { createSvgIcon } from '@mui/material';

const CheckIdIcon = createSvgIcon(
  <svg
    fill="none"
    height="17"
    viewBox="0 0 17 17"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2553_13318)">
      <path
        clipRule="evenodd"
        d="M6.42989 16.0924C10.6967 16.0924 15.0822 12.6359 16.2255 8.37237C17.3687 4.10865 14.8367 0.652344 10.5701 0.652344C6.30332 0.652344 1.91778 4.10865 0.774518 8.37237C-0.368748 12.636 2.16328 16.0924 6.42989 16.0924ZM7.58326 12.0041C8.91577 12.0041 10.273 11.281 11.1838 10.2056L9.6024 9.12643C9.23789 9.55637 8.69509 9.84586 8.16212 9.84586C7.36691 9.84586 6.89486 9.20151 7.10794 8.40689C7.32103 7.61226 8.13863 6.96791 8.93383 6.96791C9.4668 6.96791 9.8544 7.25717 9.98823 7.68723L12.1486 6.60786C11.8143 5.53277 10.845 4.80935 9.51257 4.80935C7.52434 4.80935 5.48069 6.41997 4.94797 8.40677C4.41514 10.3936 5.59503 12.0041 7.58326 12.0041Z"
        fill="#45A735"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_2553_13318">
        <rect
          fill="white"
          height="16"
          transform="translate(0.5 0.320312)"
          width="16"
        />
      </clipPath>
    </defs>
  </svg>,
  'CheckIdIcon'
);
export default CheckIdIcon;

import { useReactiveVar } from '@apollo/client';
import {
  faMapMarkerAlt,
  faEllipsisV,
  faEyeSlash,
  faTimesCircle,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import Menu from '@mui/material/Menu';
import { parseISO, format, differenceInHours } from 'date-fns';
import qs from 'query-string';
import { ReactNode, useMemo, useState } from 'react';

import ShiftsCard from '../ShiftsCard';
import { JobStatus, getShiftIntervals } from '../util';

import Avatar from '@/components/Avatar';
import Stack from '@/components/Stack';
import Icon from '@/elements/Icon';
import { getTimeinHoursAndMinutes } from '@/hooks/useCountdown';
import useMediaQuery from '@/hooks/useMediaQuery';
import { Role } from '@/routes/PrivateRoute';
import styled from '@/styles';
import { GetJobQuery, JobStatusEnum, JobTypeEnum } from '@/types/graphql';
import { currentAdminVar } from '@/util/apollo/cache';
import { DAY_HOURS } from '@/util/constants';
import { isWithin24Hours } from '@/util/date';
import { costOfJob } from '@/util/job';
import { centsToCurrency } from '@/util/number';

const MIN_LIMIT_HOURS = DAY_HOURS;
const MAX_LIMIT_HOURS = DAY_HOURS * 2;

type Props = {
  job: GetJobQuery['job'];
  displayStatus: ReactNode[];
  jobStatus: string;
  isJobPublished: boolean;
  handlePublishClick: () => void;
  handleCloseClick: (job: any) => void;
};

const ShiftTiming = styled('div', {
  display: 'flex',
  flexDirection: 'row',
});

const ItemList = styled('div', {
  marginTop: '68px',
  '@phoneOnly': {
    width: '100%',
    marginTop: '0',
    marginLeft: '55px',
  },
});

const Item = styled('div', {
  position: 'relative',
});

const Subtitle = styled('h6', {
  color: '#808B9D',
  fontSize: '13px',
  fontWeight: '500',
  lineHeight: 'normal',
  marginBottom: '4px',
  marginTop: '0px',
  marginRight: '44px',
});

const HeadingNew = styled('h2', {
  color: '#0E1E40',
  fontSize: '28px',
  fontWeight: '700',
  lineHeight: 'normal',
});

const TimeComp = styled('p', {
  color: '#424242',
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: 'normal',
  marginBottom: '9px',

  '@phoneOnly': {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 8,
    fontWeight: 'normal',
  },
});

const LocationHeading = styled('p', {
  color: '#52C241',
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: 'normal',
  cursor: 'pointer',
});

const NameHeading = styled('p', {
  color: '#6D6D6D',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: 'normal',
});

const TimeChip = styled('span', {
  borderRadius: '100px',
  border: '1px solid #60A546',
  background: '#FFF',
  padding: '6px',
  marginRight: '8px',
  alignItems: 'center',
  gap: '2px',
  color: '#60A546',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: 'normal',
});

const IconButtonMenu = styled('button', {
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  border: '1px solid #E7E8EA',
  background: 'white',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const MenuList = styled('ul', {
  background: '#FFF',
});

const MenuItemRight = styled('li', {
  padding: '12px 20px',
  fontSize: '14px',
  fontWeight: '400',
  cursor: 'pointer',
});

const DropdownWrapper = styled('div', {
  position: 'absolute',
  right: '0',
  top: '0',
});

const JobHeader = ({
  job,
  displayStatus,
  isJobPublished,
  jobStatus,
  handleCloseClick,
  handlePublishClick,
}: Props) => {
  const { address } = job;
  const { totalEstimate } = costOfJob(job);
  const shiftIntervals = useMemo(
    () => getShiftIntervals(job.shifts),

    [job.shifts]
  );
  const currentAdmin = useReactiveVar(currentAdminVar);
  const isTenantAdmin = currentAdmin!.role === Role.TENANT_ADMIN;
  const { canEditSchedule, limitOfHoursOnSelectedDays } = useMemo(() => {
    const quantityOfJobsHired = job.jobWorkers.filter(
      (jobWorker) => jobWorker.status === 'HIRED'
    ).length;
    const limitOfHoursOnSelectedDays = isTenantAdmin
      ? 0
      : quantityOfJobsHired > 0
      ? MAX_LIMIT_HOURS
      : MIN_LIMIT_HOURS;

    const firstShiftStartAt = parseISO(job.firstShiftStartAt);
    const differenceHours = differenceInHours(firstShiftStartAt, new Date());
    const jobStatusIsUpComing = job.status === JobStatusEnum.UPCOMING;
    return {
      canEditSchedule: isTenantAdmin
        ? jobStatusIsUpComing && job.timesheets.length === 0
        : differenceHours >= DAY_HOURS && jobStatusIsUpComing,

      limitOfHoursOnSelectedDays,
    };
  }, [job]);

  const [anchorEl, setAnchorEl] = useState(null);
  const { account } = job;
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseJob = () => {
    handleCloseClick(job);
    setTimeout(() => {
      setAnchorEl(null);
    }, 100);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const getTimer = () => {
    const startAtIso = parseISO(job.shifts[0].startAt);
    if (isWithin24Hours(startAtIso) && jobStatus !== JobStatus.CANCELLED) {
      const countDownTime = startAtIso.getTime() - new Date().getTime();
      const [hours, minutes] = getTimeinHoursAndMinutes(countDownTime);
      const Hours = hours < 10 ? `0${hours}` : hours;
      const Minutes = minutes < 10 ? `0${minutes}` : minutes;
      return (
        <span>
          <TimeChip>{`${Hours}:${Minutes}`}</TimeChip>
          {displayStatus}
        </span>
      );
    } else {
      return <span>{displayStatus}</span>;
    }
  };

  const mapsParams = {
    query: `${address.addressLine1},${address.city},${address.state}`,
  };
  const mapsUrl = `https://www.google.com/maps/search/?api=1&${qs.stringify(
    mapsParams
  )}`;

  const onClickMap = () => {
    window.open(mapsUrl, '_blank', 'noreferrer');
  };

  const addressLine = `${address.addressLine1 ? address.addressLine1 : ''} ${
    address.addressLine2 ? address.addressLine2 : ''
  }`;

  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const locationOnNewline = phoneOnly || account?.customer?.name?.length > 50;
  return (
    <>
      <Stack
        align="start"
        justify="apart"
        style={
          phoneOnly
            ? { flexWrap: 'wrap', position: 'relative', marginBottom: '40px' }
            : { marginBottom: '40px' }
        }
      >
        <Stack align="start" style={{ gap: '20px' }}>
          <Item>
            <Avatar src={job.skill.imageUrl} />
          </Item>

          <Item>
            <Subtitle>Order #{job.order.id}</Subtitle>

            <Stack
              gap={8}
              style={{ marginBottom: '8px' }}
              vertical={phoneOnly && displayStatus?.length > 1}
            >
              <HeadingNew>{job.skill.name}</HeadingNew>
              {getTimer()}
            </Stack>
            <ShiftTiming>
              {shiftIntervals.map((interval, index) => (
                <TimeComp key={index}>
                  {format(new Date(job.firstShiftStartAt), 'PP')} -{' '}
                  {format(new Date(job.lastShiftEndAt), 'PP')}
                  <span style={{ color: '#ACACAC' }}>
                    &ensp;
                    {!phoneOnly && '|'}
                    &ensp;
                  </span>
                  {format(interval[0].startAt, 'p')} -{' '}
                  {format(interval[interval.length - 1].endAt, 'p')}
                </TimeComp>
              ))}
              {canEditSchedule && (
                <ShiftsCard
                  job={job}
                  limitOfHoursOnSelectedDays={limitOfHoursOnSelectedDays}
                />
              )}
            </ShiftTiming>
            <Stack style={locationOnNewline ? { flexWrap: 'wrap' } : {}}>
              <NameHeading>
                {account?.customer?.name} {!locationOnNewline && '|'}{' '}
              </NameHeading>
              <div
                style={
                  locationOnNewline
                    ? {
                        flexWrap: 'wrap',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '7px',
                      }
                    : { display: 'flex', alignItems: 'center', gap: '7px' }
                }
              >
                <LocationHeading onClick={onClickMap}>
                  <Icon
                    icon={faMapMarkerAlt}
                    style={{ color: '#808B9D', marginRight: 4 }}
                  />{' '}
                  {addressLine} {address.city}, {address.state} {address.zip}
                </LocationHeading>
              </div>
            </Stack>
          </Item>
        </Stack>
        <ItemList>
          <p style={{ whiteSpace: 'nowrap' }}>
            Total Billing Estimate:{' '}
            <span style={{ fontWeight: 700 }}>
              ${centsToCurrency(totalEstimate)}
            </span>
          </p>
        </ItemList>
        <DropdownWrapper>
          {jobStatus === JobStatus.CANCELLED ||
          jobStatus === JobStatus.COMPLETED ||
          (job.jobType === JobTypeEnum.LTA &&
            jobStatus === JobStatus.IN_PROGRESS) ? (
            ''
          ) : (
            <Item style={phoneOnly ? { order: '2' } : {}}>
              <IconButtonMenu
                aria-controls={open ? 'basic-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                id="job-menu-button"
                onClick={handleClick}
              >
                <Icon icon={faEllipsisV} style={{ color: '#808b9d' }} />
              </IconButtonMenu>

              <Menu
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                id="basic-menu"
                open={open}
                sx={{
                  // background: 'red',
                  border: '1px solid #ECECEC',
                  filter:
                    'drop-shadow(0px 4.050473213195801px 4.050473213195801px rgba(0, 0, 0, 0.05))',
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                onClose={handleClose}
              >
                <MenuList>
                  {job.jobType !== JobTypeEnum.LTA && (
                    <MenuItemRight
                      style={{ color: '#44A735' }}
                      onClick={handlePublishClick}
                    >
                      <Icon
                        icon={isJobPublished ? faEyeSlash : faEye}
                        style={{ marginRight: '10px' }}
                      />{' '}
                      Make Job {isJobPublished ? 'Private' : 'Public'}
                    </MenuItemRight>
                  )}

                  {jobStatus !== JobStatus.IN_PROGRESS && (
                    <MenuItemRight
                      style={{ color: '#E94E46' }}
                      onClick={handleCloseJob}
                    >
                      <Icon
                        icon={faTimesCircle}
                        style={{ marginRight: '10px' }}
                      />{' '}
                      Cancel Job
                    </MenuItemRight>
                  )}
                </MenuList>
              </Menu>
            </Item>
          )}
        </DropdownWrapper>
      </Stack>
    </>
  );
};

export default JobHeader;

import { differenceInMinutes, parseISO } from 'date-fns';

import { TimesheetItem } from '..';

import FormElement from '@/components/FormElement';
import Stack from '@/components/Stack';
import { Small, ProfileHeading } from '@/components/Typography';
import useMediaQuery from '@/hooks/useMediaQuery';
import { getTotalLengthOfShiftTimesheet } from '@/routes/Agency/Job/util';
import styled from '@/styles';
import { Address, Point, TimesheetStatusEnum } from '@/types/graphql';
import { formatISO } from '@/util/date';

enum TimesheetInformationVariants {
  DETAIL = 'DETAIL',
  DEFAULT = 'DEFAULT',
}

type Props = {
  variant?: 'DETAIL' | 'DEFAULT';
  jobCoords: Point;
  timesheet?: TimesheetItem;
  jobAddress: Address;
};

const TimesheetInformation = ({
  variant = TimesheetInformationVariants.DEFAULT,
  timesheet,
  jobAddress,
}: Props) => {
  const reportedNetHours =
    timesheet &&
    timesheet.reportedCheckinAt &&
    timesheet.reportedCheckoutAt &&
    timesheet.checkinCoords &&
    timesheet.checkoutCoords
      ? differenceInMinutes(
          parseISO(timesheet.reportedCheckoutAt),
          parseISO(timesheet.reportedCheckinAt)
        ) - (timesheet.reportedBreakMinutes ?? 0)
      : 0;

  const systemNetHours =
    timesheet && timesheet.checkinAt && timesheet.checkoutAt
      ? differenceInMinutes(
          parseISO(timesheet.checkoutAt),
          parseISO(timesheet.checkinAt)
        )
      : 0;

  const approvedNetHours =
    timesheet && timesheet.approvedCheckinAt && timesheet.approvedCheckoutAt
      ? differenceInMinutes(
          parseISO(timesheet.approvedCheckoutAt),
          parseISO(timesheet.approvedCheckinAt)
        ) - (timesheet.approvedBreakMinutes ?? 0)
      : 0;
  const phoneOnly = useMediaQuery('(max-width: 790px)');

  const TimeVals = styled('span', {
    color: '#262626',
    fontSize: '14px',
    fontWeight: '600',
  });

  const TimeNet = styled('div', {
    color: '#4367A6',
    fontSize: '12px',
    fontWeight: '400',
    display: 'flex',
    width: '100%',
    span: {
      fontWeight: '700',
      marginLeft: '10px',
    },
    '@phoneOnly': {
      fontSize: '14px',
    },
  });
  
  return (
    <>
      <Stack vertical verticalGap={0}>
        <ProfileHeading>System Generated Hours</ProfileHeading>
        <Stack
          style={{
            marginTop: '16px',
            width: phoneOnly ? '100%' : '80%',
          }}
        >
          <FormElement displayType="row" label="Check-in:">
            {timesheet?.checkinAt ? (
              <TimeVals>{formatISO(timesheet.checkinAt, 'hh:mm a', jobAddress.timezone)}</TimeVals>
            ) : (
              <Small>-</Small>
            )}
          </FormElement>
          <FormElement displayType="row" label="Break:">
            <TimeVals>0 m</TimeVals>
          </FormElement>
        </Stack>

        <Stack
          style={{
            marginTop: '12px',
            width: phoneOnly ? '100%' : '80%',
          }}
        >
          <FormElement displayType="row" label="Check-out:">
            {timesheet?.checkoutAt ? (
              <TimeVals>{formatISO(timesheet.checkoutAt, 'hh:mm a', jobAddress.timezone)}</TimeVals>
            ) : (
              <Small>-</Small>
            )}
          </FormElement>
          <TimeNet>
            Net Hours:
            <span>
              {systemNetHours
                ? getTotalLengthOfShiftTimesheet(systemNetHours)
                : '-'}
            </span>
          </TimeNet>
        </Stack>

        <Stack gap={'0'} style={{ margin: '16px 0' }}>
          <ProfileHeading>Workers Submitted Hours</ProfileHeading>
        </Stack>

        <Stack
          style={{
            width: phoneOnly ? '100%' : '80%',
          }}
        >
          <FormElement displayType="row" label="Check-in:">
            {(timesheet?.checkinAt || timesheet?.reportedCheckinAt) &&
            timesheet?.checkinCoords ? (
              <TimeVals>
                {formatISO(
                  timesheet.reportedCheckinAt ?? timesheet.checkinAt,
                  'hh:mm a', jobAddress.timezone
                )}
              </TimeVals>
            ) : (
              <Small>-</Small>
            )}
          </FormElement>
          <FormElement displayType="row" label="Break:">
            {timesheet?.checkoutCoords ? (
              <TimeVals>{timesheet.reportedBreakMinutes ?? 0} m</TimeVals>
            ) : (
              <Small>-</Small>
            )}
          </FormElement>
        </Stack>

        <Stack style={{ marginTop: '12px', width: phoneOnly ? '100%' : '80%' }}>
          <FormElement displayType="row" label="Check-out:">
            {(timesheet?.checkoutAt || timesheet?.reportedCheckoutAt) &&
            timesheet?.checkoutCoords ? (
              <TimeVals>
                {formatISO(
                  timesheet.reportedCheckoutAt ?? timesheet.checkoutAt,
                  'hh:mm a', jobAddress.timezone
                )}
              </TimeVals>
            ) : (
              <Small>-</Small>
            )}
          </FormElement>
          <TimeNet>
            Net Hours:
            <span>
              {reportedNetHours
                ? getTotalLengthOfShiftTimesheet(reportedNetHours)
                : '-'}
            </span>
          </TimeNet>
        </Stack>

        {variant === TimesheetInformationVariants.DETAIL && (
          <>
            <Stack style={{ margin: '16px 0' }}>
              <ProfileHeading>Confirm Hours</ProfileHeading>
            </Stack>
            <FormElement displayType="row" label="Check-in:">
              {timesheet?.approvedCheckinAt ? (
                <TimeVals>
                  {formatISO(timesheet.approvedCheckinAt, 'hh:mm a', jobAddress.timezone)}
                </TimeVals>
              ) : (
                <Small>-</Small>
              )}
            </FormElement>
            <FormElement displayType="row" label="Check-out:">
              {timesheet?.approvedCheckoutAt ? (
                <TimeVals>
                  {formatISO(timesheet.approvedCheckoutAt, 'hh:mm a', jobAddress.timezone)}
                </TimeVals>
              ) : (
                <Small>-</Small>
              )}
            </FormElement>
            <FormElement displayType="row" label="Break:">
              <TimeVals>{timesheet?.approvedBreakMinutes ?? 0} m</TimeVals>
            </FormElement>
            <TimeNet>
              Net Hours:
              <span>
                {approvedNetHours
                  ? getTotalLengthOfShiftTimesheet(approvedNetHours)
                  : '-'}
              </span>
            </TimeNet>
            <div style={{ marginTop: '10px' }}>
              <ProfileHeading>Tips</ProfileHeading>
            </div>
            {timesheet?.status !== TimesheetStatusEnum.REJECTED &&
            timesheet?.tipAmount ? (
              <FormElement displayType="row" label="Tip amount">
                <TimeVals>{timesheet.tipAmount}</TimeVals>
              </FormElement>
            ) : (
              <FormElement displayType="row" label="Tip amount">
                <TimeVals>No tips</TimeVals>
              </FormElement>
            )}
          </>
        )}
      </Stack>
    </>
  );
};

export default TimesheetInformation;

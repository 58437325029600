import Version from '@/components/Version';
import { privacyPolicyUrl } from '@/constants/urls';
import Link from '@/elements/Link';
import Text from '@/elements/Text';
import styled from '@/styles';

const FOOTER_HEIGHT = '82px';

const Wrapper = styled('footer', {
  alignItems: 'center',
  backgroundColor: '$neutralA4',
  display: 'flex',
  gridArea: 'footer',
  justifyContent: 'space-between',
  height: FOOTER_HEIGHT,
  padding: '0 32px',
  '@phoneOnly': {
    padding: '0 8px',
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    gap: '5px',
  },
});

Wrapper.displayName = 'stitches(AppFooter.Wrapper)';

const Footer = () => {
  return (
    <Wrapper>
      <Version size="sm" />
      <Link
        target="_self"
        to={{ pathname: `/` }}
        onClick={() => {
          window.open(privacyPolicyUrl, '_self');
        }}
      >
        <Text color="lighter" size="sm">
          Privacy Policy
        </Text>
      </Link>
      <Text color="lighter" size="sm">
        &copy; {new Date().getFullYear()} Gravy Workforce Technologies
      </Text>
    </Wrapper>
  );
};

export { FOOTER_HEIGHT };
export default Footer;

import {
  differenceInMinutes,
  format,
  isAfter,
  isBefore,
  parseISO,
} from 'date-fns';

import {
  CancelRemoveReason,
  CancelJobReason,
  ContextCancelRemoveModal,
} from '@/constants/job';
import {
  GetJobQuery,
  Job,
  Scalars,
  TimesheetStatusEnum,
  UpdateJobMutationVariables,
  JobWorkerItemFragment,
} from '@/types/graphql';
import { closestDateToISO } from '@/util/date';

export type ShiftRange = {
  startAt: Date;
  endAt: Date;
};

export enum JobStatus {
  UPCOMING = 'upcoming',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  UNPUBLISHED = 'unpublished',
}
export enum TimeSheetStatus {
  INCOMPLETE = 'Incomplete',
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}

export const getJobStatusByJob = (
  job: Pick<Job, 'firstShiftStartAt' | 'lastShiftEndAt' | 'cancelledAt'>
) => {
  const startAt = parseISO(job.firstShiftStartAt);
  const endAt = parseISO(job.lastShiftEndAt);

  if (job.cancelledAt) {
    return JobStatus.CANCELLED;
  } else if (isBefore(new Date(), startAt)) {
    return JobStatus.UPCOMING;
  } else if (isAfter(new Date(), endAt)) {
    return JobStatus.COMPLETED;
  } else {
    return JobStatus.IN_PROGRESS;
  }
};

export const MINS_IN_DAY = 24 * 60;

export const getShiftIntervals = (shifts: GetJobQuery['job']['shifts']) => {
  const intervals: ShiftRange[][] = [];

  shifts.forEach((shift) => {
    const lastInterval = intervals[intervals.length - 1];

    const startAt = parseISO(shift.startAt);
    const endAt = parseISO(shift.endAt);

    if (!lastInterval || lastInterval.length === 0) {
      return intervals.push([{ startAt, endAt }]);
    }

    const lastShift = lastInterval[lastInterval.length - 1];

    if (
      differenceInMinutes(startAt, lastShift.startAt) !== MINS_IN_DAY ||
      differenceInMinutes(endAt, lastShift.endAt) !== MINS_IN_DAY
    ) {
      intervals.push([{ startAt, endAt }]);
    } else {
      lastInterval.push({ startAt, endAt });
    }
  });

  return intervals;
};

export const getRangeShiftsFormat = (
  shifts: GetJobQuery['job']['shifts'][0]
) => {
  const startAt = format(parseISO(shifts.startAt), 'HH:mm');
  const endAt = format(parseISO(shifts.endAt), 'HH:mm');

  return { startAt, endAt };
};

export const getJobValues = (
  job: GetJobQuery['job']
): UpdateJobMutationVariables => ({
  jobId: job.id,
  addressId: job.address.id ?? '',
  addressInstructions: job.addressInstructions ?? '',
  contactId: job.contact.id,
  contactInstructions: job.contactInstructions ?? '',
  instructions: job.instructions ?? '',
  uniformId: job.uniform.id,
  uniformInstructions: job.uniformInstructions ?? '',
});

export const getCannonicalTime = (
  startAt: string,
  checkTime: string | null,
  reportedTime: string | null
) => {
  if (checkTime) {
    if (reportedTime) {
      return closestDateToISO(startAt, checkTime, reportedTime);
    } else {
      return parseISO(checkTime);
    }
  } else {
    if (reportedTime) {
      return parseISO(reportedTime);
    } else {
      return null;
    }
  }
};

export const getTimesheetReport = (
  approvedCheckTime: string | null,
  reportedTime: string | null,
  userTime?: string | null
) => {
  const check = approvedCheckTime ? parseISO(approvedCheckTime) : null;
  const reported = reportedTime ? parseISO(reportedTime) : null;
  const userReportTime = userTime ? parseISO(userTime) : null;

  if (check || reported || userReportTime) {
    return check || reported || userReportTime;
  }

  return null;
};

export const getTotalLengthOfShiftTimesheet = (approvedMinutes: number) => {
  const hours: any = Math.floor(approvedMinutes / 60);
  const minutes: any = Math.floor(approvedMinutes % 60);
  return (hours > 0 ? `${hours} h` : '') + (minutes > 0 ? ` ${minutes} m` : '');
};

export const getRoundOff = (value: number, length: number = 2) => {
  const factor = Math.pow(10, length);
  return Math.floor(value * factor) / factor;
};

export const getTotalLengthOfShift = (approvedMinutes: number) => {
  let hours: any = Math.floor(approvedMinutes / 60);
  hours = hours < 10 ? '0' + hours : hours;
  let minutes: any = Math.floor(approvedMinutes % 60);
  minutes = minutes < 10 ? '0' + minutes : minutes;

  return `${hours}h${minutes > 0 ? ` and ${minutes}m` : ''}`;
};

export const getOptionValueForCancelOrDismiss = (
  context: ContextCancelRemoveModal
): {
  id: Scalars['Int'];
  label: Scalars['String'];
}[] => {
  switch (context) {
    case ContextCancelRemoveModal.CancelOrder:
    case ContextCancelRemoveModal.CancelJob: {
      return Object.values(CancelJobReason).map((item, index) => ({
        id: index + 1,
        label: item,
      }));
    }
    case ContextCancelRemoveModal.RemoveWorker: {
      return Object.values(CancelRemoveReason).map((item, index) => ({
        id: index + 1,
        label: item,
      }));
    }
    default: {
      return [];
    }
  }
};

export const getBodyAndTitleForCancelOrDismiss = (
  context: ContextCancelRemoveModal
) => {
  return {
    [ContextCancelRemoveModal.CancelJob]: {
      title: 'Cancel Job',
      body: 'Are you sure you want to cancel this job?',
      textButton: 'Cancel Job',
      sizeModal: 'sm',
      otherValue: CancelJobReason.Other,
    },
    [ContextCancelRemoveModal.CancelOrder]: {
      title: 'Cancel Order',
      body: 'Are you sure you want to cancel this order?',
      textButton: 'Cancel Order',
      sizeModal: 'sm',
      otherValue: CancelJobReason.Other,
    },
    [ContextCancelRemoveModal.RemoveWorker]: {
      title: 'Remove Worker',
      body: 'Are you sure you want to remove this worker?',
      textButton: 'Submit',
      sizeModal: 'xs',
      otherValue: CancelRemoveReason.Other,
    },
  }[context];
};

export const calculateNetTime = (
  checkIn: string | null,
  checkOut: string | null,
  breakTime?: number
) => {
  if (!checkIn || !checkOut) return 0;
  return (
    differenceInMinutes(parseISO(checkOut), parseISO(checkIn)) -
    (breakTime || 0)
  );
};

export const getTimeSheetStatus = (timesheet: any) => {
  if (!timesheet) return undefined;
  if (timesheet.status === TimesheetStatusEnum.IN_PROGRESS) {
    const containcheckout =
      timesheet.approvedCheckoutAt || timesheet.reportedCheckoutAt;
    return containcheckout
      ? TimeSheetStatus.PENDING
      : TimeSheetStatus.INCOMPLETE;
  } else if (timesheet.status === TimesheetStatusEnum.PENDING) {
    return TimeSheetStatus.PENDING;
  } else if (timesheet.status === TimesheetStatusEnum.APPROVED) {
    return TimeSheetStatus.APPROVED;
  } else if (timesheet.status === TimesheetStatusEnum.REJECTED) {
    return TimeSheetStatus.REJECTED;
  }
};

export const enableCheckboxForClient = (ts: any, jobStatus: JobStatus) => {
  const tsStatus = getTimeSheetStatus(ts);
  if (
    tsStatus === TimeSheetStatus.PENDING ||
    tsStatus === TimeSheetStatus.INCOMPLETE ||
    (jobStatus === JobStatus.COMPLETED && !tsStatus)
  ) {
    return true;
  }
  return false;
};

export const getSortedWorkerSkillsList = (
  workerSkills: JobWorkerItemFragment['worker']['workerSkills']
) => {
  const workerSkillsNonZero = workerSkills.filter((skill) => {
    return (skill?.jobsWorked ?? 0) > 0;
  });
  const workerSkillsWithCountZero = workerSkills.filter((skill) => {
    return skill?.jobsWorked === 0 ?? false;
  });
  // Sort by `count` in descending order
  const sortedByCount = workerSkillsNonZero.sort(
    (a, b) => (b?.jobsWorked ?? 0) - (a?.jobsWorked ?? 0)
  );
  // Sort by `name` in ascending order
  const sortedByName = workerSkillsWithCountZero.sort((a, b) =>
    a.skill.name.localeCompare(b.skill.name)
  );
  // Merge the sorted arrays
  const mergedWorkerSkillsArray = sortedByCount.concat(sortedByName);
  return mergedWorkerSkillsArray;
};

import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import Alert from '@/components/Alert';
import type { Variants } from '@/components/Alert';

export type Props = {
  title?: string;
  description: string;
  icon?: IconDefinition;
  marginVertical?: boolean;
} & Variants;

const SubmitErrorAlert = ({
  title = 'Something went wrong',
  description,
  icon = faExclamationTriangle,
  status = 'warning',
  marginVertical = false,
}: Props) => (
  <Alert {...{ title, description, icon, status, marginVertical }} />
);

export default SubmitErrorAlert;

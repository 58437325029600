import { useCallback } from 'react';
import { useModal } from 'react-modal-hook';

import { useChangeWorkerAvatarMutation } from '@/graphql';
import ChangeAvatarModal from '@/modals/ChangeAvatarModal';
import { GetWorkerQuery } from '@/types/graphql';

const useChangeAvatarModal = (
  worker: GetWorkerQuery['worker'] | null | undefined
) => {
  const [workerChangeAvatar] = useChangeWorkerAvatarMutation();

  const handleUpload = useCallback(
    (avatarKey: string) =>
      workerChangeAvatar({ variables: { avatarKey, workerId: worker!.id } }),
    [worker?.id]
  );

  const [showModal, hideModal] = useModal(
    () => (
      <ChangeAvatarModal
        currentAvatarUrl={worker?.avatarUrl ?? null}
        hideModal={hideModal}
        onUpload={handleUpload}
      />
    ),
    [worker?.avatarUrl]
  );

  return showModal;
};

export default useChangeAvatarModal;

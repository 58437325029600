import { SubmitHelpers } from '@area2k/use-form';
import { useCallback } from 'react';

import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import Form from '@/form';
import FormColumns from '@/form/FormColumns';
import SingleOptionGroupField from '@/form/SingleOptionGroupField';
import { useUpdateBackgroundStatusMutation } from '@/graphql';
import { BackgroundStatusEnum, GetWorkerQuery } from '@/types/graphql';
import { handleMutationFormError } from '@/util/error';

type Props = {
  hideModal: () => void;
  worker: GetWorkerQuery['worker'];
};

type FormValues = {
  backgroundStatus: BackgroundStatusEnum;
};

const backgroundStatusOptions = [
  { label: 'In Progress', value: BackgroundStatusEnum.IN_PROGRESS },
  { label: 'Needs Review', value: BackgroundStatusEnum.NEEDS_REVIEW },
  { label: 'Approved', value: BackgroundStatusEnum.APPROVED },
  { label: 'Declined', value: BackgroundStatusEnum.DECLINED },
  { label: 'N/A', value: BackgroundStatusEnum.NA },
];

const ChangeBackgroundModal = ({ hideModal, worker }: Props) => {
  const [UpdateCheck] = useUpdateBackgroundStatusMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(worker),
        fields: {
          status() {},
        },
      });
    },
  });

  const initialValues: FormValues = {
    backgroundStatus: worker.backgroundStatus
      ? worker.backgroundStatus.toUpperCase()
      : BackgroundStatusEnum.IN_PROGRESS,
  };

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        await UpdateCheck({
          variables: {
            ...values,
            workerId: worker.id,
          },
        });
        hideModal();
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
          errorMap: {
            all: (gqlError) => ({
              title: gqlError.name,
              message: gqlError.message,
              status: 'danger',
            }),
          },
        });
      }
    },
    [worker]
  );

  return (
    <Modal
      disableClickout
      size="xs"
      title="Update Background Status"
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <FormColumns>
            <SingleOptionGroupField
              vertical
              fieldId="backgroundStatus"
              options={backgroundStatusOptions}
            />
          </FormColumns>
          <Stack justify="end">
            <Button
              a11yLabel="Submit"
              id="btn-submit"
              label="Save"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  );
};

export default ChangeBackgroundModal;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.afterArrow{
  overflow: visible !important;
}

.afterArrow::after{
  content: '';
  display: block;  
	position: absolute;
	left: -190px;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  bottom: -10px;
	width: 0;
	height: 0;
	border-top: 10px solid white;
	border-right: 10px solid transparent;
	border-bottom: 0 solid transparent;
	border-left: 10px solid transparent;
}
.worker-status-chip ,
.icon-add-rating {
	display: flex;
	align-items: center;
}
.worker-status-chip span {
    display: inline-block;
    padding: 4px 10px;
}
.rating-stack {
	border: 1px solid #D3D3D3 !important;
	display: flex;
    align-items: center;
}
.rating-disabled {
	border: 1px solid #EBEBEB !important;
	cursor: not-allowed;
}
.rating-disabled div {
	cursor: not-allowed;
	pointer-events: none;
}
.rating-disabled div svg path {
	fill: #EBEBEB !important;
	pointer-events: none;
}

#modal {
	border-radius: 0;
}
#close-modal-btn svg path{
	fill: #A1A1A1 !important;
}`, "",{"version":3,"sources":["webpack://./src/routes/Agency/Job/WorkerTable/index.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;;AAEA;EACE,WAAW;EACX,cAAc;CACf,kBAAkB;CAClB,YAAY;EACX,QAAQ;EACR,kBAAkB;EAClB,iBAAiB;EACjB,aAAa;CACd,QAAQ;CACR,SAAS;CACT,4BAA4B;CAC5B,oCAAoC;CACpC,kCAAkC;CAClC,mCAAmC;AACpC;AACA;;CAEC,aAAa;CACb,mBAAmB;AACpB;AACA;IACI,qBAAqB;IACrB,iBAAiB;AACrB;AACA;CACC,oCAAoC;CACpC,aAAa;IACV,mBAAmB;AACvB;AACA;CACC,oCAAoC;CACpC,mBAAmB;AACpB;AACA;CACC,mBAAmB;CACnB,oBAAoB;AACrB;AACA;CACC,wBAAwB;CACxB,oBAAoB;AACrB;;AAEA;CACC,gBAAgB;AACjB;AACA;CACC,wBAAwB;AACzB","sourcesContent":[".afterArrow{\n  overflow: visible !important;\n}\n\n.afterArrow::after{\n  content: '';\n  display: block;  \n\tposition: absolute;\n\tleft: -190px;\n  right: 0;\n  margin-right: auto;\n  margin-left: auto;\n  bottom: -10px;\n\twidth: 0;\n\theight: 0;\n\tborder-top: 10px solid white;\n\tborder-right: 10px solid transparent;\n\tborder-bottom: 0 solid transparent;\n\tborder-left: 10px solid transparent;\n}\n.worker-status-chip ,\n.icon-add-rating {\n\tdisplay: flex;\n\talign-items: center;\n}\n.worker-status-chip span {\n    display: inline-block;\n    padding: 4px 10px;\n}\n.rating-stack {\n\tborder: 1px solid #D3D3D3 !important;\n\tdisplay: flex;\n    align-items: center;\n}\n.rating-disabled {\n\tborder: 1px solid #EBEBEB !important;\n\tcursor: not-allowed;\n}\n.rating-disabled div {\n\tcursor: not-allowed;\n\tpointer-events: none;\n}\n.rating-disabled div svg path {\n\tfill: #EBEBEB !important;\n\tpointer-events: none;\n}\n\n#modal {\n\tborder-radius: 0;\n}\n#close-modal-btn svg path{\n\tfill: #A1A1A1 !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useModal } from 'react-modal-hook';

import OrderFiltersModal from './OrderFiltersModal';
import OrderStatusFilter from './OrderStatusFilter';
import TagDisplay from './TagDisplay';

import Button from '@/components/Button';
import Dropdown from '@/components/Dropdown';
import IconicButton from '@/components/IconicButton';
import Stack from '@/components/Stack';
import TextInput from '@/components/TextInput';
import ButtonGroup from '@/elements/ButtonGroup';
import useMediaQuery from '@/hooks/useMediaQuery';
import { OrderFilterSetInput } from '@/types/graphql';

export enum OrderStatus {
  ALL = 'ALL',
  IN_PROGRESS = 'IN_PROGRESS',
  PAST = 'PAST',
  UPCOMING = 'UPCOMING',
}

type Props = {
  filters: OrderFilterSetInput;
  hasValidAccountStatus: boolean;
  query: string;
  onQueryChange: (query: string) => void;
  onChangeFilter: <K extends keyof OrderFilterSetInput>(
    key: K,
    value: NonNullable<OrderFilterSetInput[K]>
  ) => void;
  onClearFilter: (key: keyof OrderFilterSetInput) => void;
  onClearAll: () => void;
};

const OrderFilters = ({
  filters,
  hasValidAccountStatus,
  query,
  onQueryChange,
  onChangeFilter,
  onClearAll,
  onClearFilter,
}: Props) => {
  const [selectedOrderStatusValue, setSelectedOrderStatusValue] =
    useState<OrderStatus>(OrderStatus.IN_PROGRESS);

  const [showFiltersModal, hideFiltersModal] = useModal(
    () => (
      <OrderFiltersModal
        filters={filters}
        hideModal={hideFiltersModal}
        orderStatusValue={selectedOrderStatusValue}
        setOrderStatusValue={setSelectedOrderStatusValue}
        onChangeFilter={onChangeFilter}
        onClearAll={onClearAll}
        onClearFilter={onClearFilter}
      />
    ),
    [filters]
  );

  const phoneOnly = useMediaQuery('(max-width: 559px)');

  return (
    <Stack vertical gap={16}>
      <Stack wrap gap={phoneOnly ? 8 : 16}>
        <div style={{ flex: 1 }}>
          <TextInput
            id="input-search-orders"
            minWidth="sm"
            placeholder="Search for orders..."
            value={query}
            onChange={(ev) => onQueryChange(ev.currentTarget.value)}
          />
        </div>
        <div>
          {!phoneOnly ? (
            <ButtonGroup>
              <Dropdown
                Content={() => (
                  <div style={{ padding: 16 }}>
                    <OrderStatusFilter
                      filters={filters}
                      idPrefix="dropdown"
                      orderStatusValue={selectedOrderStatusValue}
                      setOrderStatusValue={setSelectedOrderStatusValue}
                      onChangeFilter={onChangeFilter}
                      onClearFilter={onClearFilter}
                    />
                  </div>
                )}
                justify="right"
                size="sm"
              >
                <Button
                  hasPopover
                  a11yLabel="View order status filters"
                  appearance="outline"
                  id="dropdown_orderStatus"
                  label="Order status"
                />
              </Dropdown>
              <Button
                a11yLabel="View all order filters"
                appearance="outline"
                id="btn-more-filters"
                label="More filters"
                onClick={showFiltersModal}
              />
            </ButtonGroup>
          ) : (
            <IconicButton
              appearance={phoneOnly ? 'outline' : 'clear'}
              icon={faFilter}
              id="btn-filters"
              size={phoneOnly ? 'md' : 'sm'}
              onClick={showFiltersModal}
            />
          )}
        </div>
      </Stack>
      {hasValidAccountStatus && (
        <TagDisplay
          filters={filters}
          orderStatusValue={selectedOrderStatusValue}
          setOrderStatusValue={setSelectedOrderStatusValue}
          onChangeFilter={onChangeFilter}
          onClearAll={onClearAll}
          onClearFilter={onClearFilter}
        />
      )}
    </Stack>
  );
};

export default OrderFilters;

import { JobWorkerStatusEnum } from '@/types/graphql';

export const ACCEPTANCE_STATUSES_TO_HIRED_WORKERS = [
  JobWorkerStatusEnum.HIRED,
  JobWorkerStatusEnum.IN_PROGRESS,
  JobWorkerStatusEnum.COMPLETED,
  JobWorkerStatusEnum.APPROVED,
  JobWorkerStatusEnum.PAID,
  JobWorkerStatusEnum.EXPIRED,
  JobWorkerStatusEnum.REJECTED,
  JobWorkerStatusEnum.CONFIRMED,
];

enum ExtraAllOption {
  ALL = 'ALL',
}

export const JobWorkerStatusFilterEnum = {
  ...ExtraAllOption,
  ...JobWorkerStatusEnum,
};

export type JobWorkerStatusFilterEnumType =
  | JobWorkerStatusEnum
  | ExtraAllOption;

export enum CancelJobReason {
  EventCancelled = 'Event cancelled',
  SupervisorNoAvailable = 'Shift supervisor no longer available',
  IncorrectJobDetails = 'Posted with incorrect job details',
  JobRequirementsChanged = 'Job requirements changed',
  StaffNoNeeded = 'Staff no longer needed',
  FoundStaffElsewhere = 'Found staff elsewhere',
  InclementWeather = 'Inclement weather',
  Other = 'Other',
}

export enum CancelRemoveReason {
  WorkerUnavailable = 'Worker reported they are unavailable',
  StaffNoNeeded = 'Staff no longer needed',
  ShiftOverbooked = 'Shift was overbooked',
  DontWantThisWorker = 'I do not want this worker',
  Other = 'Other',
}

export enum ContextCancelRemoveModal {
  CancelJob = 'CancelJob',
  CancelOrder = 'CancelOrder',
  RemoveWorker = 'RemoveWorker',
}

export enum ContextConfirmModalCancellation {
  CancelJob = 'CancelJob',
  CancelOrder = 'CancelOrder',
}

export enum OrderFilterKeysEnum {
  ACCOUNT = 'account',
  AGENCY = 'agency',
  APPLY_DISJUNCTIVELY = 'applyDisjunctively',
  CUSTOMER = 'customer',
  END_AT = 'endAt',
  JOBS_COUNT = 'jobsCount',
  QUERY = 'query',
  START_AT = 'startAt',
  STATUS = 'status',
  TAGS = 'tags',
  UUID = 'uuid',
}

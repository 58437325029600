import Badge from '@/components/Badge';
import { PayrollItemStatusEnum, PayrollStatusEnum } from '@/types/graphql';

type PayrollsStatus = {
  status: PayrollStatusEnum | PayrollItemStatusEnum;
};

const PayrollStatusBadge = ({ status }: PayrollsStatus) => {
  switch (status) {
    case PayrollStatusEnum.PAID: {
      return <Badge label="Paid" status="theme" />;
    }
    case PayrollStatusEnum.PENDING: {
      return <Badge label="Pending" status="warning" />;
    }
    case PayrollStatusEnum.PROCESSING: {
      return <Badge label="Processing" status="warning" />;
    }
    case PayrollStatusEnum.PARTIALLY_PAID: {
      return <Badge label="Partially paid" status="warning" />;
    }
    case PayrollStatusEnum.FAILED: {
      return <Badge label="Failed" status="danger" />;
    }
    default: {
      return <Badge label="Draft" status="disabled" />;
    }
  }
};

export default PayrollStatusBadge;

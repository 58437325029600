import useModal from '@area2k/use-modal';

import Card from '@/components/Card';
import CommentsList from '@/components/CommentsList';
import AddCommentsModal from '@/modals/AddCommentModal';
import { GetWorkerQuery } from '@/types/graphql';

type Props = {
  worker: GetWorkerQuery['worker'];
};

const Comments = ({ worker }: Props) => {
  const [showAddModal, hideAddModal] = useModal(
    () => <AddCommentsModal hideModal={hideAddModal} worker={worker} />,
    [worker]
  );

  return (
    <Card
      actions={[
        {
          a11yLabel: 'Add a comment about the GravyWorker',
          label: 'Add',
          onAction: showAddModal,
          id: 'add-comment',
        },
      ]}
      title="Comments"
    >
      <CommentsList worker={worker} />
    </Card>
  );
};

export default Comments;

import styled from '@/styles';

const Popover = styled('div', {
  display: 'none',

  position: 'absolute',

  zIndex: '$popover',

  variants: {
    justify: {
      center: {
        left: '50%',

        transform: 'translateX(-50%) perspective(1px);',
      },
      left: {
        left: 0,
      },
      right: {
        right: 0,
      },
      'outside-left': {
        right: 'calc(100% + 8px)',
      },
      'outside-right': {
        left: 'calc(100% + 8px)',
      },
    },
    align: {
      above: {
        bottom: 'calc(100% + 4px)',
      },
      below: {
        top: 'calc(100% + 4px)',
      },
      bottom: {
        bottom: 'calc(50% - 24px)',
      },
      middle: {
        top: '50%',

        transform: 'translateY(-50%) perspective(1px);',
      },
      top: {
        top: 'calc(50% - 24px)',
      },
      aboveRight: {
        bottom: 'calc(100% + 4px)',
        left: '100px',
      },
    },
    size: {
      auto: {},
      parent: {
        // NOTE: if we dont add 1px, for some reason the opener shows through
        minWidth: 'calc(100% + 1px)',
      },
      sm: {
        minWidth: '180px',
      },
      md: {
        minWidth: '220px',
      },
      lg: {
        minWidth: '300px',
      },
    },
    status: {
      closed: {
        display: 'none',
      },
      open: {
        display: 'block',
      },
    },
    height: {
      auto: {},
      sm: {
        maxHeight: '80px',
        overflow: 'auto',
      },
      md: {
        maxHeight: '120px',
        overflow: 'auto',
      },
      lg: {
        maxHeight: '160px',
        overflow: 'auto',
      },
    },
    margin: {
      none: {
        marginRight: '0px',
      },
      rightSm: {
        marginRight: '3px',
      },
      rightMd: {
        marginRight: '5px',
      },
      rightLg: {
        marginRight: '10px',
      },
    },
  },

  compoundVariants: [
    {
      align: 'middle',
      justify: 'center',
      css: {
        transform: 'translate(-50%, -50%) perspective(1px)',
      },
    },
  ],

  defaultVariants: {
    align: 'below',
    justify: 'left',
    size: 'auto',
    status: 'closed',
  },
});

Popover.displayName = 'stitches(Popover.Wrapper)';

export default Popover;

import { addDays, differenceInMinutes, format, parse } from 'date-fns';

import { Schedule } from '../../context';

import { PAST_DAYS } from '@/constants/general';
import { getRoundOff } from '@/routes/Agency/Job/util';
import { Role } from '@/routes/PrivateRoute';

export const parseStringsHours = (input: string, day?: any) => {
  const time = input.split(':');
  const hours = +time[0];
  const minutes = +time[1];
  const date = day
    ? new Date(day).setHours(hours, minutes, 0)
    : new Date().setHours(hours, minutes, 0);
  return date;
};

export const toDate = (value: string | number | Date) => {
  const date = new Date(value);
  return date;
};

export type ItemDate = number | Date | undefined;

export const timeToSeconds = (time: string) => {
  const aux: number[] = time.split(':').map((e) => +e);
  return aux[0] * 3600 + aux[1] * 60;
};
export const getStartDate = (date: Date, currentAdmin): Date => {
  date.setHours(0, 0, 0, 0);
  if (date && currentAdmin!.role === Role.TENANT_ADMIN) {
    date.setDate(date.getDate() - PAST_DAYS);
  }
  return date;
};
export const checkTimeRange = (endTime, startTime, minLimitOfRage) => {
  if (endTime && startTime) {
    const parseEndTime =
      endTime < startTime
        ? addDays(parseStringsHours(endTime), 1)
        : parseStringsHours(endTime);
    const parseStartTime = parseStringsHours(startTime);
    const difference = differenceInMinutes(parseEndTime, parseStartTime);
    return difference <= minLimitOfRage;
  }
  return false;
};
export const getDifferenceInMinutes = (endTime, startTime) => {
  if (endTime && startTime) {
    const parseEndTime =
      endTime < startTime
        ? addDays(parseStringsHours(endTime), 1)
        : parseStringsHours(endTime);
    const parseStartTime = parseStringsHours(startTime);
    return differenceInMinutes(parseEndTime, parseStartTime);
  }
  return 0;
};

export const groupBy = (array: Schedule[], key: string) =>
  array.reduce((acc, item) => {
    const val = item[key];
    acc[val] = acc[val] || [];
    acc[val].push(item);
    return acc;
  }, {} as Record<string, Schedule[]>);

export const getDatesInRange = (range: any) => {
  const startDate = toDate(range.startDate);
  const endDate = toDate(range.endDate);
  const dates: any[] = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = addDays(currentDate, 1);
  }
  return dates;
};

export const dateParser = (date: Date | string) => {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return date.toISOString().split('T')[0];
};

export const isHoliday = (holidays: any[], date: Date | null) => {
  if (!date) return false;
  const dateStr = format(date, 'yyyy-MM-dd');
  return holidays.find((h) => h.date === dateStr);
};

export const calculateRates = (rateInput: string, minValue: number) => {
  const input = Number(rateInput) * 100;
  return input > minValue ? input : minValue;
};

export const isFutureDate = (date: Date, time?: string) => {
  if (time) {
    return parse(time, 'HH:mm', date) > new Date();
  }
  return date > new Date();
};

export const updSchedulesWithHolidayRates = ({
  schedules,
  calcPayRate,
  calcBillRate,
  holidays,
  minPay,
}: {
  schedules: Schedule[];
  calcPayRate: number;
  calcBillRate: number;
  holidays: any[];
  minPay: number;
}) => {
  return schedules.map((sch) => {
    const holiday = isHoliday(holidays, sch.dateRange?.startDate);
    if (holiday) {
      return {
        ...sch,
        payRate: Math.round(calcPayRate * holiday.rate),
        costRate: Math.round(calcBillRate * holiday.rate),
        originalPayRate: calcPayRate,
        originalCostRate: calcBillRate,
        isHoliday: true,
        rate: holiday.rate,
        minPay,
      };
    } else {
      return {
        ...sch,
        payRate: calcPayRate,
        costRate: calcBillRate,
        originalPayRate: calcPayRate,
        originalCostRate: calcBillRate,
        isHoliday: false,
        rate: undefined,
        minPay,
      };
    }
  });
};

export const getTotalHoursInDecimal = (totalMintsInSeconds: number) => {
  const hours = getRoundOff(totalMintsInSeconds / 3600);
  return hours < 10 ? '0' + hours : hours;
};

import { createSvgIcon } from '@mui/material';

const EditTimeIcon = createSvgIcon(
  <svg
    fill="none"
    height="19"
    viewBox="0 0 18 19"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.25 3.63281H3C2.60218 3.63281 2.22064 3.79085 1.93934 4.07215C1.65804 4.35346 1.5 4.73499 1.5 5.13281V15.6328C1.5 16.0306 1.65804 16.4122 1.93934 16.6935C2.22064 16.9748 2.60218 17.1328 3 17.1328H13.5C13.8978 17.1328 14.2794 16.9748 14.5607 16.6935C14.842 16.4122 15 16.0306 15 15.6328V10.3828"
      stroke="#1C1C1C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M13.875 2.50774C14.1734 2.20937 14.578 2.04175 15 2.04175C15.422 2.04175 15.8266 2.20937 16.125 2.50774C16.4234 2.80611 16.591 3.21078 16.591 3.63274C16.591 4.05469 16.4234 4.45937 16.125 4.75774L9 11.8827L6 12.6327L6.75 9.63274L13.875 2.50774Z"
      stroke="#1C1C1C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>,
  'EditTimeIcon'
);
export default EditTimeIcon;

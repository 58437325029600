import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { Body } from '@/components/Typography';

type Props = {
  onConfirm: () => void;
  onHide: () => void;
};

const SignOutModal = ({ onHide, onConfirm }: Props) => {
  return (
    <Modal size="xs" title="Sign out" onRequestClose={onHide}>
      <Card.Section>
        <Stack vertical gap={24}>
          <Body weight="medium">Are you sure you want to sign out?</Body>

          <Stack justify="end">
            <Button
              a11yLabel="Cancel"
              appearance="outline"
              id="cancel-btn"
              label="Cancel"
              onClick={onHide}
            />
            <Button
              a11yLabel="Sign out"
              appearance="primary"
              id="sign-out-btn"
              label="Sign out"
              onClick={onConfirm}
            />
          </Stack>
        </Stack>
      </Card.Section>
    </Modal>
  );
};

export default SignOutModal;

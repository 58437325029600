import { faMinus } from '@fortawesome/free-solid-svg-icons';
import {
  CheckBoxOutlined,
  CloseOutlined,
  IndeterminateCheckBoxOutlined,
} from '@mui/icons-material';
import {
  Dialog,
  DialogTitle,
  IconButton,
  Stack as MuiStack,
  Divider,
  Checkbox,
} from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';

import {
  Schedule,
  useJobDraftActions,
  useJobDraftState,
} from '../../../context';

import ShiftsWorkersModalRow from './ShiftsWorkersModalRow';
import { CountSection } from './StaffingUtils';

import Button from '@/components/Button';
import Card from '@/components/Card';
import Stack from '@/components/Stack';
import Table from '@/components/Table';
import Tabs, { TabDefinition } from '@/components/Tabs';
import { Subheading } from '@/components/Typography';
import Text from '@/elements/Text';
import useMediaQuery from '@/hooks/useMediaQuery';
import { theme } from '@/styles/colors';
import { JobWorkerItemFragment } from '@/types/graphql';
import { formatTime } from '@/util/datetime';

import '../index.css';

type ShiftWorkersModalProps = {
  schedule: Schedule;
  isClientAdmin: boolean;
  hiredWorkersList: JobWorkerItemFragment['worker'][];
  invitedWorkersList: JobWorkerItemFragment['worker'][];
  hideModal: () => void;
  workersFullySatisfied?: boolean;
  isLTA: boolean;
};

const ShiftWorkersModal = ({
  schedule,
  isClientAdmin,
  hiredWorkersList,
  invitedWorkersList,
  hideModal,
  workersFullySatisfied,
  isLTA,
}: ShiftWorkersModalProps) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const phoneStyles = phoneOnly
    ? { height: '90vh', width: '100%', borderRadius: '20px' }
    : {};
  const { updateSchedules } = useJobDraftActions();
  const { schedules } = useJobDraftState();

  const [tab, setTab] = useState(isClientAdmin && !isLTA ? 1 : 0);
  const [activeTabWorkersList, setActiveTabWorkersList] = useState<
    JobWorkerItemFragment['worker'][]
  >(isClientAdmin && !isLTA ? invitedWorkersList : hiredWorkersList);
  const [selected, setSelected] = useState<JobWorkerItemFragment['worker'][]>(
    [],
  );

  const TABS: TabDefinition[] = [
    {
      a11yLabel: 'hired Workers',
      label: phoneOnly ? 'Hired' : 'Hired Workers',
      id: `${hiredWorkersList.length}`,
    },
    {
      a11yLabel: 'invited Workers',
      label: phoneOnly ? 'Invited' : 'Invited Workers',
      id: `${invitedWorkersList.length}`,
    },
  ];
  const hiredTab = tab === 0;

  const tabOnChangeHandler = (tab: number) => {
    setTab(tab);
    setActiveTabWorkersList(tab === 0 ? hiredWorkersList : invitedWorkersList);
    setSelected([]);
  };

  const handleCancelSelected = (wId?: string) => {
    const updSchedules = schedules.map((sch) => {
      if (sch.groupId === schedule.groupId) {
        const property = hiredTab ? 'hiredWorkers' : 'selectedWorkers';
        const selectedWorkers = sch[property].filter((worker) => {
          if (wId) {
            return worker.id !== wId;
          } else {
            return !selected.find((s) => s.id === worker.id);
          }
        });
        setActiveTabWorkersList(selectedWorkers);
        if (hiredTab) {
          return { ...sch, hiredWorkers: selectedWorkers };
        }
        return { ...sch, selectedWorkers };
      }
      return sch;
    });
    setSelected([]);
    updateSchedules({ schedules: updSchedules });
  };

  useEffect(() => {
    if (hiredWorkersList.length === 0 && invitedWorkersList.length === 0) {
      hideModal();
    }
  }, [hiredWorkersList, invitedWorkersList]);

  return (
    <Dialog
      PaperProps={{
        style: {
          height: 650,
          width: 700,
          overflowX: 'hidden',
          borderRadius: '20px',
          maxHeight: 'calc(100% - 40px)',
          maxWidth: 700,
          ...phoneStyles,
        },
      }}
      aria-labelledby="worker-profile-dialog"
      className="worker-profile-dialog-view"
      fullScreen={phoneOnly}
      open={true}
      sx={{
        '& .MuiDialog-container': phoneOnly
          ? { alignItems: 'end', marginBottom: '2px' }
          : {},
      }}
      onClose={hideModal}
    >
      <DialogTitle
        id="shift-workers-dialog-title"
        sx={{ m: 0, px: '20px', pt: '14px', pb: 0, fontWeight: '600' }}
      >
        <Subheading weight="semibold">
          {schedule?.dateRange?.startDate
            ? format(schedule?.dateRange?.startDate, 'E, MMMM dd')
            : ''}
        </Subheading>
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 6,
            top: 4,
          }}
          onClick={hideModal}
        >
          <CloseOutlined />
        </IconButton>
      </DialogTitle>
      <Card.Section css={{ padding: '0px' }}>
        <MuiStack
          direction={'column'}
          padding="0px 20px 20px 20px"
          spacing={{ md: 3, xs: 2 }}
        >
          <MuiStack direction={'column'} spacing={{ xs: 1 }}>
            <Stack vertical gap={12}>
              <Stack
                css={{ flexDirection: 'row', alignItems: 'base-line' }}
                gap="4px"
              >
                <Text as="div">
                  {schedule.startTime && schedule.endTime ? (
                    <>
                      {formatTime(schedule.startTime, 'h:mmaaa') + ' - '}
                      {formatTime(schedule.endTime, 'h:mmaaa')}
                    </>
                  ) : (
                    <>-</>
                  )}
                </Text>
                <Text css={{ color: '#7A7A7A' }} lineHeightProp={'small'}>
                  {` | ${schedule.mandatoryBreakTime} min break`}
                </Text>
              </Stack>

              <CountSection
                fullyStaffed={workersFullySatisfied}
                hiredCount={hiredWorkersList.length}
                invitedCount={invitedWorkersList.length}
                isClientAdmin={isClientAdmin}
                isLTA={isLTA}
                quantity={schedule?.quantity}
              />
            </Stack>

            <Divider
              style={{ width: '100%', marginTop: '16px', marginBottom: '8px' }}
            />

            {!isClientAdmin && !isLTA && (
              <div
                className="hide-scroll"
                style={{
                  width: '100%',
                  overflow: 'auto hidden',
                  marginBottom: '12px',
                }}
              >
                <Tabs
                  fit
                  selected={tab}
                  showTabOnMobile={true}
                  tabs={TABS}
                  onSelect={tabOnChangeHandler}
                />
              </div>
            )}
            {!isClientAdmin && isLTA && (
              <Subheading weight="semibold">{'Hired Workers'}</Subheading>
            )}
            {isClientAdmin && (
              <Subheading weight="semibold">
                {isLTA ? 'Hired Workers' : 'Invited Workers'}
              </Subheading>
            )}
            {activeTabWorkersList.length > 0 && !phoneOnly && (
              <MuiStack direction={'row'} justifyContent={'space-between'}>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (selected.length) {
                      setSelected([]);
                    } else {
                      setSelected(activeTabWorkersList);
                    }
                  }}
                >
                  <Checkbox
                    checked={activeTabWorkersList.length === selected.length}
                    checkedIcon={
                      <CheckBoxOutlined htmlColor={theme.themeDefault} />
                    }
                    indeterminate={
                      selected.length > 0 &&
                      selected.length < activeTabWorkersList.length
                    }
                    indeterminateIcon={
                      <IndeterminateCheckBoxOutlined
                        htmlColor={theme.themeDefault}
                      />
                    }
                    size="small"
                    style={{ padding: 0 }}
                  />
                  <Text
                    color={'themeDefault'}
                    css={{ top: '2px' }}
                    size={'lg'}
                    weight={'medium'}
                  >
                    {' '}
                    Select all
                  </Text>
                </div>
                {selected.length > 0 && (
                  <Button
                    a11yLabel="cancel button"
                    appearance="secondary"
                    iconLeft={faMinus}
                    id={hiredTab ? 'hire-btn' : 'invite-btn'}
                    label={hiredTab ? 'Cancel Hire' : 'Cancel Invite'}
                    style={{
                      background: '#EBEBEB',
                      color: '#262626',
                    }}
                    type="button"
                    onClick={() => handleCancelSelected()}
                  />
                )}
              </MuiStack>
            )}

            <Stack vertical gap={24}>
              <div style={{ width: '100%' }}>
                <div style={{ padding: phoneOnly ? '0px' : '20px 0' }}>
                  <Table>
                    <tbody>
                      {activeTabWorkersList.map((w) => (
                        <ShiftsWorkersModalRow
                          key={w.id}
                          handleCancel={handleCancelSelected}
                          isClientAdmin={isClientAdmin}
                          phoneOnly={phoneOnly}
                          selected={!!selected.find((s) => s.id === w.id)}
                          setSelectedworkers={setSelected}
                          tabId={tab}
                          worker={w}
                        />
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Stack>
          </MuiStack>
        </MuiStack>
      </Card.Section>
    </Dialog>
  );
};

export default ShiftWorkersModal;

import Icon from '@/elements/Icon';
import styled from '@/styles';

export const IconProgressCustomerProfile = styled(Icon, {
  variants: {
    completed: {
      true: {
        color: '$themeDefault',
      },
      false: {
        color: '$textLightest',
      },
    },
  },
});

import { createSvgIcon } from '@mui/material';

const GravyTrained = createSvgIcon(
  <svg
    fill="none"
    height="30"
    viewBox="0 0 29 30"
    width="29"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4783 1.35097C14.7337 1.19434 15.0554 1.19434 15.3108 1.35097L18.922 3.56542C19.0133 3.6214 19.1149 3.65839 19.2208 3.67418L23.4106 4.29905C23.7069 4.34325 23.9534 4.55004 24.0484 4.8342L25.3913 8.85181C25.4252 8.95336 25.4793 9.04702 25.5503 9.1272L28.3582 12.299C28.5568 12.5233 28.6126 12.8402 28.5027 13.1189L26.949 17.0598C26.9097 17.1594 26.891 17.2659 26.8938 17.3729L27.006 21.6076C27.0139 21.9071 26.853 22.1857 26.5897 22.3286L22.8663 24.3487C22.7722 24.3998 22.6893 24.4693 22.6227 24.5531L19.9867 27.8692C19.8002 28.1037 19.4979 28.2137 19.2043 28.1539L15.0535 27.3081C14.9486 27.2867 14.8405 27.2867 14.7355 27.3081L10.5847 28.1539C10.2911 28.2137 9.98883 28.1037 9.80239 27.8692L7.16635 24.5531C7.09972 24.4693 7.01687 24.3998 6.92276 24.3487L3.19938 22.3286C2.93603 22.1857 2.77517 21.9071 2.78311 21.6076L2.89528 17.3729C2.89811 17.2659 2.87933 17.1594 2.84006 17.0598L1.28632 13.1189C1.17643 12.8402 1.2323 12.5233 1.43089 12.299L4.23879 9.1272C4.30976 9.04702 4.36384 8.95336 4.39778 8.85181L5.7407 4.8342C5.83569 4.55004 6.08213 4.34325 6.37847 4.29905L10.5682 3.67418C10.6741 3.65839 10.7758 3.6214 10.867 3.56542L14.4783 1.35097Z"
      fill="#45A735"
      stroke="white"
      strokeWidth="0.796296"
    />
    <path
      d="M10.6888 13.6038C11.5514 13.6038 12.2507 12.9045 12.2507 12.0419C12.2507 11.1793 11.5514 10.48 10.6888 10.48C9.82623 10.48 9.12695 11.1793 9.12695 12.0419C9.12695 12.9045 9.82623 13.6038 10.6888 13.6038Z"
      fill="white"
    />
    <path
      d="M6.16531 11.6767L9.13378 20.8407L23.9252 9C19.3929 11.4109 14.8619 13.8205 10.3296 16.2314C8.9411 14.7128 7.55258 13.1941 6.16406 11.6767H6.16531Z"
      fill="white"
    />
  </svg>,
  'GravyTrained'
);
export default GravyTrained;

import { useCallback, useEffect, useState } from 'react';

import Autocomplete, { Props as AutocompleteProps } from '../Autocomplete';
import FormElement from '../FormElement';

import { DEBOUNCE_TIME } from '@/constants/general';
import useDebouncedValue from '@/hooks/useDebouncedValue';
import { AddressItemFragment } from '@/types/graphql';

type Props = Pick<
  AutocompleteProps<AddressItemFragment>,
  'selectedItem' | 'onSelectedItemChange' | 'placeholder' | 'disabled'
> & {
  addresses: AddressItemFragment[];
};

const ZipAutocomplete = ({
  addresses,
  selectedItem,
  onSelectedItemChange,
  placeholder,
  disabled,
}: Props) => {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebouncedValue(query, DEBOUNCE_TIME);
  const [items, setItems] = useState(addresses);

  const handleOnInputValueChange = useCallback(
    ({ inputValue }) => setQuery(inputValue || ''),
    []
  );

  useEffect(() => {
    const filteredItems = addresses.filter(
      ({ zip, state }) =>
        !debouncedQuery ||
        zip?.toLowerCase().includes(debouncedQuery) ||
        state?.toLowerCase().includes(debouncedQuery)
    );

    setItems(filteredItems);
  }, [debouncedQuery]);

  return (
    <FormElement htmlFor="zip-code" label="Zip Code">
      <Autocomplete
        disabled={disabled}
        id="autocomplete-zip-code"
        itemToKey={(item) => item.id}
        itemToString={(item) => {
          if (!item) {
            return '';
          }

          return `${item.zip}, ${item.state}`;
        }}
        items={items}
        placeholder={placeholder}
        selectedItem={selectedItem}
        onInputValueChange={handleOnInputValueChange}
        onSelectedItemChange={onSelectedItemChange}
      />
    </FormElement>
  );
};

export default ZipAutocomplete;

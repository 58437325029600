import Card from '@/components/Card';
import TextStack from '@/components/TextStack';
import { Body } from '@/components/Typography';
import { GetWorkerQuery, I9StatusEnum } from '@/types/graphql';
import { parseEnums } from '@/util/text';

type Props = {
  worker: GetWorkerQuery['worker'];
};

const i9Status = (statusEnum) =>
  ({
    [I9StatusEnum.EVERIFY_IN_PROGRESS]: 'E-Verify In Progress',
    [I9StatusEnum.PENDING_EVERIFY]: 'Pending E-Verify',
    [I9StatusEnum.PENDING_SECTION2]: 'Pending Section 2',
    [I9StatusEnum.PENDING]: 'N/A',
  }[statusEnum] || parseEnums(statusEnum));

const I9Form = ({ worker }: Props) => (
  <Card.Section title="I-9 Form">
    <TextStack>
      <Body>{worker.i9Status ? i9Status(worker.i9Status) : 'N/A'}</Body>
    </TextStack>
  </Card.Section>
);

export default I9Form;

import { createSvgIcon } from '@mui/material';

const HomeIcon = createSvgIcon(
  <svg
    fill="none"
    height="20"
    viewBox="0 0 512 512"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M448.83 512H63.169c-15.214 0-27.547-12.334-27.547-27.546V220.386a27.543 27.543 0 0 1 8.071-19.476L236.524 8.079a27.545 27.545 0 0 1 38.98 0l192.83 192.831a27.368 27.368 0 0 1 8.043 19.476v264.068c0 15.212-12.334 27.546-27.546 27.546zM200.906 319.171h110.189v137.733h110.19V231.789L256 66.506 90.717 231.789v225.115h110.189V319.171z"
      fill="currentColor"
    />
  </svg>,
  'HomeIcon'
);
export default HomeIcon;

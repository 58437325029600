import { useFeatureValue } from '@growthbook/growthbook-react';
import { Event, CalendarMonth } from '@mui/icons-material';
import { Menu, MenuItem, MenuList } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { JobTypeEnum } from '@/types/graphql';

type LtaGigMenuProps = {
  anchorEl: any;
  open: boolean;
  handleCloseMenu: () => void;
  marginTop?: number;
};

export const LtaGigMenu = ({
  anchorEl,
  open,
  handleCloseMenu,
  marginTop = 5,
}: LtaGigMenuProps) => {
  const orderFolder = 'creategigorder';
  const navigate = useNavigate();
  return (
    <Menu
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      PaperProps={{
        sx: {
          border: '1px solid #D3D3D3',
          filter:
            'drop-shadow(0px 4.050473213195801px 4.050473213195801px rgba(0, 0, 0, 0.05))',
          boxShadow: 'none',
          marginTop: `${marginTop}px`,
        },
      }}
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      id="basic-menu"
      open={open}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      onClose={handleCloseMenu}
    >
      <MenuList dense={true} sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
        <MenuItem
          id="new_order_gig"
          onClick={() => {
            navigate(`/orders/${orderFolder}?type=${JobTypeEnum.GIG}`);
          }}
        >
          <Event
            fontSize="small"
            htmlColor={'#7A7A7A'}
            style={{ marginRight: '10px' }}
          />
          <p style={{ color: '#262626', fontSize: '16px' }}>Create New Gig</p>
        </MenuItem>
        <MenuItem
          id="new_order_lta"
          onClick={() => {
            navigate(`/orders/${orderFolder}?type=${JobTypeEnum.LTA}`);
          }}
        >
          <CalendarMonth
            fontSize="small"
            htmlColor={'#7A7A7A'}
            style={{ marginRight: '10px' }}
          />
          <p style={{ color: '#262626', fontSize: '16px' }}>Create New LTA</p>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { ChangeEventHandler, PropsWithChildren } from 'react';

import Icon from '@/elements/Icon';
import styled from '@/styles';

const Wrapper = styled('label', {
  display: 'block',
  height: '100%',
  overflow: 'hidden',

  border: '1px dashed $colors$neutralLight',
  borderRadius: '$lg',
  cursor: 'pointer',

  '& > *': {
    zIndex: '-1',
  },

  variants: {
    isDisabled: {
      true: {
        cursor: 'not-allowed',
        filter: 'grayscale(100%)',
      },
      false: {
        '&:hover': {
          borderColor: '$themeDefault',
        },
      },
    },
    isSelected: {
      true: {
        border: '1px solid $colors$themeDefault',
        boxShadow: '$shadows$2, inset 0 0 0 1px $colors$themeDefault',
      },
      false: {},
    },
  },

  defaultVariants: {
    isDisabled: false,
    isSelected: false,
  },
});

const IconPositioner = styled('div', {
  alignItems: 'center',
  boxSize: 12,
  display: 'flex',
  justifyContent: 'center',

  position: 'absolute',
  top: -4,
  right: -4,

  backgroundColor: 'white',
  borderRadius: '$round',
  color: '$themeDefault',

  fontSize: '$lg',
});

export type Props = {
  disabled?: boolean;
  id: string;
  isSelected: boolean;
  name: string;
  required?: boolean;
  type?: 'checkbox' | 'radio';
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

const SelectableBox = ({
  children,
  isSelected,
  type = 'radio',
  ...props
}: PropsWithChildren<Props>) => {
  return (
    <div>
      <input
        {...props}
        checked={isSelected}
        style={{ display: 'none' }}
        type={type}
      />
      <Wrapper
        htmlFor={props.id}
        isDisabled={props.disabled || false}
        isSelected={isSelected}
      >
        {children}
      </Wrapper>
      {isSelected && (
        <IconPositioner>
          <Icon icon={faCheckCircle} />
        </IconPositioner>
      )}
    </div>
  );
};

SelectableBox.Wrapper = Wrapper;
Wrapper.displayName = 'stitches(SelectableBox.Wrapper)';

export default SelectableBox;

import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { useModal } from 'react-modal-hook';

import DashboardFiltersModal from './DashboardFilterModal';

import Button from '@/components/Button';
import IconicButton from '@/components/IconicButton';
import Stack from '@/components/Stack';
import TextInput from '@/components/TextInput';
import ButtonGroup from '@/elements/ButtonGroup';
import useAuth from '@/hooks/useAuth';
import useMediaQuery from '@/hooks/useMediaQuery';
import { JobFilterSetInput, Scalars } from '@/types/graphql';

type Props = {
  filters: JobFilterSetInput;
  query: string;
  onQueryChange: (query: string) => void;
  onChangeFilter: <K extends keyof JobFilterSetInput>(
    key: K,
    value: NonNullable<JobFilterSetInput[K]>
  ) => void;
  onClearFilter: (key: keyof JobFilterSetInput) => Scalars['Void'];
  onClearAll: () => Scalars['Void'];
};

const DashboardFilters = ({
  filters,
  query,
  onQueryChange,
  onChangeFilter,
  onClearAll,
  onClearFilter,
}: Props) => {
  const { currentAdminIsCustomerAdmin } = useAuth();
  const [showFiltersModal, hideFiltersModal] = useModal(
    () => (
      <DashboardFiltersModal
        filters={filters}
        hideModal={hideFiltersModal}
        onChangeFilter={onChangeFilter}
        onClearAll={onClearAll}
        onClearFilter={onClearFilter}
      />
    ),
    [filters]
  );

  const phoneOnly = useMediaQuery('(max-width: 559px)');

  return (
    <Stack vertical gap={16}>
      <Stack wrap gap={phoneOnly ? 8 : 16} justify={'end'}>
        {!currentAdminIsCustomerAdmin && (
          <div style={{ flex: 1 }}>
            <TextInput
              id="txt_search_client"
              minWidth="sm"
              placeholder="Search by Client Name"
              value={query}
              onChange={(ev) => onQueryChange(ev.currentTarget.value)}
            />
          </div>
        )}
        <div>
          {!phoneOnly ? (
            <ButtonGroup>
              <Button
                a11yLabel="View all dashboard filters"
                appearance="outline"
                id="btn_more_filters"
                label="More filters"
                onClick={() => showFiltersModal()}
              />
            </ButtonGroup>
          ) : (
            <IconicButton
              a11yLabel={'View all dashboard filters'}
              appearance={phoneOnly ? 'outline' : 'clear'}
              icon={faFilter}
              id="icn_btn_more_filters"
              size={phoneOnly ? 'md' : 'sm'}
              onClick={() => showFiltersModal()}
            />
          )}
        </div>
      </Stack>
    </Stack>
  );
};

export default DashboardFilters;

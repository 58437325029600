import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { GraphQLError } from 'graphql';
import { useCallback, useState } from 'react';

import { InvalidStateErrorText } from '../CreateAddressModal';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import EditAddress from '@/components/EditAddress';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { INCORRECT_STATE_ERROR_MSG } from '@/constants/general';
import Form from '@/form';
import { GetCustomerQueryResult, useUpdateAddressMutation } from '@/graphql';
import useGetValidStates from '@/hooks/useGetValidStates';
import { AddressItemFragment } from '@/types/graphql';

export type Props = {
  address: AddressItemFragment;
  hideModal: () => void;
  refetchGetCustomer: GetCustomerQueryResult['refetch'];
};

type FormValues = {
  addressLine1: AddressItemFragment['addressLine1'];
  addressLine2: AddressItemFragment['addressLine2'];
  city: AddressItemFragment['city'];
  neighborhood: AddressItemFragment['city'];
  coords: AddressItemFragment['coords'];
  name: AddressItemFragment['name'];
  state: AddressItemFragment['state'];
  zip: AddressItemFragment['zip'];
};

const UpdateAddressModal = ({
  address,
  hideModal,
  refetchGetCustomer,
}: Props) => {
  const [initialValues, setInitialValues] = useState<FormValues>({
    addressLine1: address.addressLine1,
    addressLine2: address.addressLine2,
    city: address.city,
    neighborhood: address.city,
    coords: {
      latitude: address.coords.latitude,
      longitude: address.coords.longitude,
    },
    state: address.state,
    zip: address.zip,
    name: address.name,
  });

  const [updateAddress, { loading: isLoading }] = useUpdateAddressMutation();

  const validStates = useGetValidStates();

  const [error, setError] = useState<GraphQLError | null>(null);
  const [invalidStateError, showInvalidStateError] = useState<boolean>(false);

  const handleSubmit = useCallback(async () => {
    try {
      await updateAddress({
        variables: { addressId: address.id, ...initialValues },
      });
      refetchGetCustomer();

      hideModal();
    } catch (error) {
      setError(error);
    }
  }, [address.id, initialValues]);

  return (
    <Modal
      disableClickout
      size="sm"
      title="Change Address"
      onRequestClose={hideModal}
    >
      <Form
        data-testid="update-address-form"
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <Card.Section>
          {error && (
            <Alert
              description={error.message}
              icon={faExclamationTriangle}
              status="danger"
              title={error.name}
            />
          )}
          <EditAddress
            setValues={(address) => {
              showInvalidStateError(!validStates.includes(address?.state));
              setInitialValues(address);
            }}
            values={initialValues}
          />
          {invalidStateError && (
            <InvalidStateErrorText>
              {INCORRECT_STATE_ERROR_MSG}
            </InvalidStateErrorText>
          )}
        </Card.Section>
        <Card.Section>
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              disabled={
                invalidStateError ||
                (address.addressLine1 === initialValues.addressLine1 &&
                  address.addressLine2 === initialValues.addressLine2)
              }
              isLoading={isLoading}
              label="Save"
              type="submit"
            />
          </Stack>
        </Card.Section>
      </Form>
    </Modal>
  );
};

export default UpdateAddressModal;

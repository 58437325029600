import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import '@/styles';

const rootElement = document.querySelector('#root');
if (!rootElement) throw new Error('No root element found!');

const root = createRoot(rootElement);

root.render(
  <Router>
    <App />
  </Router>,
);

import { useReactiveVar } from '@apollo/client';
import { FieldContext } from '@area2k/use-form';
import { useCallback, useState } from 'react';

import Button from '@/components/Button';
import Card from '@/components/Card';
import FormElement from '@/components/FormElement';
import MaskedInput from '@/components/MaskedInput';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { Small } from '@/components/Typography';
import { TIME_TO_REFRESH } from '@/constants/general';
import { MIN_PAY, MAX_PAY, COST_FACTOR } from '@/constants/rates';
import Input from '@/elements/Input';
import Form from '@/form';
import FormColumns from '@/form/FormColumns';
import ItemSelectField from '@/form/ItemSelectField';
import { useListAgencySkillsQuery, useUpdateRateMutation } from '@/graphql';
import { Maybe } from '@/types';
import { GetCustomerQuery, ListAgencySkillsQuery, Rate } from '@/types/graphql';
import { currentAgencyVar } from '@/util/apollo/cache';
import { centsToCurrency, getPreciseNumber } from '@/util/number';

type Props = {
  customer: GetCustomerQuery['customer'];
  rate: Maybe<Rate>;
  hideModal: () => void;
};

type SkillItem =
  ListAgencySkillsQuery['agency']['skillCategories'][0]['skills'][0];
type AddressItem = GetCustomerQuery['customer']['addresses'][0];
type AccountItem = GetCustomerQuery['customer']['accounts'][0];

type FormValues = {
  skill: Maybe<SkillItem>;
  address: Maybe<AddressItem>;
  account: Maybe<AccountItem>;
  payRate: number;
  billingRate: number;
};

const UpdateRateModal = ({ rate, customer, hideModal }: Props) => {
  const [payRatePristine, setPayRatePristine] = useState(true);
  const currentAgency = useReactiveVar(currentAgencyVar);

  const { data: agencyData } = useListAgencySkillsQuery({
    variables: { agencyId: currentAgency!.id },
    pollInterval: TIME_TO_REFRESH,
  });

  const [updateRate, { loading }] = useUpdateRateMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(customer),
        fields: { addresses() {} },
      });
    },
  });

  const skillCategories = agencyData ? agencyData.agency.skillCategories : [];
  const skillItems: SkillItem[] = skillCategories.flatMap(
    (category) => category.skills
  );

  const [formValues, setFormValues] = useState<FormValues>({
    skill: rate?.skill,
    account: rate?.account,
    address: rate?.address,
    payRate: rate?.pay,
    billingRate: rate?.cost,
  });

  const [payRateInput, setPayRateInput] = useState(
    centsToCurrency(formValues.payRate)
  );
  const [billRateInput, setBillRateInput] = useState(
    centsToCurrency(formValues.billingRate)
  );

  const handleFormValuesChange = <T extends any>(
    fieldContext: FieldContext<T>,
    fieldId: keyof FormValues
  ) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldId]: fieldContext.value,
    }));
  };

  const handlePayRateChange = useCallback((payRateInput: string) => {
    setPayRateInput(payRateInput);
    const payRate = payRateInput
      ? getPreciseNumber(parseFloat(payRateInput.replace(/,/g, '')) * 100)
      : 0;
    setBillRateInput(centsToCurrency(Number(payRate) * COST_FACTOR));
    setFormValues((prevValues) => ({ ...prevValues, payRate }));
  }, []);

  const handleBillRateChange = useCallback((billRateInput: string) => {
    setBillRateInput(billRateInput);
    const billingRate = billRateInput
      ? parseFloat(billRateInput.replace(/,/g, '')) * 100
      : 0;

    setFormValues((prevValues) => ({ ...prevValues, billingRate }));
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      await updateRate({
        variables: {
          rateId: rate!.id,
          baseCost: formValues.billingRate,
          basePay: formValues.payRate,
        },
      });

      hideModal();
    } catch (err) {
      console.error(err);
    }
  }, [formValues]);

  return (
    <Modal
      disableClickout
      size="md"
      title={'Edit custom rate'}
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Form initialValues={formValues} onSubmit={handleSubmit}>
          <ItemSelectField<SkillItem>
            disabled
            required
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext, 'skill');
            }}
            fieldId="skill"
            itemToKey={(item) => item.id}
            itemToString={(item) => (item ? `${item.name}` : '')}
            items={skillItems}
            label="Job Skill*"
            placeholder="Select a skill..."
          />

          <ItemSelectField<AccountItem>
            disabled
            required
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext, 'account');
            }}
            fieldId="account"
            itemToKey={(item) => item.id}
            itemToString={(item) => (item ? `${item.name}` : '')}
            items={customer.accounts}
            label="Account*"
            placeholder="Select an account..."
          />

          <ItemSelectField<AddressItem>
            disabled
            required
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext, 'address');
            }}
            fieldId="address"
            itemToKey={(item) => item.id}
            itemToString={(item) => (item ? `${item.addressLine1}` : '')}
            items={customer.addresses.filter((x) => x.active)}
            label="Address*"
            placeholder="Select an address..."
          />

          <FormColumns layout="triple">
            <FormElement label="City">
              <Input
                disabled
                value={
                  formValues.address
                    ? formValues.address.city!
                    : 'Select an address first...'
                }
              />
            </FormElement>

            <FormElement label="Zip Code">
              <Input
                disabled
                value={
                  formValues.address
                    ? formValues.address.zip!
                    : 'Select an address first...'
                }
              />
            </FormElement>

            <FormElement label="State">
              <Input
                disabled
                value={
                  formValues.address
                    ? formValues.address.state!
                    : 'Select an address first...'
                }
              />
            </FormElement>
          </FormColumns>

          <FormColumns layout="double">
            <FormElement label="Pay rate*">
              <MaskedInput
                normalizeZeros
                padFractionalZeros
                mask={Number}
                radix="."
                scale={2}
                signed={false}
                thousandsSeparator=","
                value={payRateInput}
                onAccept={(value) => handlePayRateChange(value)}
                onBlur={() => setPayRatePristine(false)}
              />
              {!payRatePristine && formValues.payRate < MIN_PAY && (
                <Small color="danger">
                  Invalid pay rate amount. The minimum possible amount is $
                  {MIN_PAY / 100}
                </Small>
              )}
              {formValues.payRate >= MAX_PAY && (
                <Small color="danger">
                  Invalid pay rate amount. The maximum possible amount is $
                  {MAX_PAY / 100 - 0.01}
                </Small>
              )}
              {formValues.billingRate < formValues.payRate && (
                <>
                  <br />
                  <Small color="danger">
                    Pay rate cannot be greater than Bill rate.
                  </Small>
                </>
              )}
            </FormElement>
            <FormElement label="Bill rate">
              <MaskedInput
                normalizeZeros
                padFractionalZeros
                mask={Number}
                radix="."
                scale={2}
                signed={false}
                thousandsSeparator=","
                value={billRateInput}
                onAccept={(value) => handleBillRateChange(value)}
              />
            </FormElement>
          </FormColumns>

          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              disabled={
                !formValues.skill ||
                !formValues.account ||
                !formValues.address ||
                formValues.payRate < MIN_PAY ||
                formValues.payRate >= MAX_PAY ||
                formValues.billingRate < formValues.payRate
              }
              isLoading={loading}
              label="Save"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  );
};

export default UpdateRateModal;

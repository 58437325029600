import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import { CommonProps } from './types';

import Button, { ButtonVariants } from '@/components/Button';
import DropdownMenu from '@/components/DropdownMenu';
import { PopoverVariants } from '@/components/Popover';
import {
  isActionList,
  isAnchorAction,
  isCallbackAction,
  isLinkAction,
} from '@/util/actions';
import { pauseEvent } from '@/util/events';

export type Props = CommonProps &
  ButtonVariants & {
    listOptions?: PopoverVariants;
    stopEvent?: boolean;
  };

const ActionButton = ({
  action,
  listOptions = {},
  stopEvent = true,
  status,
  ...rest
}: Props) => {
  const commonProps = {
    ...rest,
    a11yLabel: action.a11yLabel,
    iconLeft: action.icon,
    id: action.id,
    label: action.label,
    status: action.dangerous ? 'danger' : status,
  };

  if (isAnchorAction(action)) {
    return (
      <Button
        appearance="plain"
        {...commonProps}
        as="a"
        disabled={action.disabled}
        download={action.download}
        href={action.href}
        iconRight={
          action.external && action.label ? faExternalLinkAlt : undefined
        }
        rel={action.external ? 'noopener noreferrer' : undefined}
        target={action.external ? '_blank' : undefined}
      />
    );
  } else if (isCallbackAction(action)) {
    return (
      <Button
        {...commonProps}
        disabled={action.disabled}
        isLoading={action.isLoading}
        loadingIcon={action.loadingIcon}
        loadingLabel={action.loadingLabel}
        onClick={stopEvent ? pauseEvent(action.onAction) : action.onAction}
      />
    );
  } else if (isLinkAction(action)) {
    return (
      <Button
        appearance="plain"
        {...commonProps}
        as={Link}
        disabled={action.disabled}
        to={action.to}
      />
    );
  } else if (isActionList(action)) {
    return (
      <DropdownMenu size="sm" {...listOptions} actions={action.actions}>
        <Button {...commonProps} disabled={action.disabled} />
      </DropdownMenu>
    );
  } else {
    return null;
  }
};

export default ActionButton;

import { SubmitHelpers } from '@area2k/use-form';
import { useCallback } from 'react';

import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import Form from '@/form';
import TextAreaField from '@/form/TextAreaField';
import { useAddCommentMutation } from '@/graphql';
import { CommentableEnum, GetWorkerQuery } from '@/types/graphql';
import { handleMutationFormError } from '@/util/error';

export type Props = {
  worker: GetWorkerQuery['worker'];
  hideModal: () => void;
};

type FormValues = {
  body: string;
};

const AddCommentsModal = ({ worker, hideModal }: Props) => {
  const initialValues: FormValues = {
    body: '',
  };

  const [addComment, { loading: isLoading }] = useAddCommentMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(worker),
        fields: {
          comments() {},
        },
      });
    },
  });

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        await addComment({
          variables: {
            commentableId: worker.id,
            commentableType: CommentableEnum.WORKER,
            ...values,
          },
        });
        hideModal();
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
        });
      }
    },
    []
  );

  return (
    <Modal
      disableClickout
      size="xs"
      title="Add a comment"
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <TextAreaField autoFocus required fieldId="body" label="" />
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              id="btn-submit"
              isLoading={isLoading}
              label="Save"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  );
};

export default AddCommentsModal;

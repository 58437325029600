import { useReactiveVar } from '@apollo/client';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Chip, List, ListItemText } from '@mui/material';
import { useState } from 'react';
import { string } from 'yup';

import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import TextInput from '@/components/TextInput';
import { Subheading } from '@/components/Typography';
import Config from '@/config';
import Icon from '@/elements/Icon';
import Text from '@/elements/Text';
import {
  useGetJobSubscribersQuery,
  useJobAddSubscriberMutation,
  useJobRemoveSubscriberMutation,
} from '@/graphql';
import { currentAgencyVar, currentTenantVar } from '@/util/apollo/cache';

const EmailValidation = string()
  .required('Please enter a valid email')
  .email('Please enter a valid email');

type Props = {
  hideModal: () => void;
  jobId: string;
  orderId: string;
  shiftId: string;
};

const SubscribeEmailModal = ({ hideModal, jobId, orderId, shiftId }: Props) => {
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const currentAgency = useReactiveVar(currentAgencyVar);
  const currentTenant = useReactiveVar(currentTenantVar);

  const { data, refetch } = useGetJobSubscribersQuery({
    variables: {
      jobId: Number(jobId),
    },
    fetchPolicy: 'network-only',
  });

  const [addJobSubscriber] = useJobAddSubscriberMutation();
  const [removeJobSubscriber] = useJobRemoveSubscriberMutation();

  const subscribeEmail = async (subEmail = email) => {
    try {
      const validEmail = EmailValidation.validateSync(subEmail);
      if (
        data?.jobSubscriber.find((sub) => sub.subscriberEmail === validEmail)
      ) {
        throw new Error('Email Already Added');
      }
      await addJobSubscriber({
        variables: {
          agencyId: currentAgency!.id,
          jobId,
          orderId,
          shiftId,
          subscriberEmail: validEmail,
        },
      });
      refetch();
      setError('');
      setEmail('');
    } catch (e: any) {
      setError(e?.message || '');
    }
  };

  const handleRemove = async (subId: number) => {
    try {
      await removeJobSubscriber({
        variables: {
          agencyId: currentAgency!.id,
          jobSubscriberId: subId,
          tenantId: currentTenant!.id,
        },
      });
      refetch({ jobId: Number(jobId) });
    } catch (e: any) {
      console.error(e?.message || '');
    }
  };

  const defaultEmail = Config.SHIFT_START_NOTIFICATION_EMAILS?.split(',') || [];
  return (
    <Modal
      disableClickout
      size="xs"
      title="Subscribe to Notifications"
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Stack vertical gap={20}>
          <div style={{ width: '100%' }}>
            <TextInput
              id="email"
              placeholder="Add your email address"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  subscribeEmail(e.currentTarget.value);
                }
              }}
            />
            {error && (
              <div style={{ marginTop: '8px' }}>
                <Text color={'danger'}>{error}</Text>
              </div>
            )}
          </div>
          <div>
            <Subheading color={'light'} weight={'semibold'}>
              Subscribers
            </Subheading>
            <Stack wrap style={{ marginTop: '10px' }}>
              {data?.jobSubscriber?.map((jobsub) => (
                <Chip
                  key={jobsub.id}
                  deleteIcon={<Icon fixedWidth icon={faTimes} size="xs" />}
                  label={jobsub.subscriberEmail}
                  variant="outlined"
                  onDelete={() => {
                    handleRemove(Number(jobsub.id));
                  }}
                />
              ))}
            </Stack>
            <List dense={true}>
              {defaultEmail.map((email) => (
                <ListItemText key={email}>
                  <Text color={'lighter'}>{email}</Text>
                </ListItemText>
              ))}
            </List>
          </div>
          <Stack justify="end">
            <Button
              a11yLabel="save email"
              appearance="primary"
              id="save-email-btn"
              label="Add Email"
              onClick={() => subscribeEmail()}
            />
          </Stack>
        </Stack>
      </Card.Section>
    </Modal>
  );
};

export default SubscribeEmailModal;

import { useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react';

import Button from '../Button';
import Option from '../Option';
import Stack from '../Stack';
import { Small } from '../Typography';

import Ach, { AchConfigProps } from './Ach';
import CreditCards from './CreditCards';

import { OptionGroupOption } from '@/form/SingleOptionGroupField';
import { usePaymentMethod } from '@/hooks/usePaymentMethod';
import { AccountPaymentMethodsType } from '@/routes/Agency/Account/PaymentSection';
import AddCardForm from '@/routes/Agency/Account/PaymentSection/AddCardForm';
import DettachPaymentMethod from '@/routes/Agency/Account/PaymentSection/DettachPaymentMethod';
import SelectPaymentAccount from '@/routes/Agency/Account/PaymentSection/SelectPaymentAccount';
import {
  onRefetchType,
  PaymentMethodType,
} from '@/routes/Agency/Account/PaymentSection/modals/ChangePaymentModal';
import { Role } from '@/routes/PrivateRoute';
import {
  GetAccountQuery,
  StripeBankAccount,
  StripePaymentMethod,
} from '@/types/graphql';
import { currentAdminVar } from '@/util/apollo/cache';

export enum EditorTypeEnum {
  'ADD_CARD',
  'ADD_ACCOUNT',
  'REMOVE_CARD',
  'REMOVE_ACCOUNT',
}

export type CardPaymentMethod = StripePaymentMethod;
export type BankAccountPaymentMethod = StripeBankAccount;
export type SelectedPaymentMethodType =
  | CardPaymentMethod
  | BankAccountPaymentMethod
  | null;

export type EditorProps = {
  isOpen: boolean;
  type: EditorTypeEnum;
  selectedPaymentMethod: SelectedPaymentMethodType;
};

type ComponentProps = {
  account: GetAccountQuery['account'];
  showSubmitButton?: boolean;
  isLoading: boolean;
  onRefetch: onRefetchType;
  accountPaymentMethods: AccountPaymentMethodsType;
};

const paymentMethodOptions: OptionGroupOption[] = [
  { label: 'Check', value: 'check' },
  { label: 'ACH', value: 'ach' },
  { label: 'Credit Card', value: 'card' },
];

const initialStateEditor: EditorProps = {
  isOpen: false,
  type: EditorTypeEnum.ADD_CARD,
  selectedPaymentMethod: null,
};

const PaymentMethod = ({
  account,
  showSubmitButton = true,
  isLoading,
  onRefetch,
  accountPaymentMethods,
}: ComponentProps) => {
  const currentAdmin = useReactiveVar(currentAdminVar);
  const currentAdminIsCustomerAdmin =
    currentAdmin!.role === Role.CUSTOMER_ADMIN;
  const { currentPaymentMethod, cards, bankAccounts } = accountPaymentMethods;

  const {
    paymentMethod,
    setPaymentMethodType,
    setSelectedPaymentMethodId,
    setPaymentMethodInformation,
  } = usePaymentMethod();

  const [editor, setEditor] = useState<EditorProps>(initialStateEditor);
  const [achConfig, setAchConfig] = useState<AchConfigProps>({
    publicToken: '',
    paymentAccounts: [],
  });

  const setEditorToInitialState = () => setEditor(initialStateEditor);

  useEffect(() => {
    setPaymentMethodInformation(accountPaymentMethods);
  }, []);

  if (editor.isOpen) {
    const DettachPaymentMethodComponent = () => (
      <DettachPaymentMethod
        account={account}
        kind={editor.type}
        paymentMethod={editor.selectedPaymentMethod}
        onCloseEditor={setEditorToInitialState}
        onRefetch={onRefetch}
      />
    );

    return {
      [EditorTypeEnum.ADD_CARD]: (
        <AddCardForm
          account={account}
          onCloseEditor={setEditorToInitialState}
          onRefetch={onRefetch}
        />
      ),
      [EditorTypeEnum.ADD_ACCOUNT]: (
        <SelectPaymentAccount
          account={account}
          achConfig={achConfig}
          onCloseEditor={setEditorToInitialState}
          onRefetch={onRefetch}
        />
      ),
      [EditorTypeEnum.REMOVE_CARD]: <DettachPaymentMethodComponent />,
      [EditorTypeEnum.REMOVE_ACCOUNT]: <DettachPaymentMethodComponent />,
    }[editor.type];
  }

  return (
    <>
      <Stack vertical>
        {paymentMethodOptions.map((option, index) => (
          <Option
            key={index}
            checked={paymentMethod.paymentMethodType === option.value}
            id={`paymentMethod${index}`}
            label={option.label}
            name="paymentMethod"
            type="radio"
            value={option.value}
            onChange={({ currentTarget: { value: paymentMethodType } }) => {
              setPaymentMethodType(paymentMethodType as PaymentMethodType);
            }}
          />
        ))}
        {paymentMethod.paymentMethodType === 'ach' && (
          <>
            {currentPaymentMethod === 'check' && currentAdminIsCustomerAdmin && (
              <Stack css={{ paddingLeft: 32, paddingRight: 32 }}>
                <Small>
                  If you change the payment method, check will be disable. To re
                  enable check as a payment you will have to contact an admin.
                </Small>
              </Stack>
            )}
            <Ach
              bankAccounts={bankAccounts}
              selectedPaymentMethodId={paymentMethod.selectedPaymentMethodId}
              setAchConfig={setAchConfig}
              setEditor={setEditor}
              setSelectedPaymentMethodId={setSelectedPaymentMethodId}
            />
          </>
        )}
        {paymentMethod.paymentMethodType === 'card' && (
          <>
            {currentPaymentMethod === 'check' && currentAdminIsCustomerAdmin && (
              <Stack css={{ paddingLeft: 32, paddingRight: 32 }}>
                <Small>
                  If you change the payment method, check will be disable. To re
                  enable check as a payment you will have to contact an admin.
                </Small>
              </Stack>
            )}
            <CreditCards
              cards={cards}
              selectedPaymentMethodId={paymentMethod.selectedPaymentMethodId}
              setEditor={setEditor}
              setSelectedPaymentMethodId={setSelectedPaymentMethodId}
            />
          </>
        )}
        {paymentMethod.paymentMethodType === 'check' &&
          currentAdminIsCustomerAdmin &&
          currentPaymentMethod !== 'check' && (
            <Stack css={{ paddingLeft: 32 }}>
              <Small>Please contact an Admin to enable this option</Small>
            </Stack>
          )}
      </Stack>

      {showSubmitButton && (
        <Stack justify="end">
          <Button
            a11yLabel="Submit form"
            disabled={
              ((paymentMethod.paymentMethodType === 'ach' ||
                paymentMethod.paymentMethodType === 'card' ||
                (paymentMethod.paymentMethodType === 'check' &&
                  currentAdminIsCustomerAdmin)) &&
                paymentMethod.selectedPaymentMethodId === null) ||
              paymentMethod.selectedPaymentMethodId === 'ach' ||
              paymentMethod.selectedPaymentMethodId === 'card' ||
              (paymentMethod.selectedPaymentMethodId === 'check' &&
                currentAdminIsCustomerAdmin)
            }
            isLoading={isLoading}
            label="Save"
            type="submit"
          />
        </Stack>
      )}
    </>
  );
};

export default PaymentMethod;

import {
  BankAccountPaymentMethod,
  CardPaymentMethod,
  SelectedPaymentMethodType,
} from '@/components/PaymentMethod';
import { IS_PROD } from '@/constants/env';
import { PaymentMethodType } from '@/hooks/usePaymentMethod';
import { AccountPaymentMethodsType } from '@/routes/Agency/Account/PaymentSection';
import { Maybe } from '@/types';
import { Scalars } from '@/types/graphql';

export const checkIfCardIsCurrentPaymentMethod = (
  cards: AccountPaymentMethodsType['cards'],
  currentPaymentMethod: AccountPaymentMethodsType['currentPaymentMethod']
) => {
  return cards.some((card) => card.id === currentPaymentMethod);
};

export const getCurrentCreditCard = (
  cards: AccountPaymentMethodsType['cards'],
  cardId: string
) => {
  return cards.find((card) => card.id === cardId);
};

export const checkIfBankAccountIsCurrentPaymentMethod = (
  bankAccounts: AccountPaymentMethodsType['bankAccounts'],
  currentPaymentMethod: AccountPaymentMethodsType['currentPaymentMethod']
) => {
  return bankAccounts.some(
    (bankAccount) => bankAccount.id === currentPaymentMethod
  );
};

export const getCurrentBankAccount = (
  bankAccounts: AccountPaymentMethodsType['bankAccounts'],
  bankAccountId: string
) => {
  return bankAccounts.find((bankAccount) => bankAccount.id === bankAccountId);
};

export const formatCurrentPaymentMethod = ({
  currentPaymentMethod: currentPaymentMethodId,
  cards,
  bankAccounts,
}: AccountPaymentMethodsType) => {
  if (!currentPaymentMethodId) return null;

  if (currentPaymentMethodId === 'check') {
    return 'Check';
  }

  if (checkIfCardIsCurrentPaymentMethod(cards, currentPaymentMethodId)) {
    const creditCard = getCurrentCreditCard(cards, currentPaymentMethodId)!;

    return `${creditCard.card.brand} **** ${creditCard.card.last4}`;
  }

  if (
    checkIfBankAccountIsCurrentPaymentMethod(
      bankAccounts,
      currentPaymentMethodId
    )
  ) {
    const bankAccount = getCurrentBankAccount(
      bankAccounts,
      currentPaymentMethodId
    )!;

    return `${bankAccount.bankName} **** ${bankAccount.last4} `;
  }
};

export const checkCurrentPaymentMethods = (
  cards: AccountPaymentMethodsType['cards'],
  bankAccounts: AccountPaymentMethodsType['bankAccounts'],
  currentPaymentMethod: AccountPaymentMethodsType['currentPaymentMethod']
) => {
  if (currentPaymentMethod === 'check') {
    return 'check';
  }
  if (checkIfCardIsCurrentPaymentMethod(cards, currentPaymentMethod)) {
    return 'card';
  }
  if (
    checkIfBankAccountIsCurrentPaymentMethod(bankAccounts, currentPaymentMethod)
  ) {
    return 'ach';
  }
  return 'none';
};

export const checkIfHasAnAssociatedpaymentMethod = (
  paymentMethods: AccountPaymentMethodsType,
  selectedType: PaymentMethodType
) => {
  if (selectedType === 'card') {
    return paymentMethods.cards.length > 0;
  }
  if (selectedType === 'ach') {
    return paymentMethods.bankAccounts.length > 0;
  }

  return false;
};

// Type guards
export const checkIfPaymentMethodIsCardType = (
  paymentMethod: SelectedPaymentMethodType
): paymentMethod is CardPaymentMethod => {
  return Boolean((paymentMethod as CardPaymentMethod).card);
};

export const checkIfPaymentMethodIsBankAccountType = (
  paymentMethod: SelectedPaymentMethodType
): paymentMethod is BankAccountPaymentMethod => {
  return Boolean((paymentMethod as BankAccountPaymentMethod).bankName);
};

export const checkIfCurrentPaymentMethodIdIsBankAccount = (
  currentPaymentMethodId: Maybe<Scalars['String']>
) => {
  const BANK_ACCOUNT_PREFIX = 'ba';

  return currentPaymentMethodId?.startsWith(BANK_ACCOUNT_PREFIX);
};

/**
 * TODO: [IMPORTANT] util for QA purposes
 * @returns returns true if currentPaymentMethodId is invalid
 */
export const checkIfCurrentPaymentMethodIdIsTestAccountToFail = (
  currentPaymentMethodId: Maybe<Scalars['String']>
) => {
  if (IS_PROD) {
    return false;
  }

  const TEST_INVALIDS_BANK_ACCOUNTS = [
    'pm_usBankAccount_accountClosed',
    'pm_usBankAccount_noAccount',
    'pm_usBankAccount_insufficientFunds',
    'pm_usBankAccount_debitNotAuthorized',
    'pm_usBankAccount_invalidCurrency',
  ];

  return TEST_INVALIDS_BANK_ACCOUNTS.includes(currentPaymentMethodId ?? '');
};

export const CalculateCost = (pay: string, markup: number) => {
  let payValue = parseFloat(pay) * 100;
  payValue = add(payValue, (payValue * markup) / 100);
  const preciseNumber = Number(payValue.toFixed(10));
  return Math.round(preciseNumber);
};
const add = (a, b) => {
  return Math.round((a + b) * 1000) / 1000;
};

import styled from '@/styles';

const TableCell = styled('td', {
  padding: '16px',

  width: 'auto',
  color: '$textDefault',
  fontSize: '$md',
  lineHeight: 1.5,
  '&:first-child': {
    paddingLeft: '20px',
  },

  '&:last-child': {
    paddingRight: '20px',
  },

  variants: {
    align: {
      center: {
        textAlign: 'center',
      },
      left: {
        textAlign: 'left',
      },
      right: {
        textAlign: 'right',
      },
    },
    size: {
      md: {
        padding: '20px 12px 20px',
        '&:first-child': {
          paddingLeft: '43px',
        },
      },
    },

    width: {
      xs: {
        width: '20%',
      },
      sm: {
        width: '35%',
      },
      md: {
        width: '50%',
      },
      lg: {
        width: '65%',
      },
    },
    stack: {
      true: {
        '@phoneOnly': {
          whiteSpace: 'nowrap',
          padding: '0',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '8px',
          lineHeight: 'normal',
          fontSize: '14px',
          '&:first-child': {
            paddingLeft: '0',
          },
          '&:last-child': {
            paddingRight: '0',
          },
          '&:before': {
            content: 'attr(data-celltitle)',
            color: '$textLighter',
          },
        },
      },
    },
    noCellTitle: {
      true: {
        '@phoneOnly': {
          '&:before': {
            display: 'none',
          },
        },
      },
      false: {},
    },
    checkboxCell: {
      true: {
        '@phoneOnly': {
          '&:before': {
            display: 'none',
          },
          position: 'absolute',
          marginLeft: '-30px',
          paddingTop: '5px',
        },
      },
    },
  },

  defaultVariants: {
    noCellTitle: false,
  },
});

TableCell.displayName = 'stitches(Table.TableCell)';

export default TableCell;

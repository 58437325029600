import { createSvgIcon } from '@mui/material';

const OrderIcon = createSvgIcon(
  <svg
    fill="none"
    height="20"
    viewBox="0 0 512 512"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M435.199 51.2H328.192C317.44 21.505 289.281 0 256 0s-61.44 21.504-72.192 51.2H76.801a51.2 51.2 0 0 0-51.199 51.2v358.399a51.202 51.202 0 0 0 51.199 51.202h358.398a51.187 51.187 0 0 0 36.204-14.998 51.2 51.2 0 0 0 14.998-36.204V102.4a51.19 51.19 0 0 0-14.998-36.203 51.191 51.191 0 0 0-36.204-14.996zM256 51.2a25.603 25.603 0 0 1 25.6 25.6A25.6 25.6 0 1 1 256 51.2zM128 153.6h256v-51.2h51.198v358.399H76.8V102.4H128v51.2zM140.8 320l38.4-38.4 51.2 51.198 115.2-115.199 38.4 38.4-153.601 153.599-89.6-89.597z"
      fill="currentColor"
    />
  </svg>,
  'OrderIcon'
);
export default OrderIcon;

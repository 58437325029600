import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import React from 'react';

import Stack from '@/components/Stack';
import { Body, Subheading } from '@/components/Typography';
import Icon from '@/elements/Icon';
import Text from '@/elements/Text';
import styled from '@/styles';

const Wrapper = styled('div', {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  padding: '32px 0',
});

export type Props = {
  error?: boolean;
  icon?: IconDefinition;
  customIcon?: React.ReactNode;
  text?: string;
  title?: string;
};

const EmptyState = ({
  error = false,
  icon = faQuestionCircle,
  customIcon,
  text,
  title,
}: Props) => {
  return (
    <Wrapper>
      <Stack vertical align="center" gap={8} justify="center">
        <Text color={error ? 'danger' : 'default'} size="xxxl">
          {customIcon || (
            <Icon
              fixedWidth
              data-testid={error ? 'error-icon' : 'default-icon'}
              icon={icon}
            />
          )}
        </Text>
        <div style={{ textAlign: 'center', padding: '0px 10px' }}>
          {title && (
            <Subheading color={error ? 'danger' : 'default'}>
              {title}
            </Subheading>
          )}
          {text && <Body color="light">{text}</Body>}
        </div>
      </Stack>
    </Wrapper>
  );
};

EmptyState.Wrapper = Wrapper;
Wrapper.displayName = 'stitches(EmptyState.Wrapper)';

export default EmptyState;

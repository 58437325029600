import { v4 as uuidv4 } from 'uuid';

import { PayrollItem } from '../../PayrollDetail';
import PayrollRowItem from '../PayrollRowItem';

import Card from '@/components/Card';
import Table from '@/components/Table';
import TableHeader from '@/components/TableHeader';

const isEarnings = (item: unknown[]): item is PayrollItem['earnings'] =>
  (item as PayrollItem['earnings'])[0].earningType !== undefined;

const earningsHeaderFields = [
  'Order',
  'Customer',
  'Type',
  'Hours',
  'Amount',
  'Approved By',
  'Details',
];

type Props = {
  earnings: unknown[];
};

const ItemEarningsCard = ({ earnings }: Props) => {
  if (isEarnings(earnings)) {
    return (
      <Card title="Earnings">
        <Table>
          <TableHeader fields={earningsHeaderFields} />
          <tbody>
            {earnings.map((earning, index) => (
              <PayrollRowItem
                key={`earning-row-item-${index}`}
                earning={earning}
                id={uuidv4()}
              />
            ))}
          </tbody>
        </Table>
      </Card>
    );
  }

  return null;
};

export default ItemEarningsCard;

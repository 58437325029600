import Card from '@/components/Card';
import TextStack from '@/components/TextStack';
import { Body } from '@/components/Typography';
import { GetWorkerQuery, LanguageEnum } from '@/types/graphql';

type Props = {
  worker: GetWorkerQuery['worker'];
};

const languagues = {
  NONE: 'Not provided',
  [LanguageEnum.SPANISH]: 'Spanish',
  [LanguageEnum.ENGLISH]: 'English',
};

const LanguageSection = ({ worker }: Props) => (
  <Card.Section title="Language">
    <TextStack>
      <Body>
        <strong>Primary: </strong>
        {languagues[worker.user.language]}
      </Body>
      <Body>
        <strong>Secondary: </strong>
        {languagues[worker.user.secondaryLanguage || 'NONE']}
      </Body>
    </TextStack>
  </Card.Section>
);

export default LanguageSection;

import { createSvgIcon } from '@mui/material';

const AnnouncementIcon = createSvgIcon(
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.5101 17.9233L15.8525 21.016C10.1146 23.0658 7.14692 29.4406 9.22386 35.2546C11.3008 41.0687 17.6359 44.1202 23.3737 42.0705L32.0314 38.9777C36.1096 37.5209 41.8896 37.9539 46.4978 38.705C49.1862 39.1431 50.5303 39.3622 51.233 39.0083C51.8846 38.6801 52.2894 38.1857 52.4868 37.4768C52.6997 36.7124 52.2634 35.4909 51.3907 33.0479L43.2448 10.2449C42.372 7.80188 41.9357 6.58038 41.2866 6.12386C40.6847 5.70053 40.0583 5.57449 39.3463 5.73344C38.5784 5.90485 37.6773 6.92602 35.875 8.96834C32.7857 12.4692 28.5883 16.4664 24.5101 17.9233Z" fill="#E7F3E6"/>
    <path d="M24.5101 17.9233L15.8525 21.016C10.1146 23.0658 7.14692 29.4406 9.22386 35.2546C11.3008 41.0687 17.6359 44.1202 23.3737 42.0705L32.0314 38.9777M24.5101 17.9233C28.5883 16.4664 32.7857 12.4692 35.875 8.96834C37.6773 6.92602 38.5784 5.90485 39.3463 5.73344C40.0583 5.57449 40.6847 5.70053 41.2866 6.12386C41.9357 6.58038 42.372 7.80188 43.2448 10.2449L51.3907 33.0479C52.2634 35.4909 52.6997 36.7124 52.4868 37.4768C52.2894 38.1857 51.8846 38.6801 51.233 39.0083C50.5303 39.3622 49.1862 39.1431 46.4978 38.705C41.8896 37.9539 36.1096 37.5209 32.0314 38.9777M24.5101 17.9233L32.0314 38.9777" stroke="#45A735" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M55.0977 9.43555C59.2582 11.062 66.3551 16.8999 61.458 27.2403" stroke="#45A735" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M53.7969 13.6357C56.2679 14.6351 60.4965 18.1874 57.6427 24.4014" stroke="#45A735" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M51.3711 17.3032C52.942 17.7555 55.5554 19.548 53.4419 23.1001" stroke="#45A735" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M28.1589 56.4782L19.5 43.0005L19.9272 42.9019C23.967 41.9697 27.9112 40.6634 31.7089 39.0001L36.893 51.981C37.9037 54.5118 36.6714 57.3829 34.1406 58.3936C31.9453 59.2703 29.4367 58.467 28.1589 56.4782Z" fill="white" stroke="#45A735" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>,
  'AnnouncementIcon'
);
export default AnnouncementIcon;

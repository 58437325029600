import { useReactiveVar } from '@apollo/client';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { ItemCreated } from '..';
import CustomerAutocompleteField, {
  CustomerItem,
} from '../../CreateGig/Billing/CustomerAutocompleteField';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import Tabs, { TabDefinition } from '@/components/Tabs';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { GAEvent } from '@/constants/gaevents';
import Form from '@/form';
import FormColumns from '@/form/FormColumns';
import MaskedInputField from '@/form/MaskedInputField';
import TextField from '@/form/TextField';
import TextSelectField from '@/form/TextSelectField';
import {
  useCreateTenantAdminMutation,
  useInviteAdminMutation,
} from '@/graphql';
import { Maybe } from '@/types';
import { UserRoleEnum } from '@/types/graphql';
import useAnalytics from '@/util/analytics';
import { currentAgencyVar, currentTenantVar } from '@/util/apollo/cache';

type Props = {
  hideModal: () => void;
  setCreatedItem: Dispatch<SetStateAction<ItemCreated>>;
};

type FormValues = {
  customer?: Maybe<CustomerItem>;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  password: string;
  roleId: UserRoleEnum;
};

const RELATIONSHIP_TABS: TabDefinition[] = [
  { a11yLabel: 'Create Gravy Admin', label: 'Gravy Admin' },
  {
    a11yLabel: 'Create Client Admins',
    label: 'Client Admin',
    htmlId: 'client-admin-tab',
  },
];

const roleOptions = [
  { label: 'Tenant Admin', value: UserRoleEnum.TENANT_ADMIN },
  { label: 'Help Desk Admin', value: UserRoleEnum.HELPDESK_ADMIN },
];

const CreateAdminModal = ({ setCreatedItem, hideModal }: Props) => {
  const [error, setError] = useState({
    active: false,
    data: {},
  });
  const [tab, setTab] = useState(0);
  const { logEvent } = useAnalytics();
  const currentTenant = useReactiveVar(currentTenantVar);
  const currentAgency = useReactiveVar(currentAgencyVar);

  const [initialValues, setInitialValues] = useState<FormValues>({
    customer: null,
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    password: '',
    roleId: UserRoleEnum.TENANT_ADMIN,
  });

  const helpdeskRole = useFeatureValue(FEATURE_TOGGLE.HelpdeskRole, false);

  const [createCustomerAdmin, { loading: isLoadingCustomerAdmin }] =
    useInviteAdminMutation({
      update: (cache) => {
        cache.modify({
          id: cache.identify(currentAgency!),
          fields: { customerAdmins() {} },
        });
      },
    });

  const [createTenantAdmin, { loading: isLoadingTenantAdmin }] =
    useCreateTenantAdminMutation({
      update: (cache) => {
        cache.modify({
          id: cache.identify(currentTenant!),
          fields: { admins() {} },
        });
      },
    });

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      try {
        if (tab === 0) {
          const resultTenant = await createTenantAdmin({
            variables: {
              ...values,
              tenantId: currentTenant!.id,
            },
          });

          if (resultTenant.data) {
            logEvent(
              values.roleId === UserRoleEnum.TENANT_ADMIN
                ? GAEvent.CreateTenantAdmin
                : GAEvent.CreateHelpdeskAdmin,
              resultTenant!.data.tenantAdminInvite.tenantAdmin.id
            );
            setCreatedItem({
              created: true,
              data: resultTenant!.data.tenantAdminInvite.tenantAdmin.user,
            });
            hideModal();
          }
          return;
        }

        const resultCustomer = await createCustomerAdmin({
          variables: {
            ...values,
            customerId: values.customer!.id,
          },
        });

        if (resultCustomer.data) {
          logEvent(
            GAEvent.CreateClientAdmin,
            resultCustomer!.data.customerInviteAdmin.customerAdmin.id
          );
          setCreatedItem({
            created: true,
            data: resultCustomer.data.customerInviteAdmin.customerAdmin.user,
          });
          hideModal();
        }
      } catch (err) {
        setError({ active: true, data: err });
      }
    },
    [tab]
  );

  return (
    <Modal
      disableClickout
      size="xs"
      title="New Admin"
      onRequestClose={hideModal}
    >
      <Tabs fit selected={tab} tabs={RELATIONSHIP_TABS} onSelect={setTab} />
      <Card.Section>
        {error.active && (
          <Alert
            description={error.data.message}
            icon={faExclamationTriangle}
            status="warning"
            title="Something went wrong"
          />
        )}
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          {tab === 0 ? null : (
            <CustomerAutocompleteField
              callback={(fieldContext) => {
                if (fieldContext.value) {
                  setInitialValues((prevValues) => {
                    return {
                      ...prevValues,
                      customer: fieldContext.value!,
                    };
                  });
                }
              }}
              fieldId="customer"
            />
          )}
          <FormColumns>
            <TextField
              autoFocus
              required
              fieldId="firstName"
              label="First Name"
              placeholder="First Name"
            />
            <TextField
              required
              fieldId="lastName"
              label="Last Name"
              placeholder="Last Name"
            />
          </FormColumns>
          <TextField
            required
            autoComplete="email"
            fieldId="email"
            label="Email Address"
            placeholder="Email Address"
            type="email"
          />
          <MaskedInputField
            fieldId="phoneNumber"
            incompletemessage="Must be a valid phone number"
            label="Phone Number"
            mask="(000) 000-0000"
            placeholder="(555) 555-5555"
            type="tel"
          />
          {tab === 0 && helpdeskRole && (
            <TextSelectField
              callback={(fieldContext) => {
                if (fieldContext.value) {
                  setInitialValues((prevValues) => {
                    return {
                      ...prevValues,
                      roleId: fieldContext.value!,
                    };
                  });
                }
              }}
              disabled={false}
              fieldId="roleId"
              label="Role"
              options={roleOptions}
            />
          )}
          <TextField
            required
            autoComplete="password"
            css={{ letterSpacing: '2px' }}
            fieldId="password"
            label="Password"
            placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
            type="password"
          />
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              id="send-invite-btn"
              isLoading={
                tab === 0 ? isLoadingTenantAdmin : isLoadingCustomerAdmin
              }
              label="Send Invite"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  );
};

export default CreateAdminModal;

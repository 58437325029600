import { createSvgIcon } from '@mui/material';

const AddRatingIcon = createSvgIcon(
  <svg
    fill="none"
    height="16"
    viewBox="0 0 17 16"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6016 13.2252C14.6016 13.5608 14.2988 13.8245 13.9135 13.8245H12.2896V15.2388C12.2896 15.5744 11.9869 15.8381 11.6016 15.8381C11.2162 15.8381 10.9135 15.5744 10.9135 15.2388V13.8245H9.28964C8.90431 13.8245 8.60156 13.5608 8.60156 13.2252C8.60156 12.8896 8.90431 12.6259 9.28964 12.6259H10.9135V11.2116C10.9135 10.876 11.2162 10.6123 11.6016 10.6123C11.9869 10.6123 12.2896 10.876 12.2896 11.2116V12.6259H13.9135C14.2988 12.6499 14.6016 12.9136 14.6016 13.2252Z"
      fill="currentColor"
    />
    <path
      d="M4.11248 15.1611C3.9163 15.1611 3.72012 15.0968 3.56754 14.9895C3.24058 14.7534 3.10979 14.3457 3.21878 13.9594L4.67921 9.56027L0.886444 6.83495C0.559481 6.5989 0.428696 6.19118 0.537683 5.80491C0.668468 5.41864 1.01723 5.18259 1.43138 5.18259H6.13965L7.60008 0.78345C7.73087 0.397184 8.07963 0.161133 8.49378 0.161133C8.90794 0.161133 9.2567 0.418644 9.38748 0.78345L10.8479 5.18259H15.5562C15.9703 5.18259 16.3191 5.4401 16.4499 5.80491C16.5807 6.19118 16.4499 6.5989 16.1229 6.83495L12.1558 9.62465C11.916 9.79632 11.5672 9.75341 11.3929 9.4959C11.2185 9.25984 11.2621 8.9165 11.5236 8.74482L15.033 6.23409H10.6953C10.2812 6.23409 9.93242 5.97658 9.80163 5.61178L8.47199 1.55598L7.14234 5.61178C7.01155 5.99804 6.66279 6.23409 6.24864 6.23409H1.91093L5.42033 8.74482C5.74729 8.98088 5.87808 9.3886 5.76909 9.77487L4.43944 13.8307L7.94884 11.3199C8.18862 11.1483 8.53738 11.1912 8.71176 11.4487C8.88614 11.6847 8.84254 12.0281 8.58097 12.1998L4.67921 14.9895C4.50483 15.0968 4.30866 15.1611 4.11248 15.1611Z"
      fill="currentColor"
    />
  </svg>,
  'AddRating'
);
export default AddRatingIcon;

import { useReactiveVar } from '@apollo/client';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { useMemo } from 'react';

import BottomNavbar from './BottomNavbar';

import {
  ChatIcon,
  ClientIcon,
  CompanyIcon,
  HomeIcon,
  OrderIcon,
  PlusIcon,
} from '@/assets/icons';
import Stack from '@/components/Stack';
import { useAppSelector } from '@/hooks/store';
import { Role } from '@/routes/PrivateRoute';
import ROUTES from '@/routes/routes';
import { CurrentCustomerAdminFragment } from '@/types/graphql';
import { hasValidCustomerAccountStatus } from '@/util/accountstatus';
import { currentAdminVar } from '@/util/apollo/cache';
import ls, { ACCOUNT_DISABLED } from '@/util/localstorage';

const BottomNav = () => {
  const currentAdmin = useReactiveVar(currentAdminVar);

  const isFeatFlagDisabledCustomerLoginAllowedEnabled = useFeatureValue(
    'disabled-customer-login',
    false,
  );
  const currentAdminUser = useReactiveVar(
    currentAdminVar,
  ) as CurrentCustomerAdminFragment;
  const accountDisabled = ls.get(ACCOUNT_DISABLED) === 'true';

  const unreadChats = useAppSelector((state) => state.pubnub.unreadChats);
  const pendingCustomers = useAppSelector(
    (state) => state.layout.pendingCustomers,
  );

  function hasValidAccountStatus(): boolean {
    return hasValidCustomerAccountStatus(
      isFeatFlagDisabledCustomerLoginAllowedEnabled,
      currentAdminUser,
      accountDisabled,
    );
  }

  const tenantAdminNavigation = useMemo(
    () => [
      {
        items: [
          {
            a11yLabel: 'Dashboard',
            to: ROUTES.basePath,
            end: true,
            id: 'dashboard-link',
            CustomIcon: () => <HomeIcon fontSize="small" htmlColor="inherit" />,
          },
          {
            a11yLabel: 'Chat',
            to: ROUTES.inappchat,
            end: true,
            id: 'chat-link',
            CustomIcon: () => <ChatIcon fontSize="small" htmlColor="inherit" />,
            badge: unreadChats,
          },
          {
            to: 'orders/create',
            id: 'orders-create',
            end: true,
            disabled: !hasValidAccountStatus(),
            CustomIcon: () => (
              <PlusIcon fontSize="medium" htmlColor="inherit" />
            ),
            isCustomerAllowedLTA: true,
          },
          {
            a11yLabel: 'Clients',
            to: ROUTES.clients,
            id: 'clients-link',
            CustomIcon: () => (
              <ClientIcon fontSize="small" htmlColor="inherit" />
            ),
            badge: pendingCustomers,
          },
          {
            a11yLabel: 'Orders',
            to: ROUTES.orders,
            id: 'orders-link',
            end: true,
            CustomIcon: () => (
              <OrderIcon fontSize="small" htmlColor="inherit" />
            ),
          },
        ],
      },
    ],
    [currentAdmin, pendingCustomers, unreadChats],
  );

  const customerAdminNavigation = useMemo(
    () => [
      {
        items: [
          {
            a11yLabel: 'Dashboard',
            to: ROUTES.basePath,
            end: true,
            id: 'dashboard-link',
            CustomIcon: () => <HomeIcon fontSize="small" htmlColor="inherit" />,
          },
          {
            a11yLabel: 'Chat',
            to: ROUTES.chat,
            end: true,
            id: 'chat-link',
            CustomIcon: () => <ChatIcon fontSize="small" htmlColor="inherit" />,
          },
          {
            to: 'orders/create',
            id: 'orders-create',
            end: true,
            disabled: !hasValidAccountStatus(),
            CustomIcon: () => (
              <PlusIcon fontSize="medium" htmlColor="inherit" />
            ),
            isCustomerAllowedLTA:
              currentAdmin?.role === Role.CUSTOMER_ADMIN &&
              currentAdminUser.customer?.ltaAllowed,
          },
          {
            a11yLabel: 'Company',
            to: ROUTES.myCompany,
            id: 'mycompany-link',
            CustomIcon: () => (
              <CompanyIcon fontSize="small" htmlColor="inherit" />
            ),
          },
          {
            a11yLabel: 'Orders',
            to: ROUTES.orders,
            id: 'orders-link',
            end: true,
            CustomIcon: () => (
              <OrderIcon fontSize="small" htmlColor="inherit" />
            ),
          },
        ],
      },
    ],
    [currentAdmin],
  );

  return (
    <Stack>
      {currentAdmin && (
        <BottomNavbar
          groups={
            currentAdmin.role === Role.TENANT_ADMIN
              ? tenantAdminNavigation
              : customerAdminNavigation
          }
        />
      )}
    </Stack>
  );
};

export default BottomNav;

import { AddressItemFragment, Maybe, Scalars } from '@/types/graphql';

const getFormattedAddress = ({
  addressLine1,
  city,
  state,
}: {
  addressLine1: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
}) => `${addressLine1}, ${city}, ${state}`;

const getUniqueAddressesByZipAndState = (addresses: AddressItemFragment[]) => {
  const unique: AddressItemFragment[] = [];

  for (const address of addresses) {
    const duplicate = unique.find(
      (obj) => obj.zip === address.zip && obj.state === address.state
    );

    if (!duplicate) {
      unique.push(address);
    }
  }

  return unique;
};

export { getFormattedAddress, getUniqueAddressesByZipAndState };

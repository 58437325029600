import * as yup from 'yup';

export const thirdStepSchema = yup
  .object({
    firstName: yup.string().required('First name is a required field'),
    lastName: yup.string().required('Last name is a required field'),
    phoneNumber: yup
      .string()
      // Note: this validation takes into account the "()" and the space " " between
      .min(14, 'Phone number must be at least 10 numbers')
      .required('Phone number is a required field'),
    howDidYourHearAboutUs: yup
      .string()
      .required('Referral is a required field'),
  })
  .required();

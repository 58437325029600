import { useEffect } from 'react';

import Card from '@/components/Card';
import TextStack from '@/components/TextStack';
import { Body } from '@/components/Typography';
import { useWorkerSignatureRequestsLazyQuery } from '@/graphql';
import { GetWorkerQuery, SignatureRequestStatusEnum } from '@/types/graphql';
import { parseEnums } from '@/util/text';

type Props = {
  worker: GetWorkerQuery['worker'];
};

const EmployeeDocuments = ({ worker }: Props) => {
  const { id: workerId, onboardingDocsStatus } = worker;
  const [fetchWorkerSignatureRequests, { data, loading }] =
    useWorkerSignatureRequestsLazyQuery();

  const handleDownload = () => {
    if (data) {
      downloadFile();
    } else {
      fetchWorkerSignatureRequests({ variables: { workerId } });
    }
  };

  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = data!.workerSignatureRequestsTenant[0].documentUrl as string;
    link.click();
  };

  useEffect(() => {
    if (data) {
      downloadFile();
    }
  }, [data]);

  return (
    <Card.Section
      actions={
        onboardingDocsStatus === SignatureRequestStatusEnum.COMPLETED
          ? [
              {
                a11yLabel: "Download employee's documents",
                label: loading ? 'Downloading..' : 'Download',
                disabled: loading,
                onAction: handleDownload,
                id: 'btn-download',
              },
            ]
          : undefined
      }
      title="Documents"
    >
      <TextStack>
        <Body>
          {!onboardingDocsStatus ? 'N/A' : parseEnums(onboardingDocsStatus)}
        </Body>
      </TextStack>
    </Card.Section>
  );
};

export default EmployeeDocuments;

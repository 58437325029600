import { CloseOutlined } from '@mui/icons-material';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import * as React from 'react';

interface Props {
  hideModal: () => void;
  children: React.ReactNode;
}

const UserInfoModal = ({ hideModal, children }: Props) => {
  return (
    <Dialog
      PaperProps={{
        style: {
          height: '50vh',
          borderRadius: '15px',
        },
      }}
      aria-labelledby="options-dialog"
      fullScreen={true}
      open={true}
      sx={{
        '& .MuiDialog-container': { alignItems: 'end', marginBottom: '2px' },
      }}
      onClose={hideModal}
    >
      <IconButton
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
        onClick={hideModal}
      >
        <CloseOutlined />
      </IconButton>
      <DialogContent style={{ marginTop: '10vh' }}>
        <div
          onClick={() =>
            setTimeout(() => {
              hideModal();
            }, 250)
          }
        >
          {children}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UserInfoModal;

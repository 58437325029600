import useModal from '@area2k/use-modal';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useFeatureValue } from '@growthbook/growthbook-react';

import ChangeMarkupPaymentModal from './ChangeMarkupPaymentModal';
import ChangePaymentModal from './ChangePaymentModal';

import Alert from '@/components/Alert';
import Card from '@/components/Card';
import Stack from '@/components/Stack';
import StackContainer from '@/components/StackContainer';
import { Body, Small, Subheading } from '@/components/Typography';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import useAuth from '@/hooks/useAuth';
import useMediaQuery from '@/hooks/useMediaQuery';
import { getRoundOff } from '@/routes/Agency/Job/util';
import {
  AccountRateTypeEnum,
  GetJobQuery,
  JobStatusEnum,
} from '@/types/graphql';
import { DEFAULT_MARKUP_PRICE_VALUE } from '@/util/constants';
import { costOfJob } from '@/util/job';
import { centsToCurrency } from '@/util/number';

type Props = {
  job: GetJobQuery['job'];
};

const PaymentCard = ({ job }: Props) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const { totalEstimate, totalHours, requiredHours } = costOfJob(job);
  const { currentAdminIsCustomerAdmin } = useAuth();

  const showMarkupBasedPricing = useFeatureValue(
    FEATURE_TOGGLE.MarkupBasedPricingFeature,
    false
  );

  const removeHolidayRates = useFeatureValue(
    FEATURE_TOGGLE.RemoveHolidayRates,
    false
  );

  const isEditable = () => {
    if (showMarkupBasedPricing) {
      if (job.account?.rateType === AccountRateTypeEnum.MARKUP) {
        return job.status === JobStatusEnum.UPCOMING;
      } else if (
        !currentAdminIsCustomerAdmin &&
        job.status === JobStatusEnum.UPCOMING
      ) {
        return true;
      }
    } else {
      if (job.account?.rateType === AccountRateTypeEnum.MARKUP) {
        return false;
      } else if (
        !currentAdminIsCustomerAdmin &&
        job.status === JobStatusEnum.UPCOMING
      ) {
        return true;
      }
    }
  };

  const cardStyle = {
    width: phoneOnly ? '100%' : '337px',
    height: phoneOnly ? 'auto' : '295px',
    paddingBottom: '16px',
  };
  const [showMarkupPaymentEditModal, hideMarkupPaymentEditModal] = useModal(
    () => (
      <ChangeMarkupPaymentModal
        hideModal={hideMarkupPaymentEditModal}
        isHoliday={removeHolidayRates ? false : job.isHoliday}
        job={job}
      />
    ),
    [job]
  );

  const [showPaymentEditModal, hidePaymentEditModal] = useModal(
    () => <ChangePaymentModal hideModal={hidePaymentEditModal} job={job} />,
    [job]
  );

  return (
    <Card changeStyle={cardStyle} noRadius={phoneOnly}>
      <Card.Section
        actions={
          isEditable()
            ? [
                {
                  a11yLabel: 'Edit payment',
                  label: 'Edit',
                  onAction: () =>
                    job.account?.rateType === AccountRateTypeEnum.MARKUP
                      ? showMarkupPaymentEditModal()
                      : showPaymentEditModal(),
                },
              ]
            : undefined
        }
        styleHeading={true}
        title="Payment"
      >
        <StackContainer css={{ paddingRight: '10px' }}>
          <Stack vertical>
            {(job.account?.rateType === AccountRateTypeEnum.MARKUP ||
              !currentAdminIsCustomerAdmin) && (
              <Stack justify="apart">
                <Small>Pay rate</Small>
                <Body>${centsToCurrency(job.payRate)}</Body>
              </Stack>
            )}
            {job.account?.rateType === AccountRateTypeEnum.MARKUP &&
              !currentAdminIsCustomerAdmin && (
                <>
                  <Stack justify="apart">
                    <Small>Markup</Small>
                    <Body>{`${job.markupPercent}%`}</Body>
                  </Stack>
                  {job.markupPercent! < DEFAULT_MARKUP_PRICE_VALUE && (
                    <Alert
                      css={{
                        margin: '0px',
                        padding: '5px',
                        gap: '6px',
                        width: '100%',
                      }}
                      description={`Rate is below than the intended rate`}
                      icon={faExclamationTriangle}
                      status={'warning2'}
                    />
                  )}
                </>
              )}
            {(job.account?.rateType === AccountRateTypeEnum.STATIC ||
              !currentAdminIsCustomerAdmin) && (
              <Stack justify="apart">
                <Small>Bill rate</Small>
                <Body>${centsToCurrency(job.costRate)}</Body>
              </Stack>
            )}
            <Stack justify="apart">
              <Small>Unpaid break time</Small>
              <Body>{`${job.mandatoryBreakTime} min`}</Body>
            </Stack>
            <Stack justify="apart">
              <Small>Shift Length (hours)</Small>
              <Body>{getRoundOff(requiredHours)}</Body>
            </Stack>
            <Stack justify="apart">
              <Small>Total Hours</Small>
              <Body>{getRoundOff(totalHours)}</Body>
            </Stack>
            {!currentAdminIsCustomerAdmin && (
              <Stack justify="apart">
                <Body>Total estimate</Body>
                <Subheading>${centsToCurrency(totalEstimate)}</Subheading>
              </Stack>
            )}
          </Stack>
        </StackContainer>
      </Card.Section>
    </Card>
  );
};

export default PaymentCard;

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { CSS, VariantProps } from '@stitches/react';

import Stack from '@/components/Stack';
import { Body, Display } from '@/components/Typography';
import Icon from '@/elements/Icon';
import styled from '@/styles';

const Wrapper = styled('div', {
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',

  variants: {
    overlayColor: {
      light: {
        backgroundColor: '$neutralA12',
      },
      dark: {
        backgroundColor: '$neutralA32',
      },
      transparent: {
        backgroundColor: 'transparent',
      },
      white: {
        backgroundColor: '$whiteA68',
      },
    },
  },

  defaultVariants: {
    overlayColor: 'transparent',
  },
});

export type Props = VariantProps<typeof Wrapper> & {
  icon?: IconDefinition;
  text?: string;
  size?: any;
  css?: CSS; // Allows you to pass additional styles via the `css` prop
};

const LoadingState = ({
  icon = faCircleNotch,
  text,
  size,
  ...variants
}: Props) => {
  return (
    <Wrapper {...variants}>
      <Stack vertical align="center" gap={16} id="loader" justify="center">
        <Display>
          <Icon size={size} fixedWidth spin icon={icon} />
        </Display>
        {text && <Body>{text}</Body>}
      </Stack>
    </Wrapper>
  );
};

LoadingState.Wrapper = Wrapper;
Wrapper.displayName = 'stitches(LoadingState.Wrapper)';

export default LoadingState;

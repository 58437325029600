import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { Maybe } from '@/types';

type Props = {
  body: Maybe<string>;
  item: string;
  hideModal: () => void;
};

const SuccessfulCreateAlert = ({ body, item, hideModal }: Props) => {
  return (
    <Modal size="xs" title="Successful creation" onRequestClose={hideModal}>
      <Card.Section>
        <Alert
          description={body || ' '}
          icon={faCheckCircle}
          status="success"
          title={`${item} was successfully created`}
          onClick={hideModal}
        />
        <Stack justify="end">
          <Button
            a11yLabel="Close the alert"
            appearance="primary"
            label="Ok"
            type="button"
            onClick={hideModal}
          />
        </Stack>
      </Card.Section>
    </Modal>
  );
};

export default SuccessfulCreateAlert;

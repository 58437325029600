import { ReactNode, useMemo, useReducer } from 'react';

import { SignUpActionType } from '../../actions/actions';
import { SignUpState } from '../../interfaces/signUp.interface';
import { signUpReducer } from '../../state/SignUpReducer';
import {
  SignUpActionContext,
  SignUpActions,
} from '../actions/SignUpActionContext';

import { SignUpStateContext } from './SignUpStateContext';

export const INITIAL_STATE: SignUpState = {
  companyName: '',
  email: '',
  password: '',
  address: {
    addressLine1: '',
    city: '',
    state: '',
    zip: '',
    coords: {
      latitude: 0,
      longitude: 0,
    },
  },
  firstName: '',
  lastName: '',
  phoneNumber: '',
  howDidYourHearAboutUs: '',
};

interface Props {
  children: ReactNode;
}

const SignUpProvider = ({ children }: Props) => {
  const [signUpState, dispatch] = useReducer(signUpReducer, INITIAL_STATE);

  const actions = useMemo<SignUpActions>(
    () => ({
      resetState: () => dispatch({ type: SignUpActionType.RESET_STATE }),
      updateFirstStep: (payload) =>
        dispatch({ type: SignUpActionType.UPDATE_FIRST_STEP, payload }),
      updateSecondStep: (payload) =>
        dispatch({ type: SignUpActionType.UPDATE_SECOND_STEP, payload }),
      updateThirdStep: (payload) =>
        dispatch({ type: SignUpActionType.UPDATE_THIRD_STEP, payload }),
    }),
    []
  );

  return (
    <SignUpActionContext.Provider value={actions}>
      <SignUpStateContext.Provider value={signUpState}>
        {children}
      </SignUpStateContext.Provider>
    </SignUpActionContext.Provider>
  );
};

export default SignUpProvider;

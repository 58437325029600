import {
  FirstStepState,
  SecondStepState,
  ThirdStepUpdate,
} from '../interfaces/signUp.interface';

export enum SignUpActionType {
  RESET_STATE = 'RESET_STATE',
  UPDATE_FIRST_STEP = 'UPDATE_FIRST_STEP',
  UPDATE_SECOND_STEP = 'UPDATE_SECOND_STEP',
  UPDATE_THIRD_STEP = 'UPDATE_THIRD_STEP',
}

type ResetStateAction = { type: SignUpActionType.RESET_STATE };

type UpdateFirstStep = {
  type: SignUpActionType.UPDATE_FIRST_STEP;
  payload: FirstStepState;
};

type UpdateSecondStep = {
  type: SignUpActionType.UPDATE_SECOND_STEP;
  payload: SecondStepState;
};

type UpdateThirdStep = {
  type: SignUpActionType.UPDATE_THIRD_STEP;
  payload: ThirdStepUpdate;
};

export type SignUpAction =
  | ResetStateAction
  | UpdateFirstStep
  | UpdateSecondStep
  | UpdateThirdStep;

import {
  CurrentCustomerAdminFragment,
  CurrentTenantAdminFragment,
} from '@/types/graphql';

export enum CustomerStatusEnum {
  PENDING = 0,
  APPROVED = 1,
  REJECTED = 2,
  DISABLED = 3,
  DELETED = 4,
}

export const getAccountStatus = (
  isAccountDisabled: boolean,
  currentAdminUser:
    | CurrentTenantAdminFragment
    | CurrentCustomerAdminFragment
    | null
) => {
  if (isAccountDisabled) {
    return CustomerStatusEnum.DISABLED;
  }
  const customerAdmin = currentAdminUser as CurrentCustomerAdminFragment;
  return customerAdmin?.customer?.status >= 0
    ? customerAdmin?.customer?.status
    : CustomerStatusEnum.DISABLED;
};

export const isAccountStatusApproved = (currentAccountStatus: number) => {
  return currentAccountStatus === CustomerStatusEnum.APPROVED;
};

export const getAccountErrorTitle = (
  isAccountDisabled: boolean,
  currentAdminUser:
    | CurrentTenantAdminFragment
    | CurrentCustomerAdminFragment
    | null
) => {
  switch (getAccountStatus(isAccountDisabled, currentAdminUser)) {
    case CustomerStatusEnum.PENDING:
      return 'Welcome to GravyWork - Your Account is Pending Review';
    case CustomerStatusEnum.REJECTED:
      return 'Account Rejected';
    case CustomerStatusEnum.DISABLED:
      return 'Account Disabled';
  }
};

export const getAccountErrorDesc = (
  isAccountDisabled: boolean,
  currentAdminUser:
    | CurrentTenantAdminFragment
    | CurrentCustomerAdminFragment
    | null
) => {
  switch (getAccountStatus(isAccountDisabled, currentAdminUser)) {
    case CustomerStatusEnum.PENDING:
      return 'Thank you for joining GravyWork. We\'re excited to have you on board. Your account is currently under review by our team. A Gravy Team member will message shortly with next steps.\nPlease continue completing your profile on the <a href="/my-company">My Company</a> page.\nIf you have any questions - please contact <a href="mailto:sales@gravywork.com">sales@gravywork.com</a>';
    case CustomerStatusEnum.REJECTED:
      return 'Your account request has been rejected.\nIf you have any questions - please contact <a href="mailto:sales@gravywork.com">sales@gravywork.com</a>';
    case CustomerStatusEnum.DISABLED:
      return "Your account has been disabled.\nIf you have any questions - please contact <a href='mailto:sales@gravywork.com'>sales@gravywork.com</a>";
  }
};

export const hasValidCustomerAccountStatus = (
  isFeatFlagDisabledCustomerLoginAllowedEnabled: boolean,
  admin: CurrentTenantAdminFragment | CurrentCustomerAdminFragment | null,
  accountDisabled: boolean
) => {
  if (isFeatFlagDisabledCustomerLoginAllowedEnabled) {
    const customerAdmin = admin as CurrentCustomerAdminFragment;
    if (
      admin === undefined ||
      admin === null ||
      customerAdmin.customer === undefined ||
      customerAdmin.customer === null
    ) {
      return true; // let it run as is for tenant and other users
    }

    if (accountDisabled) {
      return false;
    }
    const isApproved = isAccountStatusApproved(
      getAccountStatus(accountDisabled, customerAdmin)
    );
    if (!isApproved) {
      return false;
    }
  }
  return true;
};

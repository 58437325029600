import { createSvgIcon } from '@mui/material';

const InfoItalicIcon = createSvgIcon(
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.9987 14.6667C11.6654 14.6667 14.6654 11.6667 14.6654 8.00004C14.6654 4.33337 11.6654 1.33337 7.9987 1.33337C4.33203 1.33337 1.33203 4.33337 1.33203 8.00004C1.33203 11.6667 4.33203 14.6667 7.9987 14.6667ZM8.1987 4.86004C8.33203 4.74004 8.49203 4.66671 8.66536 4.66671C8.84536 4.66671 8.9987 4.74004 9.1387 4.86004C9.26536 5.00004 9.33203 5.16004 9.33203 5.33337C9.33203 5.51337 9.26536 5.66671 9.1387 5.80671C8.9987 5.93337 8.84536 6.00004 8.66536 6.00004C8.49203 6.00004 8.33203 5.93337 8.1987 5.80671C8.07203 5.66671 7.9987 5.51337 7.9987 5.33337C7.9987 5.16004 8.07203 5.00004 8.1987 4.86004ZM6.53203 7.98004C6.53203 7.98004 7.9787 6.83337 8.50536 6.78671C8.9987 6.74671 8.8987 7.31337 8.85203 7.60671L8.84536 7.64671C8.75203 8.00004 8.6387 8.42671 8.52536 8.83337C8.27203 9.76004 8.02536 10.6667 8.08536 10.8334C8.15203 11.06 8.56536 10.7734 8.86536 10.5734C8.90536 10.5467 8.9387 10.52 8.97203 10.5C8.97203 10.5 9.02536 10.4467 9.0787 10.52C9.09203 10.54 9.10536 10.56 9.1187 10.5734C9.1787 10.6667 9.21203 10.7 9.13203 10.7534L9.10536 10.7667C8.9587 10.8667 8.33203 11.3067 8.0787 11.4667C7.80536 11.6467 6.7587 12.2467 6.9187 11.08C7.0587 10.26 7.24536 9.55337 7.39203 9.00004C7.66536 8.00004 7.78536 7.54671 7.17203 7.94004C6.92536 8.08671 6.7787 8.18004 6.69203 8.24004C6.6187 8.29337 6.61203 8.29337 6.56536 8.20671L6.54536 8.16671L6.51203 8.11337C6.46536 8.04671 6.46536 8.04004 6.53203 7.98004Z"
      fill="CurrentColor"
    />
  </svg>,
  'InfoItalicIcon'
);
export default InfoItalicIcon;

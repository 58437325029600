import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { Body } from '@/components/Typography';
import { Maybe } from '@/types';
import { CustomerStatusEnum } from '@/util/accountstatus';

type Props = {
  hideModal: () => void;
  status: Maybe<CustomerStatusEnum>;
};

const CustomerProfileStatusModal = ({ hideModal, status }: Props) => {
  return (
    <Modal size="xs" title={`Client not approved`} onRequestClose={hideModal}>
      <Card.Section>
        <Stack vertical gap={24}>
          <Body>
            {`Client status is ${status}. Kindly approve client before creating order`}
          </Body>

          <Stack justify="end">
            <Button
              a11yLabel="Close"
              appearance="outline"
              label="Close"
              onClick={hideModal}
            />
          </Stack>
        </Stack>
      </Card.Section>
    </Modal>
  );
};

export default CustomerProfileStatusModal;

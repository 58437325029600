import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import useModal from '@area2k/use-modal';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  ConversationHeader,
  Sidebar,
  ConversationList,
  Conversation,
  Avatar,
} from '@chatscope/chat-ui-kit-react';
import Pubnub from 'pubnub';
import { usePubNub } from 'pubnub-react';
import { useState, useEffect, useCallback } from 'react';

import AnnouncementDetails from './AnnouncementDetails';
import ChatDetails from './ChatDetails';
import NetworkNotAvailableModal from './NetworkNotAvailableModal';
import SelectReceiver from './SelectReceiver';
import SendBulkMessage from './SendBulkMessage';
import { styles } from './styles';
import {
  checkUnreadAnnouncements,
  getAnnouncementTitle,
  removeHtmlTags,
  showReadDotIcon,
} from './utils';

import Action from '@/components/Action';
import Card from '@/components/Card';
import Tabs, { TabDefinition } from '@/components/Tabs';
import { Heading, Subheading } from '@/components/Typography';
import Config from '@/config';
import {
  PUBNUB_CHAT_LIMIT,
  PUBNUB_INITIAL_CHAT_LIMIT,
} from '@/constants/general';
import { V3Endpoints } from '@/constants/urls';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import useAuth from '@/hooks/useAuth';
import useMediaQuery from '@/hooks/useMediaQuery';
import axiosClient from '@/util/axios/axiosClient';
import {
  AnnouncementGroupInterface,
  ChatGroupGWUserType,
  ChatGroupInterface,
  ContentType,
  MessageType,
  MessageActionType,
} from '@/util/axios/types';
import { dateFormatter, dateToTimeToken } from '@/util/date';
import './index.css';
import {
  setChannels,
  setPubnubMessage,
  setUnreadChats,
} from '@/store/slices/pubnubSlice';
import ls, { ACTIVE_CHAT_ID, SHOW_RED_DOT_ON_CHATS } from '@/util/localstorage';

const InappChat = () => {
  const pubnub = usePubNub();
  const [messages, setMessages] = useState({});
  const { currentAdmin, currentAdminIsCustomerAdmin } = useAuth();
  const [showReceivers, setShowReceivers] = useState(false);
  const [conversationChannels, setConversationChannels] = useState<string[]>(
    [],
  );
  const [activeConversation, setActiveConversation] = useState<string>();
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [chatGroups, setChatGroups] = useState<ChatGroupInterface[]>();
  const [announcementGroup, setAnnouncementGroup] =
    useState<AnnouncementGroupInterface[]>();
  const [showLoader, setShowLoader] = useState(true);
  const [lastReadTimeTokens, setLastReadTimeTokens] = useState();
  const [showBulkMsg, setShowBulkMsg] = useState(false);

  const [chatPageNumber, setChatPageNumber] = useState(1);
  const [allChatsLoaded, setAllChatsLoaded] = useState(false);

  const [announcementPageNumber, setAnnouncementPageNumber] = useState(1);
  const [allAnnouncementsLoaded, setAllAnnouncementsLoaded] = useState(false);

  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const dispatch = useAppDispatch();

  const INAPPCHAT_TABS: TabDefinition[] = [
    { a11yLabel: 'Chats', label: 'Chats' },
    { a11yLabel: 'Announcements', label: 'Announcements' },
  ];
  const [tab, setTab] = useState<number>(0);

  const [showConnectivityError, hideConnectivityError] = useModal(
    () => <NetworkNotAvailableModal />,
    [],
  );

  const connectionStatus = useAppSelector(
    (state) => state.pubnub.connectionStatus,
  );

  useEffect(() => {
    if (connectionStatus) {
      hideConnectivityError();
    } else {
      showConnectivityError();
    }
  }, [connectionStatus]);

  const handleTab = async (tab) => {
    setTab(tab);
    setActiveConversation('');
    if (tab === 0 && !chatGroups) {
      setShowLoader(true);
      await fetchChatGroups();
    } else if (tab === 1 && !announcementGroup) {
      setShowLoader(true);
      await fetchAnnouncements();
    }
  };
  const fetchAnnouncements = async () => {
    try {
      const response = await axiosClient.get(V3Endpoints.GET_CHATGROUP_LIST, {
        params: {
          isBroadcast: true,
          page: announcementPageNumber,
          perPage: Config.MAX_CHAT_LIMIT,
        },
      });
      const { data } = response;
      if (data && data.length) {
        const grps: AnnouncementGroupInterface[] = [];
        data.forEach((announcementGrp) => {
          const announcement: AnnouncementGroupInterface = {
            id: announcementGrp.id,
            createdAt: announcementGrp.created_at,
            name: announcementGrp.name,
            email: '',
            avatar: '',
            lastMessage: '',
            lastMessageTime: '',
            users: [],
            filters: announcementGrp.filters,
          };
          grps.push(announcement);
        });
        if (announcementGroup) {
          if (announcementPageNumber === 1) {
            setAnnouncementGroup([...grps]);
          } else {
            setAnnouncementGroup([...announcementGroup, ...grps]);
          }
        } else {
          setAnnouncementGroup(grps);
        }
        setAnnouncementPageNumber(announcementPageNumber + 1);

        if (data.length < Config.MAX_CHAT_LIMIT) {
          setAllAnnouncementsLoaded(true);
        }

        setTimeout(() => {
          const channels = grps.map(({ id }) => id);
          fetchMessages(channels);
        }, 100);
      } else {
        setAllAnnouncementsLoaded(true);
      }
      setShowLoader(false);
    } catch (e) {
      setShowLoader(false);
      console.error(e);
    }
  };
  const fetchChatGroups = async () => {
    try {
      const response = await axiosClient.get(V3Endpoints.GET_CHATGROUP_LIST, {
        params: { page: chatPageNumber, perPage: Config.MAX_CHAT_LIMIT },
      });
      const { data } = response;
      if (data && data.length) {
        const grps: ChatGroupInterface[] = [];
        data.forEach((chatGrp) => {
          if (!(chatGroups && chatGroups.find((c) => c.id === chatGrp.id))) {
            const chat: ChatGroupInterface = {
              id: chatGrp.id,
              createdAt: chatGrp.created_at,
              name: chatGrp.name,
              email: chatGrp.email,
              avatar: chatGrp.avatar_key || '',
              active: chatGrp.active,
              lastMessage: '',
              lastMessageTime: dateToTimeToken(chatGrp.last_message_sent_at),
              allMessagesLoaded: false,
              users: [],
            };
            grps.push(chat);
          }
        });

        if (chatGroups) {
          setChatGroups([...chatGroups, ...grps]);
        } else {
          setChatGroups(grps);
        }

        setChatPageNumber(chatPageNumber + 1);
        if (data.length < Config.MAX_CHAT_LIMIT) {
          setAllChatsLoaded(true);
        }

        if (!announcementGroup) {
          await fetchAnnouncements();
        }

        setTimeout(() => {
          const channels = grps.map(({ id }) => id);
          setConversationChannels(
            Array.from(
              new Set([
                ...conversationChannels,
                ...channels,
                `${currentAdmin!.user.channelId}`,
              ]),
            ),
          );
          const activeChatId = ls.get(ACTIVE_CHAT_ID);
          if (activeChatId) {
            handleCreateChatGroup(activeChatId, channels);
            ls.remove(ACTIVE_CHAT_ID);
          }
          fetchMessages(channels);
          dispatch(
            setChannels(
              Array.from(new Set([...conversationChannels, ...channels])),
            ),
          );
        }, 100);
      } else {
        if (!chatGroups) {
          setConversationChannels([`${currentAdmin!.user.channelId}`]);
        }
        setAllChatsLoaded(true);
      }
      setShowLoader(false);
    } catch {
      setConversationChannels([`${currentAdmin!.user.channelId}`]);
      setShowLoader(false);
    }
  };

  const getChatGroupById = async (
    chatGroupId: string,
    isBroadcast: boolean,
  ) => {
    try {
      const response = await axiosClient.get(
        `${V3Endpoints.GET_CHATGROUP_BY_ID}${chatGroupId}`,
        {
          params: {
            isBroadcast,
          },
        },
      );
      if (response && response.data) {
        const { data } = response;
        if (!isBroadcast) {
          const chat: ChatGroupInterface = {
            id: data.id,
            createdAt: data.created_at,
            name: data.name,
            email: data.email,
            avatar: data.avatar_key || '',
            active: data.active,
            lastMessage: '',
            lastMessageTime: '',
            allMessagesLoaded: false,
            users: [],
          };
          setChatGroups((chatGroups) => {
            if (chatGroups) {
              const found = !!chatGroups.find((x) => x.id === chat.id);
              return found ? chatGroups : [chat, ...chatGroups];
            } else {
              return [chat];
            }
          });
          setConversationChannels(
            Array.from(new Set([...conversationChannels, chatGroupId])),
          );
          dispatch(
            setChannels(
              Array.from(new Set([...conversationChannels, chatGroupId])),
            ),
          );
        } else {
          const announcement: AnnouncementGroupInterface = {
            id: data.id,
            createdAt: data.created_at,
            name: data.name,
            email: '',
            avatar: '',
            lastMessage: '',
            lastMessageTime: '',
            users: [],
            filters: data.filters,
          };
          if (announcementGroup) {
            setAnnouncementGroup([announcement, ...announcementGroup]);
          } else {
            setAnnouncementGroup([announcement]);
          }
        }
        fetchMessages([chatGroupId]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getPubnubMemberships();
  }, []);

  const getMembership = async (payload) => {
    const lastRead = {};
    const response = await pubnub.objects.getMemberships(payload);
    if (response && response.data && response.data.length) {
      response.data.map((x) => {
        lastRead[`${x.channel.id}`] = x.custom.lastReadTimetoken;
        return true;
      });
      if (response.data.length === PUBNUB_CHAT_LIMIT) {
        const nextPayload = { ...payload, page: { next: response.next } };
        const nextRead = await getMembership(nextPayload);
        return { ...lastRead, ...nextRead };
      } else {
        return { ...lastRead };
      }
    }
  };

  const getPubnubMemberships = async () => {
    const payload = {
      include: {
        customFields: true,
      },
      limit: PUBNUB_CHAT_LIMIT,
    };

    const lastRead = await getMembership(payload);
    setLastReadTimeTokens(lastRead);
  };

  const setLastReadTimeTokenForChannel = (channelId, timeToken) => {
    pubnub.objects.setMemberships(
      {
        channels: [
          channelId,
          {
            id: channelId,
            custom: {
              lastReadTimetoken: timeToken,
            },
          },
        ],
      },
      () => {
        setLastReadTimeTokens({
          ...lastReadTimeTokens,
          [`${channelId}`]: timeToken,
        });
      },
    );
  };

  const handleSystemMessage = async (message) => {
    if (
      message.content?.actionType === MessageActionType.ADDED_TO_NEW_CHAT_GROUP
    ) {
      if (message?.payload?.channelId) {
        await getChatGroupById(message.payload.channelId, false);
      }
    } else if (
      message.content?.actionType ===
      MessageActionType.ADDED_TO_NEW_ANNOUNCEMENT
    ) {
      if (message?.payload?.channelId) {
        await getChatGroupById(message.payload.channelId, true);
      }
    } else if (
      (message.content?.actionType === MessageActionType.WORKER_ENABLED ||
        message.content?.actionType === MessageActionType.WORKER_DISABLED) &&
      message.payload
    ) {
      const { channelIds = [] } = message.payload || {};
      const active =
        message.content?.actionType === MessageActionType.WORKER_ENABLED;

      let allGroups = chatGroups ? [...chatGroups] : [];
      channelIds?.forEach((cid) => {
        allGroups = allGroups?.map((cg) => {
          if (cg.id === cid) {
            const chatGroup = { ...cg };
            chatGroup.active = active;
            return chatGroup;
          } else return cg;
        });
      });
      const allAnnouncements = announcementGroup ? [...announcementGroup] : [];
      setChatGroups(allGroups);
      setAnnouncementGroup(allAnnouncements);
    }
  };

  const pubnubMessage = useAppSelector((state) => state.pubnub.pubnubMessage);
  useEffect(() => {
    if (pubnubMessage && pubnubMessage.message) {
      if (pubnubMessage.message.content?.type === MessageType.SYSTEM_MESSAGE) {
        handleSystemMessage(pubnubMessage.message);
      } else {
        if (
          !chatGroups ||
          !chatGroups.find((x) => x.id === pubnubMessage.channel)
        ) {
          getChatGroupById(pubnubMessage.channel, false);
        } else if (messages[`${pubnubMessage.channel}`]) {
          const msgs = messages[`${pubnubMessage.channel}`];
          if (
            msgs.filter((x) => x.timetoken === pubnubMessage.timetoken)
              .length === 0
          ) {
            msgs.push({ ...pubnubMessage });
            setMessages((messages) => ({
              ...messages,
              [pubnubMessage.channel]: msgs,
            }));
          }
        } else {
          setMessages((messages) => ({
            ...messages,
            [pubnubMessage.channel]: [{ ...pubnubMessage }],
          }));
        }
      }
      dispatch(setPubnubMessage({}));
    }
  }, [pubnubMessage]);

  const handleCreateChatGroup = async (
    chatGrpId: string,
    allChatIds?: string[],
  ) => {
    const chatChannels = allChatIds || conversationChannels;
    if (!chatChannels.find((x) => x === chatGrpId)) {
      await getChatGroupById(chatGrpId, false);
    }
    setActiveConversation(chatGrpId);
    if (tab === 1) {
      setTab(0);
    }
    phoneOnly && setSidebarVisible(false);
    setShowReceivers(false);
  };

  const sendMessage = (message) => {
    if (message && activeConversation) {
      const builder = Pubnub.notificationPayload(
        currentAdmin?.user
          ? currentAdmin?.user.firstName + ' ' + currentAdmin?.user.lastName
          : 'New Message',
        message,
      );
      const messagePayload: any = builder.buildPayload(['fcm']);
      messagePayload.content = {
        message,
        type: ContentType.TEXT,
      };
      messagePayload.sender = {
        ...currentAdmin?.user,
        type: currentAdminIsCustomerAdmin
          ? ChatGroupGWUserType.CLIENT_ADMIN
          : ChatGroupGWUserType.TENANT_ADMIN,
      };
      messagePayload.pn_gcm.data = {
        redirect: 'CHAT_DETAILS',
        channelId: activeConversation,
      };
      messagePayload.pn_gcm.content_available = true;
      pubnub
        .publish({
          channel: activeConversation,
          message: messagePayload,
        })
        .then((response) => {
          setLastReadTimeTokenForChannel(
            activeConversation,
            response.timetoken,
          );
        })
        .catch(() => {});
    }
  };

  const fetchMessages = (channels) => {
    pubnub.fetchMessages(
      {
        channels: [...channels],
        count: PUBNUB_INITIAL_CHAT_LIMIT,
      },
      (status, response) => {
        setMessages((messages) => ({ ...messages, ...response.channels }));
      },
    );
  };

  const updateAllMessagesLoaded = (channelId) => {
    const index = chatGroups?.findIndex((x) => x.id === channelId);
    if (index !== undefined && index >= 0 && chatGroups) {
      const cg = [...chatGroups];
      cg[index].allMessagesLoaded = true;
      setChatGroups(cg);
    }
  };

  const fetchMoreMessages = (channelId) => {
    pubnub.fetchMessages(
      {
        channels: [channelId],
        count: PUBNUB_CHAT_LIMIT,
        start: messages[channelId][0].timetoken,
      },
      (status, response) => {
        if (
          response &&
          response.channels &&
          response.channels[channelId].length
        ) {
          if (response.channels[channelId].length < PUBNUB_CHAT_LIMIT) {
            updateAllMessagesLoaded(channelId);
          }

          setMessages((messages) => ({
            ...messages,
            [channelId]: [
              ...response.channels[channelId],
              ...messages[channelId],
            ],
          }));
        } else {
          updateAllMessagesLoaded(channelId);
        }
      },
    );
  };

  useEffect(() => {
    if (announcementGroup && chatGroups && lastReadTimeTokens) {
      let unreadChats = false;
      for (let a = 0; a < chatGroups?.length; a++) {
        if (
          showReadDotIcon(
            chatGroups[a].id,
            chatGroups[a].lastMessageTime,
            lastReadTimeTokens,
            activeConversation,
            setLastReadTimeTokens,
          )
        ) {
          unreadChats = true;
          break;
        }
      }
      if (!unreadChats) {
        unreadChats = checkUnreadAnnouncements(
          announcementGroup,
          lastReadTimeTokens,
          activeConversation,
          setLastReadTimeTokens,
        );
      }
      ls.set(SHOW_RED_DOT_ON_CHATS, unreadChats ? 'true' : 'false');
      dispatch(setUnreadChats(unreadChats));
    }
  }, [announcementGroup, chatGroups, lastReadTimeTokens]);

  useEffect(() => {
    if (chatGroups) {
      const conversations = chatGroups.map((x) => {
        if (messages[x.id] && messages[x.id].length) {
          x.lastMessage =
            messages[x.id][messages[x.id].length - 1].message?.content?.message;
          x.lastMessageTime =
            messages[x.id][messages[x.id].length - 1].timetoken;
        }
        return x;
      });
      conversations.sort((a, b) => {
        let prev = 0;
        let next = 0;
        if (a.lastMessageTime) {
          prev = Number(a.lastMessageTime) / 10000;
        } else {
          prev = new Date(a.createdAt).getTime();
        }
        if (b.lastMessageTime) {
          next = Number(b.lastMessageTime) / 10000;
        } else {
          next = new Date(b.createdAt).getTime();
        }
        return prev < next ? 1 : -1;
      });
      setChatGroups(conversations);
    }
    if (announcementGroup) {
      const announcements = announcementGroup.map((x) => {
        if (messages[x.id] && messages[x.id].length) {
          x.lastMessage =
            messages[x.id][messages[x.id].length - 1].message.content.message;
          x.lastMessageTime =
            messages[x.id][messages[x.id].length - 1].timetoken;
        }
        return x;
      });
      announcements.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      );

      setAnnouncementGroup(announcements);
    }
  }, [messages]);

  const handleBulkClick = useCallback(() => {
    setShowBulkMsg(true);
    if (phoneOnly) {
      setSidebarVisible(false);
    }
  }, [phoneOnly]);

  const handleBackBulkMsg = useCallback(() => {
    setShowReceivers(true);
    setSidebarVisible(true);
    setShowBulkMsg(false);
  }, []);

  const handleBulkSuccess = useCallback(() => {
    setShowBulkMsg(false);
    setShowReceivers(false);
    setSidebarVisible(true);
  }, []);

  useEffect(() => {
    if (tab === 0) {
      if (showLoader && !allChatsLoaded && tab === 0) {
        fetchChatGroups();
      } else if (showLoader) {
        setTimeout(() => setShowLoader(false), 50);
      }
    } else {
      if (showLoader && !allAnnouncementsLoaded && tab === 1) {
        fetchAnnouncements();
      } else if (showLoader) {
        setTimeout(() => setShowLoader(false), 50);
      }
    }
  }, [showLoader]);

  const onPageEndReached = () => {
    setShowLoader(true);
  };

  return (
    <MainContainer
      style={{
        ...styles.mainContainerStyle,
      }}
    >
      <Sidebar
        position="left"
        style={
          sidebarVisible
            ? { ...styles.sideBarStyle, width: phoneOnly ? '100%' : '360px' }
            : phoneOnly
              ? { display: 'none' }
              : {}
        }
      >
        {showReceivers ? (
          <SelectReceiver
            handleBulkClick={handleBulkClick}
            handleCreateChatGroup={handleCreateChatGroup}
            setShowBulkMsg={setShowBulkMsg}
            setShowReceivers={setShowReceivers}
            showBulkMsg={showBulkMsg}
          />
        ) : (
          <>
            <ConversationHeader style={styles.conversationHeaderStyle}>
              <ConversationHeader.Content
                style={styles.conversationContentStyle}
              >
                <Heading>Messages</Heading>
                <Action.Button
                  action={{
                    a11yLabel: `New Message`,
                    label: 'New Message',
                    id: 'btn-new-message',
                    onAction: () => {
                      setShowReceivers(true);
                      setActiveConversation('');
                    },
                  }}
                  appearance="primary"
                />
              </ConversationHeader.Content>
            </ConversationHeader>
            <Card>
              <Tabs
                fit
                selected={tab}
                showTabOnMobile={false}
                tabs={INAPPCHAT_TABS}
                onSelect={handleTab}
              />
              {tab === 0 ? (
                <ConversationList
                  key={'chat'}
                  id="chat"
                  loading={showLoader && !chatGroups}
                  loadingMore={showLoader && chatGroups && !allChatsLoaded}
                  style={{ height: 'calc(100vh - 255px)' }}
                  onYReachEnd={onPageEndReached}
                >
                  {chatGroups &&
                    chatGroups.map((grp, index) => {
                      return (
                        <Conversation
                          key={grp.id}
                          active={activeConversation === grp.id}
                          id={`chat-${index}`}
                          lastActivityTime={dateFormatter(grp.lastMessageTime)}
                          unreadDot={showReadDotIcon(
                            grp.id,
                            grp.lastMessageTime,
                            lastReadTimeTokens,
                            activeConversation,
                            setLastReadTimeTokens,
                          )}
                          onClick={() => {
                            setActiveConversation(grp.id);
                            setLastReadTimeTokenForChannel(
                              grp.id,
                              grp.lastMessageTime,
                            );
                            setSidebarVisible(false);
                          }}
                        >
                          <Conversation.Content
                            info={removeHtmlTags(grp.lastMessage)}
                            name={grp.name}
                            style={{ display: 'flex' }}
                          />
                          <Avatar
                            name={'Test'}
                            src={
                              grp.avatar
                                ? grp.avatar
                                : `https://ui-avatars.com/api/?name=${grp.name}`
                            }
                          />
                        </Conversation>
                      );
                    })}
                </ConversationList>
              ) : (
                <ConversationList
                  key={'announcement'}
                  id="announcement"
                  loading={showLoader && !announcementGroup}
                  loadingMore={
                    showLoader && announcementGroup && !allAnnouncementsLoaded
                  }
                  style={{ height: 'calc(100vh - 255px)' }}
                  onYReachEnd={onPageEndReached}
                >
                  {announcementGroup &&
                    announcementGroup.map((grp, index) => {
                      return (
                        <Conversation
                          key={grp.id}
                          active={activeConversation === grp.id}
                          id={`announcement-${index}`}
                          lastActivityTime={dateFormatter(grp.lastMessageTime)}
                          unreadDot={showReadDotIcon(
                            grp.id,
                            grp.lastMessageTime,
                            lastReadTimeTokens,
                            activeConversation,
                            setLastReadTimeTokens,
                          )}
                          onClick={() => {
                            setActiveConversation(grp.id);
                            setLastReadTimeTokenForChannel(
                              grp.id,
                              grp.lastMessageTime,
                            );
                            setSidebarVisible(false);
                          }}
                        >
                          <Conversation.Content
                            info={removeHtmlTags(grp.lastMessage)}
                            name={getAnnouncementTitle(grp)}
                            style={{ display: 'flex' }}
                          />
                          <Avatar
                            src={require('@/assets/img/announcment.png')}
                          />
                        </Conversation>
                      );
                    })}
                </ConversationList>
              )}
            </Card>
          </>
        )}
      </Sidebar>
      {tab === 0 ? (
        !showBulkMsg && activeConversation && chatGroups ? (
          <ChatDetails
            backClick={() => {
              setSidebarVisible(true);
              setActiveConversation('');
            }}
            chatGroup={chatGroups?.find((x) => x.id === activeConversation)!}
            fetchMoreMessages={fetchMoreMessages}
            messages={messages[activeConversation]}
            phoneOnly={phoneOnly}
            sendMessage={sendMessage}
          />
        ) : (
          !showBulkMsg &&
          !activeConversation && (
            <ChatContainer style={phoneOnly ? { display: 'none' } : {}}>
              <MessageList style={{ backgroundColor: '#f4f5f7' }}>
                <MessageList.Content style={styles.emptyMessageContent}>
                  <Subheading color={'lighter'}>
                    Send Messages through Gravy Chat
                  </Subheading>
                </MessageList.Content>
              </MessageList>
            </ChatContainer>
          )
        )
      ) : (
        !showBulkMsg &&
        activeConversation &&
        announcementGroup && (
          <AnnouncementDetails
            announcementGroup={
              announcementGroup?.find((x) => x.id === activeConversation)!
            }
            backClick={() => {
              setSidebarVisible(true);
              setActiveConversation('');
            }}
            messages={messages[activeConversation]}
            phoneOnly={phoneOnly}
          />
        )
      )}
      {showBulkMsg && (
        <SendBulkMessage
          handleBack={handleBackBulkMsg}
          handleBulkSuccess={handleBulkSuccess}
        />
      )}
    </MainContainer>
  );
};

export default InappChat;

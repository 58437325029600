import { createSvgIcon } from '@mui/material';

const ClockIcon = createSvgIcon(
  <svg
    fill="none"
    height="14"
    viewBox="0 0 14 14"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_13163_27468)">
      <path
        d="M6.75 12.625C9.8566 12.625 12.375 10.1066 12.375 7C12.375 3.8934 9.8566 1.375 6.75 1.375C3.6434 1.375 1.125 3.8934 1.125 7C1.125 10.1066 3.6434 12.625 6.75 12.625Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.125"
      />
      <path
        d="M6.75 3.625V7L9 8.125"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.125"
      />
    </g>
    <defs>
      <clipPath id="clip0_13163_27468">
        <rect
          fill="white"
          height="13.5"
          transform="translate(0 0.25)"
          width="13.5"
        />
      </clipPath>
    </defs>
  </svg>,
  'ClockIcon'
);
export default ClockIcon;

import Card from '@/components/Card';
import Skeleton from '@/components/Skeleton';
import Stack from '@/components/Stack';

const PageSkeleton = () => {
  return (
    <Skeleton.Page>
      <Stack vertical gap={30}>
        <Card sectioned title="Details">
          <Skeleton.Body />
        </Card>
        <Card sectioned title="Totals">
          <Skeleton.Body />
        </Card>
        <Card sectioned title="Items">
          <Skeleton.Body />
        </Card>
      </Stack>
    </Skeleton.Page>
  );
};

export default PageSkeleton;

import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { Body } from '@/components/Typography';
import { Scalars } from '@/types/graphql';

export type Props = {
  text?: Scalars['String'];
  hideModal: () => Scalars['Void'];
};

const DEFAULT_TEXT =
  'This address is not yet available because GravyWork must approve the location.';

const ActiveAddress = ({ text = DEFAULT_TEXT, hideModal }: Props) => {
  return (
    <Modal disableClickout size="xs" title="Address" onRequestClose={hideModal}>
      <Card.Section>
        <Stack vertical gap={8}>
          <Body>{text}</Body>
        </Stack>
        <Stack justify="end">
          <Button
            a11yLabel="Submit form"
            label="Ok"
            type="button"
            onClick={() => hideModal()}
          />
        </Stack>
      </Card.Section>
    </Modal>
  );
};

export default ActiveAddress;

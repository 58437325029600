import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { format, parseISO } from 'date-fns';
import { useEffect, useRef, useState } from 'react';

import Stack from '@/components/Stack';
import StaffingShiftsCardsSection from '@/routes/Agency/CreateGigOrder/JobEditor/Steps/StaffingStep/Common/StaffingShiftsCardsSection';

type Props = {
  jobs: Record<string, any>[];
  isClientAdmin: boolean;
  isLTA: boolean;
  selectedIds: number[];
  phoneOnly: boolean;
};

const ShiftsList = ({
  jobs,
  isClientAdmin,
  isLTA,
  selectedIds,
  phoneOnly,
}: Props) => {
  const containerRef = useRef<any | null>(null);
  const [isShowNavIcon, setIsShowNavIcon] = useState(false);
  const schedulesContainerWidth = jobs.length * 226 + 20 * (jobs.length - 1);

  useEffect(() => {
    setTimeout(() => {
      const modalElem = document.getElementById('worker-staffing-model');
      if (modalElem) {
        const isShowNextIcon =
          schedulesContainerWidth > modalElem?.clientWidth - 40;
        setIsShowNavIcon(isShowNextIcon);
      }
    }, 150);
  }, [jobs]);

  const navigationHandler = (rightNav = true) => {
    if (containerRef.current) {
      if (rightNav) {
        containerRef.current.scrollBy({ left: 500, behavior: 'smooth' });
      } else {
        containerRef.current.scrollBy({ left: -500, behavior: 'smooth' });
      }
    }
  };

  const mapJobToSchedule = (job) => {
    return {
      dateRange: {
        startDate: parseISO(job.firstShiftStartAt),
        endDate: parseISO(job.lastShiftEndAt),
      },
      quantity: job.quantity,
      startTime: format(new Date(job.firstShiftStartAt), 'hh:mm'),
      endTime: format(new Date(job.lastShiftEndAt), 'hh:mm'),
      selectedWorkers: job.jobOffers.map((offer) => offer.worker),
      hiredWorkers: job.jobWorkers.map((jw) => jw.worker),
      groupId: job.id,
      mandatoryBreakTime: job.mandatoryBreakTime,
    };
  };
  const navAdjust = isShowNavIcon && !phoneOnly;

  return (
    <>
      {jobs.length > 0 && (
        <div
          ref={containerRef}
          className="hide-scroll"
          style={{
            width: navAdjust ? '96%' : '100%',
            position: phoneOnly ? 'initial' : 'revert',
            overflow: phoneOnly ? 'auto hidden' : 'hidden',
            marginLeft: navAdjust ? '16px' : undefined,
          }}
        >
          <Stack
            className="hide-scroll"
            css={{
              width: '100%',
              marginTop: '10px',
              marginBottom: '10px',
              flexDirection: 'row',
            }}
            gap={20}
          >
            {jobs.map(mapJobToSchedule).map((sch, idx) => (
              <StaffingShiftsCardsSection
                key={idx}
                hideCancelModal={true}
                hideSelection={true}
                isClientAdmin={isClientAdmin}
                isLTA={isLTA}
                schedule={sch}
                selectedSchedules={selectedIds}
              />
            ))}
          </Stack>
          {navAdjust && (
            <NavigateNext
              sx={{
                position: 'absolute',
                top: '134px',
                right: '1%',
                border: '1px solid #D3D3D3',
                borderRadius: '20px',
                cursor: 'pointer',
              }}
              onClick={() => navigationHandler()}
            />
          )}
          {navAdjust && (
            <NavigateBefore
              sx={{
                position: 'absolute',
                top: '134px',
                left: '0.5%',
                border: '1px solid #D3D3D3',
                borderRadius: '20px',
                cursor: 'pointer',
              }}
              onClick={() => navigationHandler(false)}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ShiftsList;

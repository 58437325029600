import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { useModal } from 'react-modal-hook';

import CustomerFiltersModal from './CustomerFiltersModal';

import Button from '@/components/Button';
import IconicButton from '@/components/IconicButton';
import Stack from '@/components/Stack';
import TextInput from '@/components/TextInput';
import ButtonGroup from '@/elements/ButtonGroup';
import useMediaQuery from '@/hooks/useMediaQuery';
import { CustomerFilterSetInput } from '@/types/graphql';

type Props = {
  filters: CustomerFilterSetInput;
  query: string;
  onQueryChange: (query: string) => void;
  onChangeFilter: <K extends keyof CustomerFilterSetInput>(
    key: K,
    value: NonNullable<CustomerFilterSetInput[K]>
  ) => void;
  onClearFilter: (key: keyof CustomerFilterSetInput) => void;
  onClearAll: () => void;
};

const CustomerFilters = ({
  onQueryChange,
  filters,
  query,
  onChangeFilter,
  onClearAll,
}: Props) => {
  const [showFiltersModal, hideFiltersModal] = useModal(
    () => (
      <CustomerFiltersModal
        filters={filters}
        hideModal={hideFiltersModal}
        onChangeFilter={onChangeFilter}
        onClearAll={onClearAll}
        onClearFilter={() => null}
      />
    ),
    [filters]
  );
  const phoneOnly = useMediaQuery('(max-width: 559px)');

  return (
    <Stack vertical gap={16}>
      <Stack gap={phoneOnly ? 8 : 16}>
        <div style={{ flex: 1 }}>
          <TextInput
            id="search-clients"
            placeholder="Search for clients..."
            value={query}
            onChange={(ev) => onQueryChange(ev.currentTarget.value)}
          />
        </div>
        {!phoneOnly ? (
          <ButtonGroup>
            <Button
              a11yLabel="View filters"
              appearance="outline"
              id="btn-show-filters"
              label="Filters"
              onClick={showFiltersModal}
            />
          </ButtonGroup>
        ) : (
          <IconicButton
            a11yLabel={'clear all filters'}
            appearance={phoneOnly ? 'outline' : 'clear'}
            icon={faFilter}
            id="btn-clear-filters"
            size={phoneOnly ? 'md' : 'sm'}
            onClick={() => showFiltersModal()}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default CustomerFilters;

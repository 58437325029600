import { FC, ReactNode } from 'react';

import { useAppSelector, useAppDispatch } from '@/hooks/store';
import {
  selectSidebarActive,
  setSidebarVisibility,
} from '@/store/slices/layoutSlice';
import styled from '@/styles';

const NAVBAR_HEIGHT = '48px';
const SIDEBAR_WIDTH = '256px';

const Wrapper = styled('main', {
  display: 'grid',
  gridTemplateAreas: '"navbar" "content" "footer"',
  gridTemplateColumns: '100vw',
  gridTemplateRows: `${NAVBAR_HEIGHT} 1fr auto`,
  height: '100%',
  padding:
    'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left) !important;',

  '@smallDesktop': {
    gridTemplateAreas:
      '"navbar navbar" "sidebar content" "sidebar footer" "bottomnavbar bottomnavbar"',
    gridTemplateColumns: `${SIDEBAR_WIDTH} 1fr`,
    gridTemplateRows: `${NAVBAR_HEIGHT} 1fr auto`,
  },
  '@phoneOnly': {
    paddingBottom: ' 0 !important',
  },
});

const NavbarContainer = styled('nav', {
  display: 'flex',
  gridArea: 'navbar',

  zIndex: '$navbar',

  '& > *': {
    flex: 1,
  },

  '@smallDesktop': {
    height: NAVBAR_HEIGHT,

    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
  },
});

const SidebarContainer = styled('aside', {
  boxShadow: '$shadows$2',

  bottom: 0,
  left: 0,
  position: 'fixed',
  top: NAVBAR_HEIGHT,

  overflowY: 'auto',

  zIndex: '$sidebar',

  '& > *': {
    flex: 1,
  },

  '@touchable': {
    width: '100%',
    backgroundColor: 'rgb(0, 0, 0,0.4)',
  },
});

const TouchableContainer = styled('div', {
  width: SIDEBAR_WIDTH,

  backgroundColor: 'white',
  boxShadow: '0 1px 2px 0 $colors$neutralA12',

  bottom: 0,
  left: 0,
  position: 'fixed',
  top: NAVBAR_HEIGHT,

  overflowY: 'auto',

  zIndex: 2000,

  '& > *': {
    flex: 1,
  },

  '@smallDesktop': {
    display: 'flex',
  },
});

const BottomNavContainer = styled('div', {
  display: 'flex',
  position: 'sticky',
  bottom: 0,
  zIndex: '$bottombar',
  backgroundColor: 'white',
  padding: '8px 10px',
  boxShadow: '0px -1px 8px rgba(0, 0, 0, 0.12)',

  '& > *': {
    flex: 1,
  },
});

type Props = {
  Footer: ReactNode;
  Navbar: ReactNode;
  Sidebar: ReactNode;
  BottomNav: ReactNode;
  isSmallDesktop: boolean;
};

const AppFrame: FC<Props> = ({
  children,
  Footer,
  Navbar,
  Sidebar,
  BottomNav,
  isSmallDesktop,
}) => {
  const showSidebar = useAppSelector((state) => selectSidebarActive(state));

  const dispatch = useAppDispatch();

  return (
    <Wrapper>
      <NavbarContainer>{Navbar}</NavbarContainer>

      {showSidebar && (
        <SidebarContainer
          onClick={() => {
            showSidebar &&
              !isSmallDesktop &&
              dispatch(setSidebarVisibility(false));
          }}
        >
          <TouchableContainer>{Sidebar}</TouchableContainer>
        </SidebarContainer>
      )}

      <section style={{ gridArea: 'content' }}>{children}</section>
      <footer style={{ gridArea: 'footer' }}>{Footer}</footer>
      {BottomNav && (
        <BottomNavContainer
          style={{ gridArea: !isSmallDesktop ? 'auto' : 'bottomnavbar' }}
        >
          {BottomNav}
        </BottomNavContainer>
      )}
    </Wrapper>
  );
};

export { NAVBAR_HEIGHT, SIDEBAR_WIDTH };
export default AppFrame;

import { createContext } from 'react';

import {
  FirstStepState,
  SecondStepState,
  ThirdStepUpdate,
} from '../../interfaces/signUp.interface';

export interface SignUpActions {
  resetState: () => void;
  updateFirstStep: (payload: FirstStepState) => void;
  updateSecondStep: (payload: SecondStepState) => void;
  updateThirdStep: (payload: ThirdStepUpdate) => void;
}

export const SignUpActionContext = createContext<SignUpActions>(
  {} as SignUpActions
);
SignUpActionContext.displayName = 'SignUpActionContext';

import { useModal } from 'react-modal-hook';

import ChangeCertificateModal from './ChangeCertificationModal';

import Card from '@/components/Card';
import TagList from '@/components/TagList';
import { GetWorkerQuery } from '@/types/graphql';
import { sortBy } from '@/util/array';

type Props = {
  worker: GetWorkerQuery['worker'];
  currentAdminIsCustomerAdmin: boolean;
};

const CertificateSection = ({ worker, currentAdminIsCustomerAdmin }: Props) => {
  const [showChangeModal, hideChangeModal] = useModal(
    () => (
      <ChangeCertificateModal hideModal={hideChangeModal} worker={worker} />
    ),
    [worker]
  );

  return (
    <Card.Section
      actions={
        !currentAdminIsCustomerAdmin
          ? [
              {
                a11yLabel: "Change this GravyWorker's certificate",
                label: 'Change',
                onAction: showChangeModal,
                id: 'btn-change-certificate',
              },
            ]
          : undefined
      }
      title="Credentials"
    >
      {worker.activeCertificates.length > 0 ? (
        <TagList
          showIcon={true}
          style={{ backgroundColor: '#e0eaff' }}
          tags={sortBy(worker.activeCertificates, 'name').map(
            (cert) => cert.name
          )}
        />
      ) : (
        'None'
      )}
    </Card.Section>
  );
};

export default CertificateSection;

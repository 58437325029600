import { useReactiveVar } from '@apollo/client';
import useModal from '@area2k/use-modal';
import { useState, useMemo, useEffect } from 'react';

import MyTeamFilters from './MyTeamFilters';
import AddNewMemberModal from './modals/addNewMemberModal';

import Avatar from '@/components/Avatar';
import Badge from '@/components/Badge';
import Card from '@/components/Card';
import LoadingState from '@/components/LoadingState';
import Page from '@/components/Page';
import Paginator from '@/components/Paginator/Paginator';
import QueryEmptyState from '@/components/QueryEmptyState';
import Stack from '@/components/Stack';
import SuccessfulCreateAlert from '@/components/SuccessfulCreationAlert';
import Table from '@/components/Table';
import TableHeader from '@/components/TableHeader';
import TextStack from '@/components/TextStack';
import { Body, Small } from '@/components/Typography';
import { TIME_TO_REFRESH } from '@/constants/general';
import Link from '@/elements/Link';
import { TableCell, TableRow } from '@/elements/Table';
import { useGetMyTeamQuery } from '@/graphql';
import useFilters from '@/hooks/useFilters';
import useMediaQuery from '@/hooks/useMediaQuery';
import ROUTES from '@/routes/routes';
import { CustomerAdminFilterSetInput, User } from '@/types/graphql';
import { currentAdminVar } from '@/util/apollo/cache';

const initialFilters: CustomerAdminFilterSetInput = {};

export type MemberInvited = {
  invited: boolean;
  data: Pick<User, 'id' | 'firstName' | 'lastName'> | undefined;
};

const MyTeam = () => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const initialMemberInvited = {
    invited: false,
    data: {
      id: '',
      firstName: '',
      lastName: '',
    },
  };
  const [memberInvited, setMemberInvited] =
    useState<MemberInvited>(initialMemberInvited);

  const {
    debouncedQuery,
    filters,
    query: searchQuery,
    setQuery,
  } = useFilters<CustomerAdminFilterSetInput>(initialFilters);

  const finalFilters = useMemo<CustomerAdminFilterSetInput>(() => {
    const value = { ...filters };

    if (debouncedQuery !== '') {
      value.query = { value: debouncedQuery };
    }

    return value;
  }, [debouncedQuery, filters]);

  const currentAdmin = useReactiveVar(currentAdminVar);
  const query = useGetMyTeamQuery({
    variables: {
      customerId: currentAdmin!.customer!.id,
      filters: finalFilters,
      page: currentPage,
      perPage: itemsPerPage,
    },
    pollInterval: TIME_TO_REFRESH,
  });

  const headerFields = useMemo(() => ['User', 'Phone number', 'Status'], []);

  const [showAddNewMemberModal, hideAddNewMemberModal] = useModal(
    () => (
      <AddNewMemberModal
        setMemberWasInvited={setMemberInvited}
        onHide={hideAddNewMemberModal}
      />
    ),
    []
  );

  const [showSuccessfulCreateModal, hideSuccessfulCreateModal] = useModal(
    () => (
      <SuccessfulCreateAlert
        body="Your invitation has been successfully sent"
        hideModal={() => {
          hideSuccessfulCreateModal();
          setMemberInvited(initialMemberInvited);
        }}
        item={`${memberInvited.data!.firstName} ${
          memberInvited.data!.lastName
        }`}
      />
    ),
    [memberInvited]
  );

  const handlePageChange = useMemo(
    () => (pageNumber: number) => setCurrentPage(pageNumber),
    []
  );

  const handleNumberItemsChange = useMemo(
    () => (event) => {
      setCurrentPage(1);
      setItemsPerPage(parseInt(event.target.value));
    },
    []
  );

  useEffect(() => {
    if (memberInvited.invited) {
      return showSuccessfulCreateModal();
    }
  }, [memberInvited, showSuccessfulCreateModal]);

  useEffect(() => {
    if (searchQuery !== '') setCurrentPage(1);
  }, [searchQuery]);

  const admins =
    !query.loading && query.data ? query.data.customer.admins.items : [];

  return (
    <Page
      footer={
        admins.length > 0 && (
          <Paginator
            currentPage={currentPage}
            handleNumberItemsChange={handleNumberItemsChange}
            handlePageChange={handlePageChange}
            itemsLength={
              !query.loading && query.data
                ? query.data.customer.admins.pageInfo.totalItems
                : 0
            }
            itemsPerPage={itemsPerPage}
          />
        )
      }
      headerPadding={phoneOnly}
      noPadding={phoneOnly}
      primaryAction={{
        a11yLabel: 'Create a Admin',
        label: 'New Member',
        onAction: showAddNewMemberModal,
        id: 'new-admin-btn',
      }}
      size={phoneOnly ? 'full' : 'md'}
      title="My Team"
    >
      <Card noRadius={phoneOnly}>
        <Card.Section>
          <MyTeamFilters query={searchQuery} onQueryChange={setQuery} />
        </Card.Section>

        <Table>
          {!phoneOnly && <TableHeader fields={headerFields} />}
          <tbody>
            {admins.map((admin) => (
              <TableRow
                key={admin.id}
                mobile={phoneOnly}
                spacing={phoneOnly ? 'normal' : false}
              >
                <TableCell noCellTitle={phoneOnly} stack={phoneOnly}>
                  <Stack gap={phoneOnly ? 10 : 8}>
                    <div>
                      <Avatar
                        firstName={admin.user.firstName}
                        size="sm"
                        src={admin.avatarUrl}
                      />
                    </div>
                    <TextStack spacing={phoneOnly ? 'loose' : 'normal'}>
                      <Link
                        to={{
                          pathname:
                            admin.id === currentAdmin!.id
                              ? `../${ROUTES.myProfile}`
                              : `../my-team/${admin.id}`,
                        }}
                      >
                        <Body color="inherit" weight="medium">
                          {admin.user.firstName} {admin.user.lastName}
                        </Body>
                      </Link>

                      <Link
                        as="a"
                        href={`mailto:${admin.user.email}`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Small size={phoneOnly ? 'md' : 'sm'}>
                          {admin.user.email}
                        </Small>
                      </Link>
                      {phoneOnly && (
                        <Link
                          as="a"
                          href={`tel:${admin.user.phoneNumber}`}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <Small color={'default'} size="md">
                            {admin.user.phoneNumber}
                          </Small>
                        </Link>
                      )}
                    </TextStack>
                  </Stack>
                </TableCell>
                {!phoneOnly && (
                  <TableCell stack={phoneOnly}>
                    {admin.user.phoneNumber}
                  </TableCell>
                )}
                <TableCell noCellTitle={phoneOnly} stack={phoneOnly}>
                  <Badge
                    label={
                      admin.acceptedAt
                        ? admin.user.active
                          ? 'Enabled'
                          : 'Disabled'
                        : 'Pending'
                    }
                    status={
                      admin.acceptedAt
                        ? admin.user.active
                          ? 'theme'
                          : 'danger'
                        : 'warning'
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
            {query.loading && (
              <TableRow>
                <TableCell align="center" colSpan={headerFields.length + 1}>
                  <LoadingState overlayColor="white" />
                </TableCell>
              </TableRow>
            )}
          </tbody>
        </Table>
        {!query.loading && admins.length === 0 && (
          <QueryEmptyState
            query={query}
            text="No client admins were found, invite one above."
            title="No client admins"
          />
        )}
      </Card>
    </Page>
  );
};

export default MyTeam;

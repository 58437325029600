import { Payroll } from '..';

import Card from '@/components/Card';
import Stack from '@/components/Stack';
import Table from '@/components/Table';
import TableHeader from '@/components/TableHeader';
import TextStack from '@/components/TextStack';
import { Body } from '@/components/Typography';
import { TableCell, TableRow } from '@/elements/Table';

const employerHeaderFields = [
  'Company Taxes',
  'Company Benefits',
  'Liability',
  'Cash Requirement',
];

const employeeHeaderFields = [
  'Employee Gross',
  'Employee Taxes',
  'Post Tax Deductions',
  'Employee Benefits',
  'Employee Net',
  'Employee Reimbursements',
];

type Props = {
  totals: Payroll['totals'];
};

const TotalsCard = ({ totals }: Props) => (
  <Card title="Totals">
    <Stack style={{ padding: '20px' }}>
      <TextStack>
        <Body weight="bold">Employer</Body>
      </TextStack>
    </Stack>
    <Table>
      <TableHeader fields={employerHeaderFields} />
      <tbody>
        <TableRow>
          <TableCell>{totals!.companyTaxes}</TableCell>
          <TableCell>{totals!.companyBenefits}</TableCell>
          <TableCell>{totals!.liability}</TableCell>
          <TableCell>{totals!.cashRequirement}</TableCell>
        </TableRow>
      </tbody>
    </Table>

    <Stack style={{ padding: '20px' }}>
      <TextStack>
        <Body weight="bold">Employee</Body>
      </TextStack>
    </Stack>
    <Table>
      <TableHeader fields={employeeHeaderFields} />
      <tbody>
        <TableRow>
          <TableCell>{totals!.employeeGross}</TableCell>
          <TableCell>{totals!.employeeTaxes}</TableCell>
          <TableCell>{totals!.postTaxDeductions}</TableCell>
          <TableCell>{totals!.employeeBenefits}</TableCell>
          <TableCell>{totals!.employeeNet}</TableCell>
          <TableCell>{totals!.employeeReimbursements}</TableCell>
        </TableRow>
      </tbody>
    </Table>
  </Card>
);

export default TotalsCard;

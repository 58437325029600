import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

import IconicButton from '@/components/IconicButton';
import Stack from '@/components/Stack';
import Input from '@/elements/Input';
import useCounter from '@/hooks/useCounter';

type Props = {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
};

const Counter = ({ value, onChange, min, max }: Props) => {
  const { count, decrement, increment } = useCounter({
    value,
    min,
    max,
    onChange,
  });

  return (
    <Stack>
      <IconicButton
        a11yLabel="action"
        appearance="clear"
        borderColor="neutral"
        borderless={false}
        css={{
          fontSize: '0.75em',
        }}
        data-testid="counter-decrement"
        icon={faMinus}
        iconTheme="neutral"
        id="counter-decrement"
        rounded={true}
        size="sm"
        onClick={decrement}
      />
      <Input
        css={{ textAlign: 'center' }}
        data-testid="counter-input"
        min={1}
        readOnly={true}
        type="number"
        value={count}
      />
      <IconicButton
        a11yLabel="action"
        appearance="clear"
        borderColor="neutral"
        borderless={false}
        css={{
          fontSize: '0.75em',
        }}
        data-testid="counter-increment"
        icon={faPlus}
        iconTheme="neutral"
        id="counter-increment"
        rounded={true}
        size="sm"
        onClick={increment}
      />
    </Stack>
  );
};

export default Counter;

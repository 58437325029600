import { useCallback, useState } from 'react';

import Card from '@/components/Card';
import LoadingState from '@/components/LoadingState';
import OrderTableItem from '@/components/OrderTableItem';
import Pagination from '@/components/Pagination';
import QueryEmptyState from '@/components/QueryEmptyState';
import Table from '@/components/Table';
import TableHeader from '@/components/TableHeader';
import { TIME_TO_REFRESH } from '@/constants/general';
import { useGetAccountOrdersQuery } from '@/graphql';
import useMediaQuery from '@/hooks/useMediaQuery';
import { GetAccountQuery, OrderListItemFragment } from '@/types/graphql';

type Props = {
  account: GetAccountQuery['account'];
};

const OrdersList = ({ account }: Props) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');

  const [page, setPage] = useState(1);

  const query = useGetAccountOrdersQuery({
    variables: {
      accountId: account.id,
      page,
      perPage: 10,
    },
    pollInterval: TIME_TO_REFRESH,
  });

  const renderRow = useCallback(
    (item: OrderListItemFragment) => (
      <OrderTableItem
        key={item.id}
        item={item}
        to={`../../../../orders/${item.id}`}
      />
    ),
    []
  );

  if (!query.data) {
    return (
      <Card title="Orders">
        <LoadingState />
      </Card>
    );
  }

  const { orders } = query.data.account;

  return (
    <Card noRadius={phoneOnly} title="Orders">
      <Table>
        {!phoneOnly && (
          <TableHeader
            fields={[
              'ID',
              'Billing Account',
              'Timeframe',
              'Approval Status',
              'Filled Jobs',
              'Skills',
            ]}
          />
        )}
        <tbody>{orders.items.map(renderRow)}</tbody>
      </Table>
      {orders.items.length === 0 && (
        <QueryEmptyState query={query} title="No orders created" />
      )}
      {orders.items.length > 0 && (
        <Card.Section subdued>
          <Pagination
            currentPage={page}
            totalPages={orders.pageInfo.totalPages}
            onPageChange={setPage}
          />
        </Card.Section>
      )}
    </Card>
  );
};

export default OrdersList;

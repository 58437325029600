import { createSvgIcon } from '@mui/material';

const ClientIcon = createSvgIcon(
  <svg
    fill="none"
    height="20"
    viewBox="0 0 512 512"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M256 256c74.281 0 134.4-57.256 134.4-128S330.28 0 256 0 121.6 57.257 121.6 128 181.72 256 256 256zm92.161 36.571H338.2c-24.96 11.427-52.678 18.286-82.2 18.286-29.52 0-57.12-6.858-82.201-18.286h-9.96c-76.32 0-138.24 58.971-138.24 131.658v32.913c0 30.286 25.8 54.858 57.6 54.858h345.6c31.8 0 57.6-24.573 57.6-54.858v-32.913c0-72.688-61.92-131.658-138.24-131.658z"
      fill="currentColor"
    />
  </svg>,
  'ClientIcon'
);
export default ClientIcon;

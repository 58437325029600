import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import * as React from 'react';

import Alert from '../Alert';

import AutocompleteField from '@/form/AutocompleteField';
import { useGetWorkerRelationshipsByCustomerQuery } from '@/graphql';
import useDebouncedValue from '@/hooks/useDebouncedValue';
import {
  GetWorkerRelationshipsByCustomerQuery,
  RelationshipKindEnum,
} from '@/types/graphql';

interface Props {
  customerId: string;
  skillIds: string[];
  fieldId?: string;
  address: any;
}
type WorkerRelationshipItem =
  GetWorkerRelationshipsByCustomerQuery['customer']['workerRelationships'][0]['worker'];

const LtaWorkersList = ({
  customerId,
  skillIds,
  fieldId = 'worker',
  address,
}: Props) => {
  const [search, setSearch] = React.useState('');
  const debouncedQuery = useDebouncedValue(search, 500);

  const { data, loading: workerLoading } =
    useGetWorkerRelationshipsByCustomerQuery({
      variables: {
        customerId,
        filters: { jurisdiction: address.state },
      },
    });

  const workersList = React.useMemo(() => {
    let items = data?.customer?.workerRelationships
      .filter(
        (item) =>
          item.kind === RelationshipKindEnum.LTA_WORKERS &&
          item.worker.skills.find((s) => skillIds.indexOf(s.id) !== -1)
      )
      .map((x) => x.worker);

    if (debouncedQuery) {
      items = items?.filter(
        (x) =>
          x.user.firstName
            .toLowerCase()
            .includes(debouncedQuery.toLowerCase()) ||
          x.user.lastName.toLowerCase().includes(debouncedQuery.toLowerCase())
      );
    }
    return items || [];
  }, [data?.customer, debouncedQuery]);

  return (
    <>
      <AutocompleteField<WorkerRelationshipItem>
        autoFocus
        required
        disabled={false}
        fieldId={fieldId}
        itemToKey={(item) => item.id}
        itemToString={(item) => {
          if (item) {
            return `${item.user.firstName || ''} ${item.user.lastName || ''}`;
          } else {
            return '';
          }
        }}
        items={workersList}
        label={'Select LTA GravyWorker'}
        openMenuOnInputFocus={true}
        placeholder="Search LTA GravyWorker..."
        onInputValueChange={({ inputValue }) => setSearch(inputValue ?? '')}
      />
      {!workerLoading && !debouncedQuery && workersList.length === 0 && (
        <Alert
          icon={faExclamationTriangle}
          status="warning"
          title="No LTA GravyWorkers found"
        />
      )}
    </>
  );
};

export default LtaWorkersList;

import { useReactiveVar } from '@apollo/client';
import { FieldContext, SubmitHelpers } from '@area2k/use-form';
import { isEqual } from 'lodash';
import { useCallback, useState } from 'react';

import AddressAutocomplete from '../AddressAutocomplete';
import EmptyState from '../EmptyState';

import ExperienceItem from './ExperienceItem';

import Button from '@/components/Button';
import FormElement from '@/components/FormElement';
import Stack from '@/components/Stack';
import Form from '@/form';
import AutocompleteField from '@/form/AutocompleteField';
import FormColumns from '@/form/FormColumns';
import MaskedInputField from '@/form/MaskedInputField';
import TextAreaField from '@/form/TextAreaField';
import TextField from '@/form/TextField';
import TextSelectField from '@/form/TextSelectField';
import { useListAgencySkillsQuery } from '@/graphql';
import { CandidateItem } from '@/routes/Agency/Workers/helpers';
import { DurationEnum, Maybe, WorkerReference } from '@/types/graphql';
import { currentAgencyVar } from '@/util/apollo/cache';

type Props = {
  experiences: WorkerReference[];
  deleteExperiences?: string[];
  handleAddition: (item) => void;
  handleDeletion?: (item) => void;
};
type FormValues = {
  name: string;
  duration: DurationEnum;
  employerName: string;
  phoneNumber: Maybe<string>;
  summary: Maybe<string>;
  email: Maybe<string>;
  title: string;
};

const durationOptions = [
  { label: 'From 0 to 6 months', value: DurationEnum.FROM_0_TO_6_MONTHS },
  { label: 'From 6 to 12 months', value: DurationEnum.FROM_6_TO_12_MONTHS },
  { label: 'From 1 to 3 years', value: DurationEnum.FROM_1_TO_3_YEARS },
  { label: 'More than 3 years', value: DurationEnum.MORE_THAN_3_YEARS },
];

const ExperienceForm = ({
  experiences,
  handleAddition,
  handleDeletion,
  deleteExperiences,
}: Props) => {
  const [query, setQuery] = useState('');
  const [addressPayload, setAddressPayload] = useState<object>({
    addressLine1: null,
    addressLine2: null,
    city: null,
    name: '',
    state: null,
    zip: null,
    coords: { latitude: 0, longitude: 0 },
  });
  const initialValues: FormValues = {
    name: '',
    duration: DurationEnum.FROM_0_TO_6_MONTHS,
    employerName: '',
    phoneNumber: '',
    summary: '',
    email: 'no@mail.com',
    title: '',
  };

  const [formValues, setFormValues] = useState<FormValues>(initialValues);

  const currentAgency = useReactiveVar(currentAgencyVar);
  const { data } = useListAgencySkillsQuery({
    variables: { agencyId: currentAgency!.id },
  });

  const getItems = useCallback(() => {
    if (data) {
      const items = data.agency.skillCategories
        .flatMap((category) => category.skills)
        .filter((skill) => {
          if (query !== '') return skill.name.toLowerCase().includes(query);
          return true;
        });

      return items;
    }
    return [];
  }, [query]);

  const handleFormValuesChange = <T extends any>(
    fieldContext: FieldContext<T>,
    fieldId: keyof FormValues
  ) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldId]: fieldContext.value,
    }));
  };

  const handleSubmit = useCallback(
    async (values: FormValues, { clearForm }: SubmitHelpers) => {
      const experience: WorkerReference = {
        ...values,
        name: values.name.name,
        title: values.name.name,
        city: `${addressPayload.city}, ${addressPayload.state}`,
      };
      handleAddition([...experiences, experience]);
      clearForm();
    },
    [addressPayload]
  );

  const handleRemove = (item: WorkerReference) => {
    item.id && handleDeletion([...deleteExperiences, item.id]);
    const index = experiences.findIndex((experience) =>
      isEqual(experience, item)
    );
    const copyArray = [...experiences];
    copyArray.splice(index, 1);
    handleAddition([...copyArray]);
  };

  return (
    <Form initialValues={formValues} onSubmit={handleSubmit}>
      <FormColumns>
        <AutocompleteField<CandidateItem['skills'][0]>
          required
          callback={(fieldContext) => {
            handleFormValuesChange(fieldContext, 'name');
          }}
          fieldId="name"
          fixedSelectHeight="lg"
          itemToKey={(item) => item.id}
          itemToString={(item) => (item ? item.name : '')}
          items={getItems()}
          label="Job"
          placeholder="Select a skill..."
          onInputValueChange={({ inputValue }) => setQuery(inputValue ?? '')}
        />
        <FormElement label="Address">
          <AddressAutocomplete
            setValues={setAddressPayload}
            values={addressPayload}
            onReset={() =>
              setAddressPayload({
                addressLine1: null,
                addressLine2: null,
                city: null,
                name: '',
                state: null,
                zip: null,
                coords: { latitude: 0, longitude: 0 },
              })
            }
          />
        </FormElement>
      </FormColumns>
      <FormColumns layout="triple">
        <TextSelectField
          callback={(fieldContext) => {
            handleFormValuesChange(fieldContext, 'duration');
          }}
          fieldId="duration"
          label="Duration"
          options={durationOptions}
        />
        <TextField
          required
          callback={(fieldContext) => {
            handleFormValuesChange(fieldContext, 'employerName');
          }}
          fieldId="employerName"
          label="Employer Name"
          placeholder="Employer Name"
        />
        <MaskedInputField
          callback={(fieldContext) => {
            handleFormValuesChange(fieldContext, 'phoneNumber');
          }}
          fieldId="phoneNumber"
          incompletemessage="Must be a valid phone number"
          label="Phone Number"
          mask="(000) 000-0000"
          placeholder="(555) 555-5555"
          type="tel"
        />
      </FormColumns>
      <TextAreaField
        callback={(fieldContext) => {
          handleFormValuesChange(fieldContext, 'summary');
        }}
        css={{ height: '56.28px' }}
        fieldId="summary"
        label="Summary"
        placeholder="Summary..."
      />
      <Stack justify="end">
        <Button
          a11yLabel="addExperience"
          appearance="secondary"
          id="btn-add-experience"
          isLoading={undefined}
          label="Add Experience"
          type="submit"
        />
      </Stack>
      <FormElement label="Experience List">
        {experiences.length > 0 ? (
          experiences.map((item: WorkerReference, index: number) => (
            <ExperienceItem
              key={index}
              handleRemove={handleRemove}
              reference={item}
            />
          ))
        ) : (
          <EmptyState
            text="Add a experience by clicking the button above"
            title="No experiences"
          />
        )}
      </FormElement>
    </Form>
  );
};

export default ExperienceForm;

import { createSvgIcon } from '@mui/material';

const Logo = createSvgIcon(
  <svg
    fill="none"
    height="64"
    viewBox="0 0 314 64"
    width="314"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_10243_19209)">
      <path
        d="M20.3003 13.2973L28.5432 39.1362L69.6162 5.75C57.0309 12.5477 44.4491 19.342 31.8639 26.1398C28.0082 21.8578 24.1525 17.5759 20.2969 13.2973H20.3003Z"
        fill="#45A735"
      />
      <path
        d="M33.1676 22.4413C35.0701 22.4413 36.6125 20.912 36.6125 19.0254C36.6125 17.1388 35.0701 15.6094 33.1676 15.6094C31.265 15.6094 29.7227 17.1388 29.7227 19.0254C29.7227 20.912 31.265 22.4413 33.1676 22.4413Z"
        fill="#45A735"
      />
      <path
        d="M55.7742 17.0012C58.439 21.0949 60.0475 25.9211 60.2132 31.1066H52.8333V32.8899H60.2098C59.9854 39.8622 57.1584 46.191 52.6538 50.9522L50.5931 48.9088L49.3228 50.1684L51.3835 52.2118C46.5855 56.6786 40.2031 59.4819 33.1684 59.7043V52.3898H31.37V59.7043C24.3387 59.4819 17.9563 56.6786 13.1583 52.2118L15.219 50.1684L13.9487 48.9088L11.888 50.9522C7.3834 46.1945 4.55638 39.8657 4.33201 32.8934H11.7085V31.1101H4.33201C4.55638 24.1378 7.3834 17.809 11.888 13.0512L13.8383 14.9851L15.1085 13.7255L13.1583 11.7916C17.9563 7.32485 24.3387 4.52155 31.37 4.29907V11.6136H33.1684V2.47128V0.0239598C32.8681 0.0171141 32.5712 0 32.2709 0C14.4769 0 0 14.3553 0 32C0 49.6447 14.4769 64 32.2709 64C50.0649 64 64.5418 49.6447 64.5418 32C64.5418 25.4521 62.5397 19.3595 59.1225 14.2835L55.7777 17.0012H55.7742Z"
        fill="#262626"
      />
      <path
        d="M86.351 29.2403H99.4816V46.2586C97.3519 46.9466 95.3498 47.4292 93.472 47.7065C91.5943 47.9837 89.6716 48.1206 87.711 48.1206C82.7162 48.1206 78.8985 46.6659 76.2648 43.7531C73.6311 40.8437 72.3125 36.661 72.3125 31.2153C72.3125 25.7696 73.8416 21.7854 76.8965 18.8212C79.9548 15.8571 84.1901 14.375 89.6095 14.375C93.0061 14.375 96.2818 15.0493 99.4368 16.3945L97.1033 21.9634C94.6871 20.7654 92.1742 20.1664 89.5646 20.1664C86.5305 20.1664 84.1004 21.1762 82.2744 23.199C80.4484 25.2185 79.5337 27.9362 79.5337 31.3488C79.5337 34.7614 80.2689 37.6297 81.7428 39.5088C83.2133 41.3879 85.3534 42.3258 88.1632 42.3258C89.6267 42.3258 91.1145 42.1752 92.6229 41.8774V35.0283H86.351V29.2369V29.2403Z"
        fill="#262626"
      />
      <path
        d="M118.629 22.0972C119.564 22.0972 120.341 22.1656 120.962 22.2991L120.441 28.7203C119.882 28.5697 119.202 28.4944 118.404 28.4944C116.199 28.4944 114.483 29.0558 113.254 30.1785C112.025 31.3012 111.408 32.8722 111.408 34.8917V47.6657H104.504V22.5661H109.733L110.752 26.7864H111.09C111.877 25.3797 112.937 24.2467 114.273 23.3842C115.608 22.5216 117.062 22.0938 118.632 22.0938L118.629 22.0972Z"
        fill="#262626"
      />
      <path
        d="M139.311 47.6745L137.975 44.262H137.796C136.633 45.7133 135.435 46.7196 134.206 47.2809C132.977 47.8423 131.372 48.1229 129.394 48.1229C126.964 48.1229 125.052 47.4349 123.654 46.059C122.259 44.683 121.559 42.7217 121.559 40.1751C121.559 37.6285 122.501 35.5475 124.379 34.281C126.256 33.018 129.09 32.3163 132.88 32.1828L137.275 32.0493V30.9472C137.275 28.404 135.963 27.1307 133.333 27.1307C131.31 27.1307 128.932 27.7366 126.201 28.9483L123.913 24.324C126.826 22.8111 130.057 22.0547 133.605 22.0547C137.154 22.0547 139.605 22.7872 141.417 24.2556C143.229 25.724 144.133 27.9522 144.133 30.9438V47.6711H139.311V47.6745ZM137.275 36.0438L134.603 36.1328C132.597 36.1944 131.103 36.5538 130.119 37.211C129.139 37.8681 128.649 38.871 128.649 40.2196C128.649 42.1501 129.764 43.1153 131.997 43.1153C133.598 43.1153 134.876 42.6601 135.835 41.7462C136.791 40.8323 137.275 39.6206 137.275 38.1077V36.0438Z"
        fill="#262626"
      />
      <path
        d="M154.949 47.6699L145.305 22.5703H152.529L157.421 36.8709C157.962 38.6816 158.304 40.3964 158.439 42.012H158.577C158.653 40.5744 158.991 38.863 159.595 36.8709L164.462 22.5703H171.687L162.042 47.6699H154.956H154.949Z"
        fill="#262626"
      />
      <path
        d="M172.527 22.5703H180.09L184.868 36.6929C185.275 37.9183 185.554 39.373 185.706 41.0467H185.844C186.01 39.5065 186.335 38.0552 186.818 36.6929L191.505 22.5703H198.909L188.199 50.8805C187.218 53.499 185.817 55.4603 183.998 56.7644C182.179 58.065 180.056 58.7188 177.626 58.7188C176.435 58.7188 175.265 58.5921 174.115 58.3354V52.9034C174.947 53.0985 175.851 53.1943 176.832 53.1943C178.054 53.1943 179.124 52.8247 180.035 52.0853C180.95 51.3426 181.661 50.2233 182.175 48.7276L182.582 47.4919L172.531 22.5703H172.527Z"
        fill="#262626"
      />
      <path
        d="M234.016 47.6687H226.025L221.541 30.4245C221.375 29.8118 221.092 28.5419 220.692 26.6183C220.291 24.6946 220.06 23.4042 220.001 22.7471C219.911 23.5548 219.684 24.8521 219.321 26.6422C218.959 28.4324 218.679 29.7091 218.482 30.4724L214.023 47.6687H206.052L197.609 14.8438H204.513L208.748 32.7622C209.487 36.0687 210.022 38.937 210.357 41.3604C210.447 40.5081 210.654 39.1869 210.978 37.3968C211.303 35.61 211.606 34.2204 211.896 33.2312L216.719 14.8438H223.353L228.175 33.2312C228.386 34.0561 228.651 35.3123 228.969 37.0031C229.287 38.694 229.528 40.1453 229.694 41.3604C229.846 40.1932 230.084 38.7385 230.419 36.9929C230.75 35.2506 231.054 33.8404 231.327 32.7622L235.538 14.8438H242.441L234.019 47.6687H234.016Z"
        fill="#262626"
      />
      <path
        d="M266.745 35.0775C266.745 39.1644 265.658 42.3613 263.483 44.6648C261.308 46.9718 258.285 48.1219 254.405 48.1219C251.975 48.1219 249.831 47.5948 247.974 46.5371C246.117 45.4829 244.691 43.9666 243.694 41.9916C242.696 40.0166 242.199 37.7097 242.199 35.0775C242.199 30.977 243.28 27.7903 245.437 25.5141C247.594 23.2379 250.629 22.1016 254.539 22.1016C256.969 22.1016 259.113 22.6253 260.97 23.6726C262.827 24.72 264.253 26.2261 265.25 28.1873C266.248 30.1486 266.745 32.4453 266.745 35.0775ZM249.244 35.0775C249.244 37.5625 249.655 39.4416 250.477 40.7115C251.298 41.9848 252.637 42.6214 254.495 42.6214C256.352 42.6214 257.66 41.9882 258.468 40.7252C259.275 39.4621 259.679 37.5796 259.679 35.0775C259.679 32.5754 259.272 30.7305 258.457 29.488C257.643 28.2455 256.307 27.626 254.45 27.626C252.593 27.626 251.281 28.2421 250.466 29.4778C249.652 30.7134 249.244 32.5823 249.244 35.0775Z"
        fill="#262626"
      />
      <path
        d="M284.261 22.0972C285.197 22.0972 285.974 22.1656 286.595 22.2991L286.074 28.7203C285.514 28.5697 284.834 28.4944 284.037 28.4944C281.831 28.4944 280.116 29.0558 278.887 30.1785C277.658 31.3012 277.04 32.8722 277.04 34.8917V47.6657H270.137V22.5661H275.366L276.384 26.7864H276.723C277.51 25.3797 278.569 24.2467 279.905 23.3842C281.241 22.5216 282.694 22.0938 284.265 22.0938L284.261 22.0972Z"
        fill="#262626"
      />
      <path
        d="M295.502 34.0209L298.515 30.2045L305.602 22.5716H313.389L303.338 33.4596L314 47.6712H306.03L298.74 37.5019L295.775 39.8603V47.6746H288.871V12.7344H295.775V28.3151L295.412 34.0175H295.502V34.0209Z"
        fill="#262626"
      />
    </g>
    <defs>
      <clipPath id="clip0_10243_19209">
        <rect fill="white" height="64" width="314" />
      </clipPath>
    </defs>
  </svg>,
  'Logo'
);
export default Logo;

import { useReactiveVar } from '@apollo/client';
import { usePubNub } from 'pubnub-react';
import qs from 'query-string';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Role } from '../PrivateRoute';

import Config from '@/config';
import { V3Endpoints } from '@/constants/urls';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import ROUTES from '@/routes/routes';
import {
  ReloadInvoicePayrollStatusEnum,
  addMessage,
  setConnectionStatus,
  setPubnubMessage,
  setReloadInvoices,
  setReloadPayrolls,
  setUnreadChats,
} from '@/store/slices/pubnubSlice';
import { currentAdminVar } from '@/util/apollo/cache';
import axiosClient from '@/util/axios/axiosClient';
import {
  MessageActionType,
  MessageType,
  NetworkStatusCategory,
} from '@/util/axios/types';
import ls, { SHOW_RED_DOT_ON_CHATS } from '@/util/localstorage';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { useFeatureValue } from '@growthbook/growthbook-react';

type UserDefaultPubNubChannelProps = {
  children: any;
};

const UserDefaultPubNubChannel = ({
  children,
}: UserDefaultPubNubChannelProps) => {
  const navigate = useNavigate();
  const pubnubInstance = usePubNub();
  const currentAdmin = useReactiveVar(currentAdminVar);

  const dispatch = useAppDispatch();
  const channels = useAppSelector((state) => state.pubnub.channels);

  const payrolllBackgroundJob = useFeatureValue(
    FEATURE_TOGGLE.EnablePayrollProcessBackgroundJob,
    false,
  );

  useEffect(() => {
    if (currentAdmin && pubnubInstance) {
      const channel = currentAdmin.user.channelId;

      const announcementChannels = [
        `BROADCAST_CHANNEL_${Config.APP_ENVIRONMENT}`,
      ];
      if (currentAdmin.role === Role.TENANT_ADMIN) {
        announcementChannels.push(
          `BROADCAST_TENANT_CHANNEL_${Config.APP_ENVIRONMENT}`,
        );
      }
      pubnubInstance.channelGroups.addChannels(
        {
          channels: [channel, ...announcementChannels, ...channels],
          channelGroup: `cg_user${currentAdmin!.user.id}`,
        },
        function () {
          pubnubInstance.subscribe({
            channelGroups: [`cg_user${currentAdmin!.user.id}`],
          });
        },
      );

      const listenerParams = {
        message: handleMessage,
        status: (statusEvent) => {
          if (statusEvent && statusEvent.category) {
            switch (statusEvent.category) {
              case NetworkStatusCategory.PUBNUB_CONNECTED:
              case NetworkStatusCategory.PUBNUB_NETWORK_UP:
              case NetworkStatusCategory.PUBNUB_RECONNECTED:
                dispatch(setConnectionStatus(true));
                return;
              case NetworkStatusCategory.PUBNUB_NETWORK_ISSUES:
              case NetworkStatusCategory.PUBNUB_NETWORK_DOWN:
              case NetworkStatusCategory.PUBNUB_NETWORK_TIMEOUT:
                dispatch(setConnectionStatus(false));
                return;
              default:
                dispatch(setConnectionStatus(true));
            }
          }
        },
      };
      pubnubInstance.addListener(listenerParams);

      return () => {
        pubnubInstance.unsubscribe({
          channelGroups: [`cg_user${currentAdmin!.user.id}`],
        });
        pubnubInstance.removeListener(listenerParams);
      };
    }
  }, [pubnubInstance, currentAdmin, channels]);

  useEffect(() => {
    const showRedDot = ls.get(SHOW_RED_DOT_ON_CHATS);
    if (currentAdmin && pubnubInstance && !showRedDot) {
      axiosClient
        .get(V3Endpoints.GET_CHATGROUP_IDS)
        .then((result) => {
          if (result.data && result.data.length) {
            getMemberships(result.data);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    } else if (showRedDot) {
      dispatch(setUnreadChats(showRedDot === 'true'));
    }
  }, [pubnubInstance, currentAdmin]);

  const getMemberships = (userChannelIds) => {
    pubnubInstance.objects.getMemberships(
      {
        include: {
          customFields: true,
        },
      },
      (status, response) => {
        if (response && response.data && response.data.length) {
          checkUserHasUnreadMessages(userChannelIds, response.data);
        }
      },
    );
  };

  const checkUserHasUnreadMessages = async (userChannelIds, membershipData) => {
    dispatch(setUnreadChats(false));
    ls.set(SHOW_RED_DOT_ON_CHATS, 'false');
    for (let i = 0; i < userChannelIds.length; i++) {
      const element = userChannelIds[i];
      const ch = membershipData.find((x) => x.channel.id === element);
      const response = await pubnubInstance.messageCounts({
        channels: [element],
        channelTimetokens: [
          ch && ch.custom?.lastReadTimetoken
            ? ch.custom?.lastReadTimetoken
            : '0',
        ],
      });
      if (response && response.channels && response.channels[element] > 0) {
        dispatch(setUnreadChats(true));
        ls.set(SHOW_RED_DOT_ON_CHATS, 'true');
        break;
      }
    }
  };

  const handleInvoiceGenerationMessage = (payload) => {
    setTimeout(() => {
      const queryString = qs.parse(location.search);
      if (
        window.location.href.includes(ROUTES.invoiceGeneratorDetail) &&
        queryString &&
        queryString.clientId &&
        queryString.accountId
      ) {
        if (
          payload.clientId.toString() !== queryString.clientId.toString() ||
          payload.accountId.toString() !== queryString.accountId.toString()
        ) {
          return;
        }

        if (payload.userId?.toString() === currentAdmin?.user.id) {
          if (payload.success) {
            dispatch(
              addMessage({
                type: 'SUCCESS',
                title: 'Invoice Successful',
                content: `Your draft Invoice <a href="/invoices/${payload.invoiceId}">#${payload.invoiceId}</a> has been created successfully.`,
                reloadLink: false,
              }),
            );
            dispatch(setReloadInvoices(ReloadInvoicePayrollStatusEnum.AUTO));
          } else {
            dispatch(
              addMessage({
                type: 'ERROR',
                title: 'Invoice Failure',
                content: `Your invoice could not be created.`,
                reloadLink: true,
              }),
            );
          }
        } else if (currentAdmin?.role === Role.TENANT_ADMIN) {
          dispatch(
            addMessage({
              type: 'INFORMATION',
              title: 'Updates Available',
              content: 'There are new changes on this page.',
              reloadLink: true,
            }),
          );
        }
      }
    }, 100);
  };

  const handlePayrollGenerationMessage = (payload) => {
    setTimeout(() => {
      if (
        window.location.href.includes(ROUTES.payrolls) &&
        payload.userId?.toString() === currentAdmin?.user.id
      ) {
        if (payload.success) {
          dispatch(
            addMessage({
              type: 'SUCCESS',
              title: 'Payroll Successful',
              content: `Your draft payroll <a href="/payrolls/${payload.payrollId}">#${payload.payrollId}</a> has been created successfully.`,
            }),
          );
          dispatch(setReloadPayrolls(ReloadInvoicePayrollStatusEnum.AUTO));
        } else {
          dispatch(
            addMessage({
              type: 'ERROR',
              title: 'Payroll Failure',
              content: `${payload.message}.`,
            }),
          );
          dispatch(setReloadPayrolls(ReloadInvoicePayrollStatusEnum.AUTO));
        }
      }
    }, 100);
  };

  const handleMessage = (event) => {
    const message = event;
    if (message.message?.content?.type === MessageType.SYSTEM_MESSAGE) {
      if (
        message.message?.content?.actionType ===
          MessageActionType.CUSTOMER_ENABLED ||
        message.message?.content?.actionType ===
          MessageActionType.CUSTOMER_DISABLED
      ) {
        const payloadUserId = message.message?.payload?.userId?.toString();
        if (payloadUserId === currentAdmin?.user.id) {
          navigate(ROUTES.signOut);
        }
      } else if (
        message.message?.content?.actionType ===
        MessageActionType.INVOICE_GENERATION
      ) {
        handleInvoiceGenerationMessage(message.message?.payload);
      } else if (
        payrolllBackgroundJob &&
        message.message?.content?.actionType ===
          MessageActionType.PAYROLL_GENERATION
      ) {
        handlePayrollGenerationMessage(message.message?.payload);
      } else {
        dispatch(setUnreadChats(true));
        ls.set(SHOW_RED_DOT_ON_CHATS, 'true');
        dispatch(setPubnubMessage(message));
      }
    } else {
      dispatch(setUnreadChats(true));
      ls.set(SHOW_RED_DOT_ON_CHATS, 'true');
      dispatch(setPubnubMessage(message));
    }
  };
  return <>{children}</>;
};

export default UserDefaultPubNubChannel;

import { ApolloError } from '@apollo/client';
import { SubmitHelpers } from '@area2k/use-form';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import ConfirmationModal from '@/components/ConfirmationModal';
import LtaWorkersList from '@/components/LtaWorkersList';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import WorkerAutocompleteField, {
  WorkerItem,
} from '@/components/WorkerAutocompleteField';
import Form from '@/form';
import { useJobHireWorkerMutation } from '@/graphql';
import useAuth from '@/hooks/useAuth';
import { JOB_HIRE_AND_OFFER_ERROR_MAP } from '@/routes/Agency/Order/InviteAndHireModal';
import { GetJobQuery, JobTypeEnum } from '@/types/graphql';
import { handleMutationFormError } from '@/util/error';
import { workerName } from '@/util/worker';

export type Props = {
  job: GetJobQuery['job'];
  hideModal: () => void;
};

type FormValues = {
  worker: WorkerItem | null;
};

const AddWorkerModal = ({ job, hideModal }: Props) => {
  const jobId = job.id;
  const initialValues: FormValues = {
    worker: null,
  };

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [rehireWorkerId, setRehireWorkerId] = useState('');

  const { currentAdminIsCustomerAdmin } = useAuth();

  const [error, setError] = useState<boolean>(false);
  const isLTAJob = job.jobType === JobTypeEnum.LTA;

  const [hireWorker, { loading: isLoading }] = useJobHireWorkerMutation();

  const handleSubmit = async (
    values: FormValues,
    { setFormError }: SubmitHelpers
  ): Promise<void> => {
    try {
      await hireWorker({
        variables: {
          jobId,
          workerId: values.worker!.id,
          payRate: job.payRate,
          forceHire: null,
        },
      });

      hideModal();
    } catch (err: any) {
      if (
        err?.graphQLErrors &&
        err.graphQLErrors[0]?.extensions?.code ===
          'WORKER_ALREADY_DROPPED_JOB' &&
        !currentAdminIsCustomerAdmin
      ) {
        setRehireWorkerId(values.worker!.id);
        setShowConfirmModal(true);
        return;
      }
      if (err instanceof ApolloError) {
        handleMutationFormError(
          err,
          {
            setFormError,
            errorMap: JOB_HIRE_AND_OFFER_ERROR_MAP,
          },
          workerName(values.worker)
        );
      } else {
        setError(true);
      }
    }
  };
  const handleRehire = async () => {
    try {
      await hireWorker({
        variables: {
          jobId,
          workerId: rehireWorkerId,
          payRate: job.payRate,
          forceHire: true,
        },
      });
      hideModal();
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <>
      <Modal
        disableClickout
        size="sm"
        title="Hire GravyWorker"
        onRequestClose={hideModal}
      >
        <Card.Section>
          {error && (
            <Alert
              icon={faExclamationTriangle}
              status="warning"
              title="GravyWorker not found"
            />
          )}
          <Form initialValues={initialValues} onSubmit={handleSubmit}>
            {isLTAJob ? (
              <LtaWorkersList
                address={job.address}
                customerId={job.account.customer.id}
                skillIds={[job.skill.id]}
              />
            ) : (
              <WorkerAutocompleteField
                context="default"
                fieldId="worker"
                jobId={jobId}
                jurisdiction={[job.address.state] as [string]}
                label="Select GravyWorker"
                skillsId={[job.skill.id]}
              />
            )}
            <Stack justify="end">
              <Button
                a11yLabel="Submit form"
                id="hire-worker-btn"
                isLoading={isLoading}
                label="Hire"
                type="submit"
              />
            </Stack>
          </Form>
        </Card.Section>
      </Modal>
      {showConfirmModal && (
        <ConfirmationModal
          acceptAction={handleRehire}
          acceptButtonLabel="Hire worker"
          bodyContentText={
            'This worker has previously dropped this job. Do you want to proceed?'
          }
          denyAction={() => {}}
          denyButtonLabel="Cancel"
          hideModal={() => setShowConfirmModal(false)}
          title={`Confirmation`}
        />
      )}
    </>
  );
};

export default AddWorkerModal;

import useModal from '@area2k/use-modal';
import { useFeatureValue } from '@growthbook/growthbook-react';

import AddressSection from './AddressSection';
import DefaultContactSection from './DefaultContactSection';
import MarkupSection from './MarkupSection';
import UpdateContactModal from './UpdateContactModal';

import Card from '@/components/Card';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { GetAccountQuery, AccountRateTypeEnum } from '@/types/graphql';

type Props = {
  account: GetAccountQuery['account'];
  phoneOnly?: boolean;
};

const ProfileCard = ({ account, phoneOnly = false }: Props) => {
  const [showCreateModal, hideCreateModal] = useModal(
    () => (
      <UpdateContactModal
        account={account}
        hideModal={hideCreateModal}
        rateType={createModalType}
      />
    ),
    [account]
  );

  const showMarkupBasedPricing = useFeatureValue(
    FEATURE_TOGGLE.MarkupBasedPricingFeature,
    false
  );
  const createModalType =
    showMarkupBasedPricing &&
    account?.markupPercent &&
    account?.rateType === AccountRateTypeEnum.MARKUP
      ? AccountRateTypeEnum.MARKUP
      : AccountRateTypeEnum.STATIC;

  const hasAddress =
    !!account.addressLine1 &&
    !!account.city &&
    !!account.state &&
    !!account.zip;

  return (
    <Card
      actions={[
        {
          a11yLabel: 'Change Contact',
          label: 'Change',
          onAction: showCreateModal,
          id: 'btn-change-contact',
        },
      ]}
      noRadius={phoneOnly}
      title="Default Contact"
    >
      <DefaultContactSection account={account} />
      {hasAddress && <AddressSection account={account} />}
      {showMarkupBasedPricing &&
      account?.markupPercent &&
      account?.rateType === 'MARKUP' ? (
        <MarkupSection markupValue={account?.markupPercent} />
      ) : null}
    </Card>
  );
};

export default ProfileCard;

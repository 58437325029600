import { createSvgIcon } from '@mui/material';

const UnApproveIcon = createSvgIcon(
  <svg
    fill="none"
    height="18"
    viewBox="0 0 20 18"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0C6.93913 0 5.92172 0.421427 5.17157 1.17157C4.42143 1.92172 4 2.93913 4 4C4 5.06087 4.42143 6.07828 5.17157 6.82843C5.92172 7.57857 6.93913 8 8 8C9.06087 8 10.0783 7.57857 10.8284 6.82843C11.5786 6.07828 12 5.06087 12 4C12 2.93913 11.5786 1.92172 10.8284 1.17157C10.0783 0.421427 9.06087 0 8 0ZM8 2C8.53043 2 9.03914 2.21071 9.41421 2.58579C9.78929 2.96086 10 3.46957 10 4C10 4.53043 9.78929 5.03914 9.41421 5.41421C9.03914 5.78929 8.53043 6 8 6C7.46957 6 6.96086 5.78929 6.58579 5.41421C6.21071 5.03914 6 4.53043 6 4C6 3.46957 6.21071 2.96086 6.58579 2.58579C6.96086 2.21071 7.46957 2 8 2ZM8 9C5.33 9 0 10.33 0 13V16H9.5C9.24833 15.394 9.08987 14.7534 9.03 14.1H1.9V13C1.9 12.36 5.03 10.9 8 10.9C8.5 10.9 9 10.95 9.5 11.03C9.7566 10.3985 10.1109 9.81136 10.55 9.29C9.61 9.1 8.71 9 8 9ZM15.5 9C13 9 11 11 11 13.5C11 16 13 18 15.5 18C18 18 20 16 20 13.5C20 11 18 9 15.5 9ZM15.5 10.5C17.16 10.5 18.5 11.84 18.5 13.5C18.5 14.06 18.35 14.58 18.08 15L14 10.92C14.42 10.65 14.94 10.5 15.5 10.5ZM12.92 12L17 16.08C16.58 16.35 16.06 16.5 15.5 16.5C13.84 16.5 12.5 15.16 12.5 13.5C12.5 12.94 12.65 12.42 12.92 12Z"
      fill="#7A7A7A"
    />
  </svg>,
  'UnApproveIcon'
);
export default UnApproveIcon;

export const DATE_FORMAT = 'MM-dd-yyyy';
export const HUMAN_DATE_FORMAT = 'LLLL dd, y';

export const DATETIME_FORMAT = 'MM-dd-yyyy hh:mm a';
export const HUMAN_DATETIME_FORMAT = "LLL dd, yy'' hh:mm a";

export const DAY_FORMAT = 'eee, MMM do';
export const DAY_FORMAT_ORDER_SUBMISSION = 'iii MMM d';
export const TIME_FORMAT = 'h:mm a';
export const EVENT_TIME_FORMAT = 'h:mmaaa';
export const EVENT_DATE_FORMAT = 'dd MMM yyyy';

export const PLANE_DATE = 'yyyy-MM-dd';
export const PLANE_DATETIME = 'yyyy-MM-dd HH:mm:ss';
export const PLANE_TIME = 'HH:mm';

import styled from '@/styles';

const ButtonGroup = styled('div', {
  display: 'flex',
  flexWrap: 'nowrap',
});

ButtonGroup.displayName = 'stitches(ButtonGroup)';

export default ButtonGroup;

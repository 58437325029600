import styled from '@/styles';

export const AgencyWrapper = styled('div', {
  padding: 20.5,
  width: '100%',

  borderBottom: '1px solid $neutralLightest',
});

export const AgencyAvatar = styled('div', {
  alignItems: 'center',
  boxSize: 36,
  display: 'flex',
  justifyContent: 'center',

  backgroundColor: '$neutralLighter',
  borderRadius: '$lg',
});

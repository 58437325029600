import styled from '@/styles';

export const Container = styled('div', {
  width: '100%',
  padding: '0.75em 0.75em 0.75em 1.5em',
  borderColor: '$neutralLight',
  borderWidth: 1,
  borderStyle: 'solid',
  variants: {
    rounded: {
      true: {
        borderRadius: '$round',
      },
      false: {
        borderRadius: '$lg',
      },
      none: {
        borderRadius: 0,
      },
    },
    rowBorder: {
      true: {},
      false: {
        borderLeft: 0,
        borderRight: 0,
      },
    },
  },
  '@phoneOnly': {
    padding: '0.75em',
  },

  defaultVariants: {
    rounded: false,
  },
});

export const Title = styled('legend', {
  padding: '0px 5px',
  color: '$textDefault',

  fontSize: '$md',
  fontWeight: '$normal',
  lineHeight: 1.5,
});

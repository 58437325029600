import { useReactiveVar } from '@apollo/client';

import { activeRegionStatesVar } from '@/util/apollo/cache';
import { allowedStates } from '@/util/constants';

const useGetValidStates = () => {
  const regionStates = useReactiveVar(activeRegionStatesVar);
  const validStates = regionStates || allowedStates;
  return validStates;
};

export default useGetValidStates;

import { logo as defaultLogo } from '@/assets/img';
import styled from '@/styles';

export type Props = {
  src?: string;
  alt?: string;
};

export const Image = styled('img', { width: '250px' });

const GravyWorkLogo = ({
  src = defaultLogo,
  alt = 'Gravywork logo',
}: Props) => <Image alt={alt} src={src} />;

export default GravyWorkLogo;

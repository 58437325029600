import { useCallback, useEffect, useState } from 'react';

import Option from '@/components/Option';
import Stack from '@/components/Stack';
import { FilterProps } from '@/filters';
import { JobFilterSetInput } from '@/types/graphql';

type Props = FilterProps<JobFilterSetInput> & {
  items: any[];
  filterKey: keyof JobFilterSetInput;
};

const CheckBoxFilterGroup = ({
  filters,
  items,
  filterKey,
  onChangeFilter,
  onClearFilter,
}: Props) => {
  const [selected, setSelected] = useState<any[]>(filters[filterKey] || []);

  const toggleSelected = useCallback(
    (value) => {
      if (selected?.includes(value)) {
        setSelected(selected.filter((item) => item !== value));
      } else {
        setSelected([...selected, value]);
      }
    },
    [selected]
  );

  useEffect(() => {
    if (selected.length > 0) {
      onChangeFilter(filterKey, selected);
    } else {
      onClearFilter(filterKey);
    }
  }, [selected]);

  return (
    <Stack gap={20} wrap={true} id={filterKey}>
      {items.map((item, index) => (
        <Option
          key={`${filterKey}-${index}`}
          appearance="checkbox"
          checked={selected?.includes(item.value)}
          id={`${index}-${filterKey}`}
          label={item.label}
          type="checkbox"
          value={item.value}
          onChange={(ev) => toggleSelected(ev.target.value)}
        />
      ))}
    </Stack>
  );
};

export default CheckBoxFilterGroup;

import { useReactiveVar } from '@apollo/client';
import { FieldCallback } from '@area2k/use-form';
import { useEffect } from 'react';

import ItemSelectField from '@/form/ItemSelectField';
import { useListAgencyUniformsLazyQuery } from '@/graphql';
import { ListAgencyUniformsQuery } from '@/types/graphql';
import { currentAgencyVar } from '@/util/apollo/cache';

export type UniformItem = ListAgencyUniformsQuery['agency']['uniforms'][0];

type Props = {
  fieldId: string;
  label: string;
  callback?: FieldCallback<UniformItem | null>;
};

const UniformSelectField = ({ fieldId, label, callback }: Props) => {
  const currentAgency = useReactiveVar(currentAgencyVar);

  const [fetch, { data, loading }] = useListAgencyUniformsLazyQuery();

  useEffect(() => {
    fetch({ variables: { agencyId: currentAgency!.id } });
  }, []);

  return (
    <ItemSelectField<UniformItem>
      required
      callback={callback}
      disabled={loading}
      fieldId={fieldId}
      itemToKey={(item) => item.id}
      itemToString={(item) => (item ? item.name : '')}
      items={data ? data.agency.uniforms : []}
      label={label}
    />
  );
};

export default UniformSelectField;

import colors from '@/styles/colors';

export const removeHtmlTags = (str: string) => {
  if (!str) return '';
  return str.replace(/(<([^>]+)>)/gi, '')?.replace(/&nbsp;/g, ' ');
};

export const isValidUSZip = (zip = '') => {
  return /^\d{5}(-\d{4})?$/.test(zip);
};
export const getAnnouncementTitle = (grp: any) => {
  const name =
    grp.name !== 'Announcement' ? grp.name : formatDate(grp.createdAt);
  return 'Workers: ' + name;
};

export const formatDate = (dateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const formattedDate = new Date(dateString).toLocaleDateString(
    'en-US',
    options
  );
  return formattedDate;
};

export const InputStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: colors.neutralLight,
    },
    '&:hover fieldset': {
      borderColor: colors.neutralDark,
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.themeLight,
    },
  },
};

export const getHeight = (length: number, phoneView: boolean) => {
  if (length === 0) return phoneView ? '82%' : '75%';
  else if (length === 2 || length === 1) return phoneView ? '75%' : '69%';
  else return phoneView ? '70%' : '65%';
};

export const showReadDotIcon = (
  channelId,
  timeToken,
  lastReadTimeTokens,
  activeConversation,
  setLastReadTimeTokens
) => {
  if (lastReadTimeTokens && lastReadTimeTokens[channelId]) {
    if (
      activeConversation === channelId &&
      Number(timeToken) > Number(lastReadTimeTokens[channelId])
    ) {
      setLastReadTimeTokens({
        ...lastReadTimeTokens,
        [`${channelId}`]: timeToken,
      });
      return false;
    }
    return Number(timeToken) > Number(lastReadTimeTokens[channelId]);
  } else if (lastReadTimeTokens) {
    return !!timeToken;
  } else {
    return false;
  }
};

export const checkUnreadAnnouncements = (
  announcementGroup,
  lastReadTimeTokens,
  activeConversation,
  setLastReadTimeTokens
) => {
  if (announcementGroup) {
    for (let a = 0; a < announcementGroup?.length; a++) {
      if (
        showReadDotIcon(
          announcementGroup[a].id,
          announcementGroup[a].lastMessageTime,
          lastReadTimeTokens,
          activeConversation,
          setLastReadTimeTokens
        )
      ) {
        return true;
      }
    }
  }
  return false;
};

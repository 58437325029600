import { useCallback, useEffect, useMemo, useState } from 'react';

import Autocomplete from '@/components/Autocomplete';
import Stack from '@/components/Stack';
import TagList from '@/components/TagList';
import { FilterProps } from '@/filters';
import { useListAgencySkillsQuery } from '@/graphql';
import useAuth from '@/hooks/useAuth';
import useDebouncedValue from '@/hooks/useDebouncedValue';
import { JobFilterSetInput, ListAgencySkillsQuery } from '@/types/graphql';
import { sortBy } from '@/util/array';

type Item = ListAgencySkillsQuery['agency']['skillCategories'][0]['skills'][0];

const SelectableSkillFilter = ({
  filters,
  filterKey,
  onChangeFilter,
  onClearFilter,
}: FilterProps<JobFilterSetInput>) => {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebouncedValue(query);
  const { currentAgency } = useAuth();

  const [skillsSelected, setSelectedSkill] = useState<Item[] | []>([]);

  const { data } = useListAgencySkillsQuery({
    variables: { agencyId: currentAgency!.id },
    onCompleted: (data) => {
      const skills = data.agency.skillCategories
        .flatMap((category) => category.skills)
        .filter((skill) => {
          return skill.name
            .toLowerCase()
            .includes(debouncedQuery.toLowerCase());
        });

      setSelectedSkill(
        skills.filter((skill) => filters.skillIds?.includes(skill.id))
      );
    },
  });

  const items: Item[] = useMemo(() => {
    if (data) {
      const skills = data.agency.skillCategories
        .flatMap((category) => category.skills)
        .filter((skill) => {
          if (skillsSelected.length > 0 && skillsSelected?.includes(skill)) {
            return false;
          }

          return skill.name
            .toLowerCase()
            .includes(debouncedQuery.toLowerCase());
        });

      return sortBy(skills, 'name');
    }

    return [];
  }, [data, debouncedQuery, skillsSelected]);

  const handleSelect = useCallback(
    (item) => {
      if (skillsSelected.length < 1) {
        return setSelectedSkill([item.selectedItem]);
      }
      return setSelectedSkill([...skillsSelected, item.selectedItem]);
    },
    [skillsSelected]
  );

  const handleRemove = useCallback(
    (index) => {
      const newSkillsSelected = skillsSelected.filter(
        (_, skillIndex) => skillIndex !== index
      );

      setSelectedSkill(newSkillsSelected);
    },
    [skillsSelected]
  );

  useEffect(() => {
    if (skillsSelected.length > 0) {
      onChangeFilter(
        filterKey,
        skillsSelected.map((skill) => skill.id)
      );
    } else {
      onClearFilter(filterKey);
    }
  }, [skillsSelected]);

  return (
    <div style={{ width: '80%' }}>
      <Autocomplete
        fixedSelectHeight="lg"
        id="skills-autocomplete"
        itemToKey={(item) => item.id}
        itemToString={(item) => (item ? item.name : '')}
        items={items}
        placeholder="Search for skills..."
        selectedItem={null}
        onInputValueChange={({ inputValue }) => setQuery(inputValue || '')}
        onSelectedItemChange={handleSelect}
      />

      {skillsSelected.length > 0 && (
        <Stack
          css={{
            paddingTop: '0.5rem',
          }}
        >
          <TagList
            tags={skillsSelected.map((skill) => skill.name)}
            onRemove={handleRemove}
          />
        </Stack>
      )}
    </div>
  );
};

export default SelectableSkillFilter;

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

interface visitorIdentification {
  token: string;
  email: string;
}
interface HubspotConversationsContextType {
  initChat: (visitorIdentification: visitorIdentification) => void;
  isReady: boolean;

  unreadMessagesCount: number;
}

const HubspotConversationsContext =
  createContext<HubspotConversationsContextType | null>(null);

const HUBSPOT_INLINE_EMBED_ELEMENT_ID =
  'hubspot-conversations-inline-embed-selector';

export const HubspotConversationsProvider = ({ children }) => {
  const [isReady, setIsReady] = useState(false);

  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  // This is for Visitor Identification API

  const initChat = useCallback(
    (visitorIdentification: visitorIdentification) => {
      if (!isReady) return;
      window.hsConversationsSettings = {
        ...window.hsConversationsSettings,
        ...(visitorIdentification.token && {
          identificationEmail: visitorIdentification.email,
          identificationToken: visitorIdentification.token,
        }),
      };
      window.HubSpotConversations.widget.load();
      window.HubSpotConversations.widget.open();
    },
    [isReady]
  );

  const onConversationsReady = useCallback(() => {
    setIsReady(true);
  }, []);

  useEffect(
    function init() {
      if (window.HubSpotConversations) {
        onConversationsReady();
      } else {
        window.hsConversationsOnReady = [onConversationsReady];
      }
    },
    [onConversationsReady]
  );

  useEffect(
    function addEventListeners() {
      if (!isReady) return;

      function listener(payload: { unreadCount: number }) {
        setUnreadMessagesCount(payload.unreadCount);
      }

      window.HubSpotConversations.on(
        'unreadConversationCountChanged',
        listener
      );

      return () => {
        window.HubSpotConversations.off(
          'unreadConversationCountChanged',
          listener
        );
      };
    },
    [isReady]
  );

  useEffect(
    function refreshConversationsOnRouteChange() {
      if (!isReady) return;
      window.HubSpotConversations.resetAndReloadWidget();
    },
    [isReady]
  );

  return (
    <HubspotConversationsContext.Provider
      value={{
        initChat,
        unreadMessagesCount,
        isReady,
      }}
    >
      {children}
      <div
        className={'chatWidgetContainer'}
        id={HUBSPOT_INLINE_EMBED_ELEMENT_ID}
      />
    </HubspotConversationsContext.Provider>
  );
};

export function useHubspotConversations() {
  const context = useContext(HubspotConversationsContext);

  if (context === null) {
    throw new Error(
      'useHubspotConversations must be used within HubspotConversationsProvider'
    );
  }

  return context;
}

declare global {
  interface Window {
    hsConversationsSettings: Record<string, any>;
    hsConversationsOnReady: Array<() => void>;
    HubSpotConversations: {
      on: any;
      off: any;
      resetAndReloadWidget: () => void;
      widget: {
        status: () => { loaded: boolean; pending: boolean };
        load: (params?: { widgetOpen: boolean }) => void;
        remove: () => void;
        open: () => void;
        close: () => void;
        refresh: (openToNewThread?: boolean) => void;
      };
    };
  }
}

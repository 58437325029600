import useModal from '@area2k/use-modal';

import UpdateShiftModal from './UpdateShiftDate';

import styled from '@/styles';
import { GetJobQuery } from '@/types/graphql';

type Props = {
  job: GetJobQuery['job'];
  limitOfHoursOnSelectedDays: any;
};

const EditScedule = styled('div', {
  height: '21px',
  color: '#45a735',
  fontWeight: 400,
  cursor: 'pointer',
  fontSize: '16px',
  paddingLeft: '15px',
});

const ShiftsCard = ({ job, limitOfHoursOnSelectedDays }: Props) => {
  const [showEditModal, hideEditModal] = useModal(
    () => (
      <UpdateShiftModal
        hideModal={hideEditModal}
        job={job}
        limitOfHours={limitOfHoursOnSelectedDays}
      />
    ),
    [job]
  );

  return <EditScedule onClick={showEditModal}>Edit</EditScedule>;
};

export default ShiftsCard;

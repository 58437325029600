import { SubmitHelpers } from '@area2k/use-form';
import { useCallback, useState } from 'react';

import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import Form from '@/form';
import TextAreaField from '@/form/TextAreaField';
import TextField from '@/form/TextField';
import {
  GetInvoiceDetailsDocument,
  useEditInvoiceDetailMutation,
} from '@/graphql';
import { Maybe } from '@/types';
import { GetInvoiceDetailsQuery } from '@/types/graphql';
import { parseTimestamp } from '@/util/date';
import { handleMutationFormError } from '@/util/error';

type FormValues = {
  dueDate: string;
  description: Maybe<string>;
};

type Props = {
  invoice: GetInvoiceDetailsQuery['listStripeInvoiceDetail']['invoice'];
  hideModal: () => void;
};

const EditInvoiceDetail = ({ invoice, hideModal }: Props) => {
  const [invoiceValues, setInvoiceValues] = useState<FormValues>({
    dueDate: parseTimestamp(invoice.dueDate!),
    description: invoice.description,
  });

  const [editInvoiceDetail, { loading: isLoading }] =
    useEditInvoiceDetailMutation({
      refetchQueries: [
        {
          query: GetInvoiceDetailsDocument,
          variables: { invoiceId: invoice.id },
        },
      ],
    });

  const handleInvoiceValues = ({ value }, id) => {
    setInvoiceValues({ ...invoiceValues, [id]: value });
  };

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        await editInvoiceDetail({
          variables: {
            invoiceId: invoice.id,
            ...invoiceValues,
          },
        });

        hideModal();
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
          errorMap: {
            all: (gqlError) => ({
              title: gqlError.name,
              message: gqlError.message,
              status: 'danger',
            }),
          },
        });
      }
    },
    [invoiceValues]
  );

  return (
    <Modal
      disableClickout
      size="sm"
      title="Edit Details"
      wrapperBackground={true}
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Form initialValues={invoiceValues} onSubmit={handleSubmit}>
          <TextField
            autoFocus
            required
            callback={(fieldContext) => {
              handleInvoiceValues(fieldContext, 'dueDate');
            }}
            fieldId="dueDate"
            label="Due Date"
            type="date"
          />

          <TextAreaField
            required
            callback={(fieldContext) => {
              handleInvoiceValues(fieldContext, 'description');
            }}
            fieldId="description"
            label="Description"
          />

          <Stack>
            <Button
              a11yLabel="Submit form"
              isLoading={isLoading}
              label="Save"
              type="submit"
              style={{ width: '100%' }}
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  );
};

export default EditInvoiceDetail;

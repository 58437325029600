import { parseISO } from 'date-fns';

import FormElement from '@/components/FormElement';
import Input from '@/elements/Input';
import FormColumns from '@/form/FormColumns';
import styled from '@/styles';

const Wrapper = styled('div', {
  '& *': {
    boxSizing: 'content-box',
    position: 'unset',
  },

  '& .rdrNextPrevButton': {
    backgroundColor: 'white',
  },
});

Wrapper.displayName = 'stitches(DateRangePicker.Wrapper)';

export type DateRange = {
  startDate: Date | null;
  endDate: Date | null;
};

const dateParser = (date: Date | string) => {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return date.toISOString().split('T')[0];
};

export type DateContext = 'startDate' | 'endDate';

type Props = {
  maxDate?: string;
  minDate?: string;
  startDate: string | undefined;
  endDate: string | undefined;
  onChange: (selection: any, context: DateContext) => void;
  fromLabel?: string;
  toLabel?: string;
};

const DateRangeInput = ({
  minDate,
  maxDate,
  onChange,
  startDate,
  endDate,
  fromLabel = 'Start Date',
  toLabel = 'End Date',
}: Props) => (
  <FormColumns style={{ width: '100%' }}>
    <FormElement label={fromLabel}>
      <Input
        data-testid="start-date"
        id="start-date"
        max={maxDate}
        min={minDate}
        type="date"
        value={startDate ? dateParser(startDate) : ''}
        onChange={(e) => {
          onChange(parseISO(e.target.value), 'startDate');
        }}
      />
    </FormElement>
    <FormElement label={toLabel}>
      <Input
        data-testid="end-date"
        id="end-date"
        max={maxDate}
        min={minDate}
        type="date"
        value={endDate ? dateParser(endDate) : ''}
        onChange={(e) => {
          onChange(parseISO(e.target.value), 'endDate');
        }}
      />
    </FormElement>
  </FormColumns>
);

export default DateRangeInput;

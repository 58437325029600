import { FieldContext, SubmitHelpers } from '@area2k/use-form';
import { useCallback, useMemo, useState } from 'react';

import { SubmitErrorAlert } from '@/components/Alerts';
import Button from '@/components/Button';
import Card from '@/components/Card';
import FormElement from '@/components/FormElement';
import MaskedInput from '@/components/MaskedInput';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { Small } from '@/components/Typography';
import ZipAutocomplete from '@/components/ZipAutocomplete';
import { TIME_TO_REFRESH } from '@/constants/general';
import { MIN_PAY, MAX_PAY, COST_FACTOR } from '@/constants/rates';
import Input from '@/elements/Input';
import Form from '@/form';
import FormColumns from '@/form/FormColumns';
import ItemSelectField from '@/form/ItemSelectField';
import {
  useCustomRateCreateBatchMutation,
  useListAgencySkillsQuery,
} from '@/graphql';
import useAuth from '@/hooks/useAuth';
import { Maybe } from '@/types';
import {
  AccountRateTypeEnum,
  GetCustomerQuery,
  ListAgencySkillsQuery,
} from '@/types/graphql';
import { getUniqueAddressesByZipAndState } from '@/util/address';
import { handleMutationFormError } from '@/util/error';
import { centsToCurrency, getPreciseNumber } from '@/util/number';

type Props = {
  customer: GetCustomerQuery['customer'];
  hideModal: () => void;
};

type SkillItem =
  ListAgencySkillsQuery['agency']['skillCategories'][0]['skills'][0];
type AddressItem = GetCustomerQuery['customer']['addresses'][0];
type AccountItem = GetCustomerQuery['customer']['accounts'][0];
type FormValues = {
  skill: Maybe<SkillItem>;
  address: Maybe<AddressItem>;
  account: Maybe<AccountItem>;
  payRate: number;
  billingRate: number;
};

const AddCustomerRateModal = ({ customer, hideModal }: Props) => {
  const [payRatePristine, setPayRatePristine] = useState(true);
  const { currentAgency } = useAuth();
  const activeAddresses = useMemo(
    () => customer.addresses.filter((address) => address.active),
    []
  );
  const noActiveAddresses = activeAddresses.length === 0;
  const { data: agencyData } = useListAgencySkillsQuery({
    variables: { agencyId: currentAgency!.id },
    pollInterval: TIME_TO_REFRESH,
  });
  // TODO: remove deprecated mutation
  // const [createRate, { loading: createRateIsLoading }] = useCreateRateMutation({
  //   update: (cache) => {
  //     cache.modify({
  //       id: cache.identify(customer),
  //       fields: { addresses() {} },
  //     });
  //   },
  // });
  const [
    customRateCreateBatch,
    {
      loading: customRateCreateBatchIsLoading,
      data: customRateCreateBatchData,
    },
  ] = useCustomRateCreateBatchMutation();

  const skillCategories = agencyData ? agencyData.agency.skillCategories : [];
  const skillItems: SkillItem[] = skillCategories.flatMap(
    (category) => category.skills
  );

  const [formValues, setFormValues] = useState<FormValues>({
    skill: null,
    account: null,
    address: null,
    payRate: 0,
    billingRate: 0,
  });

  const [payRateInput, setPayRateInput] = useState(
    centsToCurrency(formValues.payRate)
  );
  const [billRateInput, setBillRateInput] = useState(
    centsToCurrency(formValues.billingRate)
  );

  const handleFormValuesChange = <T extends any>(
    fieldContext: FieldContext<T>,
    fieldId: keyof FormValues
  ) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldId]: fieldContext.value,
    }));
  };

  const handlePayRateChange = useCallback((payRateInput: string) => {
    setPayRateInput(payRateInput);
    const payRate = payRateInput
      ? getPreciseNumber(parseFloat(payRateInput.replace(/,/g, '')) * 100)
      : 0;
    setBillRateInput(centsToCurrency(Number(payRate) * COST_FACTOR));
    setFormValues((prevValues) => ({ ...prevValues, payRate }));
  }, []);

  const handleBillRateChange = useCallback((billRateInput: string) => {
    setBillRateInput(billRateInput);
    const billingRate = billRateInput
      ? parseFloat(billRateInput.replace(/,/g, '')) * 100
      : 0;

    setFormValues((prevValues) => ({ ...prevValues, billingRate }));
  }, []);

  const handleSubmit = useCallback(
    async (_, { setFormError }: SubmitHelpers) => {
      const { skill, account, address, billingRate, payRate } = formValues;

      // TODO: remove deprecated mutation
      //   createRate({
      //     variables: { ...commonVariables, addressId: address!.id },
      //   })
      //     .then(hideModal)
      //     .catch((error) =>
      //       handleMutationFormError(error, {
      //         setFormError,
      //         errorMap: {
      //           ADDRESS_INACTIVE: (error) => ({
      //             title: error.message,
      //             message: error.message,
      //           }),
      //           DUPLICATE_RATE: (error) => ({
      //             title: error.message,
      //             message:
      //               'There is already a custom rate associated with this skill, address and account.',
      //           }),
      //         },
      //       })
      //     );
      //   return;

      customRateCreateBatch({
        variables: {
          accountId: account!.id,
          agencyId: currentAgency!.id,
          baseCost: billingRate,
          basePay: payRate,
          skillId: skill!.id,
          state: address?.state || '',
          zipCode: address?.zip || '',
        },
      })
        .then(({ data }) => {
          if (data?.customRateCreateBatch.allCreated) {
            return hideModal();
          }
        })
        .catch((error) =>
          handleMutationFormError(error, {
            setFormError,
            errorMap: {
              NO_ACTIVE_ADDRESSES: (error) => ({
                title: error.message,
                message: error.message,
              }),
            },
          })
        );
    },
    [formValues]
  );

  return (
    <Modal
      disableClickout
      size="md"
      title={'Add custom rate'}
      onRequestClose={hideModal}
    >
      {!customRateCreateBatchData?.customRateCreateBatch.allCreated &&
      customRateCreateBatchData?.customRateCreateBatch.message ? (
        <Card.Section>
          <SubmitErrorAlert
            description={
              customRateCreateBatchData.customRateCreateBatch.message
            }
            title="Duplicated Custom Rates"
          />
          <Stack justify="end">
            <Button a11yLabel="Ok" label="Ok" onClick={hideModal} />
          </Stack>
        </Card.Section>
      ) : (
        <Card.Section>
          <Form initialValues={formValues} onSubmit={handleSubmit}>
            <ItemSelectField<SkillItem>
              required
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'skill');
              }}
              fieldId="skill"
              itemToKey={(item) => item.id}
              itemToString={(item) => (item ? `${item.name}` : '')}
              items={skillItems}
              label="Job Skill*"
              placeholder="Select a skill..."
            />

            <ItemSelectField<AccountItem>
              required
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'account');
              }}
              fieldId="account"
              itemToKey={(item) => item.id}
              itemToString={(item) => (item ? `${item.name}` : '')}
              items={customer.accounts.filter(
                (acc) => acc.rateType === AccountRateTypeEnum.STATIC
              )}
              label="Account*"
              placeholder="Select an account..."
            />

            <ZipAutocomplete
              addresses={getUniqueAddressesByZipAndState(activeAddresses)}
              disabled={noActiveAddresses}
              placeholder="Enter a zip code..."
              selectedItem={formValues.address}
              onSelectedItemChange={({ selectedItem }) => {
                setFormValues((prevValues) => ({
                  ...prevValues,
                  address: selectedItem ?? null,
                }));
              }}
            />

            <FormColumns layout="double">
              <FormElement htmlFor="custom-rate-city" label="City">
                <Input
                  disabled
                  id="custom-rate-city"
                  placeholder="Enter a zip code first..."
                  value={formValues.address?.city ?? ''}
                />
              </FormElement>

              <FormElement htmlFor="custom-rate-state" label="State">
                <Input
                  disabled
                  id="custom-rate-state"
                  placeholder="Enter a zip code first..."
                  value={formValues.address?.state ?? ''}
                />
              </FormElement>
            </FormColumns>

            <FormColumns layout="double">
              <FormElement htmlFor="pay-rate-input" label="Pay rate*">
                <MaskedInput
                  normalizeZeros
                  padFractionalZeros
                  id="pay-rate-input"
                  mask={Number}
                  radix="."
                  scale={2}
                  signed={false}
                  thousandsSeparator=","
                  value={payRateInput}
                  onAccept={(value) => handlePayRateChange(value)}
                  onBlur={() => setPayRatePristine(false)}
                />
                {!payRatePristine && formValues.payRate < MIN_PAY && (
                  <Small color="danger">
                    Invalid pay rate amount. The minimum possible amount is $
                    {MIN_PAY / 100}
                  </Small>
                )}
                {formValues.payRate >= MAX_PAY && (
                  <Small color="danger">
                    Invalid pay rate amount. The maximum possible amount is $
                    {MAX_PAY / 100 - 0.01}
                  </Small>
                )}
                {formValues.billingRate < formValues.payRate && (
                  <>
                    <br />
                    <Small color="danger">
                      Pay rate cannot be greater than Bill rate.
                    </Small>
                  </>
                )}
              </FormElement>
              <FormElement htmlFor="bill-rate-input" label="Bill rate*">
                <MaskedInput
                  normalizeZeros
                  padFractionalZeros
                  id="bill-rate-input"
                  mask={Number}
                  radix="."
                  scale={2}
                  signed={false}
                  thousandsSeparator=","
                  value={billRateInput}
                  onAccept={(value) => handleBillRateChange(value)}
                />
              </FormElement>
            </FormColumns>

            <Stack justify="end">
              <Button
                a11yLabel="Submit form"
                disabled={
                  !formValues.skill ||
                  !formValues.account ||
                  !formValues.address ||
                  formValues.payRate < MIN_PAY ||
                  formValues.payRate >= MAX_PAY ||
                  formValues.billingRate < formValues.payRate ||
                  noActiveAddresses
                }
                id="save-rates-btn"
                isLoading={customRateCreateBatchIsLoading}
                label="Save"
                type="submit"
              />
            </Stack>
          </Form>
        </Card.Section>
      )}
    </Modal>
  );
};

export default AddCustomerRateModal;

import { useState, useEffect } from 'react';

import { useOrderState } from '../../../context';

import AddressSection from './AddressSection';
import CreateContactForm from './CreateContactForm';
import SkillCategorySelectField from './SkillCategorySelectField';
import SkillSelectField from './SkillSelectField';

import { OnChangeFirstStepForm, FirstStepFormValues } from './';

import LoadingState from '@/components/LoadingState';
import Stack from '@/components/Stack';
import { TIME_TO_REFRESH } from '@/constants/general';
import {
  useListSkillCategoriesByCustomRatesQuery,
  useListSkillCategoriesByDefaultRatesQuery,
} from '@/graphql';
import useMediaQuery from '@/hooks/useMediaQuery';
import { replaceAtIndex } from '@/util/array';

const groupBy = (items: any[]) =>
  items.reduce((result, currentValue) => {
    if (currentValue !== undefined) {
      const existCategory = result.find(
        (item) => item.name === currentValue.name
      );

      if (!existCategory) {
        const { skill, ...rest } = currentValue;
        result.push({ ...rest, skills: [{ item: skill }] });
      } else {
        const categoryIndex = result.findIndex(
          (item) => item.id === currentValue.id
        );
        result[categoryIndex] = {
          ...result[categoryIndex],
          skills: [
            ...result[categoryIndex].skills,
            { item: currentValue.skill },
          ],
        };
      }
    }
    return result;
  }, []);

interface Props {
  formValues: FirstStepFormValues;
  onChange: OnChangeFirstStepForm;
  continueButtonIsDisabled: boolean;
}

const SkillSelection = ({
  formValues,
  onChange,
  continueButtonIsDisabled,
}: Props) => {
  const { billing } = useOrderState();
  const phoneOnly = useMediaQuery('(max-width: 559px)');

  const [options, setOptions] = useState<any[]>([]);
  const {
    data: categoriesByCustomRates,
    loading: categoriesByCustomRatesLoading,
  } = useListSkillCategoriesByCustomRatesQuery({
    variables: { customerId: billing!.customer.id },
    pollInterval: TIME_TO_REFRESH,
  });
  const {
    data: categoriesByDefaultRates,
    loading: categoriesByDefaultRatesLoading,
  } = useListSkillCategoriesByDefaultRatesQuery({
    pollInterval: TIME_TO_REFRESH,
  });
  const isLoading =
    categoriesByDefaultRatesLoading || categoriesByCustomRatesLoading;

  useEffect(() => {
    if (categoriesByCustomRates && categoriesByDefaultRates) {
      const customRatesBySelectedAccount = categoriesByCustomRates
        ? categoriesByCustomRates.customer.addresses.map((address) => {
            if (address.rateQuotes) {
              const filteredRateQuotesByAccount = address.rateQuotes.filter(
                (rateQuote) => rateQuote.account!.id === billing!.account.id
              );

              return { ...address, rateQuotes: filteredRateQuotesByAccount };
            }

            return address;
          })
        : [];

      const allCustomRatesBySkillCategories =
        customRatesBySelectedAccount.flatMap((address) => {
          if (address.rateQuotes) {
            return address.rateQuotes.map((rateQuote) => ({
              ...rateQuote.skill!.category,
              skill: rateQuote.skill,
            }));
          }

          return [];
        });
      const allCategories =
        categoriesByDefaultRates &&
        // TODO: we have to see what should be the desired behaviour for this error.
        // eslint-disable-next-line array-callback-return
        categoriesByDefaultRates?.defaultRates.flatMap((skill) => {
          if (skill.skill) {
            return { ...skill.skill!.category, skill: skill.skill };
          }
        });

      const groupSkills = groupBy(allCategories);

      let skillCategories: any[] = [];

      allCustomRatesBySkillCategories.forEach((skillCategoryItem) => {
        const skillCategoryExist = skillCategories.find(
          (item) => item.id === skillCategoryItem.id
        );

        if (skillCategoryExist === undefined) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { skill, ...rest } = skillCategoryItem;
          skillCategories.push({ ...rest, skills: [skillCategoryItem.skill] });
        }

        const categoryIndex = skillCategories.findIndex(
          (item) => item.id === skillCategoryItem.id
        );

        skillCategories = replaceAtIndex(skillCategories, categoryIndex, 1, {
          ...skillCategories[categoryIndex],
          skills: Array.from(
            new Set([
              ...skillCategories[categoryIndex].skills,
              skillCategoryItem.skill,
            ])
          ),
        });
      });

      const mappedSkillCategories = skillCategories.map((item) => ({
        item: { ...item, skills: item.skills.map((item) => ({ item })) },
      }));

      const mappedSkill = groupSkills.map((item) => ({
        item: { ...item, skills: item.skills },
      }));

      const skillsOptions =
        mappedSkillCategories.length >= 1 ? mappedSkillCategories : mappedSkill;

      setOptions(skillsOptions);
    }
  }, [categoriesByCustomRates, categoriesByDefaultRates]);

  if (isLoading) {
    return (
      <Stack align="center" justify="center">
        <LoadingState overlayColor="white" text="Loading skills categories" />
      </Stack>
    );
  }

  const getContactForm = (hasAddressSelected) => {
    if (phoneOnly) {
      if (hasAddressSelected) {
        return <CreateContactForm onChange={onChange} />;
      } else {
        return <></>;
      }
    } else {
      return <CreateContactForm onChange={onChange} />;
    }
  };

  return (
    <Stack vertical align="center" gap={24}>
      <SkillCategorySelectField
        callback={(fieldContext) => onChange(fieldContext, 'skillCategory')}
        options={options}
      />
      <SkillSelectField
        callback={(fieldContext) => onChange(fieldContext, 'skill')}
      />
      <AddressSection
        address={formValues.address}
        contact={formValues.contact}
        continueButtonIsDisabled={continueButtonIsDisabled}
        hasInstructions={formValues.addressInstructions !== ''}
        skill={formValues.skill}
        onChange={(context, id) => {
          onChange(context, id);
        }}
      />
      {getContactForm(
        formValues.address?.addressLine1 &&
          formValues.address?.addressLine1.length > 0
      )}
    </Stack>
  );
};

export default SkillSelection;

import { ReactNode, useCallback, useEffect, useState } from 'react';

import Autocomplete, { Props as AutocompleteProps } from '../Autocomplete';
import FormElement from '../FormElement';

import { DEBOUNCE_TIME } from '@/constants/general';
import { US_STATES } from '@/constants/states';
import useDebouncedValue from '@/hooks/useDebouncedValue';

type USState = typeof US_STATES[0];
type Props = Pick<
  AutocompleteProps<USState>,
  'selectedItem' | 'onSelectedItemChange'
> & {
  children?: ReactNode;
};

const StatesAutocomplete = ({
  selectedItem,
  onSelectedItemChange,
  children,
}: Props) => {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebouncedValue(query, DEBOUNCE_TIME);
  const [items, setItems] = useState(US_STATES);

  const handleOnInputValueChange = useCallback(
    ({ inputValue }) => setQuery(inputValue || ''),
    []
  );

  useEffect(() => {
    const filteredStates = US_STATES.filter(
      (state) =>
        !debouncedQuery ||
        state.toLowerCase().includes(debouncedQuery) ||
        state.toUpperCase().includes(debouncedQuery)
    );
    setItems(filteredStates);
  }, [debouncedQuery]);

  return (
    <FormElement htmlFor="state" label="State">
      <Autocomplete<string>
        id="state"
        itemToKey={(item) => item}
        itemToString={(item) => item || ''}
        items={items}
        placeholder="State"
        selectedItem={selectedItem}
        onInputValueChange={handleOnInputValueChange}
        onSelectedItemChange={onSelectedItemChange}
      />
      {children}
    </FormElement>
  );
};

export default StatesAutocomplete;

import { FieldCallback, useField } from '@area2k/use-form';
import { ComponentProps } from 'react';

import FormElement from '@/components/FormElement';
import Option from '@/components/Option';

type OptionProps = Omit<
  ComponentProps<typeof Option>,
  'id' | 'value' | 'onChange'
>;

type Props = OptionProps & {
  fieldId: string;
  label?: string;
  callback?: FieldCallback<boolean>;
};

const OptionField = ({
  fieldId,
  label,
  callback = () => {},
  ...props
}: Props) => {
  const { value, setValue } = useField<boolean>(fieldId, callback);

  return (
    <FormElement>
      <Option
        type="checkbox"
        {...props}
        checked={value}
        id={fieldId}
        label={label}
        name={fieldId}
        onChange={(ev) => setValue(ev.currentTarget.checked)}
      />
    </FormElement>
  );
};

export default OptionField;

import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useState, useEffect } from 'react';

import axiosClient from '@/util/axios/axiosClient';

const useAxios = (axiosParams: AxiosRequestConfig, callApiOnMount = true) => {
  const [response, setResponse] = useState<AxiosResponse>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(callApiOnMount);

  const fetchData = async (params?: AxiosRequestConfig) => {
    try {
      const result = await axiosClient.request({ ...axiosParams, ...params });
      setResponse(result);
      setLoading(false);
      error && setError(undefined);
    } catch (err: any) {
      setError(err?.response || err);
      setLoading(false);
      response && setResponse(undefined);
    }
  };

  const callApi = (params?: AxiosRequestConfig) => {
    setLoading(true);
    fetchData(params);
  };

  useEffect(() => {
    callApiOnMount && fetchData(axiosParams);
  }, []);

  return { data: response, error, loading, callApi };
};

export default useAxios;

import { useErrors } from '@area2k/use-form';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import Alert from '@/components/Alert';
import FormElement from '@/components/FormElement';
import { FormError } from '@/types';

const DEFAULT_ICON = faExclamationTriangle;

const FormErrorAlerts = () => {
  const formErrors = useErrors();
  const keys = Object.keys(formErrors);

  if (keys.length === 0) return null;

  return (
    <>
      {keys.map((key) => {
        const { icon, message, status, title } = formErrors[key] as FormError;

        return (
          <FormElement key={key}>
            <Alert
              description={message}
              icon={icon || DEFAULT_ICON}
              status={status || 'warning'}
              title={title}
            />
          </FormElement>
        );
      })}
    </>
  );
};

export default FormErrorAlerts;

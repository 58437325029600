import { FieldCallback, useField } from '@area2k/use-form';
import { useEffect } from 'react';

import FormElement from '@/components/FormElement';
import ItemSelect, { Props as ItemSelectProps } from '@/components/ItemSelect';
import { Scalars, Maybe } from '@/types/graphql';

type InputProps<T> = Omit<
  ItemSelectProps<T>,
  'id' | 'selectedItem' | 'onSelectedItemChange'
>;

type Props<T> = InputProps<T> & {
  fieldId: Scalars['String'];
  label?: Scalars['String'];
  callback?: FieldCallback<Maybe<T>>;
  selectedItem?: Maybe<T>;
};

const ItemSelectField = <T extends object>({
  fieldId,
  label,
  callback,
  selectedItem,
  ...props
}: Props<T>) => {
  const { value, setValue } = useField<Maybe<T>>(fieldId, callback);

  useEffect(() => {
    if (selectedItem) {
      setValue(selectedItem);
    }
  }, [selectedItem, props?.items]);

  return (
    <FormElement htmlFor={`${fieldId}-toggle-button`} label={label}>
      <ItemSelect
        {...props}
        id={fieldId}
        selectedItem={value}
        onSelectedItemChange={({ selectedItem }) =>
          setValue(selectedItem || null)
        }
      />
    </FormElement>
  );
};

export default ItemSelectField;

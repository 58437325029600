import { CircularProgress, Tooltip } from '@mui/material';
import { useMemo } from 'react';

import Avatar from '@/components/Avatar';
import StyledChip from '@/components/Chip';
import EmptyState from '@/components/EmptyState';
import Stack from '@/components/Stack';
import Table from '@/components/Table';
import TableHeader from '@/components/TableHeader';
import { Subheading } from '@/components/Typography';
import { TableCell, TableRow } from '@/elements/Table';
import { useGetJobWorkersQuery } from '@/graphql';
import { jobColors } from '@/styles/colors';
import {
  JobWorkerCheckInStatusEnum as CheckInStatusEnum,
  JobStatusEnum,
  JobWorkerStatusEnum,
  ListJobsForMonitorQuery,
} from '@/types/graphql';
import { formatISO } from '@/util/date';

const generalStyle = { padding: '5px 16px', color: 'black' };
const HEADER_FIELDS = [
  { name: 'NAME', style: generalStyle },
  { name: 'PHONE NUMBER', style: generalStyle },
  { name: 'EMAIL', style: generalStyle },
  { name: 'CHECKED IN', style: generalStyle },
  { name: 'CHECKED OUT', style: generalStyle },
  { name: 'STATUS', style: generalStyle },
];

interface Props {
  sideLineColor?: string;
  job: ListJobsForMonitorQuery['agency']['shiftsByDay'][0]['job'];
}
const FILTER_WORKER_STATUS = [
  JobWorkerStatusEnum.DISMISSED,
  JobWorkerStatusEnum.DROPPED,
];
const STATUS_ORDER = Object.values([
  CheckInStatusEnum.NO_SHOW,
  CheckInStatusEnum.NOT_CHECKED_IN,
  CheckInStatusEnum.CHECKED_IN,
  CheckInStatusEnum.CHECKED_OUT,
]);

const WorkerDetailsTable = ({ sideLineColor, job }: Props) => {
  const { data, loading } = useGetJobWorkersQuery({
    variables: { jobId: job.id },
    fetchPolicy: 'network-only',
  });

  const filterWorkers = useMemo(() => {
    if (data?.job?.jobWorkers) {
      const filterWorkers = data.job.jobWorkers.filter(
        (w) => !FILTER_WORKER_STATUS.includes(w.status),
      );
      filterWorkers.sort(
        (a, b) =>
          STATUS_ORDER.indexOf(a.checkInStatus) -
          STATUS_ORDER.indexOf(b.checkInStatus),
      );
      return filterWorkers;
    } else return [];
  }, [data?.job]);

  const getStatusChip = (status: CheckInStatusEnum) => {
    switch (status) {
      case CheckInStatusEnum.NOT_CHECKED_IN:
        return (
          <StyledChip
            label="Not Checked In"
            style={{ backgroundColor: '#F9F1DF', color: '#F5AA00' }}
          />
        );
      case CheckInStatusEnum.NO_SHOW:
        return <StyledChip color="error" label="No Show" />;

      case CheckInStatusEnum.CHECKED_IN:
        return (
          <StyledChip
            label="Checked In"
            style={{ backgroundColor: '#D7F2D3', color: '#44A735' }}
          />
        );
      case CheckInStatusEnum.CHECKED_OUT:
        return <StyledChip color="primary" label="Checked Out" />;
    }
  };

  return (
    <div
      style={{
        backgroundColor: 'white',
        border: sideLineColor ? `1px solid ${sideLineColor}` : '',
        height: 'auto',
        maxHeight: '762px',
        overflowY: 'auto',
      }}
    >
      <Subheading
        css={{ marginLeft: '20px', paddingTop: '15px', paddingBottom: '15px' }}
        weight={'semibold'}
      >
        Worker Details
        {loading && (
          <CircularProgress
            color="inherit"
            size={20}
            style={{ marginLeft: 10 }}
          />
        )}
      </Subheading>
      <Table>
        <TableHeader
          fields={HEADER_FIELDS}
          headerBgColor={jobColors.headerLight}
        />
        <tbody>
          {filterWorkers?.map((jobWorker) => {
            const user = jobWorker.worker?.user;
            const checkIn = jobWorker?.timesheets[0]?.checkinAt;
            const checkout = jobWorker?.timesheets[0]?.checkoutAt;
            const reportedCheckin =
              jobWorker?.timesheets[0]?.reportedCheckinAt ||
              jobWorker?.timesheets[0]?.approvedCheckinAt;
            const reportedCheckout =
              jobWorker?.timesheets[0]?.reportedCheckoutAt ||
              jobWorker?.timesheets[0]?.approvedCheckoutAt;
            return (
              <TableRow
                key={jobWorker.worker?.id}
                clickable
                id={`row-${jobWorker.worker?.id}`}
                style={{ backgroundColor: 'white' }}
                onClick={() => {
                  window.open(`/workers/${jobWorker.worker?.id}`);
                }}
              >
                <TableCell>
                  <Stack>
                    <Avatar size="sm" src={jobWorker.worker?.avatarUrl} />
                    {user?.firstName} {user?.lastName}
                  </Stack>
                </TableCell>
                <TableCell>{user?.phoneNumber}</TableCell>
                <TableCell>{user?.email}</TableCell>

                <TableCell>
                  {checkIn ? (
                    <Tooltip title="System Generated">
                      <div>{formatISO(checkIn, 'p', job.address.timezone)}</div>
                    </Tooltip>
                  ) : reportedCheckin ? (
                    <Tooltip title="Admin Generated">
                      <div>
                        {formatISO(reportedCheckin, 'p', job.address.timezone)}
                      </div>
                    </Tooltip>
                  ) : (
                    '-'
                  )}
                </TableCell>

                <TableCell>
                  {checkout ? (
                    <Tooltip title="System Generated">
                      <div>
                        {formatISO(checkout, 'p', job.address.timezone)}
                      </div>
                    </Tooltip>
                  ) : reportedCheckout ? (
                    <Tooltip title="Admin Generated">
                      <div>
                        {formatISO(reportedCheckout, 'p', job.address.timezone)}
                      </div>
                    </Tooltip>
                  ) : (
                    '-'
                  )}
                </TableCell>
                <TableCell>
                  {job.status === JobStatusEnum.UPCOMING &&
                  jobWorker.checkInStatus !== CheckInStatusEnum.CHECKED_IN
                    ? '-'
                    : getStatusChip(jobWorker.checkInStatus)}
                </TableCell>
              </TableRow>
            );
          })}
        </tbody>
      </Table>
      {!filterWorkers.length && !loading && (
        <EmptyState text="Invite an employee to this job." title="No workers" />
      )}
    </div>
  );
};

export default WorkerDetailsTable;

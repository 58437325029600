import { TimesheetInvoiceStatusEnum } from '@/types/graphql';

export const invoiceStatusOptions = [
  {
    label: 'All',
    value: TimesheetInvoiceStatusEnum.ALL,
  },
  {
    label: 'Draft',
    value: TimesheetInvoiceStatusEnum.DRAFT,
  },
  {
    label: 'Not Invoiced',
    value: TimesheetInvoiceStatusEnum.NOT_INVOICED,
  },
];

export const HEADER_FIELDS = [
  {
    name: 'Service Date',
    style: { minWidth: '120px', width: '10%', color: 'black' },
  },
  {
    name: 'Order #',
    style: { minWidth: '80px', width: '8%', color: 'black' },
  },
  {
    name: 'Address',
    style: { minWidth: '150px', width: '15%', color: 'black' },
  },
  {
    name: 'Worker',
    style: { minWidth: '100px', width: '10%', color: 'black' },
  },
  {
    name: 'Description',
    style: { minWidth: '100px', width: '12%', color: 'black' },
  },
  {
    name: 'Net Time',
    style: { minWidth: '100px', width: '8%', color: 'black' },
  },
  {
    name: 'Bill Rates',
    style: { minWidth: '80px', width: '8%', color: 'black' },
  },
  {
    name: 'Tips',
    style: { minWidth: '70px', width: '8%', color: 'black' },
  },
  {
    name: 'Invoice ID',
    style: { minWidth: '90px', width: '9%', color: 'black' },
  },
  {
    name: 'Status',
    style: { minWidth: '150px', width: '15%', color: 'black' },
  },
];

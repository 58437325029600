import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import Alert from '../Alert';

interface Props {
  rate?: number;
  style?: Record<string, any>;
}
const HolidayInfoAlert = ({ rate = 1.5, style }: Props) => {
  const getRate = () => {
    return Math.round((rate - 1) * 100);
  };
  return (
    <Alert
      css={style}
      description={`This shift occurs on a holiday.`}
      icon={faInfoCircle}
      status="info"
      title={`Holidays incur a ${getRate()}% rate increase`}
    />
  );
};

export default HolidayInfoAlert;

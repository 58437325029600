import { useReactiveVar } from '@apollo/client';
import { SetStateAction, useState } from 'react';

import { SubmitErrorAlert } from '@/components/Alerts';
import BillingWeekFormField from '@/components/BillingWeekFormField';
import Button from '@/components/Button';
import Card from '@/components/Card';
import GroupInvoicesFormField from '@/components/GroupInvoicesFormField';
import InvoiceDurationFormField from '@/components/InvoiceDurationFormField';
import Modal from '@/components/Modal';
import Option from '@/components/Option';
import Stack from '@/components/Stack';
import TooltipInfo from '@/components/TooltipInfo';
import {
  CONSOLIDATE_CHARGES,
  SEPARATE_INVOICE_BY_MONTH,
  SEPARATE_INVOICE_HINT,
  SWEEP_HINT,
  UNKNOWN_ERROR_TEXT,
} from '@/constants/text';
import { useUpdateAccountCutOffMutation } from '@/graphql';
import { Role } from '@/routes/PrivateRoute';
import {
  BillingWeekEnum,
  GetAccountQuery,
  InvoiceCutOffEnum,
  InvoiceGroupByEnum,
  Scalars,
} from '@/types/graphql';
import { currentAdminVar } from '@/util/apollo/cache';

interface ChageInvoiceDurationModalProps {
  account: GetAccountQuery['account'];
  onClose: () => Scalars['Void'];
}

const ChangeInvoiceDurationModal = ({
  account,
  onClose,
}: ChageInvoiceDurationModalProps) => {
  const [
    updateAccountCutOff,
    { loading: updateAccountCutOffIsLoading, error: updateAccountCutOffError },
  ] = useUpdateAccountCutOffMutation({
    onCompleted: onClose,
    update: (cache) => {
      cache.modify({
        id: cache.identify(account),
        fields: {
          cutOff() {},
          billingWeek() {},
          monthlyCutOff() {},
          consolidateUnprocessedCharges() {},
          groupBy() {},
        },
      });
    },
  });
  const [seperateInvoices, setSeperateInvoices] = useState(
    account.monthlyCutOff
  );
  const [consolidateCharges, setConsolidateCharges] = useState(
    account.consolidateUnprocessedCharges
  );
  const [groupBy, setGroupBy] = useState(
    account.groupBy || InvoiceGroupByEnum.NONE
  );

  const currentAdmin = useReactiveVar(currentAdminVar);
  const isTenantAdmin = currentAdmin?.role === Role.TENANT_ADMIN;

  const [invoiceDuration, setInvoiceDuration] = useState<
    GetAccountQuery['account']['cutOff']
  >(account.cutOff);
  const isDurationWeekly = invoiceDuration === InvoiceCutOffEnum.WEEKLY;

  const [billingWeek, selectedBillingWeek] = useState<
    GetAccountQuery['account']['billingWeek']
  >(account.billingWeek);
  const handleOnChangeBillingWeek = (
    billingWeek: SetStateAction<GetAccountQuery['account']['billingWeek']>
  ) => {
    selectedBillingWeek(billingWeek);
  };

  const handleOnChangeInvoiceDuration = (
    invoiceDuration: SetStateAction<GetAccountQuery['account']['cutOff']>
  ) => {
    setInvoiceDuration(invoiceDuration);
    if (invoiceDuration !== InvoiceCutOffEnum.WEEKLY) {
      // set default values
      setGroupBy(InvoiceGroupByEnum.NONE);
      setConsolidateCharges(true);
      selectedBillingWeek(BillingWeekEnum.MONDAY);
    }
  };

  const handleOnSaveInvoiceDuration = () => {
    updateAccountCutOff({
      variables: {
        accountId: account.id,
        invoiceCutOff: invoiceDuration,
        monthlyCutOff: seperateInvoices,
        billingWeek,
        consolidateUnprocessedCharges: consolidateCharges,
        groupBy,
      },
    });
  };

  return (
    <Modal size="xs" title="Invoice Settings" onRequestClose={onClose}>
      <Card>
        <Card.Section>
          {updateAccountCutOffError && (
            <SubmitErrorAlert description={UNKNOWN_ERROR_TEXT} />
          )}
          <InvoiceDurationFormField
            invoiceDuration={invoiceDuration}
            onChangeInvoiceDuration={handleOnChangeInvoiceDuration}
          />
          {isTenantAdmin && isDurationWeekly && (
            <>
              <BillingWeekFormField
                billingWeek={billingWeek}
                onChangeBillingWeek={handleOnChangeBillingWeek}
              />
              <GroupInvoicesFormField
                groupBy={groupBy}
                onChangeGroupBy={(val) => setGroupBy(val)}
              />
            </>
          )}

          <Stack vertical gap={10}>
            {isDurationWeekly && isTenantAdmin && (
              <Option
                appearance="checkbox"
                checked={consolidateCharges}
                id="consolidate-charges"
                label={
                  <>
                    <span style={{ marginRight: '10px' }}>
                      {CONSOLIDATE_CHARGES}
                    </span>
                    <TooltipInfo text={SWEEP_HINT} />
                  </>
                }
                onChange={(val) => setConsolidateCharges(val.target.checked)}
              />
            )}
            <Option
              appearance="checkbox"
              checked={seperateInvoices}
              id="invoice-seperate-month"
              label={
                <>
                  <span style={{ marginRight: '10px' }}>
                    {SEPARATE_INVOICE_BY_MONTH}
                  </span>
                  <TooltipInfo text={SEPARATE_INVOICE_HINT} />
                </>
              }
              onChange={(val) => setSeperateInvoices(val.target.checked)}
            />
          </Stack>

          <Stack justify="end">
            <Button
              a11yLabel="Cancel"
              appearance="outline"
              label="Cancel"
              onClick={onClose}
            />
            <Button
              a11yLabel="Save Invoice Duration"
              isLoading={updateAccountCutOffIsLoading}
              label="Save"
              onClick={handleOnSaveInvoiceDuration}
            />
          </Stack>
        </Card.Section>
      </Card>
    </Modal>
  );
};

export default ChangeInvoiceDurationModal;

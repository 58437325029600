import { useEffect } from 'react';

import Button from '@/components/Button';
import Option from '@/components/Option';
import Stack from '@/components/Stack';
import {
  JobWorkerStatusFilterEnumType,
  JobWorkerStatusFilterEnum,
} from '@/constants/job';

type option = {
  label: string;
  value: string;
};

interface Props {
  idPrefix: string;
  onChangeFilter: Function;
  onClearFilter: Function;
  setJobStatusValue: Function;
  options: option[];
  jobStatusValue: JobWorkerStatusFilterEnumType;
}

const JobStatusFilter = ({
  idPrefix,
  onChangeFilter,
  onClearFilter,
  jobStatusValue,
  setJobStatusValue,
  options,
}: Props) => {
  const handleChange = (value: string) => () => {
    setJobStatusValue(value);
  };
  const handleClearFilter = () => {
    setJobStatusValue(JobWorkerStatusFilterEnum.ALL);
  };

  useEffect(() => {
    if (jobStatusValue === JobWorkerStatusFilterEnum.ALL) {
      return onClearFilter('status');
    }
    onChangeFilter('status', { value: jobStatusValue });
  }, [jobStatusValue]);

  return (
    <Stack vertical gap={16}>
      {options.map((option, index) => (
        <Option
          key={`options-status-${index}`}
          appearance="bullseye"
          checked={jobStatusValue === option.value}
          id={`${idPrefix}_orderStatus-${option.value}`}
          label={option.label}
          name={`${idPrefix}_orderStatus`}
          type="radio"
          value={option.value}
          onChange={handleChange(option.value)}
        />
      ))}
      {jobStatusValue !== JobWorkerStatusFilterEnum.ALL && (
        <Button
          a11yLabel="Clear order status filter"
          appearance="plain"
          label="Clear"
          onClick={handleClearFilter}
        />
      )}
    </Stack>
  );
};

export default JobStatusFilter;

import { PayrollItem } from '../../PayrollDetail';

import Card from '@/components/Card';
import Table from '@/components/Table';
import TableHeader from '@/components/TableHeader';
import { TableCell, TableRow } from '@/elements/Table';
import { PostTaxDeduction } from '@/types/graphql';

const deductionsHeaderFields = ['Description', 'Type', 'Amount'];

type Props = {
  deductions: PayrollItem['deductions'];
};
const ItemDeductionsCard = ({ deductions }: Props) => {
  const renderDeductions = (deduction: PostTaxDeduction) => (
    <TableRow key={deduction.checkPtdId}>
      <TableCell style={{ width: '45%' }}>{deduction.description}</TableCell>
      <TableCell style={{ width: '30%' }}>{deduction.ptdType} </TableCell>
      <TableCell>{deduction.amount}</TableCell>
    </TableRow>
  );
  return (
    <Card title="Deductions">
      <Table>
        <TableHeader fields={deductionsHeaderFields} />
        <tbody>{deductions!.map(renderDeductions)}</tbody>
      </Table>
    </Card>
  );
};

export default ItemDeductionsCard;

import { createSvgIcon } from '@mui/material';

const CheckoutMiles = createSvgIcon(
  <svg
    fill="none"
    height="20"
    viewBox="0 0 14 20"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0C10.86 0 14 3.14 14 7C14 12.25 7 20 7 20C7 20 0 12.25 0 7C0 3.14 3.14 0 7 0ZM5.47 12L12 5.41L10.6 4L5.47 9.18L3.4 7.09L2 8.5L5.47 12Z"
      fill="#44A735"
    />
  </svg>,
  'CheckoutMiles'
);
export default CheckoutMiles;

import { useReactiveVar } from '@apollo/client';
import useModal from '@area2k/use-modal';
import React, { useMemo, useState, useEffect, useCallback } from 'react';

import {
  DEFAULT_CLIENT_TABS,
  RenderBody,
  TOGGLE_ADDED_CLIENT_TABS,
} from '../Workers/helpers';

import ClientTableHeader from './ClientTableHeader';
import CreateClientModal from './CreateCustomerModal';
import CustomerFilters from './CustomerFilters';

import Card from '@/components/Card';
import LoadingState from '@/components/LoadingState';
import Page from '@/components/Page';
import Paginator from '@/components/Paginator/Paginator';
import Table from '@/components/Table';
import { setRegions } from '@/constants/filters';
import { TIME_TO_REFRESH } from '@/constants/general';
import { useListCustomersLazyQuery, useRegionLazyQuery } from '@/graphql';
import useFilters from '@/hooks/useFilters';
import useMediaQuery from '@/hooks/useMediaQuery';
import { jobColors } from '@/styles/colors';
import {
  CustomerFilterSetInput,
  CustomerSortKeyEnum,
  Scalars,
} from '@/types/graphql';
import { currentAgencyVar } from '@/util/apollo/cache';

export type SortByFilter = {
  sort: CustomerSortKeyEnum;
  reverse: Scalars['Boolean'];
};

const initialSortBy: SortByFilter = {
  sort: CustomerSortKeyEnum.STATUS,
  reverse: false,
};

const initialFilters: CustomerFilterSetInput = {};

const Clients = () => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const {
    debouncedQuery,
    filters,
    query: searchQuery,
    clearAll,
    setFilter,
    setQuery,
  } = useFilters<CustomerFilterSetInput>(initialFilters);

  const finalFilters = useMemo<CustomerFilterSetInput>(() => {
    const value = { ...filters };

    if (value.status && value.status.length) {
      value.status = value.status.map(Number);
    }

    if (value.createdAt && (!value.createdAt.from || !value.createdAt.to)) {
      value.createdAt = undefined;
    }

    if (debouncedQuery !== '') {
      value.query = { value: debouncedQuery };
    }

    return value;
  }, [debouncedQuery, filters]);

  const currentAgency = useReactiveVar(currentAgencyVar);
  const [sortBy, setSortBy] = useState<SortByFilter>(initialSortBy);

  const [handleCustomerList, query] = useListCustomersLazyQuery({
    fetchPolicy: 'network-only',
    pollInterval: TIME_TO_REFRESH,
  });

  const [getRegions] = useRegionLazyQuery({
    onCompleted: (data) => {
      if (data) {
        setRegions(data.region);
      }
    },
  });

  const sorting = (value: SortByFilter['sort']) => {
    setSortBy((prevValue) => ({
      sort: value,
      reverse: prevValue.sort === value ? !prevValue.reverse : true,
    }));
  };

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleNumberItemsChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setCurrentPage(1);
    setItemsPerPage(parseInt(event.target.value));
  };

  const [showNewClientModal, handleNewClientModal] = useModal(() => (
    <CreateClientModal hideModal={handleNewClientModal} />
  ));

  useEffect(() => {
    if (searchQuery !== '') {
      setCurrentPage(1);
    }
  }, [searchQuery]);

  useEffect(() => {
    getRegions({
      variables: {
        agencyId: currentAgency!.id,
      },
    });
  }, []);

  const clientHeaders = [...DEFAULT_CLIENT_TABS, ...TOGGLE_ADDED_CLIENT_TABS];

  useEffect(() => {
    handleCustomerList({
      variables: {
        agencyId: currentAgency!.id,
        filters: finalFilters,
        page: currentPage,
        perPage: itemsPerPage,
        sortKey: sortBy.sort,
        reverse: sortBy.reverse,
      },
    });
  }, [finalFilters, sortBy, currentPage, itemsPerPage]);

  const refreshQuery = useCallback(() => {
    handleCustomerList({
      variables: {
        agencyId: currentAgency!.id,
        filters: finalFilters,
        page: currentPage,
        perPage: itemsPerPage,
        sortKey: sortBy.sort,
        reverse: sortBy.reverse,
      },
    });
  }, [sortBy, finalFilters, currentPage, itemsPerPage]);

  return (
    <Page
      footer={
        query.data &&
        query.data.agency.customers.items.length !== 0 && (
          <Paginator
            currentPage={currentPage}
            handleNumberItemsChange={handleNumberItemsChange}
            handlePageChange={handlePageChange}
            itemsLength={query.data?.agency.customers.pageInfo.totalItems}
            itemsPerPage={itemsPerPage}
          />
        )
      }
      headerPadding={!!phoneOnly}
      noPadding={!!phoneOnly}
      primaryAction={{
        a11yLabel: 'Create a client',
        label: 'New client',
        id: 'new-client-btn',
        onAction: showNewClientModal,
      }}
      size={phoneOnly ? 'full' : 'md'}
      title="Clients"
    >
      <Card noRadius={phoneOnly}>
        <Card.Section>
          <CustomerFilters
            filters={filters}
            query={searchQuery}
            onChangeFilter={setFilter}
            onClearAll={clearAll}
            onClearFilter={() => null}
            onQueryChange={setQuery}
          />
        </Card.Section>
        {query.loading ? (
          <LoadingState />
        ) : (
          <Table>
            {!phoneOnly && (
              <ClientTableHeader
                fields={clientHeaders}
                headerBgColor={jobColors.nightCharcoal}
                sortBy={sortBy}
                sorting={sorting}
              />
            )}
            <RenderBody
              items={query.data?.agency.customers.items || []}
              refreshQuery={refreshQuery}
              tab={4}
            />
          </Table>
        )}
      </Card>
    </Page>
  );
};

export default Clients;

import { createSvgIcon } from '@mui/material';

const HeartFillIcon = createSvgIcon(
  <svg
    fill="none"
    height="23"
    viewBox="0 0 23 23"
    width="23"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_14934_11030)">
      <path
        d="M19.214 4.39938C18.7458 3.93097 18.1899 3.55939 17.578 3.30588C16.9662 3.05236 16.3104 2.92187 15.6481 2.92188C14.9858 2.92187 14.3301 3.05236 13.7182 3.30588C13.1064 3.55939 12.5505 3.93097 12.0823 4.39938L11.1106 5.37105L10.139 4.39938C9.19324 3.45366 7.91057 2.92237 6.57312 2.92237C5.23568 2.92237 3.95301 3.45366 3.00729 4.39938C2.06157 5.3451 1.53027 6.62777 1.53027 7.96522C1.53027 9.30266 2.06157 10.5853 3.00729 11.531L3.97896 12.5027L11.1106 19.6344L18.2423 12.5027L19.214 11.531C19.6824 11.0629 20.0539 10.507 20.3075 9.89512C20.561 9.28329 20.6915 8.6275 20.6915 7.96522C20.6915 7.30294 20.561 6.64714 20.3075 6.03531C20.0539 5.42347 19.6824 4.86757 19.214 4.39938Z"
        fill="#44A735"
        stroke="#45A735"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.44444"
      />
    </g>
    <defs>
      <clipPath id="clip0_14934_11030">
        <rect
          fill="white"
          height="22"
          transform="translate(0.111328 0.171875)"
          width="22"
        />
      </clipPath>
    </defs>
  </svg>,
  'HeartFillIcon'
);
export default HeartFillIcon;

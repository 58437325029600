import { createSvgIcon } from '@mui/material';

const GenerateInvoice = createSvgIcon(
  <svg
    fill="none"
    height="19"
    viewBox="0 0 20 19"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 17L10.3 17.86C10.1 17.28 10 16.65 10 16C10 15.76 10 15.5 10.04 15.29L9 14.6L6 16.6L3 14.6L2 15.26V2H16V10C16.7 10 17.37 10.12 18 10.34V0H0V19L3 17L6 19L9 17ZM14 6V4H4V6H14ZM12 10V8H4V10H12ZM12.5 16L15.25 19L20 14.23L18.84 12.82L15.25 16.41L13.66 14.82L12.5 16Z"
      fill="#44A735"
    />
  </svg>,
  'HomeIcon'
);
export default GenerateInvoice;

import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { parseISO } from 'date-fns';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import InvoiceBadge from '../../Invoices/InvoiceBadge';

import Card from '@/components/Card';
import EmptyState from '@/components/EmptyState';
import IconicButton from '@/components/IconicButton';
import LoadingState from '@/components/LoadingState';
import Paginator from '@/components/Paginator/Paginator';
import Stack from '@/components/Stack';
import Table from '@/components/Table';
import { TIME_TO_REFRESH } from '@/constants/general';
import { TableCell, TableHead, TableHeading, TableRow } from '@/elements/Table';
import { useGetCustomerInvoiceQuery } from '@/graphql';
import useMediaQuery from '@/hooks/useMediaQuery';
import routes from '@/routes/routes';
import { GetCustomerQuery, StripeInvoiceStatusEnum } from '@/types/graphql';
import { parseTimestamp } from '@/util/date';
import { formatDateSimple } from '@/util/datetime';
import { centsToCurrency } from '@/util/number';

type Props = {
  customer: GetCustomerQuery['customer'];
  accordion?: boolean;
};

const InvoiceList = ({ customer, accordion = false }: Props) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const navigate = useNavigate();

  const query = useGetCustomerInvoiceQuery({
    pollInterval: TIME_TO_REFRESH,
    variables: {
      customerId: customer.id,
      page: currentPage,
      perPage: itemsPerPage,
    },
  });
  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber);
  const handleNumberItemsChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setCurrentPage(1);
    setItemsPerPage(parseInt(event.target.value));
  };

  const invoices =
    !query.loading && query.data ? query.data.customer?.invoices?.items : [];
  const pageInfo =
    !query.loading && query.data
      ? query.data.customer?.invoices?.pageInfo
      : { totalItems: undefined };
  return (
    <Card accordion={accordion} title="Invoices">
      <Table>
        {!phoneOnly && (
          <TableHead>
            <TableHeading>Invoice ID</TableHeading>
            <TableHeading>Billing Account</TableHeading>
            <TableHeading>Amount</TableHeading>
            <TableHeading>Created on</TableHeading>
            <TableHeading>Due</TableHeading>
            <TableHeading align="center">Invoice Status</TableHeading>
            <TableHeading align="center">Action</TableHeading>
          </TableHead>
        )}
        <tbody>
          {invoices.map((invoice) => {
            const total = centsToCurrency(Number(invoice.total));
            const formattedCreatedDate = formatDateSimple(
              parseISO(invoice.createdDatetime)
            );
            const dueDate = parseTimestamp(invoice.dueDate ?? '');
            const formattedDueDate = formatDateSimple(parseISO(dueDate));
            const invoiceIsDraft =
              invoice.status === StripeInvoiceStatusEnum.DRAFT;

            return (
              <TableRow
                key={`inv-${invoice.id}`}
                clickable
                id={`inv-${invoice.id}`}
                mobile={phoneOnly}
                onClick={() =>
                  navigate(`../../${routes.invoices}/${invoice.id}`, {
                    state: { from: `../../${routes.clients}/${customer.id}` },
                  })
                }
              >
                <TableCell data-celltitle={'Invoice ID: '} stack={phoneOnly}>
                  <Stack justify={'apart'}>
                    <Stack>
                      {invoice.id}
                      {invoice.number ? `-${invoice.number}` : ''}
                    </Stack>
                    {phoneOnly && (
                      <IconicButton
                        a11yLabel="action"
                        appearance="clear"
                        css={{ fontSize: '18px' }}
                        disabled={invoiceIsDraft}
                        icon={faFileAlt}
                        iconTheme={'theme'}
                        id={`download-${invoice.id}`}
                        size={'xs'}
                        onClick={(ev) => {
                          ev.stopPropagation();
                          navigate(
                            `../../${routes.invoices}/${invoice.id}/pay`
                          );
                        }}
                      />
                    )}
                  </Stack>
                </TableCell>
                <TableCell
                  data-celltitle={'Billing Account: '}
                  stack={phoneOnly}
                >
                  {invoice.account?.name}
                </TableCell>
                <TableCell data-celltitle={'Amount: '} stack={phoneOnly}>
                  {total} USD
                </TableCell>
                <TableCell data-celltitle={'Created on: '} stack={phoneOnly}>
                  {formattedCreatedDate}
                </TableCell>
                <TableCell data-celltitle={'Due: '} stack={phoneOnly}>
                  {invoice.dueDateDatetime && formattedDueDate}
                </TableCell>
                <TableCell
                  align="center"
                  data-celltitle={'Invoice Status: '}
                  noCellTitle={true}
                  stack={phoneOnly}
                >
                  <InvoiceBadge status={invoice.status} />
                </TableCell>
                {!phoneOnly && (
                  <TableCell
                    align="center"
                    stack={phoneOnly}
                    onClick={(e) => {
                      invoiceIsDraft && e.stopPropagation();
                    }}
                  >
                    <IconicButton
                      a11yLabel="action"
                      appearance="clear"
                      disabled={invoiceIsDraft}
                      icon={faFileAlt}
                      id={`download-${invoice.id}`}
                      onClick={(ev) => {
                        ev.stopPropagation();
                        navigate(`../../${routes.invoices}/${invoice.id}/pay`);
                      }}
                    />
                  </TableCell>
                )}
              </TableRow>
            );
          })}
          {query.loading && (
            <TableRow>
              <TableCell align="center" colSpan={7}>
                <LoadingState overlayColor="white" />
              </TableCell>
            </TableRow>
          )}
        </tbody>
      </Table>
      {!query.loading && invoices.length === 0 && (
        <EmptyState icon={faFileAlt} title="No Invoices were found" />
      )}
      <Card.Section>
        {invoices.length > 0 && (
          <Paginator
            currentPage={currentPage}
            handleNumberItemsChange={handleNumberItemsChange}
            handlePageChange={handlePageChange}
            itemOptions={[10, 20, 50]}
            itemsLength={pageInfo?.totalItems}
            itemsPerPage={itemsPerPage}
          />
        )}
      </Card.Section>
    </Card>
  );
};

export default InvoiceList;

import {
  faCcAmex,
  faCcDinersClub,
  faCcMastercard,
  faCcVisa,
} from '@fortawesome/free-brands-svg-icons';

import { CardPaymentMethod } from '@/components/PaymentMethod';

export const getMappedPaymentMethod = (paymentMethod: CardPaymentMethod) => {
  return {
    amex: {
      icon: faCcAmex,
      name: 'American Express',
    },
    diners_club: {
      icon: faCcDinersClub,
      name: 'Diners Club',
    },
    mastercard: {
      icon: faCcMastercard,
      name: 'Mastercard',
    },
    visa: { icon: faCcVisa, name: 'Visa' },
  }[paymentMethod.card.brand];
};

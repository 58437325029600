import useModal from '@area2k/use-modal';

import UpdateInstructionModal from './UpdateInstructionModal';

import Card from '@/components/Card';
import Stack from '@/components/Stack';
import StackContainer from '@/components/StackContainer';
import TextStack from '@/components/TextStack';
import { Small } from '@/components/Typography';
import { GetJobQuery, JobStatusEnum } from '@/types/graphql';
import '../index.css';

type Props = {
  job: GetJobQuery['job'];
};

const JobInstructionSection = ({ job }: Props) => {
  const [showUpdateModal, hideUpdateModal] = useModal(
    () => <UpdateInstructionModal hideModal={hideUpdateModal} job={job} />,
    [job]
  );

  const { status, instructions, skill } = job;

  return (
    <Card.Section
      actions={
        status === JobStatusEnum.UPCOMING
          ? [
              {
                a11yLabel: 'Change job instructions',
                label: 'Change',
                onAction: showUpdateModal,
                id: 'change-job-instructions',
              },
            ]
          : undefined
      }
      styleHeading={true}
      title="Job Instructions"
    >
      <StackContainer className="scrollbar">
        <Stack vertical>
          <TextStack>
            <Small>&quot;{instructions || skill.descriptionEn}&quot;</Small>
          </TextStack>
        </Stack>
      </StackContainer>
    </Card.Section>
  );
};

export default JobInstructionSection;

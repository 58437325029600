import { Payroll } from '..';
import { payrollHeaderFields } from '../..';
import PayrollStatusBadge from '../../PayrollStatusBadge';

import Card from '@/components/Card';
import Table from '@/components/Table';
import TableHeader from '@/components/TableHeader';
import { TableCell, TableRow } from '@/elements/Table';
import { formatHumanDate, formatDateTime } from '@/util/datetime';

type Props = {
  payroll: Payroll;
};

const DetailsCard = ({ payroll }: Props) => {
  return (
    <Card title="Details">
      <Table>
        <TableHeader fields={payrollHeaderFields} />
        <tbody>
          <TableRow>
            <TableCell>{payroll!.id}</TableCell>
            <TableCell>{formatHumanDate(payroll!.payday)}</TableCell>
            <TableCell>{formatHumanDate(payroll!.periodStart)}</TableCell>
            <TableCell>{formatHumanDate(payroll!.periodEnd)}</TableCell>
            <TableCell>{formatDateTime(payroll!.approvalDeadline)}</TableCell>
            <TableCell>
              {payroll!.approvedAt !== null
                ? formatDateTime(payroll!.approvedAt)
                : 'N/A'}
            </TableCell>
            <TableCell>
              <PayrollStatusBadge status={payroll!.status} />
            </TableCell>
          </TableRow>
        </tbody>
      </Table>
    </Card>
  );
};

export default DetailsCard;

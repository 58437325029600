import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import AccountList from './AccountList';
import AddressList from './AddressList';
import AdminList from './AdminList';
import CustomRates from './CustomRates';
import InvoiceList from './InvoiceList';
import OrdersList from './OrdersList';
import PageSkeleton from './PageSkeleton';
import SettingsCard from './SettingsCard';
import WorkerList from './WorkerList';
import useChangeLogoModal from './useChangeLogoModal';

import Avatar from '@/components/Avatar';
import Page from '@/components/Page';
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout';
import Stack from '@/components/Stack';
import { TIME_TO_REFRESH } from '@/constants/general';
import { useGetCustomerQuery } from '@/graphql';
import useAuth from '@/hooks/useAuth';
import useMediaQuery from '@/hooks/useMediaQuery';

const Customer = () => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const { currentAdminIsCustomerAdmin, currentAdmin, isCurrentCustomerAdmin } =
    useAuth();
  const { customerId: customerIdByParam } = useParams();

  const customerId = useMemo(() => {
    if (customerIdByParam) {
      return customerIdByParam;
    } else if (
      currentAdminIsCustomerAdmin &&
      isCurrentCustomerAdmin(currentAdmin)
    ) {
      return currentAdmin?.customer.id;
    }

    return '';
  }, []);

  const { data, refetch } = useGetCustomerQuery({
    variables: {
      customerId,
    },
    pollInterval: TIME_TO_REFRESH,
    fetchPolicy: 'network-only',
  });

  const showLogoModal = useChangeLogoModal(data?.customer);

  if (!data) {
    return <PageSkeleton />;
  }

  const customer = data.customer;

  return (
    <Page
      headerPadding={phoneOnly}
      media={
        <Avatar firstName={customer.name} size="md" src={customer.logoUrl} />
      }
      noPadding={phoneOnly}
      primaryAction={{
        a11yLabel: 'Change customer logo',
        label: 'Change logo',
        icon: faCamera,
        onAction: showLogoModal,
        id: 'btn-customer-logo',
      }}
      size={phoneOnly ? 'full' : 'lg'}
      title={customer.name}
    >
      <PrimarySecondaryLayout css={{ gap: phoneOnly ? 8 : 24 }}>
        <PrimarySecondaryLayout.Primary>
          <Stack vertical gap={phoneOnly ? 8 : 24}>
            {phoneOnly && (
              <>
                <SettingsCard
                  accordion={phoneOnly}
                  accordionDefault={true}
                  currentAdminIsCustomerAdmin={currentAdminIsCustomerAdmin}
                  customer={customer}
                />
              </>
            )}
            <AdminList
              accordion={phoneOnly}
              currentAdminIsCustomerAdmin={currentAdminIsCustomerAdmin}
              customer={customer}
            />
            <AccountList accordion={phoneOnly} customer={customer} />
            <AddressList
              accordion={phoneOnly}
              customer={customer}
              refetchGetCustomer={refetch}
            />
            {!currentAdminIsCustomerAdmin && (
              <CustomRates
                accordion={phoneOnly}
                customer={customer}
                refetchGetCustomerQuery={refetch}
              />
            )}
            <WorkerList accordion={phoneOnly} customer={customer} />
            {!phoneOnly && currentAdminIsCustomerAdmin && (
              <OrdersList accordion={phoneOnly} customer={customer} />
            )}
            {!currentAdminIsCustomerAdmin && (
              <>
                <OrdersList accordion={phoneOnly} customer={customer} />
                <InvoiceList accordion={phoneOnly} customer={customer} />
              </>
            )}
          </Stack>
        </PrimarySecondaryLayout.Primary>
        <PrimarySecondaryLayout.Secondary>
          <Stack vertical gap={phoneOnly ? 8 : 24}>
            {!phoneOnly && (
              <>
                <SettingsCard
                  currentAdminIsCustomerAdmin={currentAdminIsCustomerAdmin}
                  customer={customer}
                />
              </>
            )}
          </Stack>
        </PrimarySecondaryLayout.Secondary>
      </PrimarySecondaryLayout>
    </Page>
  );
};

export default Customer;

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { differenceInMinutes } from 'date-fns';

import { TimesheetItem } from '../..';
import { getTotalLengthOfShift, MINS_IN_DAY } from '../../../util';

import Alert from '@/components/Alert';
import FormElement from '@/components/FormElement';
import { isDateInvalid } from '@/util/date';

type NextDateAlertProps = {
  checkinAt: TimesheetItem['checkinAt'];
  checkoutAt: TimesheetItem['checkoutAt'];
};

const NextDateAlert = ({ checkinAt, checkoutAt }: NextDateAlertProps) => {
  const startDate = new Date(checkinAt!);
  const endDate = new Date(checkoutAt!);

  if (isDateInvalid(startDate) || isDateInvalid(endDate)) {
    return null;
  }

  const lengthOfShift = differenceInMinutes(endDate, startDate);

  if (lengthOfShift < MINS_IN_DAY) {
    return null;
  }

  return (
    <div style={{ marginTop: '10px', marginBottom: '-10px' }}>
      <FormElement>
        <Alert
          description={`Total length of shift: ${getTotalLengthOfShift(
            lengthOfShift
          )}`}
          icon={faInfoCircle}
          status="neutral"
          title="Shift crosses midnight"
        />
      </FormElement>
    </div>
  );
};

export default NextDateAlert;

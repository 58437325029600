import { PropsWithChildren } from 'react';

import styled from '@/styles';

const Wrapper = styled('div', {
  display: 'grid',
  gridTemplateAreas: '"left right"',
  gridTemplateRows: 'auto',
  gridTemplateColumns: '60% 40%',
  minHeight: '100%',
  overflowY: 'auto',
  backgroundColor: 'white',
  '@touchable': {
    gridTemplateAreas: '"left left"',
    gridTemplateColumns: 'initial',
  },
});

const Left = styled('div', {
  display: 'flex',
  gridArea: 'left',
  flexDirection: 'row-reverse',
  padding: '100px 30px',
  zIndex: 10,

  '@touchable': {
    padding: '122px 8px',
    flexDirection: 'row',
    boxShadow: '$0',
  },
});

const Right = styled('div', {
  gridArea: 'right',
  padding: '137px 30px',
  backgroundColor: '$neutralGhost',
});

const SplitLayout = ({ children }: PropsWithChildren<{}>) => {
  return <Wrapper>{children}</Wrapper>;
};

SplitLayout.Wrapper = Wrapper;
Wrapper.displayName = 'stitches(SplitLayout.Wrapper)';

SplitLayout.Left = Left;
Left.displayName = 'stitches(SplitLayout.Left)';

SplitLayout.Right = Right;
Right.displayName = 'stitches(SplitLayout.Right)';

export default SplitLayout;

import * as yup from 'yup';

const OneCapitalPattern = /^(?=.*[A-Z])/;
const OneNumberPattern = /(?=.*[0-9])/;
const LengthPasswordPattern = /(?=.{8,})/;

const firstStepSchema = yup
  .object({
    companyName: yup.string().required('Company name is a required field'),
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('Email address is a required field'),
    password: yup
      .string()
      .required('Password is a required field')
      .matches(LengthPasswordPattern, {
        message: 'Password must contain at least 8 characters',
      })
      .matches(OneCapitalPattern, {
        message: 'Password must contain at least 1 capital letter',
      })
      .matches(OneNumberPattern, {
        message: 'Password must contain at least 1 number',
      }),
  })
  .required();

export default firstStepSchema;

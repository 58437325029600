import { useReactiveVar } from '@apollo/client';
import React from 'react';

import * as components from './roles.json';
import styled from '@/styles';

const ReadOnly = styled('div', {
  pointerEvents: 'none',
  opacity: 0.7,
});

import { currentAdminVar } from '@/util/apollo/cache';

const hasUserRights = (id) => {
  if (components[id]) {
    const currentAdmin = useReactiveVar(currentAdminVar);
    return components[id].includes(currentAdmin?.user.roleId.toString());
  }
  return true;
};

type Props = {
  id: string;
  children: React.ReactNode;
  showAsReadOnly?: boolean;
};

const PermissionComponent = ({
  id,
  showAsReadOnly = false,
  children,
}: Props) => {
  if (!hasUserRights(id) && showAsReadOnly) {
    return <ReadOnly>{children}</ReadOnly>;
  } else if (!hasUserRights(id)) {
    return <></>;
  } else {
    return <>{children}</>;
  }
};

export default PermissionComponent;

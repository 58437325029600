import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { Divider } from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';

import { Schedule } from '../../../context';

import ShiftWorkersModal from './ShiftWorkersModal';
import { CountSection, getWorkersProfileImages } from './StaffingUtils';
import { WeeekDay, weekDayActiveStyles } from './styles';

import { ShiftCheckedIcon } from '@/assets/icons';
import AvatarShiftsWorkersGroupComponent from '@/components/AvatarShiftsWorkersGroupComponent';
import Option from '@/components/Option';
import Stack from '@/components/Stack';
import { Subheading } from '@/components/Typography';
import Icon from '@/elements/Icon';
import Text from '@/elements/Text';
import useMediaQuery from '@/hooks/useMediaQuery';
import { formatTime } from '@/util/datetime';

type StaffingShiftsCardsSectionProps = {
  schedule: Partial<Schedule>;
  isClientAdmin: boolean;
  onShiftCardSelection?: (scheduleId: number) => void;
  selectedSchedules: number[];
  isLTA: boolean;
  hideSelection?: boolean;
  hideCancelModal?: boolean;
};

const weekDaysFirstLetters = [
  {
    label: 'M',
    value: 'Monday',
  },
  {
    label: 'T',
    value: 'Tuesday',
  },
  {
    label: 'W',
    value: 'Wednesday',
  },
  {
    label: 'T',
    value: 'Thursday',
  },
  {
    label: 'F',
    value: 'Friday',
  },
  {
    label: 'S',
    value: 'Saturday',
  },
  {
    label: 'S',
    value: 'Sunday',
  },
];

const StaffingShiftsCardsSection = ({
  schedule,
  isClientAdmin,
  onShiftCardSelection,
  selectedSchedules,
  isLTA,
  hideSelection = false,
  hideCancelModal = false,
}: StaffingShiftsCardsSectionProps) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const invitedWorkersList = schedule.selectedWorkers || [];
  const hiredWorkersList = schedule.hiredWorkers || [];

  const [avatarHovered, setAvatarHovered] = useState(false);

  const workersImagesList = getWorkersProfileImages(
    invitedWorkersList.concat(hiredWorkersList)
  );
  const activeCardCheck = !!selectedSchedules.find(
    (id) => id === schedule.groupId
  );
  const fullyStaffed =
    hiredWorkersList.length > 0
      ? hiredWorkersList.length === schedule?.quantity
      : false;

  const [showWorkerModal, setShowWorkerModal] = useState(false);

  const onImageClick = () => {
    setShowWorkerModal(true);
  };

  const checkWeekdayMatch = (day: string) => {
    if (!schedule?.dateRange?.startDate) {
      return false;
    }
    const weekday = format(schedule?.dateRange?.startDate, 'EEEE');
    return weekday === day;
  };
  return (
    <>
      <Stack
        vertical
        css={{
          height: 230,
          width: 226,
          borderRadius: 8,
          border: !fullyStaffed ? '1px solid #D3D3D3' : '1px solid #45A735',
          padding: 16,
          background: activeCardCheck ? '#E7F3E6' : 'white',
          '&:hover': {
            border: '1px solid #45a735',
          },
        }}
        gap={'11px'}
      >
        <Stack
          vertical
          gap={'13px'}
          onClick={() =>
            !fullyStaffed &&
            !hideSelection &&
            onShiftCardSelection?.(schedule.groupId!)
          }
        >
          <Stack css={{ flexDirection: 'row' }} justify="apart">
            <Subheading>
              {schedule?.dateRange?.startDate
                ? format(schedule?.dateRange?.startDate, 'MMMM dd')
                : ''}
            </Subheading>
            {!fullyStaffed && !hideSelection && (
              <>
                {activeCardCheck ? (
                  <ShiftCheckedIcon
                    id="shift-checked-icon"
                    sx={{
                      width: '20px',
                      height: '20px',
                    }}
                  />
                ) : (
                  <Option
                    appearance="bullseye"
                    id={`shift-card-${schedule.groupId}`}
                    label={''}
                    style={{ left: 11 }}
                    type="checkbox"
                    value={`shift-card-${schedule.groupId}}`}
                  />
                )}
              </>
            )}
          </Stack>

          <Stack gap={8.5}>
            {weekDaysFirstLetters.map((day, index) => (
              <WeeekDay
                key={index}
                css={checkWeekdayMatch(day.value) ? weekDayActiveStyles : {}}
              >
                {day.label}
              </WeeekDay>
            ))}
          </Stack>

          <Stack
            css={{ flexDirection: 'column', alignItems: 'flex-start' }}
            gap="4px"
          >
            <Text as="div">
              {schedule.startTime && schedule.endTime ? (
                <>
                  {formatTime(schedule.startTime, 'h:mmaaa') + ' - '}
                  {formatTime(schedule.endTime, 'h:mmaaa')}
                </>
              ) : (
                <>-</>
              )}
            </Text>
            <Text css={{ color: '#7A7A7A' }} lineHeightProp={'small'}>
              {`${schedule.mandatoryBreakTime} min break`}
            </Text>
          </Stack>
        </Stack>
        <Divider style={{ width: '100%' }} />

        <div
          style={{
            width: '100%',
            cursor:
              !hideCancelModal && workersImagesList.length > 0
                ? 'pointer'
                : undefined,
          }}
          onClick={() => {
            !hideCancelModal && workersImagesList.length > 0 && onImageClick();
          }}
          onMouseEnter={() => {
            !hideCancelModal && setAvatarHovered(true);
          }}
          onMouseLeave={() => {
            setAvatarHovered(false);
          }}
        >
          <CountSection
            fullyStaffed={fullyStaffed}
            hiredCount={hiredWorkersList.length}
            invitedCount={invitedWorkersList.length}
            isClientAdmin={isClientAdmin}
            isLTA={isLTA}
            quantity={schedule?.quantity}
          />

          <Stack css={{ marginTop: 4 }}>
            {workersImagesList.length > 0 ? (
              <AvatarShiftsWorkersGroupComponent
                hovered={avatarHovered}
                items={workersImagesList}
                maxLimitToShow={4}
                small={phoneOnly}
              />
            ) : (
              <Icon
                fixedWidth
                icon={faUserCircle}
                style={{ width: 36, height: 36, color: '#D3D3D3' }}
              />
            )}
          </Stack>
        </div>
      </Stack>
      {showWorkerModal && !hideCancelModal && (
        <ShiftWorkersModal
          hideModal={() => setShowWorkerModal(false)}
          hiredWorkersList={hiredWorkersList}
          invitedWorkersList={invitedWorkersList}
          isClientAdmin={isClientAdmin}
          isLTA={isLTA}
          schedule={schedule}
          workersFullySatisfied={fullyStaffed}
        />
      )}
    </>
  );
};

export default StaffingShiftsCardsSection;

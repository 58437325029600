import Stack from '@/components/Stack';
import TextInput from '@/components/TextInput';

type Props = {
  query: string;
  onQueryChange: (query: string) => void;
};

const MyTeamFilters = ({ query, onQueryChange }: Props) => {
  return (
    <Stack>
      <div style={{ flex: 1 }}>
        <TextInput
          id="input-search-member"
          minWidth="sm"
          placeholder="Search for members..."
          value={query}
          onChange={(ev) => onQueryChange(ev.currentTarget.value)}
        />
      </div>
    </Stack>
  );
};

export default MyTeamFilters;

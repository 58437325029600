import Card from '@/components/Card';
import { Body } from '@/components/Typography';
import styled from '@/styles';

const Wrapper = styled('div', {
  padding: '20px 20px 16px',

  borderBottom: '1px solid $colors$neutralLightest',

  [`${Card.Section.Wrapper} + &`]: {
    borderTop: '1px solid $colors$neutralLightest',
  },
});

export type ResourceName = {
  singular: string;
  plural: string;
};

export type Props = {
  itemCount: number;
  resourceName?: ResourceName;
  totalItemCount?: number;
};

const Header = ({ itemCount, resourceName, totalItemCount }: Props) => {
  // const resourceLabel = itemCount === 1 ? resourceName?.plural : resourceName?.singular
  const countLabel =
    totalItemCount && itemCount !== totalItemCount
      ? `${itemCount} of ${totalItemCount}`
      : itemCount;

  return (
    <Wrapper>
      {resourceName && (
        <Body>
          Showing {countLabel}{' '}
          {itemCount === 1 ? resourceName.singular : resourceName.plural}
        </Body>
      )}
    </Wrapper>
  );
};

Header.Wrapper = Wrapper;
Wrapper.displayName = 'stitches(ResourceList.Header.Wrapper)';

export default Header;

import React from 'react';
import Pagination from 'react-js-pagination';

import { Small } from '../Typography';

import Stack from '@/components/Stack';
import useMediaQuery from '@/hooks/useMediaQuery';
import './styles.css';

type Props = {
  currentPage: number;
  itemsPerPage: number;
  handlePageChange: (number: number) => void;
  handleNumberItemsChange: (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => void;
  itemsLength?: number;
  pageRangeDisplayed?: number;
  itemOptions?: number[];
};

const Paginator: React.FC<Props> = ({
  currentPage,
  itemsPerPage,
  handlePageChange,
  handleNumberItemsChange,
  itemsLength = 0,
  pageRangeDisplayed = 5,
  itemOptions = [5, 10, 15, 20],
}) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  return (
    <Stack
      align={'center'}
      gap={phoneOnly ? 16 : 8}
      justify={phoneOnly ? 'center' : 'apart'}
      style={{ marginTop: phoneOnly ? 24 : 32 }}
    >
      <Stack
        wrap
        css={{ order: phoneOnly ? 1 : 0 }}
        justify={phoneOnly ? 'end' : 'start'}
      >
        <Small css={{ display: phoneOnly ? 'none' : 'inline-block' }}>
          Items per page
        </Small>
        <select
          value={itemsPerPage.toString()}
          onChange={handleNumberItemsChange}
        >
          {itemOptions.map((size) => (
            <option key={size} value={size.toString()}>
              {size}
            </option>
          ))}
        </select>
      </Stack>
      <Pagination
        activePage={currentPage}
        itemsCountPerPage={itemsPerPage}
        pageRangeDisplayed={pageRangeDisplayed}
        totalItemsCount={itemsLength}
        onChange={handlePageChange}
      />
    </Stack>
  );
};

export default Paginator;

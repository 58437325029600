import Card from '@/components/Card';
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout';
import Skeleton from '@/components/Skeleton';

const InvoicePaySkeleton = () => (
  <Skeleton.Page>
    <PrimarySecondaryLayout>
      <PrimarySecondaryLayout.Primary>
        <Card>
          <Card.Section>
            <Skeleton.Body lines={8} />
          </Card.Section>
        </Card>
      </PrimarySecondaryLayout.Primary>
      <PrimarySecondaryLayout.Secondary>
        <Card>
          <Card.Section>
            <Skeleton.Body />
          </Card.Section>
        </Card>
      </PrimarySecondaryLayout.Secondary>
    </PrimarySecondaryLayout>
  </Skeleton.Page>
);

export default InvoicePaySkeleton;

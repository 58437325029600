import { createSvgIcon } from '@mui/material';

const BlockIconWithBackground = createSvgIcon(
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="14" cy="14" r="14" fill="#FFF5F5" />
    <path
      d="M14.0003 22.3307C12.8475 22.3307 11.7642 22.112 10.7503 21.6745C9.73644 21.237 8.85449 20.6432 8.10449 19.8932C7.35449 19.1432 6.76074 18.2613 6.32324 17.2474C5.88574 16.2335 5.66699 15.1502 5.66699 13.9974C5.66699 12.8446 5.88574 11.7613 6.32324 10.7474C6.76074 9.73351 7.35449 8.85156 8.10449 8.10156C8.85449 7.35156 9.73644 6.75781 10.7503 6.32031C11.7642 5.88281 12.8475 5.66406 14.0003 5.66406C15.1531 5.66406 16.2364 5.88281 17.2503 6.32031C18.2642 6.75781 19.1462 7.35156 19.8962 8.10156C20.6462 8.85156 21.2399 9.73351 21.6774 10.7474C22.1149 11.7613 22.3337 12.8446 22.3337 13.9974C22.3337 15.1502 22.1149 16.2335 21.6774 17.2474C21.2399 18.2613 20.6462 19.1432 19.8962 19.8932C19.1462 20.6432 18.2642 21.237 17.2503 21.6745C16.2364 22.112 15.1531 22.3307 14.0003 22.3307ZM14.0003 20.6641C14.7503 20.6641 15.4725 20.5425 16.167 20.2995C16.8614 20.0564 17.5003 19.7057 18.0837 19.2474L8.75032 9.91406C8.29199 10.4974 7.9413 11.1363 7.69824 11.8307C7.45519 12.5252 7.33366 13.2474 7.33366 13.9974C7.33366 15.8585 7.97949 17.4349 9.27116 18.7266C10.5628 20.0182 12.1392 20.6641 14.0003 20.6641ZM19.2503 18.0807C19.7087 17.4974 20.0594 16.8585 20.3024 16.1641C20.5455 15.4696 20.667 14.7474 20.667 13.9974C20.667 12.1363 20.0212 10.5599 18.7295 9.26823C17.4378 7.97656 15.8614 7.33073 14.0003 7.33073C13.2503 7.33073 12.5281 7.45226 11.8337 7.69531C11.1392 7.93837 10.5003 8.28906 9.91699 8.7474L19.2503 18.0807Z"
      fill="#DC1515"
    />
  </svg>,
  'BlockIconWithBackground',
);
export default BlockIconWithBackground;

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import Tooltip from '../Tooltip';

import Icon from '@/elements/Icon';

const TooltipInfo = ({ text }: { text: string }) => {
  return (
    <Tooltip align="aboveRight" text={text}>
      <Icon icon={faInfoCircle} theme="lighter" />
    </Tooltip>
  );
};

export default TooltipInfo;

import useModal from '@area2k/use-modal';

import AdminUpdateProfileModal from '@/routes/Agency/Contacts/AdminProfile';
import {
  CurrentTenantAdminFragment,
  ListTenantAdminQuery,
} from '@/types/graphql';

export type TenantAdminItem =
  ListTenantAdminQuery['agency']['tenant']['admins'][0];

const useTenantAdminProfile = (
  admin: CurrentTenantAdminFragment | TenantAdminItem
) => {
  const [showModal, hideModal] = useModal(
    () => <AdminUpdateProfileModal admin={admin} hideModal={hideModal} />,
    [admin]
  );
  return { showProfile: showModal };
};

export default useTenantAdminProfile;

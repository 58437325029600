import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { Body } from '@/components/Typography';

const NetworkNotAvailableModal = () => {
  return (
    <Modal disableClickout={true} size="xs" title={`Connectivity Error`}>
      <Card.Section>
        <Stack vertical gap={24}>
          <Body>{`Couldn't connect to server, Please check your internet connect.`}</Body>
        </Stack>
      </Card.Section>
    </Modal>
  );
};

export default NetworkNotAvailableModal;

import { createSvgIcon } from '@mui/material';

const DeleteIcon = createSvgIcon(
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.49967 2.5V3.33333H3.33301V5H4.16634V15.8333C4.16634 16.2754 4.34194 16.6993 4.6545 17.0118C4.96706 17.3244 5.39098 17.5 5.83301 17.5H14.1663C14.6084 17.5 15.0323 17.3244 15.3449 17.0118C15.6574 16.6993 15.833 16.2754 15.833 15.8333V5H16.6663V3.33333H12.4997V2.5H7.49967ZM5.83301 5H14.1663V15.8333H5.83301V5ZM7.49967 6.66667V14.1667H9.16634V6.66667H7.49967ZM10.833 6.66667V14.1667H12.4997V6.66667H10.833Z"
      fill="#7A7A7A"
    />
  </svg>,
  'DeleteIcon'
);
export default DeleteIcon;

import Card from '@/components/Card';
import Modal from '@/components/Modal';
import TextStack from '@/components/TextStack';
import { Body, Small } from '@/components/Typography';
import styled from '@/styles';
import { WorkerReference } from '@/types/graphql';

const Grid = styled('div', {
  display: 'grid',
  gridTemplateRows: 'auto auto auto',
  gridTemplateColumns: '1fr 1fr',
  rowGap: '24px',
});

export type Props = {
  reference: WorkerReference;
  hideModal: () => void;
};

const MoreInfoModal = ({ reference, hideModal }: Props) => (
  <Modal size="sm" title="Experience Information" onRequestClose={hideModal}>
    <Card.Section>
      <Grid>
        <TextStack spacing="loose">
          <Small>Employer Name</Small>
          <Body>{reference.employerName}</Body>
        </TextStack>
        <TextStack spacing="loose">
          <Small>Job</Small>
          <Body>{reference.name}</Body>
        </TextStack>
        <TextStack spacing="loose">
          <Small>Phone Reference</Small>
          <Body>{reference.phoneNumber}</Body>
        </TextStack>
        <TextStack spacing="loose">
          <Small>City</Small>
          <Body>{reference.city}</Body>
        </TextStack>
        <TextStack spacing="loose">
          <Small>Duration</Small>
          <Body>{reference.duration}</Body>
        </TextStack>
        <TextStack spacing="loose">
          <Small>Summary</Small>
          <Body>{reference.summary}</Body>
        </TextStack>
      </Grid>
    </Card.Section>
  </Modal>
);

export default MoreInfoModal;

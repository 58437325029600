import Card from '@/components/Card';
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout';
import Skeleton from '@/components/Skeleton';
import Stack from '@/components/Stack';
import useMediaQuery from '@/hooks/useMediaQuery';

const PageSkeleton = () => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  return (
    <Skeleton.Page media>
      <PrimarySecondaryLayout>
        <PrimarySecondaryLayout.Primary css={{ gap: phoneOnly ? 8 : 24 }}>
          <Stack vertical gap={phoneOnly ? 8 : 24}>
            <Card sectioned noRadius={phoneOnly} title="Administrators">
              <Skeleton.Body />
            </Card>
            <Card sectioned noRadius={phoneOnly} title="Accounts">
              <Skeleton.Body />
            </Card>
            <Card sectioned noRadius={phoneOnly} title="Addresses">
              <Skeleton.Body />
            </Card>
            <Card sectioned noRadius={phoneOnly} title="Custom rates">
              <Skeleton.Body />
            </Card>
            <Card sectioned noRadius={phoneOnly} title="GravyWorkers">
              <Skeleton.Body />
            </Card>
            <Card sectioned noRadius={phoneOnly} title="Orders">
              <Skeleton.Body />
            </Card>
          </Stack>
        </PrimarySecondaryLayout.Primary>
        <PrimarySecondaryLayout.Secondary>
          <Stack vertical gap={phoneOnly ? 8 : 24}>
            <Card noRadius={phoneOnly} title="Information">
              <Card.Section>
                <Skeleton.Body />
              </Card.Section>
              <Card.Section noRadius={phoneOnly} title="Payment">
                <Skeleton.Body />
              </Card.Section>
            </Card>
          </Stack>
        </PrimarySecondaryLayout.Secondary>
      </PrimarySecondaryLayout>
    </Skeleton.Page>
  );
};

export default PageSkeleton;

import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import Button from '@/components/Button';
import Stack from '@/components/Stack';
import TextInput from '@/components/TextInput';
import TextSelect from '@/components/TextSelect';
import { FilterProps } from '@/filters';
import useDebouncedValue from '@/hooks/useDebouncedValue';
import { CompareOperatorEnum, OrderFilterSetInput } from '@/types/graphql';

const NumericCompareFilter = ({
  filters,
  filterKey,
  onChangeFilter,
  onClearFilter,
}: FilterProps<OrderFilterSetInput>) => {
  const [op, setOp] = useState<CompareOperatorEnum | null>(
    filters.jobsCount ? filters.jobsCount.op : null
  );
  const [value, setValue] = useState<number>(
    filters.jobsCount ? filters.jobsCount.value : 0
  );
  const debouncedValue = useDebouncedValue(value);

  const handleOpChange = useCallback((ev: ChangeEvent<HTMLSelectElement>) => {
    setOp(ev.currentTarget.value as CompareOperatorEnum);
  }, []);

  const handleValueChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    setValue(Number(ev.currentTarget.value));
  }, []);

  const clearFilter = useCallback(() => {
    setOp(null);
    onClearFilter(filterKey);
  }, [filterKey]);

  useEffect(() => {
    if (op) {
      onChangeFilter(filterKey, { op, value: debouncedValue });
    }
  }, [op, debouncedValue, filterKey]);

  return (
    <Stack vertical gap={16}>
      <TextSelect
        id="select-number-of-jobs"
        value={op ?? ''}
        onChange={handleOpChange}
      >
        <option disabled value="">
          Select...
        </option>
        <option value={CompareOperatorEnum.EQ}>Equal to</option>
        <option value={CompareOperatorEnum.GT}>Greater-than</option>
        <option value={CompareOperatorEnum.GTEQ}>
          Greater-than or equal to
        </option>
        <option value={CompareOperatorEnum.LT}>Less-than</option>
        <option value={CompareOperatorEnum.LTEQ}>Less-than or equal to</option>
        <option value={CompareOperatorEnum.NOT}>Not equal to</option>
      </TextSelect>
      {op && (
        <>
          <TextInput
            id="input-number"
            type="number"
            value={value}
            onChange={handleValueChange}
          />
          <Button
            a11yLabel="Clear order status filter"
            appearance="plain"
            id="clear-number"
            label="Clear"
            onClick={clearFilter}
          />
        </>
      )}
    </Stack>
  );
};

export default NumericCompareFilter;

import { useState } from 'react';

import Card from '@/components/Card';
import Skeleton from '@/components/Skeleton';
import Action from '@/components/Skeleton/Action';
import Body from '@/components/Skeleton/Body';
import Stack from '@/components/Stack';
import Tabs, { TabDefinition } from '@/components/Tabs';

const TABS: TabDefinition[] = [
  {
    a11yLabel: 'Workers details',
    label: 'Worker Details',
  },
  { a11yLabel: 'Job Details', label: 'Job Details' },
];

const customTabTitle = <Body />;

const PageSkeleton = () => {
  const [tab, setTab] = useState(0);

  return (
    <Skeleton.Page media primaryAction size={'xl'} title={customTabTitle}>
      <Tabs
        selected={tab}
        showTabOnMobile={true}
        tabs={TABS}
        onSelect={setTab}
      />
      <div style={{ width: '100%', marginTop: '40px' }}>
        <Card sectioned>
          <Stack justify="apart">
            <Stack css={{ width: 300 }}>
              <Body lines={1} />
            </Stack>
            <Stack gap={16} justify="end">
              <Action />
              <Action />
            </Stack>
          </Stack>
        </Card>
        <Card sectioned>
          <Skeleton.TagList />
        </Card>
        <Card sectioned>
          <Skeleton.TagList />
        </Card>
        <Card sectioned>
          <Skeleton.TagList />
        </Card>
        <Card sectioned>
          <Skeleton.TagList />
        </Card>
      </div>
    </Skeleton.Page>
  );
};

export default PageSkeleton;

import AlreadyValidated from './AlreadyValidate';
import LinkExpired from './LinkExpired';

type Props = {
  stateAccount: string;
};

const StateValidationAccount = ({ stateAccount }: Props) => {
  return stateAccount === '0' ? <AlreadyValidated /> : <LinkExpired />;
};

export default StateValidationAccount;

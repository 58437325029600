import useModal from '@area2k/use-modal';

import ChangeBackgroundModal from './ChangeBackgroundModal';

import Card from '@/components/Card';
import TextStack from '@/components/TextStack';
import { Body } from '@/components/Typography';
import { BackgroundStatusEnum, GetWorkerQuery } from '@/types/graphql';
import { parseEnums } from '@/util/text';

type Props = {
  worker: GetWorkerQuery['worker'];
  currentAdminIsCustomerAdmin: boolean;
};

const backgroundStatus = (statusEnum) =>
  ({
    [BackgroundStatusEnum.NA]: 'N/A',
  }[statusEnum] || parseEnums(statusEnum));

const BackgroundCheck = ({ worker }: Props) => {
  const [showChangeModal, hideChangeModal] = useModal(
    () => <ChangeBackgroundModal hideModal={hideChangeModal} worker={worker} />,
    [worker]
  );
  return (
    <Card.Section
      actions={[
        {
          a11yLabel: "Change this GravyWorker's Background Check",
          label: 'Change',
          onAction: showChangeModal,
          id: 'btn-change-background',
        },
      ]}
      title="Background Check"
    >
      <TextStack>
        <Body>
          {worker.backgroundStatus
            ? backgroundStatus(worker.backgroundStatus)
            : 'not provided'}
        </Body>
      </TextStack>
    </Card.Section>
  );
};

export default BackgroundCheck;

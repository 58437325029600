import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';

type Props = {
  hideModal: () => void;
};

const SuccessfulAlertModal = ({ hideModal }: Props) => (
  <Modal size="xs" title="Report in progress" onRequestClose={hideModal}>
    <Card.Section>
      <Alert
        icon={faCheckCircle}
        status="success"
        title={'The report will be sent to your mailbox'}
        onClick={hideModal}
      />
      <Stack justify="end">
        <Button
          a11yLabel="Close the alert"
          appearance="primary"
          id="ok-btn"
          label="Ok"
          type="button"
          onClick={hideModal}
        />
      </Stack>
    </Card.Section>
  </Modal>
);

export default SuccessfulAlertModal;

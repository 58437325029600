import useModal from '@area2k/use-modal';
import {
  Edit,
  MailOutline,
  PhoneOutlined,
  PlaceOutlined,
} from '@mui/icons-material';
import { Grid, Stack } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';

import InvoiceBadge from '../../InvoiceBadge';

import EditInvoiceDetail from './EditInvoiceModal';

import { AccountRecordIcon, Logo } from '@/assets/icons';
import LoadingState from '@/components/LoadingState';
import TextStack from '@/components/TextStack';
import { Subheading } from '@/components/Typography';
import Button from '@/elements/Button';
import Link from '@/elements/Link';
import Text from '@/elements/Text';
import useAuth from '@/hooks/useAuth';
import useMediaQuery from '@/hooks/useMediaQuery';
import ChangePaymentModal from '@/routes/Agency/Account/PaymentSection/modals/ChangePaymentModal';
import { theme } from '@/styles/colors';
import {
  GetAccountPaymentMethodsQuery,
  GetInvoiceDetailsRevampQuery,
  StripeInvoiceStatusEnum,
} from '@/types/graphql';
import { parseTimestamp } from '@/util/date';
import { centsToCurrency } from '@/util/number';
import { formatCurrentPaymentMethod } from '@/util/payments';
import { isNullOrEmpty } from '@/util/constants';

type Props = {
  invoice: GetInvoiceDetailsRevampQuery['listStripeInvoiceDetail']['invoice'];
  editable: boolean;
  refetchInv: () => Promise<any>;
  accountPaymentMethods?: GetAccountPaymentMethodsQuery['accountPaymentMethods'];
  loadingPM: boolean;
  refetchPM?: () => Promise<any>;
};
const editIconStyle = {
  cursor: 'pointer',
  height: '16px',
  width: '16px',
  marginLeft: '8px',
  top: '2px',
};
const editInvPaymentStatus = [
  StripeInvoiceStatusEnum.OPEN,
  StripeInvoiceStatusEnum.DRAFT,
];

const DetailsCard = ({
  invoice,
  editable,
  refetchInv,
  accountPaymentMethods,
  loadingPM,
  refetchPM,
}: Props) => {
  const { currentAgency, currentAdminIsCustomerAdmin } = useAuth();
  const [showEditModal, hideEditModal] = useModal(
    () => <EditInvoiceDetail hideModal={hideEditModal} invoice={invoice} />,
    [invoice],
  );
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const account = invoice.account;
  const agencyAddress = currentAgency?.address;
  const contact = account.defaultContact?.user;

  const [showModal, setshowModal] = useState(false);

  const dueDate = parseTimestamp(invoice.dueDate!);
  const invoiceAmountDue = centsToCurrency(Number(invoice.amountDue));
  const paymentMethod = accountPaymentMethods
    ? formatCurrentPaymentMethod(accountPaymentMethods)
    : '';
  const isPaymentEditable = () => {
    if (
      !accountPaymentMethods ||
      !editInvPaymentStatus.includes(invoice.status)
    )
      return false;
    return true;
  };
  const accLink = currentAdminIsCustomerAdmin
    ? `/my-company/accounts/${account.id}`
    : `/clients/${invoice?.customer?.id}/accounts/${account.id}`;

  return (
    <>
      {showModal && refetchPM && (
        <ChangePaymentModal
          account={account}
          accountPaymentMethods={accountPaymentMethods!}
          handleSuccess={refetchInv}
          hideModal={() => {
            setshowModal(false);
          }}
          onRefetch={refetchPM}
        />
      )}

      <Grid container alignItems="center" spacing={3.5} wrap="wrap">
        <Grid item sm={6.6} xs={12}>
          <Logo id="gravy-logo" sx={{ height: 'auto', width: '280px' }} />
        </Grid>
        <Grid item sm={2} xs={4}>
          <TextStack css={{ borderRight: '1px solid #D3D3D3' }}>
            <Text color={'lighter'}>
              Payment Method:
              {isPaymentEditable() && (
                <Edit
                  htmlColor={theme.themeNew}
                  id="edit-payment-method"
                  sx={editIconStyle}
                  onClick={() => {
                    setshowModal(true);
                  }}
                />
              )}
            </Text>
            <Text
              css={{ textTransform: 'capitalize' }}
              id="payment-method"
              size={'xl'}
              weight={'medium'}
            >
              {loadingPM && <LoadingState />}
              {paymentMethod}
            </Text>
          </TextStack>
        </Grid>
        <Grid item sm={1.8} xs={4}>
          <TextStack css={{ borderRight: '1px solid #D3D3D3' }}>
            <Text color={'lighter'}>
              Due Date:{' '}
              {editable && (
                <Edit
                  htmlColor={theme.themeNew}
                  id="edit-due-date"
                  sx={editIconStyle}
                  onClick={showEditModal}
                />
              )}
            </Text>
            <Text id="due-date" size={'xl'} weight={'medium'}>
              {format(parseISO(dueDate), 'LLL dd, yyyy')}
            </Text>
          </TextStack>
        </Grid>
        <Grid item sm={1.6} xs={4}>
          <Stack direction={'row'}>
            <TextStack>
              <Text color={'lighter'}>Amount Due: </Text>
              <Text id="amount-due" size={'xl'} weight={'semibold'}>
                ${invoiceAmountDue}
              </Text>
            </TextStack>
          </Stack>
        </Grid>
      </Grid>
      <div style={{ marginTop: '12px', marginBottom: '4px' }}>
        <Text color={'lighter'}>Date: </Text>
        <Text id="date">
          {format(
            parseISO(invoice.invoiceFinalizedAt || invoice.createdDatetime),
            'LLLL dd, yyyy',
          )}
        </Text>
      </div>
      <div>
        <Text size={'xl'} weight={'semibold'}>
          Invoice{' '}
        </Text>
        <Text css={{ marginRight: '12px' }} id="invoice-number" size={'xl'}>
          {`${invoice.id}${invoice.number ? `-${invoice.number}` : ''}`}
        </Text>
        <InvoiceBadge id="status" status={invoice.status} />
      </div>
      <br />
      <Grid container spacing={2.5} sx={{ marginTop: '5px' }} wrap="wrap">
        <Grid
          item
          sm={4}
          sx={{
            borderRight: phoneOnly ? '0px' : '1px solid #D3D3D3',
            paddingTop: '2px !important',
            borderBottom: phoneOnly ? '1px solid #D3D3D3' : '0px',
            paddingBottom: phoneOnly ? '8px' : '0px',
          }}
          xs={12}
        >
          <Stack gap={1}>
            <Stack direction={'row'} gap={1}>
              <Text color={'lighter'} size={'lg'} weight={'semibold'}>
                From:{' '}
              </Text>
              <Subheading id="from-name">{currentAgency?.name}</Subheading>
            </Stack>
            <Stack direction={'row'} gap={1}>
              <PlaceOutlined fontSize="small" htmlColor={theme.themeNew} />
              <TextStack id="from-address">
                <Text>{agencyAddress?.addressLine1}</Text>
                {!isNullOrEmpty(agencyAddress?.addressLine2) && (
                  <Text>{agencyAddress?.addressLine2}</Text>
                )}
                <Text>{`${agencyAddress?.city}, ${agencyAddress?.state} ${agencyAddress?.zip}`}</Text>
              </TextStack>
            </Stack>
            <Stack direction={'row'} gap={1}>
              <PhoneOutlined fontSize="small" htmlColor={theme.themeNew} />
              <Text id="from-phone">{currentAgency?.phoneNumber}</Text>
            </Stack>
            <Stack direction={'row'} gap={1}>
              <MailOutline fontSize="small" htmlColor={theme.themeNew} />
              <a
                href={`mailto:${currentAgency?.email}`}
                id="from-email"
                style={{ color: theme.themeNew, textDecoration: 'none' }}
              >
                {currentAgency?.email}
              </a>
            </Stack>
          </Stack>
        </Grid>
        <Grid
          item
          sm={4}
          sx={{
            borderRight: phoneOnly ? '0px' : '1px solid #D3D3D3',
            paddingRight: '4px',
            paddingTop: '2px !important',
            borderBottom: phoneOnly ? '1px solid #D3D3D3' : '0px',
            paddingBottom: phoneOnly ? '8px' : '0px',
          }}
          xs={12}
        >
          <Stack gap={1}>
            <Stack direction={'row'} gap={1}>
              <Text color={'lighter'} size={'lg'} weight={'semibold'}>
                Customer:{' '}
              </Text>
              <Subheading id="customer-name">{`${
                invoice?.customer?.name || ''
              }`}</Subheading>
            </Stack>
            <Stack direction={'row'} gap={1}>
              <PlaceOutlined fontSize="small" htmlColor={theme.themeNew} />
              <TextStack id="customer-address">
                <Text>{account.addressLine1}</Text>
                {!isNullOrEmpty(account.addressLine2) && (
                  <Text>{account.addressLine2}</Text>
                )}
                <Text>
                  {`${account.city}, ${account.state} ${account.zip}`}
                </Text>
              </TextStack>
            </Stack>
            <Stack direction={'row'} gap={1}>
              <MailOutline fontSize="small" htmlColor={theme.themeNew} />
              <Text id="customer-email">{contact.email}</Text>
            </Stack>
            <Stack direction={'row'} gap={1}>
              <AccountRecordIcon sx={{ width: '20px', height: '20px' }} />
              <Link id="account-name" to={accLink}>
                {account.name}
              </Link>
            </Stack>
          </Stack>
        </Grid>
        <Grid item sm={4} sx={{ paddingTop: '2px !important' }} xs={12}>
          <Stack gap={1}>
            <Stack direction={'row'} gap={1} justifyContent={'space-between'}>
              <Subheading>{'Description'}</Subheading>
              {editable && (
                <Button
                  appearance={'plain'}
                  id="edit-desc-btn"
                  onClick={showEditModal}
                >
                  Edit
                </Button>
              )}
            </Stack>
            <Stack direction={'row'} gap={1}>
              <Text css={{ wordBreak: 'break-word' }} id="inv-description">
                {invoice.description}
              </Text>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default DetailsCard;

import FormProvider, {
  FieldErrorMap,
  FormProps,
  FormValues,
  SubmitHelpers,
} from '@area2k/use-form';
import { useCallback } from 'react';

import FormErrorAlerts from '@/form/FormErrorAlerts';
import styled from '@/styles';
import { Scalars } from '@/types/graphql';

const StyledFieldSet = styled('fieldset', {
  margin: 0,
  padding: 0,
  border: 'none',
});

type Props<V extends FormValues> = Omit<FormProps<V>, 'onSubmitWithErrors'> & {
  onSubmitWithErrors?: FormProps<V>['onSubmitWithErrors'];
  isLoading?: Scalars['Boolean'];
};

const Form = <V extends FormValues>({
  children,
  onSubmitWithErrors,
  isLoading,
  ...props
}: Props<V>) => {
  const handleSubmitWithErrors = useCallback(
    async (values: V, errors: FieldErrorMap<V>, helpers: SubmitHelpers) => {
      if (onSubmitWithErrors) {
        return onSubmitWithErrors(values, errors, helpers);
      }

      helpers.setFormError('fieldErrors', {
        title: 'Form contains errors',
        message: 'Please fix the highlighted fields to continue.',
        status: 'danger',
      });
    },
    [onSubmitWithErrors]
  );

  return (
    <FormProvider {...props} onSubmitWithErrors={handleSubmitWithErrors}>
      <FormErrorAlerts />
      <StyledFieldSet aria-busy={isLoading} disabled={isLoading}>
        {children}
      </StyledFieldSet>
    </FormProvider>
  );
};

export default Form;

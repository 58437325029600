import { Navigate } from 'react-router-dom';

import ROUTES from '@/routes/routes';
import { clearCache } from '@/util/apollo/cache';
import ls from '@/util/localstorage';
import { isWeb } from '@/util/platform';

const Signout = () => {
  ls.clearAuth();
  clearCache();
  if (isWeb()) {
    return <Navigate to={`/${ROUTES.login}`} />;
  } else {
    return <Navigate to={`/${ROUTES.appLogin}`} />;
  }
};

export default Signout;

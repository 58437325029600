import styled from '@/styles';

const TableRow = styled('tr', {
  boxShadow: 'inset 0 -1px 0 0 $colors$neutralA8',

  '&:nth-child(even)': {
    backgroundColor: '$neutralA2',
  },

  variants: {
    clickable: {
      true: {
        cursor: 'pointer',

        '&:hover': {
          backgroundColor: '$neutralA4',
        },
      },
      false: {},
    },
    selected: {
      true: {
        $$selected: '$colors$themeA12',

        backgroundColor: '$$selected',

        '&:nth-child(even)': {
          backgroundColor: '$$selected',
        },

        '&:hover': {
          backgroundColor: '$colors$themeA16',
        },
      },
    },
    disabled: {
      true: {
        $$disabled: '$neutralA12',

        backgroundColor: '$neutralA12',
        '&:nth-child(even)': {
          backgroundColor: '$neutralA12',
        },
      },
      false: {},
    },
    mobile: {
      true: {
        '@phoneOnly': {
          padding: '16px 24px',
          gap: '6px',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        },
      },
      false: {},
    },
    spacing: {
      normal: {
        '@phoneOnly': {
          paddingLeft: '16px',
          paddingRight: '16px',
        },
      },
      loose: {
        '@phoneOnly': {
          paddingLeft: '32px',
          paddingRight: '32px',
        },
      },
      extraloose: {
        '@phoneOnly': {
          paddingLeft: '40px',
          paddingRight: '40px',
        },
      },
      false: {},
    },
  },

  defaultVariants: {
    clickable: false,
  },
});

TableRow.displayName = 'stitches(Table.TableRow)';

export default TableRow;

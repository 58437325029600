import { useFeatureValue } from '@growthbook/growthbook-react';
import { CheckBoxOutlined, MoreVert } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Checkbox,
  IconButton,
  Menu,
  MenuList,
  Snackbar,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useCallback, useState } from 'react';
import { useModal } from 'react-modal-hook';

import CommentsTimesheetModal from '../TimesheetList/modals/CommentsTimesheetModal';
import EditTimesheetModal from '../TimesheetList/modals/EditTimesheetModal';
import {
  JobStatus,
  TimeSheetStatus,
  enableCheckboxForClient,
  getTimeSheetStatus,
  getTimesheetReport,
} from '../util';

import CustomTooltipJob from './TooltipJob';
import WorkerMobileView from './WorkerMobileView';
import useWorkerActions from './useWorkerActions';
import {
  ConfirmedChip,
  HiredChip,
  MenuItemsObject,
  NoShowChip,
  RemovedWorkerChip,
  RenderWorkerInfo,
  getShiftTime,
  renderTimeSheetStatusChip,
} from './workerUtils';

import { EditTimesheetIcon } from '@/assets/icons';
import TextStack from '@/components/TextStack';
import { Body } from '@/components/Typography';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import Button from '@/elements/Button';
import { TableRow, TableCell } from '@/elements/Table';
import Text from '@/elements/Text';
import useMediaQuery from '@/hooks/useMediaQuery';
import { getRoundOff } from '@/routes/Agency/Job/util';
import { jobColors } from '@/styles/colors';
import { Address, GetJobQuery, JobWorkerStatusEnum } from '@/types/graphql';

// types
type WorkerInfo = GetJobQuery['job']['jobWorkers'][0];
type Props = {
  job: GetJobQuery['job'];
  worker: WorkerInfo;
  jobStatus: JobStatus;
  isClientAdmin: boolean;
  setCheckboxIds: (ids: any) => void;
  checkboxIds: string[];
  isSelecting?: boolean;
  setIsSelecting?: (isSelect: boolean) => void;
};

const HiredWorkerRow = ({
  job,
  worker,
  jobStatus,
  isClientAdmin,
  checkboxIds,
  setCheckboxIds,
  isSelecting,
  setIsSelecting,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const workerInfo = worker.worker;
  const timesheet = job.timesheets.find(
    (timesheet) => timesheet.jobWorker.id === worker.id,
  );
  const isRemovedWorker = jobStatus === JobStatus.CANCELLED;

  const reason = worker?.dismissReason || undefined;
  const phoneOnly = useMediaQuery('(max-width: 559px)');

  const [anchorTooltip, setAnchorTooltip] = React.useState(null);
  const handlePopoverOpen = (event) => {
    if (isNoShow && workerInfo) {
      setAnchorTooltip(event.currentTarget);
    }
    reason && setAnchorTooltip(event.currentTarget);
  };
  const handlePopoverClose = () => {
    if (isNoShow && workerInfo) {
      setAnchorTooltip(null);
    }
    reason && setAnchorTooltip(null);
  };
  const openTooltip = Boolean(anchorTooltip);

  const tsStatus = getTimeSheetStatus(timesheet);

  const showRating = useFeatureValue(FEATURE_TOGGLE.WorkerRating, false);

  const [showEditModal, hideEditModal] = useModal(
    () => (
      <EditTimesheetModal
        hideModal={hideEditModal}
        isCancelJobTimesheet={isRemovedWorker}
        jobAddress={job.address as Address}
        jobId={job.id}
        shift={job.shifts[0]}
        timesheet={timesheet}
        variant={
          tsStatus === TimeSheetStatus.APPROVED ||
          tsStatus === TimeSheetStatus.REJECTED
            ? 'DETAIL'
            : 'DEFAULT'
        }
        workerInfo={workerInfo}
      />
    ),
    [timesheet, tsStatus, job?.id, job?.shifts],
  );

  const {
    handleShowed,
    showRemoveModal,
    approveTimesheet,
    unapproveTimesheet,
    rejectTimesheet,
    unRejectTimesheet,
    error,
    setError,
  } = useWorkerActions(job);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleEditTimesheet = async () => {
    showEditModal();
  };

  const [showCommentsModal, hideCommentsModal] = useModal(
    () => (
      <CommentsTimesheetModal
        hideModal={hideCommentsModal}
        timesheet={timesheet!}
      />
    ),
    [timesheet],
  );

  const handleApproveTimesheet = useCallback(async () => {
    const payload = {
      approvedBreakMinutes: timesheet?.reportedBreakMinutes ?? '',
      approvedCheckinAt:
        timesheet?.reportedCheckinAt ?? timesheet?.checkinAt ?? '',
      approvedCheckoutAt:
        timesheet?.reportedCheckoutAt ?? timesheet?.checkoutAt ?? '',
      ratingComment: timesheet?.reportComment,
      tipAmount: timesheet?.tipAmount ?? 0,
      timesheetId: timesheet?.id,
    };
    if (timesheet) {
      await approveTimesheet({
        variables: payload,
      });
      handleCloseMenu();
    }
  }, [timesheet]);

  const handleUnapproveTimesheets = useCallback(async () => {
    await unapproveTimesheet({ variables: { timesheetId: timesheet!.id } });
    handleCloseMenu();
  }, [timesheet?.id]);

  const handleRejectTimesheet = useCallback(async () => {
    await rejectTimesheet({
      variables: {
        timesheetId: timesheet!.id,
        ratingComment: '',
      },
    });
    handleCloseMenu();
  }, [timesheet?.id]);

  const handleUnrejectTimesheet = useCallback(async () => {
    await unRejectTimesheet({
      variables: {
        timesheetId: timesheet!.id,
      },
    });
    handleCloseMenu();
  }, [timesheet?.id]);

  const handleRemoveTimesheet = useCallback(() => {
    showRemoveModal({ id: worker.id });
    setTimeout(() => {
      handleCloseMenu();
    }, 150);
  }, [worker?.id]);

  const handleClose = useCallback((event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setError('');
  }, []);

  const checkIn = getTimesheetReport(
    timesheet?.approvedCheckinAt || null,
    timesheet?.reportedCheckinAt || null,
    timesheet?.checkinAt || null,
  );
  const checkOut = getTimesheetReport(
    timesheet?.approvedCheckoutAt || null,
    timesheet?.reportedCheckoutAt || null,
    timesheet?.checkoutAt || null,
  );

  const approvedTime = timesheet ? getShiftTime(timesheet) : 0;
  const isNoShow = !worker.showed;
  const isConfirmed = worker.status === JobWorkerStatusEnum.CONFIRMED;

  const handleToggleShowed = async () => {
    if (!isNoShow && checkboxIds.includes(worker.id)) {
      setCheckboxIds((ids) => ids.filter((i) => worker.id !== i));
    }
    await handleShowed({
      variables: { jobWorkerId: worker.id, showed: isNoShow },
    });
    handleCloseMenu();
    if (isNoShow && tsStatus === TimeSheetStatus.REJECTED) {
      setTimeout(() => {
        handleUnrejectTimesheet();
      }, 150);
    }
  };
  const handleAddComment = () => {
    showCommentsModal();
    handleCloseMenu();
  };
  const handleCheckClick = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setCheckboxIds((ids) => [...ids, worker.id]);
      } else {
        setCheckboxIds((ids) => ids.filter((i) => i !== worker.id));
      }
    },
    [setCheckboxIds],
  );

  const getMenuItems = () => {
    if (isRemovedWorker && tsStatus && isClientAdmin) {
      return [MenuItemsObject.addComment(handleAddComment, !showRating)].filter(
        (i) => i,
      );
    }

    if (!tsStatus)
      return jobStatus === JobStatus.UPCOMING
        ? [MenuItemsObject.removeWorker(handleRemoveTimesheet)]
        : [MenuItemsObject.noShow(handleToggleShowed)];

    if (isClientAdmin) {
      return GetMenuItemsForClientAdmin(tsStatus)?.filter((i) => i);
    } else {
      return GetMenuItemsForTenantAdmin(tsStatus)?.filter((i) => i);
    }
  };

  const GetMenuItemsForTenantAdmin = (timesheetStatus) => {
    switch (timesheetStatus) {
      case TimeSheetStatus.INCOMPLETE:
        return [
          MenuItemsObject.reject(handleRejectTimesheet),
          MenuItemsObject.addComment(handleAddComment, !showRating),
        ];
      case TimeSheetStatus.PENDING:
        return [
          MenuItemsObject.approve(handleApproveTimesheet),
          MenuItemsObject.reject(handleRejectTimesheet),
          MenuItemsObject.addComment(handleAddComment, !showRating),
        ];
      case TimeSheetStatus.APPROVED:
        return [
          MenuItemsObject.unApprove(handleUnapproveTimesheets),
          MenuItemsObject.addComment(handleAddComment, !showRating),
        ];
      case TimeSheetStatus.REJECTED:
        return [
          MenuItemsObject.unReject(handleUnrejectTimesheet),
          MenuItemsObject.noShow(handleToggleShowed),
          MenuItemsObject.addComment(handleAddComment, !showRating),
        ];
      default:
        break;
    }
  };

  const GetMenuItemsForClientAdmin = (timesheetStatus) => {
    switch (timesheetStatus) {
      case TimeSheetStatus.INCOMPLETE:
        return [MenuItemsObject.addComment(handleAddComment, !showRating)];
      case TimeSheetStatus.PENDING:
        return [
          MenuItemsObject.approve(handleApproveTimesheet),
          MenuItemsObject.addComment(handleAddComment, !showRating),
        ];
      case TimeSheetStatus.APPROVED:
        return [MenuItemsObject.addComment(handleAddComment, !showRating)];
      case TimeSheetStatus.REJECTED:
        return [
          MenuItemsObject.noShow(handleToggleShowed),
          MenuItemsObject.addComment(handleAddComment, !showRating),
        ];
      default:
        break;
    }
  };

  const getChip = () => {
    if (isRemovedWorker) {
      return RemovedWorkerChip;
    } else if (isNoShow) {
      return NoShowChip;
    } else if (isConfirmed && !isClientAdmin) {
      return ConfirmedChip;
    } else {
      return HiredChip;
    }
  };

  const MENU_ITEMS = isNoShow
    ? [MenuItemsObject.cancelNoshow(handleToggleShowed)]
    : getMenuItems();
  const disabledCheckbox =
    isNoShow ||
    (!timesheet && jobStatus !== JobStatus.COMPLETED) ||
    isRemovedWorker ||
    (isClientAdmin && !enableCheckboxForClient(timesheet, jobStatus));

  const disableEdit =
    isNoShow || (!timesheet && jobStatus !== JobStatus.COMPLETED);

  const totalTime =
    timesheet && checkOut
      ? approvedTime > 0
        ? getRoundOff(approvedTime)
        : approvedTime
      : '-';

  const getName = (worker) => {
    let fullName = '';
    const user = worker.noShowMarkedBy;
    if (user) {
      fullName += user.firstName;
      if (user.middleName !== null && user.middleName !== undefined) {
        fullName += ` ${user.middleName}`;
      }
      fullName += ` ${user.lastName}`;
    }
    return fullName;
  };

  return (
    <React.Fragment key={worker.id}>
      {phoneOnly ? (
        <TableRow
          style={{
            backgroundColor: isNoShow ? jobColors.redLight : 'white',
            boxShadow: isSelecting ? 'none' : undefined,
          }}
        >
          <WorkerMobileView
            checkboxIds={checkboxIds}
            disableCheckbox={disabledCheckbox}
            handleAddComment={showCommentsModal}
            handleEditTimesheet={disableEdit ? undefined : handleEditTimesheet}
            isClientAdmin={isClientAdmin}
            isSelecting={isSelecting}
            menuItems={MENU_ITEMS}
            setCheckboxIds={setCheckboxIds}
            setIsSelecting={setIsSelecting}
            statusChip={renderTimeSheetStatusChip(tsStatus)}
            timeSheet={timesheet}
            totalTime={totalTime}
            workerInfo={{ ...workerInfo, workerId: worker.id }}
          />
        </TableRow>
      ) : (
        <>
          <TableRow
            key={worker.id}
            aria-haspopup="true"
            aria-owns={open ? 'mouse-over-popover' : undefined}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <TableCell>
              <Checkbox
                checked={checkboxIds.includes(worker.id)}
                checkedIcon={<CheckBoxOutlined htmlColor="#332F2D" />}
                disabled={disabledCheckbox}
                size="small"
                style={{ padding: 0 }}
                onChange={handleCheckClick}
              />
            </TableCell>
            <TableCell css={{ paddingLeft: '12px' }}>
              <RenderWorkerInfo
                StatusChip={getChip()}
                handleAddComment={showCommentsModal}
                isClientAdmin={isClientAdmin}
                timeSheet={timesheet}
                workerInfo={workerInfo}
              />
            </TableCell>
            <TableCell>
              {timesheet ? (
                <TextStack>
                  <div>
                    <Text color="lighter">Check In: </Text>
                    <Text css={{ paddingLeft: '10px' }} weight={'medium'}>
                      {checkIn && format(checkIn, 'p')}
                    </Text>
                  </div>
                  <div>
                    <Text color="lighter">Check Out: </Text>
                    <Text weight={'medium'}>
                      {checkOut && format(checkOut, 'p')}
                    </Text>
                  </div>
                </TextStack>
              ) : (
                '-'
              )}
            </TableCell>
            <TableCell>
              <Body>
                {timesheet && checkOut
                  ? (timesheet.approvedBreakMinutes ||
                      timesheet.reportedBreakMinutes) + 'm'
                  : '-'}
              </Body>
            </TableCell>
            <TableCell>{totalTime}</TableCell>
            <TableCell>{renderTimeSheetStatusChip(tsStatus)}</TableCell>
            <TableCell>
              {jobStatus === JobStatus.UPCOMING && !timesheet && '-'}
              {jobStatus !== JobStatus.UPCOMING ||
              (jobStatus === JobStatus.UPCOMING && timesheet) ? (
                <Button
                  disabled={disableEdit}
                  id="edit-timesheet-btn"
                  style={{ padding: '4px 12px', borderRadius: '22px' }}
                  onClick={handleEditTimesheet}
                >
                  <EditTimesheetIcon
                    style={{
                      fontSize: '16px',
                    }}
                  />
                </Button>
              ) : (
                ''
              )}
            </TableCell>
            <TableCell>
              {
                <>
                  {timesheet?.updatedBy ? (
                    <>
                      {`${timesheet.updatedBy.firstName} ${timesheet.updatedBy.lastName}`}
                    </>
                  ) : timesheet?.updatedByType === 'GravyWorkSystem' ? (
                    'System Approved'
                  ) : (
                    '-'
                  )}
                </>
              }
            </TableCell>
            <TableCell>
              <IconButton
                aria-label="menu-icon"
                disabled={!(MENU_ITEMS && MENU_ITEMS.length)}
                id="menu-icon"
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <MoreVert />
              </IconButton>
              <Menu
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                  sx: {
                    border: '1px solid #D3D3D3',
                    filter:
                      'drop-shadow(0px 4.050473213195801px 4.050473213195801px rgba(0, 0, 0, 0.05))',
                    boxShadow: 'none',
                  },
                }}
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                id="basic-menu"
                open={open}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                onClose={handleCloseMenu}
              >
                <MenuList
                  dense={true}
                  sx={{ paddingTop: '0px', paddingBottom: '0px' }}
                >
                  {MENU_ITEMS}
                </MenuList>
              </Menu>
            </TableCell>
          </TableRow>
          {isNoShow && workerInfo && (
            <CustomTooltipJob
              anchorEl={anchorTooltip}
              eventDate={worker.noShowMarkedAt}
              eventDoneBy={getName(worker)}
              handlePopoverClose={handlePopoverClose}
              isDropped={false}
              isNoShow={isNoShow}
              open={openTooltip}
              reason={worker.dismissReason ?? ''}
            />
          )}
        </>
      )}

      {error && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={4000}
          open={true}
          onClose={handleClose}
        >
          <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose}>
            <AlertTitle>Error: {error}</AlertTitle>
          </Alert>
        </Snackbar>
      )}
    </React.Fragment>
  );
};

export default HiredWorkerRow;

import { createSvgIcon } from '@mui/material';

const PaymentFailIcon = createSvgIcon(
  <svg
    fill="none"
    height="110"
    viewBox="0 0 110 110"
    width="110"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M55.25 91C75.2703 91 91.5 74.7703 91.5 54.75C91.5 34.7297 75.2703 18.5 55.25 18.5C35.2297 18.5 19 34.7297 19 54.75C19 74.7703 35.2297 91 55.25 91Z"
      fill="#FFF5F5"
      stroke="#DC1515"
      strokeWidth="2"
    />
    <path
      d="M57.6339 87.9079C50.8604 87.9026 44.234 85.8943 38.5582 82.1265C32.8824 78.3587 28.4012 72.9934 25.6579 66.6812C22.9146 60.369 22.0272 53.3812 23.1033 46.5651C24.1794 39.7491 27.1728 33.3977 31.7203 28.2812C28.1523 31.51 25.2696 35.4482 23.2494 39.8539C21.2291 44.2596 20.114 49.0397 19.9724 53.9007C19.8309 58.7617 20.6659 63.6008 22.4262 68.1209C24.1866 72.6409 26.835 76.7464 30.2089 80.1852C33.5827 83.6239 37.6108 86.3233 42.0456 88.1175C46.4803 89.9117 51.2282 90.7628 55.9974 90.6185C60.7667 90.4742 65.4567 89.3376 69.7792 87.2785C74.1018 85.2194 77.9657 82.2813 81.1335 78.6447C74.7094 84.6139 66.3269 87.9182 57.6339 87.9079Z"
      fill="#FFE1E1"
    />
    <circle cx="41.2227" cy="15.5" fill="#FFE1E1" r="2.5" stroke="#FFE1E1" />
    <circle cx="33.2227" cy="104.5" r="2.5" stroke="#FFE1E1" />
    <circle cx="102.223" cy="75.5" fill="#FFE1E1" r="1.5" stroke="#FFE1E1" />
    <circle cx="3.22266" cy="35.5" r="1" stroke="#FFE1E1" />
    <circle cx="89" cy="15.5" r="2" stroke="#FFE1E1" />
    <path
      d="M65 44L44 65M65 65L44 44"
      stroke="#DC1515"
      strokeLinecap="round"
      strokeWidth="2"
    />
  </svg>,
  'PaymentFailIcon'
);
export default PaymentFailIcon;

import useModal from '@area2k/use-modal';

import UpdateUniformModal from './UpdateUniformModal';

import Avatar from '@/components/Avatar';
import Card from '@/components/Card';
import Stack from '@/components/Stack';
import StackContainer from '@/components/StackContainer';
import { Body, Small } from '@/components/Typography';
import { GetJobQuery, JobStatusEnum } from '@/types/graphql';
import '../index.css';

type Props = {
  job: GetJobQuery['job'];
};

const UniformSection = ({ job }: Props) => {
  const [showCreateModal, hideCreateModal] = useModal(
    () => <UpdateUniformModal hideModal={hideCreateModal} job={job} />,
    [job]
  );

  const { uniform, uniformInstructions, status } = job;

  return (
    <Card.Section
      actions={
        status === JobStatusEnum.UPCOMING
          ? [
              {
                a11yLabel: 'Change job dress code',
                label: 'Change',
                onAction: showCreateModal,
                id: 'change-job-dress-code',
              },
            ]
          : undefined
      }
      styleHeading={true}
      title="Dress code"
    >
      <StackContainer
        className="scrollbar"
        css={{
          paddingBottom: '50px',
          WebkitMaskImage:
            'linear-gradient(to bottom, black 80%, transparent 100%)',
        }}
      >
        <Stack vertical gap={12}>
          <Stack gap={20}>
            <div>
              <Avatar src={uniform.imageUrl} />
            </div>
            <Body>{uniform.name}</Body>
          </Stack>
          {uniformInstructions && (
            <Small>&quot;{uniformInstructions}&quot;</Small>
          )}
        </Stack>
      </StackContainer>
    </Card.Section>
  );
};

export default UniformSection;

import { createSvgIcon } from '@mui/material';

const AccountStateErrorIcon = createSvgIcon(
  <svg
    fill="none"
    height="50"
    viewBox="0 0 50 50"
    width="50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="attention / error_outline">
      <path
        d="M24.9793 45.8307C13.476 45.8192 4.15909 36.4867 4.16675 24.9835C4.17442 13.4803 13.5038 4.16023 25.007 4.16406C36.5102 4.1679 45.8334 13.4942 45.8334 24.9974C45.8265 36.5086 36.4905 45.8353 24.9793 45.8307ZM8.33342 25.3557C8.43199 34.525 15.9191 41.8923 25.0887 41.8432C34.2584 41.7937 41.6658 34.3463 41.6658 25.1765C41.6658 16.0068 34.2584 8.55942 25.0887 8.50988C15.9191 8.46084 8.43199 15.8281 8.33342 24.9974V25.3557ZM27.0834 35.4141H22.9168V31.2474H27.0834V35.4141ZM27.0834 27.0807H22.9168V14.5807H27.0834V27.0807Z"
        fill="#262626"
        id="coolicon"
      />
    </g>
  </svg>,
  'AccountStateErrorIcon'
);
export default AccountStateErrorIcon;

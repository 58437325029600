/* eslint-disable react/prop-types */
import { VariantProps } from '@stitches/react';
import {
  ComponentPropsWithoutRef,
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
} from 'react';

import Stack from './Stack';
import { Small } from './Typography';

import Cover from '@/elements/Cover';
import Input from '@/elements/Input';
import styled from '@/styles';

const FocusCover = styled(Cover, {
  backgroundColor: 'white',
  border: '1px solid $colors$neutralLight',
  borderRadius: '$lg',

  focusPseudoElement: {
    element: 'after',
    activator: 'textarea:focus + &',
    borderWidth: 1,
  },
});

const Wrapper = styled('div', {
  display: 'flex',
  width: '100%',

  [`> ${Input}`]: {
    paddingBottom: 8,
    paddingTop: 8,
    resize: 'none',

    background: 'none',
    borderColor: 'transparent',

    lineHeight: 1.5,

    zIndex: 2,
  },
});

const Count = styled('span', {
  position: 'absolute',
  top: '-29px',
  zIndex: '9',
  color: '#6E8476',
  fontSize: '12px',
  fontWeight: '400',
});

type Props = ComponentPropsWithoutRef<'textarea'> &
  VariantProps<typeof Input> & { maxLengthPosition?: 'top' | 'bottom' };
type TextAreaType = ForwardRefExoticComponent<
  Props & RefAttributes<HTMLTextAreaElement>
>;

const TextArea = forwardRef<HTMLTextAreaElement>(
  ({ maxLength, value, maxLengthPosition, ...rest }: Props, ref) => {
    return (
      <Stack
        vertical
        align="end"
        justify="end"
        style={{
          position: maxLengthPosition === 'top' ? 'relative' : 'initial',
        }}
      >
        {maxLength && maxLengthPosition === 'top' && (
          <Count>
            {value?.toString().length}/{maxLength}
          </Count>
        )}
        <Wrapper>
          <Input
            ref={ref}
            as="textarea"
            maxLength={maxLength}
            maxLengthPosition={maxLengthPosition}
            rows={4}
            value={value}
            {...rest}
          />
          <FocusCover />
        </Wrapper>
        {maxLength && maxLengthPosition === 'bottom' && (
          <Small>
            {value?.toString().length}/{maxLength}
          </Small>
        )}
      </Stack>
    );
  },
) as TextAreaType;

TextArea.displayName = 'TextArea';

export default TextArea;

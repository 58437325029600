export const FEATURE_TOGGLE = {
  GroupChat: 'v2.2402.06_group-chat',
  BillingAccountOrderCreation: 'v2.2402.20_billing-account-order-creation',
  WorkerRating: 'worker-rating',
  HideBrokenReports: 'hide-broken-reports',
  ClientAdminToDeleteAccount:
    'v2.2405.14_allow-customer-admins-to-delete-account',
  DashboardApprovalStatusFeature: 'v2.2410.22_needs-approval',
  SelectNearestAddressRateFeature: 'v2.2404.30_select_nearest_address_rate',
  MarkupBasedPricingFeature: 'v2.2406.11_markup-based-pricing',
  SameWorkerAllShifts: 'v2.2401.09_same-worker-all-shifts',
  RemoveAddressCreationOnOrder:
    'v2.2405.28_remove-address-creation-during-order-creation',
  WorkerProfileRevamp: 'v2.2406.11_worker-profile-revamp',
  RemoveHolidayRates: 'v2.2407.02_remove-holiday-rates',
  LockBillRates: 'v2.2408.06_lock_bill_rates',
  HideDisabledBillingAccount: 'v2.2408.06_disable_billing_accounts',
  ForceUpdateClientApp: 'v2.2408.20_force-update-client-app',
  HelpdeskRole: 'v2.2409.03_helpdesk-role',
  TipsInCents: 'v2.2409.10_add-cents-in-tips',
  TipsInCentsWeb: 'v2.2409.10_add-cents-in-tips-web',
  JobTimezoneFeature: 'v2.2410.08_show-job-as-per-timezone',
  ConfigurableCheckinFeature: 'v2.2410.08_configurable-checkin-distance',
  EnablePayrollProcessBackgroundJob:
    'v2.2410.08_enable-payroll-process-background-job',
};

import Button from '@/components/Button';
import Dropdown from '@/components/Dropdown';
import TextStack from '@/components/TextStack';
import { Body, Small } from '@/components/Typography';
import styled from '@/styles';
import { DurationEnum, WorkerReference } from '@/types/graphql';

const Wrapper = styled('div', {
  $$bgColor: '$colors$neutralLightest',
  $$borderColor: '$colors$neutralLight',

  width: '100%',
  heigth: 'auto',
  padding: '20px 10px',
  marginBottom: '8px',

  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',

  backgroundColor: '$$bgColor',
  border: '1px solid $$borderColor',
  borderRadius: '5px',
});

const ContentInfo = styled('div', {
  $$borderColor: '$colors$neutralLight',

  width: '100%',
  heigth: 'auto',
  padding: '15px',
  marginBottom: '8px',

  border: '1px solid $$borderColor',
  borderRadius: '5px',
});

type Props = {
  reference: WorkerReference;
  handleRemove: (item) => void;
};

const ExperienceItem = ({ reference }: Props) => {
  const referenceDuration = (duration) => {
    switch (duration) {
      case DurationEnum.FROM_0_TO_6_MONTHS:
        return '0-6 months';
      case DurationEnum.FROM_6_TO_12_MONTHS:
        return '6-12 months';
      case DurationEnum.FROM_1_TO_3_YEARS:
        return '1-3 years';
      case DurationEnum.MORE_THAN_3_YEARS:
        return '+3 years';
      default:
        return duration;
    }
  };

  return (
    <Wrapper>
      <TextStack>
        <Body>{`${reference.employerName} - ${reference.city}`}</Body>
        <Small>{reference.name}</Small>
        <Small>{reference.phoneNumber}</Small>
      </TextStack>
      {/* <IconicButton
        a11yLabel="delete"
        icon={faTimes}
        appearance="clear"
        onClick={() => handleRemove(reference)}
      /> */}
      <Dropdown
        Content={() => (
          <ContentInfo>
            <TextStack>
              <Small>Duration</Small>
              <Body>{referenceDuration(reference.duration)}</Body>
              <Small>Summary</Small>
              <Body>{reference.summary}</Body>
            </TextStack>
          </ContentInfo>
        )}
        justify="right"
        size="sm"
      >
        <Button
          a11yLabel={''}
          appearance="outline"
          id="btn-more-info"
          label="More info"
          type="button"
        />
      </Dropdown>
    </Wrapper>
  );
};

export default ExperienceItem;

import { useCallback } from 'react';

import { PayrollItem } from '../../PayrollDetail';

import Card from '@/components/Card';
import Table from '@/components/Table';
import TableHeader from '@/components/TableHeader';
import { TableCell, TableRow } from '@/elements/Table';
import { PayrollItemStatusEnum, PayrollItemTax } from '@/types/graphql';

const taxesHeaderFields = ['Description', 'Payer', 'Amount'];

type Props = {
  payrollStatus?: PayrollItemStatusEnum;
  taxes: PayrollItem['taxes'];
};
const ItemTaxesCard = ({ taxes }: Props) => {
  const renderTaxes = useCallback(
    (tax: PayrollItemTax) => (
      <TableRow key={tax.checkTaxId}>
        <TableCell style={{ width: '45%' }}>{tax.description}</TableCell>
        <TableCell>{tax.payer} </TableCell>
        <TableCell>{tax.amount}</TableCell>
      </TableRow>
    ),
    []
  );
  return (
    <Card title="Taxes">
      <Table>
        <TableHeader fields={taxesHeaderFields} />
        <tbody>{taxes!.map(renderTaxes)}</tbody>
      </Table>
    </Card>
  );
};

export default ItemTaxesCard;

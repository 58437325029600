import {
  addDays,
  differenceInCalendarDays,
  differenceInHours,
  differenceInMinutes,
  format,
  parseISO,
  startOfWeek,
  subYears,
} from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import { DATE_FORMAT } from '@/constants/datetime';
import { getWeeksRanges } from '@/routes/Agency/Payrolls/util';
import { DefaultDueDateEnum } from '@/types/graphql';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { gb } from '@/App';
import debug from './debug';

const timezoneFeatureEnabled = () => {
  return gb.getFeatureValue(FEATURE_TOGGLE.JobTimezoneFeature, false);
};

export const getTimeZone = (
  date: Date,
  timezone: string,
  timezoneName: 'short' | 'shortOffset',
) => {
  if (
    !date ||
    !Number.isFinite(date.getDate()) ||
    !Number.isFinite(date.getTime())
  )
    return '';

  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    timeZoneName: timezoneName,
    timeZone: timezone,
  }).formatToParts(date);
  const part = dateFormatter.find((part) => part.type === 'timeZoneName');
  return part ? part.value : '';
};

export const getTimezoneAbbreviation = (
  timezone: string,
  date?: string,
  locale: boolean = false,
) => {
  if (
    (Intl.DateTimeFormat().resolvedOptions().timeZone !== timezone || locale) &&
    timezoneFeatureEnabled()
  ) {
    return getTimeZone(date ? new Date(date) : new Date(), timezone, 'short');
  }
  return null;
};

export const getTimezoneOffset = (date: Date, timezone: string) => {
  const offset =
    fromZonedTime(date, timezone, {
      timeZone: timezone,
    }).getTimezoneOffset() / 60;
  return offset * -1;
};

export const getFormattedDateInTimeZone = (date: Date, timezone: string) => {
  return timezoneFeatureEnabled() ? fromZonedTime(date, timezone) : date;
};

export const convertTZ = (date, tzString) => {
  if (timezoneFeatureEnabled()) {
    return new Date(
      (typeof date === 'string' ? new Date(date) : date).toLocaleString(
        'en-US',
        {
          timeZone: tzString,
        },
      ),
    );
  } else {
    return typeof date === 'string' ? parseISO(date) : date;
  }
};

export const formatISO = (
  dateString: string,
  formatString: string,
  timezone: string = 'America/New_York',
) => {
  return format(convertTZ(dateString, timezone), formatString);
};

export const closestDateToISO = (baseDate: string, ...dates: string[]) => {
  const base = parseISO(baseDate);

  return dates
    .map((date) => parseISO(date))
    .sort((a, b) => {
      const distanceA = Math.abs(differenceInMinutes(base, a));
      const distanceB = Math.abs(differenceInMinutes(base, b));

      if (distanceA > distanceB) {
        return 1;
      } else if (distanceB > distanceA) {
        return -1;
      }

      return 0;
    })[0];
};

export const parseDateComment = (dateString: string) => {
  const date = parseISO(dateString);
  return format(date, 'MM/dd/yyyy, h:mm aaa');
};

export const isDateInvalid = (date: Date) => isNaN(date.getTime());

export const weekdaysShort = () => {
  const firstDOW = startOfWeek(new Date());
  const shortWeekDaysArray = Array.from(Array(7)).map((e, i) =>
    format(addDays(firstDOW, i), 'eee'),
  );
  return shortWeekDaysArray;
};

export const stringToEnumInvoiceDueDate = (invoiceDueDate: string) => {
  switch (invoiceDueDate) {
    case '10':
      return DefaultDueDateEnum.INVOICE_DUE_10_DAYS;
    case '30':
      return DefaultDueDateEnum.INVOICE_DUE_30_DAYS;
    case '45':
      return DefaultDueDateEnum.INVOICE_DUE_45_DAYS;
    case '60':
      return DefaultDueDateEnum.INVOICE_DUE_60_DAYS;
    case '15':
      return DefaultDueDateEnum.INVOICE_DUE_15_DAYS;
    default:
      return DefaultDueDateEnum.INVOICE_DUE_15_DAYS;
  }
};

export const parseTimestamp = (date: string) =>
  new Date(Number(date) * 1000).toISOString().split('T')[0];

export const getWeekRangesOptions = (date?: Date) => {
  const startDate = subYears(date ?? new Date(), 1);
  const endDate = date ?? new Date();

  return getWeeksRanges(startDate, endDate).map((dates) => {
    const formattedStartDate = format(dates.start!, DATE_FORMAT);
    const formattedEndDate = format(dates.end!, DATE_FORMAT);

    return {
      label: `${formattedStartDate} - ${formattedEndDate}`,
      value: dates.start.toISOString(),
    };
  });
};

export const isTodaysDate = (date: Date) =>
  differenceInCalendarDays(date, new Date()) === 0;

export const isWithin24Hours = (date: Date) => {
  const diffrence = differenceInHours(date, new Date());
  const minutes = differenceInMinutes(date, new Date());
  return minutes < 0 ? false : diffrence >= 0 && diffrence < 24;
};

export const dateFormatter = (date) => {
  if (!date) {
    return '';
  }
  const formattedDate = new Date(Number(date) / 10000);
  const dateDifferenceInTime = new Date().getTime() - formattedDate.getTime();

  const dateDifferenceInDays = dateDifferenceInTime / (1000 * 60 * 60 * 24);

  if (dateDifferenceInDays < 1) {
    return format(formattedDate, 'hh:mm a'); // 10:04 am
  } else if (dateDifferenceInDays < 2) {
    return 'Yesterday'; // just YesterDay
  } else {
    return format(formattedDate, 'MM/dd/yyyy');
  }
};

export const getTimeString = (date: Date) => format(date, 'hh:mm a');

export const getDateandLabel = (inputDate: Date) => {
  if (isTodaysDate(inputDate)) {
    return 'Today';
  } else if (differenceInCalendarDays(inputDate, new Date()) === -1) {
    return 'Yesterday';
  } else {
    return format(inputDate, 'EEEE, do LLLL');
  }
};

export const dateToTimeToken = (date: string) => {
  if (date) {
    return (new Date(date).getTime() * 10000).toString();
  }
  return '';
};

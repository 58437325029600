import { faComment } from '@fortawesome/free-solid-svg-icons';
import { format, parseISO } from 'date-fns';
import { useCallback } from 'react';

import EmptyState from './EmptyState';
import Stack from './Stack';
import TextStack from './TextStack';

import ResourceList from '@/components/ResourceList';
import { Body, Small } from '@/components/Typography';
import { GetWorkerQuery } from '@/types/graphql';

type CommentItem = GetWorkerQuery['worker']['comments'][0];

export type Props = {
  gap?: number;
  worker: GetWorkerQuery['worker'];
};

const CommentsList = ({ gap, worker }: Props) => {
  const renderComment = useCallback(
    (item: CommentItem) => (
      <ResourceList.Item key={item.id} to={`./`}>
        <Stack wrap gap={gap}>
          <TextStack>
            <Body>{item.body}</Body>
          </TextStack>
          <Stack wrap justify="apart">
            <Small color="theme">
              {format(parseISO(item.createdAt), 'MMMM dd, yyyy')}
            </Small>
            <Small color="theme">
              {item.actor.user.firstName} {item.actor.user.lastName}
            </Small>
          </Stack>
        </Stack>
      </ResourceList.Item>
    ),
    [worker]
  );

  return (
    <ResourceList
      emptyState={
        <EmptyState
          icon={faComment}
          text="Add a comment if necessary"
          title="No comments about the GravyWorker"
        />
      }
      items={worker.comments}
      renderItem={renderComment}
    />
  );
};

export default CommentsList;

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

import Alert from './Alert';
import Modal from './Modal';
import Stack from './Stack';
import { Body } from './Typography';

import Button from '@/components/Button';
import Card from '@/components/Card';
import {
  handleGraphQLError,
  hasGraphQLErrors,
  isApolloError,
} from '@/util/error';

type Props = {
  title: string;
  bodyContentText: string;
  acceptButtonLabel: string;
  denyButtonLabel: string;
  modalSize?: string;
  acceptAction: () => void;
  denyAction: () => void;
  hideModal: () => void;
  errorDescription?: string;
  errorIcon?: IconDefinition;
  errorTitle?: string;
  loading?: boolean;
};

const ConfirmationModal = ({
  title,
  bodyContentText,
  acceptButtonLabel,
  denyButtonLabel,
  modalSize = 'sm',
  acceptAction,
  denyAction,
  hideModal,
  errorDescription = 'Something went wrong, please contact support',
  errorIcon = faExclamationTriangle,
  errorTitle = 'An error has occurred',
  loading = false,
}: Props) => {
  const [error, setError] = useState<{
    message: string;
    title: string;
  } | null>(null);

  const [alertStatus, setAlertStatus] = useState<string>('danger'); // [1
  const [hideContent, setHideContent] = useState<boolean>(false); // [1

  const handleAccept = async () => {
    try {
      await acceptAction();
      hideModal();
    } catch (err: any) {
      if (isApolloError(err) && hasGraphQLErrors(err)) {
        handleGraphQLError(err, {
          all: (e) => {
            setError((prevValues) => ({
              ...prevValues,
              title: errorTitle,
              message: e.message ?? errorDescription,
            }));
          },
        });
      }
      if (err?.response?.data?.message) {
        if (err.response.data.alertStatus === 'warning') {
          setAlertStatus(err.response.data.alertStatus);
          setHideContent(true);
        }
        setError((prevValues) => ({
          ...prevValues,
          title: err.response.data.key,
          message: err.response.data.message,
        }));
      }
    }
  };

  const handleDeny = async () => {
    try {
      await denyAction();
      hideModal();
    } catch (err: unknown) {
      if (isApolloError(err) && hasGraphQLErrors(err)) {
        handleGraphQLError(err, {
          all: (e) => {
            setError((prevValues) => ({
              ...prevValues,
              title: errorTitle,
              message: e.message ?? errorDescription,
            }));
          },
        });
      }
    }
  };

  return (
    <Modal
      disableClickout
      size={modalSize}
      title={title}
      onRequestClose={hideModal}
    >
      <Card.Section>
        {error && (
          <Alert
            data-testid="confirmation-modal-alert"
            description={error.message}
            icon={errorIcon}
            status={alertStatus}
            title={error.title}
          />
        )}
        {!hideContent && (
          <>
            <Body>{bodyContentText}</Body>
            <Stack css={{ marginTop: '10px' }} gap={10} justify="end">
              <Button
                a11yLabel="deny"
                appearance="outline"
                isLoading={undefined}
                label={denyButtonLabel}
                status="danger"
                type="button"
                onClick={() => handleDeny()}
              />

              <Button
                a11yLabel="accept"
                isLoading={loading}
                label={acceptButtonLabel}
                type="button"
                onClick={() => handleAccept()}
              />
            </Stack>
          </>
        )}
      </Card.Section>
    </Modal>
  );
};

export default ConfirmationModal;

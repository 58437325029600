import { RefAttributes } from 'react';

import styled from '@/styles';

const Card = styled('div', {
  height: '100%',
  width: '100%',

  variants: {
    active: {
      true: {},
      false: {
        pointerEvents: 'none',
        opacity: 0.4,
        '@phoneOnly': {
          opacity: 0.6,
        },
      },
    },
  },
});

type Props = {
  active: boolean;
  children: RefAttributes<HTMLDivElement>;
};

const ActiveCard = ({ active: isActivate, children }: Props) => {
  return <Card active={isActivate}>{children}</Card>;
};

export default ActiveCard;

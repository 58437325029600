import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { StyledPage } from '../../SignUp/styles';

import Card from '@/components/Card';
import GravyWorkLogo from '@/components/GravyWorkLogo';
import Stack from '@/components/Stack';
import { Body, Subheading } from '@/components/Typography';
import { V3Endpoints } from '@/constants/urls';
import Text from '@/elements/Text';
import ROUTES from '@/routes/routes';
import axiosClient from '@/util/axios/axiosClient';

const PayInvoice = () => {
  const { invoiceId } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const getInvoiceLink = async (invoiceId: string) => {
    axiosClient
      .get(`${V3Endpoints.GET_INVOICE_LINK}${invoiceId}/invoice-link`)
      .then((res) => {
        if (res?.data?.stripeInvoiceLink) {
          window.location.href = res?.data?.stripeInvoiceLink;
        } else {
          setError(true);
        }
      })
      .catch(() => {
        setError(true);
      });
  };
  useEffect(() => {
    if (!invoiceId) {
      navigate(ROUTES.linkExpire);
    } else {
      try {
        getInvoiceLink(invoiceId);
      } catch (e) {
        setError(true);
      }
    }
  }, [invoiceId]);

  return (
    <StyledPage size="xs">
      <Card>
        <Stack css={{ paddingTop: '20px' }} justify="center">
          <GravyWorkLogo />
        </Stack>
        <Card.Section>
          <Stack
            vertical
            style={{
              alignItems: 'center',
              alignSelf: 'center',
            }}
          >
            <img
              css={{ width: '80px', height: '80px' }}
              src={
                error
                  ? require('../../../assets/img/error.png')
                  : require('../../../assets/img/approved.png')
              }
            />
            <Subheading>
              {error ? 'Unable to Locate Invoice' : 'Hang Tight!'}
            </Subheading>
            <Body
              css={{ width: '70%', textAlign: 'center', paddingBottom: '20px' }}
            >
              {error
                ? 'The system encountered an error while locating the requested invoice.'
                : 'You’re being redirected to the payment screen.'}
              {error && (
                <Body css={{ paddingTop: '12px' }}>
                  Please visit your{' '}
                  <a href={'/invoices'} style={{ textDecoration: 'none' }}>
                    {' '}
                    <Text color={'theme'}>Invoices </Text>
                  </a>{' '}
                  or contact{' '}
                  <a
                    href={'mailto:billing@gravywork.com'}
                    style={{ textDecoration: 'none' }}
                  >
                    <Text color={'theme'}>billing@gravywork.com </Text>
                  </a>
                  for assistance.
                </Body>
              )}
            </Body>
          </Stack>
        </Card.Section>
      </Card>
    </StyledPage>
  );
};

export default PayInvoice;

import { useState, useEffect } from 'react';

const useNullableMediaQuery = (query, initialValue: boolean | null = false) => {
  const [matches, setMatches] = useState<boolean | null>(initialValue);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [matches, query]);

  return matches;
};

export default useNullableMediaQuery;

import { Capacitor } from '@capacitor/core';

/**
 * Checks if the application is running on a native platform using Capacitor.
 *
 * @returns {boolean} True if the application is running on a native platform, otherwise false.
 */
export function isNativePlatform(): boolean {
  return Capacitor.isNativePlatform();
}

/**
 * Retrieves the platform on which the application is currently running using Capacitor.
 *
 * @returns {string} A string representing the platform, such as 'web', 'ios', 'android', etc.
 */
export function getPlatform(): string {
  return Capacitor.getPlatform();
}

import { createSvgIcon } from '@mui/material';

const NoFavIcon = createSvgIcon(
  <svg
    fill="none"
    height="300"
    viewBox="0 0 300 300"
    width="300"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="100.559"
      cy="36.2501"
      fill="#ADDB9B"
      r="6.75"
      stroke="#ADDB9B"
      strokeWidth="1.5"
    />
    <circle cx="80.5586" cy="253.75" r="6.5" stroke="#ADDB9B" strokeWidth="2" />
    <circle
      cx="292.344"
      cy="175.536"
      fill="#ADDB9B"
      r="4.25"
      stroke="#ADDB9B"
      strokeWidth="1.5"
    />
    <circle
      cx="5.55859"
      cy="86.2501"
      r="2.5"
      stroke="#ADDB9B"
      strokeWidth="2"
    />
    <circle cx="247.5" cy="272.5" r="2.5" stroke="#ADDB9B" strokeWidth="2" />
    <circle cx="270" cy="61.2501" r="5" stroke="#ADDB9B" strokeWidth="2" />
    <rect
      fill="#E7F3E6"
      height="82.5903"
      rx="3"
      stroke="#45A735"
      strokeWidth="3"
      width="210.156"
      x="36"
      y="100.5"
    />
    <circle
      cx="82.6112"
      cy="141.386"
      fill="#ADDB9B"
      r="25.3495"
      stroke="#45A735"
      strokeWidth="3"
    />
    <path
      d="M127.586 124.214H204.452"
      stroke="#45A735"
      strokeLinecap="round"
      strokeWidth="3"
    />
    <path
      d="M127.586 140.569H187.28"
      stroke="#45A735"
      strokeLinecap="round"
      strokeWidth="3"
    />
    <path
      d="M127.586 156.923H169.29"
      stroke="#45A735"
      strokeLinecap="round"
      strokeWidth="3"
    />
    <path
      d="M235.085 159.482L233.889 161.062L232.693 159.481C229.086 154.714 223.186 151.575 216.478 151.575C205.394 151.575 196.547 160.074 196.547 170.393C196.547 171.848 196.742 173.268 197.081 174.65L197.094 174.703L197.103 174.756C198.519 183.09 204.168 191.828 211.416 199.171C218.487 206.336 226.891 211.98 233.889 214.515C240.886 211.98 249.29 206.336 256.361 199.171C263.609 191.828 269.257 183.09 270.67 174.757L270.679 174.701L270.693 174.646C271.036 173.266 271.231 171.848 271.231 170.393C271.231 160.074 262.384 151.575 251.302 151.575C244.592 151.575 238.693 154.714 235.085 159.482Z"
      fill="#45A735"
      stroke="#45A735"
      strokeWidth="3"
    />
  </svg>,
  'NoFavIcon'
);
export default NoFavIcon;

import { useMemo } from 'react';

import Action from '@/components/Action';
import Card from '@/components/Card';
import TextStack from '@/components/TextStack';
import { Small } from '@/components/Typography';
import { mapUrl } from '@/routes/Agency/Customer/AddressList';
import { GenericActionOrList } from '@/types';
import type { GetAccountQuery } from '@/types/graphql';

interface AddressSectionProps {
  account: GetAccountQuery['account'];
}

const AddressSection = ({ account }: AddressSectionProps) => {
  const addressAction = useMemo<GenericActionOrList>(
    () => ({
      a11yLabel: `Open new tab to ${mapUrl(account)}`,
      label: account.addressLine1!,
      href: mapUrl(account),
      external: true,
    }),
    [account]
  );

  return (
    <Card.Section title="Billing Address">
      <TextStack>
        <Action.Button action={addressAction} />
        {account.addressLine2 && <Small>Ste: {account.addressLine2}</Small>}
        <Small>
          {account.city}, {account.state} {account.zip}
        </Small>
      </TextStack>
    </Card.Section>
  );
};

export default AddressSection;

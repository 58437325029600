import { Dispatch, SetStateAction } from 'react';

import { ItemSelected } from '../PayrollDetail';

import ItemDeductionsCard from './DeductionsCard';
import ItemEarningsCard from './EarningsCard';
import ItemDetailsCard from './ItemDetailsCard';
import PageSkeleton from './PageSkeleton';
import ItemTaxesCard from './TaxesCard';

import Button from '@/components/Button';
import Page from '@/components/Page';
import Stack from '@/components/Stack';
import {
  SimpleContractorPaymentsFragment,
  SimplePayrollItemFragment,
} from '@/types/graphql';

type Props = {
  item: SimplePayrollItemFragment | SimpleContractorPaymentsFragment | null;
  setItem: Dispatch<SetStateAction<ItemSelected>>;
  context: 'item' | 'contractor';
};

export const isPayrollItem = (
  item: SimplePayrollItemFragment | SimpleContractorPaymentsFragment | null
): item is SimplePayrollItemFragment =>
  (item as SimplePayrollItemFragment).taxes !== undefined;

const ItemPayrollDetails = ({ item, setItem, context }: Props) => {
  if (!item) {
    return <PageSkeleton />;
  }

  const earnings = isPayrollItem(item)
    ? item.earnings
    : item.contractorPaymentItems;

  const title = isPayrollItem(item) ? 'Payroll Item' : 'Contractor Payment';
  const deductions = isPayrollItem(item) ? item.deductions : null;
  const taxes = isPayrollItem(item) ? item.taxes : null;

  return (
    <Page size="lg" title={title}>
      <Stack vertical gap={30}>
        <ItemDetailsCard item={item} />
        <ItemEarningsCard earnings={earnings} />
        {context === 'item' && deductions && deductions.length > 0 && (
          <ItemDeductionsCard deductions={deductions} />
        )}
        {context === 'item' && taxes && (
          <ItemTaxesCard payrollStatus={item.status} taxes={taxes} />
        )}
        <Stack justify="end">
          <Button
            a11yLabel="Go back"
            appearance="primary"
            id="btn-go-back"
            label="Go Back"
            type="button"
            onClick={() =>
              setItem({
                showItem: false,
                item: null,
                type: null,
              })
            }
          />
        </Stack>
      </Stack>
    </Page>
  );
};

export default ItemPayrollDetails;

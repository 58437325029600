import { endOfDay } from 'date-fns';
import { useState, useEffect } from 'react';

import { invoiceStatusOptions } from '../../constants';

import Button from '@/components/Button';
import DateRangeInput, { DateContext } from '@/components/DateRangeInput';
import Modal from '@/components/Modal';
import Option from '@/components/Option';
import Stack from '@/components/Stack';
import Text from '@/elements/Text';
import {
  TimesheetFilterSetInput,
  TimesheetInvoiceStatusEnum,
} from '@/types/graphql';

type Props = {
  filters: TimesheetFilterSetInput;
  hideModal: () => void;
  onChangeFilter: (arg: TimesheetFilterSetInput) => void;
};

const InvoiceFiltersModal = ({ filters, hideModal, onChangeFilter }: Props) => {
  const [able, setAble] = useState(false);
  const [filterParams, setFilterParams] = useState(filters);

  const handleOnChangeFilter = <K extends keyof TimesheetFilterSetInput>(
    key: K,
    value: NonNullable<TimesheetFilterSetInput[K]>
  ) => {
    setFilterParams((prevValue) => ({ ...prevValue, [key]: value }));
  };

  useEffect(() => {
    if (able && Object.keys(filterParams).length !== 0) {
      const finalFilters = {};
      Object.keys(filterParams).map((key: any) => {
        finalFilters[key] = filterParams[key];
        return null;
      });
      if (
        !filterParams.approvedCheckinAt?.from ||
        !filterParams.approvedCheckinAt?.to
      ) {
        finalFilters.approvedCheckinAt = undefined;
      }
      if (
        finalFilters.approvedCheckinAt !== filters.approvedCheckinAt ||
        finalFilters.invoiceStatus !== filters.invoiceStatus
      ) {
        onChangeFilter(finalFilters);
      }
    }
  }, [able]);

  const [DateRanges, setDateRanges] = useState({
    from: filterParams?.approvedCheckinAt?.from || undefined,
    to: filterParams?.approvedCheckinAt?.to || undefined,
  });

  const handleRangeChange = (date: Date, context: DateContext) => {
    let dateString;
    try {
      dateString = date.toISOString();
    } catch {}
    if (context === 'startDate') {
      setDateRanges({
        ...DateRanges,
        from: dateString,
      });
    }
    if (context === 'endDate') {
      setDateRanges({
        ...DateRanges,
        to: endOfDay(date).toISOString(),
      });
    }
  };

  useEffect(() => {
    handleOnChangeFilter('approvedCheckinAt', { ...DateRanges });
  }, [DateRanges]);

  return (
    <Modal
      disableClickout
      size="xs"
      title="Change Filters"
      wrapperBackground={true}
      onRequestClose={hideModal}
    >
      <Stack style={{ padding: '20px' }} vertical={true}>
        <Text as="h4" css={{ color: '#262626' }} weight={'bold'}>
          Date
        </Text>
        <DateRangeInput
          endDate={DateRanges.to}
          fromLabel=""
          minDate={DateRanges.from}
          startDate={DateRanges?.from}
          toLabel=""
          onChange={handleRangeChange}
        />
        <Text as="h4" css={{ color: '#262626' }} weight={'bold'}>
          Status
        </Text>
        <Stack gap={20} wrap={true}>
          {invoiceStatusOptions.map((item, index) => (
            <Option
              key={`invoice-status-${index}`}
              appearance="bullseye"
              checked={filterParams?.invoiceStatus === item.value}
              id={`invoice-status-${index}`}
              label={item.label}
              type="radio"
              value={item.value}
              onChange={(ev) =>
                handleOnChangeFilter(
                  'invoiceStatus',
                  ev.target.value as TimesheetInvoiceStatusEnum
                )
              }
            />
          ))}
        </Stack>
        <Stack justify="end" style={{ marginTop: '10px' }}>
          <Button
            a11yLabel="Save all filters"
            appearance="primary"
            id="btn-save-filter"
            label="Apply Filter"
            onClick={() => {
              setAble(true);
              hideModal();
            }}
          />
        </Stack>
      </Stack>
    </Modal>
  );
};

export default InvoiceFiltersModal;

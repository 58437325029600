import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from '@mui/material';
import { ComponentPropsWithoutRef, PropsWithChildren, ReactNode } from 'react';

import DropdownMenu from '../DropdownMenu';
import IconicButton from '../IconicButton';
import TooltipInfo from '../TooltipInfo';

import Action from '@/components/Action';
import Stack from '@/components/Stack';
import { Subheading } from '@/components/Typography';
import Icon from '@/elements/Icon';
import styled from '@/styles';
import breakpoints from '@/styles/breakpoints';
import { GenericAction, GenericActionOrList } from '@/types';

const Wrapper = styled(Stack, {
  padding: '20px 20px 0',
  '@phoneOnly': {
    padding: '16px 16px 0',
  },
  variants: {
    background: {
      true: {
        backgroundColor: '#EEFFEC',
        padding: '15px 15px 15px',
        borderRadius: '10px',
      },
      false: {
        backgroundColor: '#FFF',
      },
    },
    headerSpacing: {
      true: {
        padding: '15px 15px 15px',
      },
      false: {},
    },
    headerNobg: {
      true: {
        backgroundColor: 'transparent',
      },
      false: {},
    },
    accordion: {
      true: {
        borderBottom: '1px solid $neutralLightest',
      },
      false: {},
    },
  },

  defaultVariants: {
    background: false,
    headerSpacing: false,
    headerNobg: false,
  },
});

const Heading = styled('div', {
  flex: '0 1 100%',
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
});

export type Props = PropsWithChildren<{
  actions?: GenericActionOrList[];
  justify?: ComponentPropsWithoutRef<typeof Stack>['justify'];
  title?: ReactNode;
  tooltipText?: string;
  wrapperBackground?: boolean;
  headerNobg?: boolean;
  headerSpacing?: boolean;
  accordion?: boolean;
  open?: boolean;
  onClick?: () => void;
  actionsDropdown?: ReactNode;
  titleColor?: string;
}>;

const Header = ({
  actions,
  children,
  justify = 'apart',
  title,
  tooltipText,
  wrapperBackground = false,
  headerNobg = false,
  headerSpacing = false,
  accordion = false,
  open = true,
  onClick,
  actionsDropdown,
  titleColor,
}: Props) => {
  const phoneBreakpoint = useMediaQuery(breakpoints.phoneOnly);

  return (
    <Wrapper
      accordion={accordion}
      background={wrapperBackground}
      headerNobg={headerNobg}
      headerSpacing={headerSpacing}
      justify={justify}
      onClick={accordion ? onClick : null}
    >
      <Heading>
        {accordion && (
          <Icon
            fixedWidth
            icon={open ? faChevronUp : faChevronDown}
            theme={accordion && open ? 'theme' : 'neutral'}
          />
        )}
        {title && (
          <Subheading
            color={titleColor ? titleColor : accordion ? (open ? 'theme' : 'default') : 'default'}
          >
            {title}
          </Subheading>
        )}
        {tooltipText && <TooltipInfo text={tooltipText} />}
      </Heading>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {actions && (
          <Stack justify="end">
            {phoneBreakpoint && actions.length > 1 ? (
              <DropdownMenu
                actions={[actions] as GenericAction[][]}
                justify="right"
                size="md"
              >
                <IconicButton
                  a11yLabel="actions"
                  icon={faChevronDown}
                  size="lg"
                />
              </DropdownMenu>
            ) : (
              actions.map((action, idx) => (
                <Action.Button
                  key={idx}
                  action={action}
                  appearance="plain"
                  listOptions={{
                    align: 'below',
                    justify: 'right',
                  }}
                />
              ))
            )}
          </Stack>
        )}
        {actionsDropdown && <Stack justify="end">{actionsDropdown}</Stack>}
      </div>
      {children}
    </Wrapper>
  );
};

Header.Wrapper = Wrapper;
Wrapper.displayName = 'stitches(Card.Header.Wrapper)';

export default Header;

/* global google */
import { ApolloError } from '@apollo/client';
import { ArrowBack } from '@mui/icons-material';
import { Grid } from '@mui/material';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  OverlayView,
} from '@react-google-maps/api';
import { addDays, format, isAfter, parse, parseISO } from 'date-fns';
import qs from 'query-string';
import { Fragment, useCallback, useMemo, useState } from 'react';

import { TimesheetItem } from '../..';
import {
  HiredChip,
  RemovedWorkerChip,
  WorkerDesc,
} from '../../../WorkerTable/workerUtils';
import TimesheetInformation from '../../TimesheetInformation';
import NextDateAlert from '../PendingTimesheetModal/NextDateAlert';

import { CheckinMiles, CheckoutMiles } from '@/assets/icons';
import Action from '@/components/Action';
import { SubmitErrorAlert, SubmitErrorAlertProps } from '@/components/Alerts';
import Avatar from '@/components/Avatar';
import Button from '@/components/Button';
import FormElement from '@/components/FormElement';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { Body, Small, Subheading } from '@/components/Typography';
import Config from '@/config';
import { UNKNOWN_ERROR_TEXT } from '@/constants/text';
import Form from '@/form';
import TextField from '@/form/TextField';
import {
  GetJobDocument,
  useApproveTimesheetMutation,
  useCreateTimesheetMutation,
  useRejectTimesheetMutation,
  useSaveTimesheetMutation,
  useUnapproveTimesheetMutation,
  useUnrejectTimesheetMutation,
} from '@/graphql';
import useAuth from '@/hooks/useAuth';
import useMediaQuery from '@/hooks/useMediaQuery';
import { neutral } from '@/styles/colors';
import { Maybe } from '@/types';
import {
  Address,
  Point,
  Scalars,
  Shift,
  TimesheetStatusEnum,
} from '@/types/graphql';
import { isDateInvalid } from '@/util/date';
import { handleGraphQLError } from '@/util/error';
import { haversine, metersToMiles } from '@/util/geo';

export type Props = {
  jobAddress: Address;
  timesheet?: TimesheetItem;
  jobId: string;
  hideModal: () => Scalars['Void'];
  shift: Pick<Shift, 'id' | 'endAt' | 'startAt'>;
  variant?: 'DETAIL' | 'DEFAULT';
  isCancelJobTimesheet: boolean;
  workerInfo: any;
};

type FormValues = {
  approvedBreakMinutes: string;
  approvedCheckinAt: string;
  approvedCheckoutAt: string;
  ratingComment: TimesheetItem['reportComment'];
  tipAmount: TimesheetItem['tipAmount'];
  save: boolean;
};

type TimesheetFormattedFormValues = {
  timesheetId: string;
  breakMinutes: number;
  checkinAt: string;
  checkoutAt: string;
  tipAmount: number;
  ratingComment: TimesheetItem['reportComment'];
};

const containerStyle = {
  width: '400px',
  height: '600px',
};

const responsiveContainerStyle = {
  width: '100%',
  height: '330px',
  marginBottom: '24px',
};

const mapCoordsUrl = (coords: Point): string =>
  qs.stringifyUrl({
    url: 'https://www.google.com/maps/search/',
    query: { api: 1, query: `${coords.latitude},${coords.longitude}` },
  });

const EditTimesheetModal = ({
  jobAddress,
  timesheet,
  jobId,
  shift,
  workerInfo,
  isCancelJobTimesheet,
  hideModal,
  variant = 'DEFAULT',
}: Props) => {
  const { coords: jobCoords } = jobAddress;
  const { currentAdminIsCustomerAdmin } = useAuth();
  const initialValues: FormValues = useMemo((): FormValues => {
    return {
      approvedBreakMinutes: timesheet?.reportedBreakMinutes ?? '',
      approvedCheckinAt: timesheet?.reportedCheckinAt
        ? format(parseISO(timesheet.reportedCheckinAt), 'HH:mm')
        : '',
      approvedCheckoutAt: timesheet?.reportedCheckoutAt
        ? format(parseISO(timesheet.reportedCheckoutAt), 'HH:mm')
        : '',
      ratingComment: timesheet?.reportComment || '',
      tipAmount: timesheet?.tipAmount ?? 0,
      save: true,
    };
  }, [timesheet]);

  const workerId = workerInfo.id;
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: Config.GOOGLE_API_KEY,
  });

  const [formValues, setFormValues] = useState<FormValues>(initialValues);
  const [submitError, setSubmitError] =
    useState<Maybe<SubmitErrorAlertProps>>(null);

  const handleOnErrorTimesheetMutation = (error: ApolloError) => {
    handleGraphQLError(error, {
      INVALID_DATES: () => {
        setSubmitError({
          title: 'Invalid shift times',
          description: 'description TEST',
        });
      },
      WORKING_TIME_TOO_SHORT: ({ message }) => {
        setSubmitError({
          title: 'Approved time must be longer',
          description: message,
        });
      },
      BREAK_TIME_TOO_LONG: ({ message }) => {
        setSubmitError({
          title: 'Break time must be short',
          description: message,
        });
      },
      SHIFT_TOO_LONG: () => {
        setSubmitError({
          title: 'Shift length too long',
          description: 'Shifts cannot exceed 24 hours.',
        });
      },
      all: () => {
        setSubmitError({
          description: UNKNOWN_ERROR_TEXT,
        });
      },
    });
  };

  const [saveTimesheet, { loading: isSaveTimesheetLoading }] =
    useSaveTimesheetMutation({
      refetchQueries: [{ query: GetJobDocument, variables: { jobId } }],
      onCompleted: hideModal,
      onError: handleOnErrorTimesheetMutation,
    });
  const [createTimesheet] = useCreateTimesheetMutation({
    onError: handleOnErrorTimesheetMutation,
  });

  const [approveTimesheet, { loading: isApproveTimesheetLoading }] =
    useApproveTimesheetMutation({
      refetchQueries: [{ query: GetJobDocument, variables: { jobId } }],
      onCompleted: hideModal,
      onError: handleOnErrorTimesheetMutation,
    });

  const [rejectTimesheet, { loading: isRejectTimesheetLoading }] =
    useRejectTimesheetMutation({
      refetchQueries: [{ query: GetJobDocument, variables: { jobId } }],
      onCompleted: hideModal,
      onError: (error) => {
        handleGraphQLError(error, {
          TIMESHEET_APPROVED: () => {
            setSubmitError({
              title: 'Timesheet already approved',
              description: 'The timesheet is already approved',
            });
          },
          all: () => {
            setSubmitError({
              description: UNKNOWN_ERROR_TEXT,
            });
          },
        });
      },
    });

  const [unapproveTimesheet, { loading: isUnapproveTimesheetLoading }] =
    useUnapproveTimesheetMutation({
      fetchPolicy: 'no-cache',
      refetchQueries: [{ query: GetJobDocument, variables: { jobId } }],
      onError: (error) => {
        setSubmitError({
          description: error.message,
        });
      },
      onCompleted: hideModal,
    });

  const [unRejectTimesheet] = useUnrejectTimesheetMutation({
    refetchQueries: [{ query: GetJobDocument, variables: { jobId } }],
    onError: (error) => {
      setSubmitError({
        description: error.message,
      });
    },
    onCompleted: hideModal,
  });

  const handleUnapprove = (timesheetId) => {
    unapproveTimesheet({ variables: { timesheetId } });
  };
  const handleUnReject = (timesheetId) => {
    unRejectTimesheet({
      variables: {
        timesheetId,
      },
    });
  };

  const handleFormValuesChange = (fieldContext, fieldId: keyof FormValues) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldId]: fieldContext.value,
    }));
  };
  const handleSubmit = useCallback(
    async ({
      approvedBreakMinutes,
      approvedCheckinAt,
      approvedCheckoutAt,
      tipAmount,
      ...values
    }: FormValues) => {
      setSubmitError(null);
      const { save } = formValues;

      let timesheetId = timesheet?.id;
      if (!timesheetId) {
        const createResp = await createTimesheet({
          variables: {
            jobId,
            workerId,
          },
        });

        if (createResp?.data?.timesheetCreate.timesheet.id) {
          timesheetId = createResp?.data?.timesheetCreate.timesheet.id;
        } else {
          return;
        }
      }

      let { startAt, endAt } = getParsedDateRange(
        approvedCheckinAt,
        approvedCheckoutAt,
        timesheet?.shift.startAt || shift.startAt
      );

      if (isDateInvalid(startAt) || isDateInvalid(endAt)) {
        const timeLabel = isDateInvalid(startAt) ? 'start time' : 'end time';

        setSubmitError({
          title: 'Shift times are invalid',
          description: `Shift ${timeLabel} could not be parsed.`,
        });
        return;
      }

      if (isAfter(startAt, endAt)) {
        endAt = addDays(endAt, 1);
      }

      const timesheetFormattedFormValues: TimesheetFormattedFormValues = {
        ...values,
        timesheetId,
        breakMinutes: Number(approvedBreakMinutes),
        checkinAt: startAt.toISOString(),
        checkoutAt: endAt.toISOString(),
        tipAmount: Number(tipAmount),
      };

      if (save) {
        await handleSaveTimesheet(timesheetFormattedFormValues);
      } else {
        handleSaveTimesheet(timesheetFormattedFormValues);
        await handleApproveTimesheet(timesheetFormattedFormValues);
      }
    },
    [formValues, timesheet?.id]
  );

  const getParsedDateRange = (
    approvedCheckinAt: Scalars['String'],
    approvedCheckoutAt: Scalars['String'],
    shiftStartAt: string
  ) => {
    const startAt = parse(approvedCheckinAt, 'HH:mm', parseISO(shiftStartAt));
    const endAt = parse(approvedCheckoutAt, 'HH:mm', parseISO(shiftStartAt));

    return { startAt, endAt };
  };

  const handleApproveTimesheet = async (
    timesheetFormattedFormValues: TimesheetFormattedFormValues
  ) => {
    const { checkinAt, checkoutAt, breakMinutes, ...rest } =
      timesheetFormattedFormValues;

    await approveTimesheet({
      variables: {
        approvedCheckinAt: checkinAt,
        approvedCheckoutAt: checkoutAt,
        approvedBreakMinutes: breakMinutes,
        ...rest,
      },
    });
  };

  const handleSaveTimesheet = async (
    timesheetFormattedFormValues: TimesheetFormattedFormValues
  ) => {
    // Only tenant admins can save timesheets
    const { checkinAt, checkoutAt, breakMinutes, ...rest } =
      timesheetFormattedFormValues;

    await saveTimesheet({
      variables: {
        reportedCheckinAt: checkinAt,
        reportedCheckoutAt: checkoutAt,
        reportedBreakMinutes: breakMinutes,
        ...rest,
      },
    });
  };

  const handleRejectTimesheet = useCallback(async () => {
    let timesheetId = timesheet?.id;
    if (!timesheetId) {
      const createResp = await createTimesheet({
        variables: {
          jobId,
          workerId,
        },
      });
      if (createResp?.data?.timesheetCreate.timesheet.id) {
        timesheetId = createResp?.data?.timesheetCreate.timesheet.id;
      } else {
        return;
      }
    }
    rejectTimesheet({
      variables: {
        timesheetId,
        ratingComment: formValues.ratingComment,
      },
    });
  }, [formValues, timesheet?.id]);

  const markers = [
    {
      lat: jobCoords.latitude,
      lng: jobCoords.longitude,
      icon: require('@/assets/icons/joblocation.png'),
      addressText: `${jobAddress.addressLine1}\n${jobAddress.city}, ${jobAddress.state}`,
      color: '#7456ED',
    },
  ];
  if (timesheet?.checkinCoords) {
    markers.push({
      lat: timesheet.checkinCoords.latitude,
      lng: timesheet.checkinCoords.longitude,
      icon: require('@/assets/icons/checkin.png'),
      addressText: 'Check In',
      color: '#332F2D',
    });
  }
  if (timesheet?.checkoutCoords) {
    markers.push({
      lat: timesheet.checkoutCoords.latitude,
      lng: timesheet.checkoutCoords.longitude,
      icon: require('@/assets/icons/checkout.png'),
      addressText: 'Check Out',
      color: '#44A735',
    });
  }

  const onLoad = (map) => {
    const bounds = new google.maps.LatLngBounds();
    markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    map.fitBounds(bounds);
    setTimeout(() => {
      map.setZoom(15);
    }, 100);
  };

  const getPixelPositionOffset = (offsetWidth, offsetHeight, labelAnchor) => {
    return {
      x: offsetWidth + labelAnchor.x,
      y: offsetHeight + labelAnchor.y,
    };
  };
  const phoneOnly = useMediaQuery('(max-width: 790px)');

  return (
    <Modal
      disableClickout
      layoutCss={phoneOnly ? { gridTemplateColumns: 'none' } : undefined}
      modalHeader={
        phoneOnly ? (
          <Stack
            style={{
              padding: '16px',
              backgroundColor: neutral.neutralLightest,
            }}
          >
            <ArrowBack onClick={hideModal} />
            <Subheading>{'Edit Hours Info'}</Subheading>
          </Stack>
        ) : undefined
      }
      modalPadding={phoneOnly ? '47px 0px 0px 0px' : undefined}
      size="md"
      title={phoneOnly ? '' : 'Edit Hours Info'}
      wrapperBackground={true}
      onRequestClose={hideModal}
    >
      <Stack
        gap={15}
        style={phoneOnly ? { padding: 20, flexWrap: 'wrap' } : { padding: 20 }}
      >
        {isLoaded && (
          <Stack vertical>
            {phoneOnly && (
              <Grid container spacing={1} style={{ marginBottom: '16px' }}>
                <Grid item xs={2}>
                  <Avatar size="md" src={workerInfo.avatarUrl} />
                </Grid>
                <Grid item xs={7}>
                  <WorkerDesc
                    isClientAdmin={currentAdminIsCustomerAdmin}
                    workerInfo={workerInfo}
                  />
                </Grid>
                <Grid
                  item
                  style={{ paddingLeft: isCancelJobTimesheet ? 1 : 20 }}
                  xs={3}
                >
                  {isCancelJobTimesheet ? RemovedWorkerChip : HiredChip}
                </Grid>
              </Grid>
            )}

            <GoogleMap
              mapContainerStyle={
                phoneOnly ? responsiveContainerStyle : containerStyle
              }
              zoom={15}
              onLoad={onLoad}
            >
              {markers.map(({ lat, lng, icon, color, addressText }, index) => (
                <Fragment key={'marker' + index}>
                  <Marker
                    icon={{
                      url: icon,
                    }}
                    position={{ lat, lng }}
                  />
                  <OverlayView
                    getPixelPositionOffset={(x, y) =>
                      getPixelPositionOffset(x, y, { x: 20, y: -45 })
                    }
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    position={{ lat, lng }}
                  >
                    <h1
                      style={{
                        fontWeight: 'bolder',
                        color: `${color}`,
                        width: '200px',
                      }}
                    >
                      {addressText}
                    </h1>
                  </OverlayView>
                </Fragment>
              ))}
            </GoogleMap>

            {phoneOnly && <Subheading>Location Miles</Subheading>}
            <FormElement
              displayType="row"
              icon={
                <CheckinMiles
                  style={{ fontSize: '16px', marginRight: '5px', top: '2px' }}
                />
              }
              label="Check In:"
            >
              {timesheet?.checkinCoords ? (
                <Stack>
                  <Body>
                    <b>
                      {metersToMiles(
                        haversine(timesheet.checkinCoords, jobCoords)
                      ).toFixed(1)}
                      mi{' '}
                    </b>
                    from job site
                  </Body>
                  <Action.Button
                    action={{
                      a11yLabel: `Open new tab to ${mapCoordsUrl(
                        timesheet.checkinCoords
                      )}`,
                      label: 'View',
                      href: mapCoordsUrl(timesheet.checkinCoords),
                      external: true,
                      id: 'link-checkin',
                    }}
                  />
                </Stack>
              ) : (
                <Small>Not reported</Small>
              )}
            </FormElement>
            <div
              style={{
                borderTop: '1px',
                borderTopStyle: 'dashed',
                width: '180px',
                marginLeft: '20px',
              }}
            />
            <FormElement
              displayType="row"
              icon={
                <CheckoutMiles
                  style={{ fontSize: '16px', marginRight: '5px', top: '2px' }}
                />
              }
              label="Check Out:"
            >
              {timesheet?.checkoutCoords ? (
                <Stack>
                  <Body>
                    <b>
                      {metersToMiles(
                        haversine(timesheet.checkoutCoords, jobCoords)
                      ).toFixed(1)}
                      mi{' '}
                    </b>
                    from job site
                  </Body>
                  <Action.Button
                    action={{
                      a11yLabel: `Open new tab to ${mapCoordsUrl(
                        timesheet.checkoutCoords
                      )}`,
                      label: 'View',
                      href: mapCoordsUrl(timesheet.checkoutCoords),
                      external: true,
                      id: 'link-checkout',
                    }}
                  />
                </Stack>
              ) : (
                <Small>Not reported</Small>
              )}
            </FormElement>
          </Stack>
        )}
        <Stack vertical align="start" style={{ alignSelf: 'flex-start' }}>
          <TimesheetInformation
            jobCoords={jobCoords}
            timesheet={timesheet}
            variant={variant}
          />
          {submitError && <SubmitErrorAlert status="danger" {...submitError} />}
          {variant === 'DEFAULT' && (
            <>
              <div style={{ marginTop: '10px' }}>
                <Subheading>Approved Hours</Subheading>
              </div>
              <Form
                data-testid="timesheet-form"
                id="timesheet-form"
                initialValues={formValues}
                onSubmit={handleSubmit}
              >
                <TextField
                  callback={(fieldContext) =>
                    handleFormValuesChange(fieldContext, 'approvedCheckinAt')
                  }
                  displayType="rowSpace"
                  fieldId="approvedCheckinAt"
                  label="Check In"
                  type="time"
                  width="180px"
                />
                <div style={{ marginTop: '10px' }}>
                  <TextField
                    callback={(fieldContext) =>
                      handleFormValuesChange(fieldContext, 'approvedCheckoutAt')
                    }
                    displayType="rowSpace"
                    fieldId="approvedCheckoutAt"
                    label="Check Out"
                    type="time"
                    width="180px"
                  />
                </div>
                {timesheet && (
                  <NextDateAlert
                    checkinAt={timesheet.reportedCheckinAt}
                    checkoutAt={timesheet.reportedCheckoutAt}
                  />
                )}

                <div style={{ marginTop: '10px' }}>
                  <TextField
                    callback={(fieldContext) =>
                      handleFormValuesChange(
                        fieldContext,
                        'approvedBreakMinutes'
                      )
                    }
                    displayType="rowSpace"
                    fieldId="approvedBreakMinutes"
                    label="Break"
                    max="90"
                    min="0"
                    type="number"
                    width="180px"
                  />
                </div>
                <div style={{ marginTop: '10px' }}>
                  <Subheading>Tips</Subheading>
                </div>
                <div style={{ marginTop: '5px' }}>
                  <TextField
                    callback={(fieldContext) =>
                      handleFormValuesChange(fieldContext, 'tipAmount')
                    }
                    displayType="rowSpace"
                    fieldId="tipAmount"
                    label="Tip Amount"
                    max="1000"
                    min="0"
                    type="number"
                    width="180px"
                  />
                </div>
              </Form>
              <Stack
                css={{ marginTop: phoneOnly ? '2em' : '6em' }}
                justify="end"
              >
                {!currentAdminIsCustomerAdmin && (
                  <Button
                    a11yLabel={`Save timesheet`}
                    appearance="outline"
                    form="timesheet-form"
                    id="btn-save-timesheet"
                    isLoading={
                      isSaveTimesheetLoading && !isApproveTimesheetLoading
                    }
                    label="Save"
                    type="submit"
                    onClick={() => {
                      handleFormValuesChange({ value: true }, 'save');
                    }}
                  />
                )}
                <Button
                  a11yLabel={`Approve timesheet`}
                  form="timesheet-form"
                  id="btn-approve-timesheet"
                  isLoading={isApproveTimesheetLoading}
                  label="Approve"
                  type="submit"
                  onClick={() => {
                    handleFormValuesChange({ value: false }, 'save');
                  }}
                />
                {!currentAdminIsCustomerAdmin && (
                  <Button
                    a11yLabel="Reject timesheet"
                    appearance="outline"
                    css={{ borderColor: '#DC1515', color: '#DC1515' }}
                    id="btn-reject-timesheet"
                    isLoading={isRejectTimesheetLoading}
                    label="Reject"
                    type="button"
                    onClick={handleRejectTimesheet}
                  />
                )}
              </Stack>
            </>
          )}
          {variant === 'DETAIL' && (
            <Stack
              css={{
                marginTop: submitError ? '3em' : phoneOnly ? '1em' : '10em',
              }}
              justify="end"
            >
              {timesheet?.status === TimesheetStatusEnum.APPROVED &&
                !currentAdminIsCustomerAdmin && (
                  <Button
                    a11yLabel="Unapprove timesheet"
                    appearance="secondary"
                    id="btn-unapprove-timesheet"
                    isLoading={isUnapproveTimesheetLoading}
                    label="Unapprove"
                    status="danger"
                    onClick={() => handleUnapprove(timesheet.id)}
                  />
                )}
              {timesheet?.status === TimesheetStatusEnum.REJECTED &&
                !currentAdminIsCustomerAdmin && (
                  <Button
                    a11yLabel="Unreject timesheet"
                    appearance="secondary"
                    id="btn-unreject-timesheet"
                    isLoading={isUnapproveTimesheetLoading}
                    label="Unreject"
                    status="danger"
                    onClick={() => handleUnReject(timesheet.id)}
                  />
                )}
              <Button
                a11yLabel="Close modal"
                id="btn-close-modal"
                label="Close"
                onClick={hideModal}
              />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Modal>
  );
};

export default EditTimesheetModal;

/* eslint-disable eqeqeq */
import { useLocation } from 'react-router-dom';

import { WarningType } from '../..';
import { Billing as BillingType } from '../../../context';

import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { Body } from '@/components/Typography';
import Link from '@/elements/Link';
import { Maybe } from '@/types';

type Props = {
  hideModal: () => void;
  onConfirm: () => void;
  customer: Maybe<BillingType['customer']>;
  account: Maybe<BillingType['account']>;
  warningType: WarningType;
};

const AccountWarning = ({
  hideModal,
  onConfirm,
  customer,
  account,
  warningType,
}: Props) => {
  const { pathname: currentPathname } = useLocation();

  return (
    <Modal size="xs" title="Custom rates" onRequestClose={hideModal}>
      <Card.Section>
        <Stack vertical gap={24}>
          <Body>
            {warningType == 'RATE' ? (
              <>
                There are no custom rates associated with the{' '}
                <b>{account?.name}</b> account, create one in the client
                profile.
              </>
            ) : (
              <>
                {`There is no active address associated with a skill in this
                account, create a skill with an active address in the client's
                profile`}
              </>
            )}
          </Body>

          <Stack justify="end">
            <Button
              a11yLabel="Close"
              appearance="outline"
              label="Close"
              onClick={hideModal}
            />
            <Link to={`${currentPathname}/../../clients/${customer!.id}`}>
              <Button
                a11yLabel="Go to client detail"
                appearance="primary"
                label="Go to create"
                onClick={onConfirm}
              />
            </Link>
          </Stack>
        </Stack>
      </Card.Section>
    </Modal>
  );
};

export default AccountWarning;

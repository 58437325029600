import { FieldCallback, useField } from '@area2k/use-form';
import { ComponentPropsWithoutRef } from 'react';

import FormElement from '@/components/FormElement';
import TextArea from '@/components/TextArea';

type InputProps = Omit<
  ComponentPropsWithoutRef<typeof TextArea>,
  'id' | 'value' | 'onChange'
>;

type Props = InputProps & {
  fieldId: string;
  label?: string;
  callback?: FieldCallback<string>;
  trim?: boolean;
  maxLengthPosition?: 'top' | 'bottom';
};

const TextAreaField = ({
  fieldId,
  label,
  callback = () => {},
  trim = false,
  maxLengthPosition = 'bottom',
  ...props
}: Props) => {
  const { value, setValue } = useField<string>(fieldId, callback);

  return (
    <FormElement htmlFor={fieldId} label={label}>
      <TextArea
        {...props}
        id={fieldId}
        maxLengthPosition={maxLengthPosition}
        name={fieldId}
        value={value}
        onBlur={() => {
          trim && setValue(value.trim());
        }}
        onChange={(ev) => setValue(ev.currentTarget.value)}
      />
    </FormElement>
  );
};

export default TextAreaField;

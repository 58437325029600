import Card from '@/components/Card';
import Stack from '@/components/Stack';
import { Body, Subheading } from '@/components/Typography';
import useMediaQuery from '@/hooks/useMediaQuery';
import { GetOrderQuery } from '@/types/graphql';
import { formatDuration } from '@/util/datetime';
import {
  calculateTotalEstimateByJobs,
  calculateTotalHoursByOrder,
} from '@/util/job';
import { centsToCurrency } from '@/util/number';

type OrderItem = GetOrderQuery['order'];
type Props = {
  order: OrderItem;
};

const SummaryCard = ({ order: { jobs } }: Props) => {
  const totalHours = formatDuration(calculateTotalHoursByOrder(jobs));
  const totalEstimate = calculateTotalEstimateByJobs(jobs);
  const phoneOnly = useMediaQuery('(max-width: 559px)');

  return (
    <Card noRadius={phoneOnly} title="Summary">
      <Card.Section>
        <Stack justify="apart">
          <Body>Total hours</Body>
          <Subheading>{totalHours}</Subheading>
        </Stack>
        <Stack justify="apart">
          <Body>Total estimate</Body>
          <Subheading color="theme">
            ${centsToCurrency(totalEstimate)}
          </Subheading>
        </Stack>
      </Card.Section>
    </Card>
  );
};

export default SummaryCard;

import { createContext, Reducer, useContext } from 'react';

import { Maybe } from '@/types';
import { Account, Customer } from '@/types/graphql';
import { noOp } from '@/util/actions';

export type Billing = {
  account: Account;
  customer: Customer;
};

export type InvoiceGeneratorState = {
  billing: Maybe<Billing>;
};

export enum InvoiceGeneratorActionType {
  SET_BILLING = 'set_billing',
}

type SetBillingAction = {
  type: InvoiceGeneratorActionType.SET_BILLING;
  billing: Maybe<Billing>;
};

export type InvoiceGeneratorAction = SetBillingAction;

export type InvoiceGeneratorActions = {
  setBilling: (billing: Maybe<Billing>) => void;
};

export const InvoiceGeneratorStateContext =
  createContext<InvoiceGeneratorState>({
    billing: null,
  });

export const InvoiceGeneratorActionsContext =
  createContext<InvoiceGeneratorActions>({
    setBilling: noOp,
  });

export const useInvoiceGeneratorState = () =>
  useContext(InvoiceGeneratorStateContext);
export const useInvoiceGeneratorActions = () =>
  useContext(InvoiceGeneratorActionsContext);
export const stateReducer: Reducer<
  InvoiceGeneratorState,
  InvoiceGeneratorAction
> = (state, action) => {
  switch (action.type) {
    case InvoiceGeneratorActionType.SET_BILLING: {
      return { ...state, billing: action.billing };
    }

    default: {
      return state;
    }
  }
};

// Util Functions
export const getTotalLengthOfShift = (approvedMinutes: number) => {
  let hours: any = Math.floor(approvedMinutes / 60);
  hours = hours < 10 ? '0' + hours : hours;
  let minutes: any = Math.floor(approvedMinutes % 60);
  minutes = minutes < 10 ? '0' + minutes : minutes;

  return `${hours}h${minutes > 0 ? ` ${minutes}m` : ''}`;
};

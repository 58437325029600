import React, {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
} from 'react';
import PlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import GooglePlacesAutocompleteProps, {
  GooglePlacesAutocompleteHandle,
} from 'react-google-places-autocomplete/build/GooglePlacesAutocomplete.types';

import { TypeEditorEnum } from '../EditAddress';
import FormElement from '../FormElement';

import type { GoogleAddress } from './types';
import {
  findContent,
  getAddress,
  getCity,
  getNeighborhood,
  getState,
} from './util';

import TextField from '@/form/TextField';
import { Scalars } from '@/types/graphql';

type Props = {
  values: any;
  showSuiteNumber?: Scalars['Boolean'];
  type?: TypeEditorEnum;
  setValues: (args: any) => Scalars['Void'];
  children?: React.ReactNode;
  onReset?: (args: any) => Scalars['Void'];
};
type AddressAutocompleteType = ForwardRefExoticComponent<
  Props &
    GooglePlacesAutocompleteProps &
    RefAttributes<GooglePlacesAutocompleteHandle>
>;

const AddressAutocomplete = forwardRef(
  (
    {
      values,
      showSuiteNumber = false,
      type,
      setValues,
      children,
      onReset,
    }: Props,
    ref
  ) => {
    const handleNewAddressValue = async (selectedAddress: GoogleAddress) => {
      if (!selectedAddress) {
        onReset &&
          onReset({
            addressLine1: '',
            addressLine2: '',
            city: '',
            neighborhood: '',
            name: '',
            state: '',
            zip: '',
            coords: { latitude: 0, longitude: 0 },
          });
        return;
      }
      const coords = { latitude: 0, longitude: 0 };
      const geocoderResults = await geocodeByPlaceId(
        selectedAddress.value.place_id
      );
      const firstGeocoderResult = geocoderResults[0];
      const addressLine1 = getAddress(selectedAddress, firstGeocoderResult);
      const city = getCity(firstGeocoderResult);
      const neighborhood = getNeighborhood(firstGeocoderResult);
      const state = getState(selectedAddress, firstGeocoderResult);
      const zip = findContent('postal_code', firstGeocoderResult);
      coords.latitude = firstGeocoderResult.geometry.location.lat();
      coords.longitude = firstGeocoderResult.geometry.location.lng();
      setValues({
        ...values,
        coords,
        city,
        neighborhood,
        state,
        zip,
        addressLine1,
      });
    };

    return (
      <>
        <div style={{ zIndex: 1000 }}>
          <FormElement
            htmlFor=""
            label={showSuiteNumber ? 'Address' : undefined}
          >
            <PlacesAutocomplete
              ref={ref}
              autocompletionRequest={{
                componentRestrictions: { country: 'us' },
                types: ['address'],
              }}
              minLengthAutocomplete={2}
              selectProps={{
                placeholder: 'Type address...',
                onChange: handleNewAddressValue,
                inputId: 'address-input',
                isClearable: onReset !== undefined,
              }}
            />
            {children}
          </FormElement>
        </div>
        {showSuiteNumber && (
          <FormElement
            label={
              type === TypeEditorEnum.FOR_WORKER
                ? 'Apartment Number'
                : 'Address Line 2'
            }
          >
            <TextField
              callback={(fieldContext) => {
                const addressLine2 = fieldContext.value;
                setValues({ ...values, addressLine2 });
              }}
              fieldId="addressLine2"
              placeholder={
                type === TypeEditorEnum.FOR_WORKER
                  ? 'Type apartment number...'
                  : 'Type address...'
              }
            />
          </FormElement>
        )}
      </>
    );
  }
) as AddressAutocompleteType;

AddressAutocomplete.displayName = 'AddressAutocomplete';

export default AddressAutocomplete;

import { Image, Link, Text, View } from '@react-pdf/renderer';
import { format, isValid, parse } from 'date-fns';
import { useMemo } from 'react';

import { Overtime } from './Icons';

import { GetInvoiceDetailsRevampQuery } from '@/types/graphql';
import { NOT_AVAILABLE_TEXT } from '@/util/constants';
import {
  formatApproveTime,
  formatNetTimeInHrAndMin,
  getRateDesc,
} from '@/util/invoice';
import { centsToCurrency } from '@/util/number';

interface Props {
  styles: Record<string, any>;
  invoiceItems: GetInvoiceDetailsRevampQuery['listStripeInvoiceDetail']['invoiceItems'];
  amountDue: string;
}

const InvoiceTable = ({ styles, invoiceItems, amountDue }: Props) => {
  const totalMints = useMemo(() => {
    let mints = 0;
    invoiceItems?.forEach((item) => {
      const netTime = Number(item.netTimeMinutes);
      if (netTime) {
        mints += netTime;
      }
    });
    return mints;
  }, [invoiceItems]);

  const nextRowOverTime = (index: number) => {
    const nextRow = invoiceItems?.[index + 1];
    return !!(
      nextRow &&
      nextRow.isOvertime &&
      nextRow.timesheetId === invoiceItems[index].timesheetId
    );
  };

  const isPartialOvertimeRow = (index: number) => {
    const prevRow = invoiceItems?.[index - 1];
    return !!(
      prevRow && prevRow.timesheetId === invoiceItems[index].timesheetId
    );
  };
  const renderOverTimeRow = (item: any) => {
    const { amount, jobId, billRate, netTime } = item;

    return (
      <View key={item.id} style={styles.tableRow} wrap={false}>
        <View style={{ ...styles.tableCol, width: '34%' }} />
        <View style={{ ...styles.tableCol, width: '2%' }}>
          <Image src={Overtime} style={styles.icons} />
        </View>
        <View style={{ ...styles.tableCol, width: '11%', paddingLeft: 14 }}>
          <Text style={styles.tableCell}>
            {jobId && netTime ? `${netTime}` : ''}
          </Text>
        </View>
        <View style={{ ...styles.tableCol, width: '29%' }} />
        <View style={{ ...styles.tableCol, width: '10%', paddingLeft: 2 }}>
          <View style={styles.textStack}>
            <Text style={styles.textSmall}>
              {billRate ? `$${centsToCurrency(billRate)}` : NOT_AVAILABLE_TEXT}
            </Text>
            <Text style={{ ...styles.textSmall, color: '#7A7A7A' }}>
              {'Overtime'}
            </Text>
          </View>
        </View>
        <View style={{ ...styles.tableCol, width: '6%' }} />
        <View style={{ ...styles.tableCol, width: '9%' }}>
          <Text style={styles.tableCell}>
            ${centsToCurrency(Number(amount))}
          </Text>
        </View>
      </View>
    );
  };
  const renderTotalRow = () => {
    return (
      <View
        style={{
          ...styles.tableRow,
          borderBottomWidth: 0,
        }}
        wrap={false}
      >
        <View style={{ ...styles.tableCol, width: '40%' }}>
          <Text style={{ ...styles.tableCell, fontWeight: 'semibold' }}>
            Total
          </Text>
        </View>
        <View style={{ ...styles.tableCol, width: '11%' }}>
          <Text style={{ ...styles.tableCell, fontWeight: 'semibold' }}>
            {formatNetTimeInHrAndMin(totalMints)}
          </Text>
        </View>
        <View style={{ ...styles.tableCol, width: '29%' }} />
        <View style={{ ...styles.tableCol, width: '10%' }} />
        <View style={{ ...styles.tableCol, width: '6%' }} />
        <View style={{ ...styles.tableCol, width: '9%' }}>
          <Text style={{ ...styles.tableCell, fontWeight: 'semibold' }}>
            ${amountDue}
          </Text>
        </View>
      </View>
    );
  };

  return (
    <View style={styles.table}>
      {/* Table Header */}
      <View style={styles.tableRow}>
        <View style={{ ...styles.tableColHeader, width: '11%' }}>
          <Text style={styles.tableCell}>Job ID</Text>
        </View>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCell}>Worker</Text>
        </View>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCell}>Date</Text>
        </View>
        <View style={{ ...styles.tableColHeader, width: '10%' }}>
          <Text style={styles.tableCell}>Net Time</Text>
        </View>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCell}>Address</Text>
        </View>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCell}>Approved By</Text>
        </View>
        <View style={{ ...styles.tableColHeader, width: '10%' }}>
          <Text style={styles.tableCell}>Rate</Text>
        </View>
        <View style={{ ...styles.tableColHeader, width: '6%' }}>
          <Text style={styles.tableCell}>Tips</Text>
        </View>
        <View style={{ ...styles.tableColHeader, width: '9%' }}>
          <Text style={styles.tableCell}>Total</Text>
        </View>
      </View>
      {/* Table Rows */}
      {invoiceItems &&
        invoiceItems.map((item, index) => {
          const {
            order,
            date,
            address,
            amount,
            worker,
            netTime,
            jobId,
            billRate,
            tip = 0,
            isOvertime,
            skill,
            cancelled,
            shortDescription = '',
            workerTimesheet,
          } = item;
          const orderId = order?.split('#')?.[1] || '';
          const description = cancelled
            ? 'CANCELLED JOB FEE'
            : shortDescription;
          const parsedDate = parse(date || '', 'MM/dd/yy', new Date());
          const weekDay = isValid(parsedDate) ? format(parsedDate, 'E') : '';
          const rateDesc = getRateDesc(item, true);
          const isNextRowOverTime = nextRowOverTime(index);
          const overTimeRow = isOvertime && isPartialOvertimeRow(index);

          return overTimeRow ? (
            renderOverTimeRow(item)
          ) : (
            <View
              key={item.id}
              style={{
                ...styles.tableRow,
                borderBottomWidth: isNextRowOverTime ? 0 : 1,
              }}
              wrap={false}
            >
              <View style={{ ...styles.tableCol, width: '11%' }}>
                {jobId ? (
                  <View style={styles.textStack}>
                    <View style={styles.stackRow}>
                      <Text style={{ ...styles.textSmall, color: '#7A7A7A' }}>
                        Order:{' '}
                      </Text>
                      <Link
                        src={`${window.location.origin}/orders/${orderId}`}
                        style={{
                          ...styles.textSmall,
                          color: '#45A735',
                          textDecoration: 'none',
                        }}
                      >
                        {orderId}
                      </Link>
                    </View>
                    <View style={styles.stackRow}>
                      <Text style={{ ...styles.textSmall, color: '#7A7A7A' }}>
                        Job:{' '}
                      </Text>
                      <Link
                        src={`${window.location.origin}/orders/${orderId}/jobs/${jobId}`}
                        style={{
                          ...styles.textSmall,
                          color: '#45A735',
                          textDecoration: 'none',
                        }}
                      >
                        {jobId}
                      </Link>
                    </View>
                  </View>
                ) : (
                  <Text style={styles.textSmall}>{NOT_AVAILABLE_TEXT}</Text>
                )}
              </View>
              <View style={styles.tableCol}>
                {worker ? (
                  <View style={styles.textStack}>
                    <Text style={{ ...styles.textSmall, color: '#45A735' }}>
                      {worker.user?.firstName + ' ' + worker.user?.lastName}
                    </Text>
                    <Text style={{ ...styles.textSmall, color: '#7A7A7A' }}>
                      {skill?.name}
                    </Text>
                    <Text style={styles.textSmall}>{description}</Text>
                  </View>
                ) : (
                  <Text style={styles.textSmall}>{description || ''}</Text>
                )}
              </View>
              <View style={styles.tableCol}>
                {date && workerTimesheet ? (
                  <View style={styles.textStack}>
                    <Text style={styles.textSmall}>
                      {formatApproveTime(
                        workerTimesheet.approvedCheckinAt,
                        workerTimesheet?.job?.address?.timezone,
                      )}{' '}
                      -{' '}
                      {formatApproveTime(
                        workerTimesheet.approvedCheckoutAt,
                        workerTimesheet?.job?.address?.timezone,
                      )}
                    </Text>
                    <Text style={{ ...styles.textSmall, color: '#7A7A7A' }}>
                      {date ? `${weekDay} ${date}` : NOT_AVAILABLE_TEXT}
                    </Text>
                    <Text style={{ ...styles.textSmall, color: '#7A7A7A' }}>
                      {workerTimesheet.approvedBreakMinutes} min break
                    </Text>
                  </View>
                ) : (
                  <Text style={styles.textSmall}>{NOT_AVAILABLE_TEXT}</Text>
                )}
              </View>
              <View style={{ ...styles.tableCol, width: '10%' }}>
                <Text style={styles.tableCell}>
                  {jobId && netTime ? `${netTime}` : NOT_AVAILABLE_TEXT}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {address || NOT_AVAILABLE_TEXT}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {workerTimesheet?.updatedBy ? (
                    <>
                      {workerTimesheet.updatedBy.firstName}{' '}
                      {workerTimesheet.updatedBy.lastName}
                    </>
                  ) : workerTimesheet?.updatedByType === 'GravyWorkSystem' ? (
                    'System Approved'
                  ) : (
                    NOT_AVAILABLE_TEXT
                  )}
                </Text>
              </View>
              <View style={{ ...styles.tableCol, width: '10%' }}>
                <View style={styles.textStack}>
                  <Text style={styles.textSmall}>
                    {billRate
                      ? `$${centsToCurrency(billRate)}`
                      : NOT_AVAILABLE_TEXT}
                  </Text>
                  {rateDesc && (
                    <Text style={{ ...styles.textSmall, color: '#7A7A7A' }}>
                      {rateDesc}
                    </Text>
                  )}
                </View>
              </View>
              <View style={{ ...styles.tableCol, width: '6%' }}>
                <Text style={styles.tableCell}>
                  {jobId ? `$${tip}` : NOT_AVAILABLE_TEXT}{' '}
                </Text>
              </View>
              <View style={{ ...styles.tableCol, width: '9%' }}>
                <Text style={styles.tableCell}>
                  ${centsToCurrency(Number(amount))}
                </Text>
              </View>
            </View>
          );
        })}
      {renderTotalRow()}
    </View>
  );
};

export default InvoiceTable;

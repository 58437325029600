import Avatar from '@/components/Avatar';
import Badge from '@/components/Badge';
import LoadingState from '@/components/LoadingState';
import Stack from '@/components/Stack';
import TextStack from '@/components/TextStack';
import { Body, Small } from '@/components/Typography';
import Link from '@/elements/Link';
import { TableRow, TableCell } from '@/elements/Table';
import ROUTES from '@/routes/routes';
import { ListCustomerAdminQuery } from '@/types/graphql';

type Props = {
  items: ListCustomerAdminQuery['agency']['customerAdmins']['items'];
  headerFields: string[];
  isLoading: boolean;
};

const TableBodyCustomerAdmin = ({ items, headerFields, isLoading }: Props) => {
  return (
    <tbody>
      {items.map((item) => (
        <TableRow key={item.id}>
          <TableCell>
            <Stack gap={20}>
              <div>
                <Avatar
                  firstName={item.user.firstName}
                  size="sm"
                  src={item.avatarUrl}
                />
              </div>
              <TextStack spacing="tight">
                <Link
                  to={`../${ROUTES.clients}/${item.customer.id}/admin/${item.id}`}
                >
                  <Body color="inherit" weight="medium">
                    {item.user.firstName} {item.user.lastName}
                  </Body>
                </Link>
                <Small>{item.user.email}</Small>
              </TextStack>
            </Stack>
          </TableCell>
          <TableCell>
            <Stack gap={12}>
              <div>
                <Avatar
                  firstName={item.customer.name}
                  size="xs"
                  src={item.customer.logoUrl}
                />
              </div>
              <Link to={`../${ROUTES.clients}/${item.customer.id}`}>
                {item.customer.name}
              </Link>
            </Stack>
          </TableCell>
          <TableCell>{item.user.phoneNumber}</TableCell>
          <TableCell>
            <Stack>
              <Badge
                label={
                  item.acceptedAt
                    ? item.user.active
                      ? 'Enabled'
                      : 'Disabled'
                    : 'Pending'
                }
                status={
                  item.acceptedAt
                    ? item.user.active
                      ? 'theme'
                      : 'danger'
                    : 'warning'
                }
              />
            </Stack>
          </TableCell>
        </TableRow>
      ))}
      {isLoading && (
        <TableRow>
          <TableCell align="center" colSpan={headerFields.length + 1}>
            <LoadingState overlayColor="white" />
          </TableCell>
        </TableRow>
      )}
    </tbody>
  );
};

export default TableBodyCustomerAdmin;

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import TextStack from '@/components/TextStack';
import { Body } from '@/components/Typography';
import { useResetWorkerPasswordMutation } from '@/graphql';
import { GetWorkerQuery } from '@/types/graphql';
import { ERROR_SUPPORT_MESSAGE } from '@/util/constants';
import {
  handleGraphQLError,
  hasGraphQLErrors,
  isApolloError,
} from '@/util/error';

export type WorkerItem = GetWorkerQuery['worker'];

type Props = {
  hideModal: () => void;
  worker: WorkerItem;
};

const UpdatePasswordToWorkerModal = ({ hideModal, worker }: Props) => {
  const [resetPassword, { loading }] = useResetWorkerPasswordMutation();
  const [error, setError] = useState<{
    message: string;
  } | null>(null);

  const handleSubmit = async () => {
    try {
      const result = await resetPassword({
        variables: {
          workerId: worker.id,
        },
      });

      if (result.data) return hideModal();
    } catch (err: unknown) {
      if (isApolloError(err) && hasGraphQLErrors(err)) {
        handleGraphQLError(err, {
          all: (e) =>
            setError((prevValues) => ({
              ...prevValues,
              message: e.message,
            })),
        });
      }
      return setError((prevValues) => ({
        ...prevValues,
        message: ERROR_SUPPORT_MESSAGE,
      }));
    }
  };

  return (
    <Modal
      disableClickout
      size="sm"
      title="Reset Password"
      onRequestClose={hideModal}
    >
      <Card.Section>
        {error && (
          <Alert
            description={error.message}
            icon={faExclamationTriangle}
            status="danger"
            title="Failed to reset password"
          />
        )}
        <TextStack>
          <Body>
            Are you sure you want to reset the password of this worker?
          </Body>
        </TextStack>
        <Stack justify="end">
          <Button
            a11yLabel="deny"
            appearance="outline"
            label="Cancel"
            status="danger"
            type="button"
            onClick={hideModal}
          />

          <Button
            a11yLabel="Reset Password"
            isLoading={loading}
            label="Reset"
            loadingLabel="Reseting..."
            onClick={handleSubmit}
          />
        </Stack>
      </Card.Section>
    </Modal>
  );
};

export default UpdatePasswordToWorkerModal;

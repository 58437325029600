import { useField } from '@area2k/use-form';
import { useCallback } from 'react';

import { Skill, JobDraftState } from '../../context';

import SkillItem from './SkillItem';

import { FirstStepFieldIdEnum } from './';

import Stack from '@/components/Stack';
import { Heading } from '@/components/Typography';
import SingleSelectableField from '@/form/SingleSelectableField';
import useMediaQuery from '@/hooks/useMediaQuery';

type Props = {
  callback?: any;
};

const SkillSelectField = ({ callback }: Props) => {
  const onChangeSkill = () => setAddress(null);
  const onChangeSkillCategory = () => setSkill(null);

  const { setValue: setAddress } = useField<JobDraftState['address']>(
    FirstStepFieldIdEnum.ADDRESS
  );
  const { setValue: setSkill } = useField<JobDraftState['skill']>(
    FirstStepFieldIdEnum.SKILL,
    onChangeSkill
  );
  const { value: skillCategory } = useField<any | null>(
    FirstStepFieldIdEnum.SKILL_CATEGORY,
    onChangeSkillCategory
  );
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const renderItem = useCallback(
    (item: Skill) => <SkillItem dense={phoneOnly} item={item} />,
    [phoneOnly]
  );

  if (!skillCategory) {
    return null;
  }

  return (
    <Stack
      vertical
      align={phoneOnly ? 'start' : 'center'}
      gap={phoneOnly ? 6 : 24}
    >
      <Heading
        size={phoneOnly ? 'sm' : 'xl'}
        weight={phoneOnly ? 'medium' : 'semibold'}
      >
        Select job skill
      </Heading>
      <Stack
        wrap
        align="center"
        css={{
          alignItems: phoneOnly ? 'stretch' : 'center',
          display: phoneOnly ? 'grid' : null,
          gridTemplateColumns: phoneOnly ? '1fr 1fr 1fr' : null,
        }}
        gap={phoneOnly ? 8 : 16}
        justify={phoneOnly ? 'start' : 'center'}
      >
        <SingleSelectableField<any>
          required
          callback={callback}
          fieldId={FirstStepFieldIdEnum.SKILL}
          itemToKey={(item) => item.id}
          options={skillCategory.skills}
          renderItem={renderItem}
        />
      </Stack>
    </Stack>
  );
};

export default SkillSelectField;

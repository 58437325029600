/* eslint-disable eqeqeq */
import {
  useState,
  createContext,
  useContext,
  SetStateAction,
  Dispatch,
  ReactNode,
} from 'react';

import { AccountPaymentMethodsType } from '@/routes/Agency/Account/PaymentSection';
import {
  checkCurrentPaymentMethods,
  checkIfHasAnAssociatedpaymentMethod,
} from '@/util/payments';

export type PaymentMethodType = 'ach' | 'card' | 'check' | 'none';
export type PaymentMethod = {
  paymentMethodType: PaymentMethodType;
  selectedPaymentMethodId: string | null;
};

const INITIAL_STATE = {
  paymentMethodType: 'ach' as PaymentMethodType,
  selectedPaymentMethodId: null,
};

interface ContextProps {
  paymentMethod: PaymentMethod;
  setPaymentMethod: Dispatch<SetStateAction<PaymentMethod>>;
}

const PaymentContext = createContext<ContextProps>({
  paymentMethod: INITIAL_STATE,
  setPaymentMethod: () => {},
});

export const usePaymentMethod = () => {
  const { paymentMethod, setPaymentMethod } = useContext(PaymentContext);

  const setPaymentMethodType = (paymentMethodType: PaymentMethodType) => {
    setPaymentMethod({
      paymentMethodType,
      selectedPaymentMethodId:
        paymentMethodType == 'check' ? 'check' : paymentMethodType,
    });
  };

  const setSelectedPaymentMethodId = (paymentMethodId: string) => {
    setPaymentMethod((prevValues) => ({
      ...prevValues,
      selectedPaymentMethodId: paymentMethodId,
    }));
  };

  const setPaymentMethodInformation = (
    accountPaymentMethods: AccountPaymentMethodsType
  ) => {
    const { currentPaymentMethod, cards, bankAccounts } = accountPaymentMethods;

    const currentPaymentMethodType = checkCurrentPaymentMethods(
      cards,
      bankAccounts,
      currentPaymentMethod
    );

    if (currentPaymentMethodType == 'none') {
      if (
        paymentMethod.paymentMethodType !== INITIAL_STATE.paymentMethodType &&
        checkIfHasAnAssociatedpaymentMethod(
          accountPaymentMethods,
          paymentMethod.paymentMethodType
        )
      ) {
        return setPaymentMethod({
          paymentMethodType: paymentMethod.paymentMethodType,
          selectedPaymentMethodId: cards[0].id,
        });
      }
      return setPaymentMethod({
        paymentMethodType: INITIAL_STATE.paymentMethodType,
        selectedPaymentMethodId:
          bankAccounts.length > 0
            ? bankAccounts[0].id
            : INITIAL_STATE.selectedPaymentMethodId,
      });
    }

    if (
      currentPaymentMethodType != paymentMethod.paymentMethodType &&
      paymentMethod.selectedPaymentMethodId === null
    ) {
      return setPaymentMethod({
        paymentMethodType: currentPaymentMethodType,
        selectedPaymentMethodId:
          currentPaymentMethod === '' ? null : currentPaymentMethod,
      });
    }

    const checkPaymentAssociatedMethods = checkIfHasAnAssociatedpaymentMethod(
      accountPaymentMethods,
      paymentMethod.paymentMethodType
    );

    return setPaymentMethod({
      paymentMethodType: checkPaymentAssociatedMethods
        ? paymentMethod.paymentMethodType
        : currentPaymentMethodType,
      selectedPaymentMethodId: {
        ach:
          bankAccounts.length > 0 ? bankAccounts[0].id : currentPaymentMethod,
        card: cards.length > 0 ? cards[0].id : currentPaymentMethod,
        check: 'check',
      }[paymentMethod.paymentMethodType],
    });
  };

  const resetPaymentMethodStatus = () => setPaymentMethod(INITIAL_STATE);

  return {
    setPaymentMethodType,
    setSelectedPaymentMethodId,
    setPaymentMethodInformation,
    resetPaymentMethodStatus,
    paymentMethod,
  };
};

// eslint-disable-next-line react/display-name
export default ({ children }: { children: ReactNode }) => {
  const [paymentMethod, setPaymentMethod] =
    useState<PaymentMethod>(INITIAL_STATE);

  return (
    <PaymentContext.Provider value={{ paymentMethod, setPaymentMethod }}>
      {children}
    </PaymentContext.Provider>
  );
};

import { VariantProps, CSS } from '@stitches/react';
import { ComponentPropsWithoutRef } from 'react';

import Text from '@/elements/Text';
import WarningIcon from '@mui/icons-material/Warning';
import Button from '@/elements/Button';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@/styles';

const Wrapper = styled('div', {
  width: '100%',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px 24px',
  borderRadius: '8px',
  border: '1px solid #FFDD92',
  marginBottom: '20px',
  backgroundColor: '#FCF6D6',
  '.icon-text': {
    alignItems: 'center',
    display: 'flex',
    gap: '4px',
    svg: {
      height: '20px',
      width: '20px',
    },
    '@phoneOnly': {
      span: {
        width: 'auto',
      },
    },
  },
  '.links-group': {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    '*': {
      fontWeight: 'bold',
      fontSize: '14px',
    },
    a: {
      width: '101px',
    },
    '.needs-approval-dismiss': {
      color: '#7A7A7A',
    },
  },
});

Wrapper.displayName = 'stitches(NeedsApprovalAlert.Wrapper)';

export type Variants = VariantProps<typeof Wrapper>;
export type Props = ComponentPropsWithoutRef<'div'> &
  Variants & {
    jobsCount: number;
    viewLastWeek?: () => void;
    dismiss?: () => void;
    phoneOnly?: Boolean;
    css?: CSS;
  };

const NeedsApprovalAlert = ({
  jobsCount,
  viewLastWeek,
  dismiss,
  phoneOnly,
  ...props
}: Props) => {
  return (
    <Wrapper data-testid="alert" {...props}>
      <div className="icon-text">
        <WarningIcon color="warning" />
        <Text weight={'medium'} size={'sm'}>
          {jobsCount > 1
            ? `${jobsCount} jobs need approval from the previous week. Please review and approve past timesheets.`
            : `${jobsCount} job needs approval from the previous week. Please review and approve past timesheets.`}
        </Text>
      </div>
      <div className="links-group">
        <Button appearance={'plain'} onClick={viewLastWeek}>
          View last week
        </Button>
        {phoneOnly ? (
          <div onClick={dismiss} style={{ padding: '10px' }}>
            <CloseIcon />
          </div>
        ) : (
          <Button
            className="needs-approval-dismiss"
            appearance={'plain'}
            onClick={dismiss}
          >
            Dismiss
          </Button>
        )}
      </div>
    </Wrapper>
  );
};

export default NeedsApprovalAlert;

import * as yup from 'yup';

const OneCapitalPattern = /^(?=.*[A-Z])/;
const OneNumberPattern = /(?=.*[0-9])/;
const LengthPasswordPattern = /(?=.{8,})/;

const setPasswordSchema = yup
  .object({
    password: yup
      .string()
      .required('Password is a required field')
      .matches(LengthPasswordPattern, {
        message: 'Password must contain at least 8 characters',
      })
      .matches(OneCapitalPattern, {
        message: 'Password must contain at least 1 capital letter',
      })
      .matches(OneNumberPattern, {
        message: 'Password must contain at least 1 number',
      }),
    confirmPassword: yup
      .string()
      .required('Password confirmation is required field')
      .matches(LengthPasswordPattern, {
        message: 'Password must contain at least 8 characters',
      })
      .matches(OneCapitalPattern, {
        message: 'Password must contain at least 1 capital letter',
      })
      .matches(OneNumberPattern, {
        message: 'Password must contain at least 1 number',
      })
      .oneOf([yup.ref('password')], 'Passwords must match'),
  })
  .required();

export default setPasswordSchema;

import UpdateContactForm from './UpdateContactForm';

import Card from '@/components/Card';
import Modal from '@/components/Modal';
import { GetJobQuery, Scalars } from '@/types/graphql';

export type Props = {
  job: GetJobQuery['job'];
  hideModal: () => Scalars['Void'];
};

const UpdateContactModal = ({ job, hideModal }: Props) => {
  return (
    <Modal
      size="sm"
      title="Update contact information"
      onRequestClose={hideModal}
    >
      <Card.Section>
        <UpdateContactForm hideModal={hideModal} job={job} />
      </Card.Section>
    </Modal>
  );
};

export default UpdateContactModal;

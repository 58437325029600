import Action from '@/components/Action';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { Body } from '@/components/Typography';
import { CreateOrderMutation } from '@/types/graphql';

type Props = {
  order: CreateOrderMutation['orderCreate']['order'];
  hideModal: () => void;
  hireMessage?: string | null;
};

const PostSubmitModal = ({ order, hideModal, hireMessage }: Props) => (
  <Modal
    disableClickout
    size="xs"
    title={`Order #${order.id} placed!`}
    wrapperBackground={true}
    onRequestClose={hideModal}
  >
    <Card.Section>
      <Stack vertical gap={24}>
        <Body>Your order #{order.id} was successfully placed.</Body>
        {hireMessage && <Body>{hireMessage}</Body>}
        <Stack justify="end">
          <Button
            a11yLabel="Start a new order"
            appearance="outline"
            id="start-new-order"
            label="Place another"
            onClick={hideModal}
          />
          <Action.Button
            action={{
              a11yLabel: 'Navigate to order page',
              id: 'view-order-btn',
              label: 'View order',
              to: `/orders/${order.id}`,
            }}
            appearance="primary"
          />
        </Stack>
      </Stack>
    </Card.Section>
  </Modal>
);

export default PostSubmitModal;

import { useState } from 'react';

import AppLogin from '../AppLogin';

import Steps from './Steps';
import SignUpProvider from './context/state/SignUpStateProvider';
import { StyledPage } from './styles';

import Card from '@/components/Card';
import GravyWorkLogo from '@/components/GravyWorkLogo';
import Stack from '@/components/Stack';
import { isWeb } from '@/util/platform';

export enum Step {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
  FOURTH = 4,
}

const SignUp = () => {
  const [currentStep, setCurrentStep] = useState(Step.FIRST);

  return isWeb() ? (
    <SignUpProvider>
      <StyledPage size="xs">
        <Card>
          <Stack css={{ paddingTop: '20px' }} justify="center">
            <GravyWorkLogo />
          </Stack>
          {currentStep === Step.FIRST && <Card.Header title="Sign Up" />}
          <Steps setStep={setCurrentStep} step={currentStep} />
        </Card>
      </StyledPage>
    </SignUpProvider>
  ) : (
    <AppLogin />
  );
};

export default SignUp;

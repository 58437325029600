import { Step } from '../';

import FirstStep from './FirstStep';
import FourthStep from './FourthStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';

type Props = {
  step: Step;
  setStep: (step: Step) => void;
};

const Steps = ({ step, setStep }: Props) => {
  return {
    [Step.FIRST]: <FirstStep setStep={setStep} />,
    [Step.SECOND]: <SecondStep setStep={setStep} />,
    [Step.THIRD]: <ThirdStep setStep={setStep} />,
    [Step.FOURTH]: <FourthStep />,
  }[step];
};

export default Steps;

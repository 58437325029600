import { ComponentPropsWithoutRef, PropsWithChildren, ReactNode } from 'react';

import Action from '@/components/Action';
import Stack from '@/components/Stack';
import { Caption } from '@/components/Typography';
import PermissionComponent from '@/routes/PermissionComponent';
import styled from '@/styles';
import { GenericActionOrList } from '@/types';

const Wrapper = styled('div', {
  padding: '20px',

  '& + &': {
    borderTop: '1px solid $colors$neutralLightest',
  },

  variants: {
    flush: {
      true: {
        padding: 0,
      },
      false: {},
    },
    subdued: {
      true: {
        backgroundColor: '$neutralA2',
      },
      false: {},
    },
    borderNone: {
      true: {
        borderTop: 'none !important',
        paddingBottom: '0px',
        paddingTop: '0px',
      },
      false: {},
    },
  },
  '@phoneOnly': {
    padding: '20px 16px',
  },
});

const Heading = styled('p', {
  color: '#424242',
  fontSize: '18px',
  fontWeight: '700',
  lineHeight: '21px',
  letterSpacing: '0em',
});

const Title = styled(Stack, {
  paddingBottom: '12px',
});

export type Props = PropsWithChildren<{
  actions?: GenericActionOrList[];
  flush?: boolean;
  justify?: ComponentPropsWithoutRef<typeof Stack>['justify'];
  subdued?: boolean;
  title?: ReactNode;
  borderNone?: boolean;
  styleHeading?: boolean;
  open?: boolean;
  titleStyle?: any;
}>;

const Section = ({
  actions,
  children,
  flush,
  subdued,
  title,
  borderNone,
  styleHeading,
  open = true,
  titleStyle = {},
  ...props
}: Props) => {
  const hasTitleBar = title || (actions && actions.length > 0);

  const headings = () => {
    if (styleHeading) {
      return <Heading>{title}</Heading>;
    }
    return <Caption>{title}</Caption>;
  };
  return (
    <Wrapper borderNone={borderNone} flush={flush} subdued={subdued} {...props}>
      {hasTitleBar && (
        <Title justify="apart" style={titleStyle}>
          <div style={{ flex: '0 1 100%' }}>{title && headings()}</div>
          {actions && (
            <Stack justify="end">
              {actions.map((action, idx) => (
                <PermissionComponent key={action.id || ''} id={action.id || ''}>
                  <Action.Button
                    key={idx}
                    action={action}
                    appearance="plain"
                    listOptions={{
                      align: 'below',
                      justify: 'right',
                    }}
                  />
                </PermissionComponent>
              ))}
            </Stack>
          )}
        </Title>
      )}
      {open && children}
    </Wrapper>
  );
};

Section.Wrapper = Wrapper;
Wrapper.displayName = 'stitches(Card.Section.Wrapper)';

export default Section;

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Card, CardContent } from '@mui/material';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import {
  StripeElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardCvcElementChangeEvent,
  StripeError,
  StripeCardNumberElement,
} from '@stripe/stripe-js';
import { FormEventHandler, useCallback, useState } from 'react';

import { onRefetchType } from '../modals/ChangePaymentModal';

import {
  cardNumberOptions,
  cardExpiryOptions,
  cardCvcOptions,
  Wrapper,
  CardElementContainer,
  StyledFormColumns,
  CardElementWrapper,
} from './styles';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import ConditionalWrapper from '@/components/ConditionalWrapper';
import FormElement from '@/components/FormElement';
import Stack from '@/components/Stack';
import { Small, Subheading } from '@/components/Typography';
import Input from '@/elements/Input';
import { useAttachCardToAccountMutation } from '@/graphql';
import { GetAccountQuery } from '@/types/graphql';
import { isWeb } from '@/util/platform';

type Props = {
  onCloseEditor: () => void;
  account?: GetAccountQuery['account'];
  onRefetch?: onRefetchType;
  handleAddCard?: (payload: {
    cardElement: StripeCardNumberElement;
    name: string;
  }) => void;
  adjustUI?: boolean;
  isLoading?: boolean;
  phoneOnly?: boolean;
};

type CardBaseType<T> = { cardNumber: T; cardExpiry: T; cardCvc: T };
type EmptyFieldsType = CardBaseType<StripeElementChangeEvent['empty']>;
type CompletedFieldsType = CardBaseType<StripeElementChangeEvent['complete']>;
type ErrorsTypes = CardBaseType<StripeElementChangeEvent['error']>;

type onChangeEventType =
  | StripeCardNumberElementChangeEvent
  | StripeCardExpiryElementChangeEvent
  | StripeCardCvcElementChangeEvent;

type fieldIdType =
  | keyof EmptyFieldsType
  | keyof CompletedFieldsType
  | keyof ErrorsTypes;

const AddCardForm = ({
  onCloseEditor,
  account,
  onRefetch,
  handleAddCard,
  adjustUI,
  isLoading,
  phoneOnly,
}: Props) => {
  const [stripeError, setStripeError] = useState<StripeError | null>(null);
  const [submitError, setSubmitError] = useState<StripeError | null>(null);
  const [fieldsEmpty, setFieldsEmpty] = useState<EmptyFieldsType>({
    cardNumber: true,
    cardExpiry: true,
    cardCvc: true,
  });

  const [errors, setErrors] = useState<ErrorsTypes>({
    cardNumber: undefined,
    cardExpiry: undefined,
    cardCvc: undefined,
  });

  const [fieldsCompleted, setFieldsCompleted] = useState<CompletedFieldsType>({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });

  const stripe = isWeb() ? useStripe() : undefined;
  const elements = useElements();

  const defaultValueName = account
    ? `${account?.defaultContact?.user.firstName} ${account?.defaultContact?.user.lastName}`
    : '';
  const [cardholderName, setCardHolderName] = useState(defaultValueName);

  const [attachCardToAccount, { loading }] = useAttachCardToAccountMutation({
    onCompleted: async () => {
      await onRefetch?.();
      setStripeError(null);
      setSubmitError(null);
      onCloseEditor();
    },
    onError: ({ networkError }) => {
      if (networkError) {
        const error = networkError.result.errors[0];

        setSubmitError(error);
      }
    },
  });

  const isDisabled =
    !stripe ||
    fieldsEmpty.cardNumber ||
    fieldsEmpty.cardExpiry ||
    fieldsEmpty.cardCvc ||
    !fieldsCompleted.cardNumber ||
    !fieldsCompleted.cardExpiry ||
    !fieldsCompleted.cardCvc ||
    errors.cardNumber !== undefined ||
    errors.cardExpiry !== undefined ||
    errors.cardCvc !== undefined;

  const handleOnEmptyFields = useCallback(
    (
      fieldId: keyof EmptyFieldsType,
      empty: StripeElementChangeEvent['empty']
    ) => setFieldsEmpty((prevValues) => ({ ...prevValues, [fieldId]: empty })),
    [fieldsEmpty]
  );

  const handleOnChangeErrors = useCallback(
    (fieldId: keyof ErrorsTypes, error: StripeElementChangeEvent['error']) =>
      setErrors((prevValues) => ({ ...prevValues, [fieldId]: error })),
    [errors]
  );

  const handleOnCompleteFields = useCallback(
    (
      fieldId: keyof CompletedFieldsType,
      complete: StripeElementChangeEvent['complete']
    ) =>
      setFieldsCompleted((prevValues) => ({
        ...prevValues,
        [fieldId]: complete,
      })),
    [fieldsCompleted]
  );

  const handleOnChange = useCallback(
    (event: onChangeEventType, fieldId: fieldIdType) => {
      handleOnEmptyFields(fieldId, event.empty);
      handleOnChangeErrors(fieldId, event.error);
      handleOnCompleteFields(fieldId, event.complete);
    },
    []
  );

  const handleSubmit: FormEventHandler<HTMLButtonElement> = useCallback(
    async (event) => {
      event.preventDefault();

      if (!stripe || !elements) return;

      const element = elements.getElement(CardNumberElement);

      if (!element) return;

      if (handleAddCard) {
        handleAddCard({ cardElement: element, name: cardholderName });
        return;
      }

      stripe
        .createPaymentMethod({
          type: 'card',
          card: element,
          billing_details: {
            name: cardholderName,
          },
        })
        .then(({ error, paymentMethod }) => {
          if (error) {
            return setStripeError(error);
          }

          if (paymentMethod && account) {
            attachCardToAccount({
              variables: {
                account: account.id,
                paymentMethodId: paymentMethod.id,
              },
            });
          }
        })
        .catch(setStripeError);
    },
    [stripe, elements, cardholderName]
  );

  function getErrorDescription() {
    const defaultMessage = 'Please contact support.';
    if (stripeError) return stripeError.message ?? defaultMessage;
    if (submitError) return submitError.message ?? defaultMessage;
    return defaultMessage;
  }

  return (
    <Wrapper>
      <ConditionalWrapper
        condition={adjustUI && !phoneOnly}
        wrapper={(children) => (
          <Card
            sx={{ border: `1px solid #D3D3D3`, width: '100%' }}
            variant="outlined"
          >
            <CardContent>
              <Subheading css={{ marginBottom: '14px' }}>
                Credit card details
              </Subheading>
              {children}
            </CardContent>
          </Card>
        )}
      >
        <CardElementContainer vertical gap={0}>
          <StyledFormColumns layout={adjustUI ? 'double' : 'single'}>
            {(stripeError || submitError) && (
              <Alert
                description={getErrorDescription()}
                icon={faExclamationTriangle}
                status="warning"
                title="Something went wrong"
              />
            )}

            <FormElement label="Cardholder Name">
              <Input
                defaultValue={defaultValueName}
                id="cardholder-name-input"
                placeholder="Enter Name"
                value={cardholderName}
                onChange={(e) => setCardHolderName(e.target.value)}
              />
            </FormElement>

            <FormElement label="Card Number">
              <CardElementWrapper>
                <CardNumberElement
                  id="card-number-input"
                  options={cardNumberOptions}
                  onChange={(event) => handleOnChange(event, 'cardNumber')}
                  onReady={(e) => !adjustUI && e.focus()}
                />
              </CardElementWrapper>
              {errors.cardNumber && (
                <Small color="danger">{errors.cardNumber.message}</Small>
              )}
            </FormElement>
          </StyledFormColumns>

          <StyledFormColumns layout="double">
            <FormElement label="Valid Through">
              <CardElementWrapper>
                <CardExpiryElement
                  id="card-expiry"
                  options={cardExpiryOptions}
                  onChange={(event) => handleOnChange(event, 'cardExpiry')}
                />
              </CardElementWrapper>
              {errors.cardExpiry && (
                <Small color="danger">{errors.cardExpiry.message}</Small>
              )}
            </FormElement>

            <FormElement label="CVC">
              <CardElementWrapper>
                <CardCvcElement
                  id="card-cvc"
                  options={cardCvcOptions}
                  onChange={(event) => handleOnChange(event, 'cardCvc')}
                />
              </CardElementWrapper>
              {errors.cardCvc && (
                <Small color="danger">{errors.cardCvc.message}</Small>
              )}
            </FormElement>
          </StyledFormColumns>
          {!adjustUI && (
            <Stack justify="end">
              <Button
                a11yLabel="Go back"
                appearance="outline"
                id="back-btn"
                label="Back"
                type="button"
                onClick={onCloseEditor}
              />

              <Button
                a11yLabel="Add Credit Card"
                disabled={isDisabled}
                id="add-credit-card-btn"
                isLoading={loading}
                label="Add Credit Card"
                type="button"
                onClick={handleSubmit}
              />
            </Stack>
          )}
        </CardElementContainer>
      </ConditionalWrapper>
      {adjustUI && (
        <Stack css={{ marginTop: '8px' }} justify="start">
          <Button
            a11yLabel="Save Credit Card"
            disabled={isDisabled}
            id="add-payment-btn"
            isLoading={isLoading}
            label="Save"
            type="button"
            onClick={handleSubmit}
          />
          <Button
            a11yLabel="Go back"
            appearance="outline"
            id="cancel-btn"
            label="Cancel"
            type="button"
            onClick={onCloseEditor}
          />
        </Stack>
      )}
    </Wrapper>
  );
};

export default AddCardForm;

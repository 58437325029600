import { SkillItem } from '@/types';
import {
  GenderEnum,
  LanguageEnum,
  PointInput,
  WorkerReference,
} from '@/types/graphql';

export type FormValues = {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  gender: GenderEnum | undefined;
  ownsCar: boolean;
  language: LanguageEnum | undefined;
  secondaryLanguage: LanguageEnum | undefined;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  coords: PointInput;
  dateOfBirth: string;
};

export enum CreateWorkerActionTypes {
  SET_FORM_VALUES = 'SET_FORM_VALUES',
  SET_EXPERIENCE_VALUES = 'SET_EXPERIENCE_VALUES',
  SET_REFERENCE_VALUES = 'SET_REFERENCE_VALUES',
  SET_FORM_ERROR = 'SET_FORM_ERROR',
  SET_SKILLS_VALUES = 'SET_SKILLS_VALUES',
  SET_QUERY_VALUE = 'SET_QUERY_VALUE',
}

interface CreateWorkerAction {
  type: CreateWorkerActionTypes;
  payload: any;
}

export interface WorkerModalState {
  formValues: FormValues;
  showExperience: boolean;
  references: WorkerReference[];
  formError: {
    visible: boolean;
    key: string;
  };
  skills: SkillItem[];
  query: string;
}

export const initialState: WorkerModalState = {
  formValues: {
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    gender: GenderEnum.UNSPECIFIED,
    ownsCar: false,
    language: LanguageEnum.ENGLISH,
    secondaryLanguage: LanguageEnum.SPANISH,
    phoneNumber: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    coords: { latitude: 0, longitude: 0 },
    dateOfBirth: '',
  },
  showExperience: false,
  references: [],
  formError: {
    visible: false,
    key: '',
  },
  skills: [],
  query: '',
};

export const workerModalReducer = (
  state: WorkerModalState,
  action: CreateWorkerAction
): WorkerModalState => {
  const { type, payload } = action;

  switch (type) {
    case CreateWorkerActionTypes.SET_FORM_VALUES: {
      return {
        ...state,
        formValues: {
          ...state.formValues,
          ...payload,
        },
      };
    }

    case CreateWorkerActionTypes.SET_EXPERIENCE_VALUES: {
      return {
        ...state,
        showExperience: payload,
      };
    }

    case CreateWorkerActionTypes.SET_REFERENCE_VALUES: {
      return {
        ...state,
        references: [...state.references, ...payload],
      };
    }

    case CreateWorkerActionTypes.SET_FORM_ERROR: {
      return {
        ...state,
        formError: payload,
      };
    }

    case CreateWorkerActionTypes.SET_SKILLS_VALUES: {
      return {
        ...state,
        skills: payload,
      };
    }

    case CreateWorkerActionTypes.SET_QUERY_VALUE: {
      return {
        ...state,
        query: payload,
      };
    }

    default: {
      return state;
    }
  }
};

import { createSvgIcon } from '@mui/material';

const CompanyIcon = createSvgIcon(
  <svg
    fill="none"
    height="20"
    viewBox="0 0 512 512"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M154.353 147.999v-40c0-6.6 5.718-12 12.706-12h42.353c6.988 0 12.706 5.4 12.706 12v40c0 6.6-5.718 12-12.706 12h-42.353c-6.988 0-12.706-5.4-12.706-12zM302.588 160h42.353c6.988 0 12.707-5.4 12.707-12v-40c0-6.6-5.718-12-12.707-12h-42.353c-6.988 0-12.706 5.4-12.706 12v40c0 6.6 5.718 12 12.706 12zm-135.529 95.999h42.353c6.988 0 12.706-5.4 12.706-12v-40c0-6.6-5.718-12-12.706-12h-42.353c-6.988 0-12.706 5.4-12.706 12v40c0 6.6 5.718 12 12.706 12zm135.529 0h42.353c6.988 0 12.707-5.4 12.707-12v-40c0-6.6-5.718-12-12.707-12h-42.353c-6.988 0-12.706 5.4-12.706 12v40c0 6.6 5.718 12 12.706 12zM222.118 340v-40c0-6.6-5.718-12.001-12.706-12.001h-42.353c-6.988 0-12.706 5.401-12.706 12.001v40c0 6.6 5.718 11.998 12.706 11.998h42.353c6.988 0 12.706-5.398 12.706-11.998zm80.47 11.998h42.353c6.988 0 12.707-5.398 12.707-11.998v-40c0-6.6-5.718-12.001-12.707-12.001h-42.353c-6.988 0-12.706 5.401-12.706 12.001v40c0 6.6 5.718 11.998 12.706 11.998zM493.176 476v36H18.823v-36c0-6.6 5.718-12.001 12.706-12.001h20.647v-440c0-13.3 11.329-24 25.412-24H434.41c14.085 0 25.413 10.7 25.413 24v440h20.647c6.988 0 12.707 5.401 12.707 12.001zm-390.175-13h119.117v-67c0-6.6 5.718-12.001 12.706-12.001h42.352c6.988 0 12.706 5.401 12.706 12.001v67H409V49l-305.471-1L103 463z"
      fill="currentColor"
    />
  </svg>,
  'CompanyIcon'
);
export default CompanyIcon;

import { createSvgIcon } from '@mui/material';

const TickIcon = createSvgIcon(
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6777 2.32797L5.65685 14.3488L0 8.69193L1.41 7.28193L5.65685 11.5188L16.2677 0.917969L17.6777 2.32797Z"
      fill="#44A735"
    />
  </svg>,
  'TickIcon'
);
export default TickIcon;

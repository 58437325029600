import { useReactiveVar } from '@apollo/client';
import { useLocation } from 'react-router-dom';

import { CustomerProfileSteps } from '../../';
import { Billing as BillingType } from '../../../context';

import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { Body } from '@/components/Typography';
import Link from '@/elements/Link';
import { Role } from '@/routes/PrivateRoute';
import ROUTES from '@/routes/routes';
import { Maybe } from '@/types';
import { currentAdminVar } from '@/util/apollo/cache';

type Props = {
  hideModal: () => void;
  onConfirm: () => void;
  currentStep: Maybe<CustomerProfileSteps>;
  totalSteps: number;
  customer: Maybe<BillingType['customer']>;
};

const CustomerProfileStepsModal = ({
  hideModal,
  onConfirm,
  customer,
}: Props) => {
  const { pathname: currentPathname } = useLocation();

  const currentAdmin = useReactiveVar(currentAdminVar);
  const currentAdminIsCustomerAdmin =
    currentAdmin!.role === Role.CUSTOMER_ADMIN;

  return (
    <Modal
      size="xs"
      title={`Complete client profile`}
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Stack vertical gap={24}>
          <Body>
            Please complete your client profile to create a new order.
          </Body>

          <Stack justify="end">
            <Button
              a11yLabel="Close"
              appearance="outline"
              label="Close"
              onClick={hideModal}
            />
            <Link
              to={
                currentAdminIsCustomerAdmin
                  ? `${currentPathname}/../../${ROUTES.myCompany}`
                  : `${currentPathname}/../../${ROUTES.clients}/${customer!.id}`
              }
            >
              <Button
                a11yLabel="Go to client detail"
                appearance="primary"
                label="Complete Profile"
                onClick={onConfirm}
              />
            </Link>
          </Stack>
        </Stack>
      </Card.Section>
    </Modal>
  );
};

export default CustomerProfileStepsModal;

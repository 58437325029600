import { createSvgIcon } from '@mui/material';

const CreditCardIcon = createSvgIcon(
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M1.5 6.75C1.5 6.15326 1.73705 5.58097 2.15901 5.15901C2.58097 4.73705 3.15326 4.5 3.75 4.5H20.25C20.8467 4.5 21.419 4.73705 21.841 5.15901C22.2629 5.58097 22.5 6.15326 22.5 6.75V7.5H1.5V6.75ZM1.5 9H22.5V17.25C22.5 17.8467 22.2629 18.419 21.841 18.841C21.419 19.2629 20.8467 19.5 20.25 19.5H3.75C3.15326 19.5 2.58097 19.2629 2.15901 18.841C1.73705 18.419 1.5 17.8467 1.5 17.25V9ZM13.5 14.25C13.3011 14.25 13.1103 14.329 12.9697 14.4697C12.829 14.6103 12.75 14.8011 12.75 15C12.75 15.1989 12.829 15.3897 12.9697 15.5303C13.1103 15.671 13.3011 15.75 13.5 15.75H18C18.1989 15.75 18.3897 15.671 18.5303 15.5303C18.671 15.3897 18.75 15.1989 18.75 15C18.75 14.8011 18.671 14.6103 18.5303 14.4697C18.3897 14.329 18.1989 14.25 18 14.25H13.5Z"
      fill="#44A735"
      fillRule="evenodd"
    />
  </svg>,
  'CreditCardIcon'
);
export default CreditCardIcon;

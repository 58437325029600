// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hide-scroll::-webkit-scrollbar {
    width: 0px;
}

.hide-scroll::-webkit-scrollbar-track {
    background: transparent;
}

.hide-scroll::-webkit-scrollbar-thumb {
    background: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/routes/Agency/CreateGigOrder/JobEditor/Steps/StaffingStep/index.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".hide-scroll::-webkit-scrollbar {\n    width: 0px;\n}\n\n.hide-scroll::-webkit-scrollbar-track {\n    background: transparent;\n}\n\n.hide-scroll::-webkit-scrollbar-thumb {\n    background: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import {
  StripeElementStyle,
  StripeCardNumberElementOptions,
  StripeCardExpiryElementOptions,
  StripeCardCvcElementOptions,
} from '@stripe/stripe-js';

import Stack from '@/components/Stack';
import FormColumns from '@/form/FormColumns';
import styled from '@/styles';
import { text, theme, danger } from '@/styles/colors';
import { systemFont } from '@/styles/typography';

export const commonStyles: StripeElementStyle = {
  base: {
    color: text.textDefault,
    fontFamily: systemFont,
    textAlign: 'left',
  },
  complete: { color: theme.themeDarker },
  invalid: { color: danger.dangerDarker, iconColor: danger.dangerDarker },
};

export const cardNumberOptions: StripeCardNumberElementOptions = {
  showIcon: true,
  iconStyle: 'solid',
  style: commonStyles,
};

export const cardExpiryOptions: StripeCardExpiryElementOptions = {
  style: commonStyles,
};

export const cardCvcOptions: StripeCardCvcElementOptions = {
  style: commonStyles,
};

// Simil <Card/>
export const Wrapper = styled('div', {
  width: '100%',
  backgroundColor: 'white',
  borderRadius: '$lg',
});

export const CardElementContainer = styled(Stack, {
  '& .StripeElement': { width: '100%' },
});

export const StyledFormColumns = styled(FormColumns, { width: '100%' });

// Like <Input/> styles
export const CardElementWrapper = styled(Stack, {
  height: '38px',

  padding: '4px 12px',
  width: '100%',

  backgroundColor: 'white',
  border: '1px solid $colors$neutralLight',
  borderRadius: '$lg',
});

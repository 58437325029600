import { Step } from '../context';

import DetailsStep from './DetailsStep';
import PayRateStep from './PayRateStep';
import ReviewStep from './ReviewStep';
import ScheduleStep from './ScheduleStep';
import SkillStep from './SkillStep';
import StaffingStep from './StaffingStep';

import { Scalars } from '@/types/graphql';

type Props = {
  step: Step;
  setStep: (step: Step) => Scalars['Void'];
  onClose: () => Scalars['Void'];
};

const Steps = ({ step, setStep, onClose }: Props) =>
  ({
    [Step.SKILL]: <SkillStep setStep={setStep} onClose={onClose} />,
    [Step.SCHEDULE]: <ScheduleStep setStep={setStep} />,
    [Step.STAFFING]: <StaffingStep setStep={setStep} />,
    [Step.DETAILS]: <DetailsStep setStep={setStep} />,
    [Step.PAYRATE]: <PayRateStep setStep={setStep} />,
    [Step.SUBMISSION]: <ReviewStep setStep={setStep} onClose={onClose} />,
  }[step]);

export default Steps;

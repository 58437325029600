import Card from '@/components/Card';

interface MarkupSectionProps {
  markupValue: number;
}

const MarkupSection = ({ markupValue }: MarkupSectionProps) => {
  return (
    <Card.Section
      css={{ marginBottom: '5px' }}
      title={'MARKUP'}
      titleStyle={{ paddingBottom: '5px' }}
    >
      {`${markupValue}%`}
    </Card.Section>
  );
};

export default MarkupSection;

import useModal from '@area2k/use-modal';

import ChangeAddressModal from './ChangeAddressModal';

import Action from '@/components/Action';
import Card from '@/components/Card';
import TextStack from '@/components/TextStack';
import { Body } from '@/components/Typography';
import { mapUrl } from '@/routes/Agency/Customer/AddressList';
import { GetWorkerQuery } from '@/types/graphql';

type Props = {
  worker: GetWorkerQuery['worker'];
  currentAdminIsCustomerAdmin: boolean;
};

const AddressSection = ({ worker, currentAdminIsCustomerAdmin }: Props) => {
  const [showChangeModal, hideChangeModal] = useModal(
    () => <ChangeAddressModal hideModal={hideChangeModal} worker={worker} />,
    [worker]
  );

  return (
    <Card.Section
      actions={
        !currentAdminIsCustomerAdmin
          ? [
              {
                a11yLabel: "Change this GravyWorker's address",
                label: 'Change',
                onAction: showChangeModal,
                id: 'btn-change-address',
              },
            ]
          : undefined
      }
      title="Address"
    >
      <TextStack>
        <Action.Button
          action={{
            a11yLabel: `Open new tab to ${mapUrl(worker)}`,
            label: worker.addressLine1!,
            href: mapUrl(worker),
            external: true,
          }}
        />
        {worker.addressLine2 && (
          <Body color="light">{worker.addressLine2}</Body>
        )}
        <Body color="light">
          {worker.city}, {worker.state} {worker.zip}
        </Body>
      </TextStack>
    </Card.Section>
  );
};

export default AddressSection;

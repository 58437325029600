import { useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';

import DownloadIcon from '../DownloadIcon';

import { TableCell } from '@/elements/Table';
import { useListInvoicesReportLazyQuery } from '@/graphql';
import { InvoicingReport } from '@/types/graphql';

interface HeaderType {
  label: string;
  key: keyof Omit<InvoicingReport, '__typename'>;
}

type CustomInvoicingReportType = {
  invoicePaid: string;
  openInvoice: string;
};

type InvoicingReportType = Omit<
  InvoicingReport,
  '__typename' | 'invoicePaid' | 'openInvoice'
> &
  CustomInvoicingReportType;

const filename = `InvoicingReport-${Date.now()}.csv`;

const Invoicing = ({ setError }) => {
  const [csvData, setCsvData] = useState<InvoicingReportType[] | null>(null);
  const csvInstance = useRef<any>();

  const [getInvoicesReport, { loading }] = useListInvoicesReportLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data.invoicingReport) {
        const mappedInvoicingReport: InvoicingReportType[] =
          data.invoicingReport.map((item) => ({
            ...item,
            invoiced: Number(item.invoiced) ? 'Yes' : 'No',
            invoicePaid: Number(item.invoicePaid) ? 'Yes' : 'No',
            openInvoice: Number(item.openInvoice) ? 'Yes' : 'No',
          }));

        setCsvData(mappedInvoicingReport);
      }
    },
    onError: (error) => setError({ message: error.message }),
  });

  const headers = useMemo<HeaderType[]>(
    () => [
      { label: 'Company Number', key: 'companyNumber' },
      { label: 'Company Name', key: 'companyName' },
      { label: 'Order Number', key: 'orderNumber' },
      { label: 'Invoice Number', key: 'invoiceNumber' },
      { label: 'Invoice Date', key: 'invoiceDate' },
      { label: 'Invoice Status', key: 'invoiceStatus' },
      { label: 'Job Date', key: 'jobDate' },
      { label: 'UnapprovedHours', key: 'unapprovedHours' },
      { label: 'Invoiced', key: 'invoiced' },
      { label: 'Invoice Paid', key: 'invoicePaid' },
      { label: 'Open Invoice', key: 'openInvoice' },
    ],
    []
  );

  useEffect(() => {
    if (csvData && csvInstance.current && csvInstance.current.link) {
      csvInstance.current.link.click();
      setCsvData(null);
    }
  }, [csvData]);

  function onDownload() {
    getInvoicesReport({ variables: { from: null, to: null } });
  }

  return (
    <>
      <TableCell>Invoicing</TableCell>
      <TableCell />
      <TableCell />
      <TableCell>
        <DownloadIcon isLoading={loading} onClick={onDownload} />
        {csvData && (
          <CSVLink
            ref={csvInstance}
            data={csvData}
            filename={filename}
            headers={headers}
          />
        )}
      </TableCell>
    </>
  );
};

export default Invoicing;

import Card from '@/components/Card';
import TextStack from '@/components/TextStack';
import { Body } from '@/components/Typography';
import { GetWorkerQuery, OnboardingStatusEnum } from '@/types/graphql';
import { parseEnums } from '@/util/text';

type Props = {
  worker: GetWorkerQuery['worker'];
};

const OnboardingStatus = ({ worker }: Props) => {
  const onboardingStatus = (statusEnum) =>
    ({
      [OnboardingStatusEnum.PENDING_SSN]: 'Pending SSN',
    }[statusEnum] || parseEnums(statusEnum));
  return (
    <Card.Section title="Onboarding Status">
      <TextStack>
        <Body>
          {worker.onboardingStatus
            ? onboardingStatus(worker.onboardingStatus)
            : 'not provided'}
        </Body>
      </TextStack>
    </Card.Section>
  );
};

export default OnboardingStatus;

import { PropsWithChildren, ReactNode } from 'react';

import Action from './Action';
import Display from './Display';

import Avatar from '@/components/Avatar';
import Breadcrumbs, {
  Props as BreadcrumbsProps,
} from '@/components/Breadcrumbs';
import SingleColumnLayout, {
  Props as SingleColumnLayoutProps,
} from '@/components/SingleColumnLayout';
import Stack from '@/components/Stack';
import { Subheading, Title } from '@/components/Typography';
import useMediaQuery from '@/hooks/useMediaQuery';
import styled from '@/styles';

const defaultTitle = <Display type="title" />;

const Header = styled('div', {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',

  padding: '32px 0 0',
  variants: {
    headerPadding: {
      true: {
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },
  },
});

export type Props = SingleColumnLayoutProps & {
  breadcrumbs?: BreadcrumbsProps['crumbs'];
  media?: boolean;
  primaryAction?: boolean;
  secondaryActions?: number;
  title?: ReactNode;
  subtitle?: ReactNode;
};

const Page = ({
  breadcrumbs,
  secondaryActions = 0,
  children,
  media = false,
  primaryAction = false,
  subtitle = false,
  title = defaultTitle,
  ...variants
}: PropsWithChildren<Props>) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const hasHeader = title || primaryAction || secondaryActions > 0;
  const size = phoneOnly ? 'full' : 'lg';

  return (
    <SingleColumnLayout noPadding={phoneOnly} size={size} {...variants}>
      {hasHeader && (
        <Header headerPadding={phoneOnly}>
          <Stack gap={phoneOnly ? 8 : 24}>
            {media && <div>{media === true ? <Avatar /> : media}</div>}
            <Stack vertical gap={2}>
              {breadcrumbs && breadcrumbs.length > 0 && (
                <Breadcrumbs crumbs={breadcrumbs} />
              )}
              {typeof title === 'string' ? (
                <Title>{title}</Title>
              ) : title === undefined ? null : (
                title
              )}
              {typeof subtitle === 'string' ? (
                <Subheading color="light">{subtitle}</Subheading>
              ) : subtitle === undefined ? null : (
                subtitle
              )}
            </Stack>
          </Stack>
          <Stack gap={8} justify="end">
            {secondaryActions > 0 && (
              <>
                {Array.from({ length: secondaryActions }, () => (
                  <Action />
                ))}
              </>
            )}
            {primaryAction && <Action />}
          </Stack>
        </Header>
      )}
      <div style={{ margin: '32px 0' }}>{children}</div>
    </SingleColumnLayout>
  );
};

Page.Header = Header;
Header.displayName = 'stitches(Skeleton.Page.Header)';

export default Page;

import { useModal } from 'react-modal-hook';

import ChangeSkillsModal from './ChangeSkillsModal';

import Card from '@/components/Card';
import TagList from '@/components/TagList';
import { GetWorkerQuery } from '@/types/graphql';
import { sortBy } from '@/util/array';

type Props = {
  worker: GetWorkerQuery['worker'];
  currentAdminIsCustomerAdmin: boolean;
};

const SkillsSection = ({ worker, currentAdminIsCustomerAdmin }: Props) => {
  const [showChangeModal, hideChangeModal] = useModal(
    () => <ChangeSkillsModal hideModal={hideChangeModal} worker={worker} />,
    [worker]
  );

  return (
    <Card.Section
      actions={
        !currentAdminIsCustomerAdmin
          ? [
              {
                a11yLabel: "Change this GravyWorker's skills",
                label: 'Change',
                onAction: showChangeModal,
                id: 'btn-change-skill',
              },
            ]
          : undefined
      }
      title="Skills"
    >
      <TagList
        tags={sortBy(worker.skills, 'name').map((skill) => skill.name)}
      />
    </Card.Section>
  );
};

export default SkillsSection;

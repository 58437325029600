import styled from '@/styles';

const StackContainer = styled('div', {
  overflowY: 'auto',
  height: '235px',
  overflowX: 'hidden',
  '@phoneOnly': {
    height: 'auto',
  },
});

export default StackContainer;

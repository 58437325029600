import useModal from '@area2k/use-modal';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useMemo, useState } from 'react';

import RemoveSkillModalConfirm from './removeSkillConfirm';

import Alert from '@/components/Alert';
import Autocomplete from '@/components/Autocomplete';
import Button from '@/components/Button';
import Card from '@/components/Card';
import FormElement from '@/components/FormElement';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import TagList from '@/components/TagList';
import { UNKNOWN_ERROR_TEXT } from '@/constants/text';
import {
  useAddWorkerSkillMutation,
  useListAgencySkillsQuery,
  useRemoveWorkerSkillMutation,
} from '@/graphql';
import useAuth from '@/hooks/useAuth';
import useDebouncedValue from '@/hooks/useDebouncedValue';
import { Maybe } from '@/types';
import { GetWorkerQuery, Scalars } from '@/types/graphql';
import { sortBy } from '@/util/array';

type Item = GetWorkerQuery['worker']['skills'][0];

type Props = {
  hideModal: () => Scalars['Void'];
  worker: GetWorkerQuery['worker'];
};

const ChangeSkillsModal = ({ hideModal, worker }: Props) => {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebouncedValue(query);
  const { currentAgency } = useAuth();
  const [error, setError] =
    useState<Maybe<{ title: Scalars['String']; message?: Scalars['String'] }>>(
      null
    );

  const { data } = useListAgencySkillsQuery({
    variables: { agencyId: currentAgency!.id },
  });

  const [addSkill] = useAddWorkerSkillMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(worker!),
        fields: { skills() {} },
      });
    },
  });
  const [removeSkill] = useRemoveWorkerSkillMutation({
    onError: (error) => {
      setError((prevValues) => {
        const defaultError = {
          ...prevValues,
          title: 'An error has occurred',
          message: UNKNOWN_ERROR_TEXT,
        };

        const gqlError = error.graphQLErrors[0];

        if (gqlError) {
          return {
            ...prevValues,
            title: 'Something went wrong',
            message: gqlError.message,
          };
        }

        return defaultError;
      });
    },
    update: (cache) => {
      cache.modify({
        id: cache.identify(worker!),
        fields: { skills() {}, jobWorkers() {} },
      });
    },
  });

  const items: Item[] = useMemo(() => {
    if (data) {
      const skills = data.agency.skillCategories
        .flatMap((category) => category.skills)
        .filter((skill) => {
          if (
            worker.skills.some((workerSkill) => workerSkill.id === skill.id)
          ) {
            return false;
          }

          return skill.name
            .toLowerCase()
            .includes(debouncedQuery.toLowerCase());
        });

      return sortBy(skills, 'name');
    }

    return [];
  }, [data, debouncedQuery, worker.skills]);

  const sortedWorkerSkills = useMemo(
    () => sortBy(worker.skills, 'name'),
    [worker.skills]
  );

  const [showChangeModal, hideChangeModal] = useModal<{
    index: Scalars['Int'];
  }>(
    ({ index }) => (
      <RemoveSkillModalConfirm
        hideModal={() => {
          hideChangeModal();
        }}
        onAction={() => {
          removeSkill({
            variables: {
              skillId: sortedWorkerSkills[index].id,
              workerId: worker.id,
            },
          });
          hideChangeModal();
        }}
      />
    ),
    [sortedWorkerSkills]
  );

  const handleRemove = useCallback(
    (index: number) => {
      setError(null);
      showChangeModal({ index });
    },
    [sortedWorkerSkills]
  );

  const handleSelect = useCallback(
    (item) => {
      addSkill({
        variables: { skillId: item.selectedItem.id, workerId: worker.id },
      });
    },
    [worker.id]
  );

  return (
    <Modal
      disableClickout
      size="sm"
      title="Change GravyWorker's skills"
      onRequestClose={hideModal}
    >
      <Card.Section>
        {error && (
          <Alert
            description={error.message}
            icon={faExclamationTriangle}
            status="danger"
            title={error.title}
          />
        )}
        <FormElement>
          <TagList
            tags={sortedWorkerSkills.map((skill) => skill.name)}
            onRemove={handleRemove}
          />
        </FormElement>
        <FormElement>
          <Autocomplete
            autoFocus
            fixedSelectHeight="lg"
            id="tag"
            itemToKey={(item) => item.id}
            itemToString={(item) => (item ? item.name : '')}
            items={items}
            placeholder="Search for skills..."
            selectedItem={null}
            onInputValueChange={({ inputValue }) => setQuery(inputValue || '')}
            onSelectedItemChange={handleSelect}
          />
        </FormElement>
        <Stack justify="end">
          <Button
            a11yLabel="Close modal"
            id="btn-finish"
            label="Finish"
            onClick={hideModal}
          />
        </Stack>
      </Card.Section>
    </Modal>
  );
};

export default ChangeSkillsModal;

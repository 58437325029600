import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../index';

interface LayoutState {
  sidebarActive: boolean;
  profileDropdownActive: boolean;
  pendingCustomers: boolean;
  keyboardVisible: boolean;
  refetchDashboardData: boolean;
}

const initialState: LayoutState = {
  sidebarActive: false,
  profileDropdownActive: false,
  pendingCustomers: false,
  keyboardVisible: false,
  refetchDashboardData: false,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setSidebarVisibility: (state, action: PayloadAction<boolean>) => {
      state.sidebarActive = action.payload;
    },
    toggleSidebar: (state) => {
      state.sidebarActive = !state.sidebarActive;
    },
    setProfileDropdownVisibility: (state, action: PayloadAction<boolean>) => {
      state.profileDropdownActive = action.payload;
    },
    toggleProfileDropdown: (state) => {
      state.profileDropdownActive = !state.profileDropdownActive;
    },
    setPendingCustomers: (state, action: PayloadAction<boolean>) => {
      state.pendingCustomers = action.payload;
    },
    setKeyboardVisible: (state, action: PayloadAction<boolean>) => {
      state.keyboardVisible = action.payload;
    },
    setRefetchDashboardData: (state, action: PayloadAction<boolean>) => {
      state.refetchDashboardData = action.payload;
    },
  },
});

export const {
  toggleSidebar,
  setSidebarVisibility,
  setProfileDropdownVisibility,
  toggleProfileDropdown,
  setPendingCustomers,
  setKeyboardVisible,
  setRefetchDashboardData,
} = layoutSlice.actions;

export const selectSidebarActive = (state: RootState) =>
  state.layout.sidebarActive;

export const selectProfileDropdownActive = (state: RootState) =>
  state.layout.profileDropdownActive;

export const selectRefetchDashboard = (state: RootState) =>
  state.layout.refetchDashboardData;

export default layoutSlice.reducer;

import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Dispatch, SetStateAction } from 'react';

import { CardPaymentMethod, EditorProps, EditorTypeEnum } from '..';

import Avatar from '@/components/Avatar';
import Button from '@/components/Button';
import IconicButton from '@/components/IconicButton';
import Option from '@/components/Option';
import Stack from '@/components/Stack';
import { Body, Small } from '@/components/Typography';
import Icon from '@/elements/Icon';
import { PaymentMethodFormValues } from '@/routes/Agency/Account/PaymentSection/modals/ChangePaymentModal';
import { getMappedPaymentMethod } from '@/routes/Agency/Account/PaymentSection/modals/ChangePaymentModal/util';
import { isWeb } from '@/util/platform';

type Props = {
  selectedPaymentMethodId: PaymentMethodFormValues['selectedPaymentMethodId'];
  cards: CardPaymentMethod[];
  setSelectedPaymentMethodId: (paymentMethodId: string) => void;
  setEditor: Dispatch<SetStateAction<EditorProps>>;
};

const CreditCards = ({
  selectedPaymentMethodId,
  cards,
  setSelectedPaymentMethodId,
  setEditor,
}: Props) => {
  const renderLabelByPaymentMethod = (paymentMethod: CardPaymentMethod) => {
    const mappedPaymentMethod = getMappedPaymentMethod(paymentMethod);

    return (
      <Stack inline>
        {mappedPaymentMethod ? (
          <>
            <Icon icon={mappedPaymentMethod.icon} size="2x" />
            <Body>{mappedPaymentMethod.name}</Body>
          </>
        ) : (
          <>
            <Avatar firstName={paymentMethod.card.brand} size="xs" />
            <Body>{paymentMethod.card.brand}</Body>
          </>
        )}

        <Body>**** {paymentMethod.card.last4}</Body>
      </Stack>
    );
  };

  const onRemoveCard = (paymentMethod: CardPaymentMethod) => {
    setEditor((prevValues) => ({
      ...prevValues,
      isOpen: true,
      type: EditorTypeEnum.REMOVE_CARD,
      selectedPaymentMethod: paymentMethod,
    }));
  };

  const onAddCard = () => {
    setEditor((prevValues) => ({
      ...prevValues,
      isOpen: true,
      type: EditorTypeEnum.ADD_CARD,
    }));
  };

  return (
    <>
      {cards.length === 0 ? (
        <Stack css={{ paddingLeft: '2rem' }}>
          <Small>No Credit Card yet</Small>
        </Stack>
      ) : (
        cards.map((paymentMethod, index) => (
          <Stack
            key={paymentMethod.id}
            css={{ paddingLeft: '2rem' }}
            justify="apart"
          >
            <Option
              checked={selectedPaymentMethodId === paymentMethod.id}
              id={`${index}`}
              label={renderLabelByPaymentMethod(paymentMethod)}
              name="creditCard"
              type="radio"
              value={paymentMethod.id}
              onChange={({ currentTarget: { value: paymentMethodId } }) => {
                setSelectedPaymentMethodId(paymentMethodId);
              }}
            />
            <IconicButton
              a11yLabel="Remove this credit Card"
              appearance="clear"
              icon={faTrash}
              status="danger"
              type="button"
              onClick={() => onRemoveCard(paymentMethod)}
            />
          </Stack>
        ))
      )}

      {isWeb() && (
        <Stack css={{ marginLeft: '2rem' }}>
          <Button
            a11yLabel="Add New Card"
            id="add-card-btn"
            label="Add New Card"
            type="button"
            onClick={onAddCard}
          />
        </Stack>
      )}
    </>
  );
};

export default CreditCards;

import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useState } from 'react';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';

import { Step } from '../..';
import { useSignUpActions } from '../../hooks/useSignUpActions';
import { useSignUpState } from '../../hooks/useSignUpState';
import { SecondStepState } from '../../interfaces/signUp.interface';
import { BackButton, InputWrapper } from '../../styles';

import { secondStepSchema } from './schema';

import AddressAutocomplete from '@/components/AddressAutocomplete';
import Button from '@/components/Button';
import Card from '@/components/Card';
import FormElement from '@/components/FormElement';
import Stack from '@/components/Stack';
import { Small } from '@/components/Typography';
import { INCORRECT_STATE_ERROR_MSG } from '@/constants/general';
import Input from '@/elements/Input';
import FormColumns from '@/form/FormColumns';
import useGetValidStates from '@/hooks/useGetValidStates';

type Props = {
  setStep: (step: Step) => void;
};

const SecondStep = ({ setStep }: Props) => {
  const { companyName, address } = useSignUpState();
  const { updateSecondStep } = useSignUpActions();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm<SecondStepState>({
    resolver: yupResolver(secondStepSchema),
    mode: 'onChange',
    defaultValues: {
      address,
    },
  });
  const [invalidStateError, showInvalidStateError] = useState<boolean>(false);
  const validStates = useGetValidStates();

  const onSubmit: SubmitHandler<SecondStepState> = useCallback((values) => {
    updateSecondStep(values);

    return setStep(Step.THIRD);
  }, []);

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Card.Section>
        <FormColumns layout="single">
          <FormElement htmlFor="companyName" label="Company Name">
            <Input disabled id="companyName" value={companyName} />
          </FormElement>
        </FormColumns>

        <FormColumns layout="single">
          <FormElement htmlFor="address" label="Address">
            <Stack vertical verticalGap={6}>
              <InputWrapper>
                <Controller
                  control={control}
                  name="address"
                  render={({ field: { value, onChange } }) => (
                    <AddressAutocomplete
                      setValues={(address) => {
                        showInvalidStateError(
                          !validStates.includes(address?.state)
                        );
                        onChange(address);
                      }}
                      values={value}
                      onReset={(address) => {
                        showInvalidStateError(false);
                        onChange(address);
                      }}
                    />
                  )}
                />
              </InputWrapper>
              {errors.address && (
                <Small color="danger">Address is a required field</Small>
              )}
              {invalidStateError && (
                <Small color="danger">{INCORRECT_STATE_ERROR_MSG}</Small>
              )}
            </Stack>
          </FormElement>
        </FormColumns>

        <FormColumns layout="single">
          <FormElement htmlFor="city" label="City">
            <Stack vertical verticalGap={6}>
              <Input disabled id="city" {...register('address.city')} />
              {errors.address && errors.address.city && (
                <Small color="danger">{errors.address.city.message}</Small>
              )}
            </Stack>
          </FormElement>
        </FormColumns>

        <FormColumns layout="double">
          <FormElement htmlFor="state" label="State">
            <Stack vertical verticalGap={6}>
              <Input disabled id="state" {...register('address.state')} />
              {errors.address && errors.address.state && (
                <Small color="danger">{errors.address.state.message}</Small>
              )}
            </Stack>
          </FormElement>

          <FormElement htmlFor="zip" label="Zip Code">
            <Stack vertical verticalGap={6}>
              <Input disabled id="zip" {...register('address.zip')} />
              {errors.address && errors.address.zip && (
                <Small color="danger">{errors.address.zip.message}</Small>
              )}
            </Stack>
          </FormElement>
        </FormColumns>

        <FormColumns layout="double">
          <BackButton
            a11yLabel="Go back to previous step"
            appearance="outline"
            id="back-btn"
            label="Back"
            type="button"
            onClick={() => setStep(Step.FIRST)}
          />

          <Button
            a11yLabel="Submit form"
            disabled={!isValid || invalidStateError}
            id="next-btn"
            label="Next"
            type="submit"
          />
        </FormColumns>
      </Card.Section>
    </form>
  );
};

export default SecondStep;

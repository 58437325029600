import { useMemo } from 'react';

import Button from '@/components/Button';
import Stack from '@/components/Stack';
import Tag from '@/components/Tag';
import { useGetCustomerAndSkillsQuery } from '@/graphql';
import useAuth from '@/hooks/useAuth';
import useMediaQuery from '@/hooks/useMediaQuery';
import { JobDashboardStatusEnum, JobFilterSetInput } from '@/types/graphql';

type TagControl = {
  label: string;
  onRemove: () => void;
};

type Props = {
  filters: JobFilterSetInput;
  onChangeFilter: <K extends keyof JobFilterSetInput>(
    key: K,
    value: NonNullable<JobFilterSetInput[K]>,
  ) => void;
  onClearAll: () => void;
  onClearFilter: (key: keyof JobFilterSetInput) => void;
};

const TagsFilter = ({
  filters,
  onClearAll,
  onChangeFilter,
  onClearFilter,
}: Props) => {
  const { currentAgency, currentAdminIsCustomerAdmin } = useAuth();
  const { data } = useGetCustomerAndSkillsQuery({
    variables: {
      agencyId: currentAgency?.id!,
    },
  });
  const phoneOnly = useMediaQuery('(max-width: 559px)');

  const allClients = useMemo(() => {
    if (data) {
      return data.agency.customers.items;
    }
    return [];
  }, [data]);

  const allSkills = useMemo(() => {
    if (data) {
      return data.agency.skillCategories.flatMap((skill) => {
        if (skill.skills) {
          return skill.skills;
        }
        return [];
      });
    }
    return [];
  }, [data]);

  const tagControls = useMemo<TagControl[]>(() => {
    const controls: TagControl[] = [];

    Object.keys(filters).forEach((key) => {
      switch (key) {
        case 'fillStatus':
          const value = filters.fillStatus;
          controls.push({
            label: `Fill Status: ${value}`,
            onRemove: () => onClearFilter('fillStatus'),
          });
          break;
        case 'visibility':
          const status = filters.visibility!;
          controls.push({
            label: `Job Status: ${status}`,
            onRemove: () => onClearFilter('visibility'),
          });
          break;
        case 'needsApproval':
          const needsApprovalValue = filters.needsApproval!;
          controls.push({
            label: `Timesheet Status: ${needsApprovalValue}`,
            onRemove: () => onClearFilter('needsApproval'),
          });
          break;
        case 'jobDashboardStatus':
          const orderStatus = filters.jobDashboardStatus!;
          orderStatus.forEach((status) => {
            const statusWithoutCurrentStatus = orderStatus.filter(
              (s) => s !== status,
            );

            controls.push({
              label: `Order Status: ${
                {
                  [JobDashboardStatusEnum.ACTIVE]: 'Active',
                  [JobDashboardStatusEnum.CANCELLED]: 'Cancelled',
                  [JobDashboardStatusEnum.NO_SHOW]: 'No Show',
                }[status]
              }`,
              onRemove:
                statusWithoutCurrentStatus.length < 1
                  ? () => onClearFilter('jobDashboardStatus')
                  : () =>
                      onChangeFilter(
                        'jobDashboardStatus',
                        statusWithoutCurrentStatus,
                      ),
            });
          });
          break;

        case 'states':
          const states = filters.states!;
          states.forEach((state) => {
            const stateWithoutCurrentState = states.filter((s) => s !== state);
            controls.push({
              label: `State: ${state}`,
              onRemove:
                stateWithoutCurrentState.length < 1
                  ? () => onClearFilter('states')
                  : () => onChangeFilter('states', stateWithoutCurrentState),
            });
          });
          break;

        case 'skillIds':
          const skillIds = filters.skillIds!;
          skillIds.forEach((skillid) => {
            const skillName =
              allSkills.find((skill) => skill!.id === skillid)?.name ?? '';
            const skillsWithouthCurrentSkill = skillIds.filter(
              (s) => s !== skillid,
            );
            controls.push({
              label: `Skill: ${skillName}`,
              onRemove:
                skillsWithouthCurrentSkill.length < 1
                  ? () => onClearFilter('skillIds')
                  : () =>
                      onChangeFilter('skillIds', skillsWithouthCurrentSkill),
            });
          });
          break;

        case 'customerIds':
          if (currentAdminIsCustomerAdmin) {
            return;
          }
          const customersId = filters.customerIds!;
          customersId.forEach((customerId) => {
            const customerName =
              allClients.find((customer) => customer!.id === customerId)
                ?.name ?? '';
            const customerWithOutCurrentCustomer = customersId.filter(
              (s) => s !== customerId,
            );
            controls.push({
              label: `${customerName}`,
              onRemove:
                customerWithOutCurrentCustomer.length < 1
                  ? () => onClearFilter('customerIds')
                  : () =>
                      onChangeFilter(
                        'customerIds',
                        customerWithOutCurrentCustomer,
                      ),
            });
          });
          break;
      }
    });

    return controls;
  }, [filters]);

  if (tagControls.length === 0) {
    return null;
  }

  return (
    <Stack gap={16} vertical={phoneOnly}>
      <Stack wrap="true">
        {tagControls.map(
          (tagControl, index) =>
            tagControl && (
              <Tag
                key={index}
                id={index}
                label={tagControl.label}
                onRemove={tagControl.onRemove}
              />
            ),
        )}
      </Stack>

      <Button
        a11yLabel="Clear all filters"
        appearance={phoneOnly ? 'outline' : 'plain'}
        id="btn_clear_all_filters"
        label="Clear all"
        onClick={() => {
          onClearAll();
        }}
      />
    </Stack>
  );
};

export default TagsFilter;

import { useReactiveVar } from '@apollo/client';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useState } from 'react';
import { useModal } from 'react-modal-hook';

import { CandidateItem } from '../helpers';

import Alert from '@/components/Alert';
import Autocomplete from '@/components/Autocomplete';
import Button from '@/components/Button';
import Card from '@/components/Card';
import ConfirmationModal from '@/components/ConfirmationModal';
import EditAddress, { TypeEditorEnum } from '@/components/EditAddress';
import ExperienceForm from '@/components/ExperienceForm';
import FormElement from '@/components/FormElement';
import Modal from '@/components/Modal';
import Option from '@/components/Option';
import Stack from '@/components/Stack';
import TagList from '@/components/TagList';
import Form from '@/form';
import FormColumns from '@/form/FormColumns';
import MaskedInputField from '@/form/MaskedInputField';
import SingleOptionGroupField from '@/form/SingleOptionGroupField';
import TextAreaField from '@/form/TextAreaField';
import TextField from '@/form/TextField';
import TextSelectField from '@/form/TextSelectField';
import {
  useAddCommentMutation,
  useAddWorkerReferenceMutation,
  useAddWorkerSkillMutation,
  useBlockWorkerMutation,
  useListAgencySkillsQuery,
  useRemoveWorkerReferenceMutation,
  useRemoveWorkerSkillMutation,
  useUpdateApplicantMutation,
} from '@/graphql';
import useDebouncedValue from '@/hooks/useDebouncedValue';
import useGetSortSkillItems from '@/hooks/useGetSortSkillItems';
import { Maybe, SkillItem } from '@/types';
import {
  CandidateStatusEnum,
  CommentableEnum,
  GetWorkerQuery,
  LanguageEnum,
  ListCandidatesQuery,
  PointInput,
  SmartphoneTypeEnum,
  TaxTypeEnum,
  WorkerReference,
} from '@/types/graphql';
import { currentAgencyVar } from '@/util/apollo/cache';
import { CONFIRM_MSG } from '@/util/constants';

type Props = {
  experiences: WorkerReference[];
  hideModal: () => void;
  applicant: CandidateItem;
};

type UserFormValues = {
  active: boolean;
  disableReason: string;
  candidateStatus: string;
  taxType: TaxTypeEnum;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phoneNumber: Maybe<string>;
  language: LanguageEnum;
  secondaryLanguage: LanguageEnum;
  addressLine1: string;
  addressLine2: string;
  city: Maybe<string>;
  state: Maybe<string>;
  zip: string;
  coords: PointInput;
  smartphoneType: SmartphoneTypeEnum;
  hasSsn: boolean;
  ownsCar: boolean;
  dateOfBirth: GetWorkerQuery['worker']['user']['dateOfBirth'];
  deactivatedByUser: ListCandidatesQuery['agency']['candidates']['items'][0]['deactivatedByUser'];
};

const languageOptions = [
  { label: 'English', value: LanguageEnum.ENGLISH },
  { label: 'Spanish', value: LanguageEnum.SPANISH },
];

const smartPhoneTypeOptions = [
  { label: 'Android', value: SmartphoneTypeEnum.ANDROID },
  { label: 'IOS', value: SmartphoneTypeEnum.IOS },
  { label: 'Other', value: SmartphoneTypeEnum.OTHER },
];

const statusOptions = [
  { label: 'Applied', value: CandidateStatusEnum.APPLIED },
  {
    label: 'Skip Video Questionnaire',
    value: CandidateStatusEnum.START_HIRE,
  },
  { label: 'Reject Worker', value: CandidateStatusEnum.REJECTED },
];

const taxOptions = [
  { label: 'W2', value: TaxTypeEnum.TAX_W2 },
  { label: '1099', value: TaxTypeEnum.TAX_1099 },
];

const EditApplicantModal = ({ applicant, experiences, hideModal }: Props) => {
  const [query, setQuery] = useState('');
  const [skills, setSkills] = useState<SkillItem[]>(applicant.skills);
  const [references, setReferences] = useState<WorkerReference[]>(experiences);
  const [deleteQueue, setDeleteQueue] = useState<string[]>([]);
  const [userFormValues, setUserFormValues] = useState<UserFormValues>({
    active: Boolean(applicant.active),
    disableReason: applicant.disableReason || '',
    candidateStatus: applicant.candidateStatus,
    taxType: applicant.taxType,
    firstName: applicant.user.firstName,
    middleName: applicant.user.middleName ?? '',
    lastName: applicant.user.lastName,
    dateOfBirth: applicant.user.dateOfBirth
      ? new Date(applicant.user.dateOfBirth!).toISOString().split('T')[0]
      : null,
    email: applicant.user.email,
    phoneNumber: applicant.user.phoneNumber,
    language: applicant.user.language,
    secondaryLanguage: applicant.user.secondaryLanguage!,
    addressLine1: applicant.addressLine1!,
    addressLine2: applicant.addressLine2!,
    city: applicant.city,
    state: applicant.state,
    zip: applicant.zip!,
    coords: applicant.coords!,
    hasSsn: applicant.taxType === TaxTypeEnum.TAX_W2 ? true : applicant.hasSsn,
    ownsCar: applicant.ownsCar ? applicant.ownsCar : false,
    smartphoneType: applicant.user.smartphoneType || SmartphoneTypeEnum.OTHER,
    deactivatedByUser: applicant.deactivatedByUser,
  });
  const [comment, setComment] = useState<string | null>(
    applicant.comments.length > 0
      ? applicant.comments[applicant.comments.length - 1].body
      : ''
  );
  const [error, setError] = useState<{ active: boolean; obj: Error | unknown }>(
    {
      active: false,
      obj: {},
    }
  );
  const initialReferenceLength = applicant.references
    ? applicant.references.length
    : 0;
  const initialComment =
    applicant.comments.length > 0
      ? applicant.comments[applicant.comments.length - 1].body
      : '';

  const debouncedQuery = useDebouncedValue(query);
  const currentAgency = useReactiveVar(currentAgencyVar);
  const [blockWorker] = useBlockWorkerMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(currentAgency!),
        fields: { candidates() {} },
      });
    },
  });

  const [showConfirmationModal, hideConfirmationModal] = useModal(
    () => (
      <ConfirmationModal
        acceptAction={handleSubmit}
        acceptButtonLabel="Confirm"
        bodyContentText={CONFIRM_MSG}
        denyAction={() => {}}
        denyButtonLabel="Cancel"
        hideModal={hideConfirmationModal}
        title={`Confirmation`}
      />
    ),
    [
      comment,
      skills,
      userFormValues,
      references,
      deleteQueue,
      initialReferenceLength,
    ]
  );

  const blockWorkerAction = async (applicant) => {
    try {
      await blockWorker({
        variables: {
          workerId: applicant.id,
          reason: applicant.disableReason,
          active: applicant.active,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const { data } = useListAgencySkillsQuery({
    variables: { agencyId: currentAgency!.id },
  });

  const updateCache = {
    update: (cache) => {
      cache.modify({
        id: cache.identify(currentAgency!),
        fields: { candidates() {} },
      });
    },
  };

  const [addSkill] = useAddWorkerSkillMutation(updateCache);
  const [removeSkill] = useRemoveWorkerSkillMutation(updateCache);
  const [addReference] = useAddWorkerReferenceMutation(updateCache);
  const [removeReference] = useRemoveWorkerReferenceMutation(updateCache);
  const [addComment] = useAddCommentMutation(updateCache);
  const [updateApplicant] = useUpdateApplicantMutation(updateCache);

  const { items, sortedWorkerSkills } = useGetSortSkillItems(
    data,
    skills,
    debouncedQuery
  );

  const handleRemove = useCallback(
    async (index: number) => {
      try {
        const trueIndex = skills.findIndex(
          (skill) => skill.id === sortedWorkerSkills[index].id
        );
        const skillsCopy = [...skills];
        skillsCopy.splice(trueIndex, 1);
        setSkills([...skillsCopy]);
        await removeSkill({
          variables: {
            skillId: sortedWorkerSkills[index].id,
            workerId: applicant.id,
          },
        });
      } catch (error) {
        setError({ active: true, obj: error });
      }
    },
    [applicant, skills, sortedWorkerSkills]
  );

  const handleSelect = useCallback(
    async (item) => {
      try {
        setSkills([...skills, item.selectedItem]);
        await addSkill({
          variables: {
            skillId: item.selectedItem.id,
            workerId: applicant.id,
          },
        });
      } catch (error) {
        setError({ active: true, obj: error });
      }
    },
    [applicant, skills]
  );

  const handleFormValuesChange = ({ value }, id) => {
    setUserFormValues({ ...userFormValues, [id]: value });
  };

  const submitReferences = useCallback(() => {
    try {
      references.map(async (reference) => {
        if (reference.id === undefined) {
          await addReference({
            variables: {
              ...reference,
              workerId: applicant.id,
            },
          });
        }
      });
    } catch (error) {
      setError({ active: true, obj: error });
    }
  }, [references]);

  const deleteReferences = useCallback(async () => {
    try {
      deleteQueue.map(async (workerReferenceId) => {
        await removeReference({
          variables: { workerReferenceId },
        });
      });
    } catch (error) {
      setError({ active: true, obj: error });
    }
  }, [deleteQueue]);

  const handleSubmit = useCallback(async () => {
    const skillIds = skills.map(({ id }) => id);

    try {
      if (userFormValues.email === '') {
        setError({
          active: true,
          obj: {
            name: 'Field is empty',
            message: 'Email address is required',
          },
        });
        return;
      }

      if (
        (userFormValues.candidateStatus as CandidateStatusEnum) ===
        CandidateStatusEnum.START_HIRE
      ) {
        if (userFormValues.firstName === '') {
          setError({
            active: true,
            obj: {
              name: 'Field is empty',
              message: 'First Name is required',
            },
          });
          return;
        } else if (userFormValues.lastName === '') {
          setError({
            active: true,
            obj: {
              name: 'Field is empty',
              message: 'Last Name is required',
            },
          });
          return;
        } else if (userFormValues.phoneNumber === '') {
          setError({
            active: true,
            obj: {
              name: 'Field is empty',
              message: 'Phone Number is required',
            },
          });
          return;
        } else if (!userFormValues.dateOfBirth) {
          setError({
            active: true,
            obj: {
              name: 'Field is empty',
              message: 'Date of Birth is required',
            },
          });
          return;
        } else if (!userFormValues.addressLine1) {
          setError({
            active: true,
            obj: {
              name: 'Field is empty',
              message: 'Address is required',
            },
          });
          return;
        }
      }

      if (references.length > 0 && references.length !== initialReferenceLength)
        await submitReferences();
      if (deleteReferences.length > 0) await deleteReferences();
      if (initialComment !== comment)
        await addComment({
          variables: {
            commentableId: applicant.id,
            commentableType: CommentableEnum.WORKER,
            body: comment as string,
          },
        });

      const {
        active,
        disableReason,
        addressLine1,
        addressLine2,
        candidateStatus,
        city,
        email,
        firstName,
        middleName,
        language,
        lastName,
        dateOfBirth,
        ownsCar,
        phoneNumber,
        secondaryLanguage,
        smartphoneType,
        state,
        taxType,
        zip,
        hasSsn,
      } = userFormValues;

      await blockWorkerAction({
        id: applicant.id,
        active,
        disableReason,
      });

      await updateApplicant({
        variables: {
          workerId: applicant.id,
          skillIds,
          addressLine1,
          addressLine2,
          candidateStatus: candidateStatus as CandidateStatusEnum,
          city: city!,
          email,
          firstName,
          middleName,
          language,
          lastName,
          dateOfBirth: dateOfBirth ?? '',
          ownsCar,
          phoneNumber: phoneNumber!,
          secondaryLanguage,
          smartphoneType,
          state: state!,
          taxType,
          zip,
          hasSsn,
        },
      });
      hideModal();
    } catch (error) {
      setError({ active: true, obj: error });
    }
  }, [
    comment,
    skills,
    userFormValues,
    references,
    deleteQueue,
    initialReferenceLength,
  ]);

  return (
    <Modal
      disableClickout
      size="xl"
      title="Edit Applicant"
      onRequestClose={hideModal}
    >
      <Card.Section>
        {error.active && (
          <Alert
            description={(error.obj as Error).message}
            icon={faExclamationTriangle}
            status="danger"
            title={(error.obj as Error).name}
          />
        )}

        <Form
          initialValues={{ ...userFormValues, comment }}
          onSubmit={handleSubmit}
        >
          <FormColumns layout="tuple">
            <TextSelectField
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'candidateStatus');
              }}
              fieldId="candidateStatus"
              label="Status"
              options={statusOptions}
            />
            <SingleOptionGroupField
              callback={({ value }) => {
                const selectedTaxType = value as TaxTypeEnum;

                setUserFormValues({
                  ...userFormValues,
                  taxType: selectedTaxType,
                  hasSsn:
                    selectedTaxType === TaxTypeEnum.TAX_W2 || applicant.hasSsn,
                });
              }}
              fieldId="taxType"
              label="Tax type"
              options={taxOptions}
            />
            <FormElement label="Important Information">
              <FormColumns layout="single">
                {userFormValues.taxType === TaxTypeEnum.TAX_1099 && (
                  <Option
                    appearance="checkbox"
                    checked={userFormValues.hasSsn}
                    id="hasSsn"
                    label="Eligible for W2 employment"
                    onChange={() => {
                      setUserFormValues((prevState) => ({
                        ...prevState,
                        hasSsn: !prevState.hasSsn,
                      }));
                    }}
                  />
                )}
                <Option
                  appearance="checkbox"
                  checked={userFormValues.ownsCar}
                  id="OwnsCar"
                  label="Access To Reliable Transportation?"
                  onChange={() => {
                    setUserFormValues((prevState) => ({
                      ...prevState,
                      ownsCar: !prevState.ownsCar,
                    }));
                  }}
                />
              </FormColumns>
            </FormElement>
          </FormColumns>
          <FormColumns layout="triple">
            <TextField
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'firstName');
              }}
              fieldId="firstName"
              label="First Name"
              placeholder="First Name"
            />
            <TextField
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'middleName');
              }}
              fieldId="middleName"
              label="Middle Name"
              placeholder="Middle Name"
            />
            <TextField
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'lastName');
              }}
              fieldId="lastName"
              label="Last Name"
              placeholder="Last Name"
            />
            <TextField
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'dateOfBirth');
              }}
              fieldId="dateOfBirth"
              label="Date of Birth"
              type="date"
            />
          </FormColumns>
          <FormColumns layout="triple">
            <TextField
              required
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'email');
              }}
              fieldId="email"
              label="Email"
              placeholder="Email"
            />
            <MaskedInputField
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'phoneNumber');
              }}
              fieldId="phoneNumber"
              incompletemessage="Must be a valid phone number"
              label="Phone Number"
              mask="(000) 000-0000"
              placeholder={
                applicant.user.phoneNumber
                  ? applicant.user.phoneNumber
                  : '(555) 555-5555'
              }
              type="tel"
            />
            <TextSelectField
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'smartphoneType');
              }}
              fieldId="smartphoneType"
              label="Phone Type"
              options={smartPhoneTypeOptions}
            />
          </FormColumns>
          <FormColumns layout="single">
            <FormElement label="Address">
              <EditAddress
                direction="row"
                setValues={setUserFormValues}
                typeEditor={TypeEditorEnum.FOR_WORKER}
                values={userFormValues}
              />
            </FormElement>
          </FormColumns>
          <FormColumns layout="double">
            <TextSelectField
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'language');
              }}
              fieldId="language"
              label="Primary language"
              options={languageOptions}
            />
            <TextSelectField
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'secondaryLanguage');
              }}
              fieldId="secondaryLanguage"
              label="Secondary language"
              options={languageOptions}
            />
          </FormColumns>
          <FormColumns>
            <FormElement label="Skills">
              {sortedWorkerSkills.length > 0 && (
                <div style={{ marginBottom: 8 }}>
                  <TagList
                    tags={sortedWorkerSkills.map((skill) => skill.name)}
                    onRemove={handleRemove}
                  />
                </div>
              )}
              <Autocomplete
                fixedSelectHeight="lg"
                id="skillFather"
                itemToKey={(item) => item.id}
                itemToString={(item) => (item ? item.name : '')}
                items={items}
                placeholder="Search for skills..."
                selectedItem={null}
                onInputValueChange={({ inputValue }) =>
                  setQuery(inputValue || '')
                }
                onSelectedItemChange={handleSelect}
              />
            </FormElement>
            <TextAreaField
              callback={(fieldContext) => {
                setComment(fieldContext.value);
              }}
              fieldId="comment"
              label="Notes"
              placeholder="Any notes..."
            />
          </FormColumns>
          <Card.Section flush title="Experience form">
            <ExperienceForm
              deleteExperiences={deleteQueue}
              experiences={references}
              handleAddition={setReferences}
              handleDeletion={setDeleteQueue}
            />
          </Card.Section>
          <FormColumns>
            <FormElement label="Status">
              <Option
                appearance="switch"
                checked={userFormValues.active}
                id="worker-status"
                label="Account Enabled"
                onChange={() =>
                  handleFormValuesChange(
                    { value: !userFormValues.active },
                    'active'
                  )
                }
              />
              <FormElement>
                {!userFormValues.active && userFormValues.deactivatedByUser && (
                  <>
                    <span>Account has been disabled by </span>
                    <span style={{ fontWeight: 'bold' }}>
                      {[
                        userFormValues.deactivatedByUser.firstName,
                        userFormValues.deactivatedByUser.middleName,
                        userFormValues.deactivatedByUser.lastName,
                      ].join(' ')}
                    </span>
                  </>
                )}
              </FormElement>
            </FormElement>
            {!userFormValues.active && (
              <FormElement label="Account Disabling Reason">
                <TextAreaField
                  autoFocus
                  callback={(fieldContext) => {
                    handleFormValuesChange(fieldContext, 'disableReason');
                  }}
                  fieldId={'disableReason'}
                  maxLength={250}
                  placeholder="State reason for disabling the worker"
                />
              </FormElement>
            )}
          </FormColumns>
        </Form>
      </Card.Section>
      <Card.Section>
        <Stack justify="end">
          <Button
            a11yLabel="Submit"
            id="btn-submit"
            isLoading={undefined}
            label="Save"
            type="submit"
            onClick={showConfirmationModal}
          />
        </Stack>
      </Card.Section>
    </Modal>
  );
};

export default EditApplicantModal;

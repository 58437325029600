import { createSvgIcon } from '@mui/material';

const ApproveReverseIcon = createSvgIcon(
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 18.5455C10.2098 18.5455 8.4929 17.8558 7.22703 16.6283C5.96116 15.4008 5.25 13.736 5.25 12C5.25 10.9091 5.53125 9.85091 6.0375 8.94545L4.395 7.35273C3.5175 8.69455 3 10.2873 3 12C3 14.3146 3.94821 16.5344 5.63604 18.1711C7.32387 19.8078 9.61305 20.7273 12 20.7273V24L16.5 19.6364L12 15.2727M12 3.27273V0L7.5 4.36364L12 8.72727V5.45455C13.7902 5.45455 15.5071 6.14415 16.773 7.37167C18.0388 8.59918 18.75 10.264 18.75 12C18.75 13.0909 18.4687 14.1491 17.9625 15.0545L19.605 16.6473C20.4825 15.3055 21 13.7127 21 12C21 9.68538 20.0518 7.46557 18.364 5.82889C16.6761 4.19221 14.3869 3.27273 12 3.27273Z"
      fill="currentColor"
    />
    <path
      d="M15.7855 8L10.4453 13.4362L8.21447 11.0989L7 12.3763L10.4453 16L17 9.28642L15.7855 8Z"
      fill="currentColor"
    />
  </svg>,
  'ApproveReverseIcon'
);
export default ApproveReverseIcon;

import { createContext, Reducer, useContext } from 'react';

import { DateRange } from './Steps/ScheduleStep/ScheduleSection/DateRangeInput';

import { Maybe, RequiredNotNull, CreateJobContactAdminType } from '@/types';
import {
  AutocompleteCustomersQuery,
  SkillCategory as SK,
  ListUniformsQuery,
  ListWorkersQuery,
  JobTypeEnum,
} from '@/types/graphql';
import { noOp } from '@/util/actions';

export type Address =
  AutocompleteCustomersQuery['agency']['customers']['items'][0]['addresses'][0];
export type CustomerAdmin = any;
export type SkillCategory = SK;
export type Skill = SK['skills'][0];
export type Uniform = ListUniformsQuery['agency']['uniforms'][0];
export type Rate = number;
export type Worker = ListWorkersQuery['agency']['workers']['items'][0];

export enum Step {
  SKILL = 1,
  SCHEDULE = 2,
  STAFFING = 3,
  DETAILS = 4,
  PAYRATE = 5,
  SUBMISSION = 6,
}
export const StepsName = {
  [Step.SKILL]: 'Skill',
  [Step.STAFFING]: 'Staffing',
  [Step.SCHEDULE]: 'Schedule',
  [Step.DETAILS]: 'Instructions',
  [Step.PAYRATE]: 'Payrate',
  [Step.SUBMISSION]: 'Submission',
};

export type Time = {
  hour: number;
  minute: number;
};

export enum ScheduleType {
  MULTIPLE_DAYS = 'multiple_days',
  ONE_DAY = 'one_day',
}

export type Schedule = {
  costRate: number;
  dateRange: DateRange;
  displayDates: DateRange;
  groupId: number;
  endTime: string;
  payRate: number;
  payRateInput: string;
  quantity: number;
  startTime: string;
  allShiftsSameWorker: boolean;
  mandatoryBreakTime: number;
  isHoliday?: boolean;
  rate?: number;
  originalPayRate: number;
  originalCostRate: number;
  minCost?: number;
  minPay?: number;
  selectedWorkers: any[];
  hiredWorkers: any[];
};

export type PostSetting = Maybe<'everyone' | 'my_selections'>;

export enum POSTSETTINGS {
  MY_SELECTIONS = 'my_selections',
  EVERYONE = 'everyone',
}

export enum PublishInEnum {
  IMMEDIATELY = 'IMMEDIATELY',
  ONE_HOUR = 'ONE_HOUR',
  SIX_HOURS = 'SIX_HOURS',
  TWELVE_HOURS = 'TWELVE_HOURS',
  TWENTY_FOUR_HOURS = 'TWENTY_FOUR_HOURS',
  FORTY_EIGHT_HOURS = 'FORTY_EIGHT_HOURS',
  SEVENTY_TWO_HOURS = 'SEVENTY_TWO_HOURS',
  NEVER = 'NEVER',
}

export type BaseJobDraftState = {
  skillCategory: Maybe<SkillCategory>;
  skill: Maybe<Skill>;
  address: Maybe<Address>;
  addressInstructions: string;

  schedules: Schedule[];

  instructions: string;
  markupPercent: Maybe<Rate>;
  minPay: Maybe<Rate>;
  maxPay: Maybe<Rate>;
  payRate: Maybe<Rate>;
  uniform: Maybe<Uniform>;
  uniformInstructions: string;
  postSetting: PostSetting;
  publishIn: PublishInEnum;
  publishJob: boolean;

  contact: Maybe<CreateJobContactAdminType>;
  contactInstructions: string;
  jobType: Maybe<JobTypeEnum>;
  lastShiftInfo?: Schedule;
  rateId: Maybe<number>;
};

// ===== TYPES =====
export type JobDraftState = { completedStep: Maybe<Step> } & BaseJobDraftState;

export type mandatoryFieldInSkillStateUpdate = RequiredNotNull<
  Pick<
    BaseJobDraftState,
    'skill' | 'skillCategory' | 'address' | 'contact' | 'contactInstructions'
  >
>;
export type SkillStateUpdate = mandatoryFieldInSkillStateUpdate &
  Pick<BaseJobDraftState, 'addressInstructions' | 'rateId'>;
export type DetailsStateUpdate = Pick<
  BaseJobDraftState,
  | 'contact'
  | 'contactInstructions'
  | 'instructions'
  | 'uniform'
  | 'uniformInstructions'
>;
export type ScheduleStateUpdate = Pick<BaseJobDraftState, 'schedules'>;
export type LastShiftUpdate = Pick<BaseJobDraftState, 'lastShiftInfo'>;
export type UpdateJob = Partial<BaseJobDraftState>;
export type UpdateRates = Pick<BaseJobDraftState, 'payRate'>;
export type PublishingStateUpdate = Pick<
  BaseJobDraftState,
  'postSetting' | 'publishIn' | 'publishJob'
>;
export type CompletedStepUpdate = { completedStep: Maybe<Step> };

// ===== CONTEXT =====
export const initialState: JobDraftState = {
  completedStep: null,
  skill: null,
  skillCategory: null,
  address: null,
  addressInstructions: '',

  schedules: [],

  instructions: '',
  markupPercent: null,
  minPay: null,
  maxPay: null,
  payRate: null,
  uniform: null,
  uniformInstructions: '',

  postSetting: null,
  publishIn: PublishInEnum.IMMEDIATELY,
  publishJob: true,

  contact: null,
  contactInstructions: '',
  jobType: null,
  lastShiftInfo: undefined,
  rateId: null,
};

export const JobDraftStateContext = createContext<JobDraftState>(initialState);

export type JobDraftActions = {
  resetState: () => void;
  updateSkill: (changes: SkillStateUpdate) => void;
  updateSchedules: (changes: ScheduleStateUpdate) => void;
  updateDetails: (changes: DetailsStateUpdate) => void;
  updatePublishing: (changes: PublishingStateUpdate) => void;
  updateCompletedStep: (changes: CompletedStepUpdate) => void;
  updateLastShift: (changes: LastShiftUpdate) => void;
  updateJob: (changes: UpdateJob) => void;
  updateRates: (changes: UpdateRates) => void;
};

export const JobDraftActionsContext = createContext<JobDraftActions>({
  resetState: noOp,
  updateSkill: noOp,
  updateDetails: noOp,
  updateSchedules: noOp,
  updatePublishing: noOp,
  updateCompletedStep: noOp,
  updateLastShift: noOp,
  updateJob: noOp,
  updateRates: noOp,
});

export const useJobDraftState = () => useContext(JobDraftStateContext);
export const useJobDraftActions = () => useContext(JobDraftActionsContext);

// ===== ACTIONS =====
export enum JobDraftActionType {
  RESET_STATE = 'reset_state',
  UPDATE_SKILL = 'update_skill',
  UPDATE_SCHEDULES = 'update_schedules',
  UPDATE_DETAILS = 'update_details',
  UPDATE_PUBLISHING = 'update_publishing',
  UPDATE_COMPLETED_STEP = 'update_completed_step',
  UPDATE_LAST_SHIFT = 'update_last_shift',
  UPDATE_JOB = 'update_job',
  UPDATE_RATES = 'update_rates',
}
export type BreakTime = {
  label: string;
  value: number;
};

export const breakOptions = [
  { label: '0 Minutes', value: 0 },
  { label: '15 Minutes', value: 15 },
  { label: '30 Minutes', value: 30 },
  { label: '45 Minutes', value: 45 },
  { label: '60 Minutes', value: 60 },
];

export enum selectionOptions {
  MY_SELECTIONS = 'my_selections',
  EVERYONE = 'everyone',
}

type ResetStateAction = { type: JobDraftActionType.RESET_STATE };
type UpdateSkillAction = {
  type: JobDraftActionType.UPDATE_SKILL;
  changes: SkillStateUpdate;
};
type UpdateSchedulesAction = {
  type: JobDraftActionType.UPDATE_SCHEDULES;
  changes: ScheduleStateUpdate;
};
type UpdatejobAction = {
  type: JobDraftActionType.UPDATE_JOB;
  changes: UpdateJob;
};
type UpdateRatesAction = {
  type: JobDraftActionType.UPDATE_RATES;
  changes: UpdateRates;
};
type UpdateLastShiftAction = {
  type: JobDraftActionType.UPDATE_LAST_SHIFT;
  changes: LastShiftUpdate;
};
type UpdateDetailsAction = {
  type: JobDraftActionType.UPDATE_DETAILS;
  changes: DetailsStateUpdate;
};
type UpdatePublishingAction = {
  type: JobDraftActionType.UPDATE_PUBLISHING;
  changes: PublishingStateUpdate;
};
type UpdateCompletedStepAction = {
  type: JobDraftActionType.UPDATE_COMPLETED_STEP;
  changes: CompletedStepUpdate;
};

export type JobDraftAction =
  | ResetStateAction
  | UpdateSkillAction
  | UpdateSchedulesAction
  | UpdateLastShiftAction
  | UpdatejobAction
  | UpdateRatesAction
  | UpdateDetailsAction
  | UpdatePublishingAction
  | UpdateCompletedStepAction;

const max = (nextStep: Step, currentStep: Maybe<Step>) =>
  currentStep === null
    ? nextStep
    : nextStep > currentStep
    ? nextStep
    : currentStep;

export const stateReducer: Reducer<JobDraftState, JobDraftAction> = (
  state,
  action
) => {
  switch (action.type) {
    case JobDraftActionType.RESET_STATE: {
      return initialState;
    }

    case JobDraftActionType.UPDATE_SKILL: {
      return {
        ...state,
        ...action.changes,
        instructions: '',
        completedStep: max(Step.SKILL, state.completedStep),
      };
    }

    case JobDraftActionType.UPDATE_PUBLISHING: {
      return { ...state, ...action.changes };
    }

    case JobDraftActionType.UPDATE_SCHEDULES: {
      return { ...state, ...action.changes };
    }
    case JobDraftActionType.UPDATE_LAST_SHIFT: {
      return { ...state, ...action.changes };
    }
    case JobDraftActionType.UPDATE_JOB: {
      return { ...state, ...action.changes };
    }

    case JobDraftActionType.UPDATE_RATES: {
      return { ...state, ...action.changes };
    }

    case JobDraftActionType.UPDATE_COMPLETED_STEP: {
      return { ...state, ...action.changes };
    }

    case JobDraftActionType.UPDATE_DETAILS: {
      return {
        ...state,
        ...action.changes,
        completedStep: max(Step.DETAILS, state.completedStep),
      };
    }

    default: {
      return state;
    }
  }
};

export type ShiftInfo = {
  date: Date;
  quantity: number;
  invitedWorkers: any[];
  hiredWorkers: any[];
  startTime: string;
  endTime: string;
  mandatoryBreakTime: number;
  groupId?: number;
};

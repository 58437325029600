import {
  ArrowBack,
  ClearOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Search,
} from '@mui/icons-material';
import {
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';

import ChatWorkerList from '../ChatWorkerList';
import { InputStyle, getHeight } from '../utils';

import CreateGroupChat from './CreateGroupChat';

import { Heading, Subheading } from '@/components/Typography';
import {
  MAX_LIMIT_OF_GROUP_WORKERS,
  USER_FETCH_LIMIT,
} from '@/constants/general';
import { V3Endpoints } from '@/constants/urls';
import Button from '@/elements/Button';
import Text from '@/elements/Text';
import useDebouncedValue from '@/hooks/useDebouncedValue';
import useMediaQuery from '@/hooks/useMediaQuery';
import breakpoints from '@/styles/breakpoints';
import { IconColor } from '@/styles/colors';
import axiosClient from '@/util/axios/axiosClient';
import { ChatGroupGWUserType, MessageReceiversList } from '@/util/axios/types';

interface Props {
  handleBack: () => void;
}

const SelectGroupWorkers = ({ handleBack }: Props) => {
  const phoneOnly = useMediaQuery(breakpoints.phoneOnly);
  const [page, setPage] = useState(1);
  const [input, setInput] = useState('');
  const debInput = useDebouncedValue(input, 400);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedWorkers, setSelectedWorkers] = useState<MessageReceiversList>(
    []
  );
  const [showMore, setShowMore] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [showCreateGroup, setShowCreateGroup] = useState(false);

  useEffect(() => {
    setLoading(true);
    axiosClient
      .post(V3Endpoints.SEARCH_CHAT_USERS, {
        search: debInput,
        offset: (page - 1) * USER_FETCH_LIMIT,
        limit: USER_FETCH_LIMIT,
        type: ChatGroupGWUserType.WORKER,
      })
      .then((data) => {
        const records = data?.data?.records;
        if (records) {
          if (page > 1) {
            setData((d) => [...d, ...records]);
          } else {
            setData(records);
            document
              .getElementById('worker-list')
              ?.scroll({ top: 0, behavior: 'auto' });
          }
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [page, debInput]);

  const maxLimitReached = selectedWorkers.length === MAX_LIMIT_OF_GROUP_WORKERS;
  const manyWorkers = selectedWorkers.length > 4;
  const clipWorkers =
    manyWorkers && !showMore ? selectedWorkers.slice(0, 3) : selectedWorkers;

  const handleClick = async (info: MessageReceiversList[0]) => {
    if (selectedWorkers.find((sw) => sw.id === info.id)) {
      setSelectedWorkers((sw) => sw.filter((s) => s.id !== info.id));
    } else {
      if (maxLimitReached) {
        return;
      }
      setSelectedWorkers([info, ...selectedWorkers]);
      if (debInput) {
        setInput('');
        setLoading(true);
        setPage(1);
      }
    }
  };

  const deleteWorker = (workerId: number) => {
    setSelectedWorkers((sw) => sw.filter((s) => s.id !== workerId));
  };
  const toggleShowmore = () => setShowMore(!showMore);
  const handleBackClick = () => {
    if (showCreateGroup) {
      setShowCreateGroup(false);
    } else if (phoneOnly && showInput) {
      setInput('');
      setShowInput(false);
    } else {
      handleBack();
    }
  };
  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInput(e.target.value);
    setPage(1);
  };
  const handleNext = () => {
    setShowInput(false);
    setShowCreateGroup(true);
  };

  return (
    <>
      <Stack
        direction={'row'}
        justifyContent={
          phoneOnly && !showCreateGroup ? 'space-between' : 'flex-start'
        }
        spacing={showCreateGroup && phoneOnly ? 14 : 2}
        style={{
          padding: '16px',
        }}
      >
        <IconButton
          id="back-btn"
          sx={{
            padding: '0',
          }}
          onClick={handleBackClick}
        >
          <ArrowBack htmlColor="#262626" />
        </IconButton>
        {phoneOnly ? (
          showInput ? (
            <TextField
              autoFocus
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
              id="search-input"
              placeholder="Search or start a new chat"
              size="small"
              sx={{
                backgroundColor: '#F4F6F3',
                borderRadius: '10px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    display: 'none',
                  },
                },
                height: '38px',
              }}
              value={input}
              onChange={handleInputChange}
            />
          ) : (
            <>
              <Subheading>New Group</Subheading>
              {!showCreateGroup && (
                <Search
                  htmlColor={IconColor}
                  id="search-icon"
                  onClick={() => setShowInput(true)}
                />
              )}
            </>
          )
        ) : (
          <Heading>Add Group Members</Heading>
        )}
      </Stack>
      <Divider />
      {showCreateGroup ? (
        <CreateGroupChat
          deleteWorker={deleteWorker}
          phoneOnly={phoneOnly}
          selectedWorkers={selectedWorkers}
        />
      ) : (
        <>
          {!phoneOnly && (
            <>
              <div style={{ padding: '12px 20px' }}>
                <TextField
                  fullWidth
                  InputProps={{
                    endAdornment: !input && (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                    startAdornment: input && (
                      <InputAdornment
                        position="start"
                        sx={{ marginLeft: '-4px', cursor: 'pointer' }}
                        onClick={() => setInput('')}
                      >
                        <ArrowBack htmlColor={IconColor} />
                      </InputAdornment>
                    ),
                  }}
                  id="search-input"
                  placeholder="Search or start a new chat"
                  size="small"
                  sx={InputStyle}
                  value={input}
                  onChange={handleInputChange}
                />
              </div>
              {selectedWorkers.length === 0 && <Divider />}
            </>
          )}
          {selectedWorkers.length > 0 && (
            <div>
              <Stack
                direction={'row'}
                sx={{
                  padding: manyWorkers ? '10px 6px 10px 8px' : '10px 18px',
                }}
              >
                <Stack direction={'row'} flexWrap="wrap" gap={0.7}>
                  {clipWorkers.map((sw) => (
                    <Chip
                      key={sw.id}
                      deleteIcon={
                        <ClearOutlined
                          htmlColor={IconColor}
                          id={'delete-' + sw.id}
                        />
                      }
                      id={'chip-' + sw.id}
                      label={`${sw.first_name} ${sw.middle_name || ''} ${
                        sw.last_name || ''
                      }`}
                      size="small"
                      sx={{ maxWidth: '150px', minWidth: '120px' }}
                      onDelete={() => {
                        deleteWorker(sw.id);
                      }}
                    />
                  ))}
                  {manyWorkers && !showMore && (
                    <Chip
                      label={`+${selectedWorkers.length - 3}`}
                      size="small"
                      sx={{ width: '42px' }}
                    />
                  )}
                </Stack>
                {manyWorkers && (
                  <div>
                    {showMore ? (
                      <KeyboardArrowUp
                        id="show-more-btn"
                        style={{ cursor: 'pointer' }}
                        onClick={toggleShowmore}
                      />
                    ) : (
                      <KeyboardArrowDown
                        id="show-less-btn"
                        style={{ cursor: 'pointer' }}
                        onClick={toggleShowmore}
                      />
                    )}
                  </div>
                )}
              </Stack>
              <Divider />
            </div>
          )}
          {maxLimitReached && (
            <Text color={'danger'} css={{ padding: '2px 20px' }}>
              {`Max Limit ${MAX_LIMIT_OF_GROUP_WORKERS} Reached for adding workers`}
            </Text>
          )}
          <ChatWorkerList
            data={data}
            handleWorkerClick={handleClick}
            listHeader={
              phoneOnly && (
                <Text
                  as="p"
                  css={{ padding: '8px 20px', color: IconColor }}
                  weight={'semibold'}
                >
                  Select Participants
                </Text>
              )
            }
            listStyles={{
              overflowY: 'auto',
              overflowX: 'hidden',
              maxHeight: getHeight(selectedWorkers.length, phoneOnly),
              height: getHeight(selectedWorkers.length, phoneOnly),
            }}
            loading={loading}
            page={page}
            selectedWorkers={selectedWorkers}
            setPage={setPage}
          />
          <div style={{ padding: '6px 20px' }}>
            <Button
              css={{ width: '100%' }}
              disabled={selectedWorkers.length < 2}
              id="next-btn"
              onClick={handleNext}
            >
              Next
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default SelectGroupWorkers;

import { createSvgIcon } from '@mui/material';

const InvitationIcon = createSvgIcon(
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 31L29.5641 10.2653C31.1226 8.76671 31.9019 8.01742 32.7938 7.73533C33.5788 7.48707 34.4212 7.48707 35.2062 7.73533C36.0981 8.01742 36.8774 8.76671 38.4359 10.2653L60 31L40.7084 42.8717C38.2727 44.3707 37.0548 45.1201 35.751 45.4126C34.598 45.6712 33.402 45.6712 32.249 45.4126C30.9452 45.1201 29.7273 44.3707 27.2916 42.8717L8 31Z" fill="#E7F3E6" stroke="#45A735" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        <rect x="14" y="14" width="40" height="34" rx="5" fill="white" stroke="#45A735" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M24 23H44" stroke="#45A735" stroke-width="3" stroke-linecap="round" />
        <path d="M27 31H41" stroke="#45A735" stroke-width="3" stroke-linecap="round" />
        <path d="M8 31V49.2C8 53.6804 8 55.9206 8.87195 57.6319C9.63893 59.1372 10.8628 60.3611 12.3681 61.1281C14.0794 62 16.3196 62 20.8 62H47.2C51.6804 62 53.9206 62 55.6319 61.1281C57.1372 60.3611 58.3611 59.1372 59.1281 57.6319C60 55.9206 60 53.6804 60 49.2V31L40.4014 42.3186C38.0694 43.6654 36.9034 44.3388 35.6637 44.6024C34.5668 44.8356 33.4332 44.8356 32.3363 44.6024C31.0966 44.3388 29.9306 43.6654 27.5986 42.3186L8 31Z" fill="#E7F3E6" stroke="#45A735" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
    </svg>,
    'InvitationIcon'
);
export default InvitationIcon;

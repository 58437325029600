import { VariantProps, CSS } from '@stitches/react';

import Tab from './Tab';
import { TabDefinition } from './types';

import Form from '@/form';
import TextSelectField from '@/form/TextSelectField';
import styled from '@/styles';

const Wrapper = styled('div', {
  padding: '0 8px',
  borderBottom: '1px solid $colors$neutralLightest',
  '@phoneOnly': {
    padding: 0,
    width: '100%',
  },
});

const SelectWrapper = styled('div', {
  display: 'none',
  '@phoneOnly': {
    width: '100%',
    display: 'flex',
    padding: '0 12px',
  },
});

const TabList = styled('ul', {
  display: 'flex',
  margin: 0,
  padding: 0,
  listStyle: 'none',
  variants: {
    fit: {
      true: {
        [`& > ${Tab.Wrapper}`]: {
          flex: 1,
        },
      },
      false: {},
    },
    showTabOnMobile: {
      true: { fontWeight: 600, fontSize: 15.17, color: '#000000' },
      false: {
        '@phoneOnly': {
          display: 'none',
        },
      },
    },
  },
});

type Variants = VariantProps<typeof TabList>;

export type Props = Variants & {
  selected: number;
  tabs: TabDefinition[];
  onSelect: (tab: number) => void;
  minWidth?: string;
  wrapperStyle?: {};
  css?: CSS;
};

const Tabs = ({
  selected,
  tabs,
  onSelect,
  minWidth,
  wrapperStyle = {},
  ...variants
}: Props) => {
  const initialValues = {
    worker: 0,
  };
  return (
    <Wrapper style={wrapperStyle}>
      <TabList {...variants} fit="true" role="tablist">
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            index={index}
            isActive={selected === index}
            minWidth={tab?.minWidth || minWidth}
            showTabOnMobile={!variants.showTabOnMobile}
            tab={tab}
            onSelect={onSelect}
          />
        ))}
      </TabList>
      {!variants.showTabOnMobile && (
        <SelectWrapper>
          <div style={{ width: '100%' }}>
            <Form initialValues={initialValues} onSubmit={async () => {}}>
              <TextSelectField
                callback={({ value }) => {
                  onSelect(parseInt(value));
                }}
                defaultValue={selected.toString()}
                fieldId="worker"
                label=" "
                options={tabs.map((tab, index) => {
                  return { label: tab.label, value: index.toString() };
                })}
              />
            </Form>
          </div>
        </SelectWrapper>
      )}
    </Wrapper>
  );
};

Tabs.Wrapper = Wrapper;
Wrapper.displayName = 'stitches(Tabs.Wrapper)';

Tabs.TabList = TabList;
TabList.displayName = 'stithces(Tabs.TabList)';

Tabs.Tab = Tab;

export type { TabDefinition };

export default Tabs;

import { ReactNode } from 'react';

import Header, { Props as HeaderProps } from './Header';
import Item from './Item';

import Card from '@/components/Card';
import EmptyState from '@/components/EmptyState';
import LoadingState from '@/components/LoadingState';
import Cover from '@/elements/Cover';
import styled from '@/styles';

const ListWrapper = styled('div', {
  [`${Card.Header.Wrapper} + &`]: {
    marginTop: 8,

    // borderTop: '1px solid $colors$neutralLightest'
  },

  variants: {
    hasItems: {
      true: {
        minHeight: 'initial',
      },
      false: {
        minHeight: 128,
      },
    },
  },

  defaultVariants: {
    hasItems: false,
  },
});

const List = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const defaultEmptyState = (
  <EmptyState text="No items were found." title="No items" />
);
const defaultLoadingState = <LoadingState overlayColor="white" />;

export type Props<T> = {
  emptyState?: ReactNode;
  isLoading?: boolean;
  items: T[];
  loadingState?: ReactNode;
  resourceName?: HeaderProps['resourceName'];
  renderItem: (item: T) => ReactNode;
  totalItemCount?: HeaderProps['totalItemCount'];
};

const ResourceList = <T extends any>({
  emptyState = defaultEmptyState,
  isLoading,
  loadingState = defaultLoadingState,
  items,
  resourceName,
  renderItem,
  totalItemCount,
}: Props<T>) => {
  const hasHeader = resourceName;

  return (
    <>
      {hasHeader && (
        <Header
          itemCount={items.length}
          resourceName={resourceName}
          totalItemCount={totalItemCount}
        />
      )}
      <ListWrapper hasItems={items.length > 0}>
        {items.length > 0 ? (
          <List>
            <div>{items.map(renderItem)}</div>
          </List>
        ) : (
          emptyState
        )}
        {isLoading && <Cover>{loadingState}</Cover>}
      </ListWrapper>
    </>
  );
};

ResourceList.List = List;
List.displayName = 'stitches(ResourceList.List)';

ResourceList.Header = Header;
ResourceList.Item = Item;

export default ResourceList;

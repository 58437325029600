import { Dispatch, SetStateAction, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { ContractorPayment, ItemSelected } from '..';
import PayrollStatusBadge from '../../PayrollStatusBadge';

import Card from '@/components/Card';
import TableHeader from '@/components/TableHeader';
import Table, { TableRow, TableCell } from '@/elements/Table';
import { capitalizeText } from '@/util/text';

export const itemsContractorHeaderFields = [
  'Employee',
  'Payment Method',
  'Payroll Status',
  'Net Pay',
];

type Props = {
  items: ContractorPayment[];
  setItem: Dispatch<SetStateAction<ItemSelected>>;
};

const ContractorPaymentsCard = ({ items, setItem }: Props) => {
  const { payrollId } = useParams();

  const renderItems = useCallback(
    (item: ContractorPayment) => {
      const { id, paymentMethod, status, worker, netPay } = item;
      return (
        <TableRow
          key={id}
          clickable
          onClick={() =>
            setItem({
              showItem: true,
              item,
              type: 'contractor',
            })
          }
        >
          <TableCell>
            {capitalizeText(
              `${worker!.user.firstName} ${worker!.user.middleName ?? ''} ${
                worker!.user.lastName
              }`
            )}
          </TableCell>
          <TableCell>{capitalizeText(paymentMethod)}</TableCell>
          <TableCell>
            <PayrollStatusBadge status={status} />
          </TableCell>
          <TableCell>{netPay}</TableCell>
        </TableRow>
      );
    },
    [payrollId]
  );

  return (
    <Card title="Contractor Payments">
      <Table>
        <TableHeader fields={itemsContractorHeaderFields} />
        <tbody>{items!.map(renderItems)}</tbody>
      </Table>
    </Card>
  );
};

export default ContractorPaymentsCard;

import { ItemPayroll } from '../../PayrollDetail';
import { itemsHeaderFields } from '../../PayrollDetail/ItemsCard';
import PayrollStatusBadge from '../../PayrollStatusBadge';

import Card from '@/components/Card';
import Table from '@/components/Table';
import TableHeader from '@/components/TableHeader';
import { TableCell, TableRow } from '@/elements/Table';
import { capitalizeText } from '@/util/text';

type Props = {
  item: ItemPayroll;
};

const ItemDetailsCard = ({ item }: Props) => {
  const { worker } = item;
  return (
    <Card title="Details">
      <Table>
        <TableHeader fields={itemsHeaderFields} />
        <tbody>
          <TableRow>
            <TableCell style={{ width: '25%' }}>
              {capitalizeText(
                `${worker!.user.firstName} ${worker!.user.middleName ?? ''} ${
                  worker!.user.lastName
                }`
              )}
            </TableCell>
            <TableCell>{capitalizeText(item.paymentMethod)} </TableCell>
            <TableCell>
              <PayrollStatusBadge status={item.status} />
            </TableCell>
            <TableCell>{item.netPay !== null ? item.netPay : 'N/A'}</TableCell>
          </TableRow>
        </tbody>
      </Table>
    </Card>
  );
};

export default ItemDetailsCard;

import { CloseOutlined } from '@mui/icons-material';
import { Dialog, DialogTitle, Divider, IconButton, Stack } from '@mui/material';

import Avatar from '@/components/Avatar';
import Card from '@/components/Card';
import RatingField from '@/components/RatingField';
import { Subheading, Small } from '@/components/Typography';
import useMediaQuery from '@/hooks/useMediaQuery';
import styled from '@/styles';

type Props = {
  hideModal: () => void;
  ProfileInfo: any;
};

const Separator = styled('li', {
  width: '75%',
  height: '3px',
  borderBottom: '2px solid $colors$neutralLightest',
  listStyleType: 'none',
});

const ProfileModal = ({ hideModal, ProfileInfo }: Props) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const phoneStyles = phoneOnly
    ? { height: '350px', width: '100%', borderRadius: '15px' }
    : {};
  return (
    <Dialog
      PaperProps={{
        style: {
          height: 'auto',
          width: '350px',
          ...phoneStyles,
        },
      }}
      aria-labelledby="options-dialog"
      fullScreen={phoneOnly}
      open={true}
      sx={{
        '& .MuiDialog-container': phoneOnly
          ? { alignItems: 'end', marginBottom: '2px' }
          : {},
      }}
      onClose={hideModal}
    >
      <DialogTitle
        id="options-dialog-title"
        sx={{ m: 0, p: 2, fontWeight: '600' }}
      >
        {'Worker Profile View'}
      </DialogTitle>
      <IconButton
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 8,
          top: 12,
        }}
        onClick={hideModal}
      >
        <CloseOutlined />
      </IconButton>
      <Divider />
      <Card.Section>
        <Stack
          alignItems="center"
          direction={'column'}
          justifyContent="center"
          spacing={{ md: 3, xs: 2 }}
        >
          <Stack
            alignItems="center"
            direction={'column'}
            justifyContent="center"
            spacing={{ xs: 1 }}
          >
            <Avatar rounded size="lg" src={ProfileInfo.avatarUrl} />
            <Subheading>{`${ProfileInfo?.user?.firstName || ''} ${
              ProfileInfo?.user?.lastName || ''
            }`}</Subheading>

            <Small size="md">
              {`${ProfileInfo?.city || ''}, ${ProfileInfo?.state || ''}`}
            </Small>
            <Stack
              alignItems="center" // Assuming your RatingLabel is in a row with the RatingField
              direction="row" // Adjust the spacing as needed
              justifyContent="center"
              spacing={1}
            >
              {ProfileInfo.jobsRatingCount >= 5 && (
                <RatingField
                  initialRating={ProfileInfo.averageRating}
                  readOnly={true}
                  showNumberLabel={true}
                />
              )}
            </Stack>
          </Stack>
          <Separator role="separator" />
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            spacing={1}
          >
            <Small size="md">Shifts Worked: </Small>
            <Small color={'default'} size="md" weight={'semibold'}>
              {`${ProfileInfo.shiftsWorked}`}
            </Small>
          </Stack>
        </Stack>
      </Card.Section>
    </Dialog>
  );
};

export default ProfileModal;

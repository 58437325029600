import { FieldCallback, useField } from '@area2k/use-form';
import { useEffect } from 'react';

import { CustomerProfileSteps } from '../';
import { Account, Billing as BillingType } from '../../context';

import FormElement from '@/components/FormElement';
import ItemSelect from '@/components/ItemSelect';
import { Maybe } from '@/types/graphql';

type Props = {
  label: string;
  fieldId: string;
  customer: Maybe<BillingType['customer']>;
  profileStep: Maybe<CustomerProfileSteps>;
  accountCallback?: FieldCallback<Account | null>;
};

const AccountSelectField = ({
  label,
  fieldId,
  customer,
  profileStep,
  accountCallback,
}: Props) => {
  const { value: account, setValue: setAccount } = useField<Maybe<Account>>(
    fieldId,
    accountCallback
  );

  useEffect(() => {
    setAccount(null);
  }, [profileStep, customer]);

  return (
    <FormElement htmlFor={`${fieldId}-toggle-button`} label={label}>
      <ItemSelect
        required
        disabled={!customer || profileStep !== null}
        id={fieldId}
        itemToKey={(item) => item.id}
        itemToString={(item) => (item ? item.name : '')}
        items={customer ? customer.accounts : []}
        placeholder={
          customer && profileStep === null ? 'Select account' : undefined
        }
        selectedItem={account}
        onSelectedItemChange={({ selectedItem }) =>
          setAccount(selectedItem || null)
        }
      />
    </FormElement>
  );
};

export default AccountSelectField;

import { Scalars } from '@/types/graphql';

export const zeroPad = (n: number, targetLength: number) => {
  const nString = String(n);

  return `${'0'.repeat(Math.max(targetLength - nString.length, 0))}${nString}`;
};

export const centsToCurrency = (n: number) => {
  const total = (n / 100).toFixed(2);
  const [dollars, cents] = total.split('.');

  return `${Number(dollars).toLocaleString()}.${cents}`;
};

export const pluralizeNumber = (
  number: Scalars['Int'],
  singular: Scalars['String'],
  plural: Scalars['String']
) => {
  const isValidNumber = typeof number === 'number';

  const isValidSingularPlural =
    typeof singular === 'string' &&
    typeof plural === 'string' &&
    singular.length > 0 &&
    singular.length > 0;

  if (isValidNumber && isValidSingularPlural) {
    return `${number === 1 ? singular : plural}`;
  }

  return null;
};

export const currencyToCents = (n: string) => {
  return parseFloat(n.replace(/,/g, '')) * 100;
};

export const toFixedWithoutZeros = (
  num: Scalars['Int'],
  precision: Scalars['Int']
) => {
  const isValidType = typeof num === 'number' && typeof precision === 'number';
  const isValidValue = precision >= 0;

  if (isValidType && isValidValue) {
    return num.toFixed(precision).replace(/\.0+$/, '');
  }

  return null;
};
export const getPreciseNumber = (number: number) => Number(number.toFixed(10));

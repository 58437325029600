import styled from '@/styles';

export const AnnouncementQuoteWrapper = styled('div', {
  padding: '10px',
  minHeight: '90px',
  maxHeight: '105px',
  width: '100%',
  background: '#E7F3E6',
  marginTop: '10px',
  borderRadius: '4px',
  borderLeft: '4px solid #45A735',
});

export const ReplyMessage = styled('p', {
  marginTop: '10px',
  color: '#262626',
  fontSize: '14px',
  fontWeight: 400,
  maxHeight: '60px',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
});

export const styles = {
  sideBarStyle: {
    display: 'flex',
    flexBasis: 'auto',
    maxWidth: '100%',
  },
  conversationHeaderStyle: {
    paddingBottom: '12.5px',
    paddingTop: '12.5px',
  },
  conversationContentStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  loaderStyle: {
    width: '100%',
    marginTop: '20px',
    justifyContent: 'center',
  },
  emptyMessageContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center',
  },
  mainContainerStyle: {
    height: '100%',
    maxHeight: 'calc(100vh - 130px)',
    overflowY: 'auto',
    backgroundColor: '#f4f5f7',
    '@phoneOnly': {
      maxHeight: '100%',
      overflow: 'visible',
    },
  },
};

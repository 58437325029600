import styled from '@/styles';

const Item = styled('div', {
  $$bgColor: 'white',
  $$highlightedBgColor: '$colors$themeA12',

  $$selectedBgColor: '$colors$themeA24',
  $$selectedHighlightedBgColor: '$colors$themeA32',

  padding: '4px 16px',
  width: '100%',

  cursor: 'pointer',
  outline: 'none',

  lineHeight: 1.5,
  textDecoration: 'none',
  whiteSpace: 'nowrap',

  variants: {
    isHighlighted: {
      true: {
        backgroundColor: '$$highlightedBgColor',
      },
      false: {},
    },
    isSelected: {
      true: {
        backgroundColor: '$$selectedBgColor',
      },
      false: {},
    },
  },

  compoundVariants: [
    {
      isHighlighted: true,
      isSelected: true,
      css: {
        backgroundColor: '$$selectedHighlightedBgColor',
      },
    },
  ],

  defaultVariants: {
    isHighlighted: false,
    isSelected: false,
  },
});

Item.displayName = 'stitches(Autocomplete.Item)';

export default Item;

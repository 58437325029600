import Av from '@/components/Avatar';
import Stack from '@/components/Stack';
import styled from '@/styles';
import { theme } from '@/styles/colors';
import { Scalars } from '@/types/graphql';

const StyledAvatar = styled(Av, {
  marginLeft: -12,

  cursor: 'pointer',
  variants: {
    hovered: {
      false: {
        border: '2px solid white',
      },
      true: {
        border: '2px solid #45a735',
      },
    },
  },
});

const StyledTextElement = styled('span', {
  textAlign: 'center',
  paddingTop: 8,
  borderRadius: '100px',
  width: 36,
  height: 36,
  border: '1px solid white',
  background: '#BCDABA',
  color: '#262626',
  fontSize: '12px',
  fontWeight: '400',
  marginLeft: -12,
});

type Props = {
  items: { url?: string; name?: string }[];
  maxLimitToShow?: Scalars['Int'];
  small?: boolean;
  onImageClick?: () => void;
  hovered: boolean;
};

const MAX_AVATARS_TO_SHOWN = 3;

const AvatarShiftsWorkersGroupComponent = ({
  items,
  maxLimitToShow = MAX_AVATARS_TO_SHOWN,
  small = false,
  onImageClick,
  hovered,
}: Props) => {
  const mapItems =
    items.length > maxLimitToShow ? items.slice(-1 * maxLimitToShow) : items;
  return (
    <Stack
      css={{
        flexDirection: 'row-reverse',
        width: !small ? '100%' : 'auto',
        marginLeft: 10,
      }}
      gap={0}
      justify={'end'}
    >
      {items.length > maxLimitToShow && (
        <StyledTextElement css={{ zIndex: items.length }}>
          +{items.length - maxLimitToShow}
        </StyledTextElement>
      )}
      {mapItems.map((item, index) => (
        <StyledAvatar
          key={index}
          rounded
          css={{ zIndex: items.length - 1 - index }}
          firstName={item.name}
          hovered={hovered}
          imageStyles={
            item.url ? undefined : { backgroundColor: theme.themeBackground }
          }
          size={'sm'}
          small={small}
          src={item.url}
          onClick={onImageClick}
        />
      ))}
    </Stack>
  );
};

export default AvatarShiftsWorkersGroupComponent;

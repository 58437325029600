import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Link from '@/elements/Link';
import Text from '@/elements/Text';
import useAuth from '@/hooks/useAuth';
import ROUTES from '@/routes/routes';
import { Scalars } from '@/types/graphql';

export type Props = {
  customerId: Scalars['String'] | undefined;
  hideModal: () => void;
};

const RedirectionClientProfileAddressModal = ({
  customerId,
  hideModal,
}: Props) => {
  const { currentAdminIsCustomerAdmin } = useAuth();

  return (
    <Modal
      disableClickout
      size="sm"
      title="New address"
      onRequestClose={() => {
        hideModal();
      }}
    >
      <Card.Section>
        {customerId && (
          <Link
            to={
              currentAdminIsCustomerAdmin
                ? `/${ROUTES.myCompany}`
                : `/clients/${customerId}`
            }
          >
            <Text as="p" color="theme">
              Please add the address on your profile page
            </Text>
          </Link>
        )}
      </Card.Section>
    </Modal>
  );
};

export default RedirectionClientProfileAddressModal;

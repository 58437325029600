import { ApolloQueryResult } from '@apollo/client';
import { useCallback } from 'react';

import { AccountPaymentMethodsType } from '../..';

import Card from '@/components/Card';
import Modal from '@/components/Modal';
import PaymentMethod from '@/components/PaymentMethod';
import Stack from '@/components/Stack';
import { GAEvent } from '@/constants/gaevents';
import Form from '@/form';
import { useSetCurrentPaymentMethodMutation } from '@/graphql';
import { usePaymentMethod } from '@/hooks/usePaymentMethod';
import {
  Exact,
  GetAccountPaymentMethodsQuery,
  GetAccountQuery,
} from '@/types/graphql';
import useAnalytics from '@/util/analytics';

export type PaymentMethodType = 'ach' | 'card' | 'check';
export type PaymentMethodFormValues = {
  paymentMethodType: PaymentMethodType;
  selectedPaymentMethodId: string | null;
};

export type onRefetchType = (
  variables?: Partial<Exact<{ accountId: string }>>
) => Promise<ApolloQueryResult<GetAccountPaymentMethodsQuery>>;

type ChangePaymentModalProps = {
  account: GetAccountQuery['account'];
  hideModal: () => void;
  onRefetch: onRefetchType;
  accountPaymentMethods: AccountPaymentMethodsType;
  handleSuccess?: () => void;
  firstAccount?: boolean;
};

const ChangePaymentModal = ({
  account,
  hideModal,
  onRefetch,
  handleSuccess,
  accountPaymentMethods,
  firstAccount,
}: ChangePaymentModalProps) => {
  const { paymentMethod, resetPaymentMethodStatus } = usePaymentMethod();

  const { logEvent } = useAnalytics();

  const [setCurrentPaymentMethod, { loading }] =
    useSetCurrentPaymentMethodMutation();

  const handleSubmit = useCallback(async () => {
    const { selectedPaymentMethodId } = paymentMethod;

    if (!selectedPaymentMethodId) {
      return console.error('Please select a payment method first.');
    }

    try {
      await setCurrentPaymentMethod({
        variables: {
          account: account.id,
          paymentMethodId: selectedPaymentMethodId,
        },
      });
      if (firstAccount && !accountPaymentMethods?.currentPaymentMethod) {
        logEvent(GAEvent.SetPaymentMethod, account.id);
      }
      await onRefetch();
      handleSuccess?.();
      resetPaymentMethodStatus();
      hideModal();
    } catch (error) {
      console.error(error);
    }
  }, [paymentMethod]);

  return (
    <Modal
      disableClickout
      size="sm"
      title="Change Payment Method"
      onRequestClose={() => {
        resetPaymentMethodStatus();
        hideModal();
      }}
    >
      <Card.Section>
        <Form onSubmit={handleSubmit}>
          <Stack vertical>
            <PaymentMethod
              account={account}
              accountPaymentMethods={accountPaymentMethods}
              isLoading={loading}
              onRefetch={onRefetch}
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  );
};

export default ChangePaymentModal;

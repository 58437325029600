import useModal from '@area2k/use-modal';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';

import SuccessfulAlertModal from '../shared/SuccessfulAlertModal';

import IconicButton from '@/components/IconicButton';
import Stack from '@/components/Stack';
import { TableCell } from '@/elements/Table';
import { useListActiveUsersLazyQuery } from '@/graphql';

function ActiveUserList({ setError }) {
  const [showSuccessfulAlertModal, hideSuccessfulAlertModal] = useModal(
    () => <SuccessfulAlertModal hideModal={hideSuccessfulAlertModal} />,
    []
  );
  const [getActiveUsers, { loading }] = useListActiveUsersLazyQuery({
    onCompleted: showSuccessfulAlertModal,
    onError: (error) => setError({ message: error.message }),
  });

  return (
    <>
      <TableCell>Active User List</TableCell>
      <TableCell />
      <TableCell />
      <TableCell>
        <Stack justify="end">
          <IconicButton
            a11yLabel="download"
            appearance="clear"
            icon={faFileExport}
            id="download-user-list"
            isLoading={loading}
            onClick={() => getActiveUsers()}
          />
        </Stack>
      </TableCell>
    </>
  );
}

export default ActiveUserList;

import { VariantProps, CSS } from '@stitches/react';
import { ComponentProps, forwardRef, ReactNode } from 'react';

import OptionElement from '@/elements/Option';
import styled from '@/styles';

const Wrapper = styled('div', {
  alignItems: 'center',
  display: 'inline-flex',

  position: 'relative',

  '& > input': {
    height: 1,
    margin: 0,
    pointerEvents: 'none',
    width: 1,

    opacity: 0,

    left: 48,
    position: 'absolute',
    top: 24,
  },
});

type OptionVariants = VariantProps<typeof OptionElement>;

export type Props = ComponentProps<'input'> &
  OptionVariants & {
    id: string;
    label: string | ReactNode;
    type?: 'checkbox' | 'radio';
    css?: CSS;
  };

const Option = forwardRef<HTMLInputElement, Props>(
  (
    { appearance, id, label, noMargin, type = 'checkbox', ...props }: Props,
    ref,
  ) => (
    <Wrapper style={props?.style}>
      <input {...props} ref={ref} id={id} type={type} />
      <OptionElement appearance={appearance} htmlFor={id} noMargin={noMargin}>
        {label}
      </OptionElement>
    </Wrapper>
  ),
);

Option.displayName = 'Option';

export default Option;

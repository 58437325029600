import { UseSelectStateChange } from 'downshift';
import { Dispatch, SetStateAction } from 'react';

import FormElement from '../FormElement';
import ItemSelect from '../ItemSelect';

import { BILLING_WEEK_HINT } from '@/constants/text';
import { BillingWeekEnum, GetAccountQuery, Scalars } from '@/types/graphql';

interface BillingWeekOption {
  label: Scalars['String'];
  value: BillingWeekEnum;
}

export const BILLING_WEEK_OPTIONS: BillingWeekOption[] = [
  { label: 'Sunday - Saturday', value: BillingWeekEnum.SUNDAY },
  { label: 'Monday - Sunday', value: BillingWeekEnum.MONDAY },
  { label: 'Tuesday - Monday', value: BillingWeekEnum.TUESDAY },
  { label: 'Wednesday - Tuesday', value: BillingWeekEnum.WEDNESDAY },
  { label: 'Thursday - Wednesday', value: BillingWeekEnum.THURSDAY },
  { label: 'Friday - Thursday', value: BillingWeekEnum.FRIDAY },
  { label: 'Saturday - Friday', value: BillingWeekEnum.SATURDAY },
];

interface BillingWeekFormFieldProps {
  billingWeek: GetAccountQuery['account']['billingWeek'];
  onChangeBillingWeek: Dispatch<
    SetStateAction<GetAccountQuery['account']['billingWeek']>
  >;
}

const BillingWeekFormField = ({
  billingWeek,
  onChangeBillingWeek,
}: BillingWeekFormFieldProps) => {
  const selectedBillingWeek =
    BILLING_WEEK_OPTIONS.find((option) => option.value === billingWeek) || null;
  const handleOnBillingWeekChange = ({
    selectedItem,
  }: UseSelectStateChange<BillingWeekOption>) => {
    if (selectedItem) {
      onChangeBillingWeek(selectedItem.value!);
    }
  };

  return (
    <FormElement
      hint={BILLING_WEEK_HINT}
      htmlFor="billing-week"
      label="Billing Week"
    >
      <ItemSelect<BillingWeekOption>
        required
        id="billing-week"
        itemToKey={(item) => item.value}
        itemToString={(item) => item?.label || ''}
        items={BILLING_WEEK_OPTIONS}
        placeholder="Select Billing Week"
        selectedItem={selectedBillingWeek}
        onSelectedItemChange={handleOnBillingWeekChange}
      />
    </FormElement>
  );
};

export default BillingWeekFormField;

import { useReactiveVar } from '@apollo/client';
import { useCallback, useState, useMemo } from 'react';

import { mapUrl } from '../../Customer/AddressList';

import Action from '@/components/Action';
import AddressAutocomplete from '@/components/AddressAutocomplete';
import Autocomplete from '@/components/Autocomplete';
import Button from '@/components/Button';
import Card from '@/components/Card';
import FormElement from '@/components/FormElement';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import TagList from '@/components/TagList';
import TextStack from '@/components/TextStack';
import { Small } from '@/components/Typography';
import Form from '@/form';
import SingleOptionGroupField from '@/form/SingleOptionGroupField';
import {
  useListAgencyCertificateQuery,
  useListAgencySkillsQuery,
} from '@/graphql';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import useDebouncedValue from '@/hooks/useDebouncedValue';
import {
  selectWorkerCompleteFilters,
  setWorkerCompleteTableFilters,
  TaxTypeEnumVoid,
} from '@/store/slices/filtersSlice';
import { SkillItem } from '@/types';
import {
  CompareOperatorEnum,
  ListAgencyCertificateQuery,
  WorkerFilterSetInput,
} from '@/types/graphql';
import { currentAgencyVar } from '@/util/apollo/cache';
import { sortBy } from '@/util/array';

type Props = {
  hideModal: () => void;
  onChangeFilter: <K extends keyof WorkerFilterSetInput>(
    key: K,
    value: NonNullable<WorkerFilterSetInput[K]>
  ) => void;
  onClearFilter: (key: keyof WorkerFilterSetInput) => void;
  onClearAll: () => void;
  filters: Record<string, any>;
  updatePageNumber: (pageno: number) => void;
};
type CertItem = ListAgencyCertificateQuery['agency']['certificates'][0];

const taxTypeOptions = [
  { label: 'Both', value: TaxTypeEnumVoid.BOTH },
  { label: 'W2', value: TaxTypeEnumVoid.TAX_W2 },
  { label: '1099', value: TaxTypeEnumVoid.TAX_1099 },
];

const WorkerFiltersModal = ({
  hideModal,
  onChangeFilter,
  onClearFilter,
  updatePageNumber,
}: Props) => {
  const initialAddressValues = {
    addressLine1: null,
    addressLine2: null,
    city: null,
    name: '',
    state: null,
    zip: null,
    coords: { latitude: 0, longitude: 0 },
  };
  const { address, skillArray, taxType, certArray } = useAppSelector((state) =>
    selectWorkerCompleteFilters(state)
  );
  const [addressValues, setAddressValues] = useState(
    address ? { ...address } : { ...initialAddressValues }
  );

  const [taxOption, setTaxOption] = useState<TaxTypeEnumVoid>(taxType);
  const [skills, setSkills] = useState<SkillItem[]>(skillArray || []);
  const [certificates, setCertificates] = useState<CertItem[]>(certArray || []);
  const [isfilterUpdated, setIsFilterUpdated] = useState(false);

  const [query, setQuery] = useState('');
  const debouncedQuery = useDebouncedValue(query);

  const [certQuery, setCertQuery] = useState('');
  const debouncedCertQuery = useDebouncedValue(certQuery);

  const currentAgency = useReactiveVar(currentAgencyVar);
  const { data } = useListAgencySkillsQuery({
    variables: { agencyId: currentAgency!.id },
  });
  const { data: certificateData } = useListAgencyCertificateQuery({
    variables: { agencyId: currentAgency!.id },
  });

  const dispatch = useAppDispatch();

  const sortedWorkerSkills = useMemo(() => {
    return sortBy(skills, 'name');
  }, [skills]);

  const sortedCertificates = useMemo(() => {
    return sortBy(certificates, 'name');
  }, [certificates]);

  const handleRemove = (index: number, type = 'skill') => {
    if (type === 'certificate') {
      const trueIndex = certificates.findIndex(
        (skill) => skill.id === sortedCertificates[index].id
      );
      const certCopy = [...certificates];
      certCopy.splice(trueIndex, 1);
      setCertificates([...certCopy]);
    } else {
      const trueIndex = skills.findIndex(
        (skill) => skill.id === sortedWorkerSkills[index].id
      );
      const skillsCopy = [...skills];
      skillsCopy.splice(trueIndex, 1);
      setSkills([...skillsCopy]);
    }
    setIsFilterUpdated(true);
  };

  const handleSelect = useCallback(
    (item) => {
      setSkills([...skills, item.selectedItem]);
      setIsFilterUpdated(true);
    },
    [skills]
  );
  const handleSelectCertificate = useCallback(
    (item) => {
      setCertificates([...certificates, item.selectedItem]);
      setIsFilterUpdated(true);
    },
    [certificates]
  );

  const items: SkillItem[] = useMemo(() => {
    if (data) {
      const availableSkills = data.agency.skillCategories
        .flatMap((category) => category.skills)
        .filter((skill) => {
          if (skills.some((workerSkill) => workerSkill.id === skill.id)) {
            return false;
          }

          return skill.name
            .toLowerCase()
            .includes(debouncedQuery.toLowerCase());
        });

      return sortBy(availableSkills, 'name');
    }

    return [];
  }, [skills, data, debouncedQuery]);

  const certItems: CertItem[] = useMemo(() => {
    if (certificateData) {
      const filterCertificate = certificateData.agency.certificates.filter(
        (cert) => {
          if (certificates.some((workerSkill) => workerSkill.id === cert.id)) {
            return false;
          }

          return cert.name
            .toLowerCase()
            .includes(debouncedCertQuery.toLowerCase());
        }
      );

      return sortBy(filterCertificate, 'name');
    }
    return [];
  }, [certificates, certificateData, debouncedCertQuery]);

  const handleSubmit = useCallback(() => {
    try {
      switch (taxOption) {
        case TaxTypeEnumVoid.BOTH:
          onClearFilter('taxType');
          break;
        case TaxTypeEnumVoid.TAX_W2:
        case TaxTypeEnumVoid.TAX_1099:
          onChangeFilter('taxType', { value: taxOption });
          break;
        default:
          break;
      }
      if (
        addressValues.coords.latitude !== 0 &&
        addressValues.coords.longitude !== 0
      )
        onChangeFilter('coords', addressValues.coords);
      else onClearFilter('coords');

      if (skills.length > 0) {
        const skillsParsed = skills.map((skill) => {
          return { value: parseInt(skill.id), op: CompareOperatorEnum.EQ };
        });
        onChangeFilter('workerSkills', skillsParsed);
      } else {
        onClearFilter('workerSkills');
      }

      if (certificates.length > 0) {
        const certParsed = certificates.map((cert) => {
          return { value: parseInt(cert.id), op: CompareOperatorEnum.EQ };
        });
        onChangeFilter('workerCertificates', certParsed);
      } else {
        onClearFilter('workerCertificates');
      }
      if (isfilterUpdated) {
        updatePageNumber(1);
      }

      dispatch(
        setWorkerCompleteTableFilters({
          address: addressValues,
          taxType: taxOption,
          skillArray: skills,
          certArray: certificates,
        })
      );

      hideModal();
    } catch (error) {
      console.error(error);
    }
  }, [addressValues, taxOption, skills, certificates, isfilterUpdated]);

  return (
    <Modal
      disableClickout
      size="sm"
      title="GravyWorker Filters"
      onRequestClose={hideModal}
    >
      <Card.Section title="">
        <Form initialValues={{ taxType }}>
          <SingleOptionGroupField
            callback={(fieldContext) => {
              setTaxOption(fieldContext.value as TaxTypeEnumVoid);
              setIsFilterUpdated(true);
            }}
            fieldId="taxType"
            label="Tax options"
            options={taxTypeOptions}
          />
        </Form>
        <FormElement htmlFor="" label="Location">
          {addressValues.addressLine1 ? (
            <Stack justify="apart">
              <TextStack>
                <Action.Button
                  action={{
                    a11yLabel: `Open new tab to ${mapUrl(addressValues)}`,
                    label: addressValues.addressLine1!,
                    href: mapUrl(addressValues),
                    external: true,
                  }}
                />
                <Small>
                  {addressValues.city}, {addressValues.state}{' '}
                  {addressValues.zip}
                </Small>
              </TextStack>
              <Button
                a11yLabel="Clear order status filter"
                appearance="plain"
                label="Clear"
                onClick={() => {
                  setAddressValues({ ...initialAddressValues });
                }}
              />
            </Stack>
          ) : (
            <AddressAutocomplete
              setValues={setAddressValues}
              values={addressValues}
              onReset={() => {
                setAddressValues(
                  address ? { ...address } : { ...initialAddressValues }
                );
              }}
            />
          )}
        </FormElement>
        <FormElement label="Skills">
          {sortedWorkerSkills.length > 0 && (
            <div style={{ marginBottom: 8 }}>
              <TagList
                tags={sortedWorkerSkills.map((skill) => skill.name)}
                onRemove={handleRemove}
              />
            </div>
          )}
          <Autocomplete
            fixedSelectHeight="lg"
            id="skillFather"
            itemToKey={(item) => item.id}
            itemToString={(item) => (item ? item.name : '')}
            items={items}
            placeholder="Search for skills..."
            selectedItem={null}
            onInputValueChange={({ inputValue }) => setQuery(inputValue || '')}
            onSelectedItemChange={handleSelect}
          />
        </FormElement>
        <FormElement label="Credentials">
          {sortedCertificates.length > 0 && (
            <div style={{ marginBottom: 8 }}>
              <TagList
                showIcon={true}
                style={{ backgroundColor: '#e0eaff' }}
                tags={sortedCertificates.map((cert) => cert.name)}
                onRemove={(index) => handleRemove(index, 'certificate')}
              />
            </div>
          )}
          <Autocomplete
            fixedSelectHeight="lg"
            id="certificate"
            itemToKey={(item) => item.id}
            itemToString={(item) => (item ? item.name : '')}
            items={certItems}
            placeholder="Search for Credentials..."
            selectedItem={null}
            onInputValueChange={({ inputValue }) =>
              setCertQuery(inputValue || '')
            }
            onSelectedItemChange={handleSelectCertificate}
          />
        </FormElement>
      </Card.Section>
      <Card.Section>
        <Stack justify="end">
          <Button
            a11yLabel="Submit"
            isLoading={false}
            label="Save"
            type="button"
            onClick={() => {
              handleSubmit();
            }}
          />
        </Stack>
      </Card.Section>
    </Modal>
  );
};

export default WorkerFiltersModal;

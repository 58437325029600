import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuList, Snackbar } from '@mui/material';
import { AxiosRequestConfig } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';

import { MenuItemsObject } from '../../Job/WorkerTable/workerUtils';
import CustomerStatusBadge from '../CustomerStatusBadge';
import OnboradingStatusBadge from '../OnboradingStatusBadge';

import Avatar from '@/components/Avatar';
import LoadingState from '@/components/LoadingState';
import Stack from '@/components/Stack';
import TextStack from '@/components/TextStack';
import { Body, Small } from '@/components/Typography';
import { V3Endpoints } from '@/constants/urls';
import Link from '@/elements/Link';
import { TableRow, TableCell } from '@/elements/Table';
import useAxios from '@/hooks/useAxios';
import useMediaQuery from '@/hooks/useMediaQuery';
import { ListCustomersQuery } from '@/types/graphql';
import { CustomerStatusEnum } from '@/util/accountstatus';
import { formatDateSimple } from '@/util/datetime';

export type CustomerItem =
  ListCustomersQuery['agency']['customers']['items'][0];

interface CustomerItemProps {
  item: CustomerItem;
  index: number;
  refreshQuery: () => void;
}

export enum CustomerStatusOperations {
  APPROVE = 'approve',
  REJECT = 'reject',
  UNREJECT = 'unreject',
  DISABLE = 'disable',
  ENABLE = 'enable',
}

const CustomerRowItem: React.FC<CustomerItemProps> = (props) => {
  const { item, refreshQuery } = props;
  const [customerItem, updateCustomerItem] = useState<CustomerItem>(item);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const open = Boolean(anchorEl);
  const phoneOnly = useMediaQuery('(max-width: 559px)');

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const operationRequestConfig: AxiosRequestConfig = useMemo(() => {
    return {
      method: 'PATCH',
      url: V3Endpoints.UPDATE_CLIENT_STATUS,
    };
  }, []);

  const { callApi, data, error, loading } = useAxios(
    operationRequestConfig,
    false
  );

  const handleClientStatusAction = (action) => {
    const payload = {
      id: customerItem.id,
      status: String(action).toLocaleLowerCase(),
    };
    handleCloseMenu();
    callApi({ ...operationRequestConfig, data: payload });
  };

  useEffect(() => {
    if (error) {
      if (error?.data) {
        setErrorMsg(error?.data?.message);
      }
    } else if (data) {
      if (data.data?.data?.id === customerItem.id) {
        const updatedCustomer = {
          ...customerItem,
          status: String(data.data?.data?.status).toUpperCase(),
        };
        updateCustomerItem({ ...updatedCustomer });
        refreshQuery?.();
      }
    }
  }, [data, error]);

  const mappedStatusProps = useMemo(
    () => ({
      [CustomerStatusEnum.APPROVED]: [
        {
          ...MenuItemsObject.disable(() =>
            handleClientStatusAction(CustomerStatusOperations.DISABLE)
          ),
          key: String(customerItem?.id + CustomerStatusOperations.DISABLE),
        },
      ],
      [CustomerStatusEnum.DISABLED]: [
        {
          ...MenuItemsObject.enable(() =>
            handleClientStatusAction(CustomerStatusOperations.ENABLE)
          ),
          key: String(customerItem?.id + CustomerStatusOperations.ENABLE),
        },
      ],
      [CustomerStatusEnum.PENDING]: [
        {
          ...MenuItemsObject.approve(() =>
            handleClientStatusAction(CustomerStatusOperations.APPROVE)
          ),
          key: String(customerItem?.id + CustomerStatusOperations.APPROVE),
        },
        {
          ...MenuItemsObject.reject(() =>
            handleClientStatusAction(CustomerStatusOperations.REJECT)
          ),
          key: String(customerItem?.id + CustomerStatusOperations.REJECT),
        },
      ],
      [CustomerStatusEnum.REJECTED]: [
        {
          ...MenuItemsObject.unReject(() =>
            handleClientStatusAction(CustomerStatusOperations.UNREJECT)
          ),
          key: String(customerItem?.id + CustomerStatusOperations.UNREJECT),
        },
      ],
    }),
    [customerItem, data, item]
  );

  const MENU_ITEMS = useMemo(
    () => mappedStatusProps[customerItem.status],
    [customerItem, data, item]
  );

  const formattedStates = useMemo(() => {
    if (Array.isArray(item?.accounts) && item?.accounts.length > 0) {
      return item.accounts.map((ac) => ac.state).join(', ');
    } else {
      return '-';
    }
  }, [customerItem, data, item]);
  const isDeleted = customerItem.status === CustomerStatusEnum.DELETED;

  return (
    <TableRow key={customerItem?.id} disabled={isDeleted}>
      {!phoneOnly ? (
        <>
          <TableCell width={'xs'}>
            <Stack gap={20}>
              <Stack vertical>
                <Stack gap={5}>
                  <Avatar
                    firstName={customerItem.name}
                    size="md"
                    src={customerItem.logoUrl}
                  />
                  <TextStack spacing="tight">
                    <Link to={!isDeleted ? `${customerItem.id}` : ''}>
                      <Body weight="medium">{customerItem.name}</Body>
                    </Link>
                  </TextStack>
                </Stack>
                <OnboradingStatusBadge status={customerItem.onboardingStatus} />
              </Stack>
            </Stack>
          </TableCell>
          <TableCell width={'xs'}>
            <Body>{formatDateSimple(new Date(customerItem.createdAt))}</Body>
          </TableCell>
          <TableCell>
            <Body>{formattedStates}</Body>
          </TableCell>
          <TableCell width={'xs'}>
            {loading ? (
              <LoadingState />
            ) : (
              <CustomerStatusBadge status={customerItem.status} />
            )}
          </TableCell>
          <TableCell css={{ width: '80px' }}>
            {!isDeleted && (
              <IconButton
                aria-label="menu-icon"
                disabled={!MENU_ITEMS && loading}
                id="menu-icon"
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <MoreVert />
              </IconButton>
            )}

            <Menu
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              PaperProps={{
                sx: {
                  border: '1px solid #D3D3D3',
                  filter:
                    'drop-shadow(0px 4.050473213195801px 4.050473213195801px rgba(0, 0, 0, 0.05))',
                  boxShadow: 'none',
                },
              }}
              anchorEl={anchorEl}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              id="basic-menu"
              open={open}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              onClose={handleCloseMenu}
            >
              <MenuList
                dense={true}
                sx={{ paddingTop: '0px', paddingBottom: '0px' }}
              >
                {MENU_ITEMS}
              </MenuList>
            </Menu>
          </TableCell>
        </>
      ) : (
        <>
          <TableCell>
            <Stack vertical>
              <Stack gap={10}>
                <Avatar
                  firstName={customerItem.name}
                  size="md"
                  src={customerItem.logoUrl}
                />
                <TextStack spacing="tight">
                  <Link to={!isDeleted ? `${customerItem.id}` : ''}>
                    <Body weight="medium">{customerItem.name}</Body>
                  </Link>
                  <Body>
                    <Small size={'md'}>States:</Small> {formattedStates}
                  </Body>
                  <Body>
                    <Small size={'md'}>Date:</Small>{' '}
                    {formatDateSimple(new Date(customerItem.createdAt))}
                  </Body>
                </TextStack>
              </Stack>
              <OnboradingStatusBadge status={customerItem.onboardingStatus} />
            </Stack>
          </TableCell>
          <TableCell valign="top" width={'xs'}>
            {loading ? (
              <LoadingState />
            ) : (
              <CustomerStatusBadge status={customerItem.status} />
            )}
          </TableCell>
          <TableCell
            align={'right'}
            css={{ padding: '6px 8px 0 0 !important' }}
            valign="top"
          >
            {!isDeleted && (
              <IconButton
                aria-label="menu-icon"
                disabled={!MENU_ITEMS && loading}
                id="menu-icon"
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <MoreVert />
              </IconButton>
            )}

            <Menu
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              PaperProps={{
                sx: {
                  border: '1px solid #D3D3D3',
                  filter:
                    'drop-shadow(0px 4.050473213195801px 4.050473213195801px rgba(0, 0, 0, 0.05))',
                  boxShadow: 'none',
                },
              }}
              anchorEl={anchorEl}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              id="basic-menu"
              open={open}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              onClose={handleCloseMenu}
            >
              <MenuList
                dense={true}
                sx={{ paddingTop: '0px', paddingBottom: '0px' }}
              >
                {MENU_ITEMS}
              </MenuList>
            </Menu>
          </TableCell>
        </>
      )}

      <Snackbar
        ContentProps={{
          sx: {
            background: '#c23636',
            color: '#FFDFD8',
          },
        }}
        autoHideDuration={2000}
        id="error-msg"
        message={errorMsg}
        open={!!errorMsg}
        onClose={() => setErrorMsg(null)}
      />
    </TableRow>
  );
};

export default CustomerRowItem;

import { Schedule } from '../../../context';

import Card from '@/components/Card';
import Stack from '@/components/Stack';
import { Body, Small, Subheading } from '@/components/Typography';
import useAuth from '@/hooks/useAuth';
import { centsToCurrency } from '@/util/number';

type Props = {
  schedule: Schedule;
  totalHours?: string;
};

const JobRates = ({ schedule, totalHours }: Props) => {
  const { currentAdminIsCustomerAdmin } = useAuth();
  const rate = schedule.rate || 1;
  const payRate = schedule.isHoliday
    ? schedule.payRate / rate
    : schedule.payRate;
  const costRate = schedule.isHoliday
    ? schedule.costRate / rate
    : schedule.costRate;
  return (
    <Card.Section>
      <Subheading>Job Rates</Subheading>
      <Stack vertical gap={12} style={{ marginTop: 12 }}>
        {!currentAdminIsCustomerAdmin && (
          <Stack justify="apart">
            <Small>Base Pay rate</Small>
            <Body>${centsToCurrency(payRate)}</Body>
          </Stack>
        )}
        <Stack justify="apart">
          <Small>Base Bill rate</Small>
          <Body>${centsToCurrency(costRate)}</Body>
        </Stack>
        <Stack justify="apart">
          <Small>Total Hours</Small>
          <Body>{totalHours}</Body>
        </Stack>
      </Stack>
    </Card.Section>
  );
};

export default JobRates;

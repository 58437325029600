import { ApolloError } from '@apollo/client';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { GraphQLError } from 'graphql';
import { useState } from 'react';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import Form from '@/form';
import FormColumns from '@/form/FormColumns';
import MaskedInputField from '@/form/MaskedInputField';
import TextField from '@/form/TextField';
import { useInviteAdminMutation } from '@/graphql';
import { GetCustomerQuery } from '@/types/graphql';

export type Props = {
  customer: GetCustomerQuery['customer'];
  hideModal: () => void;
};

type FormValues = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

type ErrorTypes = {
  active: boolean;
  data: GraphQLError | ApolloError | undefined;
};

const InviteAdminModal = ({ customer, hideModal }: Props) => {
  const [error, setError] = useState<ErrorTypes>({
    active: false,
    data: undefined,
  });

  const initialValues: FormValues = {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
  };

  const [inviteAdmin, { loading: isLoading }] = useInviteAdminMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(customer),
        fields: {
          admins() {},
        },
      });
    },
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      await inviteAdmin({
        variables: {
          customerId: customer.id,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          password: null,
        },
      });
      hideModal();
    } catch (err) {
      if (err instanceof GraphQLError || err instanceof ApolloError) {
        setError({ active: true, data: err });
        return;
      }

      setError({ active: true, data: undefined });
    }
  };

  return (
    <Modal
      disableClickout
      size="sm"
      title="Invite a new administrator"
      onRequestClose={hideModal}
    >
      <Card.Section>
        {error.active && (
          <Alert
            description={error.data?.message}
            icon={faExclamationTriangle}
            status="warning"
            title="Something went wrong"
          />
        )}
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <FormColumns>
            <TextField
              autoFocus
              required
              fieldId="firstName"
              label="First Name"
              placeholder="First Name"
            />
            <TextField
              required
              fieldId="lastName"
              label="Last Name"
              placeholder="Last Name"
            />
          </FormColumns>
          <TextField
            required
            autoComplete="email"
            fieldId="email"
            label="Email Address"
            placeholder="Email Address"
            type="email"
          />
          <MaskedInputField
            fieldId="phoneNumber"
            incompletemessage="Must be a valid phone number"
            label="Phone Number"
            mask="(000) 000-0000"
            placeholder="(555) 555-5555"
            type="tel"
          />
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              id="send-invite-btn"
              isLoading={isLoading}
              label="Send Invite"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  );
};

export default InviteAdminModal;

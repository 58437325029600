import useModal from '@area2k/use-modal';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import {
  Download,
  MoreVert,
  SubdirectoryArrowRight,
} from '@mui/icons-material';
import { IconButton, MenuItem, Popover } from '@mui/material';
import { format, isValid, parse } from 'date-fns';
import { useState } from 'react';

import ConfirmDeleteItem from './ConfirmDeleteItem';
import InvoiceItemForm from './InvoiceItemForm';
import TotalsRow from './TotalsRow';
import { getHeaderFields } from './helper';

import EmptyState from '@/components/EmptyState';
import Stack from '@/components/Stack';
import Table from '@/components/Table';
import TableHeader from '@/components/TableHeader';
import TextStack from '@/components/TextStack';
import { Heading } from '@/components/Typography';
import Button from '@/elements/Button';
import Link from '@/elements/Link';
import { TableCell, TableRow } from '@/elements/Table';
import Text from '@/elements/Text';
import { useGetTimesheetPdfMutation } from '@/graphql';
import ROUTES from '@/routes/routes';
import styled from '@/styles';
import { IconColor } from '@/styles/colors';
import type { InvoiceItem } from '@/types/graphql';
import { StripeInvoice } from '@/types/graphql';
import { NOT_AVAILABLE_TEXT } from '@/util/constants';
import { formatApproveTime, getRateDesc } from '@/util/invoice';
import { centsToCurrency } from '@/util/number';

type Props = {
  items: InvoiceItem[];
  invoice: StripeInvoice;
  editable: boolean;
  isClientAdmin: boolean;
};

const CompactTableCell = styled(TableCell, {
  paddingTop: '0px',
  paddingBottom: '8px',
});

const ItemsCard = ({ items, invoice, editable, isClientAdmin }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedItem, setSelectedItem] = useState<InvoiceItem>();

  const [getTimesheetPdfDetails] = useGetTimesheetPdfMutation({
    onCompleted: (data) => {
      const url = data.invoiceTimesheetsPdf.pdfCreated.url;
      window.open(url, '_blank', 'noopener,noreferrer');
    },
  });

  const [showForm, hideForm] = useModal<InvoiceItem>((invoiceItem) => (
    <InvoiceItemForm
      hideModal={hideForm}
      invoice={invoice}
      invoiceItem={
        Object.keys(invoiceItem).length > 0 ? invoiceItem : undefined
      }
    />
  ));

  const [showConfirmDeleteModal, hideConfirmDeleteModal] =
    useModal<InvoiceItem>(
      (invoiceItem) => (
        <ConfirmDeleteItem
          hideModal={hideConfirmDeleteModal}
          invoiceId={invoice.id}
          invoiceItemId={invoiceItem.id!}
        />
      ),
      [items],
    );

  const headerFields = getHeaderFields(editable);

  const orderIdParserLink = (orderText: string | null) => {
    if (!orderText) return NOT_AVAILABLE_TEXT;
    if (orderText.includes('#')) {
      const [, orderId] = orderText.split('#');
      return <Link to={`../../${ROUTES.orders}/${orderId}`}>{orderId}</Link>;
    }
    return orderText;
  };

  const nextRowOverTime = (index: number) => {
    const nextRow = items[index + 1];
    return !!(
      nextRow &&
      nextRow.isOvertime &&
      nextRow.timesheetId === items[index].timesheetId
    );
  };

  const isPartialOvertimeRow = (index: number) => {
    const prevRow = items[index - 1];
    return !!(prevRow && prevRow.timesheetId === items[index].timesheetId);
  };

  const renderOverTimeRow = (item: InvoiceItem, index: number) => (
    <TableRow
      key={`overtime-row-${index}`}
      css={{ backgroundColor: 'white !important' }}
      id={`overtime-row-${index}`}
    >
      <CompactTableCell />
      <CompactTableCell />
      <CompactTableCell>
        <SubdirectoryArrowRight
          htmlColor={IconColor}
          id="overtime-icon"
          style={{ float: 'right' }}
        />
      </CompactTableCell>
      <CompactTableCell>
        {item.jobId && item.netTime ? `${item.netTime}` : ''}
      </CompactTableCell>
      <CompactTableCell />
      <CompactTableCell />
      <CompactTableCell>
        <TextStack>
          {item.billRate ? `$${centsToCurrency(item.billRate)}` : ''}
          <Text color={'lighter'}>Overtime</Text>
        </TextStack>
      </CompactTableCell>
      <CompactTableCell />
      <CompactTableCell>
        ${centsToCurrency(Number(item.amount))}
      </CompactTableCell>
    </TableRow>
  );

  const renderTableRow = (
    item: InvoiceItem,
    index: number,
    isNextRowOverTime: boolean,
  ) => {
    const {
      order,
      date,
      address,
      amount,
      worker,
      jobId,
      billRate,
      tip = 0,
      cancelled,
      shortDescription = '',
      workerTimesheet,
      netTime,
      skill,
    } = item;
    const orderId = order?.split('#')?.[1] || '';
    const description = cancelled ? 'CANCELLED JOB FEE' : shortDescription;

    const parsedDate = parse(date || '', 'MM/dd/yy', new Date());
    const weekDay = isValid(parsedDate) ? format(parsedDate, 'E') : '';
    const rateDesc = getRateDesc(item, true);

    const jobDetails = jobId ? (
      <TextStack>
        <Text color={'lighter'}>Order: {orderIdParserLink(order)}</Text>
        <Text color={'lighter'}>
          Job:{' '}
          <Link to={`../../${ROUTES.orders}/${orderId}/jobs/${jobId}`}>
            {jobId}
          </Link>
        </Text>
      </TextStack>
    ) : (
      NOT_AVAILABLE_TEXT
    );

    const workerDetails = worker ? (
      <TextStack>
        {isClientAdmin ? (
          <Text>{`${worker.user?.firstName} ${worker.user?.lastName}`}</Text>
        ) : (
          <Link to={`../../${ROUTES.workers}/${worker.id}`}>
            {`${worker.user?.firstName} ${worker.user?.lastName}`}
          </Link>
        )}
        <Text color={'lighter'}>{skill?.name}</Text>
        <Text>{description}</Text>
      </TextStack>
    ) : (
      description
    );

    const timesheetDetails =
      date && workerTimesheet ? (
        <TextStack>
          <Text>{`${formatApproveTime(
            workerTimesheet.approvedCheckinAt,
            workerTimesheet?.job?.address?.timezone,
          )} - ${formatApproveTime(
            workerTimesheet.approvedCheckoutAt,
            workerTimesheet?.job?.address?.timezone,
          )}`}</Text>
          <Text color={'lighter'}>
            {date ? `${weekDay} ${date}` : NOT_AVAILABLE_TEXT}
          </Text>
          <Text
            color={'lighter'}
          >{`${workerTimesheet.approvedBreakMinutes} min break`}</Text>
        </TextStack>
      ) : (
        NOT_AVAILABLE_TEXT
      );

    const updatedByDetails = workerTimesheet?.updatedBy
      ? `${workerTimesheet.updatedBy.firstName} ${workerTimesheet.updatedBy.lastName}`
      : workerTimesheet?.updatedByType === 'GravyWorkSystem'
        ? 'System Approved'
        : NOT_AVAILABLE_TEXT;

    const billRateDetails = billRate
      ? `$${centsToCurrency(billRate)}`
      : NOT_AVAILABLE_TEXT;

    return (
      <TableRow
        key={`item-card-${index}`}
        css={{
          backgroundColor: 'white !important',
          boxShadow: isNextRowOverTime ? 'none' : '',
        }}
        id={`item-row-${index}`}
      >
        <TableCell>{jobDetails}</TableCell>
        <TableCell>{workerDetails}</TableCell>
        <TableCell>{timesheetDetails}</TableCell>
        <TableCell>{jobId && netTime ? netTime : NOT_AVAILABLE_TEXT}</TableCell>
        <TableCell>{address || NOT_AVAILABLE_TEXT}</TableCell>
        <TableCell>{updatedByDetails}</TableCell>
        <TableCell>
          <TextStack>
            {billRateDetails}
            {rateDesc && <Text color={'lighter'}>{rateDesc}</Text>}
          </TextStack>
        </TableCell>
        <TableCell>{jobId ? `$${tip}` : NOT_AVAILABLE_TEXT}</TableCell>
        <TableCell>{`$${centsToCurrency(Number(amount))}`}</TableCell>
        {editable && (
          <TableCell>
            <IconButton
              aria-label="menu-icon"
              id="menu-icon"
              onClick={(e) => {
                setSelectedItem(item);
                setAnchorEl(e.currentTarget);
              }}
            >
              <MoreVert />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
    );
  };

  const renderTableContent = () => {
    if (items.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={headerFields.length}>
            <EmptyState
              icon={faFileAlt}
              text="No invoice items were found"
              title="No Invoices"
            />
          </TableCell>
        </TableRow>
      );
    }

    return items.map((item, index) => {
      const isNextRowOverTime = nextRowOverTime(index);
      const overTimeRow = item.isOvertime && isPartialOvertimeRow(index);

      return overTimeRow
        ? renderOverTimeRow(item, index)
        : renderTableRow(item, index, isNextRowOverTime);
    });
  };

  return (
    <>
      <Stack
        css={{ marginTop: '18px', marginBottom: '10px' }}
        justify={'apart'}
      >
        <Heading>Invoice Items</Heading>
        <div>
          <Button
            appearance={'plain'}
            css={
              {
                top: '3.5px',
                fontWeight: '500',
                left: editable ? '-30px' : '',
              } as any
            }
            id="download-timesheet-btn"
            onClick={() =>
              getTimesheetPdfDetails({ variables: { invoiceId: invoice.id } })
            }
          >
            <Download sx={{ width: '15px', height: '17.5px' }} />
            Download Timesheet
          </Button>
          {editable && (
            <Button
              appearance={'plain'}
              id="add-item"
              style={{ marginLeft: '10px', fontWeight: '500' } as any}
              onClick={() => showForm()}
            >
              Add Item
            </Button>
          )}
        </div>
      </Stack>
      <div style={{ margin: '0px -22px -22px -22px' }}>
        <Table>
          <TableHeader
            fields={headerFields}
            headerBgColor="#E0DFDF"
            headerColor="black"
          />
          <tbody id="inv-items-table">
            {renderTableContent()}
            {items.length > 0 && (
              <TotalsRow
                editable={editable}
                invoice={invoice}
                invoiceItems={items}
              />
            )}
          </tbody>
        </Table>
        <Popover
          PaperProps={{
            sx: {
              border: '1px solid #D3D3D3',
              filter:
                'drop-shadow(0px 4.050473213195801px 4.050473213195801px rgba(0, 0, 0, 0.05))',
              boxShadow: 'none',
            },
          }}
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          id={`popover`}
          open={!!anchorEl}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem
            dense={true}
            onClick={() => {
              showForm(selectedItem);
              setAnchorEl(null);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            dense={true}
            onClick={() => {
              showConfirmDeleteModal(selectedItem);
              setAnchorEl(null);
            }}
          >
            Delete
          </MenuItem>
        </Popover>
      </div>
    </>
  );
};

export default ItemsCard;

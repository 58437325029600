import { Stack } from '@mui/material';

import { PaymentSuccessIcon } from '@/assets/icons';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import { Heading, Body } from '@/components/Typography';

type Props = { invId: string; hideModal: () => void };
const SuccessPaymentModal = ({ invId, hideModal }: Props) => {
  return (
    <Modal
      disableClickout
      size="xs"
      title="Payment Successful"
      wrapperBackground={true}
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Stack alignItems={'center'} gap={1}>
          <PaymentSuccessIcon sx={{ width: '70px', height: '70px' }} />
          <Heading>Thank you for your payment!</Heading>
          <Body>{`You have successfully paid to invoice #${invId}`}</Body>
        </Stack>
      </Card.Section>
    </Modal>
  );
};

export default SuccessPaymentModal;

import { CloseOutlined } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuList,
} from '@mui/material';
import * as React from 'react';

interface Props {
  title: string;
  hideModal: () => void;
  menuItems: React.ReactNode[];
}
const HeightMap = {
  0: '0%',
  1: '25vh',
  2: '25vh',
  3: '31vh',
  4: '36vh',
};

const OptionsModal = ({ title, hideModal, menuItems }: Props) => {
  return (
    <Dialog
      PaperProps={{
        style: {
          height: HeightMap[menuItems.length],
          borderRadius: '15px',
        },
      }}
      aria-labelledby="options-dialog"
      fullScreen={true}
      open={true}
      sx={{
        '& .MuiDialog-container': { alignItems: 'end', marginBottom: '2px' },
      }}
      onClose={hideModal}
    >
      <DialogTitle id="options-dialog-title" sx={{ m: 0, p: 2 }}>
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
        onClick={hideModal}
      >
        <CloseOutlined />
      </IconButton>
      <DialogContent dividers style={{ padding: '6px 0px' }}>
        <div
          onClick={() =>
            setTimeout(() => {
              hideModal();
            }, 250)
          }
        >
          <MenuList
            sx={{ paddingTop: '0px', paddingBottom: '0px', overflowY: 'auto' }}
          >
            {menuItems}
          </MenuList>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default OptionsModal;

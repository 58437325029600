import axios from 'axios';

import ls, { ACCESS_TOKEN_KEY } from '../localstorage';

import { BASE_URL_V3 } from '@/constants/urls';

const axiosClient = axios.create({
  baseURL: BASE_URL_V3,
});

axiosClient.interceptors.request.use(async (config) => {
  const token = ls.get(ACCESS_TOKEN_KEY);

  if (token && config.headers) {
    config.headers.Authorization = `bearer ${token}`;
  }

  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error?.config;
    if (error?.response?.status === 401 && !originalRequest?._retry) {
      originalRequest._retry = true;
      // logic for refresh token
      // const access_token = await refreshAccessToken();
      // originalRequest.headers.Authorization = 'bearer ' + newToken;
      // return axiosClient(originalRequest);
    } else if (error?.response?.status === 401) {
      // Logout logic
    }
    return Promise.reject(error);
  }
);

export default axiosClient;

import { differenceInCalendarDays, parseISO } from 'date-fns';

import { JobStatus } from '../Job/util';

import { PAST_DAYS } from '@/constants/general';
import { TaxTypeEnum } from '@/types/graphql';
import { TAX_TYPE_LABELS } from '@/util/constants';

export const getTaxTypeLabel = (taxType: TaxTypeEnum | null) =>
  taxType?.includes(TaxTypeEnum.TAX_W2) ? TAX_TYPE_LABELS.TAX_W2 : undefined;

export const disableCheckbox = (
  jobStatus: JobStatus,
  jobDate: string,
  job: any
) => {
  if (jobStatus === JobStatus.CANCELLED) return true;
  const diff = differenceInCalendarDays(parseISO(jobDate), new Date());
  const invalidPastJob = diff < PAST_DAYS * -1;
  const fullyStaffed = job ? job.hiredWorkersCount === job.quantity : false;

  return invalidPastJob || fullyStaffed;
};

export const isJobCompOrCancel = (jobStatus: JobStatus) =>
  jobStatus === JobStatus.COMPLETED || jobStatus === JobStatus.CANCELLED;

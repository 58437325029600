import { useReactiveVar } from '@apollo/client';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Divider } from '@mui/material';
import { useState } from 'react';
import {
  Controller,
  DefaultValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import POBoxFormFields from './POBoxFormFields';
import { getSchemaByContext } from './schema';

import AddressAutocomplete from '@/components/AddressAutocomplete';
import Alert from '@/components/Alert';
import BillingWeekFormField from '@/components/BillingWeekFormField';
import Button from '@/components/Button';
import FormElement from '@/components/FormElement';
import GroupInvoicesFormField from '@/components/GroupInvoicesFormField';
import InvoiceDurationFormField from '@/components/InvoiceDurationFormField';
import ItemSelect from '@/components/ItemSelect';
import Option from '@/components/Option';
import Stack from '@/components/Stack';
import TextInput from '@/components/TextInput';
import TooltipInfo from '@/components/TooltipInfo';
import { Small } from '@/components/Typography';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import {
  CONSOLIDATE_CHARGES,
  SEPARATE_INVOICE_BY_MONTH,
  SEPARATE_INVOICE_HINT,
  SWEEP_HINT,
} from '@/constants/text';
import useToggle from '@/hooks/useToggle';
import { Role } from '@/routes/PrivateRoute';
import styled from '@/styles';
import {
  AccountFragmentFragment,
  BillingWeekEnum,
  CustomerItemFragment,
  InvoiceCutOffEnum,
  InvoiceGroupByEnum,
  AccountRateTypeEnum,
} from '@/types/graphql';
import { currentAdminVar } from '@/util/apollo/cache';
import {
  LOW_MARKUP_WARNING_DESCRIPTION,
  DEFAULT_MARKUP_PRICE_VALUE,
  MARKUP_PERCENTAGE_FIELD_ERROR,
} from '@/util/constants';

const LabelText = styled('p', {
  fontSize: '13px',
  fontWeight: 600,
  color: '#332F2D',
  marginBottom: '5px',
});

type AdminItem = CustomerItemFragment['admins']['items'][0];
export type AccountFormValues = {
  accountName: string;
  defaultContact: CustomerItemFragment['admins']['items'][0];
  address: {
    addressLine1: string;
    addressLine2?: string;
    city: string;
    state: string;
    zip: string;
  };
  invoiceDuration: InvoiceCutOffEnum;
  billingWeek: BillingWeekEnum;
  crossMonthInvoice: boolean;
  invoiceSweepRule: boolean;
  groupBy: InvoiceGroupByEnum;
  markupPercent: string;
};

interface Props {
  customer: CustomerItemFragment;
  selectedAccount?: AccountFragmentFragment;
  onSubmit: SubmitHandler<AccountFormValues>;
  isLoading: boolean;
  newAccount: boolean;
  rateType?: string;
}

const AccountForm = ({
  customer,
  selectedAccount,
  onSubmit,
  isLoading,
  newAccount,
  rateType,
}: Props) => {
  const defaultValues: DefaultValues<AccountFormValues> = {
    accountName: selectedAccount?.name,
    defaultContact: selectedAccount?.defaultContact,
    address: {
      addressLine1: selectedAccount?.addressLine1 ?? undefined,
      addressLine2: selectedAccount?.addressLine2 ?? '',
      city: selectedAccount?.city ?? undefined,
      state: selectedAccount?.state ?? undefined,
      zip: selectedAccount?.zip ?? undefined,
    },
    invoiceDuration: InvoiceCutOffEnum.WEEKLY,
    billingWeek: BillingWeekEnum.MONDAY,
    crossMonthInvoice: false,
    invoiceSweepRule: true,
    groupBy: InvoiceGroupByEnum.NONE,
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    control,
    setValue,
  } = useForm<AccountFormValues>({
    resolver: yupResolver(getSchemaByContext(selectedAccount ? 'EDIT' : 'ADD')),
    mode: 'onChange',
    defaultValues,
  });
  const [isPOBox, togglePOBox] = useToggle();
  const showMarkUpPriceValue =
    (newAccount && customer?.markupPercent) ||
    (!newAccount && selectedAccount?.markupPercent);
  const [markupPercentage, setMarkupPercentage] = useState<string>(
    `${showMarkUpPriceValue}%`
  );
  const [showMarkupLowValueError, setShowMarkupLowValueError] =
    useState<boolean>(false);

  const showMarkupBasedPricing = useFeatureValue(
    FEATURE_TOGGLE.MarkupBasedPricingFeature,
    false
  );

  const currentAdmin = useReactiveVar(currentAdminVar);
  const isTenantAdmin = currentAdmin?.role === Role.TENANT_ADMIN;
  const isDurationWeekly =
    control._formValues.invoiceDuration === InvoiceCutOffEnum.WEEKLY;

  const handleOnClickPOBoxOption = () => {
    togglePOBox();
    setValue('address.addressLine2', undefined);
  };

  const handleMarkUpValueChange = (inputMarkupValue: string) => {
    if (!/^\d*%?$/.test(inputMarkupValue)) {
      // check only digits and only one %
      return;
    }
    const markUpValueFormatted = inputMarkupValue?.replace('%', '');
    Number(markUpValueFormatted) < DEFAULT_MARKUP_PRICE_VALUE
      ? setShowMarkupLowValueError(true)
      : setShowMarkupLowValueError(false);

    setMarkupPercentage(`${markUpValueFormatted}%`);
  };

  const showMarkUpPriceField =
    (customer?.markupPercent &&
      customer?.rateType === AccountRateTypeEnum.MARKUP) ||
    (selectedAccount?.markupPercent &&
      selectedAccount?.rateType === AccountRateTypeEnum.MARKUP);

  const showMarkUpPriceFieldError =
    Number(markupPercentage?.replace('%', '')) < 1;

  const formSubmitDisabledCheck =
    showMarkupBasedPricing && rateType === AccountRateTypeEnum.MARKUP
      ? !isValid || showMarkUpPriceFieldError
      : !isValid;

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      {!selectedAccount && (
        <FormElement htmlFor="accountName" label="Account Name">
          <TextInput
            autoFocus
            id="accountName"
            placeholder="Account Name"
            {...register('accountName')}
          />
          {errors.accountName && (
            <Small color="danger" role="alert">
              {errors.accountName.message}
            </Small>
          )}
        </FormElement>
      )}
      <FormElement htmlFor="defaultContact" label="Default Contact">
        <Controller
          control={control}
          name="defaultContact"
          render={({ field: { onChange } }) => (
            <ItemSelect<AdminItem>
              disabled={customer.admins.items.length === 0}
              id="defaultContact"
              itemToKey={(item) => item.id}
              itemToString={(item) =>
                typeof item !== 'string'
                  ? `${item?.user.firstName} ${item?.user.lastName}`
                  : ''
              }
              items={customer.admins.items.filter((item) => item.active)}
              placeholder={
                customer.admins.items.length === 0
                  ? 'No contacts available, please create a new Administrator'
                  : 'Select default contact...'
              }
              selectedItem={getValues('defaultContact')}
              onSelectedItemChange={({ selectedItem }) =>
                onChange(selectedItem)
              }
            />
          )}
        />
        {errors.defaultContact && (
          <Small color="danger" role="alert">
            {errors.defaultContact?.message}
          </Small>
        )}
      </FormElement>
      <FormElement>
        <Option
          appearance="checkbox"
          id="isPOBox"
          label="Is PO Box?"
          onClick={handleOnClickPOBoxOption}
        />
      </FormElement>
      {isPOBox ? (
        <POBoxFormFields {...{ register, errors, control, setValue }} />
      ) : (
        <>
          <FormElement htmlFor="address" label="Address">
            <Controller
              control={control}
              name="address"
              render={({ field: { value, onChange } }) => (
                <AddressAutocomplete
                  setValues={onChange}
                  values={value}
                  onReset={(values) => onChange(values)}
                >
                  {errors.address && (
                    <Small color="danger" role="alert">
                      Address is a required field
                    </Small>
                  )}
                </AddressAutocomplete>
              )}
            />
          </FormElement>
          <FormElement htmlFor="addressLine2" label="Address Line 2">
            <Controller
              control={control}
              name="address.addressLine2"
              render={({ field }) => (
                <TextInput
                  id="addressLine2"
                  placeholder="Type address..."
                  {...field}
                />
              )}
            />
          </FormElement>
        </>
      )}

      {showMarkupBasedPricing && rateType === AccountRateTypeEnum.MARKUP && (
        <>
          {showMarkUpPriceField && (
            <>
              <Divider />
              <LabelText style={{ marginTop: '15px', marginBottom: '10px' }}>
                Markup
              </LabelText>
              <FormElement
                hint={
                  'To further inquire about the markup percentage connect with GravyWork team.'
                }
                htmlFor="markupPercent"
                label={isTenantAdmin ? 'Set Markup' : 'Markup'}
              >
                {isTenantAdmin && (
                  <>
                    <TextInput
                      id="markupPercent"
                      {...register('markupPercent')}
                      value={markupPercentage}
                      onChange={(e) => {
                        handleMarkUpValueChange(e.target.value);
                      }}
                    />
                    {showMarkUpPriceFieldError && (
                      <Small color="danger" role="alert">
                        {MARKUP_PERCENTAGE_FIELD_ERROR}
                      </Small>
                    )}
                    {showMarkupLowValueError && (
                      <Alert
                        css={{ marginBottom: '10px', marginTop: '16px' }}
                        description={LOW_MARKUP_WARNING_DESCRIPTION}
                        icon={faExclamationTriangle}
                        status="warning"
                        title="Low Markup"
                      />
                    )}
                  </>
                )}
                {!isTenantAdmin && (
                  <>
                    <LabelText
                      style={{ marginTop: '10px', marginBottom: '5px' }}
                    >
                      {`${showMarkUpPriceValue}%`}{' '}
                    </LabelText>
                  </>
                )}
              </FormElement>
              <Divider sx={{ marginBottom: '20px' }} />
            </>
          )}
        </>
      )}

      {newAccount && (
        <>
          <Controller
            control={control}
            name="invoiceDuration"
            render={({ field: { value, onChange } }) => {
              return (
                <InvoiceDurationFormField
                  invoiceDuration={value}
                  onChangeInvoiceDuration={(val) => {
                    onChange(val);
                    if (val !== InvoiceCutOffEnum.WEEKLY) {
                      // set default values
                      setValue('invoiceSweepRule', true);
                      setValue('groupBy', InvoiceGroupByEnum.NONE);
                      setValue('billingWeek', BillingWeekEnum.MONDAY);
                    }
                  }}
                />
              );
            }}
          />
          {isTenantAdmin && isDurationWeekly && (
            <Controller
              control={control}
              name="billingWeek"
              render={({ field: { value, onChange } }) => {
                return (
                  <BillingWeekFormField
                    billingWeek={value}
                    onChangeBillingWeek={onChange}
                  />
                );
              }}
            />
          )}
          {isTenantAdmin && isDurationWeekly && (
            <Controller
              control={control}
              name="groupBy"
              render={({ field: { value, onChange } }) => {
                return (
                  <GroupInvoicesFormField
                    groupBy={value}
                    onChangeGroupBy={onChange}
                  />
                );
              }}
            />
          )}
          <Stack vertical gap={10}>
            {isTenantAdmin && isDurationWeekly && (
              <Controller
                control={control}
                name="invoiceSweepRule"
                render={({ field: { value, onChange } }) => {
                  return (
                    <Option
                      appearance="checkbox"
                      checked={value}
                      id="invoice-sweep-rule"
                      label={
                        <>
                          <span style={{ marginRight: '10px' }}>
                            {CONSOLIDATE_CHARGES}
                          </span>
                          <TooltipInfo text={SWEEP_HINT} />
                        </>
                      }
                      onChange={onChange}
                    />
                  );
                }}
              />
            )}
            <Controller
              control={control}
              name="crossMonthInvoice"
              render={({ field: { value, onChange } }) => {
                return (
                  <Option
                    appearance="checkbox"
                    checked={value}
                    id="invoice-cross-month"
                    label={
                      <>
                        <span style={{ marginRight: '10px' }}>
                          {SEPARATE_INVOICE_BY_MONTH}
                        </span>
                        <TooltipInfo text={SEPARATE_INVOICE_HINT} />
                      </>
                    }
                    onChange={onChange}
                  />
                );
              }}
            />
          </Stack>
        </>
      )}
      <Stack justify="end" style={{ marginTop: '10px' }}>
        <Button
          a11yLabel="Submit form"
          disabled={formSubmitDisabledCheck}
          id="submit-btn"
          isLoading={isLoading}
          label={selectedAccount ? 'Update' : 'Create'}
          style={{ width: '100%' }}
          type="submit"
        />
      </Stack>
    </form>
  );
};

export default AccountForm;

import { useReactiveVar } from '@apollo/client';
import { FieldCallback } from '@area2k/use-form';
import { useEffect, useState } from 'react';

import { DEBOUNCE_TIME } from '@/constants/general';
import AutocompleteField from '@/form/AutocompleteField';
import { useCustomerListingLazyQuery } from '@/graphql';
import useDebouncedValue from '@/hooks/useDebouncedValue';
import { CustomerListingQuery } from '@/types/graphql';
import { CustomerStatusEnum } from '@/util/accountstatus';
import { currentAgencyVar } from '@/util/apollo/cache';

export type CustomerItem =
  CustomerListingQuery['agency']['customers']['items'][0];

export type Props = {
  fieldId: string;
  callback?: FieldCallback<CustomerItem | null>;
};

const CustomerAutocompleteField = ({ fieldId, callback }: Props) => {
  const [query, setQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const debouncedQuery = useDebouncedValue(query, DEBOUNCE_TIME);

  const currentAgency = useReactiveVar(currentAgencyVar);
  const [fetch, { data }] = useCustomerListingLazyQuery();

  useEffect(() => {
    fetch({
      variables: {
        agencyId: currentAgency!.id,
        query: debouncedQuery,
        customerStatus: CustomerStatusEnum.APPROVED,
      },
    });
  }, [debouncedQuery]);

  useEffect(() => {
    if (data && data.agency.customers.items.length) {
      setFilteredData(
        data.agency.customers.items.filter((x) => x.accounts.length > 0)
      );
    } else {
      setFilteredData([]);
    }
  }, [data]);

  return (
    <AutocompleteField<CustomerItem>
      required
      callback={callback}
      fieldId={fieldId}
      fixedSelectHeight="lg"
      itemToKey={(item) => item.id}
      itemToString={(item) => (item ? item.name : '')}
      items={filteredData}
      label="Client*"
      placeholder="Search for clients..."
      onInputValueChange={({ inputValue }) => setQuery(inputValue || '')}
    />
  );
};

export default CustomerAutocompleteField;

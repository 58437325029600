import { ComponentProps } from 'react';

import Badge from '@/components/Badge';
import { StripeInvoiceStatusEnum } from '@/types/graphql';

interface Props {
  status: StripeInvoiceStatusEnum;
  id?: string;
}

const getBadgePropsByInvoiceStatus = (
  status: StripeInvoiceStatusEnum
): ComponentProps<typeof Badge> =>
  ({
    [StripeInvoiceStatusEnum.DRAFT]: { label: 'Draft', status: 'neutral' },
    [StripeInvoiceStatusEnum.OPEN]: { label: 'Open', status: 'success' },
    [StripeInvoiceStatusEnum.PAID]: { label: 'Paid', status: 'theme' },
    [StripeInvoiceStatusEnum.PAST_DUE]: { label: 'Past Due', status: 'danger' },
    [StripeInvoiceStatusEnum.VOID]: { label: 'Void', status: 'danger' },
    [StripeInvoiceStatusEnum.OUTSTANDING]: {
      label: 'Processing',
      status: 'warning',
    },
  }[status]);

const InvoiceBadge = ({ status, id }: Props) => (
  <Badge {...getBadgePropsByInvoiceStatus(status)} id={id} />
);

export default InvoiceBadge;

import { FC } from 'react';

import Stack from '@/components/Stack';
import styled from '@/styles';

const Wrapper = styled('div', {
  display: 'flex',
  height: 80,
  bottom: 0,
  left: 0,
  position: 'fixed',
  right: 0,
  backgroundColor: 'white',
  boxShadow: '0 -4px 8px $colors$neutralA12',
  zIndex: 1001,
  padding: '0 32px',
  '@phoneOnly': {
    padding: '0 22px',
  },
});

Wrapper.displayName = 'stitches(JobEditor.Steps.BottomBar.Wrapper)';

const BottomBar: FC = ({ children }) => {
  return (
    <Wrapper>
      <Stack gap={16} justify="center">
        {children}
      </Stack>
    </Wrapper>
  );
};

export default BottomBar;

import { UseSelectStateChange } from 'downshift';
import { Dispatch, SetStateAction } from 'react';

import FormElement from '../FormElement';
import ItemSelect from '../ItemSelect';

import { INVOICE_DURATION_HINT } from '@/constants/text';
import { GetAccountQuery, InvoiceCutOffEnum, Scalars } from '@/types/graphql';

interface InvoiceDurationOption {
  label: Scalars['String'];
  value: InvoiceCutOffEnum;
}

interface InvoiceDurationFormFieldProps {
  invoiceDuration: GetAccountQuery['account']['cutOff'];
  onChangeInvoiceDuration: Dispatch<
    SetStateAction<GetAccountQuery['account']['cutOff']>
  >;
}

const INVOICE_DURATION_OPTIONS = [
  { label: 'Weekly', value: InvoiceCutOffEnum.WEEKLY },
  { label: 'Daily', value: InvoiceCutOffEnum.DAILY },
  { label: 'By Job', value: InvoiceCutOffEnum.JOB },
];

const InvoiceDurationFormField = ({
  invoiceDuration,
  onChangeInvoiceDuration,
}: InvoiceDurationFormFieldProps) => {
  const selectedItem =
    INVOICE_DURATION_OPTIONS.find(
      (option) => option.value === invoiceDuration
    ) || null;
  const handleOnSelectedItemChange = ({
    selectedItem,
  }: UseSelectStateChange<InvoiceDurationOption>) => {
    onChangeInvoiceDuration(selectedItem?.value || null);
  };

  return (
    <FormElement
      hint={INVOICE_DURATION_HINT}
      htmlFor="invoice-duration"
      label="Invoice Cycle"
    >
      <ItemSelect<InvoiceDurationOption>
        required
        id="invoice-duration"
        itemToKey={(item) => item.value}
        itemToString={(item) => item?.label || ''}
        items={INVOICE_DURATION_OPTIONS}
        placeholder="Select Duration"
        selectedItem={selectedItem}
        onSelectedItemChange={handleOnSelectedItemChange}
      />
    </FormElement>
  );
};

export default InvoiceDurationFormField;

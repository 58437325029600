import { createSvgIcon } from '@mui/material';

const HalfClock = createSvgIcon(
  <svg
    fill="none"
    height="14"
    viewBox="0 0 14 14"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.66621 0.351562V0.364896V1.69823C10.5929 2.05823 12.6662 4.71823 12.3062 7.6449C11.9995 10.0716 10.0929 11.9982 7.66621 12.2849V13.6182C11.3329 13.2516 13.9995 9.99823 13.6329 6.33156C13.3329 3.1649 10.8195 0.664896 7.66621 0.351562ZM6.33288 0.371562C5.03288 0.498229 3.79288 0.998229 2.77954 1.83823L3.73288 2.8249C4.47954 2.2249 5.37954 1.83823 6.33288 1.7049V0.371562ZM1.83954 2.77823C0.999545 3.79156 0.499544 5.0249 0.366211 6.33156H1.69954C1.82621 5.3849 2.19954 4.4849 2.79288 3.73156L1.83954 2.77823ZM0.372878 7.6649C0.506211 8.97156 1.01954 10.2049 1.84621 11.2182L2.79288 10.2649C2.20621 9.51156 1.82621 8.61156 1.70621 7.6649H0.372878ZM3.73288 11.2449L2.77954 12.1582C3.78621 12.9982 5.02621 13.5249 6.33288 13.6649V12.3316C5.38621 12.2116 4.48621 11.8316 3.73288 11.2449ZM7.33288 3.6649V7.1649L10.3329 8.9449L9.83288 9.7649L6.33288 7.6649V3.6649H7.33288Z"
      fill="#262626"
    />
  </svg>,
  'HalfClock'
);
export default HalfClock;

import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { CheckBoxOutlined } from '@mui/icons-material';
import { Checkbox } from '@mui/material';

import { RenderWorkerInfo } from './StaffingUtils';

import Button from '@/components/Button';
import { TableRow, TableCell } from '@/elements/Table';
import { theme } from '@/styles/colors';
import { JobWorkerItemFragment } from '@/types/graphql';

type StaffingWorkerProfileRowProps = {
  worker: JobWorkerItemFragment['worker'];
  isClientAdmin: boolean;
  tabId: number;
  selected: boolean;
  setSelectedworkers: (any) => void;
  handleCancel: (workerId: string) => void;
  phoneOnly: boolean;
};

const ShiftsWorkersModalRow = ({
  worker,
  isClientAdmin,
  tabId,
  selected,
  setSelectedworkers,
  handleCancel,
  phoneOnly,
}: StaffingWorkerProfileRowProps) => {
  const renderButton = () => (
    <Button
      a11yLabel={tabId === 0 ? 'cancel hire button' : 'cancel invite button'}
      appearance="secondary"
      disabled={selected}
      iconLeft={faMinus}
      id={tabId === 0 ? 'hire-btn' : 'invite-btn'}
      label={tabId === 0 ? 'Cancel Hire' : 'Cancel Invite'}
      style={
        selected
          ? {
              background: '#EBEBEB',
              color: '#7A7A7A',
              width: phoneOnly ? '100%' : '90%',
            }
          : {
              background: '#EBEBEB',
              color: '#262626',
              width: phoneOnly ? '100%' : '90%',
            }
      }
      type="button"
      onClick={() => handleCancel(worker.id)}
    />
  );
  return (
    <>
      <TableRow key={worker.id}>
        {!phoneOnly && (
          <TableCell
            css={{ paddingRight: '0px', paddingLeft: '1px !important' }}
          >
            <Checkbox
              checked={selected}
              checkedIcon={<CheckBoxOutlined htmlColor={theme.themeDefault} />}
              size="small"
              style={{ padding: 0 }}
              onChange={() => {
                setSelectedworkers((workers) => {
                  if (selected) {
                    return workers.filter((w) => w.id !== worker.id);
                  }
                  return [...workers, worker];
                });
              }}
            />
          </TableCell>
        )}

        <TableCell>
          <RenderWorkerInfo isClientAdmin={isClientAdmin} workerInfo={worker} />
          {phoneOnly && (
            <div style={{ width: '100%', marginTop: '15px' }}>
              {renderButton()}
            </div>
          )}
        </TableCell>
        {!phoneOnly && (
          <TableCell
            align={'right'}
            css={{ paddingRight: '4px !important', width: '165px' }}
          >
            {renderButton()}
          </TableCell>
        )}
      </TableRow>
    </>
  );
};

export default ShiftsWorkersModalRow;

import Card from '@/components/Card';
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout';
import Skeleton from '@/components/Skeleton';
import Stack from '@/components/Stack';

const PageSkeleton = () => {
  return (
    <Skeleton.Page media>
      <PrimarySecondaryLayout>
        <PrimarySecondaryLayout.Primary>
          <Stack vertical gap={24}>
            <Card sectioned title="Jobs">
              <Skeleton.Body />
            </Card>
          </Stack>
        </PrimarySecondaryLayout.Primary>
        <PrimarySecondaryLayout.Secondary>
          <Stack vertical gap={24}>
            <Card title="Profile">
              <Card.Section>
                <Skeleton.Body />
              </Card.Section>
              <Card.Section title="Skills">
                <Skeleton.Body />
              </Card.Section>
              <Card.Section title="Address">
                <Skeleton.Body />
              </Card.Section>
            </Card>
            <Card sectioned title="Comments">
              <Skeleton.CommentsList />
            </Card>
          </Stack>
        </PrimarySecondaryLayout.Secondary>
      </PrimarySecondaryLayout>
    </Skeleton.Page>
  );
};

export default PageSkeleton;

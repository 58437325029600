// ---- V3 API URLS ---
import Config from '@/config';

export const BASE_URL_V3 = Config.BASEURL_V3 || '';

export const privacyPolicyUrl = 'https://gravywork.com/privacy-policy';

export const V3Endpoints = {
  GET_USER: '/v1/users/me',
  GET_HEALTH: '/v1/health',
  REQUEST_APP_ACCESS: '/v1/users/request-access/client-app',
  GRANT_APP_ACCESS: '/v1/users/grant-access/client-app',
  REVOKE_APP_ACCESS: '/v1/users/revoke-access/client-app',
  UPDATE_CLIENT_STATUS: '/v1/customers/status/update',
  GET_CHATGROUP_LIST: '/v1/chatgroup/list',
  GET_CHATGROUP_BY_ID: '/v1/chatgroup/',
  VERIFY_ANNOUNCEMENT: '/v1/announcement/verify',
  GET_CHATGROUP_LIST_USERS: '/v1/chatgroup/list/users/',
  SEARCH_CHAT_USERS: '/v1/chatgroup/search',
  CREATE_CHAT_GROUP: '/v1/chatgroup/create',
  GET_CUSTOMERS_COUNT: '/v1/customers/count',
  GET_CHATGROUP_IDS: '/v1/chatgroup/list/ids',
  CREATE_ANNOUNCEMENT: '/v1/announcement',
  CREATE_BULK_CHAT: '/v1/chatgroup/bulk/create',
  CREATE_GROUP_CHAT: 'v1/chatgroup/create/group',
  GET_INVOICE_LINK: '/v1/invoices/',
  DELETE_CUSTOMER_ACCOUNT: '/v1/customers/account/close/',
  GET_WORKERS_COUNT: '/v1/jobworker/worker-count',
  GET_WORKERS_LIST: '/v1/jobworker/valid-workers',
  GET_ACCOUNT_SKILLS: '/v1/customers/account/{account_id}/skills',
  GET_CUSTOMER_ADDRESSES: '/v1/customers/addresses/',
  CHECK_APP_VERSION: '/v1/versions/check/',
};

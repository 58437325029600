import useModal from '@area2k/use-modal';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useMemo, useState } from 'react';

import RemoveCertificateModalConfirm from '../RemoveCertConfirmModal';

import Alert from '@/components/Alert';
import Autocomplete from '@/components/Autocomplete';
import Button from '@/components/Button';
import Card from '@/components/Card';
import FormElement from '@/components/FormElement';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import TagList from '@/components/TagList';
import { UNKNOWN_ERROR_TEXT } from '@/constants/text';
import {
  useAddWorkerCertificationMutation,
  useListAgencyCertificateQuery,
  useRemoveWorkerCertificateMutation,
} from '@/graphql';
import useAuth from '@/hooks/useAuth';
import useDebouncedValue from '@/hooks/useDebouncedValue';
import { Maybe } from '@/types';
import { GetWorkerQuery, Scalars } from '@/types/graphql';
import { sortBy } from '@/util/array';

type Item = GetWorkerQuery['worker']['activeCertificates'][0];

type Props = {
  hideModal: () => Scalars['Void'];
  worker: GetWorkerQuery['worker'];
};

const ChangeCertificateModal = ({ hideModal, worker }: Props) => {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebouncedValue(query);
  const { currentAgency } = useAuth();
  const [error, setError] =
    useState<Maybe<{ title: Scalars['String']; message?: Scalars['String'] }>>(
      null
    );

  const { data } = useListAgencyCertificateQuery({
    variables: { agencyId: currentAgency!.id },
  });

  const [addCertificate] = useAddWorkerCertificationMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(worker!),
        fields: { skills() {}, activeCertificates() {} },
      });
    },
  });
  const [removeCertificate] = useRemoveWorkerCertificateMutation({
    onError: (error) => {
      setError((prevValues) => {
        const defaultError = {
          ...prevValues,
          title: 'An error has occurred',
          message: UNKNOWN_ERROR_TEXT,
        };

        const gqlError = error.graphQLErrors[0];

        if (gqlError) {
          return {
            ...prevValues,
            title: 'Something went wrong',
            message: gqlError.message,
          };
        }

        return defaultError;
      });
    },
    update: (cache) => {
      cache.modify({
        id: cache.identify(worker!),
        fields: { activeCertificates() {}, jobWorkers() {} },
      });
    },
  });

  const items: Item[] = useMemo(() => {
    if (data) {
      const certificates = data.agency.certificates.filter((certificate) => {
        if (
          worker.activeCertificates.some((cert) => cert.id === certificate.id)
        ) {
          return false;
        }

        return certificate.name
          .toLowerCase()
          .includes(debouncedQuery.toLowerCase());
      });

      return sortBy(certificates, 'name');
    }

    return [];
  }, [data, debouncedQuery, worker.activeCertificates]);

  const sortedWorkerCertificates = useMemo(
    () => sortBy(worker.activeCertificates, 'name'),
    [worker.activeCertificates]
  );

  const [showChangeModal, hideChangeModal] = useModal<{
    index: Scalars['Int'];
  }>(
    ({ index }) => (
      <RemoveCertificateModalConfirm
        hideModal={() => {
          hideChangeModal();
        }}
        onAction={() => {
          removeCertificate({
            variables: {
              certificateId: sortedWorkerCertificates[index].id,
              workerId: worker.id,
            },
          });
          hideChangeModal();
        }}
      />
    ),
    [sortedWorkerCertificates]
  );

  const handleRemove = useCallback(
    (index: number) => {
      setError(null);
      showChangeModal({ index });
    },
    [sortedWorkerCertificates]
  );

  const handleSelect = useCallback(
    (item) => {
      addCertificate({
        variables: { certificateId: item.selectedItem.id, workerId: worker.id },
      });
    },
    [worker.id]
  );

  return (
    <Modal
      disableClickout
      size="sm"
      title="Change GravyWorker's credentials"
      onRequestClose={hideModal}
    >
      <Card.Section>
        {error && (
          <Alert
            description={error.message}
            icon={faExclamationTriangle}
            status="danger"
            title={error.title}
          />
        )}
        {sortedWorkerCertificates.length > 0 && (
          <FormElement>
            <TagList
              showIcon={true}
              style={{ backgroundColor: '#e0eaff' }}
              tags={sortedWorkerCertificates.map((cert) => cert.name)}
              onRemove={handleRemove}
            />
          </FormElement>
        )}

        <FormElement>
          <Autocomplete
            autoFocus
            fixedSelectHeight="lg"
            id="certificate"
            itemToKey={(item) => item.id}
            itemToString={(item) => (item ? item.name : '')}
            items={items}
            placeholder="Search for Credentials..."
            selectedItem={null}
            onInputValueChange={({ inputValue }) => setQuery(inputValue || '')}
            onSelectedItemChange={handleSelect}
          />
        </FormElement>
        <Stack justify="end">
          <Button
            a11yLabel="Close modal"
            id="btn-finish"
            label="Finish"
            onClick={hideModal}
          />
        </Stack>
      </Card.Section>
    </Modal>
  );
};

export default ChangeCertificateModal;

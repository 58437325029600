import useModal from '@area2k/use-modal';
import { MoreVert } from '@mui/icons-material';
import { MenuItem, Popover, Stack, Divider } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useMemo, useState } from 'react';

import { useOrderState, useOrderActions } from '../../context';
import { JobDraftState, Schedule, ShiftInfo } from '../context';

import RemoveJobFromOrderModal from './ReviewStep/modals/RemoveJobModal';
import ShiftModal from './ScheduleStep/ShiftModal';
import { updSchedulesWithHolidayRates } from './ScheduleStep/util';

import { DeleteIcon, EditIcon } from '@/assets/icons';
import Button from '@/components/Button';
import MaskedInput from '@/components/MaskedInput';
import { Subheading, Body } from '@/components/Typography';
import Text from '@/elements/Text';
import useAuth from '@/hooks/useAuth';
import useMediaQuery from '@/hooks/useMediaQuery';
import {
  calcHours,
  formatDaySimpleWithTwoDigitYear,
  formatTime,
} from '@/util/datetime';
import { centsToCurrency } from '@/util/number';
import { CalculateCost } from '@/util/payments';
import TZBadge from '@/components/TZBadge';

type SummaryTableRevampedProps = {
  handleJobsChange: (schedules: Schedule[]) => void;
  updateLastShift?: (schedule: Schedule) => void;
  skillId?: string;
  job: JobDraftState;
  jobIndex: number;
};

const SummaryTableRevamped = ({
  handleJobsChange,
  updateLastShift,
  jobIndex,
  job,
}: SummaryTableRevampedProps) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { billing, orderType, holidays } = useOrderState();
  const { removeJob, replaceJob } = useOrderActions();
  const { payRate, schedules } = job;

  const [selectedSchedule, setSelectedSchedule] = useState<
    Schedule | undefined
  >();
  const [showShiftModal, setShowShiftModal] = useState(false);
  const [pay, setPay] = useState(payRate ? (payRate / 100).toString() : '0');

  const { currentAdminIsCustomerAdmin } = useAuth();

  const handleEdit = (info: ShiftInfo) => {
    if (!selectedSchedule) return;
    const updatedSch: Schedule = {
      ...selectedSchedule,
      startTime: info.startTime,
      endTime: info.endTime,
      quantity: info.quantity,
      mandatoryBreakTime: info.mandatoryBreakTime,
      hiredWorkers: info.hiredWorkers,
      selectedWorkers: info.invitedWorkers,
    };
    const filterSch = schedules.filter(
      (s) => s.groupId !== selectedSchedule.groupId,
    );
    filterSch.push(updatedSch);
    handleJobsChange(filterSch);
    updateLastShift?.(updatedSch);

    setSelectedSchedule(undefined);
    setShowShiftModal(false);
  };

  const removeSkillJob = (index) => {
    removeJob(index);
  };

  const [showRemoveJobModal, hideRemoveJobModal] = useModal(
    ({ index }: { index: number }) => (
      <RemoveJobFromOrderModal
        hideModal={hideRemoveJobModal}
        onConfirm={() => {
          removeSkillJob(index);
          hideRemoveJobModal();
        }}
      />
    ),
    [schedules],
  );

  const holiday = schedules.filter((sch) => sch.isHoliday);

  const color = useMemo(() => {
    const payValue = Number(pay.replace('$', '')) * 100;
    if (payValue < job.minPay!) {
      return '#DC1515';
    } else if (payValue < job.maxPay!) {
      return '#EC7F00';
    } else {
      return '#45A735';
    }
  }, [pay, job.minPay, job.maxPay]);

  const handlePayChange = (payInput) => {
    const payInputNum = payInput.replace('$', '');
    const payValue = Number(payInputNum) * 100;
    const calcBillRate = CalculateCost(
      payInputNum || '0',
      billing?.account.markupPercent ?? 0,
    );
    const newSchedules = updSchedulesWithHolidayRates({
      holidays: holidays || [],
      schedules,
      calcPayRate: payValue,
      calcBillRate,
      minPay: job.minPay || 0,
    });
    setPay(payInput);
    replaceJob(jobIndex, {
      ...job,
      payRate: payValue,
      schedules: newSchedules,
    });
  };

  return (
    <>
      <Stack>
        <Divider />
        <Subheading css={{ marginTop: 12, fontSize: '18px' }}>
          Pay Rates
        </Subheading>
        <Subheading css={{ marginTop: 12 }}>Job Rates</Subheading>
        <Stack
          alignItems="center"
          flexDirection="row"
          height="50px"
          justifyContent="space-between"
          padding="10px"
          style={{ background: '#F4F6F3', marginTop: 12 }}
        >
          <Text weight={'bold'}>Pay rate</Text>
          <div>
            <MaskedInput
              blocks={{
                num: {
                  mask: Number,
                  thousandsSeparator: ',',
                  radix: '.',
                  max: 999,
                  scale: 2,
                  padFractionalZeros: true,
                  signed: false,
                  normalizeZeros: true,
                },
              }}
              mask={'$num'}
              style={{
                marginLeft: '5px',
                width: '100px',
                textAlign: 'end',
                fontSize: '20px',
                fontWeight: '700',
                color,
              }}
              value={pay}
              onAccept={handlePayChange}
            />
          </div>
        </Stack>

        {holiday.length > 0 && (
          <Stack style={{ marginTop: 12 }}>
            <Subheading css={{ marginTop: 12 }}>Holiday Rates</Subheading>
            <Stack style={{ marginTop: 12 }}>
              {!currentAdminIsCustomerAdmin && (
                <Stack
                  alignItems="center"
                  flexDirection="row"
                  height="50px"
                  justifyContent="space-between"
                  padding="10px"
                  style={{ background: '#F4F6F3' }}
                >
                  <Text css={{ fontWeight: 'bold' }}>Pay rate</Text>
                  <Body>${centsToCurrency(holiday[0].payRate)}</Body>
                </Stack>
              )}
            </Stack>
          </Stack>
        )}

        <Divider sx={{ marginTop: '10px' }} />

        <Subheading
          css={{ marginBottom: '10px', marginTop: '10px', fontSize: '18px' }}
        >
          Shift Details
        </Subheading>
        {schedules.map((schGrp, idx) => {
          const startDate = formatDaySimpleWithTwoDigitYear(
            schGrp.dateRange.startDate,
          );
          const hrs = calcHours(schGrp.startTime, schGrp.endTime);
          return (
            <>
              <Stack
                flexDirection="row"
                gap="10px"
                justifyContent="space-between"
              >
                <Stack
                  gap="10px"
                  style={{
                    paddingTop: '16px',
                    paddingBottom: '10px',
                    paddingRight: '10px',
                  }}
                >
                  <Text as="div" weight="bold">
                    {startDate}
                  </Text>
                  <Stack gap="6px">
                    <Text as="div">
                      {schGrp.startTime && schGrp.endTime ? (
                        <>
                          {`Total Hours: `}
                          {formatTime(schGrp.startTime) + ' - '}
                          {formatTime(schGrp.endTime)}
                          <TZBadge
                            timezone={job.address?.timezone ?? ''}
                            date={startDate}
                            style={{
                              marginLeft: '5px',
                              marginRight: '2px',
                              display: 'inline',
                            }}
                            locale={true}
                          />
                          <Text
                            css={{ color: '#7A7A7A' }}
                            lineHeightProp={'small'}
                          >
                            {` | ${schGrp.mandatoryBreakTime} min`}
                          </Text>
                        </>
                      ) : (
                        <>-</>
                      )}
                    </Text>
                    <Text as="div" color={'light'} lineHeightProp={'small'}>
                      {`${hrs} hrs`}
                    </Text>
                  </Stack>
                </Stack>

                {phoneOnly ? (
                  <>
                    <IconButton
                      id={`${schGrp.groupId}-menu-btn`}
                      sx={{ padding: '0px !important', top: '-4px' }}
                      onClick={(e) => {
                        setSelectedSchedule(schGrp);
                        setAnchorEl(e.currentTarget);
                      }}
                    >
                      <MoreVert />
                    </IconButton>
                  </>
                ) : (
                  <Stack
                    flexDirection="row"
                    height="fit-content"
                    marginTop="16px"
                  >
                    <IconButton
                      id={`edit-shift-${idx}`}
                      size="small"
                      style={{ padding: '5px' }}
                      onClick={() => {
                        setSelectedSchedule(schGrp);
                        setShowShiftModal(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      id={`delete-shift-${idx}`}
                      size="small"
                      style={{ padding: '5px' }}
                      onClick={() =>
                        handleJobsChange(
                          schedules.filter((s) => s.groupId !== schGrp.groupId),
                        )
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                )}
              </Stack>
              {idx !== schedules?.length - 1 && <Divider />}
            </>
          );
        })}

        <Divider sx={{ marginTop: '10px' }} />
        <Button
          a11yLabel="Delete Schedule"
          appearance="outline"
          label="Delete Schedule"
          style={{
            marginBottom: '10px',
            borderColor: '#DC1515',
            color: '#DC1515',
          }}
          type="button"
          onClick={() => showRemoveJobModal({ index: jobIndex })}
        />

        <Popover
          PaperProps={{
            sx: {
              border: '1px solid #D3D3D3',
              filter:
                'drop-shadow(0px 4.050473213195801px 4.050473213195801px rgba(0, 0, 0, 0.05))',
              boxShadow: 'none',
            },
          }}
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          id={`popover`}
          open={open}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem
            dense={true}
            onClick={() => {
              setShowShiftModal(true);
              setAnchorEl(null);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            dense={true}
            onClick={() =>
              handleJobsChange(
                schedules.filter(
                  (s) => s.groupId !== selectedSchedule?.groupId,
                ),
              )
            }
          >
            Delete
          </MenuItem>
        </Popover>
      </Stack>

      {showShiftModal && selectedSchedule && (
        <ShiftModal
          customerId={billing?.customer?.id!}
          date={selectedSchedule.dateRange.startDate!}
          hideModal={(e, r) => {
            if (r === 'backdropClick') return;
            setShowShiftModal(false);
          }}
          orderType={orderType}
          schedule={selectedSchedule}
          onSave={handleEdit}
        />
      )}
    </>
  );
};

export default SummaryTableRevamped;
